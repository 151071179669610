<template>
  <div>
    <div class="mb-2 d-flex align-center">
      <img
        class="mr-2 delivery-icon"
        :src="userIcon"
      >
      <span class="delivery-details-text">{{ name }}</span>
    </div>
    <div class="mb-2 d-flex">
      <img
        class="mr-2 delivery-icon"
        :src="mapPinIcon"
      >
      <span class="delivery-details-text">
        {{ address }}
      </span>
    </div>
    <div class="mb-2 d-flex">
      <img
        class="mr-2 delivery-icon"
        :src="phoneIcon"
      >
      <span class="delivery-details-text">{{ phoneNumber }}</span>
    </div>
  </div>
</template>

<script>
import UserIcon from '@/assets/images/logo/user.png';
import MapPinIcon from '@/assets/images/logo/map-pin.png';
import PhoneIcon from '@/assets/images/logo/phone.png';

export default {
  name: 'Location',
  props: {
    name: {
      type: String,
      default: () => '',
    },
    address: {
      type: String,
      default: () => '',
    },
    phoneNumber: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    userIcon: UserIcon,
    mapPinIcon: MapPinIcon,
    phoneIcon: PhoneIcon,
  }),
};
</script>

<style lang="scss">
  .edit-text {
    color: $primary-color-02;
    cursor: pointer;
  }
  .delivery-icon {
    width: 14px;
    height: 14px;
  }
  .delivery-details-text {
    font-family: Sarabun;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: $black-00;
  }
</style>
