<template>
  <div>
    <v-dialog
      v-model="isShowDialogValue"
      :width="width"
    >
      <div class="dialog">
        <div class="d-flex justify-end">
          <img
            class="closeIcon"
            src="@/assets/images/logo/close.svg"
            @click="close()"
          >
        </div>
        <div v-if="!isTitleEmpty">
          <div class="title-text">
            {{ title }}
            <v-divider class="mb-4 mt-3" />
          </div>
        </div>

        <slot />
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    isShowDialog: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => '',
    },
    width: {
      type: String,
      default: () => '650',
    },
  },
  computed: {
    isShowDialogValue: {
      get() {
        return this.isShowDialog;
      },
      set(value) {
        this.$emit('setIsShowDialog', value);
      },
    },
    isTitleEmpty() {
      return this.title === '' || this.title === undefined;
    },
  },
  methods: {
    close() {
      this.$emit('setIsShowDialog', false);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/fonts.scss';

.v-dialog {
  background-color: $white-00;
}

.dialog {
  background-color: $white-00;
  padding: 5%;
  border-radius: 10px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
}

.closeIcon {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.title-text {
  @include kanit;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  color: $black-00;
}
</style>
