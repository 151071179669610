const addresses = [
    {
        "district": "คลองท่อมเหนือ",
        "amphoe": "คลองท่อม",
        "province": "กระบี่",
        "zipcode": 81120,
        "district_code": 810402,
        "amphoe_code": 8104,
        "province_code": 81
    },
    {
        "district": "คลองท่อมใต้",
        "amphoe": "คลองท่อม",
        "province": "กระบี่",
        "zipcode": 81120,
        "district_code": 810401,
        "amphoe_code": 8104,
        "province_code": 81
    },
    {
        "district": "คลองพน",
        "amphoe": "คลองท่อม",
        "province": "กระบี่",
        "zipcode": 81170,
        "district_code": 810403,
        "amphoe_code": 8104,
        "province_code": 81
    },
    {
        "district": "ทรายขาว",
        "amphoe": "คลองท่อม",
        "province": "กระบี่",
        "zipcode": 81170,
        "district_code": 810404,
        "amphoe_code": 8104,
        "province_code": 81
    },
    {
        "district": "พรุดินนา",
        "amphoe": "คลองท่อม",
        "province": "กระบี่",
        "zipcode": 81120,
        "district_code": 810406,
        "amphoe_code": 8104,
        "province_code": 81
    },
    {
        "district": "ห้วยน้ำขาว",
        "amphoe": "คลองท่อม",
        "province": "กระบี่",
        "zipcode": 81120,
        "district_code": 810405,
        "amphoe_code": 8104,
        "province_code": 81
    },
    {
        "district": "เพหลา",
        "amphoe": "คลองท่อม",
        "province": "กระบี่",
        "zipcode": 81120,
        "district_code": 810407,
        "amphoe_code": 8104,
        "province_code": 81
    },
    {
        "district": "คีรีวง",
        "amphoe": "ปลายพระยา",
        "province": "กระบี่",
        "zipcode": 81160,
        "district_code": 810604,
        "amphoe_code": 8106,
        "province_code": 81
    },
    {
        "district": "ปลายพระยา",
        "amphoe": "ปลายพระยา",
        "province": "กระบี่",
        "zipcode": 81160,
        "district_code": 810599,
        "amphoe_code": 8106,
        "province_code": 81
    },
    {
        "district": "เขาต่อ",
        "amphoe": "ปลายพระยา",
        "province": "กระบี่",
        "zipcode": 81160,
        "district_code": 810598,
        "amphoe_code": 8106,
        "province_code": 81
    },
    {
        "district": "เขาเขน",
        "amphoe": "ปลายพระยา",
        "province": "กระบี่",
        "zipcode": 81160,
        "district_code": 810597,
        "amphoe_code": 8106,
        "province_code": 81
    },
    {
        "district": "ดินอุดม",
        "amphoe": "ลำทับ",
        "province": "กระบี่",
        "zipcode": 81190,
        "district_code": 810702,
        "amphoe_code": 8107,
        "province_code": 81
    },
    {
        "district": "ดินแดง",
        "amphoe": "ลำทับ",
        "province": "กระบี่",
        "zipcode": 81190,
        "district_code": 810704,
        "amphoe_code": 8107,
        "province_code": 81
    },
    {
        "district": "ทุ่งไทรทอง",
        "amphoe": "ลำทับ",
        "province": "กระบี่",
        "zipcode": 81190,
        "district_code": 810703,
        "amphoe_code": 8107,
        "province_code": 81
    },
    {
        "district": "ลำทับ",
        "amphoe": "ลำทับ",
        "province": "กระบี่",
        "zipcode": 81190,
        "district_code": 810499,
        "amphoe_code": 8107,
        "province_code": 81
    },
    {
        "district": "คลองยา",
        "amphoe": "อ่าวลึก",
        "province": "กระบี่",
        "zipcode": 81110,
        "district_code": 810508,
        "amphoe_code": 8105,
        "province_code": 81
    },
    {
        "district": "คลองหิน",
        "amphoe": "อ่าวลึก",
        "province": "กระบี่",
        "zipcode": 81110,
        "district_code": 810504,
        "amphoe_code": 8105,
        "province_code": 81
    },
    {
        "district": "นาเหนือ",
        "amphoe": "อ่าวลึก",
        "province": "กระบี่",
        "zipcode": 81110,
        "district_code": 810503,
        "amphoe_code": 8105,
        "province_code": 81
    },
    {
        "district": "บ้านกลาง",
        "amphoe": "อ่าวลึก",
        "province": "กระบี่",
        "zipcode": 81110,
        "district_code": 810509,
        "amphoe_code": 8105,
        "province_code": 81
    },
    {
        "district": "อ่าวลึกน้อย",
        "amphoe": "อ่าวลึก",
        "province": "กระบี่",
        "zipcode": 81110,
        "district_code": 810505,
        "amphoe_code": 8105,
        "province_code": 81
    },
    {
        "district": "อ่าวลึกเหนือ",
        "amphoe": "อ่าวลึก",
        "province": "กระบี่",
        "zipcode": 81110,
        "district_code": 810506,
        "amphoe_code": 8105,
        "province_code": 81
    },
    {
        "district": "อ่าวลึกใต้",
        "amphoe": "อ่าวลึก",
        "province": "กระบี่",
        "zipcode": 81110,
        "district_code": 810501,
        "amphoe_code": 8105,
        "province_code": 81
    },
    {
        "district": "เขาใหญ่",
        "amphoe": "อ่าวลึก",
        "province": "กระบี่",
        "zipcode": 81110,
        "district_code": 810507,
        "amphoe_code": 8105,
        "province_code": 81
    },
    {
        "district": "แหลมสัก",
        "amphoe": "อ่าวลึก",
        "province": "กระบี่",
        "zipcode": 81110,
        "district_code": 810502,
        "amphoe_code": 8105,
        "province_code": 81
    },
    {
        "district": "คลองยาง",
        "amphoe": "เกาะลันตา",
        "province": "กระบี่",
        "zipcode": 81120,
        "district_code": 810304,
        "amphoe_code": 8103,
        "province_code": 81
    },
    {
        "district": "ศาลาด่าน",
        "amphoe": "เกาะลันตา",
        "province": "กระบี่",
        "zipcode": 81150,
        "district_code": 810305,
        "amphoe_code": 8103,
        "province_code": 81
    },
    {
        "district": "เกาะกลาง",
        "amphoe": "เกาะลันตา",
        "province": "กระบี่",
        "zipcode": 81120,
        "district_code": 810303,
        "amphoe_code": 8103,
        "province_code": 81
    },
    {
        "district": "เกาะลันตาน้อย",
        "amphoe": "เกาะลันตา",
        "province": "กระบี่",
        "zipcode": 81150,
        "district_code": 810302,
        "amphoe_code": 8103,
        "province_code": 81
    },
    {
        "district": "เกาะลันตาใหญ่",
        "amphoe": "เกาะลันตา",
        "province": "กระบี่",
        "zipcode": 81150,
        "district_code": 810301,
        "amphoe_code": 8103,
        "province_code": 81
    },
    {
        "district": "พรุเตียว",
        "amphoe": "เขาพนม",
        "province": "กระบี่",
        "zipcode": 81140,
        "district_code": 810204,
        "amphoe_code": 8102,
        "province_code": 81
    },
    {
        "district": "สินปุน",
        "amphoe": "เขาพนม",
        "province": "กระบี่",
        "zipcode": 80240,
        "district_code": 810203,
        "amphoe_code": 8102,
        "province_code": 81
    },
    {
        "district": "หน้าเขา",
        "amphoe": "เขาพนม",
        "province": "กระบี่",
        "zipcode": 81140,
        "district_code": 810205,
        "amphoe_code": 8102,
        "province_code": 81
    },
    {
        "district": "เขาดิน",
        "amphoe": "เขาพนม",
        "province": "กระบี่",
        "zipcode": 81140,
        "district_code": 810202,
        "amphoe_code": 8102,
        "province_code": 81
    },
    {
        "district": "เขาพนม",
        "amphoe": "เขาพนม",
        "province": "กระบี่",
        "zipcode": 81140,
        "district_code": 810201,
        "amphoe_code": 8102,
        "province_code": 81
    },
    {
        "district": "โคกหาร",
        "amphoe": "เขาพนม",
        "province": "กระบี่",
        "zipcode": 80240,
        "district_code": 810206,
        "amphoe_code": 8102,
        "province_code": 81
    },
    {
        "district": "กระบี่น้อย",
        "amphoe": "เมืองกระบี่",
        "province": "กระบี่",
        "zipcode": 81000,
        "district_code": 810103,
        "amphoe_code": 8101,
        "province_code": 81
    },
    {
        "district": "กระบี่ใหญ่",
        "amphoe": "เมืองกระบี่",
        "province": "กระบี่",
        "zipcode": 81000,
        "district_code": 810102,
        "amphoe_code": 8101,
        "province_code": 81
    },
    {
        "district": "คลองประสงค์",
        "amphoe": "เมืองกระบี่",
        "province": "กระบี่",
        "zipcode": 81000,
        "district_code": 810118,
        "amphoe_code": 8101,
        "province_code": 81
    },
    {
        "district": "ทับปริก",
        "amphoe": "เมืองกระบี่",
        "province": "กระบี่",
        "zipcode": 81000,
        "district_code": 810111,
        "amphoe_code": 8101,
        "province_code": 81
    },
    {
        "district": "ปากน้ำ",
        "amphoe": "เมืองกระบี่",
        "province": "กระบี่",
        "zipcode": 81000,
        "district_code": 810101,
        "amphoe_code": 8101,
        "province_code": 81
    },
    {
        "district": "หนองทะเล",
        "amphoe": "เมืองกระบี่",
        "province": "กระบี่",
        "zipcode": 81180,
        "district_code": 810117,
        "amphoe_code": 8101,
        "province_code": 81
    },
    {
        "district": "อ่าวนาง",
        "amphoe": "เมืองกระบี่",
        "province": "กระบี่",
        "zipcode": 81000,
        "district_code": 810116,
        "amphoe_code": 8101,
        "province_code": 81
    },
    {
        "district": "เขาคราม",
        "amphoe": "เมืองกระบี่",
        "province": "กระบี่",
        "zipcode": 81000,
        "district_code": 810105,
        "amphoe_code": 8101,
        "province_code": 81
    },
    {
        "district": "เขาทอง",
        "amphoe": "เมืองกระบี่",
        "province": "กระบี่",
        "zipcode": 81000,
        "district_code": 810106,
        "amphoe_code": 8101,
        "province_code": 81
    },
    {
        "district": "ไสไทย",
        "amphoe": "เมืองกระบี่",
        "province": "กระบี่",
        "zipcode": 81000,
        "district_code": 810115,
        "amphoe_code": 8101,
        "province_code": 81
    },
    {
        "district": "คลองขนาน",
        "amphoe": "เหนือคลอง",
        "province": "กระบี่",
        "zipcode": 81130,
        "district_code": 810107,
        "amphoe_code": 8108,
        "province_code": 81
    },
    {
        "district": "คลองเขม้า",
        "amphoe": "เหนือคลอง",
        "province": "กระบี่",
        "zipcode": 81130,
        "district_code": 810108,
        "amphoe_code": 8108,
        "province_code": 81
    },
    {
        "district": "ตลิ่งชัน",
        "amphoe": "เหนือคลอง",
        "province": "กระบี่",
        "zipcode": 81130,
        "district_code": 810110,
        "amphoe_code": 8108,
        "province_code": 81
    },
    {
        "district": "ปกาสัย",
        "amphoe": "เหนือคลอง",
        "province": "กระบี่",
        "zipcode": 81130,
        "district_code": 810112,
        "amphoe_code": 8108,
        "province_code": 81
    },
    {
        "district": "ห้วยยูง",
        "amphoe": "เหนือคลอง",
        "province": "กระบี่",
        "zipcode": 81130,
        "district_code": 810113,
        "amphoe_code": 8108,
        "province_code": 81
    },
    {
        "district": "เกาะศรีบอยา",
        "amphoe": "เหนือคลอง",
        "province": "กระบี่",
        "zipcode": 81130,
        "district_code": 810104,
        "amphoe_code": 8108,
        "province_code": 81
    },
    {
        "district": "เหนือคลอง",
        "amphoe": "เหนือคลอง",
        "province": "กระบี่",
        "zipcode": 81130,
        "district_code": 810114,
        "amphoe_code": 8108,
        "province_code": 81
    },
    {
        "district": "โคกยาง",
        "amphoe": "เหนือคลอง",
        "province": "กระบี่",
        "zipcode": 81130,
        "district_code": 810109,
        "amphoe_code": 8108,
        "province_code": 81
    },
    {
        "district": "คลองต้นไทร",
        "amphoe": "คลองสาน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10600,
        "district_code": 101804,
        "amphoe_code": 1018,
        "province_code": 10
    },
    {
        "district": "คลองสาน",
        "amphoe": "คลองสาน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10600,
        "district_code": 101599,
        "amphoe_code": 1018,
        "province_code": 10
    },
    {
        "district": "บางลำภูล่าง",
        "amphoe": "คลองสาน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10600,
        "district_code": 101803,
        "amphoe_code": 1018,
        "province_code": 10
    },
    {
        "district": "สมเด็จเจ้าพระยา",
        "amphoe": "คลองสาน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10600,
        "district_code": 101801,
        "amphoe_code": 1018,
        "province_code": 10
    },
    {
        "district": "ทรายกองดิน",
        "amphoe": "คลองสามวา",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10510,
        "district_code": 101004,
        "amphoe_code": 1046,
        "province_code": 10
    },
    {
        "district": "ทรายกองดินใต้",
        "amphoe": "คลองสามวา",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10510,
        "district_code": 101005,
        "amphoe_code": 1046,
        "province_code": 10
    },
    {
        "district": "บางชัน",
        "amphoe": "คลองสามวา",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10510,
        "district_code": 101003,
        "amphoe_code": 1046,
        "province_code": 10
    },
    {
        "district": "สามวาตะวันตก",
        "amphoe": "คลองสามวา",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10510,
        "district_code": 101007,
        "amphoe_code": 1046,
        "province_code": 10
    },
    {
        "district": "สามวาตะวันออก",
        "amphoe": "คลองสามวา",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10510,
        "district_code": 101006,
        "amphoe_code": 1046,
        "province_code": 10
    },
    {
        "district": "คลองตัน",
        "amphoe": "คลองเตย",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10110,
        "district_code": 100902,
        "amphoe_code": 1033,
        "province_code": 10
    },
    {
        "district": "คลองเตย",
        "amphoe": "คลองเตย",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10110,
        "district_code": 100901,
        "amphoe_code": 1033,
        "province_code": 10
    },
    {
        "district": "พระโขนง",
        "amphoe": "คลองเตย",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10110,
        "district_code": 100903,
        "amphoe_code": 1033,
        "province_code": 10
    },
    {
        "district": "พระโขนง",
        "amphoe": "คลองเตย",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10260,
        "district_code": 100903,
        "amphoe_code": 1033,
        "province_code": 10
    },
    {
        "district": "คันนายาว",
        "amphoe": "คันนายาว",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10230,
        "district_code": 100606,
        "amphoe_code": 1043,
        "province_code": 10
    },
    {
        "district": "รามอินทรา",
        "amphoe": "คันนายาว",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10230,
        "district_code": false,
        "amphoe_code": 1043,
        "province_code": 10
    },
    {
        "district": "จตุจักร",
        "amphoe": "จตุจักร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10900,
        "district_code": 103005,
        "amphoe_code": 1030,
        "province_code": 10
    },
    {
        "district": "จอมพล",
        "amphoe": "จตุจักร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10900,
        "district_code": 103004,
        "amphoe_code": 1030,
        "province_code": 10
    },
    {
        "district": "จันทรเกษม",
        "amphoe": "จตุจักร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10900,
        "district_code": 103003,
        "amphoe_code": 1030,
        "province_code": 10
    },
    {
        "district": "ลาดยาว",
        "amphoe": "จตุจักร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10900,
        "district_code": 100501,
        "amphoe_code": 1030,
        "province_code": 10
    },
    {
        "district": "เสนานิคม",
        "amphoe": "จตุจักร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10900,
        "district_code": 103002,
        "amphoe_code": 1030,
        "province_code": 10
    },
    {
        "district": "จอมทอง",
        "amphoe": "จอมทอง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10150,
        "district_code": 102102,
        "amphoe_code": 1035,
        "province_code": 10
    },
    {
        "district": "บางขุนเทียน",
        "amphoe": "จอมทอง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10150,
        "district_code": 102103,
        "amphoe_code": 1035,
        "province_code": 10
    },
    {
        "district": "บางค้อ",
        "amphoe": "จอมทอง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10150,
        "district_code": 102101,
        "amphoe_code": 1035,
        "province_code": 10
    },
    {
        "district": "บางมด",
        "amphoe": "จอมทอง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10150,
        "district_code": 102106,
        "amphoe_code": 1035,
        "province_code": 10
    },
    {
        "district": "ดอนเมือง",
        "amphoe": "ดอนเมือง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10210,
        "district_code": false,
        "amphoe_code": 1036,
        "province_code": 10
    },
    {
        "district": "สนามบิน",
        "amphoe": "ดอนเมือง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10210,
        "district_code": false,
        "amphoe_code": 1036,
        "province_code": 10
    },
    {
        "district": "สีกัน",
        "amphoe": "ดอนเมือง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10210,
        "district_code": 100505,
        "amphoe_code": 1036,
        "province_code": 10
    },
    {
        "district": "ดินแดง",
        "amphoe": "ดินแดง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10400,
        "district_code": 101703,
        "amphoe_code": 1026,
        "province_code": 10
    },
    {
        "district": "ดุสิต",
        "amphoe": "ดุสิต",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10300,
        "district_code": 100201,
        "amphoe_code": 1002,
        "province_code": 10
    },
    {
        "district": "ถนนนครไชยศรี",
        "amphoe": "ดุสิต",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10300,
        "district_code": 100206,
        "amphoe_code": 1002,
        "province_code": 10
    },
    {
        "district": "วชิรพยาบาล",
        "amphoe": "ดุสิต",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10300,
        "district_code": 100202,
        "amphoe_code": 1002,
        "province_code": 10
    },
    {
        "district": "สวนจิตรลดา",
        "amphoe": "ดุสิต",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10300,
        "district_code": 100203,
        "amphoe_code": 1002,
        "province_code": 10
    },
    {
        "district": "สวนจิตรลดา",
        "amphoe": "ดุสิต",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10303,
        "district_code": 100203,
        "amphoe_code": 1002,
        "province_code": 10
    },
    {
        "district": "สี่แยกมหานาค",
        "amphoe": "ดุสิต",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10300,
        "district_code": 100204,
        "amphoe_code": 1002,
        "province_code": 10
    },
    {
        "district": "คลองชักพระ",
        "amphoe": "ตลิ่งชัน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10170,
        "district_code": 101901,
        "amphoe_code": 1019,
        "province_code": 10
    },
    {
        "district": "ฉิมพลี",
        "amphoe": "ตลิ่งชัน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10170,
        "district_code": 101903,
        "amphoe_code": 1019,
        "province_code": 10
    },
    {
        "district": "ตลิ่งชัน",
        "amphoe": "ตลิ่งชัน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10170,
        "district_code": 101902,
        "amphoe_code": 1019,
        "province_code": 10
    },
    {
        "district": "บางพรม",
        "amphoe": "ตลิ่งชัน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10170,
        "district_code": 101904,
        "amphoe_code": 1019,
        "province_code": 10
    },
    {
        "district": "บางระมาด",
        "amphoe": "ตลิ่งชัน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10170,
        "district_code": 101905,
        "amphoe_code": 1019,
        "province_code": 10
    },
    {
        "district": "บางเชือกหนัง",
        "amphoe": "ตลิ่งชัน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10170,
        "district_code": 101907,
        "amphoe_code": 1019,
        "province_code": 10
    },
    {
        "district": "ทวีวัฒนา",
        "amphoe": "ทวีวัฒนา",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10170,
        "district_code": 101906,
        "amphoe_code": 1048,
        "province_code": 10
    },
    {
        "district": "ศาลาธรรมสพน์",
        "amphoe": "ทวีวัฒนา",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10170,
        "district_code": 101908,
        "amphoe_code": 1048,
        "province_code": 10
    },
    {
        "district": "ทุ่งครุ",
        "amphoe": "ทุ่งครุ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10140,
        "district_code": 102404,
        "amphoe_code": 1049,
        "province_code": 10
    },
    {
        "district": "บางมด",
        "amphoe": "ทุ่งครุ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10140,
        "district_code": 102106,
        "amphoe_code": 1049,
        "province_code": 10
    },
    {
        "district": "ดาวคะนอง",
        "amphoe": "ธนบุรี",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10600,
        "district_code": 101506,
        "amphoe_code": 1015,
        "province_code": 10
    },
    {
        "district": "ตลาดพลู",
        "amphoe": "ธนบุรี",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10600,
        "district_code": 101505,
        "amphoe_code": 1015,
        "province_code": 10
    },
    {
        "district": "บางยี่เรือ",
        "amphoe": "ธนบุรี",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10600,
        "district_code": 101503,
        "amphoe_code": 1015,
        "province_code": 10
    },
    {
        "district": "บุคคโล",
        "amphoe": "ธนบุรี",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10600,
        "district_code": 101504,
        "amphoe_code": 1015,
        "province_code": 10
    },
    {
        "district": "วัดกัลยาณ์",
        "amphoe": "ธนบุรี",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10600,
        "district_code": 101501,
        "amphoe_code": 1015,
        "province_code": 10
    },
    {
        "district": "สำเหร่",
        "amphoe": "ธนบุรี",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10600,
        "district_code": 101507,
        "amphoe_code": 1015,
        "province_code": 10
    },
    {
        "district": "หิรัญรูจี",
        "amphoe": "ธนบุรี",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10600,
        "district_code": 101502,
        "amphoe_code": 1015,
        "province_code": 10
    },
    {
        "district": "บางขุนนนท์",
        "amphoe": "บางกอกน้อย",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10700,
        "district_code": 102006,
        "amphoe_code": 1020,
        "province_code": 10
    },
    {
        "district": "บางขุนศรี",
        "amphoe": "บางกอกน้อย",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10700,
        "district_code": 102007,
        "amphoe_code": 1020,
        "province_code": 10
    },
    {
        "district": "บ้านช่างหล่อ",
        "amphoe": "บางกอกน้อย",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10700,
        "district_code": 102005,
        "amphoe_code": 1020,
        "province_code": 10
    },
    {
        "district": "ศิริราช",
        "amphoe": "บางกอกน้อย",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10700,
        "district_code": 102004,
        "amphoe_code": 1020,
        "province_code": 10
    },
    {
        "district": "อรุณอัมรินทร์",
        "amphoe": "บางกอกน้อย",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10700,
        "district_code": false,
        "amphoe_code": 1020,
        "province_code": 10
    },
    {
        "district": "วัดท่าพระ",
        "amphoe": "บางกอกใหญ่",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10600,
        "district_code": 101602,
        "amphoe_code": 1016,
        "province_code": 10
    },
    {
        "district": "วัดอรุณ",
        "amphoe": "บางกอกใหญ่",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10600,
        "district_code": 101601,
        "amphoe_code": 1016,
        "province_code": 10
    },
    {
        "district": "คลองจั่น",
        "amphoe": "บางกะปิ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10240,
        "district_code": 100601,
        "amphoe_code": 1006,
        "province_code": 10
    },
    {
        "district": "หัวหมาก",
        "amphoe": "บางกะปิ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10240,
        "district_code": 100608,
        "amphoe_code": 1006,
        "province_code": 10
    },
    {
        "district": "หัวหมาก",
        "amphoe": "บางกะปิ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10250,
        "district_code": 100608,
        "amphoe_code": 1006,
        "province_code": 10
    },
    {
        "district": "ท่าข้าม",
        "amphoe": "บางขุนเทียน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10150,
        "district_code": 102105,
        "amphoe_code": 1021,
        "province_code": 10
    },
    {
        "district": "แสมดำ",
        "amphoe": "บางขุนเทียน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10150,
        "district_code": 102107,
        "amphoe_code": 1021,
        "province_code": 10
    },
    {
        "district": "บางคอแหลม",
        "amphoe": "บางคอแหลม",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10120,
        "district_code": 101207,
        "amphoe_code": 1031,
        "province_code": 10
    },
    {
        "district": "บางโคล่",
        "amphoe": "บางคอแหลม",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10120,
        "district_code": 101206,
        "amphoe_code": 1031,
        "province_code": 10
    },
    {
        "district": "วัดพระยาไกร",
        "amphoe": "บางคอแหลม",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10120,
        "district_code": 101205,
        "amphoe_code": 1031,
        "province_code": 10
    },
    {
        "district": "บางซื่อ",
        "amphoe": "บางซื่อ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10800,
        "district_code": 100205,
        "amphoe_code": 1029,
        "province_code": 10
    },
    {
        "district": "วงศ์สว่าง",
        "amphoe": "บางซื่อ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10800,
        "district_code": false,
        "amphoe_code": 1029,
        "province_code": 10
    },
    {
        "district": "บางนาเหนือ",
        "amphoe": "บางนา",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10260,
        "district_code": 100904,
        "amphoe_code": 1047,
        "province_code": 10
    },
    {
        "district": "บางนาใต้",
        "amphoe": "บางนา",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10260,
        "district_code": 100904,
        "amphoe_code": 1047,
        "province_code": 10
    },
    {
        "district": "บางบอน",
        "amphoe": "บางบอน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10150,
        "district_code": 102104,
        "amphoe_code": 1050,
        "province_code": 10
    },
    {
        "district": "บางบำหรุ",
        "amphoe": "บางพลัด",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10700,
        "district_code": 102002,
        "amphoe_code": 1025,
        "province_code": 10
    },
    {
        "district": "บางพลัด",
        "amphoe": "บางพลัด",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10700,
        "district_code": 102001,
        "amphoe_code": 1025,
        "province_code": 10
    },
    {
        "district": "บางยี่ขัน",
        "amphoe": "บางพลัด",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10700,
        "district_code": 102008,
        "amphoe_code": 1025,
        "province_code": 10
    },
    {
        "district": "บางอ้อ",
        "amphoe": "บางพลัด",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10700,
        "district_code": 102003,
        "amphoe_code": 1025,
        "province_code": 10
    },
    {
        "district": "บางรัก",
        "amphoe": "บางรัก",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10500,
        "district_code": 100404,
        "amphoe_code": 1004,
        "province_code": 10
    },
    {
        "district": "มหาพฤฒาราม",
        "amphoe": "บางรัก",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10500,
        "district_code": 100401,
        "amphoe_code": 1004,
        "province_code": 10
    },
    {
        "district": "สีลม",
        "amphoe": "บางรัก",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10500,
        "district_code": 100402,
        "amphoe_code": 1004,
        "province_code": 10
    },
    {
        "district": "สี่พระยา",
        "amphoe": "บางรัก",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10500,
        "district_code": 100405,
        "amphoe_code": 1004,
        "province_code": 10
    },
    {
        "district": "สุริยวงศ์",
        "amphoe": "บางรัก",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10500,
        "district_code": 100403,
        "amphoe_code": 1004,
        "province_code": 10
    },
    {
        "district": "ท่าแร้ง",
        "amphoe": "บางเขน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10220,
        "district_code": 100508,
        "amphoe_code": 1005,
        "province_code": 10
    },
    {
        "district": "ท่าแร้ง",
        "amphoe": "บางเขน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10230,
        "district_code": 100508,
        "amphoe_code": 1005,
        "province_code": 10
    },
    {
        "district": "อนุสาวรีย์",
        "amphoe": "บางเขน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10220,
        "district_code": 100502,
        "amphoe_code": 1005,
        "province_code": 10
    },
    {
        "district": "บางแค",
        "amphoe": "บางแค",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10160,
        "district_code": 102203,
        "amphoe_code": 1040,
        "province_code": 10
    },
    {
        "district": "บางแคเหนือ",
        "amphoe": "บางแค",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10160,
        "district_code": 102204,
        "amphoe_code": 1040,
        "province_code": 10
    },
    {
        "district": "บางไผ่",
        "amphoe": "บางแค",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10160,
        "district_code": 102205,
        "amphoe_code": 1040,
        "province_code": 10
    },
    {
        "district": "หลักสอง",
        "amphoe": "บางแค",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10160,
        "district_code": 102301,
        "amphoe_code": 1040,
        "province_code": 10
    },
    {
        "district": "คลองกุ่ม",
        "amphoe": "บึงกุ่ม",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10230,
        "district_code": 100604,
        "amphoe_code": 1027,
        "province_code": 10
    },
    {
        "district": "คลองกุ่ม",
        "amphoe": "บึงกุ่ม",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10240,
        "district_code": 100604,
        "amphoe_code": 1027,
        "province_code": 10
    },
    {
        "district": "นวมินทร์",
        "amphoe": "บึงกุ่ม",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10230,
        "district_code": false,
        "amphoe_code": 1027,
        "province_code": 10
    },
    {
        "district": "นวลจันทร์",
        "amphoe": "บึงกุ่ม",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10230,
        "district_code": false,
        "amphoe_code": 1027,
        "province_code": 10
    },
    {
        "district": "ปทุมวัน",
        "amphoe": "ปทุมวัน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10330,
        "district_code": 100703,
        "amphoe_code": 1007,
        "province_code": 10
    },
    {
        "district": "รองเมือง",
        "amphoe": "ปทุมวัน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10330,
        "district_code": 100701,
        "amphoe_code": 1007,
        "province_code": 10
    },
    {
        "district": "ลุมพินี",
        "amphoe": "ปทุมวัน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10330,
        "district_code": 100704,
        "amphoe_code": 1007,
        "province_code": 10
    },
    {
        "district": "วังใหม่",
        "amphoe": "ปทุมวัน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10330,
        "district_code": 100702,
        "amphoe_code": 1007,
        "province_code": 10
    },
    {
        "district": "วังใหม่",
        "amphoe": "ปทุมวัน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10110,
        "district_code": 100702,
        "amphoe_code": 1007,
        "province_code": 10
    },
    {
        "district": "วังใหม่",
        "amphoe": "ปทุมวัน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10120,
        "district_code": 100702,
        "amphoe_code": 1007,
        "province_code": 10
    },
    {
        "district": "วังใหม่",
        "amphoe": "ปทุมวัน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10400,
        "district_code": 100702,
        "amphoe_code": 1007,
        "province_code": 10
    },
    {
        "district": "วังใหม่",
        "amphoe": "ปทุมวัน",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10500,
        "district_code": 100702,
        "amphoe_code": 1007,
        "province_code": 10
    },
    {
        "district": "ดอกไม้",
        "amphoe": "ประเวศ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10250,
        "district_code": 100909,
        "amphoe_code": 1032,
        "province_code": 10
    },
    {
        "district": "ประเวศ",
        "amphoe": "ประเวศ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10250,
        "district_code": 100908,
        "amphoe_code": 1032,
        "province_code": 10
    },
    {
        "district": "หนองบอน",
        "amphoe": "ประเวศ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10250,
        "district_code": 100907,
        "amphoe_code": 1032,
        "province_code": 10
    },
    {
        "district": "คลองมหานาค",
        "amphoe": "ป้อมปราบศัตรูพ่าย",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10100,
        "district_code": 100803,
        "amphoe_code": 1008,
        "province_code": 10
    },
    {
        "district": "บ้านบาตร",
        "amphoe": "ป้อมปราบศัตรูพ่าย",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10100,
        "district_code": 100804,
        "amphoe_code": 1008,
        "province_code": 10
    },
    {
        "district": "ป้อมปราบ",
        "amphoe": "ป้อมปราบศัตรูพ่าย",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10100,
        "district_code": 100801,
        "amphoe_code": 1008,
        "province_code": 10
    },
    {
        "district": "วัดเทพศิรินทร์",
        "amphoe": "ป้อมปราบศัตรูพ่าย",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10100,
        "district_code": 100802,
        "amphoe_code": 1008,
        "province_code": 10
    },
    {
        "district": "วัดโสมนัส",
        "amphoe": "ป้อมปราบศัตรูพ่าย",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10100,
        "district_code": 100805,
        "amphoe_code": 1008,
        "province_code": 10
    },
    {
        "district": "สามเสนใน",
        "amphoe": "พญาไท",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10400,
        "district_code": 100299,
        "amphoe_code": 1014,
        "province_code": 10
    },
    {
        "district": "ชนะสงคราม",
        "amphoe": "พระนคร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10200,
        "district_code": 100109,
        "amphoe_code": 1001,
        "province_code": 10
    },
    {
        "district": "ตลาดยอด",
        "amphoe": "พระนคร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10200,
        "district_code": 100108,
        "amphoe_code": 1001,
        "province_code": 10
    },
    {
        "district": "บวรนิเวศ",
        "amphoe": "พระนคร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10200,
        "district_code": 100107,
        "amphoe_code": 1001,
        "province_code": 10
    },
    {
        "district": "บางขุนพรหม",
        "amphoe": "พระนคร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10200,
        "district_code": 100111,
        "amphoe_code": 1001,
        "province_code": 10
    },
    {
        "district": "บ้านพานถม",
        "amphoe": "พระนคร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10200,
        "district_code": 100110,
        "amphoe_code": 1001,
        "province_code": 10
    },
    {
        "district": "พระบรมมหาราชวัง",
        "amphoe": "พระนคร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10200,
        "district_code": 100101,
        "amphoe_code": 1001,
        "province_code": 10
    },
    {
        "district": "วังบูรพาภิรมย์",
        "amphoe": "พระนคร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10200,
        "district_code": 100102,
        "amphoe_code": 1001,
        "province_code": 10
    },
    {
        "district": "วัดราชบพิธ",
        "amphoe": "พระนคร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10200,
        "district_code": 100103,
        "amphoe_code": 1001,
        "province_code": 10
    },
    {
        "district": "วัดสามพระยา",
        "amphoe": "พระนคร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10200,
        "district_code": 100112,
        "amphoe_code": 1001,
        "province_code": 10
    },
    {
        "district": "ศาลเจ้าพ่อเสือ",
        "amphoe": "พระนคร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10200,
        "district_code": 100105,
        "amphoe_code": 1001,
        "province_code": 10
    },
    {
        "district": "สำราญราษฎร์",
        "amphoe": "พระนคร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10200,
        "district_code": 100104,
        "amphoe_code": 1001,
        "province_code": 10
    },
    {
        "district": "เสาชิงช้า",
        "amphoe": "พระนคร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10200,
        "district_code": 100106,
        "amphoe_code": 1001,
        "province_code": 10
    },
    {
        "district": "บางจาก",
        "amphoe": "พระโขนง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10260,
        "district_code": 100905,
        "amphoe_code": 1009,
        "province_code": 10
    },
    {
        "district": "คลองขวาง",
        "amphoe": "ภาษีเจริญ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10160,
        "district_code": 102208,
        "amphoe_code": 1022,
        "province_code": 10
    },
    {
        "district": "คูหาสวรรค์",
        "amphoe": "ภาษีเจริญ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10160,
        "district_code": 102210,
        "amphoe_code": 1022,
        "province_code": 10
    },
    {
        "district": "บางจาก",
        "amphoe": "ภาษีเจริญ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10160,
        "district_code": 100905,
        "amphoe_code": 1022,
        "province_code": 10
    },
    {
        "district": "บางด้วน",
        "amphoe": "ภาษีเจริญ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10160,
        "district_code": 102202,
        "amphoe_code": 1022,
        "province_code": 10
    },
    {
        "district": "บางหว้า",
        "amphoe": "ภาษีเจริญ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10160,
        "district_code": 102201,
        "amphoe_code": 1022,
        "province_code": 10
    },
    {
        "district": "บางแวก",
        "amphoe": "ภาษีเจริญ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10160,
        "district_code": 102207,
        "amphoe_code": 1022,
        "province_code": 10
    },
    {
        "district": "ปากคลองภาษีเจริญ",
        "amphoe": "ภาษีเจริญ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10160,
        "district_code": 102209,
        "amphoe_code": 1022,
        "province_code": 10
    },
    {
        "district": "มีนบุรี",
        "amphoe": "มีนบุรี",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10510,
        "district_code": 101001,
        "amphoe_code": 1010,
        "province_code": 10
    },
    {
        "district": "แสนแสบ",
        "amphoe": "มีนบุรี",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10510,
        "district_code": 101002,
        "amphoe_code": 1010,
        "province_code": 10
    },
    {
        "district": "ช่องนนทรี",
        "amphoe": "ยานนาวา",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10120,
        "district_code": 101203,
        "amphoe_code": 1012,
        "province_code": 10
    },
    {
        "district": "บางโพงพาง",
        "amphoe": "ยานนาวา",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10120,
        "district_code": 101204,
        "amphoe_code": 1012,
        "province_code": 10
    },
    {
        "district": "ถนนพญาไท",
        "amphoe": "ราชเทวี",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10400,
        "district_code": 101405,
        "amphoe_code": 1037,
        "province_code": 10
    },
    {
        "district": "ถนนเพชรบุรี",
        "amphoe": "ราชเทวี",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10400,
        "district_code": 101402,
        "amphoe_code": 1037,
        "province_code": 10
    },
    {
        "district": "ทุ่งพญาไท",
        "amphoe": "ราชเทวี",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10400,
        "district_code": 101403,
        "amphoe_code": 1037,
        "province_code": 10
    },
    {
        "district": "มักกะสัน",
        "amphoe": "ราชเทวี",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10400,
        "district_code": 101404,
        "amphoe_code": 1037,
        "province_code": 10
    },
    {
        "district": "บางปะกอก",
        "amphoe": "ราษฎร์บูรณะ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10140,
        "district_code": 102402,
        "amphoe_code": 1024,
        "province_code": 10
    },
    {
        "district": "ราษฎร์บูรณะ",
        "amphoe": "ราษฎร์บูรณะ",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10140,
        "district_code": 102401,
        "amphoe_code": 1024,
        "province_code": 10
    },
    {
        "district": "ขุมทอง",
        "amphoe": "ลาดกระบัง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10520,
        "district_code": 101106,
        "amphoe_code": 1011,
        "province_code": 10
    },
    {
        "district": "คลองสองต้นนุ่น",
        "amphoe": "ลาดกระบัง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10520,
        "district_code": 101102,
        "amphoe_code": 1011,
        "province_code": 10
    },
    {
        "district": "คลองสามประเวศ",
        "amphoe": "ลาดกระบัง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10520,
        "district_code": 101103,
        "amphoe_code": 1011,
        "province_code": 10
    },
    {
        "district": "ทับยาว",
        "amphoe": "ลาดกระบัง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10520,
        "district_code": 101105,
        "amphoe_code": 1011,
        "province_code": 10
    },
    {
        "district": "ลาดกระบัง",
        "amphoe": "ลาดกระบัง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10520,
        "district_code": 101101,
        "amphoe_code": 1011,
        "province_code": 10
    },
    {
        "district": "ลำปลาทิว",
        "amphoe": "ลาดกระบัง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10520,
        "district_code": 101104,
        "amphoe_code": 1011,
        "province_code": 10
    },
    {
        "district": "จรเข้บัว",
        "amphoe": "ลาดพร้าว",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10230,
        "district_code": 100607,
        "amphoe_code": 1038,
        "province_code": 10
    },
    {
        "district": "ลาดพร้าว",
        "amphoe": "ลาดพร้าว",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10230,
        "district_code": 100603,
        "amphoe_code": 1038,
        "province_code": 10
    },
    {
        "district": "คลองเจ้าคุณสิงห์",
        "amphoe": "วังทองหลาง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10310,
        "district_code": false,
        "amphoe_code": 1045,
        "province_code": 10
    },
    {
        "district": "พลับพลา",
        "amphoe": "วังทองหลาง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10310,
        "district_code": false,
        "amphoe_code": 1045,
        "province_code": 10
    },
    {
        "district": "วังทองหลาง",
        "amphoe": "วังทองหลาง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10310,
        "district_code": 100602,
        "amphoe_code": 1045,
        "province_code": 10
    },
    {
        "district": "สะพานสอง",
        "amphoe": "วังทองหลาง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10310,
        "district_code": false,
        "amphoe_code": 1045,
        "province_code": 10
    },
    {
        "district": "คลองตันเหนือ",
        "amphoe": "วัฒนา",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10110,
        "district_code": 103305,
        "amphoe_code": 1039,
        "province_code": 10
    },
    {
        "district": "คลองเตยเหนือ",
        "amphoe": "วัฒนา",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10110,
        "district_code": 103304,
        "amphoe_code": 1039,
        "province_code": 10
    },
    {
        "district": "พระโขนงเหนือ",
        "amphoe": "วัฒนา",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10110,
        "district_code": 103306,
        "amphoe_code": 1039,
        "province_code": 10
    },
    {
        "district": "พระโขนงเหนือ",
        "amphoe": "วัฒนา",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10260,
        "district_code": 103306,
        "amphoe_code": 1039,
        "province_code": 10
    },
    {
        "district": "สวนหลวง",
        "amphoe": "สวนหลวง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10250,
        "district_code": 100906,
        "amphoe_code": 1034,
        "province_code": 10
    },
    {
        "district": "อ่อนนุช",
        "amphoe": "สวนหลวง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10250,
        "district_code": 103400,
        "amphoe_code": 1034,
        "province_code": 10
    },
    {
        "district": "สะพานสูง",
        "amphoe": "สะพานสูง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10240,
        "district_code": 100605,
        "amphoe_code": 1044,
        "province_code": 10
    },
    {
        "district": "สะพานสูง",
        "amphoe": "สะพานสูง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10250,
        "district_code": 100605,
        "amphoe_code": 1044,
        "province_code": 10
    },
    {
        "district": "ราษฎร์พัฒนา",
        "amphoe": "สะพานสูง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10240,
        "district_code": 104402,
        "amphoe_code": 1044,
        "province_code": 10
    },
    {
        "district": "ทับช้าง",
        "amphoe": "สะพานสูง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10250,
        "district_code": 104403,
        "amphoe_code": 1044,
        "province_code": 10
    },
    {
        "district": "จักรวรรดิ์",
        "amphoe": "สัมพันธวงศ์",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10100,
        "district_code": false,
        "amphoe_code": 1013,
        "province_code": 10
    },
    {
        "district": "ตลาดน้อย",
        "amphoe": "สัมพันธวงศ์",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10100,
        "district_code": 101303,
        "amphoe_code": 1013,
        "province_code": 10
    },
    {
        "district": "สัมพันธวงศ์",
        "amphoe": "สัมพันธวงศ์",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10100,
        "district_code": 101302,
        "amphoe_code": 1013,
        "province_code": 10
    },
    {
        "district": "ทุ่งมหาเมฆ",
        "amphoe": "สาทร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10120,
        "district_code": 101208,
        "amphoe_code": 1028,
        "province_code": 10
    },
    {
        "district": "ทุ่งวัดดอน",
        "amphoe": "สาทร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10120,
        "district_code": 101201,
        "amphoe_code": 1028,
        "province_code": 10
    },
    {
        "district": "ยานนาวา",
        "amphoe": "สาทร",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10120,
        "district_code": 101202,
        "amphoe_code": 1028,
        "province_code": 10
    },
    {
        "district": "คลองถนน",
        "amphoe": "สายไหม",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10220,
        "district_code": 100503,
        "amphoe_code": 1042,
        "province_code": 10
    },
    {
        "district": "สายไหม",
        "amphoe": "สายไหม",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10220,
        "district_code": 100506,
        "amphoe_code": 1042,
        "province_code": 10
    },
    {
        "district": "ออเงิน",
        "amphoe": "สายไหม",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10220,
        "district_code": 100509,
        "amphoe_code": 1042,
        "province_code": 10
    },
    {
        "district": "กระทุ่มราย",
        "amphoe": "หนองจอก",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10530,
        "district_code": 100301,
        "amphoe_code": 1003,
        "province_code": 10
    },
    {
        "district": "คลองสิบ",
        "amphoe": "หนองจอก",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10530,
        "district_code": 100303,
        "amphoe_code": 1003,
        "province_code": 10
    },
    {
        "district": "คลองสิบสอง",
        "amphoe": "หนองจอก",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10530,
        "district_code": 100304,
        "amphoe_code": 1003,
        "province_code": 10
    },
    {
        "district": "คู้ฝั่งเหนือ",
        "amphoe": "หนองจอก",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10530,
        "district_code": 100306,
        "amphoe_code": 1003,
        "province_code": 10
    },
    {
        "district": "ลำต้อยติ่ง",
        "amphoe": "หนองจอก",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10530,
        "district_code": 100308,
        "amphoe_code": 1003,
        "province_code": 10
    },
    {
        "district": "ลำผักชี",
        "amphoe": "หนองจอก",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10530,
        "district_code": 100307,
        "amphoe_code": 1003,
        "province_code": 10
    },
    {
        "district": "หนองจอก",
        "amphoe": "หนองจอก",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10530,
        "district_code": 100302,
        "amphoe_code": 1003,
        "province_code": 10
    },
    {
        "district": "โคกแฝด",
        "amphoe": "หนองจอก",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10530,
        "district_code": 100305,
        "amphoe_code": 1003,
        "province_code": 10
    },
    {
        "district": "หนองค้างพลู",
        "amphoe": "หนองแขม",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10160,
        "district_code": 102303,
        "amphoe_code": 1023,
        "province_code": 10
    },
    {
        "district": "หนองแขม",
        "amphoe": "หนองแขม",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10160,
        "district_code": 102302,
        "amphoe_code": 1023,
        "province_code": 10
    },
    {
        "district": "ตลาดบางเขน",
        "amphoe": "หลักสี่",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10210,
        "district_code": 100504,
        "amphoe_code": 1041,
        "province_code": 10
    },
    {
        "district": "ทุ่งสองห้อง",
        "amphoe": "หลักสี่",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10210,
        "district_code": 100507,
        "amphoe_code": 1041,
        "province_code": 10
    },
    {
        "district": "บางกะปิ",
        "amphoe": "ห้วยขวาง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10310,
        "district_code": 101702,
        "amphoe_code": 1017,
        "province_code": 10
    },
    {
        "district": "สามเสนนอก",
        "amphoe": "ห้วยขวาง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10310,
        "district_code": 101704,
        "amphoe_code": 1017,
        "province_code": 10
    },
    {
        "district": "ห้วยขวาง",
        "amphoe": "ห้วยขวาง",
        "province": "กรุงเทพมหานคร",
        "zipcode": 10310,
        "district_code": 101701,
        "amphoe_code": 1017,
        "province_code": 10
    },
    {
        "district": "กลอนโด",
        "amphoe": "ด่านมะขามเตี้ย",
        "province": "กาญจนบุรี",
        "zipcode": 71260,
        "district_code": 710115,
        "amphoe_code": 7111,
        "province_code": 71
    },
    {
        "district": "จรเข้เผือก",
        "amphoe": "ด่านมะขามเตี้ย",
        "province": "กาญจนบุรี",
        "zipcode": 71260,
        "district_code": 710114,
        "amphoe_code": 7111,
        "province_code": 71
    },
    {
        "district": "ด่านมะขามเตี้ย",
        "amphoe": "ด่านมะขามเตี้ย",
        "province": "กาญจนบุรี",
        "zipcode": 71260,
        "district_code": 710112,
        "amphoe_code": 7111,
        "province_code": 71
    },
    {
        "district": "หนองไผ่",
        "amphoe": "ด่านมะขามเตี้ย",
        "province": "กาญจนบุรี",
        "zipcode": 71260,
        "district_code": 711104,
        "amphoe_code": 7111,
        "province_code": 71
    },
    {
        "district": "ชะแล",
        "amphoe": "ทองผาภูมิ",
        "province": "กาญจนบุรี",
        "zipcode": 71180,
        "district_code": 710705,
        "amphoe_code": 7107,
        "province_code": 71
    },
    {
        "district": "ท่าขนุน",
        "amphoe": "ทองผาภูมิ",
        "province": "กาญจนบุรี",
        "zipcode": 71180,
        "district_code": 710701,
        "amphoe_code": 7107,
        "province_code": 71
    },
    {
        "district": "ปิล๊อก",
        "amphoe": "ทองผาภูมิ",
        "province": "กาญจนบุรี",
        "zipcode": 71180,
        "district_code": 710702,
        "amphoe_code": 7107,
        "province_code": 71
    },
    {
        "district": "ลิ่นถิ่น",
        "amphoe": "ทองผาภูมิ",
        "province": "กาญจนบุรี",
        "zipcode": 71180,
        "district_code": 710704,
        "amphoe_code": 7107,
        "province_code": 71
    },
    {
        "district": "สหกรณ์นิคม",
        "amphoe": "ทองผาภูมิ",
        "province": "กาญจนบุรี",
        "zipcode": 71180,
        "district_code": 710707,
        "amphoe_code": 7107,
        "province_code": 71
    },
    {
        "district": "หินดาด",
        "amphoe": "ทองผาภูมิ",
        "province": "กาญจนบุรี",
        "zipcode": 71180,
        "district_code": 710703,
        "amphoe_code": 7107,
        "province_code": 71
    },
    {
        "district": "ห้วยเขย่ง",
        "amphoe": "ทองผาภูมิ",
        "province": "กาญจนบุรี",
        "zipcode": 71180,
        "district_code": 710706,
        "amphoe_code": 7107,
        "province_code": 71
    },
    {
        "district": "ดอนขมิ้น",
        "amphoe": "ท่ามะกา",
        "province": "กาญจนบุรี",
        "zipcode": 71120,
        "district_code": 710509,
        "amphoe_code": 7105,
        "province_code": 71
    },
    {
        "district": "ดอนชะเอม",
        "amphoe": "ท่ามะกา",
        "province": "กาญจนบุรี",
        "zipcode": 71130,
        "district_code": 710503,
        "amphoe_code": 7105,
        "province_code": 71
    },
    {
        "district": "ตะคร้ำเอน",
        "amphoe": "ท่ามะกา",
        "province": "กาญจนบุรี",
        "zipcode": 71130,
        "district_code": 710505,
        "amphoe_code": 7105,
        "province_code": 71
    },
    {
        "district": "ท่ามะกา",
        "amphoe": "ท่ามะกา",
        "province": "กาญจนบุรี",
        "zipcode": 71120,
        "district_code": 710506,
        "amphoe_code": 7105,
        "province_code": 71
    },
    {
        "district": "ท่าเรือ",
        "amphoe": "ท่ามะกา",
        "province": "กาญจนบุรี",
        "zipcode": 71130,
        "district_code": 710507,
        "amphoe_code": 7105,
        "province_code": 71
    },
    {
        "district": "ท่าเสา",
        "amphoe": "ท่ามะกา",
        "province": "กาญจนบุรี",
        "zipcode": 71120,
        "district_code": 710202,
        "amphoe_code": 7105,
        "province_code": 71
    },
    {
        "district": "ท่าไม้",
        "amphoe": "ท่ามะกา",
        "province": "กาญจนบุรี",
        "zipcode": 71120,
        "district_code": 710504,
        "amphoe_code": 7105,
        "province_code": 71
    },
    {
        "district": "พงตึก",
        "amphoe": "ท่ามะกา",
        "province": "กาญจนบุรี",
        "zipcode": 71120,
        "district_code": 710501,
        "amphoe_code": 7105,
        "province_code": 71
    },
    {
        "district": "พระแท่น",
        "amphoe": "ท่ามะกา",
        "province": "กาญจนบุรี",
        "zipcode": 71130,
        "district_code": 710512,
        "amphoe_code": 7105,
        "province_code": 71
    },
    {
        "district": "ยางม่วง",
        "amphoe": "ท่ามะกา",
        "province": "กาญจนบุรี",
        "zipcode": 71120,
        "district_code": 710502,
        "amphoe_code": 7105,
        "province_code": 71
    },
    {
        "district": "สนามแย้",
        "amphoe": "ท่ามะกา",
        "province": "กาญจนบุรี",
        "zipcode": 70190,
        "district_code": 710515,
        "amphoe_code": 7105,
        "province_code": 71
    },
    {
        "district": "หนองลาน",
        "amphoe": "ท่ามะกา",
        "province": "กาญจนบุรี",
        "zipcode": 71130,
        "district_code": 710517,
        "amphoe_code": 7105,
        "province_code": 71
    },
    {
        "district": "หวายเหนียว",
        "amphoe": "ท่ามะกา",
        "province": "กาญจนบุรี",
        "zipcode": 71120,
        "district_code": 710513,
        "amphoe_code": 7105,
        "province_code": 71
    },
    {
        "district": "อุโลกสี่หมื่น",
        "amphoe": "ท่ามะกา",
        "province": "กาญจนบุรี",
        "zipcode": 71130,
        "district_code": 710510,
        "amphoe_code": 7105,
        "province_code": 71
    },
    {
        "district": "เขาสามสิบหาบ",
        "amphoe": "ท่ามะกา",
        "province": "กาญจนบุรี",
        "zipcode": 71120,
        "district_code": 710511,
        "amphoe_code": 7105,
        "province_code": 71
    },
    {
        "district": "แสนตอ",
        "amphoe": "ท่ามะกา",
        "province": "กาญจนบุรี",
        "zipcode": 71130,
        "district_code": 710514,
        "amphoe_code": 7105,
        "province_code": 71
    },
    {
        "district": "โคกตะบอง",
        "amphoe": "ท่ามะกา",
        "province": "กาญจนบุรี",
        "zipcode": 71120,
        "district_code": 710508,
        "amphoe_code": 7105,
        "province_code": 71
    },
    {
        "district": "ทุ่งทอง",
        "amphoe": "ท่าม่วง",
        "province": "กาญจนบุรี",
        "zipcode": 71110,
        "district_code": 710606,
        "amphoe_code": 7106,
        "province_code": 71
    },
    {
        "district": "ท่าตะคร้อ",
        "amphoe": "ท่าม่วง",
        "province": "กาญจนบุรี",
        "zipcode": 71130,
        "district_code": 710611,
        "amphoe_code": 7106,
        "province_code": 71
    },
    {
        "district": "ท่าม่วง",
        "amphoe": "ท่าม่วง",
        "province": "กาญจนบุรี",
        "zipcode": 71110,
        "district_code": 710601,
        "amphoe_code": 7106,
        "province_code": 71
    },
    {
        "district": "ท่าล้อ",
        "amphoe": "ท่าม่วง",
        "province": "กาญจนบุรี",
        "zipcode": 71000,
        "district_code": 710604,
        "amphoe_code": 7106,
        "province_code": 71
    },
    {
        "district": "บ้านใหม่",
        "amphoe": "ท่าม่วง",
        "province": "กาญจนบุรี",
        "zipcode": 71110,
        "district_code": 710609,
        "amphoe_code": 7106,
        "province_code": 71
    },
    {
        "district": "พังตรุ",
        "amphoe": "ท่าม่วง",
        "province": "กาญจนบุรี",
        "zipcode": 71110,
        "district_code": 710610,
        "amphoe_code": 7106,
        "province_code": 71
    },
    {
        "district": "ม่วงชุม",
        "amphoe": "ท่าม่วง",
        "province": "กาญจนบุรี",
        "zipcode": 71110,
        "district_code": 710608,
        "amphoe_code": 7106,
        "province_code": 71
    },
    {
        "district": "รางสาลี่",
        "amphoe": "ท่าม่วง",
        "province": "กาญจนบุรี",
        "zipcode": 71110,
        "district_code": 710612,
        "amphoe_code": 7106,
        "province_code": 71
    },
    {
        "district": "วังขนาย",
        "amphoe": "ท่าม่วง",
        "province": "กาญจนบุรี",
        "zipcode": 71110,
        "district_code": 710602,
        "amphoe_code": 7106,
        "province_code": 71
    },
    {
        "district": "วังศาลา",
        "amphoe": "ท่าม่วง",
        "province": "กาญจนบุรี",
        "zipcode": 71110,
        "district_code": 710603,
        "amphoe_code": 7106,
        "province_code": 71
    },
    {
        "district": "หนองขาว",
        "amphoe": "ท่าม่วง",
        "province": "กาญจนบุรี",
        "zipcode": 71110,
        "district_code": 710605,
        "amphoe_code": 7106,
        "province_code": 71
    },
    {
        "district": "หนองตากยา",
        "amphoe": "ท่าม่วง",
        "province": "กาญจนบุรี",
        "zipcode": 71110,
        "district_code": 710613,
        "amphoe_code": 7106,
        "province_code": 71
    },
    {
        "district": "เขาน้อย",
        "amphoe": "ท่าม่วง",
        "province": "กาญจนบุรี",
        "zipcode": 71110,
        "district_code": 710607,
        "amphoe_code": 7106,
        "province_code": 71
    },
    {
        "district": "ช่องด่าน",
        "amphoe": "บ่อพลอย",
        "province": "กาญจนบุรี",
        "zipcode": 71160,
        "district_code": 710308,
        "amphoe_code": 7103,
        "province_code": 71
    },
    {
        "district": "บ่อพลอย",
        "amphoe": "บ่อพลอย",
        "province": "กาญจนบุรี",
        "zipcode": 71160,
        "district_code": 710301,
        "amphoe_code": 7103,
        "province_code": 71
    },
    {
        "district": "หนองกร่าง",
        "amphoe": "บ่อพลอย",
        "province": "กาญจนบุรี",
        "zipcode": 71220,
        "district_code": 710309,
        "amphoe_code": 7103,
        "province_code": 71
    },
    {
        "district": "หนองกุ่ม",
        "amphoe": "บ่อพลอย",
        "province": "กาญจนบุรี",
        "zipcode": 71160,
        "district_code": 710302,
        "amphoe_code": 7103,
        "province_code": 71
    },
    {
        "district": "หนองรี",
        "amphoe": "บ่อพลอย",
        "province": "กาญจนบุรี",
        "zipcode": 71220,
        "district_code": 710303,
        "amphoe_code": 7103,
        "province_code": 71
    },
    {
        "district": "หลุมรัง",
        "amphoe": "บ่อพลอย",
        "province": "กาญจนบุรี",
        "zipcode": 71160,
        "district_code": 710305,
        "amphoe_code": 7103,
        "province_code": 71
    },
    {
        "district": "ดอนตาเพชร",
        "amphoe": "พนมทวน",
        "province": "กาญจนบุรี",
        "zipcode": 71140,
        "district_code": 710912,
        "amphoe_code": 7109,
        "province_code": 71
    },
    {
        "district": "ดอนเจดีย์",
        "amphoe": "พนมทวน",
        "province": "กาญจนบุรี",
        "zipcode": 71140,
        "district_code": 710904,
        "amphoe_code": 7109,
        "province_code": 71
    },
    {
        "district": "ทุ่งสมอ",
        "amphoe": "พนมทวน",
        "province": "กาญจนบุรี",
        "zipcode": 71140,
        "district_code": 710903,
        "amphoe_code": 7109,
        "province_code": 71
    },
    {
        "district": "พนมทวน",
        "amphoe": "พนมทวน",
        "province": "กาญจนบุรี",
        "zipcode": 71140,
        "district_code": 710901,
        "amphoe_code": 7109,
        "province_code": 71
    },
    {
        "district": "พังตรุ",
        "amphoe": "พนมทวน",
        "province": "กาญจนบุรี",
        "zipcode": 71140,
        "district_code": 710610,
        "amphoe_code": 7109,
        "province_code": 71
    },
    {
        "district": "รางหวาย",
        "amphoe": "พนมทวน",
        "province": "กาญจนบุรี",
        "zipcode": 71170,
        "district_code": 710906,
        "amphoe_code": 7109,
        "province_code": 71
    },
    {
        "district": "หนองสาหร่าย",
        "amphoe": "พนมทวน",
        "province": "กาญจนบุรี",
        "zipcode": 71140,
        "district_code": 710911,
        "amphoe_code": 7109,
        "province_code": 71
    },
    {
        "district": "หนองโรง",
        "amphoe": "พนมทวน",
        "province": "กาญจนบุรี",
        "zipcode": 71140,
        "district_code": 710902,
        "amphoe_code": 7109,
        "province_code": 71
    },
    {
        "district": "ด่านแม่แฉลบ",
        "amphoe": "ศรีสวัสดิ์",
        "province": "กาญจนบุรี",
        "zipcode": 71250,
        "district_code": 710402,
        "amphoe_code": 7104,
        "province_code": 71
    },
    {
        "district": "ท่ากระดาน",
        "amphoe": "ศรีสวัสดิ์",
        "province": "กาญจนบุรี",
        "zipcode": 71250,
        "district_code": 710404,
        "amphoe_code": 7104,
        "province_code": 71
    },
    {
        "district": "นาสวน",
        "amphoe": "ศรีสวัสดิ์",
        "province": "กาญจนบุรี",
        "zipcode": 71250,
        "district_code": 710401,
        "amphoe_code": 7104,
        "province_code": 71
    },
    {
        "district": "หนองเป็ด",
        "amphoe": "ศรีสวัสดิ์",
        "province": "กาญจนบุรี",
        "zipcode": 71250,
        "district_code": 710403,
        "amphoe_code": 7104,
        "province_code": 71
    },
    {
        "district": "เขาโจด",
        "amphoe": "ศรีสวัสดิ์",
        "province": "กาญจนบุรี",
        "zipcode": 71220,
        "district_code": 710405,
        "amphoe_code": 7104,
        "province_code": 71
    },
    {
        "district": "แม่กระบุง",
        "amphoe": "ศรีสวัสดิ์",
        "province": "กาญจนบุรี",
        "zipcode": 71250,
        "district_code": 710406,
        "amphoe_code": 7104,
        "province_code": 71
    },
    {
        "district": "ปรังเผล",
        "amphoe": "สังขละบุรี",
        "province": "กาญจนบุรี",
        "zipcode": 71240,
        "district_code": 710802,
        "amphoe_code": 7108,
        "province_code": 71
    },
    {
        "district": "หนองลู",
        "amphoe": "สังขละบุรี",
        "province": "กาญจนบุรี",
        "zipcode": 71240,
        "district_code": 710801,
        "amphoe_code": 7108,
        "province_code": 71
    },
    {
        "district": "ไล่โว่",
        "amphoe": "สังขละบุรี",
        "province": "กาญจนบุรี",
        "zipcode": 71240,
        "district_code": 710803,
        "amphoe_code": 7108,
        "province_code": 71
    },
    {
        "district": "สมเด็จเจริญ",
        "amphoe": "หนองปรือ",
        "province": "กาญจนบุรี",
        "zipcode": 71220,
        "district_code": 710307,
        "amphoe_code": 7112,
        "province_code": 71
    },
    {
        "district": "หนองปรือ",
        "amphoe": "หนองปรือ",
        "province": "กาญจนบุรี",
        "zipcode": 71220,
        "district_code": 710304,
        "amphoe_code": 7112,
        "province_code": 71
    },
    {
        "district": "หนองปลาไหล",
        "amphoe": "หนองปรือ",
        "province": "กาญจนบุรี",
        "zipcode": 71220,
        "district_code": 710306,
        "amphoe_code": 7112,
        "province_code": 71
    },
    {
        "district": "ดอนแสลบ",
        "amphoe": "ห้วยกระเจา",
        "province": "กาญจนบุรี",
        "zipcode": 71170,
        "district_code": 710907,
        "amphoe_code": 7113,
        "province_code": 71
    },
    {
        "district": "วังไผ่",
        "amphoe": "ห้วยกระเจา",
        "province": "กาญจนบุรี",
        "zipcode": 71170,
        "district_code": 710910,
        "amphoe_code": 7113,
        "province_code": 71
    },
    {
        "district": "สระลงเรือ",
        "amphoe": "ห้วยกระเจา",
        "province": "กาญจนบุรี",
        "zipcode": 71170,
        "district_code": 710909,
        "amphoe_code": 7113,
        "province_code": 71
    },
    {
        "district": "ห้วยกระเจา",
        "amphoe": "ห้วยกระเจา",
        "province": "กาญจนบุรี",
        "zipcode": 71170,
        "district_code": 710908,
        "amphoe_code": 7113,
        "province_code": 71
    },
    {
        "district": "ช่องสะเดา",
        "amphoe": "เมืองกาญจนบุรี",
        "province": "กาญจนบุรี",
        "zipcode": 71190,
        "district_code": 710109,
        "amphoe_code": 7101,
        "province_code": 71
    },
    {
        "district": "ท่ามะขาม",
        "amphoe": "เมืองกาญจนบุรี",
        "province": "กาญจนบุรี",
        "zipcode": 71000,
        "district_code": 710104,
        "amphoe_code": 7101,
        "province_code": 71
    },
    {
        "district": "บ้านเก่า",
        "amphoe": "เมืองกาญจนบุรี",
        "province": "กาญจนบุรี",
        "zipcode": 71000,
        "district_code": 710113,
        "amphoe_code": 7101,
        "province_code": 71
    },
    {
        "district": "บ้านเหนือ",
        "amphoe": "เมืองกาญจนบุรี",
        "province": "กาญจนบุรี",
        "zipcode": 71000,
        "district_code": 710101,
        "amphoe_code": 7101,
        "province_code": 71
    },
    {
        "district": "บ้านใต้",
        "amphoe": "เมืองกาญจนบุรี",
        "province": "กาญจนบุรี",
        "zipcode": 71000,
        "district_code": 710102,
        "amphoe_code": 7101,
        "province_code": 71
    },
    {
        "district": "ปากแพรก",
        "amphoe": "เมืองกาญจนบุรี",
        "province": "กาญจนบุรี",
        "zipcode": 71000,
        "district_code": 710103,
        "amphoe_code": 7101,
        "province_code": 71
    },
    {
        "district": "ลาดหญ้า",
        "amphoe": "เมืองกาญจนบุรี",
        "province": "กาญจนบุรี",
        "zipcode": 71190,
        "district_code": 710107,
        "amphoe_code": 7101,
        "province_code": 71
    },
    {
        "district": "วังด้ง",
        "amphoe": "เมืองกาญจนบุรี",
        "province": "กาญจนบุรี",
        "zipcode": 71190,
        "district_code": 710108,
        "amphoe_code": 7101,
        "province_code": 71
    },
    {
        "district": "วังเย็น",
        "amphoe": "เมืองกาญจนบุรี",
        "province": "กาญจนบุรี",
        "zipcode": 71000,
        "district_code": 710116,
        "amphoe_code": 7101,
        "province_code": 71
    },
    {
        "district": "หนองบัว",
        "amphoe": "เมืองกาญจนบุรี",
        "province": "กาญจนบุรี",
        "zipcode": 71190,
        "district_code": 710106,
        "amphoe_code": 7101,
        "province_code": 71
    },
    {
        "district": "หนองหญ้า",
        "amphoe": "เมืองกาญจนบุรี",
        "province": "กาญจนบุรี",
        "zipcode": 71000,
        "district_code": 710110,
        "amphoe_code": 7101,
        "province_code": 71
    },
    {
        "district": "เกาะสำโรง",
        "amphoe": "เมืองกาญจนบุรี",
        "province": "กาญจนบุรี",
        "zipcode": 71000,
        "district_code": 710111,
        "amphoe_code": 7101,
        "province_code": 71
    },
    {
        "district": "แก่งเสี้ยน",
        "amphoe": "เมืองกาญจนบุรี",
        "province": "กาญจนบุรี",
        "zipcode": 71000,
        "district_code": 710105,
        "amphoe_code": 7101,
        "province_code": 71
    },
    {
        "district": "ทุ่งกระบ่ำ",
        "amphoe": "เลาขวัญ",
        "province": "กาญจนบุรี",
        "zipcode": 71210,
        "district_code": 711006,
        "amphoe_code": 7110,
        "province_code": 71
    },
    {
        "district": "หนองนกแก้ว",
        "amphoe": "เลาขวัญ",
        "province": "กาญจนบุรี",
        "zipcode": 71210,
        "district_code": 711005,
        "amphoe_code": 7110,
        "province_code": 71
    },
    {
        "district": "หนองประดู่",
        "amphoe": "เลาขวัญ",
        "province": "กาญจนบุรี",
        "zipcode": 71210,
        "district_code": 711003,
        "amphoe_code": 7110,
        "province_code": 71
    },
    {
        "district": "หนองปลิง",
        "amphoe": "เลาขวัญ",
        "province": "กาญจนบุรี",
        "zipcode": 71210,
        "district_code": 711004,
        "amphoe_code": 7110,
        "province_code": 71
    },
    {
        "district": "หนองฝ้าย",
        "amphoe": "เลาขวัญ",
        "province": "กาญจนบุรี",
        "zipcode": 71210,
        "district_code": 711007,
        "amphoe_code": 7110,
        "province_code": 71
    },
    {
        "district": "หนองโสน",
        "amphoe": "เลาขวัญ",
        "province": "กาญจนบุรี",
        "zipcode": 71210,
        "district_code": 711002,
        "amphoe_code": 7110,
        "province_code": 71
    },
    {
        "district": "เลาขวัญ",
        "amphoe": "เลาขวัญ",
        "province": "กาญจนบุรี",
        "zipcode": 71210,
        "district_code": 711001,
        "amphoe_code": 7110,
        "province_code": 71
    },
    {
        "district": "ท่าเสา",
        "amphoe": "ไทรโยค",
        "province": "กาญจนบุรี",
        "zipcode": 71150,
        "district_code": 710202,
        "amphoe_code": 7102,
        "province_code": 71
    },
    {
        "district": "บ้องตี้",
        "amphoe": "ไทรโยค",
        "province": "กาญจนบุรี",
        "zipcode": 71150,
        "district_code": 710207,
        "amphoe_code": 7102,
        "province_code": 71
    },
    {
        "district": "ลุ่มสุ่ม",
        "amphoe": "ไทรโยค",
        "province": "กาญจนบุรี",
        "zipcode": 71150,
        "district_code": 710201,
        "amphoe_code": 7102,
        "province_code": 71
    },
    {
        "district": "วังกระแจะ",
        "amphoe": "ไทรโยค",
        "province": "กาญจนบุรี",
        "zipcode": 71150,
        "district_code": 710205,
        "amphoe_code": 7102,
        "province_code": 71
    },
    {
        "district": "ศรีมงคล",
        "amphoe": "ไทรโยค",
        "province": "กาญจนบุรี",
        "zipcode": 71150,
        "district_code": 710206,
        "amphoe_code": 7102,
        "province_code": 71
    },
    {
        "district": "สิงห์",
        "amphoe": "ไทรโยค",
        "province": "กาญจนบุรี",
        "zipcode": 71150,
        "district_code": 710203,
        "amphoe_code": 7102,
        "province_code": 71
    },
    {
        "district": "ไทรโยค",
        "amphoe": "ไทรโยค",
        "province": "กาญจนบุรี",
        "zipcode": 71150,
        "district_code": 710204,
        "amphoe_code": 7102,
        "province_code": 71
    },
    {
        "district": "กมลาไสย",
        "amphoe": "กมลาไสย",
        "province": "กาฬสินธุ์",
        "zipcode": 46130,
        "district_code": 460301,
        "amphoe_code": 4603,
        "province_code": 46
    },
    {
        "district": "ดงลิง",
        "amphoe": "กมลาไสย",
        "province": "กาฬสินธุ์",
        "zipcode": 46130,
        "district_code": 460304,
        "amphoe_code": 4603,
        "province_code": 46
    },
    {
        "district": "ธัญญา",
        "amphoe": "กมลาไสย",
        "province": "กาฬสินธุ์",
        "zipcode": 46130,
        "district_code": 460305,
        "amphoe_code": 4603,
        "province_code": 46
    },
    {
        "district": "หนองแปน",
        "amphoe": "กมลาไสย",
        "province": "กาฬสินธุ์",
        "zipcode": 46130,
        "district_code": 460308,
        "amphoe_code": 4603,
        "province_code": 46
    },
    {
        "district": "หลักเมือง",
        "amphoe": "กมลาไสย",
        "province": "กาฬสินธุ์",
        "zipcode": 46130,
        "district_code": 460302,
        "amphoe_code": 4603,
        "province_code": 46
    },
    {
        "district": "เจ้าท่า",
        "amphoe": "กมลาไสย",
        "province": "กาฬสินธุ์",
        "zipcode": 46130,
        "district_code": 460310,
        "amphoe_code": 4603,
        "province_code": 46
    },
    {
        "district": "โคกสมบูรณ์",
        "amphoe": "กมลาไสย",
        "province": "กาฬสินธุ์",
        "zipcode": 46130,
        "district_code": 460311,
        "amphoe_code": 4603,
        "province_code": 46
    },
    {
        "district": "โพนงาม",
        "amphoe": "กมลาไสย",
        "province": "กาฬสินธุ์",
        "zipcode": 46130,
        "district_code": 460303,
        "amphoe_code": 4603,
        "province_code": 46
    },
    {
        "district": "กุดค้าว",
        "amphoe": "กุฉินารายณ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46110,
        "district_code": 460512,
        "amphoe_code": 4605,
        "province_code": 46
    },
    {
        "district": "กุดหว้า",
        "amphoe": "กุฉินารายณ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46110,
        "district_code": 460506,
        "amphoe_code": 4605,
        "province_code": 46
    },
    {
        "district": "จุมจัง",
        "amphoe": "กุฉินารายณ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46110,
        "district_code": 460504,
        "amphoe_code": 4605,
        "province_code": 46
    },
    {
        "district": "นาขาม",
        "amphoe": "กุฉินารายณ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46110,
        "district_code": 460508,
        "amphoe_code": 4605,
        "province_code": 46
    },
    {
        "district": "นาโก",
        "amphoe": "กุฉินารายณ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46110,
        "district_code": 460510,
        "amphoe_code": 4605,
        "province_code": 46
    },
    {
        "district": "บัวขาว",
        "amphoe": "กุฉินารายณ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46110,
        "district_code": 460501,
        "amphoe_code": 4605,
        "province_code": 46
    },
    {
        "district": "สมสะอาด",
        "amphoe": "กุฉินารายณ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46110,
        "district_code": 460511,
        "amphoe_code": 4605,
        "province_code": 46
    },
    {
        "district": "สามขา",
        "amphoe": "กุฉินารายณ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46110,
        "district_code": 460507,
        "amphoe_code": 4605,
        "province_code": 46
    },
    {
        "district": "หนองห้าง",
        "amphoe": "กุฉินารายณ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46110,
        "district_code": 460509,
        "amphoe_code": 4605,
        "province_code": 46
    },
    {
        "district": "เหล่าใหญ่",
        "amphoe": "กุฉินารายณ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46110,
        "district_code": 460503,
        "amphoe_code": 4605,
        "province_code": 46
    },
    {
        "district": "เหล่าไฮงาม",
        "amphoe": "กุฉินารายณ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46110,
        "district_code": 460505,
        "amphoe_code": 4605,
        "province_code": 46
    },
    {
        "district": "แจนแลน",
        "amphoe": "กุฉินารายณ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46110,
        "district_code": 460502,
        "amphoe_code": 4605,
        "province_code": 46
    },
    {
        "district": "ดินจี่",
        "amphoe": "คำม่วง",
        "province": "กาฬสินธุ์",
        "zipcode": 46180,
        "district_code": 461005,
        "amphoe_code": 4610,
        "province_code": 46
    },
    {
        "district": "ทุ่งคลอง",
        "amphoe": "คำม่วง",
        "province": "กาฬสินธุ์",
        "zipcode": 46180,
        "district_code": 460997,
        "amphoe_code": 4610,
        "province_code": 46
    },
    {
        "district": "นาทัน",
        "amphoe": "คำม่วง",
        "province": "กาฬสินธุ์",
        "zipcode": 46180,
        "district_code": 461007,
        "amphoe_code": 4610,
        "province_code": 46
    },
    {
        "district": "นาบอน",
        "amphoe": "คำม่วง",
        "province": "กาฬสินธุ์",
        "zipcode": 46180,
        "district_code": 461006,
        "amphoe_code": 4610,
        "province_code": 46
    },
    {
        "district": "เนินยาง",
        "amphoe": "คำม่วง",
        "province": "กาฬสินธุ์",
        "zipcode": 46180,
        "district_code": 461009,
        "amphoe_code": 4610,
        "province_code": 46
    },
    {
        "district": "โพน",
        "amphoe": "คำม่วง",
        "province": "กาฬสินธุ์",
        "zipcode": 46180,
        "district_code": 460999,
        "amphoe_code": 4610,
        "province_code": 46
    },
    {
        "district": "ฆ้องชัยพัฒนา",
        "amphoe": "ฆ้องชัย",
        "province": "กาฬสินธุ์",
        "zipcode": 46130,
        "district_code": 460313,
        "amphoe_code": 4618,
        "province_code": 46
    },
    {
        "district": "ลำชี",
        "amphoe": "ฆ้องชัย",
        "province": "กาฬสินธุ์",
        "zipcode": 46130,
        "district_code": 460307,
        "amphoe_code": 4618,
        "province_code": 46
    },
    {
        "district": "เหล่ากลาง",
        "amphoe": "ฆ้องชัย",
        "province": "กาฬสินธุ์",
        "zipcode": 46130,
        "district_code": 461802,
        "amphoe_code": 4618,
        "province_code": 46
    },
    {
        "district": "โคกสะอาด",
        "amphoe": "ฆ้องชัย",
        "province": "กาฬสินธุ์",
        "zipcode": 46130,
        "district_code": 460309,
        "amphoe_code": 4618,
        "province_code": 46
    },
    {
        "district": "โนนศิลาเลิง",
        "amphoe": "ฆ้องชัย",
        "province": "กาฬสินธุ์",
        "zipcode": 46130,
        "district_code": 461804,
        "amphoe_code": 4618,
        "province_code": 46
    },
    {
        "district": "ดงพยุง",
        "amphoe": "ดอนจาน",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460112,
        "amphoe_code": 4617,
        "province_code": 46
    },
    {
        "district": "ดอนจาน",
        "amphoe": "ดอนจาน",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460114,
        "amphoe_code": 4617,
        "province_code": 46
    },
    {
        "district": "นาจำปา",
        "amphoe": "ดอนจาน",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460118,
        "amphoe_code": 4617,
        "province_code": 46
    },
    {
        "district": "ม่วงนา",
        "amphoe": "ดอนจาน",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460110,
        "amphoe_code": 4617,
        "province_code": 46
    },
    {
        "district": "สะอาดไชยศรี",
        "amphoe": "ดอนจาน",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460122,
        "amphoe_code": 4617,
        "province_code": 46
    },
    {
        "district": "กุงเก่า",
        "amphoe": "ท่าคันโท",
        "province": "กาฬสินธุ์",
        "zipcode": 46190,
        "district_code": 461102,
        "amphoe_code": 4611,
        "province_code": 46
    },
    {
        "district": "กุดจิก",
        "amphoe": "ท่าคันโท",
        "province": "กาฬสินธุ์",
        "zipcode": 46190,
        "district_code": 461104,
        "amphoe_code": 4611,
        "province_code": 46
    },
    {
        "district": "ดงสมบูรณ์",
        "amphoe": "ท่าคันโท",
        "province": "กาฬสินธุ์",
        "zipcode": 46190,
        "district_code": 461106,
        "amphoe_code": 4611,
        "province_code": 46
    },
    {
        "district": "ท่าคันโท",
        "amphoe": "ท่าคันโท",
        "province": "กาฬสินธุ์",
        "zipcode": 46190,
        "district_code": 461101,
        "amphoe_code": 4611,
        "province_code": 46
    },
    {
        "district": "นาตาล",
        "amphoe": "ท่าคันโท",
        "province": "กาฬสินธุ์",
        "zipcode": 46190,
        "district_code": 461105,
        "amphoe_code": 4611,
        "province_code": 46
    },
    {
        "district": "ยางอู้ม",
        "amphoe": "ท่าคันโท",
        "province": "กาฬสินธุ์",
        "zipcode": 46190,
        "district_code": 461103,
        "amphoe_code": 4611,
        "province_code": 46
    },
    {
        "district": "นาคู",
        "amphoe": "นาคู",
        "province": "กาฬสินธุ์",
        "zipcode": 46160,
        "district_code": 460605,
        "amphoe_code": 4616,
        "province_code": 46
    },
    {
        "district": "บ่อแก้ว",
        "amphoe": "นาคู",
        "province": "กาฬสินธุ์",
        "zipcode": 46160,
        "district_code": 460607,
        "amphoe_code": 4616,
        "province_code": 46
    },
    {
        "district": "ภูแล่นช้าง",
        "amphoe": "นาคู",
        "province": "กาฬสินธุ์",
        "zipcode": 46160,
        "district_code": 460604,
        "amphoe_code": 4616,
        "province_code": 46
    },
    {
        "district": "สายนาวัง",
        "amphoe": "นาคู",
        "province": "กาฬสินธุ์",
        "zipcode": 46160,
        "district_code": 460609,
        "amphoe_code": 4616,
        "province_code": 46
    },
    {
        "district": "โนนนาจาน",
        "amphoe": "นาคู",
        "province": "กาฬสินธุ์",
        "zipcode": 46160,
        "district_code": 460610,
        "amphoe_code": 4616,
        "province_code": 46
    },
    {
        "district": "นามน",
        "amphoe": "นามน",
        "province": "กาฬสินธุ์",
        "zipcode": 46230,
        "district_code": 460198,
        "amphoe_code": 4602,
        "province_code": 46
    },
    {
        "district": "ยอดแกง",
        "amphoe": "นามน",
        "province": "กาฬสินธุ์",
        "zipcode": 46230,
        "district_code": 460199,
        "amphoe_code": 4602,
        "province_code": 46
    },
    {
        "district": "สงเปลือย",
        "amphoe": "นามน",
        "province": "กาฬสินธุ์",
        "zipcode": 46230,
        "district_code": 460203,
        "amphoe_code": 4602,
        "province_code": 46
    },
    {
        "district": "หนองบัว",
        "amphoe": "นามน",
        "province": "กาฬสินธุ์",
        "zipcode": 46230,
        "district_code": 460205,
        "amphoe_code": 4602,
        "province_code": 46
    },
    {
        "district": "หลักเหลี่ยม",
        "amphoe": "นามน",
        "province": "กาฬสินธุ์",
        "zipcode": 46230,
        "district_code": 460204,
        "amphoe_code": 4602,
        "province_code": 46
    },
    {
        "district": "คลองขาม",
        "amphoe": "ยางตลาด",
        "province": "กาฬสินธุ์",
        "zipcode": 46120,
        "district_code": 460711,
        "amphoe_code": 4607,
        "province_code": 46
    },
    {
        "district": "ดอนสมบูรณ์",
        "amphoe": "ยางตลาด",
        "province": "กาฬสินธุ์",
        "zipcode": 46120,
        "district_code": 460709,
        "amphoe_code": 4607,
        "province_code": 46
    },
    {
        "district": "นาดี",
        "amphoe": "ยางตลาด",
        "province": "กาฬสินธุ์",
        "zipcode": 46120,
        "district_code": 460713,
        "amphoe_code": 4607,
        "province_code": 46
    },
    {
        "district": "นาเชือก",
        "amphoe": "ยางตลาด",
        "province": "กาฬสินธุ์",
        "zipcode": 46120,
        "district_code": 460710,
        "amphoe_code": 4607,
        "province_code": 46
    },
    {
        "district": "บัวบาน",
        "amphoe": "ยางตลาด",
        "province": "กาฬสินธุ์",
        "zipcode": 46120,
        "district_code": 460704,
        "amphoe_code": 4607,
        "province_code": 46
    },
    {
        "district": "ยางตลาด",
        "amphoe": "ยางตลาด",
        "province": "กาฬสินธุ์",
        "zipcode": 46120,
        "district_code": 460701,
        "amphoe_code": 4607,
        "province_code": 46
    },
    {
        "district": "หนองตอกแป้น",
        "amphoe": "ยางตลาด",
        "province": "กาฬสินธุ์",
        "zipcode": 46120,
        "district_code": 460715,
        "amphoe_code": 4607,
        "province_code": 46
    },
    {
        "district": "หนองอิเฒ่า",
        "amphoe": "ยางตลาด",
        "province": "กาฬสินธุ์",
        "zipcode": 46120,
        "district_code": 460708,
        "amphoe_code": 4607,
        "province_code": 46
    },
    {
        "district": "หัวงัว",
        "amphoe": "ยางตลาด",
        "province": "กาฬสินธุ์",
        "zipcode": 46120,
        "district_code": 460702,
        "amphoe_code": 4607,
        "province_code": 46
    },
    {
        "district": "หัวนาคำ",
        "amphoe": "ยางตลาด",
        "province": "กาฬสินธุ์",
        "zipcode": 46120,
        "district_code": 460707,
        "amphoe_code": 4607,
        "province_code": 46
    },
    {
        "district": "อิตื้อ",
        "amphoe": "ยางตลาด",
        "province": "กาฬสินธุ์",
        "zipcode": 46120,
        "district_code": 460706,
        "amphoe_code": 4607,
        "province_code": 46
    },
    {
        "district": "อุ่มเม่า",
        "amphoe": "ยางตลาด",
        "province": "กาฬสินธุ์",
        "zipcode": 46120,
        "district_code": 460703,
        "amphoe_code": 4607,
        "province_code": 46
    },
    {
        "district": "เขาพระนอน",
        "amphoe": "ยางตลาด",
        "province": "กาฬสินธุ์",
        "zipcode": 46120,
        "district_code": 460712,
        "amphoe_code": 4607,
        "province_code": 46
    },
    {
        "district": "เว่อ",
        "amphoe": "ยางตลาด",
        "province": "กาฬสินธุ์",
        "zipcode": 46120,
        "district_code": 460705,
        "amphoe_code": 4607,
        "province_code": 46
    },
    {
        "district": "โนนสูง",
        "amphoe": "ยางตลาด",
        "province": "กาฬสินธุ์",
        "zipcode": 46120,
        "district_code": 460714,
        "amphoe_code": 4607,
        "province_code": 46
    },
    {
        "district": "ร่องคำ",
        "amphoe": "ร่องคำ",
        "province": "กาฬสินธุ์",
        "zipcode": 46210,
        "district_code": 460401,
        "amphoe_code": 4604,
        "province_code": 46
    },
    {
        "district": "สามัคคี",
        "amphoe": "ร่องคำ",
        "province": "กาฬสินธุ์",
        "zipcode": 46210,
        "district_code": 460402,
        "amphoe_code": 4604,
        "province_code": 46
    },
    {
        "district": "เหล่าอ้อย",
        "amphoe": "ร่องคำ",
        "province": "กาฬสินธุ์",
        "zipcode": 46210,
        "district_code": 460403,
        "amphoe_code": 4604,
        "province_code": 46
    },
    {
        "district": "ผาเสวย",
        "amphoe": "สมเด็จ",
        "province": "กาฬสินธุ์",
        "zipcode": 46150,
        "district_code": 461306,
        "amphoe_code": 4613,
        "province_code": 46
    },
    {
        "district": "มหาไชย",
        "amphoe": "สมเด็จ",
        "province": "กาฬสินธุ์",
        "zipcode": 46150,
        "district_code": 461304,
        "amphoe_code": 4613,
        "province_code": 46
    },
    {
        "district": "ลำห้วยหลัว",
        "amphoe": "สมเด็จ",
        "province": "กาฬสินธุ์",
        "zipcode": 46150,
        "district_code": 461308,
        "amphoe_code": 4613,
        "province_code": 46
    },
    {
        "district": "ศรีสมเด็จ",
        "amphoe": "สมเด็จ",
        "province": "กาฬสินธุ์",
        "zipcode": 46150,
        "district_code": 461307,
        "amphoe_code": 4613,
        "province_code": 46
    },
    {
        "district": "สมเด็จ",
        "amphoe": "สมเด็จ",
        "province": "กาฬสินธุ์",
        "zipcode": 46150,
        "district_code": 461301,
        "amphoe_code": 4613,
        "province_code": 46
    },
    {
        "district": "หนองแวง",
        "amphoe": "สมเด็จ",
        "province": "กาฬสินธุ์",
        "zipcode": 46150,
        "district_code": 461302,
        "amphoe_code": 4613,
        "province_code": 46
    },
    {
        "district": "หมูม่น",
        "amphoe": "สมเด็จ",
        "province": "กาฬสินธุ์",
        "zipcode": 46150,
        "district_code": 461305,
        "amphoe_code": 4613,
        "province_code": 46
    },
    {
        "district": "แซงบาดาล",
        "amphoe": "สมเด็จ",
        "province": "กาฬสินธุ์",
        "zipcode": 46150,
        "district_code": 461303,
        "amphoe_code": 4613,
        "province_code": 46
    },
    {
        "district": "นามะเขือ",
        "amphoe": "สหัสขันธ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46140,
        "district_code": 460903,
        "amphoe_code": 4609,
        "province_code": 46
    },
    {
        "district": "นิคม",
        "amphoe": "สหัสขันธ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46140,
        "district_code": 460905,
        "amphoe_code": 4609,
        "province_code": 46
    },
    {
        "district": "ภูสิงห์",
        "amphoe": "สหัสขันธ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46140,
        "district_code": 460901,
        "amphoe_code": 4609,
        "province_code": 46
    },
    {
        "district": "สหัสขันธ์",
        "amphoe": "สหัสขันธ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46140,
        "district_code": 460902,
        "amphoe_code": 4609,
        "province_code": 46
    },
    {
        "district": "โนนน้ำเกลี้ยง",
        "amphoe": "สหัสขันธ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46140,
        "district_code": 460908,
        "amphoe_code": 4609,
        "province_code": 46
    },
    {
        "district": "โนนบุรี",
        "amphoe": "สหัสขันธ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46140,
        "district_code": 460907,
        "amphoe_code": 4609,
        "province_code": 46
    },
    {
        "district": "โนนศิลา",
        "amphoe": "สหัสขันธ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46140,
        "district_code": 460312,
        "amphoe_code": 4609,
        "province_code": 46
    },
    {
        "district": "โนนแหลมทอง",
        "amphoe": "สหัสขันธ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46140,
        "district_code": 460906,
        "amphoe_code": 4609,
        "province_code": 46
    },
    {
        "district": "คำสร้างเที่ยง",
        "amphoe": "สามชัย",
        "province": "กาฬสินธุ์",
        "zipcode": 46180,
        "district_code": 461008,
        "amphoe_code": 4615,
        "province_code": 46
    },
    {
        "district": "สำราญ",
        "amphoe": "สามชัย",
        "province": "กาฬสินธุ์",
        "zipcode": 46180,
        "district_code": 460998,
        "amphoe_code": 4615,
        "province_code": 46
    },
    {
        "district": "สำราญใต้",
        "amphoe": "สามชัย",
        "province": "กาฬสินธุ์",
        "zipcode": 46180,
        "district_code": 461004,
        "amphoe_code": 4615,
        "province_code": 46
    },
    {
        "district": "หนองช้าง",
        "amphoe": "สามชัย",
        "province": "กาฬสินธุ์",
        "zipcode": 46180,
        "district_code": 461010,
        "amphoe_code": 4615,
        "province_code": 46
    },
    {
        "district": "ดงมูล",
        "amphoe": "หนองกุงศรี",
        "province": "กาฬสินธุ์",
        "zipcode": 46220,
        "district_code": 461207,
        "amphoe_code": 4612,
        "province_code": 46
    },
    {
        "district": "ลำหนองแสน",
        "amphoe": "หนองกุงศรี",
        "province": "กาฬสินธุ์",
        "zipcode": 46220,
        "district_code": 461208,
        "amphoe_code": 4612,
        "province_code": 46
    },
    {
        "district": "หนองกุงศรี",
        "amphoe": "หนองกุงศรี",
        "province": "กาฬสินธุ์",
        "zipcode": 46220,
        "district_code": 461201,
        "amphoe_code": 4612,
        "province_code": 46
    },
    {
        "district": "หนองบัว",
        "amphoe": "หนองกุงศรี",
        "province": "กาฬสินธุ์",
        "zipcode": 46220,
        "district_code": 460205,
        "amphoe_code": 4612,
        "province_code": 46
    },
    {
        "district": "หนองสรวง",
        "amphoe": "หนองกุงศรี",
        "province": "กาฬสินธุ์",
        "zipcode": 46220,
        "district_code": 461204,
        "amphoe_code": 4612,
        "province_code": 46
    },
    {
        "district": "หนองหิน",
        "amphoe": "หนองกุงศรี",
        "province": "กาฬสินธุ์",
        "zipcode": 46220,
        "district_code": 461209,
        "amphoe_code": 4612,
        "province_code": 46
    },
    {
        "district": "หนองใหญ่",
        "amphoe": "หนองกุงศรี",
        "province": "กาฬสินธุ์",
        "zipcode": 46220,
        "district_code": 461206,
        "amphoe_code": 4612,
        "province_code": 46
    },
    {
        "district": "เสาเล้า",
        "amphoe": "หนองกุงศรี",
        "province": "กาฬสินธุ์",
        "zipcode": 46220,
        "district_code": 461205,
        "amphoe_code": 4612,
        "province_code": 46
    },
    {
        "district": "โคกเครือ",
        "amphoe": "หนองกุงศรี",
        "province": "กาฬสินธุ์",
        "zipcode": 46220,
        "district_code": 461198,
        "amphoe_code": 4612,
        "province_code": 46
    },
    {
        "district": "คำบง",
        "amphoe": "ห้วยผึ้ง",
        "province": "กาฬสินธุ์",
        "zipcode": 46240,
        "district_code": 461401,
        "amphoe_code": 4614,
        "province_code": 46
    },
    {
        "district": "นิคมห้วยผึ้ง",
        "amphoe": "ห้วยผึ้ง",
        "province": "กาฬสินธุ์",
        "zipcode": 46240,
        "district_code": 461403,
        "amphoe_code": 4614,
        "province_code": 46
    },
    {
        "district": "หนองอีบุตร",
        "amphoe": "ห้วยผึ้ง",
        "province": "กาฬสินธุ์",
        "zipcode": 46240,
        "district_code": 461404,
        "amphoe_code": 4614,
        "province_code": 46
    },
    {
        "district": "ไค้นุ่น",
        "amphoe": "ห้วยผึ้ง",
        "province": "กาฬสินธุ์",
        "zipcode": 46240,
        "district_code": 461402,
        "amphoe_code": 4614,
        "province_code": 46
    },
    {
        "district": "กุดโดน",
        "amphoe": "ห้วยเม็ก",
        "province": "กาฬสินธุ์",
        "zipcode": 46170,
        "district_code": 460803,
        "amphoe_code": 4608,
        "province_code": 46
    },
    {
        "district": "คำเหมือดแก้ว",
        "amphoe": "ห้วยเม็ก",
        "province": "กาฬสินธุ์",
        "zipcode": 46170,
        "district_code": 460807,
        "amphoe_code": 4608,
        "province_code": 46
    },
    {
        "district": "คำใหญ่",
        "amphoe": "ห้วยเม็ก",
        "province": "กาฬสินธุ์",
        "zipcode": 46170,
        "district_code": 460802,
        "amphoe_code": 4608,
        "province_code": 46
    },
    {
        "district": "ทรายทอง",
        "amphoe": "ห้วยเม็ก",
        "province": "กาฬสินธุ์",
        "zipcode": 46170,
        "district_code": 460809,
        "amphoe_code": 4608,
        "province_code": 46
    },
    {
        "district": "บึงนาเรียง",
        "amphoe": "ห้วยเม็ก",
        "province": "กาฬสินธุ์",
        "zipcode": 46170,
        "district_code": 460804,
        "amphoe_code": 4608,
        "province_code": 46
    },
    {
        "district": "พิมูล",
        "amphoe": "ห้วยเม็ก",
        "province": "กาฬสินธุ์",
        "zipcode": 46170,
        "district_code": 460806,
        "amphoe_code": 4608,
        "province_code": 46
    },
    {
        "district": "หัวหิน",
        "amphoe": "ห้วยเม็ก",
        "province": "กาฬสินธุ์",
        "zipcode": 46170,
        "district_code": 460805,
        "amphoe_code": 4608,
        "province_code": 46
    },
    {
        "district": "ห้วยเม็ก",
        "amphoe": "ห้วยเม็ก",
        "province": "กาฬสินธุ์",
        "zipcode": 46170,
        "district_code": 460801,
        "amphoe_code": 4608,
        "province_code": 46
    },
    {
        "district": "โนนสะอาด",
        "amphoe": "ห้วยเม็ก",
        "province": "กาฬสินธุ์",
        "zipcode": 46170,
        "district_code": 460808,
        "amphoe_code": 4608,
        "province_code": 46
    },
    {
        "district": "กุดปลาค้าว",
        "amphoe": "เขาวง",
        "province": "กาฬสินธุ์",
        "zipcode": 46160,
        "district_code": 460611,
        "amphoe_code": 4606,
        "province_code": 46
    },
    {
        "district": "กุดสิมคุ้มใหม่",
        "amphoe": "เขาวง",
        "province": "กาฬสินธุ์",
        "zipcode": 46160,
        "district_code": 460606,
        "amphoe_code": 4606,
        "province_code": 46
    },
    {
        "district": "คุ้มเก่า",
        "amphoe": "เขาวง",
        "province": "กาฬสินธุ์",
        "zipcode": 46160,
        "district_code": 460601,
        "amphoe_code": 4606,
        "province_code": 46
    },
    {
        "district": "สงเปลือย",
        "amphoe": "เขาวง",
        "province": "กาฬสินธุ์",
        "zipcode": 46160,
        "district_code": 460203,
        "amphoe_code": 4606,
        "province_code": 46
    },
    {
        "district": "สระพังทอง",
        "amphoe": "เขาวง",
        "province": "กาฬสินธุ์",
        "zipcode": 46160,
        "district_code": 460608,
        "amphoe_code": 4606,
        "province_code": 46
    },
    {
        "district": "หนองผือ",
        "amphoe": "เขาวง",
        "province": "กาฬสินธุ์",
        "zipcode": 46160,
        "district_code": 460603,
        "amphoe_code": 4606,
        "province_code": 46
    },
    {
        "district": "กลางหมื่น",
        "amphoe": "เมืองกาฬสินธุ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460116,
        "amphoe_code": 4601,
        "province_code": 46
    },
    {
        "district": "กาฬสินธุ์",
        "amphoe": "เมืองกาฬสินธุ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460101,
        "amphoe_code": 4601,
        "province_code": 46
    },
    {
        "district": "ขมิ้น",
        "amphoe": "เมืองกาฬสินธุ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460117,
        "amphoe_code": 4601,
        "province_code": 46
    },
    {
        "district": "นาจารย์",
        "amphoe": "เมืองกาฬสินธุ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460120,
        "amphoe_code": 4601,
        "province_code": 46
    },
    {
        "district": "บึงวิชัย",
        "amphoe": "เมืองกาฬสินธุ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460108,
        "amphoe_code": 4601,
        "province_code": 46
    },
    {
        "district": "ภูดิน",
        "amphoe": "เมืองกาฬสินธุ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460113,
        "amphoe_code": 4601,
        "province_code": 46
    },
    {
        "district": "ภูปอ",
        "amphoe": "เมืองกาฬสินธุ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460111,
        "amphoe_code": 4601,
        "province_code": 46
    },
    {
        "district": "ลำคลอง",
        "amphoe": "เมืองกาฬสินธุ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460121,
        "amphoe_code": 4601,
        "province_code": 46
    },
    {
        "district": "ลำปาว",
        "amphoe": "เมืองกาฬสินธุ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460105,
        "amphoe_code": 4601,
        "province_code": 46
    },
    {
        "district": "ลำพาน",
        "amphoe": "เมืองกาฬสินธุ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460106,
        "amphoe_code": 4601,
        "province_code": 46
    },
    {
        "district": "หนองกุง",
        "amphoe": "เมืองกาฬสินธุ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460115,
        "amphoe_code": 4601,
        "province_code": 46
    },
    {
        "district": "หลุบ",
        "amphoe": "เมืองกาฬสินธุ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460103,
        "amphoe_code": 4601,
        "province_code": 46
    },
    {
        "district": "ห้วยโพธิ์",
        "amphoe": "เมืองกาฬสินธุ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460109,
        "amphoe_code": 4601,
        "province_code": 46
    },
    {
        "district": "เชียงเครือ",
        "amphoe": "เมืองกาฬสินธุ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460107,
        "amphoe_code": 4601,
        "province_code": 46
    },
    {
        "district": "เหนือ",
        "amphoe": "เมืองกาฬสินธุ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460102,
        "amphoe_code": 4601,
        "province_code": 46
    },
    {
        "district": "โพนทอง",
        "amphoe": "เมืองกาฬสินธุ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460119,
        "amphoe_code": 4601,
        "province_code": 46
    },
    {
        "district": "ไผ่",
        "amphoe": "เมืองกาฬสินธุ์",
        "province": "กาฬสินธุ์",
        "zipcode": 46000,
        "district_code": 460104,
        "amphoe_code": 4601,
        "province_code": 46
    },
    {
        "district": "ดอนแตง",
        "amphoe": "ขาณุวรลักษบุรี",
        "province": "กำแพงเพชร",
        "zipcode": 62140,
        "district_code": 620408,
        "amphoe_code": 6204,
        "province_code": 62
    },
    {
        "district": "บ่อถ้ำ",
        "amphoe": "ขาณุวรลักษบุรี",
        "province": "กำแพงเพชร",
        "zipcode": 62140,
        "district_code": 620407,
        "amphoe_code": 6204,
        "province_code": 62
    },
    {
        "district": "ปางมะค่า",
        "amphoe": "ขาณุวรลักษบุรี",
        "province": "กำแพงเพชร",
        "zipcode": 62140,
        "district_code": 620411,
        "amphoe_code": 6204,
        "province_code": 62
    },
    {
        "district": "ป่าพุทรา",
        "amphoe": "ขาณุวรลักษบุรี",
        "province": "กำแพงเพชร",
        "zipcode": 62130,
        "district_code": 620404,
        "amphoe_code": 6204,
        "province_code": 62
    },
    {
        "district": "ยางสูง",
        "amphoe": "ขาณุวรลักษบุรี",
        "province": "กำแพงเพชร",
        "zipcode": 62130,
        "district_code": 620403,
        "amphoe_code": 6204,
        "province_code": 62
    },
    {
        "district": "วังชะพลู",
        "amphoe": "ขาณุวรลักษบุรี",
        "province": "กำแพงเพชร",
        "zipcode": 62140,
        "district_code": 620409,
        "amphoe_code": 6204,
        "province_code": 62
    },
    {
        "district": "วังหามแห",
        "amphoe": "ขาณุวรลักษบุรี",
        "province": "กำแพงเพชร",
        "zipcode": 62140,
        "district_code": 620412,
        "amphoe_code": 6204,
        "province_code": 62
    },
    {
        "district": "สลกบาตร",
        "amphoe": "ขาณุวรลักษบุรี",
        "province": "กำแพงเพชร",
        "zipcode": 62140,
        "district_code": 620406,
        "amphoe_code": 6204,
        "province_code": 62
    },
    {
        "district": "เกาะตาล",
        "amphoe": "ขาณุวรลักษบุรี",
        "province": "กำแพงเพชร",
        "zipcode": 62130,
        "district_code": 620413,
        "amphoe_code": 6204,
        "province_code": 62
    },
    {
        "district": "แสนตอ",
        "amphoe": "ขาณุวรลักษบุรี",
        "province": "กำแพงเพชร",
        "zipcode": 62130,
        "district_code": 620405,
        "amphoe_code": 6204,
        "province_code": 62
    },
    {
        "district": "โค้งไผ่",
        "amphoe": "ขาณุวรลักษบุรี",
        "province": "กำแพงเพชร",
        "zipcode": 62140,
        "district_code": 620410,
        "amphoe_code": 6204,
        "province_code": 62
    },
    {
        "district": "คลองขลุง",
        "amphoe": "คลองขลุง",
        "province": "กำแพงเพชร",
        "zipcode": 62120,
        "district_code": 620501,
        "amphoe_code": 6205,
        "province_code": 62
    },
    {
        "district": "คลองสมบูรณ์",
        "amphoe": "คลองขลุง",
        "province": "กำแพงเพชร",
        "zipcode": 62120,
        "district_code": 620516,
        "amphoe_code": 6205,
        "province_code": 62
    },
    {
        "district": "ท่าพุทรา",
        "amphoe": "คลองขลุง",
        "province": "กำแพงเพชร",
        "zipcode": 62120,
        "district_code": 620504,
        "amphoe_code": 6205,
        "province_code": 62
    },
    {
        "district": "ท่ามะเขือ",
        "amphoe": "คลองขลุง",
        "province": "กำแพงเพชร",
        "zipcode": 62120,
        "district_code": 620502,
        "amphoe_code": 6205,
        "province_code": 62
    },
    {
        "district": "วังบัว",
        "amphoe": "คลองขลุง",
        "province": "กำแพงเพชร",
        "zipcode": 62120,
        "district_code": 620513,
        "amphoe_code": 6205,
        "province_code": 62
    },
    {
        "district": "วังยาง",
        "amphoe": "คลองขลุง",
        "province": "กำแพงเพชร",
        "zipcode": 62120,
        "district_code": 620506,
        "amphoe_code": 6205,
        "province_code": 62
    },
    {
        "district": "วังแขม",
        "amphoe": "คลองขลุง",
        "province": "กำแพงเพชร",
        "zipcode": 62120,
        "district_code": 620507,
        "amphoe_code": 6205,
        "province_code": 62
    },
    {
        "district": "วังไทร",
        "amphoe": "คลองขลุง",
        "province": "กำแพงเพชร",
        "zipcode": 62120,
        "district_code": 620509,
        "amphoe_code": 6205,
        "province_code": 62
    },
    {
        "district": "หัวถนน",
        "amphoe": "คลองขลุง",
        "province": "กำแพงเพชร",
        "zipcode": 62120,
        "district_code": 620508,
        "amphoe_code": 6205,
        "province_code": 62
    },
    {
        "district": "แม่ลาด",
        "amphoe": "คลองขลุง",
        "province": "กำแพงเพชร",
        "zipcode": 62120,
        "district_code": 620505,
        "amphoe_code": 6205,
        "province_code": 62
    },
    {
        "district": "คลองน้ำไหล",
        "amphoe": "คลองลาน",
        "province": "กำแพงเพชร",
        "zipcode": 62180,
        "district_code": 620301,
        "amphoe_code": 6203,
        "province_code": 62
    },
    {
        "district": "คลองลานพัฒนา",
        "amphoe": "คลองลาน",
        "province": "กำแพงเพชร",
        "zipcode": 62180,
        "district_code": 620303,
        "amphoe_code": 6203,
        "province_code": 62
    },
    {
        "district": "สักงาม",
        "amphoe": "คลองลาน",
        "province": "กำแพงเพชร",
        "zipcode": 62180,
        "district_code": 620304,
        "amphoe_code": 6203,
        "province_code": 62
    },
    {
        "district": "โป่งน้ำร้อน",
        "amphoe": "คลองลาน",
        "province": "กำแพงเพชร",
        "zipcode": 62180,
        "district_code": 620198,
        "amphoe_code": 6203,
        "province_code": 62
    },
    {
        "district": "ถาวรวัฒนา",
        "amphoe": "ทรายทองวัฒนา",
        "province": "กำแพงเพชร",
        "zipcode": 62190,
        "district_code": 620803,
        "amphoe_code": 6208,
        "province_code": 62
    },
    {
        "district": "ทุ่งทราย",
        "amphoe": "ทรายทองวัฒนา",
        "province": "กำแพงเพชร",
        "zipcode": 62190,
        "district_code": 620599,
        "amphoe_code": 6208,
        "province_code": 62
    },
    {
        "district": "ทุ่งทอง",
        "amphoe": "ทรายทองวัฒนา",
        "province": "กำแพงเพชร",
        "zipcode": 62190,
        "district_code": 620514,
        "amphoe_code": 6208,
        "province_code": 62
    },
    {
        "district": "บึงสามัคคี",
        "amphoe": "บึงสามัคคี",
        "province": "กำแพงเพชร",
        "zipcode": 62210,
        "district_code": 620414,
        "amphoe_code": 6210,
        "province_code": 62
    },
    {
        "district": "ระหาน",
        "amphoe": "บึงสามัคคี",
        "province": "กำแพงเพชร",
        "zipcode": 62210,
        "district_code": 620402,
        "amphoe_code": 6210,
        "province_code": 62
    },
    {
        "district": "วังชะโอน",
        "amphoe": "บึงสามัคคี",
        "province": "กำแพงเพชร",
        "zipcode": 62210,
        "district_code": 620401,
        "amphoe_code": 6210,
        "province_code": 62
    },
    {
        "district": "เทพนิมิต",
        "amphoe": "บึงสามัคคี",
        "province": "กำแพงเพชร",
        "zipcode": 62210,
        "district_code": 621004,
        "amphoe_code": 6210,
        "province_code": 62
    },
    {
        "district": "ปางตาไว",
        "amphoe": "ปางศิลาทอง",
        "province": "กำแพงเพชร",
        "zipcode": 62120,
        "district_code": 620511,
        "amphoe_code": 6209,
        "province_code": 62
    },
    {
        "district": "หินดาต",
        "amphoe": "ปางศิลาทอง",
        "province": "กำแพงเพชร",
        "zipcode": 62120,
        "district_code": 620515,
        "amphoe_code": 6209,
        "province_code": 62
    },
    {
        "district": "โพธิ์ทอง",
        "amphoe": "ปางศิลาทอง",
        "province": "กำแพงเพชร",
        "zipcode": 62120,
        "district_code": 620510,
        "amphoe_code": 6209,
        "province_code": 62
    },
    {
        "district": "คลองพิไกร",
        "amphoe": "พรานกระต่าย",
        "province": "กำแพงเพชร",
        "zipcode": 62110,
        "district_code": 620608,
        "amphoe_code": 6206,
        "province_code": 62
    },
    {
        "district": "คุยบ้านโอง",
        "amphoe": "พรานกระต่าย",
        "province": "กำแพงเพชร",
        "zipcode": 62110,
        "district_code": 620607,
        "amphoe_code": 6206,
        "province_code": 62
    },
    {
        "district": "ถ้ำกระต่ายทอง",
        "amphoe": "พรานกระต่าย",
        "province": "กำแพงเพชร",
        "zipcode": 62110,
        "district_code": 620609,
        "amphoe_code": 6206,
        "province_code": 62
    },
    {
        "district": "ท่าไม้",
        "amphoe": "พรานกระต่าย",
        "province": "กำแพงเพชร",
        "zipcode": 62110,
        "district_code": 620603,
        "amphoe_code": 6206,
        "province_code": 62
    },
    {
        "district": "พรานกระต่าย",
        "amphoe": "พรานกระต่าย",
        "province": "กำแพงเพชร",
        "zipcode": 62110,
        "district_code": 620601,
        "amphoe_code": 6206,
        "province_code": 62
    },
    {
        "district": "วังควง",
        "amphoe": "พรานกระต่าย",
        "province": "กำแพงเพชร",
        "zipcode": 62110,
        "district_code": 620604,
        "amphoe_code": 6206,
        "province_code": 62
    },
    {
        "district": "วังตะแบก",
        "amphoe": "พรานกระต่าย",
        "province": "กำแพงเพชร",
        "zipcode": 62110,
        "district_code": 620605,
        "amphoe_code": 6206,
        "province_code": 62
    },
    {
        "district": "หนองหัววัว",
        "amphoe": "พรานกระต่าย",
        "province": "กำแพงเพชร",
        "zipcode": 62110,
        "district_code": 620602,
        "amphoe_code": 6206,
        "province_code": 62
    },
    {
        "district": "ห้วยยั้ง",
        "amphoe": "พรานกระต่าย",
        "province": "กำแพงเพชร",
        "zipcode": 62110,
        "district_code": 620610,
        "amphoe_code": 6206,
        "province_code": 62
    },
    {
        "district": "เขาคีริส",
        "amphoe": "พรานกระต่าย",
        "province": "กำแพงเพชร",
        "zipcode": 62110,
        "district_code": 620606,
        "amphoe_code": 6206,
        "province_code": 62
    },
    {
        "district": "จันทิมา",
        "amphoe": "ลานกระบือ",
        "province": "กำแพงเพชร",
        "zipcode": 62170,
        "district_code": 620707,
        "amphoe_code": 6207,
        "province_code": 62
    },
    {
        "district": "ช่องลม",
        "amphoe": "ลานกระบือ",
        "province": "กำแพงเพชร",
        "zipcode": 62170,
        "district_code": 620702,
        "amphoe_code": 6207,
        "province_code": 62
    },
    {
        "district": "บึงทับแรต",
        "amphoe": "ลานกระบือ",
        "province": "กำแพงเพชร",
        "zipcode": 62170,
        "district_code": 620706,
        "amphoe_code": 6207,
        "province_code": 62
    },
    {
        "district": "ประชาสุขสันต์",
        "amphoe": "ลานกระบือ",
        "province": "กำแพงเพชร",
        "zipcode": 62170,
        "district_code": 620705,
        "amphoe_code": 6207,
        "province_code": 62
    },
    {
        "district": "ลานกระบือ",
        "amphoe": "ลานกระบือ",
        "province": "กำแพงเพชร",
        "zipcode": 62170,
        "district_code": 620701,
        "amphoe_code": 6207,
        "province_code": 62
    },
    {
        "district": "หนองหลวง",
        "amphoe": "ลานกระบือ",
        "province": "กำแพงเพชร",
        "zipcode": 62170,
        "district_code": 620703,
        "amphoe_code": 6207,
        "province_code": 62
    },
    {
        "district": "โนนพลวง",
        "amphoe": "ลานกระบือ",
        "province": "กำแพงเพชร",
        "zipcode": 62170,
        "district_code": 620704,
        "amphoe_code": 6207,
        "province_code": 62
    },
    {
        "district": "คณฑี",
        "amphoe": "เมืองกำแพงเพชร",
        "province": "กำแพงเพชร",
        "zipcode": 62000,
        "district_code": 620111,
        "amphoe_code": 6201,
        "province_code": 62
    },
    {
        "district": "คลองแม่ลาย",
        "amphoe": "เมืองกำแพงเพชร",
        "province": "กำแพงเพชร",
        "zipcode": 62000,
        "district_code": 620117,
        "amphoe_code": 6201,
        "province_code": 62
    },
    {
        "district": "ทรงธรรม",
        "amphoe": "เมืองกำแพงเพชร",
        "province": "กำแพงเพชร",
        "zipcode": 62000,
        "district_code": 620106,
        "amphoe_code": 6201,
        "province_code": 62
    },
    {
        "district": "ท่าขุนราม",
        "amphoe": "เมืองกำแพงเพชร",
        "province": "กำแพงเพชร",
        "zipcode": 62000,
        "district_code": 620115,
        "amphoe_code": 6201,
        "province_code": 62
    },
    {
        "district": "ธำมรงค์",
        "amphoe": "เมืองกำแพงเพชร",
        "province": "กำแพงเพชร",
        "zipcode": 62160,
        "district_code": 620118,
        "amphoe_code": 6201,
        "province_code": 62
    },
    {
        "district": "นครชุม",
        "amphoe": "เมืองกำแพงเพชร",
        "province": "กำแพงเพชร",
        "zipcode": 62000,
        "district_code": 620105,
        "amphoe_code": 6201,
        "province_code": 62
    },
    {
        "district": "นาบ่อคำ",
        "amphoe": "เมืองกำแพงเพชร",
        "province": "กำแพงเพชร",
        "zipcode": 62000,
        "district_code": 620104,
        "amphoe_code": 6201,
        "province_code": 62
    },
    {
        "district": "นิคมทุ่งโพธิ์ทะเล",
        "amphoe": "เมืองกำแพงเพชร",
        "province": "กำแพงเพชร",
        "zipcode": 62000,
        "district_code": 620112,
        "amphoe_code": 6201,
        "province_code": 62
    },
    {
        "district": "ลานดอกไม้",
        "amphoe": "เมืองกำแพงเพชร",
        "province": "กำแพงเพชร",
        "zipcode": 62000,
        "district_code": 620107,
        "amphoe_code": 6201,
        "province_code": 62
    },
    {
        "district": "วังทอง",
        "amphoe": "เมืองกำแพงเพชร",
        "province": "กำแพงเพชร",
        "zipcode": 62000,
        "district_code": 620114,
        "amphoe_code": 6201,
        "province_code": 62
    },
    {
        "district": "สระแก้ว",
        "amphoe": "เมืองกำแพงเพชร",
        "province": "กำแพงเพชร",
        "zipcode": 62000,
        "district_code": 620119,
        "amphoe_code": 6201,
        "province_code": 62
    },
    {
        "district": "หนองปลิง",
        "amphoe": "เมืองกำแพงเพชร",
        "province": "กำแพงเพชร",
        "zipcode": 62000,
        "district_code": 620110,
        "amphoe_code": 6201,
        "province_code": 62
    },
    {
        "district": "อ่างทอง",
        "amphoe": "เมืองกำแพงเพชร",
        "province": "กำแพงเพชร",
        "zipcode": 62000,
        "district_code": 620103,
        "amphoe_code": 6201,
        "province_code": 62
    },
    {
        "district": "เทพนคร",
        "amphoe": "เมืองกำแพงเพชร",
        "province": "กำแพงเพชร",
        "zipcode": 62000,
        "district_code": 620113,
        "amphoe_code": 6201,
        "province_code": 62
    },
    {
        "district": "ในเมือง",
        "amphoe": "เมืองกำแพงเพชร",
        "province": "กำแพงเพชร",
        "zipcode": 62000,
        "district_code": 620101,
        "amphoe_code": 6201,
        "province_code": 62
    },
    {
        "district": "ไตรตรึงษ์",
        "amphoe": "เมืองกำแพงเพชร",
        "province": "กำแพงเพชร",
        "zipcode": 62160,
        "district_code": 620102,
        "amphoe_code": 6201,
        "province_code": 62
    },
    {
        "district": "ลานดอกไม้ตก",
        "amphoe": "โกสัมพีนคร",
        "province": "กำแพงเพชร",
        "zipcode": 62000,
        "district_code": 620108,
        "amphoe_code": 6211,
        "province_code": 62
    },
    {
        "district": "เพชรชมภู",
        "amphoe": "โกสัมพีนคร",
        "province": "กำแพงเพชร",
        "zipcode": 62000,
        "district_code": 620116,
        "amphoe_code": 6211,
        "province_code": 62
    },
    {
        "district": "โกสัมพี",
        "amphoe": "โกสัมพีนคร",
        "province": "กำแพงเพชร",
        "zipcode": 62000,
        "district_code": 620109,
        "amphoe_code": 6211,
        "province_code": 62
    },
    {
        "district": "พานทอง",
        "amphoe": "ไทรงาม",
        "province": "กำแพงเพชร",
        "zipcode": 62150,
        "district_code": 620206,
        "amphoe_code": 6202,
        "province_code": 62
    },
    {
        "district": "มหาชัย",
        "amphoe": "ไทรงาม",
        "province": "กำแพงเพชร",
        "zipcode": 62150,
        "district_code": 620205,
        "amphoe_code": 6202,
        "province_code": 62
    },
    {
        "district": "หนองคล้า",
        "amphoe": "ไทรงาม",
        "province": "กำแพงเพชร",
        "zipcode": 62150,
        "district_code": 620197,
        "amphoe_code": 6202,
        "province_code": 62
    },
    {
        "district": "หนองทอง",
        "amphoe": "ไทรงาม",
        "province": "กำแพงเพชร",
        "zipcode": 62150,
        "district_code": 620203,
        "amphoe_code": 6202,
        "province_code": 62
    },
    {
        "district": "หนองแม่แตง",
        "amphoe": "ไทรงาม",
        "province": "กำแพงเพชร",
        "zipcode": 62150,
        "district_code": 620207,
        "amphoe_code": 6202,
        "province_code": 62
    },
    {
        "district": "หนองไม้กอง",
        "amphoe": "ไทรงาม",
        "province": "กำแพงเพชร",
        "zipcode": 62150,
        "district_code": 620204,
        "amphoe_code": 6202,
        "province_code": 62
    },
    {
        "district": "ไทรงาม",
        "amphoe": "ไทรงาม",
        "province": "กำแพงเพชร",
        "zipcode": 62150,
        "district_code": 620199,
        "amphoe_code": 6202,
        "province_code": 62
    },
    {
        "district": "ดูนสาด",
        "amphoe": "กระนวน",
        "province": "ขอนแก่น",
        "zipcode": 40170,
        "district_code": 400909,
        "amphoe_code": 4009,
        "province_code": 40
    },
    {
        "district": "น้ำอ้อม",
        "amphoe": "กระนวน",
        "province": "ขอนแก่น",
        "zipcode": 40170,
        "district_code": 400911,
        "amphoe_code": 4009,
        "province_code": 40
    },
    {
        "district": "บ้านฝาง",
        "amphoe": "กระนวน",
        "province": "ขอนแก่น",
        "zipcode": 40170,
        "district_code": 400206,
        "amphoe_code": 4009,
        "province_code": 40
    },
    {
        "district": "หนองกุงใหญ่",
        "amphoe": "กระนวน",
        "province": "ขอนแก่น",
        "zipcode": 40170,
        "district_code": 400902,
        "amphoe_code": 4009,
        "province_code": 40
    },
    {
        "district": "หนองโก",
        "amphoe": "กระนวน",
        "province": "ขอนแก่น",
        "zipcode": 40170,
        "district_code": 400901,
        "amphoe_code": 4009,
        "province_code": 40
    },
    {
        "district": "หนองโน",
        "amphoe": "กระนวน",
        "province": "ขอนแก่น",
        "zipcode": 40170,
        "district_code": 400910,
        "amphoe_code": 4009,
        "province_code": 40
    },
    {
        "district": "หัวนาคำ",
        "amphoe": "กระนวน",
        "province": "ขอนแก่น",
        "zipcode": 40170,
        "district_code": 400912,
        "amphoe_code": 4009,
        "province_code": 40
    },
    {
        "district": "ห้วยยาง",
        "amphoe": "กระนวน",
        "province": "ขอนแก่น",
        "zipcode": 40170,
        "district_code": 400906,
        "amphoe_code": 4009,
        "province_code": 40
    },
    {
        "district": "ห้วยโจด",
        "amphoe": "กระนวน",
        "province": "ขอนแก่น",
        "zipcode": 40170,
        "district_code": 400905,
        "amphoe_code": 4009,
        "province_code": 40
    },
    {
        "district": "กุดเพียขอม",
        "amphoe": "ชนบท",
        "province": "ขอนแก่น",
        "zipcode": 40180,
        "district_code": 401802,
        "amphoe_code": 4018,
        "province_code": 40
    },
    {
        "district": "ชนบท",
        "amphoe": "ชนบท",
        "province": "ขอนแก่น",
        "zipcode": 40180,
        "district_code": 401801,
        "amphoe_code": 4018,
        "province_code": 40
    },
    {
        "district": "บ้านแท่น",
        "amphoe": "ชนบท",
        "province": "ขอนแก่น",
        "zipcode": 40180,
        "district_code": 401805,
        "amphoe_code": 4018,
        "province_code": 40
    },
    {
        "district": "ปอแดง",
        "amphoe": "ชนบท",
        "province": "ขอนแก่น",
        "zipcode": 40180,
        "district_code": 401808,
        "amphoe_code": 4018,
        "province_code": 40
    },
    {
        "district": "วังแสง",
        "amphoe": "ชนบท",
        "province": "ขอนแก่น",
        "zipcode": 40180,
        "district_code": 401803,
        "amphoe_code": 4018,
        "province_code": 40
    },
    {
        "district": "ศรีบุญเรือง",
        "amphoe": "ชนบท",
        "province": "ขอนแก่น",
        "zipcode": 40180,
        "district_code": 401806,
        "amphoe_code": 4018,
        "province_code": 40
    },
    {
        "district": "ห้วยแก",
        "amphoe": "ชนบท",
        "province": "ขอนแก่น",
        "zipcode": 40180,
        "district_code": 401804,
        "amphoe_code": 4018,
        "province_code": 40
    },
    {
        "district": "โนนพะยอม",
        "amphoe": "ชนบท",
        "province": "ขอนแก่น",
        "zipcode": 40180,
        "district_code": 401807,
        "amphoe_code": 4018,
        "province_code": 40
    },
    {
        "district": "ขัวเรียง",
        "amphoe": "ชุมแพ",
        "province": "ขอนแก่น",
        "zipcode": 40130,
        "district_code": 400505,
        "amphoe_code": 4005,
        "province_code": 40
    },
    {
        "district": "ชุมแพ",
        "amphoe": "ชุมแพ",
        "province": "ขอนแก่น",
        "zipcode": 40130,
        "district_code": 400501,
        "amphoe_code": 4005,
        "province_code": 40
    },
    {
        "district": "นาหนองทุ่ม",
        "amphoe": "ชุมแพ",
        "province": "ขอนแก่น",
        "zipcode": 40290,
        "district_code": 400503,
        "amphoe_code": 4005,
        "province_code": 40
    },
    {
        "district": "นาเพียง",
        "amphoe": "ชุมแพ",
        "province": "ขอนแก่น",
        "zipcode": 40130,
        "district_code": 400509,
        "amphoe_code": 4005,
        "province_code": 40
    },
    {
        "district": "วังหินลาด",
        "amphoe": "ชุมแพ",
        "province": "ขอนแก่น",
        "zipcode": 40130,
        "district_code": 400508,
        "amphoe_code": 4005,
        "province_code": 40
    },
    {
        "district": "หนองเขียด",
        "amphoe": "ชุมแพ",
        "province": "ขอนแก่น",
        "zipcode": 40290,
        "district_code": 400510,
        "amphoe_code": 4005,
        "province_code": 40
    },
    {
        "district": "หนองเสาเล้า",
        "amphoe": "ชุมแพ",
        "province": "ขอนแก่น",
        "zipcode": 40130,
        "district_code": 400511,
        "amphoe_code": 4005,
        "province_code": 40
    },
    {
        "district": "หนองไผ่",
        "amphoe": "ชุมแพ",
        "province": "ขอนแก่น",
        "zipcode": 40130,
        "district_code": 400506,
        "amphoe_code": 4005,
        "province_code": 40
    },
    {
        "district": "โนนสะอาด",
        "amphoe": "ชุมแพ",
        "province": "ขอนแก่น",
        "zipcode": 40290,
        "district_code": 400409,
        "amphoe_code": 4005,
        "province_code": 40
    },
    {
        "district": "โนนหัน",
        "amphoe": "ชุมแพ",
        "province": "ขอนแก่น",
        "zipcode": 40290,
        "district_code": 400502,
        "amphoe_code": 4005,
        "province_code": 40
    },
    {
        "district": "โนนอุดม",
        "amphoe": "ชุมแพ",
        "province": "ขอนแก่น",
        "zipcode": 40130,
        "district_code": 400504,
        "amphoe_code": 4005,
        "province_code": 40
    },
    {
        "district": "ไชยสอ",
        "amphoe": "ชุมแพ",
        "province": "ขอนแก่น",
        "zipcode": 40130,
        "district_code": 400507,
        "amphoe_code": 4005,
        "province_code": 40
    },
    {
        "district": "กระนวน",
        "amphoe": "ซำสูง",
        "province": "ขอนแก่น",
        "zipcode": 40170,
        "district_code": 400903,
        "amphoe_code": 4021,
        "province_code": 40
    },
    {
        "district": "คำแมด",
        "amphoe": "ซำสูง",
        "province": "ขอนแก่น",
        "zipcode": 40170,
        "district_code": 400908,
        "amphoe_code": 4021,
        "province_code": 40
    },
    {
        "district": "คูคำ",
        "amphoe": "ซำสูง",
        "province": "ขอนแก่น",
        "zipcode": 40170,
        "district_code": 400913,
        "amphoe_code": 4021,
        "province_code": 40
    },
    {
        "district": "บ้านโนน",
        "amphoe": "ซำสูง",
        "province": "ขอนแก่น",
        "zipcode": 40170,
        "district_code": 400904,
        "amphoe_code": 4021,
        "province_code": 40
    },
    {
        "district": "ห้วยเตย",
        "amphoe": "ซำสูง",
        "province": "ขอนแก่น",
        "zipcode": 40170,
        "district_code": 400914,
        "amphoe_code": 4021,
        "province_code": 40
    },
    {
        "district": "กุดน้ำใส",
        "amphoe": "น้ำพอง",
        "province": "ขอนแก่น",
        "zipcode": 40310,
        "district_code": 400712,
        "amphoe_code": 4007,
        "province_code": 40
    },
    {
        "district": "ทรายมูล",
        "amphoe": "น้ำพอง",
        "province": "ขอนแก่น",
        "zipcode": 40140,
        "district_code": 400709,
        "amphoe_code": 4007,
        "province_code": 40
    },
    {
        "district": "ท่ากระเสริม",
        "amphoe": "น้ำพอง",
        "province": "ขอนแก่น",
        "zipcode": 40140,
        "district_code": 400710,
        "amphoe_code": 4007,
        "province_code": 40
    },
    {
        "district": "น้ำพอง",
        "amphoe": "น้ำพอง",
        "province": "ขอนแก่น",
        "zipcode": 40140,
        "district_code": 400701,
        "amphoe_code": 4007,
        "province_code": 40
    },
    {
        "district": "บัวเงิน",
        "amphoe": "น้ำพอง",
        "province": "ขอนแก่น",
        "zipcode": 40140,
        "district_code": 400708,
        "amphoe_code": 4007,
        "province_code": 40
    },
    {
        "district": "บัวใหญ่",
        "amphoe": "น้ำพอง",
        "province": "ขอนแก่น",
        "zipcode": 40140,
        "district_code": 400704,
        "amphoe_code": 4007,
        "province_code": 40
    },
    {
        "district": "บ้านขาม",
        "amphoe": "น้ำพอง",
        "province": "ขอนแก่น",
        "zipcode": 40140,
        "district_code": 400707,
        "amphoe_code": 4007,
        "province_code": 40
    },
    {
        "district": "พังทุย",
        "amphoe": "น้ำพอง",
        "province": "ขอนแก่น",
        "zipcode": 40140,
        "district_code": 400711,
        "amphoe_code": 4007,
        "province_code": 40
    },
    {
        "district": "ม่วงหวาน",
        "amphoe": "น้ำพอง",
        "province": "ขอนแก่น",
        "zipcode": 40310,
        "district_code": 400706,
        "amphoe_code": 4007,
        "province_code": 40
    },
    {
        "district": "วังชัย",
        "amphoe": "น้ำพอง",
        "province": "ขอนแก่น",
        "zipcode": 40140,
        "district_code": 400702,
        "amphoe_code": 4007,
        "province_code": 40
    },
    {
        "district": "สะอาด",
        "amphoe": "น้ำพอง",
        "province": "ขอนแก่น",
        "zipcode": 40310,
        "district_code": 400705,
        "amphoe_code": 4007,
        "province_code": 40
    },
    {
        "district": "หนองกุง",
        "amphoe": "น้ำพอง",
        "province": "ขอนแก่น",
        "zipcode": 40140,
        "district_code": 400703,
        "amphoe_code": 4007,
        "province_code": 40
    },
    {
        "district": "บ้านฝาง",
        "amphoe": "บ้านฝาง",
        "province": "ขอนแก่น",
        "zipcode": 40270,
        "district_code": 400206,
        "amphoe_code": 4002,
        "province_code": 40
    },
    {
        "district": "บ้านเหล่า",
        "amphoe": "บ้านฝาง",
        "province": "ขอนแก่น",
        "zipcode": 40270,
        "district_code": 400204,
        "amphoe_code": 4002,
        "province_code": 40
    },
    {
        "district": "ป่ามะนาว",
        "amphoe": "บ้านฝาง",
        "province": "ขอนแก่น",
        "zipcode": 40270,
        "district_code": 400205,
        "amphoe_code": 4002,
        "province_code": 40
    },
    {
        "district": "ป่าหวายนั่ง",
        "amphoe": "บ้านฝาง",
        "province": "ขอนแก่น",
        "zipcode": 40270,
        "district_code": 400202,
        "amphoe_code": 4002,
        "province_code": 40
    },
    {
        "district": "หนองบัว",
        "amphoe": "บ้านฝาง",
        "province": "ขอนแก่น",
        "zipcode": 40270,
        "district_code": 400199,
        "amphoe_code": 4002,
        "province_code": 40
    },
    {
        "district": "โคกงาม",
        "amphoe": "บ้านฝาง",
        "province": "ขอนแก่น",
        "zipcode": 40270,
        "district_code": 400207,
        "amphoe_code": 4002,
        "province_code": 40
    },
    {
        "district": "โนนฆ้อง",
        "amphoe": "บ้านฝาง",
        "province": "ขอนแก่น",
        "zipcode": 40270,
        "district_code": 400203,
        "amphoe_code": 4002,
        "province_code": 40
    },
    {
        "district": "บ้านแฮด",
        "amphoe": "บ้านแฮด",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 401003,
        "amphoe_code": 4024,
        "province_code": 40
    },
    {
        "district": "หนองแซง",
        "amphoe": "บ้านแฮด",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 401012,
        "amphoe_code": 4024,
        "province_code": 40
    },
    {
        "district": "โคกสำราญ",
        "amphoe": "บ้านแฮด",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 401004,
        "amphoe_code": 4024,
        "province_code": 40
    },
    {
        "district": "โนนสมบูรณ์",
        "amphoe": "บ้านแฮด",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 401015,
        "amphoe_code": 4024,
        "province_code": 40
    },
    {
        "district": "บ้านลาน",
        "amphoe": "บ้านไผ่",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 401009,
        "amphoe_code": 4010,
        "province_code": 40
    },
    {
        "district": "บ้านไผ่",
        "amphoe": "บ้านไผ่",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 401001,
        "amphoe_code": 4010,
        "province_code": 40
    },
    {
        "district": "ป่าปอ",
        "amphoe": "บ้านไผ่",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 401013,
        "amphoe_code": 4010,
        "province_code": 40
    },
    {
        "district": "ภูเหล็ก",
        "amphoe": "บ้านไผ่",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 401011,
        "amphoe_code": 4010,
        "province_code": 40
    },
    {
        "district": "หนองน้ำใส",
        "amphoe": "บ้านไผ่",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 401016,
        "amphoe_code": 4010,
        "province_code": 40
    },
    {
        "district": "หัวหนอง",
        "amphoe": "บ้านไผ่",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 401017,
        "amphoe_code": 4010,
        "province_code": 40
    },
    {
        "district": "หินตั้ง",
        "amphoe": "บ้านไผ่",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 401014,
        "amphoe_code": 4010,
        "province_code": 40
    },
    {
        "district": "เมืองเพีย",
        "amphoe": "บ้านไผ่",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 401005,
        "amphoe_code": 4010,
        "province_code": 40
    },
    {
        "district": "แคนเหนือ",
        "amphoe": "บ้านไผ่",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 401010,
        "amphoe_code": 4010,
        "province_code": 40
    },
    {
        "district": "ในเมือง",
        "amphoe": "บ้านไผ่",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 400101,
        "amphoe_code": 4010,
        "province_code": 40
    },
    {
        "district": "ขามป้อม",
        "amphoe": "พระยืน",
        "province": "ขอนแก่น",
        "zipcode": 40320,
        "district_code": 400305,
        "amphoe_code": 4003,
        "province_code": 40
    },
    {
        "district": "บ้านโต้น",
        "amphoe": "พระยืน",
        "province": "ขอนแก่น",
        "zipcode": 40320,
        "district_code": 400198,
        "amphoe_code": 4003,
        "province_code": 40
    },
    {
        "district": "พระบุ",
        "amphoe": "พระยืน",
        "province": "ขอนแก่น",
        "zipcode": 40320,
        "district_code": 400302,
        "amphoe_code": 4003,
        "province_code": 40
    },
    {
        "district": "พระยืน",
        "amphoe": "พระยืน",
        "province": "ขอนแก่น",
        "zipcode": 40320,
        "district_code": 400301,
        "amphoe_code": 4003,
        "province_code": 40
    },
    {
        "district": "หนองแวง",
        "amphoe": "พระยืน",
        "province": "ขอนแก่น",
        "zipcode": 40320,
        "district_code": 400304,
        "amphoe_code": 4003,
        "province_code": 40
    },
    {
        "district": "ลอมคอม",
        "amphoe": "พล",
        "province": "ขอนแก่น",
        "zipcode": 40120,
        "district_code": 401210,
        "amphoe_code": 4012,
        "province_code": 40
    },
    {
        "district": "หนองมะเขือ",
        "amphoe": "พล",
        "province": "ขอนแก่น",
        "zipcode": 40120,
        "district_code": 401205,
        "amphoe_code": 4012,
        "province_code": 40
    },
    {
        "district": "หนองแวงนางเบ้า",
        "amphoe": "พล",
        "province": "ขอนแก่น",
        "zipcode": 40120,
        "district_code": 401209,
        "amphoe_code": 4012,
        "province_code": 40
    },
    {
        "district": "หนองแวงโสกพระ",
        "amphoe": "พล",
        "province": "ขอนแก่น",
        "zipcode": 40120,
        "district_code": 401206,
        "amphoe_code": 4012,
        "province_code": 40
    },
    {
        "district": "หัวทุ่ง",
        "amphoe": "พล",
        "province": "ขอนแก่น",
        "zipcode": 40120,
        "district_code": 401213,
        "amphoe_code": 4012,
        "province_code": 40
    },
    {
        "district": "เก่างิ้ว",
        "amphoe": "พล",
        "province": "ขอนแก่น",
        "zipcode": 40120,
        "district_code": 401204,
        "amphoe_code": 4012,
        "province_code": 40
    },
    {
        "district": "เพ็กใหญ่",
        "amphoe": "พล",
        "province": "ขอนแก่น",
        "zipcode": 40120,
        "district_code": 401207,
        "amphoe_code": 4012,
        "province_code": 40
    },
    {
        "district": "เมืองพล",
        "amphoe": "พล",
        "province": "ขอนแก่น",
        "zipcode": 40120,
        "district_code": 401201,
        "amphoe_code": 4012,
        "province_code": 40
    },
    {
        "district": "โคกสง่า",
        "amphoe": "พล",
        "province": "ขอนแก่น",
        "zipcode": 40120,
        "district_code": 401208,
        "amphoe_code": 4012,
        "province_code": 40
    },
    {
        "district": "โจดหนองแก",
        "amphoe": "พล",
        "province": "ขอนแก่น",
        "zipcode": 40120,
        "district_code": 401203,
        "amphoe_code": 4012,
        "province_code": 40
    },
    {
        "district": "โนนข่า",
        "amphoe": "พล",
        "province": "ขอนแก่น",
        "zipcode": 40120,
        "district_code": 401211,
        "amphoe_code": 4012,
        "province_code": 40
    },
    {
        "district": "โสกนกเต็น",
        "amphoe": "พล",
        "province": "ขอนแก่น",
        "zipcode": 40120,
        "district_code": 401212,
        "amphoe_code": 4012,
        "province_code": 40
    },
    {
        "district": "นาฝาย",
        "amphoe": "ภูผาม่าน",
        "province": "ขอนแก่น",
        "zipcode": 40350,
        "district_code": 402002,
        "amphoe_code": 4020,
        "province_code": 40
    },
    {
        "district": "ภูผาม่าน",
        "amphoe": "ภูผาม่าน",
        "province": "ขอนแก่น",
        "zipcode": 40350,
        "district_code": 402003,
        "amphoe_code": 4020,
        "province_code": 40
    },
    {
        "district": "วังสวาบ",
        "amphoe": "ภูผาม่าน",
        "province": "ขอนแก่น",
        "zipcode": 40350,
        "district_code": 402004,
        "amphoe_code": 4020,
        "province_code": 40
    },
    {
        "district": "ห้วยม่วง",
        "amphoe": "ภูผาม่าน",
        "province": "ขอนแก่น",
        "zipcode": 40350,
        "district_code": 402005,
        "amphoe_code": 4020,
        "province_code": 40
    },
    {
        "district": "โนนคอม",
        "amphoe": "ภูผาม่าน",
        "province": "ขอนแก่น",
        "zipcode": 40350,
        "district_code": 402001,
        "amphoe_code": 4020,
        "province_code": 40
    },
    {
        "district": "กุดขอนแก่น",
        "amphoe": "ภูเวียง",
        "province": "ขอนแก่น",
        "zipcode": 40150,
        "district_code": 401605,
        "amphoe_code": 4016,
        "province_code": 40
    },
    {
        "district": "ดินดำ",
        "amphoe": "ภูเวียง",
        "province": "ขอนแก่น",
        "zipcode": 40150,
        "district_code": 401616,
        "amphoe_code": 4016,
        "province_code": 40
    },
    {
        "district": "ทุ่งชมพู",
        "amphoe": "ภูเวียง",
        "province": "ขอนแก่น",
        "zipcode": 40150,
        "district_code": 401614,
        "amphoe_code": 4016,
        "province_code": 40
    },
    {
        "district": "นาชุมแสง",
        "amphoe": "ภูเวียง",
        "province": "ขอนแก่น",
        "zipcode": 40150,
        "district_code": 401606,
        "amphoe_code": 4016,
        "province_code": 40
    },
    {
        "district": "นาหว้า",
        "amphoe": "ภูเวียง",
        "province": "ขอนแก่น",
        "zipcode": 40150,
        "district_code": 401607,
        "amphoe_code": 4016,
        "province_code": 40
    },
    {
        "district": "บ้านเรือ",
        "amphoe": "ภูเวียง",
        "province": "ขอนแก่น",
        "zipcode": 40150,
        "district_code": 401601,
        "amphoe_code": 4016,
        "province_code": 40
    },
    {
        "district": "ภูเวียง",
        "amphoe": "ภูเวียง",
        "province": "ขอนแก่น",
        "zipcode": 40150,
        "district_code": 401617,
        "amphoe_code": 4016,
        "province_code": 40
    },
    {
        "district": "สงเปือย",
        "amphoe": "ภูเวียง",
        "province": "ขอนแก่น",
        "zipcode": 40150,
        "district_code": 401613,
        "amphoe_code": 4016,
        "province_code": 40
    },
    {
        "district": "หนองกุงธนสาร",
        "amphoe": "ภูเวียง",
        "province": "ขอนแก่น",
        "zipcode": 40150,
        "district_code": 401610,
        "amphoe_code": 4016,
        "province_code": 40
    },
    {
        "district": "หนองกุงเซิน",
        "amphoe": "ภูเวียง",
        "province": "ขอนแก่น",
        "zipcode": 40150,
        "district_code": 401612,
        "amphoe_code": 4016,
        "province_code": 40
    },
    {
        "district": "หว้าทอง",
        "amphoe": "ภูเวียง",
        "province": "ขอนแก่น",
        "zipcode": 40150,
        "district_code": 401604,
        "amphoe_code": 4016,
        "province_code": 40
    },
    {
        "district": "เขาน้อย",
        "amphoe": "เวียงเก่า",
        "province": "ขอนแก่น",
        "zipcode": 40150,
        "district_code": 401608,
        "amphoe_code": 4029,
        "province_code": 40
    },
    {
        "district": "เมืองเก่าพัฒนา",
        "amphoe": "เวียงเก่า",
        "province": "ขอนแก่น",
        "zipcode": 40150,
        "district_code": 401615,
        "amphoe_code": 4029,
        "province_code": 40
    },
    {
        "district": "ในเมือง",
        "amphoe": "เวียงเก่า",
        "province": "ขอนแก่น",
        "zipcode": 40150,
        "district_code": 400101,
        "amphoe_code": 4029,
        "province_code": 40
    },
    {
        "district": "กุดเค้า",
        "amphoe": "มัญจาคีรี",
        "province": "ขอนแก่น",
        "zipcode": 40160,
        "district_code": 401701,
        "amphoe_code": 4017,
        "province_code": 40
    },
    {
        "district": "คำแคน",
        "amphoe": "มัญจาคีรี",
        "province": "ขอนแก่น",
        "zipcode": 40160,
        "district_code": 401705,
        "amphoe_code": 4017,
        "province_code": 40
    },
    {
        "district": "ท่าศาลา",
        "amphoe": "มัญจาคีรี",
        "province": "ขอนแก่น",
        "zipcode": 40160,
        "district_code": 401710,
        "amphoe_code": 4017,
        "province_code": 40
    },
    {
        "district": "นาข่า",
        "amphoe": "มัญจาคีรี",
        "province": "ขอนแก่น",
        "zipcode": 40160,
        "district_code": 401706,
        "amphoe_code": 4017,
        "province_code": 40
    },
    {
        "district": "นางาม",
        "amphoe": "มัญจาคีรี",
        "province": "ขอนแก่น",
        "zipcode": 40160,
        "district_code": 401707,
        "amphoe_code": 4017,
        "province_code": 40
    },
    {
        "district": "สวนหม่อน",
        "amphoe": "มัญจาคีรี",
        "province": "ขอนแก่น",
        "zipcode": 40160,
        "district_code": 401702,
        "amphoe_code": 4017,
        "province_code": 40
    },
    {
        "district": "หนองแปน",
        "amphoe": "มัญจาคีรี",
        "province": "ขอนแก่น",
        "zipcode": 40160,
        "district_code": 401703,
        "amphoe_code": 4017,
        "province_code": 40
    },
    {
        "district": "โพนเพ็ก",
        "amphoe": "มัญจาคีรี",
        "province": "ขอนแก่น",
        "zipcode": 40160,
        "district_code": 401704,
        "amphoe_code": 4017,
        "province_code": 40
    },
    {
        "district": "ซำยาง",
        "amphoe": "สีชมพู",
        "province": "ขอนแก่น",
        "zipcode": 40220,
        "district_code": 400605,
        "amphoe_code": 4006,
        "province_code": 40
    },
    {
        "district": "ดงลาน",
        "amphoe": "สีชมพู",
        "province": "ขอนแก่น",
        "zipcode": 40220,
        "district_code": 400607,
        "amphoe_code": 4006,
        "province_code": 40
    },
    {
        "district": "นาจาน",
        "amphoe": "สีชมพู",
        "province": "ขอนแก่น",
        "zipcode": 40220,
        "district_code": 400603,
        "amphoe_code": 4006,
        "province_code": 40
    },
    {
        "district": "บริบูรณ์",
        "amphoe": "สีชมพู",
        "province": "ขอนแก่น",
        "zipcode": 40220,
        "district_code": 400608,
        "amphoe_code": 4006,
        "province_code": 40
    },
    {
        "district": "บ้านใหม่",
        "amphoe": "สีชมพู",
        "province": "ขอนแก่น",
        "zipcode": 40220,
        "district_code": 400609,
        "amphoe_code": 4006,
        "province_code": 40
    },
    {
        "district": "ภูห่าน",
        "amphoe": "สีชมพู",
        "province": "ขอนแก่น",
        "zipcode": 40220,
        "district_code": 400610,
        "amphoe_code": 4006,
        "province_code": 40
    },
    {
        "district": "วังเพิ่ม",
        "amphoe": "สีชมพู",
        "province": "ขอนแก่น",
        "zipcode": 40220,
        "district_code": 400604,
        "amphoe_code": 4006,
        "province_code": 40
    },
    {
        "district": "ศรีสุข",
        "amphoe": "สีชมพู",
        "province": "ขอนแก่น",
        "zipcode": 40220,
        "district_code": 400602,
        "amphoe_code": 4006,
        "province_code": 40
    },
    {
        "district": "สีชมพู",
        "amphoe": "สีชมพู",
        "province": "ขอนแก่น",
        "zipcode": 40220,
        "district_code": 400601,
        "amphoe_code": 4006,
        "province_code": 40
    },
    {
        "district": "หนองแดง",
        "amphoe": "สีชมพู",
        "province": "ขอนแก่น",
        "zipcode": 40220,
        "district_code": 400606,
        "amphoe_code": 4006,
        "province_code": 40
    },
    {
        "district": "กุดธาตุ",
        "amphoe": "หนองนาคำ",
        "province": "ขอนแก่น",
        "zipcode": 40150,
        "district_code": 401609,
        "amphoe_code": 4023,
        "province_code": 40
    },
    {
        "district": "ขนวน",
        "amphoe": "หนองนาคำ",
        "province": "ขอนแก่น",
        "zipcode": 40150,
        "district_code": 401611,
        "amphoe_code": 4023,
        "province_code": 40
    },
    {
        "district": "บ้านโคก",
        "amphoe": "หนองนาคำ",
        "province": "ขอนแก่น",
        "zipcode": 40150,
        "district_code": 401603,
        "amphoe_code": 4023,
        "province_code": 40
    },
    {
        "district": "คึมชาด",
        "amphoe": "หนองสองห้อง",
        "province": "ขอนแก่น",
        "zipcode": 40190,
        "district_code": 401502,
        "amphoe_code": 4015,
        "province_code": 40
    },
    {
        "district": "ดงเค็ง",
        "amphoe": "หนองสองห้อง",
        "province": "ขอนแก่น",
        "zipcode": 40190,
        "district_code": 401508,
        "amphoe_code": 4015,
        "province_code": 40
    },
    {
        "district": "ดอนดั่ง",
        "amphoe": "หนองสองห้อง",
        "province": "ขอนแก่น",
        "zipcode": 40190,
        "district_code": 401510,
        "amphoe_code": 4015,
        "province_code": 40
    },
    {
        "district": "ดอนดู่",
        "amphoe": "หนองสองห้อง",
        "province": "ขอนแก่น",
        "zipcode": 40190,
        "district_code": 401507,
        "amphoe_code": 4015,
        "province_code": 40
    },
    {
        "district": "ตะกั่วป่า",
        "amphoe": "หนองสองห้อง",
        "province": "ขอนแก่น",
        "zipcode": 40190,
        "district_code": 401504,
        "amphoe_code": 4015,
        "province_code": 40
    },
    {
        "district": "วังหิน",
        "amphoe": "หนองสองห้อง",
        "province": "ขอนแก่น",
        "zipcode": 40190,
        "district_code": 401511,
        "amphoe_code": 4015,
        "province_code": 40
    },
    {
        "district": "สำโรง",
        "amphoe": "หนองสองห้อง",
        "province": "ขอนแก่น",
        "zipcode": 40190,
        "district_code": 401505,
        "amphoe_code": 4015,
        "province_code": 40
    },
    {
        "district": "หนองสองห้อง",
        "amphoe": "หนองสองห้อง",
        "province": "ขอนแก่น",
        "zipcode": 40190,
        "district_code": 401501,
        "amphoe_code": 4015,
        "province_code": 40
    },
    {
        "district": "หนองเม็ก",
        "amphoe": "หนองสองห้อง",
        "province": "ขอนแก่น",
        "zipcode": 40190,
        "district_code": 401506,
        "amphoe_code": 4015,
        "province_code": 40
    },
    {
        "district": "หนองไผ่ล้อม",
        "amphoe": "หนองสองห้อง",
        "province": "ขอนแก่น",
        "zipcode": 40190,
        "district_code": 401512,
        "amphoe_code": 4015,
        "province_code": 40
    },
    {
        "district": "หันโจด",
        "amphoe": "หนองสองห้อง",
        "province": "ขอนแก่น",
        "zipcode": 40190,
        "district_code": 401509,
        "amphoe_code": 4015,
        "province_code": 40
    },
    {
        "district": "โนนธาตุ",
        "amphoe": "หนองสองห้อง",
        "province": "ขอนแก่น",
        "zipcode": 40190,
        "district_code": 401503,
        "amphoe_code": 4015,
        "province_code": 40
    },
    {
        "district": "กุดกว้าง",
        "amphoe": "หนองเรือ",
        "province": "ขอนแก่น",
        "zipcode": 40210,
        "district_code": 400407,
        "amphoe_code": 4004,
        "province_code": 40
    },
    {
        "district": "จระเข้",
        "amphoe": "หนองเรือ",
        "province": "ขอนแก่น",
        "zipcode": 40240,
        "district_code": 400405,
        "amphoe_code": 4004,
        "province_code": 40
    },
    {
        "district": "บ้านกง",
        "amphoe": "หนองเรือ",
        "province": "ขอนแก่น",
        "zipcode": 40240,
        "district_code": 400403,
        "amphoe_code": 4004,
        "province_code": 40
    },
    {
        "district": "บ้านผือ",
        "amphoe": "หนองเรือ",
        "province": "ขอนแก่น",
        "zipcode": 40240,
        "district_code": 400410,
        "amphoe_code": 4004,
        "province_code": 40
    },
    {
        "district": "บ้านเม็ง",
        "amphoe": "หนองเรือ",
        "province": "ขอนแก่น",
        "zipcode": 40210,
        "district_code": 400402,
        "amphoe_code": 4004,
        "province_code": 40
    },
    {
        "district": "ยางคำ",
        "amphoe": "หนองเรือ",
        "province": "ขอนแก่น",
        "zipcode": 40240,
        "district_code": 400404,
        "amphoe_code": 4004,
        "province_code": 40
    },
    {
        "district": "หนองเรือ",
        "amphoe": "หนองเรือ",
        "province": "ขอนแก่น",
        "zipcode": 40210,
        "district_code": 400401,
        "amphoe_code": 4004,
        "province_code": 40
    },
    {
        "district": "โนนทอง",
        "amphoe": "หนองเรือ",
        "province": "ขอนแก่น",
        "zipcode": 40210,
        "district_code": 400406,
        "amphoe_code": 4004,
        "province_code": 40
    },
    {
        "district": "โนนทัน",
        "amphoe": "หนองเรือ",
        "province": "ขอนแก่น",
        "zipcode": 40210,
        "district_code": 400408,
        "amphoe_code": 4004,
        "province_code": 40
    },
    {
        "district": "โนนสะอาด",
        "amphoe": "หนองเรือ",
        "province": "ขอนแก่น",
        "zipcode": 40210,
        "district_code": 400409,
        "amphoe_code": 4004,
        "province_code": 40
    },
    {
        "district": "ทุ่งโป่ง",
        "amphoe": "อุบลรัตน์",
        "province": "ขอนแก่น",
        "zipcode": 40250,
        "district_code": 400806,
        "amphoe_code": 4008,
        "province_code": 40
    },
    {
        "district": "นาคำ",
        "amphoe": "อุบลรัตน์",
        "province": "ขอนแก่น",
        "zipcode": 40250,
        "district_code": 400804,
        "amphoe_code": 4008,
        "province_code": 40
    },
    {
        "district": "บ้านดง",
        "amphoe": "อุบลรัตน์",
        "province": "ขอนแก่น",
        "zipcode": 40250,
        "district_code": 400802,
        "amphoe_code": 4008,
        "province_code": 40
    },
    {
        "district": "ศรีสุขสำราญ",
        "amphoe": "อุบลรัตน์",
        "province": "ขอนแก่น",
        "zipcode": 40250,
        "district_code": 400805,
        "amphoe_code": 4008,
        "province_code": 40
    },
    {
        "district": "เขื่อนอุบลรัตน์",
        "amphoe": "อุบลรัตน์",
        "province": "ขอนแก่น",
        "zipcode": 40250,
        "district_code": 400803,
        "amphoe_code": 4008,
        "province_code": 40
    },
    {
        "district": "โคกสูง",
        "amphoe": "อุบลรัตน์",
        "province": "ขอนแก่น",
        "zipcode": 40250,
        "district_code": 400801,
        "amphoe_code": 4008,
        "province_code": 40
    },
    {
        "district": "คำม่วง",
        "amphoe": "เขาสวนกวาง",
        "province": "ขอนแก่น",
        "zipcode": 40280,
        "district_code": 401905,
        "amphoe_code": 4019,
        "province_code": 40
    },
    {
        "district": "ดงเมืองแอม",
        "amphoe": "เขาสวนกวาง",
        "province": "ขอนแก่น",
        "zipcode": 40280,
        "district_code": 401902,
        "amphoe_code": 4019,
        "province_code": 40
    },
    {
        "district": "นางิ้ว",
        "amphoe": "เขาสวนกวาง",
        "province": "ขอนแก่น",
        "zipcode": 40280,
        "district_code": 401903,
        "amphoe_code": 4019,
        "province_code": 40
    },
    {
        "district": "เขาสวนกวาง",
        "amphoe": "เขาสวนกวาง",
        "province": "ขอนแก่น",
        "zipcode": 40280,
        "district_code": 401901,
        "amphoe_code": 4019,
        "province_code": 40
    },
    {
        "district": "โนนสมบูรณ์",
        "amphoe": "เขาสวนกวาง",
        "province": "ขอนแก่น",
        "zipcode": 40280,
        "district_code": 401015,
        "amphoe_code": 4019,
        "province_code": 40
    },
    {
        "district": "ขามป้อม",
        "amphoe": "เปือยน้อย",
        "province": "ขอนแก่น",
        "zipcode": 40340,
        "district_code": 400305,
        "amphoe_code": 4011,
        "province_code": 40
    },
    {
        "district": "วังม่วง",
        "amphoe": "เปือยน้อย",
        "province": "ขอนแก่น",
        "zipcode": 40340,
        "district_code": 401098,
        "amphoe_code": 4011,
        "province_code": 40
    },
    {
        "district": "สระแก้ว",
        "amphoe": "เปือยน้อย",
        "province": "ขอนแก่น",
        "zipcode": 40340,
        "district_code": 401096,
        "amphoe_code": 4011,
        "province_code": 40
    },
    {
        "district": "เปือยน้อย",
        "amphoe": "เปือยน้อย",
        "province": "ขอนแก่น",
        "zipcode": 40340,
        "district_code": 401099,
        "amphoe_code": 4011,
        "province_code": 40
    },
    {
        "district": "ดอนช้าง",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40000,
        "district_code": 400112,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "ดอนหัน",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40260,
        "district_code": 400113,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "ท่าพระ",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40260,
        "district_code": 400104,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "บึงเนียม",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40000,
        "district_code": 400117,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "บ้านค้อ",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40000,
        "district_code": 400110,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "บ้านทุ่ม",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40000,
        "district_code": 400105,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "บ้านหว้า",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40000,
        "district_code": 400109,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "บ้านเป็ด",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40000,
        "district_code": 400115,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "พระลับ",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40000,
        "district_code": 400107,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "ศิลา",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40000,
        "district_code": 400114,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "สาวะถี",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40000,
        "district_code": 400108,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "สำราญ",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40000,
        "district_code": 400102,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "หนองตูม",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40000,
        "district_code": 400116,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "เมืองเก่า",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40000,
        "district_code": 400106,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "แดงใหญ่",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40000,
        "district_code": 400111,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "โคกสี",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40000,
        "district_code": 400103,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "โนนท่อน",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40000,
        "district_code": 400118,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "ในเมือง",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40000,
        "district_code": 400101,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "มหาวิทยาลัยขอนแก่น",
        "amphoe": "เมืองขอนแก่น",
        "province": "ขอนแก่น",
        "zipcode": 40002,
        "district_code": 400101,
        "amphoe_code": 4001,
        "province_code": 40
    },
    {
        "district": "ก้านเหลือง",
        "amphoe": "แวงน้อย",
        "province": "ขอนแก่น",
        "zipcode": 40230,
        "district_code": 401294,
        "amphoe_code": 4014,
        "province_code": 40
    },
    {
        "district": "ทางขวาง",
        "amphoe": "แวงน้อย",
        "province": "ขอนแก่น",
        "zipcode": 40230,
        "district_code": 401290,
        "amphoe_code": 4014,
        "province_code": 40
    },
    {
        "district": "ท่านางแนว",
        "amphoe": "แวงน้อย",
        "province": "ขอนแก่น",
        "zipcode": 40230,
        "district_code": 401403,
        "amphoe_code": 4014,
        "province_code": 40
    },
    {
        "district": "ท่าวัด",
        "amphoe": "แวงน้อย",
        "province": "ขอนแก่น",
        "zipcode": 40230,
        "district_code": 401291,
        "amphoe_code": 4014,
        "province_code": 40
    },
    {
        "district": "ละหานนา",
        "amphoe": "แวงน้อย",
        "province": "ขอนแก่น",
        "zipcode": 40230,
        "district_code": 401404,
        "amphoe_code": 4014,
        "province_code": 40
    },
    {
        "district": "แวงน้อย",
        "amphoe": "แวงน้อย",
        "province": "ขอนแก่น",
        "zipcode": 40230,
        "district_code": 401293,
        "amphoe_code": 4014,
        "province_code": 40
    },
    {
        "district": "คอนฉิม",
        "amphoe": "แวงใหญ่",
        "province": "ขอนแก่น",
        "zipcode": 40330,
        "district_code": 401299,
        "amphoe_code": 4013,
        "province_code": 40
    },
    {
        "district": "แวงใหญ่",
        "amphoe": "แวงใหญ่",
        "province": "ขอนแก่น",
        "zipcode": 40330,
        "district_code": 401296,
        "amphoe_code": 4013,
        "province_code": 40
    },
    {
        "district": "โนนทอง",
        "amphoe": "แวงใหญ่",
        "province": "ขอนแก่น",
        "zipcode": 40330,
        "district_code": 400406,
        "amphoe_code": 4013,
        "province_code": 40
    },
    {
        "district": "โนนสะอาด",
        "amphoe": "แวงใหญ่",
        "province": "ขอนแก่น",
        "zipcode": 40330,
        "district_code": 400409,
        "amphoe_code": 4013,
        "province_code": 40
    },
    {
        "district": "ใหม่นาเพียง",
        "amphoe": "แวงใหญ่",
        "province": "ขอนแก่น",
        "zipcode": 40330,
        "district_code": 401298,
        "amphoe_code": 4013,
        "province_code": 40
    },
    {
        "district": "ซับสมบูรณ์",
        "amphoe": "โคกโพธิ์ไชย",
        "province": "ขอนแก่น",
        "zipcode": 40160,
        "district_code": 401711,
        "amphoe_code": 4022,
        "province_code": 40
    },
    {
        "district": "นาแพง",
        "amphoe": "โคกโพธิ์ไชย",
        "province": "ขอนแก่น",
        "zipcode": 40160,
        "district_code": 401712,
        "amphoe_code": 4022,
        "province_code": 40
    },
    {
        "district": "บ้านโคก",
        "amphoe": "โคกโพธิ์ไชย",
        "province": "ขอนแก่น",
        "zipcode": 40160,
        "district_code": 401603,
        "amphoe_code": 4022,
        "province_code": 40
    },
    {
        "district": "โพธิ์ไชย",
        "amphoe": "โคกโพธิ์ไชย",
        "province": "ขอนแก่น",
        "zipcode": 40160,
        "district_code": 401709,
        "amphoe_code": 4022,
        "province_code": 40
    },
    {
        "district": "บ้านหัน",
        "amphoe": "โนนศิลา",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 401008,
        "amphoe_code": 4025,
        "province_code": 40
    },
    {
        "district": "หนองปลาหมอ",
        "amphoe": "โนนศิลา",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 401020,
        "amphoe_code": 4025,
        "province_code": 40
    },
    {
        "district": "เปือยใหญ่",
        "amphoe": "โนนศิลา",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 401006,
        "amphoe_code": 4025,
        "province_code": 40
    },
    {
        "district": "โนนศิลา",
        "amphoe": "โนนศิลา",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 401007,
        "amphoe_code": 4025,
        "province_code": 40
    },
    {
        "district": "โนนแดง",
        "amphoe": "โนนศิลา",
        "province": "ขอนแก่น",
        "zipcode": 40110,
        "district_code": 401019,
        "amphoe_code": 4025,
        "province_code": 40
    },
    {
        "district": "ขลุง",
        "amphoe": "ขลุง",
        "province": "จันทบุรี",
        "zipcode": 22110,
        "district_code": 220201,
        "amphoe_code": 2202,
        "province_code": 22
    },
    {
        "district": "ซึ้ง",
        "amphoe": "ขลุง",
        "province": "จันทบุรี",
        "zipcode": 22110,
        "district_code": 220207,
        "amphoe_code": 2202,
        "province_code": 22
    },
    {
        "district": "ตกพรม",
        "amphoe": "ขลุง",
        "province": "จันทบุรี",
        "zipcode": 22110,
        "district_code": 220211,
        "amphoe_code": 2202,
        "province_code": 22
    },
    {
        "district": "ตรอกนอง",
        "amphoe": "ขลุง",
        "province": "จันทบุรี",
        "zipcode": 22110,
        "district_code": 220210,
        "amphoe_code": 2202,
        "province_code": 22
    },
    {
        "district": "ตะปอน",
        "amphoe": "ขลุง",
        "province": "จันทบุรี",
        "zipcode": 22110,
        "district_code": 220204,
        "amphoe_code": 2202,
        "province_code": 22
    },
    {
        "district": "บางชัน",
        "amphoe": "ขลุง",
        "province": "จันทบุรี",
        "zipcode": 22110,
        "district_code": 220205,
        "amphoe_code": 2202,
        "province_code": 22
    },
    {
        "district": "บ่อ",
        "amphoe": "ขลุง",
        "province": "จันทบุรี",
        "zipcode": 22110,
        "district_code": 220202,
        "amphoe_code": 2202,
        "province_code": 22
    },
    {
        "district": "บ่อเวฬุ",
        "amphoe": "ขลุง",
        "province": "จันทบุรี",
        "zipcode": 22150,
        "district_code": 220212,
        "amphoe_code": 2202,
        "province_code": 22
    },
    {
        "district": "มาบไพ",
        "amphoe": "ขลุง",
        "province": "จันทบุรี",
        "zipcode": 22110,
        "district_code": 220208,
        "amphoe_code": 2202,
        "province_code": 22
    },
    {
        "district": "วังสรรพรส",
        "amphoe": "ขลุง",
        "province": "จันทบุรี",
        "zipcode": 22110,
        "district_code": 220209,
        "amphoe_code": 2202,
        "province_code": 22
    },
    {
        "district": "วันยาว",
        "amphoe": "ขลุง",
        "province": "จันทบุรี",
        "zipcode": 22110,
        "district_code": 220206,
        "amphoe_code": 2202,
        "province_code": 22
    },
    {
        "district": "เกวียนหัก",
        "amphoe": "ขลุง",
        "province": "จันทบุรี",
        "zipcode": 22110,
        "district_code": 220203,
        "amphoe_code": 2202,
        "province_code": 22
    },
    {
        "district": "คลองขุด",
        "amphoe": "ท่าใหม่",
        "province": "จันทบุรี",
        "zipcode": 22120,
        "district_code": 220314,
        "amphoe_code": 2203,
        "province_code": 22
    },
    {
        "district": "ตะกาดเง้า",
        "amphoe": "ท่าใหม่",
        "province": "จันทบุรี",
        "zipcode": 22120,
        "district_code": 220313,
        "amphoe_code": 2203,
        "province_code": 22
    },
    {
        "district": "ทุ่งเบญจา",
        "amphoe": "ท่าใหม่",
        "province": "จันทบุรี",
        "zipcode": 22170,
        "district_code": 220309,
        "amphoe_code": 2203,
        "province_code": 22
    },
    {
        "district": "ท่าใหม่",
        "amphoe": "ท่าใหม่",
        "province": "จันทบุรี",
        "zipcode": 22120,
        "district_code": 220301,
        "amphoe_code": 2203,
        "province_code": 22
    },
    {
        "district": "บ่อพุ",
        "amphoe": "ท่าใหม่",
        "province": "จันทบุรี",
        "zipcode": 22120,
        "district_code": 220304,
        "amphoe_code": 2203,
        "province_code": 22
    },
    {
        "district": "พลอยแหวน",
        "amphoe": "ท่าใหม่",
        "province": "จันทบุรี",
        "zipcode": 22120,
        "district_code": 220305,
        "amphoe_code": 2203,
        "province_code": 22
    },
    {
        "district": "ยายร้า",
        "amphoe": "ท่าใหม่",
        "province": "จันทบุรี",
        "zipcode": 22120,
        "district_code": 220302,
        "amphoe_code": 2203,
        "province_code": 22
    },
    {
        "district": "รำพัน",
        "amphoe": "ท่าใหม่",
        "province": "จันทบุรี",
        "zipcode": 22170,
        "district_code": 220311,
        "amphoe_code": 2203,
        "province_code": 22
    },
    {
        "district": "สองพี่น้อง",
        "amphoe": "ท่าใหม่",
        "province": "จันทบุรี",
        "zipcode": 22120,
        "district_code": 220308,
        "amphoe_code": 2203,
        "province_code": 22
    },
    {
        "district": "สีพยา",
        "amphoe": "ท่าใหม่",
        "province": "จันทบุรี",
        "zipcode": 22120,
        "district_code": 220303,
        "amphoe_code": 2203,
        "province_code": 22
    },
    {
        "district": "เขาบายศรี",
        "amphoe": "ท่าใหม่",
        "province": "จันทบุรี",
        "zipcode": 22120,
        "district_code": 220307,
        "amphoe_code": 2203,
        "province_code": 22
    },
    {
        "district": "เขาวัว",
        "amphoe": "ท่าใหม่",
        "province": "จันทบุรี",
        "zipcode": 22120,
        "district_code": 220306,
        "amphoe_code": 2203,
        "province_code": 22
    },
    {
        "district": "เขาแก้ว",
        "amphoe": "ท่าใหม่",
        "province": "จันทบุรี",
        "zipcode": 22170,
        "district_code": 220324,
        "amphoe_code": 2203,
        "province_code": 22
    },
    {
        "district": "โขมง",
        "amphoe": "ท่าใหม่",
        "province": "จันทบุรี",
        "zipcode": 22170,
        "district_code": 220312,
        "amphoe_code": 2203,
        "province_code": 22
    },
    {
        "district": "กระแจะ",
        "amphoe": "นายายอาม",
        "province": "จันทบุรี",
        "zipcode": 22170,
        "district_code": 220315,
        "amphoe_code": 2209,
        "province_code": 22
    },
    {
        "district": "ช้างข้าม",
        "amphoe": "นายายอาม",
        "province": "จันทบุรี",
        "zipcode": 22160,
        "district_code": 220317,
        "amphoe_code": 2209,
        "province_code": 22
    },
    {
        "district": "นายายอาม",
        "amphoe": "นายายอาม",
        "province": "จันทบุรี",
        "zipcode": 22160,
        "district_code": 220318,
        "amphoe_code": 2209,
        "province_code": 22
    },
    {
        "district": "วังโตนด",
        "amphoe": "นายายอาม",
        "province": "จันทบุรี",
        "zipcode": 22170,
        "district_code": 220310,
        "amphoe_code": 2209,
        "province_code": 22
    },
    {
        "district": "วังใหม่",
        "amphoe": "นายายอาม",
        "province": "จันทบุรี",
        "zipcode": 22170,
        "district_code": 220906,
        "amphoe_code": 2209,
        "province_code": 22
    },
    {
        "district": "สนามไชย",
        "amphoe": "นายายอาม",
        "province": "จันทบุรี",
        "zipcode": 22170,
        "district_code": 220316,
        "amphoe_code": 2209,
        "province_code": 22
    },
    {
        "district": "ฉมัน",
        "amphoe": "มะขาม",
        "province": "จันทบุรี",
        "zipcode": 22150,
        "district_code": 220506,
        "amphoe_code": 2205,
        "province_code": 22
    },
    {
        "district": "ท่าหลวง",
        "amphoe": "มะขาม",
        "province": "จันทบุรี",
        "zipcode": 22150,
        "district_code": 220502,
        "amphoe_code": 2205,
        "province_code": 22
    },
    {
        "district": "ปัถวี",
        "amphoe": "มะขาม",
        "province": "จันทบุรี",
        "zipcode": 22150,
        "district_code": 220503,
        "amphoe_code": 2205,
        "province_code": 22
    },
    {
        "district": "มะขาม",
        "amphoe": "มะขาม",
        "province": "จันทบุรี",
        "zipcode": 22150,
        "district_code": 220501,
        "amphoe_code": 2205,
        "province_code": 22
    },
    {
        "district": "วังแซ้ม",
        "amphoe": "มะขาม",
        "province": "จันทบุรี",
        "zipcode": 22150,
        "district_code": 220504,
        "amphoe_code": 2205,
        "province_code": 22
    },
    {
        "district": "อ่างคีรี",
        "amphoe": "มะขาม",
        "province": "จันทบุรี",
        "zipcode": 22150,
        "district_code": 220508,
        "amphoe_code": 2205,
        "province_code": 22
    },
    {
        "district": "ทรายขาว",
        "amphoe": "สอยดาว",
        "province": "จันทบุรี",
        "zipcode": 22180,
        "district_code": 220403,
        "amphoe_code": 2207,
        "province_code": 22
    },
    {
        "district": "ทับช้าง",
        "amphoe": "สอยดาว",
        "province": "จันทบุรี",
        "zipcode": 22180,
        "district_code": 220408,
        "amphoe_code": 2207,
        "province_code": 22
    },
    {
        "district": "ทุ่งขนาน",
        "amphoe": "สอยดาว",
        "province": "จันทบุรี",
        "zipcode": 22180,
        "district_code": 220406,
        "amphoe_code": 2207,
        "province_code": 22
    },
    {
        "district": "ปะตง",
        "amphoe": "สอยดาว",
        "province": "จันทบุรี",
        "zipcode": 22180,
        "district_code": 220405,
        "amphoe_code": 2207,
        "province_code": 22
    },
    {
        "district": "สะตอน",
        "amphoe": "สอยดาว",
        "province": "จันทบุรี",
        "zipcode": 22180,
        "district_code": 220407,
        "amphoe_code": 2207,
        "province_code": 22
    },
    {
        "district": "คลองพลู",
        "amphoe": "เขาคิชฌกูฏ",
        "province": "จันทบุรี",
        "zipcode": 22210,
        "district_code": 220509,
        "amphoe_code": 2210,
        "province_code": 22
    },
    {
        "district": "จันทเขลม",
        "amphoe": "เขาคิชฌกูฏ",
        "province": "จันทบุรี",
        "zipcode": 22210,
        "district_code": 221005,
        "amphoe_code": 2210,
        "province_code": 22
    },
    {
        "district": "ชากไทย",
        "amphoe": "เขาคิชฌกูฏ",
        "province": "จันทบุรี",
        "zipcode": 22210,
        "district_code": 221001,
        "amphoe_code": 2210,
        "province_code": 22
    },
    {
        "district": "ตะเคียนทอง",
        "amphoe": "เขาคิชฌกูฏ",
        "province": "จันทบุรี",
        "zipcode": 22210,
        "district_code": 220507,
        "amphoe_code": 2210,
        "province_code": 22
    },
    {
        "district": "พลวง",
        "amphoe": "เขาคิชฌกูฏ",
        "province": "จันทบุรี",
        "zipcode": 22210,
        "district_code": 220505,
        "amphoe_code": 2210,
        "province_code": 22
    },
    {
        "district": "คมบาง",
        "amphoe": "เมืองจันทบุรี",
        "province": "จันทบุรี",
        "zipcode": 22000,
        "district_code": 220105,
        "amphoe_code": 2201,
        "province_code": 22
    },
    {
        "district": "คลองนารายณ์",
        "amphoe": "เมืองจันทบุรี",
        "province": "จันทบุรี",
        "zipcode": 22000,
        "district_code": 220103,
        "amphoe_code": 2201,
        "province_code": 22
    },
    {
        "district": "จันทนิมิต",
        "amphoe": "เมืองจันทบุรี",
        "province": "จันทบุรี",
        "zipcode": 22000,
        "district_code": 220107,
        "amphoe_code": 2201,
        "province_code": 22
    },
    {
        "district": "ตลาด",
        "amphoe": "เมืองจันทบุรี",
        "province": "จันทบุรี",
        "zipcode": 22000,
        "district_code": 220101,
        "amphoe_code": 2201,
        "province_code": 22
    },
    {
        "district": "ท่าช้าง",
        "amphoe": "เมืองจันทบุรี",
        "province": "จันทบุรี",
        "zipcode": 22000,
        "district_code": 220106,
        "amphoe_code": 2201,
        "province_code": 22
    },
    {
        "district": "บางกะจะ",
        "amphoe": "เมืองจันทบุรี",
        "province": "จันทบุรี",
        "zipcode": 22000,
        "district_code": 220108,
        "amphoe_code": 2201,
        "province_code": 22
    },
    {
        "district": "พลับพลา",
        "amphoe": "เมืองจันทบุรี",
        "province": "จันทบุรี",
        "zipcode": 22000,
        "district_code": 220111,
        "amphoe_code": 2201,
        "province_code": 22
    },
    {
        "district": "วัดใหม่",
        "amphoe": "เมืองจันทบุรี",
        "province": "จันทบุรี",
        "zipcode": 22000,
        "district_code": 220102,
        "amphoe_code": 2201,
        "province_code": 22
    },
    {
        "district": "หนองบัว",
        "amphoe": "เมืองจันทบุรี",
        "province": "จันทบุรี",
        "zipcode": 22000,
        "district_code": 220110,
        "amphoe_code": 2201,
        "province_code": 22
    },
    {
        "district": "เกาะขวาง",
        "amphoe": "เมืองจันทบุรี",
        "province": "จันทบุรี",
        "zipcode": 22000,
        "district_code": 220104,
        "amphoe_code": 2201,
        "province_code": 22
    },
    {
        "district": "แสลง",
        "amphoe": "เมืองจันทบุรี",
        "province": "จันทบุรี",
        "zipcode": 22000,
        "district_code": 220109,
        "amphoe_code": 2201,
        "province_code": 22
    },
    {
        "district": "ขุนซ่อง",
        "amphoe": "แก่งหางแมว",
        "province": "จันทบุรี",
        "zipcode": 22160,
        "district_code": 220323,
        "amphoe_code": 2208,
        "province_code": 22
    },
    {
        "district": "พวา",
        "amphoe": "แก่งหางแมว",
        "province": "จันทบุรี",
        "zipcode": 22160,
        "district_code": 220322,
        "amphoe_code": 2208,
        "province_code": 22
    },
    {
        "district": "สามพี่น้อง",
        "amphoe": "แก่งหางแมว",
        "province": "จันทบุรี",
        "zipcode": 22160,
        "district_code": 220320,
        "amphoe_code": 2208,
        "province_code": 22
    },
    {
        "district": "เขาวงกต",
        "amphoe": "แก่งหางแมว",
        "province": "จันทบุรี",
        "zipcode": 22160,
        "district_code": 220321,
        "amphoe_code": 2208,
        "province_code": 22
    },
    {
        "district": "แก่งหางแมว",
        "amphoe": "แก่งหางแมว",
        "province": "จันทบุรี",
        "zipcode": 22160,
        "district_code": 220319,
        "amphoe_code": 2208,
        "province_code": 22
    },
    {
        "district": "คลองน้ำเค็ม",
        "amphoe": "แหลมสิงห์",
        "province": "จันทบุรี",
        "zipcode": 22190,
        "district_code": 220605,
        "amphoe_code": 2206,
        "province_code": 22
    },
    {
        "district": "บางกะไชย",
        "amphoe": "แหลมสิงห์",
        "province": "จันทบุรี",
        "zipcode": 22120,
        "district_code": 220607,
        "amphoe_code": 2206,
        "province_code": 22
    },
    {
        "district": "บางสระเก้า",
        "amphoe": "แหลมสิงห์",
        "province": "จันทบุรี",
        "zipcode": 22190,
        "district_code": 220606,
        "amphoe_code": 2206,
        "province_code": 22
    },
    {
        "district": "ปากน้ำแหลมสิงห์",
        "amphoe": "แหลมสิงห์",
        "province": "จันทบุรี",
        "zipcode": 22130,
        "district_code": 220601,
        "amphoe_code": 2206,
        "province_code": 22
    },
    {
        "district": "พลิ้ว",
        "amphoe": "แหลมสิงห์",
        "province": "จันทบุรี",
        "zipcode": 22190,
        "district_code": 220604,
        "amphoe_code": 2206,
        "province_code": 22
    },
    {
        "district": "หนองชิ่ม",
        "amphoe": "แหลมสิงห์",
        "province": "จันทบุรี",
        "zipcode": 22130,
        "district_code": 220603,
        "amphoe_code": 2206,
        "province_code": 22
    },
    {
        "district": "เกาะเปริด",
        "amphoe": "แหลมสิงห์",
        "province": "จันทบุรี",
        "zipcode": 22130,
        "district_code": 220602,
        "amphoe_code": 2206,
        "province_code": 22
    },
    {
        "district": "คลองใหญ่",
        "amphoe": "โป่งน้ำร้อน",
        "province": "จันทบุรี",
        "zipcode": 22140,
        "district_code": 220410,
        "amphoe_code": 2204,
        "province_code": 22
    },
    {
        "district": "ทับไทร",
        "amphoe": "โป่งน้ำร้อน",
        "province": "จันทบุรี",
        "zipcode": 22140,
        "district_code": 220401,
        "amphoe_code": 2204,
        "province_code": 22
    },
    {
        "district": "หนองตาคง",
        "amphoe": "โป่งน้ำร้อน",
        "province": "จันทบุรี",
        "zipcode": 22140,
        "district_code": 220404,
        "amphoe_code": 2204,
        "province_code": 22
    },
    {
        "district": "เทพนิมิต",
        "amphoe": "โป่งน้ำร้อน",
        "province": "จันทบุรี",
        "zipcode": 22140,
        "district_code": 220409,
        "amphoe_code": 2204,
        "province_code": 22
    },
    {
        "district": "โป่งน้ำร้อน",
        "amphoe": "โป่งน้ำร้อน",
        "province": "จันทบุรี",
        "zipcode": 22140,
        "district_code": 220402,
        "amphoe_code": 2204,
        "province_code": 22
    },
    {
        "district": "ก้อนแก้ว",
        "amphoe": "คลองเขื่อน",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240202,
        "amphoe_code": 2411,
        "province_code": 24
    },
    {
        "district": "คลองเขื่อน",
        "amphoe": "คลองเขื่อน",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240203,
        "amphoe_code": 2411,
        "province_code": 24
    },
    {
        "district": "บางตลาด",
        "amphoe": "คลองเขื่อน",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24110,
        "district_code": 240207,
        "amphoe_code": 2411,
        "province_code": 24
    },
    {
        "district": "บางเล่า",
        "amphoe": "คลองเขื่อน",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240205,
        "amphoe_code": 2411,
        "province_code": 24
    },
    {
        "district": "บางโรง",
        "amphoe": "คลองเขื่อน",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240206,
        "amphoe_code": 2411,
        "province_code": 24
    },
    {
        "district": "คลองตะเกรา",
        "amphoe": "ท่าตะเกียบ",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24160,
        "district_code": 240806,
        "amphoe_code": 2410,
        "province_code": 24
    },
    {
        "district": "ท่าตะเกียบ",
        "amphoe": "ท่าตะเกียบ",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24160,
        "district_code": 240804,
        "amphoe_code": 2410,
        "province_code": 24
    },
    {
        "district": "ท่าทองหลาง",
        "amphoe": "บางคล้า",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24110,
        "district_code": 240210,
        "amphoe_code": 2402,
        "province_code": 24
    },
    {
        "district": "บางกระเจ็ด",
        "amphoe": "บางคล้า",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24110,
        "district_code": 240208,
        "amphoe_code": 2402,
        "province_code": 24
    },
    {
        "district": "บางคล้า",
        "amphoe": "บางคล้า",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24110,
        "district_code": 240201,
        "amphoe_code": 2402,
        "province_code": 24
    },
    {
        "district": "บางสวน",
        "amphoe": "บางคล้า",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24110,
        "district_code": 240204,
        "amphoe_code": 2402,
        "province_code": 24
    },
    {
        "district": "ปากน้ำ",
        "amphoe": "บางคล้า",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24110,
        "district_code": 240209,
        "amphoe_code": 2402,
        "province_code": 24
    },
    {
        "district": "สาวชะโงก",
        "amphoe": "บางคล้า",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24110,
        "district_code": 240211,
        "amphoe_code": 2402,
        "province_code": 24
    },
    {
        "district": "หัวไทร",
        "amphoe": "บางคล้า",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24110,
        "district_code": 240214,
        "amphoe_code": 2402,
        "province_code": 24
    },
    {
        "district": "เสม็ดเหนือ",
        "amphoe": "บางคล้า",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24110,
        "district_code": 240212,
        "amphoe_code": 2402,
        "province_code": 24
    },
    {
        "district": "เสม็ดใต้",
        "amphoe": "บางคล้า",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24110,
        "district_code": 240213,
        "amphoe_code": 2402,
        "province_code": 24
    },
    {
        "district": "ดอนฉิมพลี",
        "amphoe": "บางน้ำเปรี้ยว",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24170,
        "district_code": 240308,
        "amphoe_code": 2403,
        "province_code": 24
    },
    {
        "district": "ดอนเกาะกา",
        "amphoe": "บางน้ำเปรี้ยว",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24170,
        "district_code": 240306,
        "amphoe_code": 2403,
        "province_code": 24
    },
    {
        "district": "บางขนาก",
        "amphoe": "บางน้ำเปรี้ยว",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24150,
        "district_code": 240302,
        "amphoe_code": 2403,
        "province_code": 24
    },
    {
        "district": "บางน้ำเปรี้ยว",
        "amphoe": "บางน้ำเปรี้ยว",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24150,
        "district_code": 240301,
        "amphoe_code": 2403,
        "province_code": 24
    },
    {
        "district": "บึงน้ำรักษ์",
        "amphoe": "บางน้ำเปรี้ยว",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24170,
        "district_code": 240305,
        "amphoe_code": 2403,
        "province_code": 24
    },
    {
        "district": "ศาลาแดง",
        "amphoe": "บางน้ำเปรี้ยว",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240309,
        "amphoe_code": 2403,
        "province_code": 24
    },
    {
        "district": "สิงโตทอง",
        "amphoe": "บางน้ำเปรี้ยว",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24150,
        "district_code": 240303,
        "amphoe_code": 2403,
        "province_code": 24
    },
    {
        "district": "หมอนทอง",
        "amphoe": "บางน้ำเปรี้ยว",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24150,
        "district_code": 240304,
        "amphoe_code": 2403,
        "province_code": 24
    },
    {
        "district": "โพรงอากาศ",
        "amphoe": "บางน้ำเปรี้ยว",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24150,
        "district_code": 240310,
        "amphoe_code": 2403,
        "province_code": 24
    },
    {
        "district": "โยธะกา",
        "amphoe": "บางน้ำเปรี้ยว",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24150,
        "district_code": 240307,
        "amphoe_code": 2403,
        "province_code": 24
    },
    {
        "district": "ท่าข้าม",
        "amphoe": "บางปะกง",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24130,
        "district_code": 240410,
        "amphoe_code": 2404,
        "province_code": 24
    },
    {
        "district": "ท่าสะอ้าน",
        "amphoe": "บางปะกง",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24130,
        "district_code": 240402,
        "amphoe_code": 2404,
        "province_code": 24
    },
    {
        "district": "บางปะกง",
        "amphoe": "บางปะกง",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24130,
        "district_code": 240401,
        "amphoe_code": 2404,
        "province_code": 24
    },
    {
        "district": "บางผึ้ง",
        "amphoe": "บางปะกง",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24130,
        "district_code": 240405,
        "amphoe_code": 2404,
        "province_code": 24
    },
    {
        "district": "บางวัว",
        "amphoe": "บางปะกง",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24130,
        "district_code": 240403,
        "amphoe_code": 2404,
        "province_code": 24
    },
    {
        "district": "บางสมัคร",
        "amphoe": "บางปะกง",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24180,
        "district_code": 240404,
        "amphoe_code": 2404,
        "province_code": 24
    },
    {
        "district": "บางเกลือ",
        "amphoe": "บางปะกง",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24180,
        "district_code": 240406,
        "amphoe_code": 2404,
        "province_code": 24
    },
    {
        "district": "พิมพา",
        "amphoe": "บางปะกง",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24180,
        "district_code": 240409,
        "amphoe_code": 2404,
        "province_code": 24
    },
    {
        "district": "สองคลอง",
        "amphoe": "บางปะกง",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24130,
        "district_code": 240407,
        "amphoe_code": 2404,
        "province_code": 24
    },
    {
        "district": "หนองจอก",
        "amphoe": "บางปะกง",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24130,
        "district_code": 240408,
        "amphoe_code": 2404,
        "province_code": 24
    },
    {
        "district": "หอมศีล",
        "amphoe": "บางปะกง",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24180,
        "district_code": 240411,
        "amphoe_code": 2404,
        "province_code": 24
    },
    {
        "district": "เขาดิน",
        "amphoe": "บางปะกง",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24130,
        "district_code": 240412,
        "amphoe_code": 2404,
        "province_code": 24
    },
    {
        "district": "คลองขุด",
        "amphoe": "บ้านโพธิ์",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24140,
        "district_code": 240503,
        "amphoe_code": 2405,
        "province_code": 24
    },
    {
        "district": "คลองบ้านโพธิ์",
        "amphoe": "บ้านโพธิ์",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24140,
        "district_code": 240504,
        "amphoe_code": 2405,
        "province_code": 24
    },
    {
        "district": "คลองประเวศ",
        "amphoe": "บ้านโพธิ์",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24140,
        "district_code": 240505,
        "amphoe_code": 2405,
        "province_code": 24
    },
    {
        "district": "ดอนทราย",
        "amphoe": "บ้านโพธิ์",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24140,
        "district_code": 240506,
        "amphoe_code": 2405,
        "province_code": 24
    },
    {
        "district": "ท่าพลับ",
        "amphoe": "บ้านโพธิ์",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24140,
        "district_code": 240508,
        "amphoe_code": 2405,
        "province_code": 24
    },
    {
        "district": "บางกรูด",
        "amphoe": "บ้านโพธิ์",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24140,
        "district_code": 240512,
        "amphoe_code": 2405,
        "province_code": 24
    },
    {
        "district": "บางซ่อน",
        "amphoe": "บ้านโพธิ์",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24140,
        "district_code": 240511,
        "amphoe_code": 2405,
        "province_code": 24
    },
    {
        "district": "บ้านโพธิ์",
        "amphoe": "บ้านโพธิ์",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24140,
        "district_code": 240501,
        "amphoe_code": 2405,
        "province_code": 24
    },
    {
        "district": "ลาดขวาง",
        "amphoe": "บ้านโพธิ์",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24140,
        "district_code": 240514,
        "amphoe_code": 2405,
        "province_code": 24
    },
    {
        "district": "สนามจันทร์",
        "amphoe": "บ้านโพธิ์",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24140,
        "district_code": 240515,
        "amphoe_code": 2405,
        "province_code": 24
    },
    {
        "district": "สิบเอ็ดศอก",
        "amphoe": "บ้านโพธิ์",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24140,
        "district_code": 240517,
        "amphoe_code": 2405,
        "province_code": 24
    },
    {
        "district": "หนองตีนนก",
        "amphoe": "บ้านโพธิ์",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24140,
        "district_code": 240509,
        "amphoe_code": 2405,
        "province_code": 24
    },
    {
        "district": "หนองบัว",
        "amphoe": "บ้านโพธิ์",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24140,
        "district_code": 240510,
        "amphoe_code": 2405,
        "province_code": 24
    },
    {
        "district": "เกาะไร่",
        "amphoe": "บ้านโพธิ์",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24140,
        "district_code": 240502,
        "amphoe_code": 2405,
        "province_code": 24
    },
    {
        "district": "เทพราช",
        "amphoe": "บ้านโพธิ์",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24140,
        "district_code": 240507,
        "amphoe_code": 2405,
        "province_code": 24
    },
    {
        "district": "แสนภูดาษ",
        "amphoe": "บ้านโพธิ์",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24140,
        "district_code": 240516,
        "amphoe_code": 2405,
        "province_code": 24
    },
    {
        "district": "แหลมประดู่",
        "amphoe": "บ้านโพธิ์",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24140,
        "district_code": 240513,
        "amphoe_code": 2405,
        "province_code": 24
    },
    {
        "district": "ท่าถ่าน",
        "amphoe": "พนมสารคาม",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24120,
        "district_code": 240606,
        "amphoe_code": 2406,
        "province_code": 24
    },
    {
        "district": "บ้านซ่อง",
        "amphoe": "พนมสารคาม",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24120,
        "district_code": 240602,
        "amphoe_code": 2406,
        "province_code": 24
    },
    {
        "district": "พนมสารคาม",
        "amphoe": "พนมสารคาม",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24120,
        "district_code": 240603,
        "amphoe_code": 2406,
        "province_code": 24
    },
    {
        "district": "หนองยาว",
        "amphoe": "พนมสารคาม",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24120,
        "district_code": 240605,
        "amphoe_code": 2406,
        "province_code": 24
    },
    {
        "district": "หนองแหน",
        "amphoe": "พนมสารคาม",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24120,
        "district_code": 240607,
        "amphoe_code": 2406,
        "province_code": 24
    },
    {
        "district": "เกาะขนุน",
        "amphoe": "พนมสารคาม",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24120,
        "district_code": 240601,
        "amphoe_code": 2406,
        "province_code": 24
    },
    {
        "district": "เขาหินซ้อน",
        "amphoe": "พนมสารคาม",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24120,
        "district_code": 240608,
        "amphoe_code": 2406,
        "province_code": 24
    },
    {
        "district": "เมืองเก่า",
        "amphoe": "พนมสารคาม",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24120,
        "district_code": 240604,
        "amphoe_code": 2406,
        "province_code": 24
    },
    {
        "district": "ดงน้อย",
        "amphoe": "ราชสาส์น",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24120,
        "district_code": 240703,
        "amphoe_code": 2407,
        "province_code": 24
    },
    {
        "district": "บางคา",
        "amphoe": "ราชสาส์น",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24120,
        "district_code": 240701,
        "amphoe_code": 2407,
        "province_code": 24
    },
    {
        "district": "เมืองใหม่",
        "amphoe": "ราชสาส์น",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24120,
        "district_code": 240702,
        "amphoe_code": 2407,
        "province_code": 24
    },
    {
        "district": "คู้ยายหมี",
        "amphoe": "สนามชัยเขต",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24160,
        "district_code": 240801,
        "amphoe_code": 2408,
        "province_code": 24
    },
    {
        "district": "ทุ่งพระยา",
        "amphoe": "สนามชัยเขต",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24160,
        "district_code": 240803,
        "amphoe_code": 2408,
        "province_code": 24
    },
    {
        "district": "ท่ากระดาน",
        "amphoe": "สนามชัยเขต",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24160,
        "district_code": 240802,
        "amphoe_code": 2408,
        "province_code": 24
    },
    {
        "district": "ลาดกระทิง",
        "amphoe": "สนามชัยเขต",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24160,
        "district_code": 240805,
        "amphoe_code": 2408,
        "province_code": 24
    },
    {
        "district": "คลองจุกกระเฌอ",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240107,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "คลองนครเนื่องเขต",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240110,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "คลองนา",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240104,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "คลองหลวงแพ่ง",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240118,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "คลองอุดมชลจร",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240117,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "คลองเปรง",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240116,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "ท่าไข่",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240102,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "บางกะไห",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240114,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "บางขวัญ",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240109,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "บางตีนเป็ด",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240105,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "บางพระ",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240113,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "บางเตย",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240119,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "บางแก้ว",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240108,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "บางไผ่",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240106,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "บ้านใหม่",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240103,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "วังตะเคียน",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240111,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "หนามแดง",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240115,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "หน้าเมือง",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240101,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "โสธร",
        "amphoe": "เมืองฉะเชิงเทรา",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24000,
        "district_code": 240112,
        "amphoe_code": 2401,
        "province_code": 24
    },
    {
        "district": "วังเย็น",
        "amphoe": "แปลงยาว",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24190,
        "district_code": 240902,
        "amphoe_code": 2409,
        "province_code": 24
    },
    {
        "district": "หนองไม้แก่น",
        "amphoe": "แปลงยาว",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24190,
        "district_code": 240904,
        "amphoe_code": 2409,
        "province_code": 24
    },
    {
        "district": "หัวสำโรง",
        "amphoe": "แปลงยาว",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24190,
        "district_code": 240903,
        "amphoe_code": 2409,
        "province_code": 24
    },
    {
        "district": "แปลงยาว",
        "amphoe": "แปลงยาว",
        "province": "ฉะเชิงเทรา",
        "zipcode": 24190,
        "district_code": 240901,
        "amphoe_code": 2409,
        "province_code": 24
    },
    {
        "district": "ตะเคียนเตี้ย",
        "amphoe": "บางละมุง",
        "province": "ชลบุรี",
        "zipcode": 20150,
        "district_code": 200407,
        "amphoe_code": 2004,
        "province_code": 20
    },
    {
        "district": "นาเกลือ",
        "amphoe": "บางละมุง",
        "province": "ชลบุรี",
        "zipcode": 20150,
        "district_code": 200408,
        "amphoe_code": 2004,
        "province_code": 20
    },
    {
        "district": "บางละมุง",
        "amphoe": "บางละมุง",
        "province": "ชลบุรี",
        "zipcode": 20150,
        "district_code": 200401,
        "amphoe_code": 2004,
        "province_code": 20
    },
    {
        "district": "หนองปรือ",
        "amphoe": "บางละมุง",
        "province": "ชลบุรี",
        "zipcode": 20150,
        "district_code": 200402,
        "amphoe_code": 2004,
        "province_code": 20
    },
    {
        "district": "หนองปลาไหล",
        "amphoe": "บางละมุง",
        "province": "ชลบุรี",
        "zipcode": 20150,
        "district_code": 200403,
        "amphoe_code": 2004,
        "province_code": 20
    },
    {
        "district": "ห้วยใหญ่",
        "amphoe": "บางละมุง",
        "province": "ชลบุรี",
        "zipcode": 20150,
        "district_code": 200406,
        "amphoe_code": 2004,
        "province_code": 20
    },
    {
        "district": "เขาไม้แก้ว",
        "amphoe": "บางละมุง",
        "province": "ชลบุรี",
        "zipcode": 20150,
        "district_code": 200405,
        "amphoe_code": 2004,
        "province_code": 20
    },
    {
        "district": "โป่ง",
        "amphoe": "บางละมุง",
        "province": "ชลบุรี",
        "zipcode": 20150,
        "district_code": 200404,
        "amphoe_code": 2004,
        "province_code": 20
    },
    {
        "district": "ธาตุทอง",
        "amphoe": "บ่อทอง",
        "province": "ชลบุรี",
        "zipcode": 20270,
        "district_code": 201004,
        "amphoe_code": 2010,
        "province_code": 20
    },
    {
        "district": "บ่อกวางทอง",
        "amphoe": "บ่อทอง",
        "province": "ชลบุรี",
        "zipcode": 20270,
        "district_code": 200697,
        "amphoe_code": 2010,
        "province_code": 20
    },
    {
        "district": "บ่อทอง",
        "amphoe": "บ่อทอง",
        "province": "ชลบุรี",
        "zipcode": 20270,
        "district_code": 200699,
        "amphoe_code": 2010,
        "province_code": 20
    },
    {
        "district": "พลวงทอง",
        "amphoe": "บ่อทอง",
        "province": "ชลบุรี",
        "zipcode": 20270,
        "district_code": 201006,
        "amphoe_code": 2010,
        "province_code": 20
    },
    {
        "district": "วัดสุวรรณ",
        "amphoe": "บ่อทอง",
        "province": "ชลบุรี",
        "zipcode": 20270,
        "district_code": 200698,
        "amphoe_code": 2010,
        "province_code": 20
    },
    {
        "district": "เกษตรสุวรรณ",
        "amphoe": "บ่อทอง",
        "province": "ชลบุรี",
        "zipcode": 20270,
        "district_code": 201005,
        "amphoe_code": 2010,
        "province_code": 20
    },
    {
        "district": "คลองกิ่ว",
        "amphoe": "บ้านบึง",
        "province": "ชลบุรี",
        "zipcode": 20220,
        "district_code": 200202,
        "amphoe_code": 2002,
        "province_code": 20
    },
    {
        "district": "บ้านบึง",
        "amphoe": "บ้านบึง",
        "province": "ชลบุรี",
        "zipcode": 20170,
        "district_code": 200201,
        "amphoe_code": 2002,
        "province_code": 20
    },
    {
        "district": "มาบไผ่",
        "amphoe": "บ้านบึง",
        "province": "ชลบุรี",
        "zipcode": 20170,
        "district_code": 200203,
        "amphoe_code": 2002,
        "province_code": 20
    },
    {
        "district": "หนองชาก",
        "amphoe": "บ้านบึง",
        "province": "ชลบุรี",
        "zipcode": 20170,
        "district_code": 200206,
        "amphoe_code": 2002,
        "province_code": 20
    },
    {
        "district": "หนองซ้ำซาก",
        "amphoe": "บ้านบึง",
        "province": "ชลบุรี",
        "zipcode": 20170,
        "district_code": 200204,
        "amphoe_code": 2002,
        "province_code": 20
    },
    {
        "district": "หนองบอนแดง",
        "amphoe": "บ้านบึง",
        "province": "ชลบุรี",
        "zipcode": 20170,
        "district_code": 200205,
        "amphoe_code": 2002,
        "province_code": 20
    },
    {
        "district": "หนองอิรุณ",
        "amphoe": "บ้านบึง",
        "province": "ชลบุรี",
        "zipcode": 20220,
        "district_code": 200207,
        "amphoe_code": 2002,
        "province_code": 20
    },
    {
        "district": "หนองไผ่แก้ว",
        "amphoe": "บ้านบึง",
        "province": "ชลบุรี",
        "zipcode": 20220,
        "district_code": 200208,
        "amphoe_code": 2002,
        "province_code": 20
    },
    {
        "district": "กุฎโง้ง",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200609,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "ทุ่งขวาง",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200615,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "ท่าข้าม",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200611,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "นามะตูม",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200622,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "นาวังหิน",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200617,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "นาเริก",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200605,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "บ้านช้าง",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200618,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "บ้านเซิด",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200604,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "พนัสนิคม",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200601,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "วัดหลวง",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200603,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "วัดโบสถ์",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200608,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "สระสี่เหลี่ยม",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200607,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "หนองขยาด",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200614,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "หนองปรือ",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200402,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "หนองเหียง",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200616,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "หน้าพระธาตุ",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200602,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "หมอนนาง",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200606,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "หัวถนน",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200610,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "โคกเพลาะ",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200620,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "ไร่หลักทอง",
        "amphoe": "พนัสนิคม",
        "province": "ชลบุรี",
        "zipcode": 20140,
        "district_code": 200621,
        "amphoe_code": 2006,
        "province_code": 20
    },
    {
        "district": "บางนาง",
        "amphoe": "พานทอง",
        "province": "ชลบุรี",
        "zipcode": 20160,
        "district_code": 200509,
        "amphoe_code": 2005,
        "province_code": 20
    },
    {
        "district": "บางหัก",
        "amphoe": "พานทอง",
        "province": "ชลบุรี",
        "zipcode": 20160,
        "district_code": 200511,
        "amphoe_code": 2005,
        "province_code": 20
    },
    {
        "district": "บ้านเก่า",
        "amphoe": "พานทอง",
        "province": "ชลบุรี",
        "zipcode": 20160,
        "district_code": 200507,
        "amphoe_code": 2005,
        "province_code": 20
    },
    {
        "district": "พานทอง",
        "amphoe": "พานทอง",
        "province": "ชลบุรี",
        "zipcode": 20160,
        "district_code": 200501,
        "amphoe_code": 2005,
        "province_code": 20
    },
    {
        "district": "มาบโป่ง",
        "amphoe": "พานทอง",
        "province": "ชลบุรี",
        "zipcode": 20160,
        "district_code": 200503,
        "amphoe_code": 2005,
        "province_code": 20
    },
    {
        "district": "หนองกะขะ",
        "amphoe": "พานทอง",
        "province": "ชลบุรี",
        "zipcode": 20160,
        "district_code": 200504,
        "amphoe_code": 2005,
        "province_code": 20
    },
    {
        "district": "หนองตำลึง",
        "amphoe": "พานทอง",
        "province": "ชลบุรี",
        "zipcode": 20160,
        "district_code": 200502,
        "amphoe_code": 2005,
        "province_code": 20
    },
    {
        "district": "หนองหงษ์",
        "amphoe": "พานทอง",
        "province": "ชลบุรี",
        "zipcode": 20160,
        "district_code": 200505,
        "amphoe_code": 2005,
        "province_code": 20
    },
    {
        "district": "หน้าประดู่",
        "amphoe": "พานทอง",
        "province": "ชลบุรี",
        "zipcode": 20160,
        "district_code": 200508,
        "amphoe_code": 2005,
        "province_code": 20
    },
    {
        "district": "เกาะลอย",
        "amphoe": "พานทอง",
        "province": "ชลบุรี",
        "zipcode": 20160,
        "district_code": 200510,
        "amphoe_code": 2005,
        "province_code": 20
    },
    {
        "district": "โคกขี้หนอน",
        "amphoe": "พานทอง",
        "province": "ชลบุรี",
        "zipcode": 20160,
        "district_code": 200506,
        "amphoe_code": 2005,
        "province_code": 20
    },
    {
        "district": "ทุ่งสุขลา",
        "amphoe": "ศรีราชา",
        "province": "ชลบุรี",
        "zipcode": 20230,
        "district_code": 200703,
        "amphoe_code": 2007,
        "province_code": 20
    },
    {
        "district": "บางพระ",
        "amphoe": "ศรีราชา",
        "province": "ชลบุรี",
        "zipcode": 20110,
        "district_code": 200707,
        "amphoe_code": 2007,
        "province_code": 20
    },
    {
        "district": "บึง",
        "amphoe": "ศรีราชา",
        "province": "ชลบุรี",
        "zipcode": 20230,
        "district_code": 200704,
        "amphoe_code": 2007,
        "province_code": 20
    },
    {
        "district": "บ่อวิน",
        "amphoe": "ศรีราชา",
        "province": "ชลบุรี",
        "zipcode": 20230,
        "district_code": 200708,
        "amphoe_code": 2007,
        "province_code": 20
    },
    {
        "district": "ศรีราชา",
        "amphoe": "ศรีราชา",
        "province": "ชลบุรี",
        "zipcode": 20110,
        "district_code": 200701,
        "amphoe_code": 2007,
        "province_code": 20
    },
    {
        "district": "สุรศักดิ์",
        "amphoe": "ศรีราชา",
        "province": "ชลบุรี",
        "zipcode": 20110,
        "district_code": 200702,
        "amphoe_code": 2007,
        "province_code": 20
    },
    {
        "district": "หนองขาม",
        "amphoe": "ศรีราชา",
        "province": "ชลบุรี",
        "zipcode": 20230,
        "district_code": 200705,
        "amphoe_code": 2007,
        "province_code": 20
    },
    {
        "district": "เขาคันทรง",
        "amphoe": "ศรีราชา",
        "province": "ชลบุรี",
        "zipcode": 20110,
        "district_code": 200706,
        "amphoe_code": 2007,
        "province_code": 20
    },
    {
        "district": "นาจอมเทียน",
        "amphoe": "สัตหีบ",
        "province": "ชลบุรี",
        "zipcode": 20250,
        "district_code": 200902,
        "amphoe_code": 2009,
        "province_code": 20
    },
    {
        "district": "บางเสร่",
        "amphoe": "สัตหีบ",
        "province": "ชลบุรี",
        "zipcode": 20250,
        "district_code": 200904,
        "amphoe_code": 2009,
        "province_code": 20
    },
    {
        "district": "พลูตาหลวง",
        "amphoe": "สัตหีบ",
        "province": "ชลบุรี",
        "zipcode": 20180,
        "district_code": 200903,
        "amphoe_code": 2009,
        "province_code": 20
    },
    {
        "district": "สัตหีบ",
        "amphoe": "สัตหีบ",
        "province": "ชลบุรี",
        "zipcode": 20180,
        "district_code": 200901,
        "amphoe_code": 2009,
        "province_code": 20
    },
    {
        "district": "แสมสาร",
        "amphoe": "สัตหีบ",
        "province": "ชลบุรี",
        "zipcode": 20180,
        "district_code": 200905,
        "amphoe_code": 2009,
        "province_code": 20
    },
    {
        "district": "คลองพลู",
        "amphoe": "หนองใหญ่",
        "province": "ชลบุรี",
        "zipcode": 20190,
        "district_code": 200298,
        "amphoe_code": 2003,
        "province_code": 20
    },
    {
        "district": "หนองเสือช้าง",
        "amphoe": "หนองใหญ่",
        "province": "ชลบุรี",
        "zipcode": 20190,
        "district_code": 200297,
        "amphoe_code": 2003,
        "province_code": 20
    },
    {
        "district": "หนองใหญ่",
        "amphoe": "หนองใหญ่",
        "province": "ชลบุรี",
        "zipcode": 20190,
        "district_code": 200299,
        "amphoe_code": 2003,
        "province_code": 20
    },
    {
        "district": "ห้างสูง",
        "amphoe": "หนองใหญ่",
        "province": "ชลบุรี",
        "zipcode": 20190,
        "district_code": 200304,
        "amphoe_code": 2003,
        "province_code": 20
    },
    {
        "district": "เขาซก",
        "amphoe": "หนองใหญ่",
        "province": "ชลบุรี",
        "zipcode": 20190,
        "district_code": 200305,
        "amphoe_code": 2003,
        "province_code": 20
    },
    {
        "district": "ท่าบุญมี",
        "amphoe": "เกาะจันทร์",
        "province": "ชลบุรี",
        "zipcode": 20240,
        "district_code": 200612,
        "amphoe_code": 2011,
        "province_code": 20
    },
    {
        "district": "เกาะจันทร์",
        "amphoe": "เกาะจันทร์",
        "province": "ชลบุรี",
        "zipcode": 20240,
        "district_code": 200619,
        "amphoe_code": 2011,
        "province_code": 20
    },
    {
        "district": "ท่าเทววงษ์",
        "amphoe": "เกาะสีชัง",
        "province": "ชลบุรี",
        "zipcode": 20120,
        "district_code": 200799,
        "amphoe_code": 2008,
        "province_code": 20
    },
    {
        "district": "คลองตำหรุ",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20000,
        "district_code": 200112,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "ดอนหัวฬ่อ",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20000,
        "district_code": 200109,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "นาป่า",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20000,
        "district_code": 200107,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "บางทราย",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20000,
        "district_code": 200111,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "บางปลาสร้อย",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20000,
        "district_code": 200101,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "บ้านปึก",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20130,
        "district_code": 200114,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "บ้านสวน",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20000,
        "district_code": 200105,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "บ้านโขด",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20000,
        "district_code": 200103,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "มะขามหย่ง",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20000,
        "district_code": 200102,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "สำนักบก",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20000,
        "district_code": 200118,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "หนองข้างคอก",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20000,
        "district_code": 200108,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "หนองรี",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20000,
        "district_code": 200106,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "หนองไม้แดง",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20000,
        "district_code": 200110,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "ห้วยกะปิ",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20000,
        "district_code": 200115,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "ห้วยกะปิ",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20130,
        "district_code": 200115,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "อ่างศิลา",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20000,
        "district_code": 200117,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "เสม็ด",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20000,
        "district_code": 200116,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "เหมือง",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20130,
        "district_code": 200113,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "แสนสุข",
        "amphoe": "เมืองชลบุรี",
        "province": "ชลบุรี",
        "zipcode": 20130,
        "district_code": 200104,
        "amphoe_code": 2001,
        "province_code": 20
    },
    {
        "district": "คุ้งสำเภา",
        "amphoe": "มโนรมย์",
        "province": "ชัยนาท",
        "zipcode": 17110,
        "district_code": 180201,
        "amphoe_code": 1802,
        "province_code": 18
    },
    {
        "district": "ท่าฉนวน",
        "amphoe": "มโนรมย์",
        "province": "ชัยนาท",
        "zipcode": 17110,
        "district_code": 180204,
        "amphoe_code": 1802,
        "province_code": 18
    },
    {
        "district": "วัดโคก",
        "amphoe": "มโนรมย์",
        "province": "ชัยนาท",
        "zipcode": 17110,
        "district_code": 180202,
        "amphoe_code": 1802,
        "province_code": 18
    },
    {
        "district": "ศิลาดาน",
        "amphoe": "มโนรมย์",
        "province": "ชัยนาท",
        "zipcode": 17110,
        "district_code": 180203,
        "amphoe_code": 1802,
        "province_code": 18
    },
    {
        "district": "หางน้ำสาคร",
        "amphoe": "มโนรมย์",
        "province": "ชัยนาท",
        "zipcode": 17170,
        "district_code": 180205,
        "amphoe_code": 1802,
        "province_code": 18
    },
    {
        "district": "อู่ตะเภา",
        "amphoe": "มโนรมย์",
        "province": "ชัยนาท",
        "zipcode": 17170,
        "district_code": 180207,
        "amphoe_code": 1802,
        "province_code": 18
    },
    {
        "district": "ไร่พัฒนา",
        "amphoe": "มโนรมย์",
        "province": "ชัยนาท",
        "zipcode": 17170,
        "district_code": 180206,
        "amphoe_code": 1802,
        "province_code": 18
    },
    {
        "district": "บ่อแร่",
        "amphoe": "วัดสิงห์",
        "province": "ชัยนาท",
        "zipcode": 17120,
        "district_code": 180307,
        "amphoe_code": 1803,
        "province_code": 18
    },
    {
        "district": "มะขามเฒ่า",
        "amphoe": "วัดสิงห์",
        "province": "ชัยนาท",
        "zipcode": 17120,
        "district_code": 180302,
        "amphoe_code": 1803,
        "province_code": 18
    },
    {
        "district": "วังหมัน",
        "amphoe": "วัดสิงห์",
        "province": "ชัยนาท",
        "zipcode": 17120,
        "district_code": 180311,
        "amphoe_code": 1803,
        "province_code": 18
    },
    {
        "district": "วัดสิงห์",
        "amphoe": "วัดสิงห์",
        "province": "ชัยนาท",
        "zipcode": 17120,
        "district_code": 180301,
        "amphoe_code": 1803,
        "province_code": 18
    },
    {
        "district": "หนองขุ่น",
        "amphoe": "วัดสิงห์",
        "province": "ชัยนาท",
        "zipcode": 17120,
        "district_code": 180306,
        "amphoe_code": 1803,
        "province_code": 18
    },
    {
        "district": "หนองน้อย",
        "amphoe": "วัดสิงห์",
        "province": "ชัยนาท",
        "zipcode": 17120,
        "district_code": 180303,
        "amphoe_code": 1803,
        "province_code": 18
    },
    {
        "district": "หนองบัว",
        "amphoe": "วัดสิงห์",
        "province": "ชัยนาท",
        "zipcode": 17120,
        "district_code": 180304,
        "amphoe_code": 1803,
        "province_code": 18
    },
    {
        "district": "ดงคอน",
        "amphoe": "สรรคบุรี",
        "province": "ชัยนาท",
        "zipcode": 17140,
        "district_code": 180506,
        "amphoe_code": 1805,
        "province_code": 18
    },
    {
        "district": "ดอนกำ",
        "amphoe": "สรรคบุรี",
        "province": "ชัยนาท",
        "zipcode": 17140,
        "district_code": 180507,
        "amphoe_code": 1805,
        "province_code": 18
    },
    {
        "district": "บางขุด",
        "amphoe": "สรรคบุรี",
        "province": "ชัยนาท",
        "zipcode": 17140,
        "district_code": 180505,
        "amphoe_code": 1805,
        "province_code": 18
    },
    {
        "district": "ห้วยกรด",
        "amphoe": "สรรคบุรี",
        "province": "ชัยนาท",
        "zipcode": 17140,
        "district_code": 180503,
        "amphoe_code": 1805,
        "province_code": 18
    },
    {
        "district": "ห้วยกรดพัฒนา",
        "amphoe": "สรรคบุรี",
        "province": "ชัยนาท",
        "zipcode": 17140,
        "district_code": 180508,
        "amphoe_code": 1805,
        "province_code": 18
    },
    {
        "district": "เที่ยงแท้",
        "amphoe": "สรรคบุรี",
        "province": "ชัยนาท",
        "zipcode": 17140,
        "district_code": 180502,
        "amphoe_code": 1805,
        "province_code": 18
    },
    {
        "district": "แพรกศรีราชา",
        "amphoe": "สรรคบุรี",
        "province": "ชัยนาท",
        "zipcode": 17140,
        "district_code": 180501,
        "amphoe_code": 1805,
        "province_code": 18
    },
    {
        "district": "โพงาม",
        "amphoe": "สรรคบุรี",
        "province": "ชัยนาท",
        "zipcode": 17140,
        "district_code": 180504,
        "amphoe_code": 1805,
        "province_code": 18
    },
    {
        "district": "ตลุก",
        "amphoe": "สรรพยา",
        "province": "ชัยนาท",
        "zipcode": 17150,
        "district_code": 180402,
        "amphoe_code": 1804,
        "province_code": 18
    },
    {
        "district": "บางหลวง",
        "amphoe": "สรรพยา",
        "province": "ชัยนาท",
        "zipcode": 17150,
        "district_code": 180406,
        "amphoe_code": 1804,
        "province_code": 18
    },
    {
        "district": "สรรพยา",
        "amphoe": "สรรพยา",
        "province": "ชัยนาท",
        "zipcode": 17150,
        "district_code": 180401,
        "amphoe_code": 1804,
        "province_code": 18
    },
    {
        "district": "หาดอาษา",
        "amphoe": "สรรพยา",
        "province": "ชัยนาท",
        "zipcode": 17150,
        "district_code": 180407,
        "amphoe_code": 1804,
        "province_code": 18
    },
    {
        "district": "เขาแก้ว",
        "amphoe": "สรรพยา",
        "province": "ชัยนาท",
        "zipcode": 17150,
        "district_code": 180403,
        "amphoe_code": 1804,
        "province_code": 18
    },
    {
        "district": "โพนางดำตก",
        "amphoe": "สรรพยา",
        "province": "ชัยนาท",
        "zipcode": 17150,
        "district_code": 180404,
        "amphoe_code": 1804,
        "province_code": 18
    },
    {
        "district": "โพนางดำออก",
        "amphoe": "สรรพยา",
        "province": "ชัยนาท",
        "zipcode": 17150,
        "district_code": 180405,
        "amphoe_code": 1804,
        "province_code": 18
    },
    {
        "district": "กุดจอก",
        "amphoe": "หนองมะโมง",
        "province": "ชัยนาท",
        "zipcode": 17120,
        "district_code": 180308,
        "amphoe_code": 1807,
        "province_code": 18
    },
    {
        "district": "วังตะเคียน",
        "amphoe": "หนองมะโมง",
        "province": "ชัยนาท",
        "zipcode": 17120,
        "district_code": 180309,
        "amphoe_code": 1807,
        "province_code": 18
    },
    {
        "district": "สะพานหิน",
        "amphoe": "หนองมะโมง",
        "province": "ชัยนาท",
        "zipcode": 17120,
        "district_code": 180310,
        "amphoe_code": 1807,
        "province_code": 18
    },
    {
        "district": "หนองมะโมง",
        "amphoe": "หนองมะโมง",
        "province": "ชัยนาท",
        "zipcode": 17120,
        "district_code": 180305,
        "amphoe_code": 1807,
        "province_code": 18
    },
    {
        "district": "บ้านเชี่ยน",
        "amphoe": "หันคา",
        "province": "ชัยนาท",
        "zipcode": 17130,
        "district_code": 180602,
        "amphoe_code": 1806,
        "province_code": 18
    },
    {
        "district": "วังไก่เถื่อน",
        "amphoe": "หันคา",
        "province": "ชัยนาท",
        "zipcode": 17130,
        "district_code": 180608,
        "amphoe_code": 1806,
        "province_code": 18
    },
    {
        "district": "สามง่ามท่าโบสถ์",
        "amphoe": "หันคา",
        "province": "ชัยนาท",
        "zipcode": 17160,
        "district_code": 180611,
        "amphoe_code": 1806,
        "province_code": 18
    },
    {
        "district": "หนองแซง",
        "amphoe": "หันคา",
        "province": "ชัยนาท",
        "zipcode": 17160,
        "district_code": 180606,
        "amphoe_code": 1806,
        "province_code": 18
    },
    {
        "district": "หันคา",
        "amphoe": "หันคา",
        "province": "ชัยนาท",
        "zipcode": 17130,
        "district_code": 180601,
        "amphoe_code": 1806,
        "province_code": 18
    },
    {
        "district": "ห้วยงู",
        "amphoe": "หันคา",
        "province": "ชัยนาท",
        "zipcode": 17160,
        "district_code": 180607,
        "amphoe_code": 1806,
        "province_code": 18
    },
    {
        "district": "เด่นใหญ่",
        "amphoe": "หันคา",
        "province": "ชัยนาท",
        "zipcode": 17130,
        "district_code": 180609,
        "amphoe_code": 1806,
        "province_code": 18
    },
    {
        "district": "ไพรนกยูง",
        "amphoe": "หันคา",
        "province": "ชัยนาท",
        "zipcode": 17130,
        "district_code": 180605,
        "amphoe_code": 1806,
        "province_code": 18
    },
    {
        "district": "กะบกเตี้ย",
        "amphoe": "เนินขาม",
        "province": "ชัยนาท",
        "zipcode": 17130,
        "district_code": 180610,
        "amphoe_code": 1808,
        "province_code": 18
    },
    {
        "district": "สุขเดือนห้า",
        "amphoe": "เนินขาม",
        "province": "ชัยนาท",
        "zipcode": 17130,
        "district_code": 180604,
        "amphoe_code": 1808,
        "province_code": 18
    },
    {
        "district": "เนินขาม",
        "amphoe": "เนินขาม",
        "province": "ชัยนาท",
        "zipcode": 17130,
        "district_code": 180603,
        "amphoe_code": 1808,
        "province_code": 18
    },
    {
        "district": "ชัยนาท",
        "amphoe": "เมืองชัยนาท",
        "province": "ชัยนาท",
        "zipcode": 17000,
        "district_code": 180104,
        "amphoe_code": 1801,
        "province_code": 18
    },
    {
        "district": "ท่าชัย",
        "amphoe": "เมืองชัยนาท",
        "province": "ชัยนาท",
        "zipcode": 17000,
        "district_code": 180103,
        "amphoe_code": 1801,
        "province_code": 18
    },
    {
        "district": "ธรรมามูล",
        "amphoe": "เมืองชัยนาท",
        "province": "ชัยนาท",
        "zipcode": 17000,
        "district_code": 180107,
        "amphoe_code": 1801,
        "province_code": 18
    },
    {
        "district": "นางลือ",
        "amphoe": "เมืองชัยนาท",
        "province": "ชัยนาท",
        "zipcode": 17000,
        "district_code": 180109,
        "amphoe_code": 1801,
        "province_code": 18
    },
    {
        "district": "บ้านกล้วย",
        "amphoe": "เมืองชัยนาท",
        "province": "ชัยนาท",
        "zipcode": 17000,
        "district_code": 180102,
        "amphoe_code": 1801,
        "province_code": 18
    },
    {
        "district": "หาดท่าเสา",
        "amphoe": "เมืองชัยนาท",
        "province": "ชัยนาท",
        "zipcode": 17120,
        "district_code": 180106,
        "amphoe_code": 1801,
        "province_code": 18
    },
    {
        "district": "เขาท่าพระ",
        "amphoe": "เมืองชัยนาท",
        "province": "ชัยนาท",
        "zipcode": 17000,
        "district_code": 180105,
        "amphoe_code": 1801,
        "province_code": 18
    },
    {
        "district": "เสือโฮก",
        "amphoe": "เมืองชัยนาท",
        "province": "ชัยนาท",
        "zipcode": 17000,
        "district_code": 180108,
        "amphoe_code": 1801,
        "province_code": 18
    },
    {
        "district": "ในเมือง",
        "amphoe": "เมืองชัยนาท",
        "province": "ชัยนาท",
        "zipcode": 17000,
        "district_code": 180101,
        "amphoe_code": 1801,
        "province_code": 18
    },
    {
        "district": "คอนสวรรค์",
        "amphoe": "คอนสวรรค์",
        "province": "ชัยภูมิ",
        "zipcode": 36140,
        "district_code": 360301,
        "amphoe_code": 3603,
        "province_code": 36
    },
    {
        "district": "ช่องสามหมอ",
        "amphoe": "คอนสวรรค์",
        "province": "ชัยภูมิ",
        "zipcode": 36140,
        "district_code": 360303,
        "amphoe_code": 3603,
        "province_code": 36
    },
    {
        "district": "บ้านโสก",
        "amphoe": "คอนสวรรค์",
        "province": "ชัยภูมิ",
        "zipcode": 36140,
        "district_code": 360306,
        "amphoe_code": 3603,
        "province_code": 36
    },
    {
        "district": "ยางหวาย",
        "amphoe": "คอนสวรรค์",
        "province": "ชัยภูมิ",
        "zipcode": 36140,
        "district_code": 360302,
        "amphoe_code": 3603,
        "province_code": 36
    },
    {
        "district": "ศรีสำราญ",
        "amphoe": "คอนสวรรค์",
        "province": "ชัยภูมิ",
        "zipcode": 36140,
        "district_code": 360309,
        "amphoe_code": 3603,
        "province_code": 36
    },
    {
        "district": "หนองขาม",
        "amphoe": "คอนสวรรค์",
        "province": "ชัยภูมิ",
        "zipcode": 36140,
        "district_code": 360308,
        "amphoe_code": 3603,
        "province_code": 36
    },
    {
        "district": "ห้วยไร่",
        "amphoe": "คอนสวรรค์",
        "province": "ชัยภูมิ",
        "zipcode": 36140,
        "district_code": 360305,
        "amphoe_code": 3603,
        "province_code": 36
    },
    {
        "district": "โคกมั่งงอย",
        "amphoe": "คอนสวรรค์",
        "province": "ชัยภูมิ",
        "zipcode": 36140,
        "district_code": 360307,
        "amphoe_code": 3603,
        "province_code": 36
    },
    {
        "district": "โนนสะอาด",
        "amphoe": "คอนสวรรค์",
        "province": "ชัยภูมิ",
        "zipcode": 36140,
        "district_code": 360304,
        "amphoe_code": 3603,
        "province_code": 36
    },
    {
        "district": "คอนสาร",
        "amphoe": "คอนสาร",
        "province": "ชัยภูมิ",
        "zipcode": 36180,
        "district_code": 361301,
        "amphoe_code": 3613,
        "province_code": 36
    },
    {
        "district": "ดงกลาง",
        "amphoe": "คอนสาร",
        "province": "ชัยภูมิ",
        "zipcode": 36180,
        "district_code": 361308,
        "amphoe_code": 3613,
        "province_code": 36
    },
    {
        "district": "ดงบัง",
        "amphoe": "คอนสาร",
        "province": "ชัยภูมิ",
        "zipcode": 36180,
        "district_code": 361306,
        "amphoe_code": 3613,
        "province_code": 36
    },
    {
        "district": "ทุ่งนาเลา",
        "amphoe": "คอนสาร",
        "province": "ชัยภูมิ",
        "zipcode": 36180,
        "district_code": 361307,
        "amphoe_code": 3613,
        "province_code": 36
    },
    {
        "district": "ทุ่งพระ",
        "amphoe": "คอนสาร",
        "province": "ชัยภูมิ",
        "zipcode": 36180,
        "district_code": 361302,
        "amphoe_code": 3613,
        "province_code": 36
    },
    {
        "district": "ทุ่งลุยลาย",
        "amphoe": "คอนสาร",
        "province": "ชัยภูมิ",
        "zipcode": 36180,
        "district_code": 361305,
        "amphoe_code": 3613,
        "province_code": 36
    },
    {
        "district": "ห้วยยาง",
        "amphoe": "คอนสาร",
        "province": "ชัยภูมิ",
        "zipcode": 36180,
        "district_code": 361304,
        "amphoe_code": 3613,
        "province_code": 36
    },
    {
        "district": "โนนคูณ",
        "amphoe": "คอนสาร",
        "province": "ชัยภูมิ",
        "zipcode": 36180,
        "district_code": 361303,
        "amphoe_code": 3613,
        "province_code": 36
    },
    {
        "district": "กุดน้ำใส",
        "amphoe": "จัตุรัส",
        "province": "ชัยภูมิ",
        "zipcode": 36130,
        "district_code": 360605,
        "amphoe_code": 3606,
        "province_code": 36
    },
    {
        "district": "บ้านกอก",
        "amphoe": "จัตุรัส",
        "province": "ชัยภูมิ",
        "zipcode": 36130,
        "district_code": 360601,
        "amphoe_code": 3606,
        "province_code": 36
    },
    {
        "district": "บ้านขาม",
        "amphoe": "จัตุรัส",
        "province": "ชัยภูมิ",
        "zipcode": 36130,
        "district_code": 360603,
        "amphoe_code": 3606,
        "province_code": 36
    },
    {
        "district": "ละหาน",
        "amphoe": "จัตุรัส",
        "province": "ชัยภูมิ",
        "zipcode": 36130,
        "district_code": 360607,
        "amphoe_code": 3606,
        "province_code": 36
    },
    {
        "district": "ส้มป่อย",
        "amphoe": "จัตุรัส",
        "province": "ชัยภูมิ",
        "zipcode": 36130,
        "district_code": 360613,
        "amphoe_code": 3606,
        "province_code": 36
    },
    {
        "district": "หนองบัวบาน",
        "amphoe": "จัตุรัส",
        "province": "ชัยภูมิ",
        "zipcode": 36130,
        "district_code": 360602,
        "amphoe_code": 3606,
        "province_code": 36
    },
    {
        "district": "หนองบัวโคก",
        "amphoe": "จัตุรัส",
        "province": "ชัยภูมิ",
        "zipcode": 36220,
        "district_code": 360611,
        "amphoe_code": 3606,
        "province_code": 36
    },
    {
        "district": "หนองบัวใหญ่",
        "amphoe": "จัตุรัส",
        "province": "ชัยภูมิ",
        "zipcode": 36130,
        "district_code": 360610,
        "amphoe_code": 3606,
        "province_code": 36
    },
    {
        "district": "หนองโดน",
        "amphoe": "จัตุรัส",
        "province": "ชัยภูมิ",
        "zipcode": 36130,
        "district_code": 360606,
        "amphoe_code": 3606,
        "province_code": 36
    },
    {
        "district": "ซับใหญ่",
        "amphoe": "ซับใหญ่",
        "province": "ชัยภูมิ",
        "zipcode": 36130,
        "district_code": 360614,
        "amphoe_code": 3616,
        "province_code": 36
    },
    {
        "district": "ตะโกทอง",
        "amphoe": "ซับใหญ่",
        "province": "ชัยภูมิ",
        "zipcode": 36130,
        "district_code": 360616,
        "amphoe_code": 3616,
        "province_code": 36
    },
    {
        "district": "ท่ากูบ",
        "amphoe": "ซับใหญ่",
        "province": "ชัยภูมิ",
        "zipcode": 36130,
        "district_code": 360612,
        "amphoe_code": 3616,
        "province_code": 36
    },
    {
        "district": "บ้านชวน",
        "amphoe": "บำเหน็จณรงค์",
        "province": "ชัยภูมิ",
        "zipcode": 36160,
        "district_code": 360701,
        "amphoe_code": 3607,
        "province_code": 36
    },
    {
        "district": "บ้านตาล",
        "amphoe": "บำเหน็จณรงค์",
        "province": "ชัยภูมิ",
        "zipcode": 36220,
        "district_code": 360703,
        "amphoe_code": 3607,
        "province_code": 36
    },
    {
        "district": "บ้านเพชร",
        "amphoe": "บำเหน็จณรงค์",
        "province": "ชัยภูมิ",
        "zipcode": 36160,
        "district_code": 360702,
        "amphoe_code": 3607,
        "province_code": 36
    },
    {
        "district": "หัวทะเล",
        "amphoe": "บำเหน็จณรงค์",
        "province": "ชัยภูมิ",
        "zipcode": 36220,
        "district_code": 360704,
        "amphoe_code": 3607,
        "province_code": 36
    },
    {
        "district": "เกาะมะนาว",
        "amphoe": "บำเหน็จณรงค์",
        "province": "ชัยภูมิ",
        "zipcode": 36160,
        "district_code": 360706,
        "amphoe_code": 3607,
        "province_code": 36
    },
    {
        "district": "โคกเพชรพัฒนา",
        "amphoe": "บำเหน็จณรงค์",
        "province": "ชัยภูมิ",
        "zipcode": 36160,
        "district_code": 360707,
        "amphoe_code": 3607,
        "province_code": 36
    },
    {
        "district": "โคกเริงรมย์",
        "amphoe": "บำเหน็จณรงค์",
        "province": "ชัยภูมิ",
        "zipcode": 36160,
        "district_code": 360705,
        "amphoe_code": 3607,
        "province_code": 36
    },
    {
        "district": "ชีบน",
        "amphoe": "บ้านเขว้า",
        "province": "ชัยภูมิ",
        "zipcode": 36170,
        "district_code": 360204,
        "amphoe_code": 3602,
        "province_code": 36
    },
    {
        "district": "ตลาดแร้ง",
        "amphoe": "บ้านเขว้า",
        "province": "ชัยภูมิ",
        "zipcode": 36170,
        "district_code": 360202,
        "amphoe_code": 3602,
        "province_code": 36
    },
    {
        "district": "บ้านเขว้า",
        "amphoe": "บ้านเขว้า",
        "province": "ชัยภูมิ",
        "zipcode": 36170,
        "district_code": 360201,
        "amphoe_code": 3602,
        "province_code": 36
    },
    {
        "district": "ภูแลนคา",
        "amphoe": "บ้านเขว้า",
        "province": "ชัยภูมิ",
        "zipcode": 36170,
        "district_code": 360205,
        "amphoe_code": 3602,
        "province_code": 36
    },
    {
        "district": "ลุ่มลำชี",
        "amphoe": "บ้านเขว้า",
        "province": "ชัยภูมิ",
        "zipcode": 36170,
        "district_code": 360203,
        "amphoe_code": 3602,
        "province_code": 36
    },
    {
        "district": "โนนแดง",
        "amphoe": "บ้านเขว้า",
        "province": "ชัยภูมิ",
        "zipcode": 36170,
        "district_code": 360206,
        "amphoe_code": 3602,
        "province_code": 36
    },
    {
        "district": "บ้านเต่า",
        "amphoe": "บ้านแท่น",
        "province": "ชัยภูมิ",
        "zipcode": 36190,
        "district_code": 361104,
        "amphoe_code": 3611,
        "province_code": 36
    },
    {
        "district": "บ้านแท่น",
        "amphoe": "บ้านแท่น",
        "province": "ชัยภูมิ",
        "zipcode": 36190,
        "district_code": 361101,
        "amphoe_code": 3611,
        "province_code": 36
    },
    {
        "district": "สระพัง",
        "amphoe": "บ้านแท่น",
        "province": "ชัยภูมิ",
        "zipcode": 36190,
        "district_code": 361103,
        "amphoe_code": 3611,
        "province_code": 36
    },
    {
        "district": "สามสวน",
        "amphoe": "บ้านแท่น",
        "province": "ชัยภูมิ",
        "zipcode": 36190,
        "district_code": 361102,
        "amphoe_code": 3611,
        "province_code": 36
    },
    {
        "district": "หนองคู",
        "amphoe": "บ้านแท่น",
        "province": "ชัยภูมิ",
        "zipcode": 36190,
        "district_code": 361105,
        "amphoe_code": 3611,
        "province_code": 36
    },
    {
        "district": "บ้านเจียง",
        "amphoe": "ภักดีชุมพล",
        "province": "ชัยภูมิ",
        "zipcode": 36260,
        "district_code": 360199,
        "amphoe_code": 3614,
        "province_code": 36
    },
    {
        "district": "วังทอง",
        "amphoe": "ภักดีชุมพล",
        "province": "ชัยภูมิ",
        "zipcode": 36260,
        "district_code": 360510,
        "amphoe_code": 3614,
        "province_code": 36
    },
    {
        "district": "เจาทอง",
        "amphoe": "ภักดีชุมพล",
        "province": "ชัยภูมิ",
        "zipcode": 36260,
        "district_code": 360198,
        "amphoe_code": 3614,
        "province_code": 36
    },
    {
        "district": "แหลมทอง",
        "amphoe": "ภักดีชุมพล",
        "province": "ชัยภูมิ",
        "zipcode": 36260,
        "district_code": 361404,
        "amphoe_code": 3614,
        "province_code": 36
    },
    {
        "district": "กวางโจน",
        "amphoe": "ภูเขียว",
        "province": "ชัยภูมิ",
        "zipcode": 36110,
        "district_code": 361002,
        "amphoe_code": 3610,
        "province_code": 36
    },
    {
        "district": "กุดยม",
        "amphoe": "ภูเขียว",
        "province": "ชัยภูมิ",
        "zipcode": 36110,
        "district_code": 361005,
        "amphoe_code": 3610,
        "province_code": 36
    },
    {
        "district": "ธาตุทอง",
        "amphoe": "ภูเขียว",
        "province": "ชัยภูมิ",
        "zipcode": 36110,
        "district_code": 361010,
        "amphoe_code": 3610,
        "province_code": 36
    },
    {
        "district": "บ้านดอน",
        "amphoe": "ภูเขียว",
        "province": "ชัยภูมิ",
        "zipcode": 36110,
        "district_code": 361011,
        "amphoe_code": 3610,
        "province_code": 36
    },
    {
        "district": "บ้านเพชร",
        "amphoe": "ภูเขียว",
        "province": "ชัยภูมิ",
        "zipcode": 36110,
        "district_code": 360702,
        "amphoe_code": 3610,
        "province_code": 36
    },
    {
        "district": "บ้านแก้ง",
        "amphoe": "ภูเขียว",
        "province": "ชัยภูมิ",
        "zipcode": 36110,
        "district_code": 361004,
        "amphoe_code": 3610,
        "province_code": 36
    },
    {
        "district": "ผักปัง",
        "amphoe": "ภูเขียว",
        "province": "ชัยภูมิ",
        "zipcode": 36110,
        "district_code": 361001,
        "amphoe_code": 3610,
        "province_code": 36
    },
    {
        "district": "หนองคอนไทย",
        "amphoe": "ภูเขียว",
        "province": "ชัยภูมิ",
        "zipcode": 36110,
        "district_code": 361003,
        "amphoe_code": 3610,
        "province_code": 36
    },
    {
        "district": "หนองตูม",
        "amphoe": "ภูเขียว",
        "province": "ชัยภูมิ",
        "zipcode": 36110,
        "district_code": 361008,
        "amphoe_code": 3610,
        "province_code": 36
    },
    {
        "district": "โคกสะอาด",
        "amphoe": "ภูเขียว",
        "province": "ชัยภูมิ",
        "zipcode": 36110,
        "district_code": 360804,
        "amphoe_code": 3610,
        "province_code": 36
    },
    {
        "district": "โอโล",
        "amphoe": "ภูเขียว",
        "province": "ชัยภูมิ",
        "zipcode": 36110,
        "district_code": 361009,
        "amphoe_code": 3610,
        "province_code": 36
    },
    {
        "district": "วังตะเฆ่",
        "amphoe": "หนองบัวระเหว",
        "province": "ชัยภูมิ",
        "zipcode": 36250,
        "district_code": 360802,
        "amphoe_code": 3608,
        "province_code": 36
    },
    {
        "district": "หนองบัวระเหว",
        "amphoe": "หนองบัวระเหว",
        "province": "ชัยภูมิ",
        "zipcode": 36250,
        "district_code": 360801,
        "amphoe_code": 3608,
        "province_code": 36
    },
    {
        "district": "ห้วยแย้",
        "amphoe": "หนองบัวระเหว",
        "province": "ชัยภูมิ",
        "zipcode": 36250,
        "district_code": 360803,
        "amphoe_code": 3608,
        "province_code": 36
    },
    {
        "district": "โคกสะอาด",
        "amphoe": "หนองบัวระเหว",
        "province": "ชัยภูมิ",
        "zipcode": 36250,
        "district_code": 360804,
        "amphoe_code": 3608,
        "province_code": 36
    },
    {
        "district": "โสกปลาดุก",
        "amphoe": "หนองบัวระเหว",
        "province": "ชัยภูมิ",
        "zipcode": 36250,
        "district_code": 360805,
        "amphoe_code": 3608,
        "province_code": 36
    },
    {
        "district": "กุดชุมแสง",
        "amphoe": "หนองบัวแดง",
        "province": "ชัยภูมิ",
        "zipcode": 36210,
        "district_code": 360502,
        "amphoe_code": 3605,
        "province_code": 36
    },
    {
        "district": "คูเมือง",
        "amphoe": "หนองบัวแดง",
        "province": "ชัยภูมิ",
        "zipcode": 36210,
        "district_code": 360508,
        "amphoe_code": 3605,
        "province_code": 36
    },
    {
        "district": "ถ้ำวัวแดง",
        "amphoe": "หนองบัวแดง",
        "province": "ชัยภูมิ",
        "zipcode": 36210,
        "district_code": 360503,
        "amphoe_code": 3605,
        "province_code": 36
    },
    {
        "district": "ท่าใหญ่",
        "amphoe": "หนองบัวแดง",
        "province": "ชัยภูมิ",
        "zipcode": 36210,
        "district_code": 360509,
        "amphoe_code": 3605,
        "province_code": 36
    },
    {
        "district": "นางแดด",
        "amphoe": "หนองบัวแดง",
        "province": "ชัยภูมิ",
        "zipcode": 36210,
        "district_code": 360504,
        "amphoe_code": 3605,
        "province_code": 36
    },
    {
        "district": "วังชมภู",
        "amphoe": "หนองบัวแดง",
        "province": "ชัยภูมิ",
        "zipcode": 36210,
        "district_code": 360511,
        "amphoe_code": 3605,
        "province_code": 36
    },
    {
        "district": "หนองบัวแดง",
        "amphoe": "หนองบัวแดง",
        "province": "ชัยภูมิ",
        "zipcode": 36210,
        "district_code": 360501,
        "amphoe_code": 3605,
        "province_code": 36
    },
    {
        "district": "หนองแวง",
        "amphoe": "หนองบัวแดง",
        "province": "ชัยภูมิ",
        "zipcode": 36210,
        "district_code": 360507,
        "amphoe_code": 3605,
        "province_code": 36
    },
    {
        "district": "กุดเลาะ",
        "amphoe": "เกษตรสมบูรณ์",
        "province": "ชัยภูมิ",
        "zipcode": 36120,
        "district_code": 360405,
        "amphoe_code": 3604,
        "province_code": 36
    },
    {
        "district": "บ้านบัว",
        "amphoe": "เกษตรสมบูรณ์",
        "province": "ชัยภูมิ",
        "zipcode": 36120,
        "district_code": 360410,
        "amphoe_code": 3604,
        "province_code": 36
    },
    {
        "district": "บ้านยาง",
        "amphoe": "เกษตรสมบูรณ์",
        "province": "ชัยภูมิ",
        "zipcode": 36120,
        "district_code": 360401,
        "amphoe_code": 3604,
        "province_code": 36
    },
    {
        "district": "บ้านหัน",
        "amphoe": "เกษตรสมบูรณ์",
        "province": "ชัยภูมิ",
        "zipcode": 36120,
        "district_code": 360402,
        "amphoe_code": 3604,
        "province_code": 36
    },
    {
        "district": "บ้านเดื่อ",
        "amphoe": "เกษตรสมบูรณ์",
        "province": "ชัยภูมิ",
        "zipcode": 36120,
        "district_code": 360403,
        "amphoe_code": 3604,
        "province_code": 36
    },
    {
        "district": "บ้านเป้า",
        "amphoe": "เกษตรสมบูรณ์",
        "province": "ชัยภูมิ",
        "zipcode": 36120,
        "district_code": 360404,
        "amphoe_code": 3604,
        "province_code": 36
    },
    {
        "district": "สระโพนทอง",
        "amphoe": "เกษตรสมบูรณ์",
        "province": "ชัยภูมิ",
        "zipcode": 36120,
        "district_code": 360407,
        "amphoe_code": 3604,
        "province_code": 36
    },
    {
        "district": "หนองข่า",
        "amphoe": "เกษตรสมบูรณ์",
        "province": "ชัยภูมิ",
        "zipcode": 36120,
        "district_code": 360408,
        "amphoe_code": 3604,
        "province_code": 36
    },
    {
        "district": "หนองโพนงาม",
        "amphoe": "เกษตรสมบูรณ์",
        "province": "ชัยภูมิ",
        "zipcode": 36120,
        "district_code": 360409,
        "amphoe_code": 3604,
        "province_code": 36
    },
    {
        "district": "โนนกอก",
        "amphoe": "เกษตรสมบูรณ์",
        "province": "ชัยภูมิ",
        "zipcode": 36120,
        "district_code": 360406,
        "amphoe_code": 3604,
        "province_code": 36
    },
    {
        "district": "โนนทอง",
        "amphoe": "เกษตรสมบูรณ์",
        "province": "ชัยภูมิ",
        "zipcode": 36120,
        "district_code": 360412,
        "amphoe_code": 3604,
        "province_code": 36
    },
    {
        "district": "นายางกลัก",
        "amphoe": "เทพสถิต",
        "province": "ชัยภูมิ",
        "zipcode": 36230,
        "district_code": 360797,
        "amphoe_code": 3609,
        "province_code": 36
    },
    {
        "district": "บ้านไร่",
        "amphoe": "เทพสถิต",
        "province": "ชัยภูมิ",
        "zipcode": 36230,
        "district_code": 360796,
        "amphoe_code": 3609,
        "province_code": 36
    },
    {
        "district": "วะตะแบก",
        "amphoe": "เทพสถิต",
        "province": "ชัยภูมิ",
        "zipcode": 36230,
        "district_code": 360799,
        "amphoe_code": 3609,
        "province_code": 36
    },
    {
        "district": "ห้วยยายจิ๋ว",
        "amphoe": "เทพสถิต",
        "province": "ชัยภูมิ",
        "zipcode": 36230,
        "district_code": 360798,
        "amphoe_code": 3609,
        "province_code": 36
    },
    {
        "district": "โป่งนก",
        "amphoe": "เทพสถิต",
        "province": "ชัยภูมิ",
        "zipcode": 36230,
        "district_code": 360905,
        "amphoe_code": 3609,
        "province_code": 36
    },
    {
        "district": "กะฮาด",
        "amphoe": "เนินสง่า",
        "province": "ชัยภูมิ",
        "zipcode": 36130,
        "district_code": 360609,
        "amphoe_code": 3615,
        "province_code": 36
    },
    {
        "district": "ตาเนิน",
        "amphoe": "เนินสง่า",
        "province": "ชัยภูมิ",
        "zipcode": 36130,
        "district_code": 360608,
        "amphoe_code": 3615,
        "province_code": 36
    },
    {
        "district": "รังงาม",
        "amphoe": "เนินสง่า",
        "province": "ชัยภูมิ",
        "zipcode": 36130,
        "district_code": 360615,
        "amphoe_code": 3615,
        "province_code": 36
    },
    {
        "district": "หนองฉิม",
        "amphoe": "เนินสง่า",
        "province": "ชัยภูมิ",
        "zipcode": 36130,
        "district_code": 360604,
        "amphoe_code": 3615,
        "province_code": 36
    },
    {
        "district": "กุดตุ้ม",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36000,
        "district_code": 360106,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "ชีลอง",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36000,
        "district_code": 360107,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "ซับสีทอง",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36000,
        "district_code": 360119,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "ท่าหินโงม",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36000,
        "district_code": 360113,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "นาฝาย",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36000,
        "district_code": 360104,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "นาเสียว",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36000,
        "district_code": 360109,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "บุ่งคล้า",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36000,
        "district_code": 360118,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "บ้านค่าย",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36240,
        "district_code": 360105,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "บ้านเล่า",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36000,
        "district_code": 360108,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "รอบเมือง",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36000,
        "district_code": 360102,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "ลาดใหญ่",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36000,
        "district_code": 360111,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "หนองนาแซง",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36000,
        "district_code": 360110,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "หนองไผ่",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36240,
        "district_code": 360112,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "ห้วยต้อน",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36000,
        "district_code": 360114,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "ห้วยบง",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36000,
        "district_code": 360115,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "โคกสูง",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36000,
        "district_code": 360117,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "โนนสำราญ",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36240,
        "district_code": 360116,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "โพนทอง",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36000,
        "district_code": 360103,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "ในเมือง",
        "amphoe": "เมืองชัยภูมิ",
        "province": "ชัยภูมิ",
        "zipcode": 36000,
        "district_code": 360101,
        "amphoe_code": 3601,
        "province_code": 36
    },
    {
        "district": "ช่องสามหมอ",
        "amphoe": "แก้งคร้อ",
        "province": "ชัยภูมิ",
        "zipcode": 36150,
        "district_code": 360303,
        "amphoe_code": 3612,
        "province_code": 36
    },
    {
        "district": "ท่ามะไฟหวาน",
        "amphoe": "แก้งคร้อ",
        "province": "ชัยภูมิ",
        "zipcode": 36150,
        "district_code": 361209,
        "amphoe_code": 3612,
        "province_code": 36
    },
    {
        "district": "นาหนองทุ่ม",
        "amphoe": "แก้งคร้อ",
        "province": "ชัยภูมิ",
        "zipcode": 36150,
        "district_code": 361203,
        "amphoe_code": 3612,
        "province_code": 36
    },
    {
        "district": "บ้านแก้ง",
        "amphoe": "แก้งคร้อ",
        "province": "ชัยภูมิ",
        "zipcode": 36150,
        "district_code": 361004,
        "amphoe_code": 3612,
        "province_code": 36
    },
    {
        "district": "หนองขาม",
        "amphoe": "แก้งคร้อ",
        "province": "ชัยภูมิ",
        "zipcode": 36150,
        "district_code": 360308,
        "amphoe_code": 3612,
        "province_code": 36
    },
    {
        "district": "หนองสังข์",
        "amphoe": "แก้งคร้อ",
        "province": "ชัยภูมิ",
        "zipcode": 36150,
        "district_code": 361205,
        "amphoe_code": 3612,
        "province_code": 36
    },
    {
        "district": "หนองไผ่",
        "amphoe": "แก้งคร้อ",
        "province": "ชัยภูมิ",
        "zipcode": 36150,
        "district_code": 360112,
        "amphoe_code": 3612,
        "province_code": 36
    },
    {
        "district": "หลุบคา",
        "amphoe": "แก้งคร้อ",
        "province": "ชัยภูมิ",
        "zipcode": 36150,
        "district_code": 361206,
        "amphoe_code": 3612,
        "province_code": 36
    },
    {
        "district": "เก่าย่าดี",
        "amphoe": "แก้งคร้อ",
        "province": "ชัยภูมิ",
        "zipcode": 36150,
        "district_code": 361208,
        "amphoe_code": 3612,
        "province_code": 36
    },
    {
        "district": "โคกกุง",
        "amphoe": "แก้งคร้อ",
        "province": "ชัยภูมิ",
        "zipcode": 36150,
        "district_code": 361207,
        "amphoe_code": 3612,
        "province_code": 36
    },
    {
        "district": "ช่องไม้แก้ว",
        "amphoe": "ทุ่งตะโก",
        "province": "ชุมพร",
        "zipcode": 86220,
        "district_code": 860804,
        "amphoe_code": 8608,
        "province_code": 86
    },
    {
        "district": "ตะโก",
        "amphoe": "ทุ่งตะโก",
        "province": "ชุมพร",
        "zipcode": 86220,
        "district_code": 860803,
        "amphoe_code": 8608,
        "province_code": 86
    },
    {
        "district": "ทุ่งตะไคร",
        "amphoe": "ทุ่งตะโก",
        "province": "ชุมพร",
        "zipcode": 86220,
        "district_code": 860802,
        "amphoe_code": 8608,
        "province_code": 86
    },
    {
        "district": "ปากตะโก",
        "amphoe": "ทุ่งตะโก",
        "province": "ชุมพร",
        "zipcode": 86220,
        "district_code": 860801,
        "amphoe_code": 8608,
        "province_code": 86
    },
    {
        "district": "หมู่เกาะศรีอบยา",
        "amphoe": "ทุ่งตะโก",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8608,
        "province_code": 86
    },
    {
        "district": "เกาะขี้นก",
        "amphoe": "ทุ่งตะโก",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8608,
        "province_code": 86
    },
    {
        "district": "เกาะคราม",
        "amphoe": "ทุ่งตะโก",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8608,
        "province_code": 86
    },
    {
        "district": "เกาะคางสือ",
        "amphoe": "ทุ่งตะโก",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8608,
        "province_code": 86
    },
    {
        "district": "เกาะมะพร้าว",
        "amphoe": "ทุ่งตะโก",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8608,
        "province_code": 86
    },
    {
        "district": "เกาะมุก",
        "amphoe": "ทุ่งตะโก",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8608,
        "province_code": 86
    },
    {
        "district": "เกาะยอ",
        "amphoe": "ทุ่งตะโก",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8608,
        "province_code": 86
    },
    {
        "district": "เกาะรางบรรทัด",
        "amphoe": "ทุ่งตะโก",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8608,
        "province_code": 86
    },
    {
        "district": "เกาะสูบ",
        "amphoe": "ทุ่งตะโก",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8608,
        "province_code": 86
    },
    {
        "district": "คุริง",
        "amphoe": "ท่าแซะ",
        "province": "ชุมพร",
        "zipcode": 86140,
        "district_code": 860202,
        "amphoe_code": 8602,
        "province_code": 86
    },
    {
        "district": "ทรัพย์อนันต์",
        "amphoe": "ท่าแซะ",
        "province": "ชุมพร",
        "zipcode": 86140,
        "district_code": 860209,
        "amphoe_code": 8602,
        "province_code": 86
    },
    {
        "district": "ท่าข้าม",
        "amphoe": "ท่าแซะ",
        "province": "ชุมพร",
        "zipcode": 86140,
        "district_code": 860206,
        "amphoe_code": 8602,
        "province_code": 86
    },
    {
        "district": "ท่าแซะ",
        "amphoe": "ท่าแซะ",
        "province": "ชุมพร",
        "zipcode": 86140,
        "district_code": 860201,
        "amphoe_code": 8602,
        "province_code": 86
    },
    {
        "district": "นากระตาม",
        "amphoe": "ท่าแซะ",
        "province": "ชุมพร",
        "zipcode": 86140,
        "district_code": 860204,
        "amphoe_code": 8602,
        "province_code": 86
    },
    {
        "district": "รับร่อ",
        "amphoe": "ท่าแซะ",
        "province": "ชุมพร",
        "zipcode": 86190,
        "district_code": 860205,
        "amphoe_code": 8602,
        "province_code": 86
    },
    {
        "district": "สลุย",
        "amphoe": "ท่าแซะ",
        "province": "ชุมพร",
        "zipcode": 86140,
        "district_code": 860203,
        "amphoe_code": 8602,
        "province_code": 86
    },
    {
        "district": "สองพี่น้อง",
        "amphoe": "ท่าแซะ",
        "province": "ชุมพร",
        "zipcode": 86140,
        "district_code": 860210,
        "amphoe_code": 8602,
        "province_code": 86
    },
    {
        "district": "หงษ์เจริญ",
        "amphoe": "ท่าแซะ",
        "province": "ชุมพร",
        "zipcode": 86140,
        "district_code": 860207,
        "amphoe_code": 8602,
        "province_code": 86
    },
    {
        "district": "หินแก้ว",
        "amphoe": "ท่าแซะ",
        "province": "ชุมพร",
        "zipcode": 86190,
        "district_code": 860208,
        "amphoe_code": 8602,
        "province_code": 86
    },
    {
        "district": "ชุมโค",
        "amphoe": "ปะทิว",
        "province": "ชุมพร",
        "zipcode": 86160,
        "district_code": 860304,
        "amphoe_code": 8603,
        "province_code": 86
    },
    {
        "district": "ดอนยาง",
        "amphoe": "ปะทิว",
        "province": "ชุมพร",
        "zipcode": 86210,
        "district_code": 860305,
        "amphoe_code": 8603,
        "province_code": 86
    },
    {
        "district": "ทะเลทรัพย์",
        "amphoe": "ปะทิว",
        "province": "ชุมพร",
        "zipcode": 86160,
        "district_code": 860302,
        "amphoe_code": 8603,
        "province_code": 86
    },
    {
        "district": "บางสน",
        "amphoe": "ปะทิว",
        "province": "ชุมพร",
        "zipcode": 86160,
        "district_code": 860301,
        "amphoe_code": 8603,
        "province_code": 86
    },
    {
        "district": "ปากคลอง",
        "amphoe": "ปะทิว",
        "province": "ชุมพร",
        "zipcode": 86210,
        "district_code": 860306,
        "amphoe_code": 8603,
        "province_code": 86
    },
    {
        "district": "สะพลี",
        "amphoe": "ปะทิว",
        "province": "ชุมพร",
        "zipcode": 86230,
        "district_code": 860303,
        "amphoe_code": 8603,
        "province_code": 86
    },
    {
        "district": "เกาะซีกง",
        "amphoe": "ปะทิว",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8603,
        "province_code": 86
    },
    {
        "district": "เกาะพระ",
        "amphoe": "ปะทิว",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8603,
        "province_code": 86
    },
    {
        "district": "เกาะยอ",
        "amphoe": "ปะทิว",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8603,
        "province_code": 86
    },
    {
        "district": "เกาะรัง",
        "amphoe": "ปะทิว",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8603,
        "province_code": 86
    },
    {
        "district": "เกาะร้านเป็ด",
        "amphoe": "ปะทิว",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8603,
        "province_code": 86
    },
    {
        "district": "เกาะร้านไก่",
        "amphoe": "ปะทิว",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8603,
        "province_code": 86
    },
    {
        "district": "เกาะเอียง",
        "amphoe": "ปะทิว",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8603,
        "province_code": 86
    },
    {
        "district": "เกาะไข่",
        "amphoe": "ปะทิว",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8603,
        "province_code": 86
    },
    {
        "district": "เขาไชยราช",
        "amphoe": "ปะทิว",
        "province": "ชุมพร",
        "zipcode": 86210,
        "district_code": 860307,
        "amphoe_code": 8603,
        "province_code": 86
    },
    {
        "district": "ปังหวาน",
        "amphoe": "พะโต๊ะ",
        "province": "ชุมพร",
        "zipcode": 86180,
        "district_code": 860603,
        "amphoe_code": 8606,
        "province_code": 86
    },
    {
        "district": "ปากทรง",
        "amphoe": "พะโต๊ะ",
        "province": "ชุมพร",
        "zipcode": 86180,
        "district_code": 860602,
        "amphoe_code": 8606,
        "province_code": 86
    },
    {
        "district": "พระรักษ์",
        "amphoe": "พะโต๊ะ",
        "province": "ชุมพร",
        "zipcode": 86180,
        "district_code": 860604,
        "amphoe_code": 8606,
        "province_code": 86
    },
    {
        "district": "พะโต๊ะ",
        "amphoe": "พะโต๊ะ",
        "province": "ชุมพร",
        "zipcode": 86180,
        "district_code": 860601,
        "amphoe_code": 8606,
        "province_code": 86
    },
    {
        "district": "ทุ่งคาวัด",
        "amphoe": "ละแม",
        "province": "ชุมพร",
        "zipcode": 86170,
        "district_code": 860504,
        "amphoe_code": 8605,
        "province_code": 86
    },
    {
        "district": "ทุ่งหลวง",
        "amphoe": "ละแม",
        "province": "ชุมพร",
        "zipcode": 86170,
        "district_code": 860502,
        "amphoe_code": 8605,
        "province_code": 86
    },
    {
        "district": "ละแม",
        "amphoe": "ละแม",
        "province": "ชุมพร",
        "zipcode": 86170,
        "district_code": 860501,
        "amphoe_code": 8605,
        "province_code": 86
    },
    {
        "district": "สวนแตง",
        "amphoe": "ละแม",
        "province": "ชุมพร",
        "zipcode": 86170,
        "district_code": 860503,
        "amphoe_code": 8605,
        "province_code": 86
    },
    {
        "district": "ครน",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": 86130,
        "district_code": 860707,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "ด่านสวี",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": 86130,
        "district_code": 860706,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "ทุ่งระยะ",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": 86130,
        "district_code": 860703,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "ท่าหิน",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": 86130,
        "district_code": 860704,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "นาสัก",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": 86130,
        "district_code": 860709,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "นาโพธิ์",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": 86130,
        "district_code": 860701,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "ปากแพรก",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": 86130,
        "district_code": 860705,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "วิสัยใต้",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": 86130,
        "district_code": 860708,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "สวี",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": 86130,
        "district_code": 860702,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "เกาะกระ",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "เกาะกุลา",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "เกาะทองแก้ว",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "เกาะบาตร์",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "เกาะพัดหวายเล็ก",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "เกาะพัดหวายใหญ่",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "เกาะยูง",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "เกาะหนู",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "เกาะแมว",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "เกาะแรด",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "เขาค่าย",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": 86130,
        "district_code": 860711,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "เขาทะลุ",
        "amphoe": "สวี",
        "province": "ชุมพร",
        "zipcode": 86130,
        "district_code": 860710,
        "amphoe_code": 8607,
        "province_code": 86
    },
    {
        "district": "ขันเงิน",
        "amphoe": "หลังสวน",
        "province": "ชุมพร",
        "zipcode": 86110,
        "district_code": 860402,
        "amphoe_code": 8604,
        "province_code": 86
    },
    {
        "district": "ท่ามะพลา",
        "amphoe": "หลังสวน",
        "province": "ชุมพร",
        "zipcode": 86110,
        "district_code": 860403,
        "amphoe_code": 8604,
        "province_code": 86
    },
    {
        "district": "นาขา",
        "amphoe": "หลังสวน",
        "province": "ชุมพร",
        "zipcode": 86110,
        "district_code": 860404,
        "amphoe_code": 8604,
        "province_code": 86
    },
    {
        "district": "นาพญา",
        "amphoe": "หลังสวน",
        "province": "ชุมพร",
        "zipcode": 86110,
        "district_code": 860405,
        "amphoe_code": 8604,
        "province_code": 86
    },
    {
        "district": "บางน้ำจืด",
        "amphoe": "หลังสวน",
        "province": "ชุมพร",
        "zipcode": 86150,
        "district_code": 860408,
        "amphoe_code": 8604,
        "province_code": 86
    },
    {
        "district": "บางมะพร้าว",
        "amphoe": "หลังสวน",
        "province": "ชุมพร",
        "zipcode": 86110,
        "district_code": 860407,
        "amphoe_code": 8604,
        "province_code": 86
    },
    {
        "district": "บ้านควน",
        "amphoe": "หลังสวน",
        "province": "ชุมพร",
        "zipcode": 86110,
        "district_code": 860406,
        "amphoe_code": 8604,
        "province_code": 86
    },
    {
        "district": "ปากน้ำ",
        "amphoe": "หลังสวน",
        "province": "ชุมพร",
        "zipcode": 86150,
        "district_code": 860102,
        "amphoe_code": 8604,
        "province_code": 86
    },
    {
        "district": "พ้อแดง",
        "amphoe": "หลังสวน",
        "province": "ชุมพร",
        "zipcode": 86110,
        "district_code": 860410,
        "amphoe_code": 8604,
        "province_code": 86
    },
    {
        "district": "วังตะกอ",
        "amphoe": "หลังสวน",
        "province": "ชุมพร",
        "zipcode": 86110,
        "district_code": 860412,
        "amphoe_code": 8604,
        "province_code": 86
    },
    {
        "district": "หลังสวน",
        "amphoe": "หลังสวน",
        "province": "ชุมพร",
        "zipcode": 86110,
        "district_code": 860401,
        "amphoe_code": 8604,
        "province_code": 86
    },
    {
        "district": "หาดยาย",
        "amphoe": "หลังสวน",
        "province": "ชุมพร",
        "zipcode": 86110,
        "district_code": 860413,
        "amphoe_code": 8604,
        "province_code": 86
    },
    {
        "district": "เกาะพิทักษ์",
        "amphoe": "หลังสวน",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8604,
        "province_code": 86
    },
    {
        "district": "แหลมทราย",
        "amphoe": "หลังสวน",
        "province": "ชุมพร",
        "zipcode": 86110,
        "district_code": 860411,
        "amphoe_code": 8604,
        "province_code": 86
    },
    {
        "district": "ขุนกระทิง",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": 86190,
        "district_code": 860113,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "ตากแดด",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": 86000,
        "district_code": 860107,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "ถ้ำสิงห์",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": 86100,
        "district_code": 860117,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "ทุ่งคา",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": 86100,
        "district_code": 860114,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "ท่าตะเภา",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": 86000,
        "district_code": 860101,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "ท่ายาง",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": 86000,
        "district_code": 860103,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "นาชะอัง",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": 86000,
        "district_code": 860106,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "นาทุ่ง",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": 86000,
        "district_code": 860105,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "บางลึก",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": 86000,
        "district_code": 860108,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "บางหมาก",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": 86000,
        "district_code": 860104,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "บ้านนา",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": 86190,
        "district_code": 860112,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "ปากน้ำ",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": 86120,
        "district_code": 860102,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "วังใหม่",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": 86190,
        "district_code": 860111,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "วังไผ่",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": 86000,
        "district_code": 860110,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "วิสัยเหนือ",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": 86100,
        "district_code": 860115,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "หาดทรายรี",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": 86120,
        "district_code": 860116,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "หาดพันไกร",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": 86000,
        "district_code": 860109,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "เกาะกา",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "เกาะกินหลักง้ำ",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "เกาะงามเล็ก",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "เกาะงามใหญ่",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "เกาะทองหลาง",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "เกาะทะลุ",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "เกาะมะพร้าว",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "เกาะมัตโพน",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "เกาะมาตรา",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "เกาะรังกาจิว",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "เกาะละวะ",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "เกาะสาก",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "เกาะหลักแรด",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "เกาะอีแรด",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "เกาะเสม็ด",
        "amphoe": "เมืองชุมพร",
        "province": "ชุมพร",
        "zipcode": "",
        "district_code": false,
        "amphoe_code": 8601,
        "province_code": 86
    },
    {
        "district": "กันตัง",
        "amphoe": "กันตัง",
        "province": "ตรัง",
        "zipcode": 92110,
        "district_code": 920201,
        "amphoe_code": 9202,
        "province_code": 92
    },
    {
        "district": "กันตังใต้",
        "amphoe": "กันตัง",
        "province": "ตรัง",
        "zipcode": 92110,
        "district_code": 920206,
        "amphoe_code": 9202,
        "province_code": 92
    },
    {
        "district": "คลองชีล้อม",
        "amphoe": "กันตัง",
        "province": "ตรัง",
        "zipcode": 92110,
        "district_code": 920214,
        "amphoe_code": 9202,
        "province_code": 92
    },
    {
        "district": "คลองลุ",
        "amphoe": "กันตัง",
        "province": "ตรัง",
        "zipcode": 92110,
        "district_code": 920208,
        "amphoe_code": 9202,
        "province_code": 92
    },
    {
        "district": "ควนธานี",
        "amphoe": "กันตัง",
        "province": "ตรัง",
        "zipcode": 92110,
        "district_code": 920202,
        "amphoe_code": 9202,
        "province_code": 92
    },
    {
        "district": "นาเกลือ",
        "amphoe": "กันตัง",
        "province": "ตรัง",
        "zipcode": 92110,
        "district_code": 920212,
        "amphoe_code": 9202,
        "province_code": 92
    },
    {
        "district": "บางสัก",
        "amphoe": "กันตัง",
        "province": "ตรัง",
        "zipcode": 92110,
        "district_code": 920211,
        "amphoe_code": 9202,
        "province_code": 92
    },
    {
        "district": "บางหมาก",
        "amphoe": "กันตัง",
        "province": "ตรัง",
        "zipcode": 92110,
        "district_code": 920203,
        "amphoe_code": 9202,
        "province_code": 92
    },
    {
        "district": "บางเป้า",
        "amphoe": "กันตัง",
        "province": "ตรัง",
        "zipcode": 92110,
        "district_code": 920204,
        "amphoe_code": 9202,
        "province_code": 92
    },
    {
        "district": "บ่อน้ำร้อน",
        "amphoe": "กันตัง",
        "province": "ตรัง",
        "zipcode": 92110,
        "district_code": 920210,
        "amphoe_code": 9202,
        "province_code": 92
    },
    {
        "district": "ย่านซื่อ",
        "amphoe": "กันตัง",
        "province": "ตรัง",
        "zipcode": 92110,
        "district_code": 920209,
        "amphoe_code": 9202,
        "province_code": 92
    },
    {
        "district": "วังวน",
        "amphoe": "กันตัง",
        "province": "ตรัง",
        "zipcode": 92110,
        "district_code": 920205,
        "amphoe_code": 9202,
        "province_code": 92
    },
    {
        "district": "เกาะลิบง",
        "amphoe": "กันตัง",
        "province": "ตรัง",
        "zipcode": 92110,
        "district_code": 920213,
        "amphoe_code": 9202,
        "province_code": 92
    },
    {
        "district": "โคกยาง",
        "amphoe": "กันตัง",
        "province": "ตรัง",
        "zipcode": 92110,
        "district_code": 920207,
        "amphoe_code": 9202,
        "province_code": 92
    },
    {
        "district": "ช่อง",
        "amphoe": "นาโยง",
        "province": "ตรัง",
        "zipcode": 92170,
        "district_code": 920121,
        "amphoe_code": 9208,
        "province_code": 92
    },
    {
        "district": "นาข้าวเสีย",
        "amphoe": "นาโยง",
        "province": "ตรัง",
        "zipcode": 92170,
        "district_code": 920111,
        "amphoe_code": 9208,
        "province_code": 92
    },
    {
        "district": "นาหมื่นศรี",
        "amphoe": "นาโยง",
        "province": "ตรัง",
        "zipcode": 92170,
        "district_code": 920112,
        "amphoe_code": 9208,
        "province_code": 92
    },
    {
        "district": "นาโยงเหนือ",
        "amphoe": "นาโยง",
        "province": "ตรัง",
        "zipcode": 92170,
        "district_code": 920116,
        "amphoe_code": 9208,
        "province_code": 92
    },
    {
        "district": "ละมอ",
        "amphoe": "นาโยง",
        "province": "ตรัง",
        "zipcode": 92170,
        "district_code": 920103,
        "amphoe_code": 9208,
        "province_code": 92
    },
    {
        "district": "โคกสะบ้า",
        "amphoe": "นาโยง",
        "province": "ตรัง",
        "zipcode": 92170,
        "district_code": 920102,
        "amphoe_code": 9208,
        "province_code": 92
    },
    {
        "district": "ทุ่งยาว",
        "amphoe": "ปะเหลียน",
        "province": "ตรัง",
        "zipcode": 92180,
        "district_code": 920402,
        "amphoe_code": 9204,
        "province_code": 92
    },
    {
        "district": "ท่าข้าม",
        "amphoe": "ปะเหลียน",
        "province": "ตรัง",
        "zipcode": 92120,
        "district_code": 920401,
        "amphoe_code": 9204,
        "province_code": 92
    },
    {
        "district": "ท่าพญา",
        "amphoe": "ปะเหลียน",
        "province": "ตรัง",
        "zipcode": 92140,
        "district_code": 920412,
        "amphoe_code": 9204,
        "province_code": 92
    },
    {
        "district": "บางด้วน",
        "amphoe": "ปะเหลียน",
        "province": "ตรัง",
        "zipcode": 92140,
        "district_code": 920404,
        "amphoe_code": 9204,
        "province_code": 92
    },
    {
        "district": "บ้านนา",
        "amphoe": "ปะเหลียน",
        "province": "ตรัง",
        "zipcode": 92140,
        "district_code": 920407,
        "amphoe_code": 9204,
        "province_code": 92
    },
    {
        "district": "ปะเหลียน",
        "amphoe": "ปะเหลียน",
        "province": "ตรัง",
        "zipcode": 92180,
        "district_code": 920403,
        "amphoe_code": 9204,
        "province_code": 92
    },
    {
        "district": "ลิพัง",
        "amphoe": "ปะเหลียน",
        "province": "ตรัง",
        "zipcode": 92180,
        "district_code": 920410,
        "amphoe_code": 9204,
        "province_code": 92
    },
    {
        "district": "สุโสะ",
        "amphoe": "ปะเหลียน",
        "province": "ตรัง",
        "zipcode": 92120,
        "district_code": 920409,
        "amphoe_code": 9204,
        "province_code": 92
    },
    {
        "district": "เกาะสุกร",
        "amphoe": "ปะเหลียน",
        "province": "ตรัง",
        "zipcode": 92120,
        "district_code": 920411,
        "amphoe_code": 9204,
        "province_code": 92
    },
    {
        "district": "แหลมสอม",
        "amphoe": "ปะเหลียน",
        "province": "ตรัง",
        "zipcode": 92180,
        "district_code": 920413,
        "amphoe_code": 9204,
        "province_code": 92
    },
    {
        "district": "ทุ่งกระบือ",
        "amphoe": "ย่านตาขาว",
        "province": "ตรัง",
        "zipcode": 92140,
        "district_code": 920306,
        "amphoe_code": 9203,
        "province_code": 92
    },
    {
        "district": "ทุ่งค่าย",
        "amphoe": "ย่านตาขาว",
        "province": "ตรัง",
        "zipcode": 92140,
        "district_code": 920307,
        "amphoe_code": 9203,
        "province_code": 92
    },
    {
        "district": "นาชุมเห็ด",
        "amphoe": "ย่านตาขาว",
        "province": "ตรัง",
        "zipcode": 92140,
        "district_code": 920303,
        "amphoe_code": 9203,
        "province_code": 92
    },
    {
        "district": "ย่านตาขาว",
        "amphoe": "ย่านตาขาว",
        "province": "ตรัง",
        "zipcode": 92140,
        "district_code": 920301,
        "amphoe_code": 9203,
        "province_code": 92
    },
    {
        "district": "หนองบ่อ",
        "amphoe": "ย่านตาขาว",
        "province": "ตรัง",
        "zipcode": 92140,
        "district_code": 920302,
        "amphoe_code": 9203,
        "province_code": 92
    },
    {
        "district": "เกาะเปียะ",
        "amphoe": "ย่านตาขาว",
        "province": "ตรัง",
        "zipcode": 92140,
        "district_code": 920308,
        "amphoe_code": 9203,
        "province_code": 92
    },
    {
        "district": "โพรงจระเข้",
        "amphoe": "ย่านตาขาว",
        "province": "ตรัง",
        "zipcode": 92140,
        "district_code": 920305,
        "amphoe_code": 9203,
        "province_code": 92
    },
    {
        "district": "ในควน",
        "amphoe": "ย่านตาขาว",
        "province": "ตรัง",
        "zipcode": 92140,
        "district_code": 920304,
        "amphoe_code": 9203,
        "province_code": 92
    },
    {
        "district": "คลองปาง",
        "amphoe": "รัษฎา",
        "province": "ตรัง",
        "zipcode": 92160,
        "district_code": 920612,
        "amphoe_code": 9209,
        "province_code": 92
    },
    {
        "district": "ควนเมา",
        "amphoe": "รัษฎา",
        "province": "ตรัง",
        "zipcode": 92160,
        "district_code": 920613,
        "amphoe_code": 9209,
        "province_code": 92
    },
    {
        "district": "หนองบัว",
        "amphoe": "รัษฎา",
        "province": "ตรัง",
        "zipcode": 92160,
        "district_code": 920604,
        "amphoe_code": 9209,
        "province_code": 92
    },
    {
        "district": "หนองปรือ",
        "amphoe": "รัษฎา",
        "province": "ตรัง",
        "zipcode": 92130,
        "district_code": 920603,
        "amphoe_code": 9209,
        "province_code": 92
    },
    {
        "district": "เขาไพร",
        "amphoe": "รัษฎา",
        "province": "ตรัง",
        "zipcode": 92160,
        "district_code": 920618,
        "amphoe_code": 9209,
        "province_code": 92
    },
    {
        "district": "ท่าสะบ้า",
        "amphoe": "วังวิเศษ",
        "province": "ตรัง",
        "zipcode": 92000,
        "district_code": 920595,
        "amphoe_code": 9207,
        "province_code": 92
    },
    {
        "district": "วังมะปราง",
        "amphoe": "วังวิเศษ",
        "province": "ตรัง",
        "zipcode": 92220,
        "district_code": 920598,
        "amphoe_code": 9207,
        "province_code": 92
    },
    {
        "district": "วังมะปรางเหนือ",
        "amphoe": "วังวิเศษ",
        "province": "ตรัง",
        "zipcode": 92220,
        "district_code": 920705,
        "amphoe_code": 9207,
        "province_code": 92
    },
    {
        "district": "อ่าวตง",
        "amphoe": "วังวิเศษ",
        "province": "ตรัง",
        "zipcode": 92220,
        "district_code": 920597,
        "amphoe_code": 9207,
        "province_code": 92
    },
    {
        "district": "เขาวิเศษ",
        "amphoe": "วังวิเศษ",
        "province": "ตรัง",
        "zipcode": 92220,
        "district_code": 920599,
        "amphoe_code": 9207,
        "province_code": 92
    },
    {
        "district": "กะลาเส",
        "amphoe": "สิเกา",
        "province": "ตรัง",
        "zipcode": 92150,
        "district_code": 920503,
        "amphoe_code": 9205,
        "province_code": 92
    },
    {
        "district": "นาเมืองเพชร",
        "amphoe": "สิเกา",
        "province": "ตรัง",
        "zipcode": 92000,
        "district_code": 920505,
        "amphoe_code": 9205,
        "province_code": 92
    },
    {
        "district": "บ่อหิน",
        "amphoe": "สิเกา",
        "province": "ตรัง",
        "zipcode": 92150,
        "district_code": 920501,
        "amphoe_code": 9205,
        "province_code": 92
    },
    {
        "district": "เขาไม้แก้ว",
        "amphoe": "สิเกา",
        "province": "ตรัง",
        "zipcode": 92150,
        "district_code": 920502,
        "amphoe_code": 9205,
        "province_code": 92
    },
    {
        "district": "ไม้ฝาด",
        "amphoe": "สิเกา",
        "province": "ตรัง",
        "zipcode": 92150,
        "district_code": 920504,
        "amphoe_code": 9205,
        "province_code": 92
    },
    {
        "district": "ตะเสะ",
        "amphoe": "หาดสำราญ",
        "province": "ตรัง",
        "zipcode": 92120,
        "district_code": 920406,
        "amphoe_code": 9210,
        "province_code": 92
    },
    {
        "district": "บ้าหวี",
        "amphoe": "หาดสำราญ",
        "province": "ตรัง",
        "zipcode": 92120,
        "district_code": 920408,
        "amphoe_code": 9210,
        "province_code": 92
    },
    {
        "district": "หาดสำราญ",
        "amphoe": "หาดสำราญ",
        "province": "ตรัง",
        "zipcode": 92120,
        "district_code": 920405,
        "amphoe_code": 9210,
        "province_code": 92
    },
    {
        "district": "ทุ่งต่อ",
        "amphoe": "ห้วยยอด",
        "province": "ตรัง",
        "zipcode": 92130,
        "district_code": 920620,
        "amphoe_code": 9206,
        "province_code": 92
    },
    {
        "district": "ท่างิ้ว",
        "amphoe": "ห้วยยอด",
        "province": "ตรัง",
        "zipcode": 92130,
        "district_code": 920614,
        "amphoe_code": 9206,
        "province_code": 92
    },
    {
        "district": "นาวง",
        "amphoe": "ห้วยยอด",
        "province": "ตรัง",
        "zipcode": 92210,
        "district_code": 920616,
        "amphoe_code": 9206,
        "province_code": 92
    },
    {
        "district": "บางกุ้ง",
        "amphoe": "ห้วยยอด",
        "province": "ตรัง",
        "zipcode": 92210,
        "district_code": 920606,
        "amphoe_code": 9206,
        "province_code": 92
    },
    {
        "district": "บางดี",
        "amphoe": "ห้วยยอด",
        "province": "ตรัง",
        "zipcode": 92210,
        "district_code": 920605,
        "amphoe_code": 9206,
        "province_code": 92
    },
    {
        "district": "ปากคม",
        "amphoe": "ห้วยยอด",
        "province": "ตรัง",
        "zipcode": 92130,
        "district_code": 920611,
        "amphoe_code": 9206,
        "province_code": 92
    },
    {
        "district": "ปากแจ่ม",
        "amphoe": "ห้วยยอด",
        "province": "ตรัง",
        "zipcode": 92190,
        "district_code": 920610,
        "amphoe_code": 9206,
        "province_code": 92
    },
    {
        "district": "ลำภูรา",
        "amphoe": "ห้วยยอด",
        "province": "ตรัง",
        "zipcode": 92190,
        "district_code": 920615,
        "amphoe_code": 9206,
        "province_code": 92
    },
    {
        "district": "วังคีรี",
        "amphoe": "ห้วยยอด",
        "province": "ตรัง",
        "zipcode": 92210,
        "district_code": 920621,
        "amphoe_code": 9206,
        "province_code": 92
    },
    {
        "district": "หนองช้างแล่น",
        "amphoe": "ห้วยยอด",
        "province": "ตรัง",
        "zipcode": 92130,
        "district_code": 920602,
        "amphoe_code": 9206,
        "province_code": 92
    },
    {
        "district": "ห้วยนาง",
        "amphoe": "ห้วยยอด",
        "province": "ตรัง",
        "zipcode": 92130,
        "district_code": 920617,
        "amphoe_code": 9206,
        "province_code": 92
    },
    {
        "district": "ห้วยยอด",
        "amphoe": "ห้วยยอด",
        "province": "ตรัง",
        "zipcode": 92130,
        "district_code": 920601,
        "amphoe_code": 9206,
        "province_code": 92
    },
    {
        "district": "เขากอบ",
        "amphoe": "ห้วยยอด",
        "province": "ตรัง",
        "zipcode": 92130,
        "district_code": 920607,
        "amphoe_code": 9206,
        "province_code": 92
    },
    {
        "district": "เขาขาว",
        "amphoe": "ห้วยยอด",
        "province": "ตรัง",
        "zipcode": 92130,
        "district_code": 920608,
        "amphoe_code": 9206,
        "province_code": 92
    },
    {
        "district": "เขาปูน",
        "amphoe": "ห้วยยอด",
        "province": "ตรัง",
        "zipcode": 92130,
        "district_code": 920609,
        "amphoe_code": 9206,
        "province_code": 92
    },
    {
        "district": "ในเตา",
        "amphoe": "ห้วยยอด",
        "province": "ตรัง",
        "zipcode": 92130,
        "district_code": 920619,
        "amphoe_code": 9206,
        "province_code": 92
    },
    {
        "district": "ควนปริง",
        "amphoe": "เมืองตรัง",
        "province": "ตรัง",
        "zipcode": 92000,
        "district_code": 920107,
        "amphoe_code": 9201,
        "province_code": 92
    },
    {
        "district": "ทับเที่ยง",
        "amphoe": "เมืองตรัง",
        "province": "ตรัง",
        "zipcode": 92000,
        "district_code": 920101,
        "amphoe_code": 9201,
        "province_code": 92
    },
    {
        "district": "นาตาล่วง",
        "amphoe": "เมืองตรัง",
        "province": "ตรัง",
        "zipcode": 92000,
        "district_code": 920117,
        "amphoe_code": 9201,
        "province_code": 92
    },
    {
        "district": "นาท่ามเหนือ",
        "amphoe": "เมืองตรัง",
        "province": "ตรัง",
        "zipcode": 92190,
        "district_code": 920119,
        "amphoe_code": 9201,
        "province_code": 92
    },
    {
        "district": "นาท่ามใต้",
        "amphoe": "เมืองตรัง",
        "province": "ตรัง",
        "zipcode": 92190,
        "district_code": 920120,
        "amphoe_code": 9201,
        "province_code": 92
    },
    {
        "district": "นาบินหลา",
        "amphoe": "เมืองตรัง",
        "province": "ตรัง",
        "zipcode": 92170,
        "district_code": 920106,
        "amphoe_code": 9201,
        "province_code": 92
    },
    {
        "district": "นาพละ",
        "amphoe": "เมืองตรัง",
        "province": "ตรัง",
        "zipcode": 92000,
        "district_code": 920104,
        "amphoe_code": 9201,
        "province_code": 92
    },
    {
        "district": "นาโต๊ะหมิง",
        "amphoe": "เมืองตรัง",
        "province": "ตรัง",
        "zipcode": 92000,
        "district_code": 920113,
        "amphoe_code": 9201,
        "province_code": 92
    },
    {
        "district": "นาโยงใต้",
        "amphoe": "เมืองตรัง",
        "province": "ตรัง",
        "zipcode": 92170,
        "district_code": 920108,
        "amphoe_code": 9201,
        "province_code": 92
    },
    {
        "district": "น้ำผุด",
        "amphoe": "เมืองตรัง",
        "province": "ตรัง",
        "zipcode": 92000,
        "district_code": 920115,
        "amphoe_code": 9201,
        "province_code": 92
    },
    {
        "district": "บางรัก",
        "amphoe": "เมืองตรัง",
        "province": "ตรัง",
        "zipcode": 92000,
        "district_code": 920109,
        "amphoe_code": 9201,
        "province_code": 92
    },
    {
        "district": "บ้านควน",
        "amphoe": "เมืองตรัง",
        "province": "ตรัง",
        "zipcode": 92000,
        "district_code": 920105,
        "amphoe_code": 9201,
        "province_code": 92
    },
    {
        "district": "บ้านโพธิ์",
        "amphoe": "เมืองตรัง",
        "province": "ตรัง",
        "zipcode": 92000,
        "district_code": 920118,
        "amphoe_code": 9201,
        "province_code": 92
    },
    {
        "district": "หนองตรุด",
        "amphoe": "เมืองตรัง",
        "province": "ตรัง",
        "zipcode": 92000,
        "district_code": 920114,
        "amphoe_code": 9201,
        "province_code": 92
    },
    {
        "district": "โคกหล่อ",
        "amphoe": "เมืองตรัง",
        "province": "ตรัง",
        "zipcode": 92000,
        "district_code": 920110,
        "amphoe_code": 9201,
        "province_code": 92
    },
    {
        "district": "คลองใหญ่",
        "amphoe": "คลองใหญ่",
        "province": "ตราด",
        "zipcode": 23110,
        "district_code": 230201,
        "amphoe_code": 2302,
        "province_code": 23
    },
    {
        "district": "หาดเล็ก",
        "amphoe": "คลองใหญ่",
        "province": "ตราด",
        "zipcode": 23110,
        "district_code": 230203,
        "amphoe_code": 2302,
        "province_code": 23
    },
    {
        "district": "ไม้รูด",
        "amphoe": "คลองใหญ่",
        "province": "ตราด",
        "zipcode": 23110,
        "district_code": 230202,
        "amphoe_code": 2302,
        "province_code": 23
    },
    {
        "district": "ช้างทูน",
        "amphoe": "บ่อไร่",
        "province": "ตราด",
        "zipcode": 23140,
        "district_code": 230398,
        "amphoe_code": 2304,
        "province_code": 23
    },
    {
        "district": "ด่านชุมพล",
        "amphoe": "บ่อไร่",
        "province": "ตราด",
        "zipcode": 23140,
        "district_code": 230396,
        "amphoe_code": 2304,
        "province_code": 23
    },
    {
        "district": "นนทรีย์",
        "amphoe": "บ่อไร่",
        "province": "ตราด",
        "zipcode": 23140,
        "district_code": 230405,
        "amphoe_code": 2304,
        "province_code": 23
    },
    {
        "district": "บ่อพลอย",
        "amphoe": "บ่อไร่",
        "province": "ตราด",
        "zipcode": 23140,
        "district_code": 230399,
        "amphoe_code": 2304,
        "province_code": 23
    },
    {
        "district": "หนองบอน",
        "amphoe": "บ่อไร่",
        "province": "ตราด",
        "zipcode": 23140,
        "district_code": 230397,
        "amphoe_code": 2304,
        "province_code": 23
    },
    {
        "district": "เกาะกูด",
        "amphoe": "เกาะกูด",
        "province": "ตราด",
        "zipcode": 23000,
        "district_code": 230506,
        "amphoe_code": 2306,
        "province_code": 23
    },
    {
        "district": "เกาะหมาก",
        "amphoe": "เกาะกูด",
        "province": "ตราด",
        "zipcode": 23000,
        "district_code": 230505,
        "amphoe_code": 2306,
        "province_code": 23
    },
    {
        "district": "เกาะช้าง",
        "amphoe": "เกาะช้าง",
        "province": "ตราด",
        "zipcode": 23170,
        "district_code": 230504,
        "amphoe_code": 2307,
        "province_code": 23
    },
    {
        "district": "เกาะช้างใต้",
        "amphoe": "เกาะช้าง",
        "province": "ตราด",
        "zipcode": 23170,
        "district_code": 230508,
        "amphoe_code": 2307,
        "province_code": 23
    },
    {
        "district": "ทุ่งนนทรี",
        "amphoe": "เขาสมิง",
        "province": "ตราด",
        "zipcode": 23130,
        "district_code": 230308,
        "amphoe_code": 2303,
        "province_code": 23
    },
    {
        "district": "ท่าโสม",
        "amphoe": "เขาสมิง",
        "province": "ตราด",
        "zipcode": 23150,
        "district_code": 230304,
        "amphoe_code": 2303,
        "province_code": 23
    },
    {
        "district": "ประณีต",
        "amphoe": "เขาสมิง",
        "province": "ตราด",
        "zipcode": 23150,
        "district_code": 230306,
        "amphoe_code": 2303,
        "province_code": 23
    },
    {
        "district": "วังตะเคียน",
        "amphoe": "เขาสมิง",
        "province": "ตราด",
        "zipcode": 23130,
        "district_code": 230303,
        "amphoe_code": 2303,
        "province_code": 23
    },
    {
        "district": "สะตอ",
        "amphoe": "เขาสมิง",
        "province": "ตราด",
        "zipcode": 23150,
        "district_code": 230305,
        "amphoe_code": 2303,
        "province_code": 23
    },
    {
        "district": "เขาสมิง",
        "amphoe": "เขาสมิง",
        "province": "ตราด",
        "zipcode": 23130,
        "district_code": 230301,
        "amphoe_code": 2303,
        "province_code": 23
    },
    {
        "district": "เทพนิมิต",
        "amphoe": "เขาสมิง",
        "province": "ตราด",
        "zipcode": 23150,
        "district_code": 230307,
        "amphoe_code": 2303,
        "province_code": 23
    },
    {
        "district": "แสนตุ้ง",
        "amphoe": "เขาสมิง",
        "province": "ตราด",
        "zipcode": 23150,
        "district_code": 230302,
        "amphoe_code": 2303,
        "province_code": 23
    },
    {
        "district": "ชำราก",
        "amphoe": "เมืองตราด",
        "province": "ตราด",
        "zipcode": 23000,
        "district_code": 230113,
        "amphoe_code": 2301,
        "province_code": 23
    },
    {
        "district": "ตะกาง",
        "amphoe": "เมืองตราด",
        "province": "ตราด",
        "zipcode": 23000,
        "district_code": 230112,
        "amphoe_code": 2301,
        "province_code": 23
    },
    {
        "district": "ท่ากุ่ม",
        "amphoe": "เมืองตราด",
        "province": "ตราด",
        "zipcode": 23000,
        "district_code": 230111,
        "amphoe_code": 2301,
        "province_code": 23
    },
    {
        "district": "ท่าพริก",
        "amphoe": "เมืองตราด",
        "province": "ตราด",
        "zipcode": 23000,
        "district_code": 230110,
        "amphoe_code": 2301,
        "province_code": 23
    },
    {
        "district": "บางพระ",
        "amphoe": "เมืองตราด",
        "province": "ตราด",
        "zipcode": 23000,
        "district_code": 230101,
        "amphoe_code": 2301,
        "province_code": 23
    },
    {
        "district": "วังกระแจะ",
        "amphoe": "เมืองตราด",
        "province": "ตราด",
        "zipcode": 23000,
        "district_code": 230107,
        "amphoe_code": 2301,
        "province_code": 23
    },
    {
        "district": "หนองคันทรง",
        "amphoe": "เมืองตราด",
        "province": "ตราด",
        "zipcode": 23000,
        "district_code": 230104,
        "amphoe_code": 2301,
        "province_code": 23
    },
    {
        "district": "หนองเสม็ด",
        "amphoe": "เมืองตราด",
        "province": "ตราด",
        "zipcode": 23000,
        "district_code": 230102,
        "amphoe_code": 2301,
        "province_code": 23
    },
    {
        "district": "หนองโสน",
        "amphoe": "เมืองตราด",
        "province": "ตราด",
        "zipcode": 23000,
        "district_code": 230103,
        "amphoe_code": 2301,
        "province_code": 23
    },
    {
        "district": "ห้วงน้ำขาว",
        "amphoe": "เมืองตราด",
        "province": "ตราด",
        "zipcode": 23000,
        "district_code": 230105,
        "amphoe_code": 2301,
        "province_code": 23
    },
    {
        "district": "ห้วยแร้ง",
        "amphoe": "เมืองตราด",
        "province": "ตราด",
        "zipcode": 23000,
        "district_code": 230108,
        "amphoe_code": 2301,
        "province_code": 23
    },
    {
        "district": "อ่าวใหญ่",
        "amphoe": "เมืองตราด",
        "province": "ตราด",
        "zipcode": 23000,
        "district_code": 230106,
        "amphoe_code": 2301,
        "province_code": 23
    },
    {
        "district": "เนินทราย",
        "amphoe": "เมืองตราด",
        "province": "ตราด",
        "zipcode": 23000,
        "district_code": 230109,
        "amphoe_code": 2301,
        "province_code": 23
    },
    {
        "district": "แหลมกลัด",
        "amphoe": "เมืองตราด",
        "province": "ตราด",
        "zipcode": 23000,
        "district_code": 230114,
        "amphoe_code": 2301,
        "province_code": 23
    },
    {
        "district": "คลองใหญ่",
        "amphoe": "แหลมงอบ",
        "province": "ตราด",
        "zipcode": 23120,
        "district_code": 230201,
        "amphoe_code": 2305,
        "province_code": 23
    },
    {
        "district": "น้ำเชี่ยว",
        "amphoe": "แหลมงอบ",
        "province": "ตราด",
        "zipcode": 23120,
        "district_code": 230502,
        "amphoe_code": 2305,
        "province_code": 23
    },
    {
        "district": "บางปิด",
        "amphoe": "แหลมงอบ",
        "province": "ตราด",
        "zipcode": 23120,
        "district_code": 230503,
        "amphoe_code": 2305,
        "province_code": 23
    },
    {
        "district": "แหลมงอบ",
        "amphoe": "แหลมงอบ",
        "province": "ตราด",
        "zipcode": 23120,
        "district_code": 230501,
        "amphoe_code": 2305,
        "province_code": 23
    },
    {
        "district": "ท่าสองยาง",
        "amphoe": "ท่าสองยาง",
        "province": "ตาก",
        "zipcode": 63150,
        "district_code": 630501,
        "amphoe_code": 6305,
        "province_code": 63
    },
    {
        "district": "แม่ต้าน",
        "amphoe": "ท่าสองยาง",
        "province": "ตาก",
        "zipcode": 63150,
        "district_code": 630502,
        "amphoe_code": 6305,
        "province_code": 63
    },
    {
        "district": "แม่วะหลวง",
        "amphoe": "ท่าสองยาง",
        "province": "ตาก",
        "zipcode": 63150,
        "district_code": 630505,
        "amphoe_code": 6305,
        "province_code": 63
    },
    {
        "district": "แม่สอง",
        "amphoe": "ท่าสองยาง",
        "province": "ตาก",
        "zipcode": 63150,
        "district_code": 630503,
        "amphoe_code": 6305,
        "province_code": 63
    },
    {
        "district": "แม่หละ",
        "amphoe": "ท่าสองยาง",
        "province": "ตาก",
        "zipcode": 63150,
        "district_code": 630504,
        "amphoe_code": 6305,
        "province_code": 63
    },
    {
        "district": "แม่อุสุ",
        "amphoe": "ท่าสองยาง",
        "province": "ตาก",
        "zipcode": 63150,
        "district_code": 630506,
        "amphoe_code": 6305,
        "province_code": 63
    },
    {
        "district": "ตากตก",
        "amphoe": "บ้านตาก",
        "province": "ตาก",
        "zipcode": 63120,
        "district_code": 630204,
        "amphoe_code": 6302,
        "province_code": 63
    },
    {
        "district": "ตากออก",
        "amphoe": "บ้านตาก",
        "province": "ตาก",
        "zipcode": 63120,
        "district_code": 630201,
        "amphoe_code": 6302,
        "province_code": 63
    },
    {
        "district": "ทุ่งกระเชาะ",
        "amphoe": "บ้านตาก",
        "province": "ตาก",
        "zipcode": 63120,
        "district_code": 630206,
        "amphoe_code": 6302,
        "province_code": 63
    },
    {
        "district": "ท้องฟ้า",
        "amphoe": "บ้านตาก",
        "province": "ตาก",
        "zipcode": 63120,
        "district_code": 630207,
        "amphoe_code": 6302,
        "province_code": 63
    },
    {
        "district": "สมอโคน",
        "amphoe": "บ้านตาก",
        "province": "ตาก",
        "zipcode": 63120,
        "district_code": 630202,
        "amphoe_code": 6302,
        "province_code": 63
    },
    {
        "district": "เกาะตะเภา",
        "amphoe": "บ้านตาก",
        "province": "ตาก",
        "zipcode": 63120,
        "district_code": 630205,
        "amphoe_code": 6302,
        "province_code": 63
    },
    {
        "district": "แม่สลิด",
        "amphoe": "บ้านตาก",
        "province": "ตาก",
        "zipcode": 63120,
        "district_code": 630203,
        "amphoe_code": 6302,
        "province_code": 63
    },
    {
        "district": "คีรีราษฎร์",
        "amphoe": "พบพระ",
        "province": "ตาก",
        "zipcode": 63160,
        "district_code": 630703,
        "amphoe_code": 6307,
        "province_code": 63
    },
    {
        "district": "ช่องแคบ",
        "amphoe": "พบพระ",
        "province": "ตาก",
        "zipcode": 63160,
        "district_code": 630702,
        "amphoe_code": 6307,
        "province_code": 63
    },
    {
        "district": "พบพระ",
        "amphoe": "พบพระ",
        "province": "ตาก",
        "zipcode": 63160,
        "district_code": 630701,
        "amphoe_code": 6307,
        "province_code": 63
    },
    {
        "district": "รวมไทยพัฒนา",
        "amphoe": "พบพระ",
        "province": "ตาก",
        "zipcode": 63160,
        "district_code": 630705,
        "amphoe_code": 6307,
        "province_code": 63
    },
    {
        "district": "วาเล่ย์",
        "amphoe": "พบพระ",
        "province": "ตาก",
        "zipcode": 63160,
        "district_code": 630704,
        "amphoe_code": 6307,
        "province_code": 63
    },
    {
        "district": "นาโบสถ์",
        "amphoe": "วังเจ้า",
        "province": "ตาก",
        "zipcode": 63180,
        "district_code": 630116,
        "amphoe_code": 6309,
        "province_code": 63
    },
    {
        "district": "ประดาง",
        "amphoe": "วังเจ้า",
        "province": "ตาก",
        "zipcode": 63180,
        "district_code": 630117,
        "amphoe_code": 6309,
        "province_code": 63
    },
    {
        "district": "เชียงทอง",
        "amphoe": "วังเจ้า",
        "province": "ตาก",
        "zipcode": 63180,
        "district_code": 630110,
        "amphoe_code": 6309,
        "province_code": 63
    },
    {
        "district": "บ้านนา",
        "amphoe": "สามเงา",
        "province": "ตาก",
        "zipcode": 63130,
        "district_code": 630305,
        "amphoe_code": 6303,
        "province_code": 63
    },
    {
        "district": "ยกกระบัตร",
        "amphoe": "สามเงา",
        "province": "ตาก",
        "zipcode": 63130,
        "district_code": 630303,
        "amphoe_code": 6303,
        "province_code": 63
    },
    {
        "district": "ย่านรี",
        "amphoe": "สามเงา",
        "province": "ตาก",
        "zipcode": 63130,
        "district_code": 630304,
        "amphoe_code": 6303,
        "province_code": 63
    },
    {
        "district": "วังจันทร์",
        "amphoe": "สามเงา",
        "province": "ตาก",
        "zipcode": 63130,
        "district_code": 630306,
        "amphoe_code": 6303,
        "province_code": 63
    },
    {
        "district": "วังหมัน",
        "amphoe": "สามเงา",
        "province": "ตาก",
        "zipcode": 63130,
        "district_code": 630302,
        "amphoe_code": 6303,
        "province_code": 63
    },
    {
        "district": "สามเงา",
        "amphoe": "สามเงา",
        "province": "ตาก",
        "zipcode": 63130,
        "district_code": 630301,
        "amphoe_code": 6303,
        "province_code": 63
    },
    {
        "district": "หนองหลวง",
        "amphoe": "อุ้มผาง",
        "province": "ตาก",
        "zipcode": 63170,
        "district_code": 630102,
        "amphoe_code": 6308,
        "province_code": 63
    },
    {
        "district": "อุ้มผาง",
        "amphoe": "อุ้มผาง",
        "province": "ตาก",
        "zipcode": 63170,
        "district_code": 630801,
        "amphoe_code": 6308,
        "province_code": 63
    },
    {
        "district": "แม่กลอง",
        "amphoe": "อุ้มผาง",
        "province": "ตาก",
        "zipcode": 63170,
        "district_code": 630806,
        "amphoe_code": 6308,
        "province_code": 63
    },
    {
        "district": "แม่จัน",
        "amphoe": "อุ้มผาง",
        "province": "ตาก",
        "zipcode": 63170,
        "district_code": 630804,
        "amphoe_code": 6308,
        "province_code": 63
    },
    {
        "district": "แม่ละมุ้ง",
        "amphoe": "อุ้มผาง",
        "province": "ตาก",
        "zipcode": 63170,
        "district_code": 630805,
        "amphoe_code": 6308,
        "province_code": 63
    },
    {
        "district": "โมโกร",
        "amphoe": "อุ้มผาง",
        "province": "ตาก",
        "zipcode": 63170,
        "district_code": 630803,
        "amphoe_code": 6308,
        "province_code": 63
    },
    {
        "district": "ตลุกกลางทุ่ง",
        "amphoe": "เมืองตาก",
        "province": "ตาก",
        "zipcode": 63000,
        "district_code": 630115,
        "amphoe_code": 6301,
        "province_code": 63
    },
    {
        "district": "น้ำรึม",
        "amphoe": "เมืองตาก",
        "province": "ตาก",
        "zipcode": 63000,
        "district_code": 630108,
        "amphoe_code": 6301,
        "province_code": 63
    },
    {
        "district": "ป่ามะม่วง",
        "amphoe": "เมืองตาก",
        "province": "ตาก",
        "zipcode": 63000,
        "district_code": 630112,
        "amphoe_code": 6301,
        "province_code": 63
    },
    {
        "district": "ระแหง",
        "amphoe": "เมืองตาก",
        "province": "ตาก",
        "zipcode": 63000,
        "district_code": 630101,
        "amphoe_code": 6301,
        "province_code": 63
    },
    {
        "district": "วังประจบ",
        "amphoe": "เมืองตาก",
        "province": "ตาก",
        "zipcode": 63000,
        "district_code": 630114,
        "amphoe_code": 6301,
        "province_code": 63
    },
    {
        "district": "วังหิน",
        "amphoe": "เมืองตาก",
        "province": "ตาก",
        "zipcode": 63000,
        "district_code": 630109,
        "amphoe_code": 6301,
        "province_code": 63
    },
    {
        "district": "หนองบัวเหนือ",
        "amphoe": "เมืองตาก",
        "province": "ตาก",
        "zipcode": 63000,
        "district_code": 630105,
        "amphoe_code": 6301,
        "province_code": 63
    },
    {
        "district": "หนองบัวใต้",
        "amphoe": "เมืองตาก",
        "province": "ตาก",
        "zipcode": 63000,
        "district_code": 630113,
        "amphoe_code": 6301,
        "province_code": 63
    },
    {
        "district": "หนองหลวง",
        "amphoe": "เมืองตาก",
        "province": "ตาก",
        "zipcode": 63000,
        "district_code": 630102,
        "amphoe_code": 6301,
        "province_code": 63
    },
    {
        "district": "หัวเดียด",
        "amphoe": "เมืองตาก",
        "province": "ตาก",
        "zipcode": 63000,
        "district_code": 630104,
        "amphoe_code": 6301,
        "province_code": 63
    },
    {
        "district": "เชียงเงิน",
        "amphoe": "เมืองตาก",
        "province": "ตาก",
        "zipcode": 63000,
        "district_code": 630103,
        "amphoe_code": 6301,
        "province_code": 63
    },
    {
        "district": "แม่ท้อ",
        "amphoe": "เมืองตาก",
        "province": "ตาก",
        "zipcode": 63000,
        "district_code": 630111,
        "amphoe_code": 6301,
        "province_code": 63
    },
    {
        "district": "โป่งแดง",
        "amphoe": "เมืองตาก",
        "province": "ตาก",
        "zipcode": 63000,
        "district_code": 630107,
        "amphoe_code": 6301,
        "province_code": 63
    },
    {
        "district": "ไม้งาม",
        "amphoe": "เมืองตาก",
        "province": "ตาก",
        "zipcode": 63000,
        "district_code": 630106,
        "amphoe_code": 6301,
        "province_code": 63
    },
    {
        "district": "ขะเนจื้อ",
        "amphoe": "แม่ระมาด",
        "province": "ตาก",
        "zipcode": 63140,
        "district_code": 630403,
        "amphoe_code": 6304,
        "province_code": 63
    },
    {
        "district": "พระธาตุ",
        "amphoe": "แม่ระมาด",
        "province": "ตาก",
        "zipcode": 63140,
        "district_code": 630406,
        "amphoe_code": 6304,
        "province_code": 63
    },
    {
        "district": "สามหมื่น",
        "amphoe": "แม่ระมาด",
        "province": "ตาก",
        "zipcode": 63140,
        "district_code": 630405,
        "amphoe_code": 6304,
        "province_code": 63
    },
    {
        "district": "แม่จะเรา",
        "amphoe": "แม่ระมาด",
        "province": "ตาก",
        "zipcode": 63140,
        "district_code": 630402,
        "amphoe_code": 6304,
        "province_code": 63
    },
    {
        "district": "แม่ตื่น",
        "amphoe": "แม่ระมาด",
        "province": "ตาก",
        "zipcode": 63140,
        "district_code": 630404,
        "amphoe_code": 6304,
        "province_code": 63
    },
    {
        "district": "แม่ระมาด",
        "amphoe": "แม่ระมาด",
        "province": "ตาก",
        "zipcode": 63140,
        "district_code": 630401,
        "amphoe_code": 6304,
        "province_code": 63
    },
    {
        "district": "ด่านแม่ละเมา",
        "amphoe": "แม่สอด",
        "province": "ตาก",
        "zipcode": 63110,
        "district_code": 630609,
        "amphoe_code": 6306,
        "province_code": 63
    },
    {
        "district": "ท่าสายลวด",
        "amphoe": "แม่สอด",
        "province": "ตาก",
        "zipcode": 63110,
        "district_code": 630606,
        "amphoe_code": 6306,
        "province_code": 63
    },
    {
        "district": "พระธาตุผาแดง",
        "amphoe": "แม่สอด",
        "province": "ตาก",
        "zipcode": 63110,
        "district_code": 630610,
        "amphoe_code": 6306,
        "province_code": 63
    },
    {
        "district": "พะวอ",
        "amphoe": "แม่สอด",
        "province": "ตาก",
        "zipcode": 63110,
        "district_code": 630603,
        "amphoe_code": 6306,
        "province_code": 63
    },
    {
        "district": "มหาวัน",
        "amphoe": "แม่สอด",
        "province": "ตาก",
        "zipcode": 63110,
        "district_code": 630608,
        "amphoe_code": 6306,
        "province_code": 63
    },
    {
        "district": "แม่กาษา",
        "amphoe": "แม่สอด",
        "province": "ตาก",
        "zipcode": 63110,
        "district_code": 630605,
        "amphoe_code": 6306,
        "province_code": 63
    },
    {
        "district": "แม่กุ",
        "amphoe": "แม่สอด",
        "province": "ตาก",
        "zipcode": 63110,
        "district_code": 630602,
        "amphoe_code": 6306,
        "province_code": 63
    },
    {
        "district": "แม่ตาว",
        "amphoe": "แม่สอด",
        "province": "ตาก",
        "zipcode": 63110,
        "district_code": 630604,
        "amphoe_code": 6306,
        "province_code": 63
    },
    {
        "district": "แม่ปะ",
        "amphoe": "แม่สอด",
        "province": "ตาก",
        "zipcode": 63110,
        "district_code": 630607,
        "amphoe_code": 6306,
        "province_code": 63
    },
    {
        "district": "แม่สอด",
        "amphoe": "แม่สอด",
        "province": "ตาก",
        "zipcode": 63110,
        "district_code": 630601,
        "amphoe_code": 6306,
        "province_code": 63
    },
    {
        "district": "ทองหลาง",
        "amphoe": "บ้านนา",
        "province": "นครนายก",
        "zipcode": 26110,
        "district_code": 260305,
        "amphoe_code": 2603,
        "province_code": 26
    },
    {
        "district": "บางอ้อ",
        "amphoe": "บ้านนา",
        "province": "นครนายก",
        "zipcode": 26110,
        "district_code": 260306,
        "amphoe_code": 2603,
        "province_code": 26
    },
    {
        "district": "บ้านนา",
        "amphoe": "บ้านนา",
        "province": "นครนายก",
        "zipcode": 26110,
        "district_code": 260301,
        "amphoe_code": 2603,
        "province_code": 26
    },
    {
        "district": "บ้านพริก",
        "amphoe": "บ้านนา",
        "province": "นครนายก",
        "zipcode": 26110,
        "district_code": 260303,
        "amphoe_code": 2603,
        "province_code": 26
    },
    {
        "district": "บ้านพร้าว",
        "amphoe": "บ้านนา",
        "province": "นครนายก",
        "zipcode": 26110,
        "district_code": 260302,
        "amphoe_code": 2603,
        "province_code": 26
    },
    {
        "district": "ป่าขะ",
        "amphoe": "บ้านนา",
        "province": "นครนายก",
        "zipcode": 26110,
        "district_code": 260308,
        "amphoe_code": 2603,
        "province_code": 26
    },
    {
        "district": "พิกุลออก",
        "amphoe": "บ้านนา",
        "province": "นครนายก",
        "zipcode": 26110,
        "district_code": 260307,
        "amphoe_code": 2603,
        "province_code": 26
    },
    {
        "district": "ศรีกะอาง",
        "amphoe": "บ้านนา",
        "province": "นครนายก",
        "zipcode": 26110,
        "district_code": 260310,
        "amphoe_code": 2603,
        "province_code": 26
    },
    {
        "district": "อาษา",
        "amphoe": "บ้านนา",
        "province": "นครนายก",
        "zipcode": 26110,
        "district_code": 260304,
        "amphoe_code": 2603,
        "province_code": 26
    },
    {
        "district": "เขาเพิ่ม",
        "amphoe": "บ้านนา",
        "province": "นครนายก",
        "zipcode": 26110,
        "district_code": 260309,
        "amphoe_code": 2603,
        "province_code": 26
    },
    {
        "district": "ท่าเรือ",
        "amphoe": "ปากพลี",
        "province": "นครนายก",
        "zipcode": 26130,
        "district_code": 260205,
        "amphoe_code": 2602,
        "province_code": 26
    },
    {
        "district": "นาหินลาด",
        "amphoe": "ปากพลี",
        "province": "นครนายก",
        "zipcode": 26130,
        "district_code": 260207,
        "amphoe_code": 2602,
        "province_code": 26
    },
    {
        "district": "ปากพลี",
        "amphoe": "ปากพลี",
        "province": "นครนายก",
        "zipcode": 26130,
        "district_code": 260203,
        "amphoe_code": 2602,
        "province_code": 26
    },
    {
        "district": "หนองแสง",
        "amphoe": "ปากพลี",
        "province": "นครนายก",
        "zipcode": 26130,
        "district_code": 260206,
        "amphoe_code": 2602,
        "province_code": 26
    },
    {
        "district": "เกาะหวาย",
        "amphoe": "ปากพลี",
        "province": "นครนายก",
        "zipcode": 26130,
        "district_code": 260201,
        "amphoe_code": 2602,
        "province_code": 26
    },
    {
        "district": "เกาะโพธิ์",
        "amphoe": "ปากพลี",
        "province": "นครนายก",
        "zipcode": 26130,
        "district_code": 260202,
        "amphoe_code": 2602,
        "province_code": 26
    },
    {
        "district": "โคกกรวด",
        "amphoe": "ปากพลี",
        "province": "นครนายก",
        "zipcode": 26130,
        "district_code": 260204,
        "amphoe_code": 2602,
        "province_code": 26
    },
    {
        "district": "คลองใหญ่",
        "amphoe": "องครักษ์",
        "province": "นครนายก",
        "zipcode": 26120,
        "district_code": 260411,
        "amphoe_code": 2604,
        "province_code": 26
    },
    {
        "district": "ชุมพล",
        "amphoe": "องครักษ์",
        "province": "นครนายก",
        "zipcode": 26120,
        "district_code": 260410,
        "amphoe_code": 2604,
        "province_code": 26
    },
    {
        "district": "ทรายมูล",
        "amphoe": "องครักษ์",
        "province": "นครนายก",
        "zipcode": 26120,
        "district_code": 260406,
        "amphoe_code": 2604,
        "province_code": 26
    },
    {
        "district": "บางปลากด",
        "amphoe": "องครักษ์",
        "province": "นครนายก",
        "zipcode": 26120,
        "district_code": 260407,
        "amphoe_code": 2604,
        "province_code": 26
    },
    {
        "district": "บางลูกเสือ",
        "amphoe": "องครักษ์",
        "province": "นครนายก",
        "zipcode": 26120,
        "district_code": 260408,
        "amphoe_code": 2604,
        "province_code": 26
    },
    {
        "district": "บางสมบูรณ์",
        "amphoe": "องครักษ์",
        "province": "นครนายก",
        "zipcode": 26120,
        "district_code": 260405,
        "amphoe_code": 2604,
        "province_code": 26
    },
    {
        "district": "บึงศาล",
        "amphoe": "องครักษ์",
        "province": "นครนายก",
        "zipcode": 26120,
        "district_code": 260402,
        "amphoe_code": 2604,
        "province_code": 26
    },
    {
        "district": "พระอาจารย์",
        "amphoe": "องครักษ์",
        "province": "นครนายก",
        "zipcode": 26120,
        "district_code": 260401,
        "amphoe_code": 2604,
        "province_code": 26
    },
    {
        "district": "ศีรษะกระบือ",
        "amphoe": "องครักษ์",
        "province": "นครนายก",
        "zipcode": 26120,
        "district_code": 260403,
        "amphoe_code": 2604,
        "province_code": 26
    },
    {
        "district": "องครักษ์",
        "amphoe": "องครักษ์",
        "province": "นครนายก",
        "zipcode": 26120,
        "district_code": 260409,
        "amphoe_code": 2604,
        "province_code": 26
    },
    {
        "district": "โพธิ์แทน",
        "amphoe": "องครักษ์",
        "province": "นครนายก",
        "zipcode": 26120,
        "district_code": 260404,
        "amphoe_code": 2604,
        "province_code": 26
    },
    {
        "district": "ดงละคร",
        "amphoe": "เมืองนครนายก",
        "province": "นครนายก",
        "zipcode": 26000,
        "district_code": 260108,
        "amphoe_code": 2601,
        "province_code": 26
    },
    {
        "district": "ดอนยอ",
        "amphoe": "เมืองนครนายก",
        "province": "นครนายก",
        "zipcode": 26000,
        "district_code": 260106,
        "amphoe_code": 2601,
        "province_code": 26
    },
    {
        "district": "ท่าช้าง",
        "amphoe": "เมืองนครนายก",
        "province": "นครนายก",
        "zipcode": 26000,
        "district_code": 260102,
        "amphoe_code": 2601,
        "province_code": 26
    },
    {
        "district": "ท่าทราย",
        "amphoe": "เมืองนครนายก",
        "province": "นครนายก",
        "zipcode": 26000,
        "district_code": 260105,
        "amphoe_code": 2601,
        "province_code": 26
    },
    {
        "district": "นครนายก",
        "amphoe": "เมืองนครนายก",
        "province": "นครนายก",
        "zipcode": 26000,
        "district_code": 260101,
        "amphoe_code": 2601,
        "province_code": 26
    },
    {
        "district": "บ้านใหญ่",
        "amphoe": "เมืองนครนายก",
        "province": "นครนายก",
        "zipcode": 26000,
        "district_code": 260103,
        "amphoe_code": 2601,
        "province_code": 26
    },
    {
        "district": "พรหมณี",
        "amphoe": "เมืองนครนายก",
        "province": "นครนายก",
        "zipcode": 26000,
        "district_code": 260113,
        "amphoe_code": 2601,
        "province_code": 26
    },
    {
        "district": "วังกระโจม",
        "amphoe": "เมืองนครนายก",
        "province": "นครนายก",
        "zipcode": 26000,
        "district_code": 260104,
        "amphoe_code": 2601,
        "province_code": 26
    },
    {
        "district": "ศรีจุฬา",
        "amphoe": "เมืองนครนายก",
        "province": "นครนายก",
        "zipcode": 26000,
        "district_code": 260107,
        "amphoe_code": 2601,
        "province_code": 26
    },
    {
        "district": "ศรีนาวา",
        "amphoe": "เมืองนครนายก",
        "province": "นครนายก",
        "zipcode": 26000,
        "district_code": 260109,
        "amphoe_code": 2601,
        "province_code": 26
    },
    {
        "district": "สาริกา",
        "amphoe": "เมืองนครนายก",
        "province": "นครนายก",
        "zipcode": 26000,
        "district_code": 260110,
        "amphoe_code": 2601,
        "province_code": 26
    },
    {
        "district": "หินตั้ง",
        "amphoe": "เมืองนครนายก",
        "province": "นครนายก",
        "zipcode": 26000,
        "district_code": 260111,
        "amphoe_code": 2601,
        "province_code": 26
    },
    {
        "district": "เขาพระ",
        "amphoe": "เมืองนครนายก",
        "province": "นครนายก",
        "zipcode": 26000,
        "district_code": 260112,
        "amphoe_code": 2601,
        "province_code": 26
    },
    {
        "district": "กระตีบ",
        "amphoe": "กำแพงแสน",
        "province": "นครปฐม",
        "zipcode": 73180,
        "district_code": 730202,
        "amphoe_code": 7302,
        "province_code": 73
    },
    {
        "district": "กำแพงแสน",
        "amphoe": "กำแพงแสน",
        "province": "นครปฐม",
        "zipcode": 73140,
        "district_code": 730212,
        "amphoe_code": 7302,
        "province_code": 73
    },
    {
        "district": "ดอนข่อย",
        "amphoe": "กำแพงแสน",
        "province": "นครปฐม",
        "zipcode": 73140,
        "district_code": 730208,
        "amphoe_code": 7302,
        "province_code": 73
    },
    {
        "district": "ทุ่งกระพังโหม",
        "amphoe": "กำแพงแสน",
        "province": "นครปฐม",
        "zipcode": 73140,
        "district_code": 730201,
        "amphoe_code": 7302,
        "province_code": 73
    },
    {
        "district": "ทุ่งขวาง",
        "amphoe": "กำแพงแสน",
        "province": "นครปฐม",
        "zipcode": 73140,
        "district_code": 730205,
        "amphoe_code": 7302,
        "province_code": 73
    },
    {
        "district": "ทุ่งบัว",
        "amphoe": "กำแพงแสน",
        "province": "นครปฐม",
        "zipcode": 73140,
        "district_code": 730207,
        "amphoe_code": 7302,
        "province_code": 73
    },
    {
        "district": "ทุ่งลูกนก",
        "amphoe": "กำแพงแสน",
        "province": "นครปฐม",
        "zipcode": 73140,
        "district_code": 730203,
        "amphoe_code": 7302,
        "province_code": 73
    },
    {
        "district": "รางพิกุล",
        "amphoe": "กำแพงแสน",
        "province": "นครปฐม",
        "zipcode": 73140,
        "district_code": 730213,
        "amphoe_code": 7302,
        "province_code": 73
    },
    {
        "district": "วังน้ำเขียว",
        "amphoe": "กำแพงแสน",
        "province": "นครปฐม",
        "zipcode": 73140,
        "district_code": 730215,
        "amphoe_code": 7302,
        "province_code": 73
    },
    {
        "district": "สระพัฒนา",
        "amphoe": "กำแพงแสน",
        "province": "นครปฐม",
        "zipcode": 73180,
        "district_code": 730209,
        "amphoe_code": 7302,
        "province_code": 73
    },
    {
        "district": "สระสี่มุม",
        "amphoe": "กำแพงแสน",
        "province": "นครปฐม",
        "zipcode": 73140,
        "district_code": 730206,
        "amphoe_code": 7302,
        "province_code": 73
    },
    {
        "district": "หนองกระทุ่ม",
        "amphoe": "กำแพงแสน",
        "province": "นครปฐม",
        "zipcode": 73140,
        "district_code": 730214,
        "amphoe_code": 7302,
        "province_code": 73
    },
    {
        "district": "ห้วยขวาง",
        "amphoe": "กำแพงแสน",
        "province": "นครปฐม",
        "zipcode": 73140,
        "district_code": 730204,
        "amphoe_code": 7302,
        "province_code": 73
    },
    {
        "district": "ห้วยม่วง",
        "amphoe": "กำแพงแสน",
        "province": "นครปฐม",
        "zipcode": 73180,
        "district_code": 730211,
        "amphoe_code": 7302,
        "province_code": 73
    },
    {
        "district": "ห้วยหมอนทอง",
        "amphoe": "กำแพงแสน",
        "province": "นครปฐม",
        "zipcode": 73140,
        "district_code": 730210,
        "amphoe_code": 7302,
        "province_code": 73
    },
    {
        "district": "ดอนพุทรา",
        "amphoe": "ดอนตูม",
        "province": "นครปฐม",
        "zipcode": 73150,
        "district_code": 730404,
        "amphoe_code": 7304,
        "province_code": 73
    },
    {
        "district": "ดอนรวก",
        "amphoe": "ดอนตูม",
        "province": "นครปฐม",
        "zipcode": 73150,
        "district_code": 730406,
        "amphoe_code": 7304,
        "province_code": 73
    },
    {
        "district": "บ้านหลวง",
        "amphoe": "ดอนตูม",
        "province": "นครปฐม",
        "zipcode": 73150,
        "district_code": 730405,
        "amphoe_code": 7304,
        "province_code": 73
    },
    {
        "district": "ลำลูกบัว",
        "amphoe": "ดอนตูม",
        "province": "นครปฐม",
        "zipcode": 73150,
        "district_code": 730408,
        "amphoe_code": 7304,
        "province_code": 73
    },
    {
        "district": "ลำเหย",
        "amphoe": "ดอนตูม",
        "province": "นครปฐม",
        "zipcode": 73150,
        "district_code": 730403,
        "amphoe_code": 7304,
        "province_code": 73
    },
    {
        "district": "สามง่าม",
        "amphoe": "ดอนตูม",
        "province": "นครปฐม",
        "zipcode": 73150,
        "district_code": 730401,
        "amphoe_code": 7304,
        "province_code": 73
    },
    {
        "district": "ห้วยด้วน",
        "amphoe": "ดอนตูม",
        "province": "นครปฐม",
        "zipcode": 73150,
        "district_code": 730407,
        "amphoe_code": 7304,
        "province_code": 73
    },
    {
        "district": "ห้วยพระ",
        "amphoe": "ดอนตูม",
        "province": "นครปฐม",
        "zipcode": 73150,
        "district_code": 730402,
        "amphoe_code": 7304,
        "province_code": 73
    },
    {
        "district": "ขุนแก้ว",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730307,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "งิ้วราย",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730323,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "ดอนแฝก",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730317,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "ท่ากระชับ",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730306,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "ท่าตำหนัก",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730304,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "ท่าพระยา",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730308,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "นครชัยศรี",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730301,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "บางกระเบา",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730302,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "บางพระ",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730320,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "บางระกำ",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730310,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "บางแก้ว",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730305,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "บางแก้วฟ้า",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730321,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "พะเนียด",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730309,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "ลานตากฟ้า",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730322,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "วัดละมุด",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730319,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "วัดสำโรง",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730316,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "วัดแค",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730303,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "ศรีมหาโพธิ์",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730314,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "ศรีษะทอง",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730312,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "สัมปทวน",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730315,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "ห้วยพลู",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730318,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "แหลมบัว",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730313,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "โคกพระเจดีย์",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730311,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "ไทยาวาส",
        "amphoe": "นครชัยศรี",
        "province": "นครปฐม",
        "zipcode": 73120,
        "district_code": 730324,
        "amphoe_code": 7303,
        "province_code": 73
    },
    {
        "district": "คลองนกกระทุง",
        "amphoe": "บางเลน",
        "province": "นครปฐม",
        "zipcode": 73130,
        "district_code": 730512,
        "amphoe_code": 7305,
        "province_code": 73
    },
    {
        "district": "ดอนตูม",
        "amphoe": "บางเลน",
        "province": "นครปฐม",
        "zipcode": 73130,
        "district_code": 730509,
        "amphoe_code": 7305,
        "province_code": 73
    },
    {
        "district": "นราภิรมย์",
        "amphoe": "บางเลน",
        "province": "นครปฐม",
        "zipcode": 73130,
        "district_code": 730513,
        "amphoe_code": 7305,
        "province_code": 73
    },
    {
        "district": "นิลเพชร",
        "amphoe": "บางเลน",
        "province": "นครปฐม",
        "zipcode": 73130,
        "district_code": 730510,
        "amphoe_code": 7305,
        "province_code": 73
    },
    {
        "district": "บัวปากท่า",
        "amphoe": "บางเลน",
        "province": "นครปฐม",
        "zipcode": 73130,
        "district_code": 730511,
        "amphoe_code": 7305,
        "province_code": 73
    },
    {
        "district": "บางปลา",
        "amphoe": "บางเลน",
        "province": "นครปฐม",
        "zipcode": 73130,
        "district_code": 730502,
        "amphoe_code": 7305,
        "province_code": 73
    },
    {
        "district": "บางภาษี",
        "amphoe": "บางเลน",
        "province": "นครปฐม",
        "zipcode": 73130,
        "district_code": 730504,
        "amphoe_code": 7305,
        "province_code": 73
    },
    {
        "district": "บางระกำ",
        "amphoe": "บางเลน",
        "province": "นครปฐม",
        "zipcode": 73130,
        "district_code": 730310,
        "amphoe_code": 7305,
        "province_code": 73
    },
    {
        "district": "บางหลวง",
        "amphoe": "บางเลน",
        "province": "นครปฐม",
        "zipcode": 73190,
        "district_code": 730503,
        "amphoe_code": 7305,
        "province_code": 73
    },
    {
        "district": "บางเลน",
        "amphoe": "บางเลน",
        "province": "นครปฐม",
        "zipcode": 73130,
        "district_code": 730501,
        "amphoe_code": 7305,
        "province_code": 73
    },
    {
        "district": "บางไทรป่า",
        "amphoe": "บางเลน",
        "province": "นครปฐม",
        "zipcode": 73130,
        "district_code": 730506,
        "amphoe_code": 7305,
        "province_code": 73
    },
    {
        "district": "ลำพญา",
        "amphoe": "บางเลน",
        "province": "นครปฐม",
        "zipcode": 73130,
        "district_code": 730514,
        "amphoe_code": 7305,
        "province_code": 73
    },
    {
        "district": "หินมูล",
        "amphoe": "บางเลน",
        "province": "นครปฐม",
        "zipcode": 73190,
        "district_code": 730507,
        "amphoe_code": 7305,
        "province_code": 73
    },
    {
        "district": "ไทรงาม",
        "amphoe": "บางเลน",
        "province": "นครปฐม",
        "zipcode": 73130,
        "district_code": 730508,
        "amphoe_code": 7305,
        "province_code": 73
    },
    {
        "district": "ไผ่หูช้าง",
        "amphoe": "บางเลน",
        "province": "นครปฐม",
        "zipcode": 73130,
        "district_code": 730515,
        "amphoe_code": 7305,
        "province_code": 73
    },
    {
        "district": "คลองโยง",
        "amphoe": "พุทธมณฑล",
        "province": "นครปฐม",
        "zipcode": 73170,
        "district_code": 730327,
        "amphoe_code": 7307,
        "province_code": 73
    },
    {
        "district": "มหาสวัสดิ์",
        "amphoe": "พุทธมณฑล",
        "province": "นครปฐม",
        "zipcode": 73170,
        "district_code": 730326,
        "amphoe_code": 7307,
        "province_code": 73
    },
    {
        "district": "ศาลายา",
        "amphoe": "พุทธมณฑล",
        "province": "นครปฐม",
        "zipcode": 73170,
        "district_code": 730325,
        "amphoe_code": 7307,
        "province_code": 73
    },
    {
        "district": "กระทุ่มล้ม",
        "amphoe": "สามพราน",
        "province": "นครปฐม",
        "zipcode": 73220,
        "district_code": 730610,
        "amphoe_code": 7306,
        "province_code": 73
    },
    {
        "district": "คลองจินดา",
        "amphoe": "สามพราน",
        "province": "นครปฐม",
        "zipcode": 73110,
        "district_code": 730613,
        "amphoe_code": 7306,
        "province_code": 73
    },
    {
        "district": "คลองใหม่",
        "amphoe": "สามพราน",
        "province": "นครปฐม",
        "zipcode": 73110,
        "district_code": 730611,
        "amphoe_code": 7306,
        "province_code": 73
    },
    {
        "district": "ตลาดจินดา",
        "amphoe": "สามพราน",
        "province": "นครปฐม",
        "zipcode": 73110,
        "district_code": 730612,
        "amphoe_code": 7306,
        "province_code": 73
    },
    {
        "district": "ทรงคนอง",
        "amphoe": "สามพราน",
        "province": "นครปฐม",
        "zipcode": 73210,
        "district_code": 730602,
        "amphoe_code": 7306,
        "province_code": 73
    },
    {
        "district": "ท่าข้าม",
        "amphoe": "สามพราน",
        "province": "นครปฐม",
        "zipcode": 73110,
        "district_code": 730601,
        "amphoe_code": 7306,
        "province_code": 73
    },
    {
        "district": "ท่าตลาด",
        "amphoe": "สามพราน",
        "province": "นครปฐม",
        "zipcode": 73110,
        "district_code": 730609,
        "amphoe_code": 7306,
        "province_code": 73
    },
    {
        "district": "บางกระทึก",
        "amphoe": "สามพราน",
        "province": "นครปฐม",
        "zipcode": 73210,
        "district_code": 730604,
        "amphoe_code": 7306,
        "province_code": 73
    },
    {
        "district": "บางช้าง",
        "amphoe": "สามพราน",
        "province": "นครปฐม",
        "zipcode": 73110,
        "district_code": 730607,
        "amphoe_code": 7306,
        "province_code": 73
    },
    {
        "district": "บางเตย",
        "amphoe": "สามพราน",
        "province": "นครปฐม",
        "zipcode": 73210,
        "district_code": 730605,
        "amphoe_code": 7306,
        "province_code": 73
    },
    {
        "district": "บ้านใหม่",
        "amphoe": "สามพราน",
        "province": "นครปฐม",
        "zipcode": 73110,
        "district_code": 730615,
        "amphoe_code": 7306,
        "province_code": 73
    },
    {
        "district": "ยายชา",
        "amphoe": "สามพราน",
        "province": "นครปฐม",
        "zipcode": 73110,
        "district_code": 730614,
        "amphoe_code": 7306,
        "province_code": 73
    },
    {
        "district": "สามพราน",
        "amphoe": "สามพราน",
        "province": "นครปฐม",
        "zipcode": 73110,
        "district_code": 730606,
        "amphoe_code": 7306,
        "province_code": 73
    },
    {
        "district": "หอมเกร็ด",
        "amphoe": "สามพราน",
        "province": "นครปฐม",
        "zipcode": 73110,
        "district_code": 730603,
        "amphoe_code": 7306,
        "province_code": 73
    },
    {
        "district": "อ้อมใหญ่",
        "amphoe": "สามพราน",
        "province": "นครปฐม",
        "zipcode": 73160,
        "district_code": 730616,
        "amphoe_code": 7306,
        "province_code": 73
    },
    {
        "district": "ไร่ขิง",
        "amphoe": "สามพราน",
        "province": "นครปฐม",
        "zipcode": 73210,
        "district_code": 730608,
        "amphoe_code": 7306,
        "province_code": 73
    },
    {
        "district": "ดอนยายหอม",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730108,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "ตาก้อง",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730105,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "ถนนขาด",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730109,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "ทัพหลวง",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730123,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "ทุ่งน้อย",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730115,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "ธรรมศาลา",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730104,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "นครปฐม",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730111,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "บางแขม",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730102,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "บ่อพลับ",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730110,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "บ้านยาง",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730125,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "พระปฐมเจดีย์",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730101,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "พระประโทน",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730103,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "มาบแค",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730106,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "ลำพยา",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730119,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "วังตะกู",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730112,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "วังเย็น",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730117,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "สนามจันทร์",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730107,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "สระกะเทียม",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730120,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "สวนป่าน",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730121,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "สามควายเผือก",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730114,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "หนองงูเหลือม",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730124,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "หนองดินแดง",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730116,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "หนองปากโลง",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730113,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "ห้วยจรเข้",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730122,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "โพรงมะเดื่อ",
        "amphoe": "เมืองนครปฐม",
        "province": "นครปฐม",
        "zipcode": 73000,
        "district_code": 730118,
        "amphoe_code": 7301,
        "province_code": 73
    },
    {
        "district": "ท่าจำปา",
        "amphoe": "ท่าอุเทน",
        "province": "นครพนม",
        "zipcode": 48120,
        "district_code": 480303,
        "amphoe_code": 4803,
        "province_code": 48
    },
    {
        "district": "ท่าอุเทน",
        "amphoe": "ท่าอุเทน",
        "province": "นครพนม",
        "zipcode": 48120,
        "district_code": 480301,
        "amphoe_code": 4803,
        "province_code": 48
    },
    {
        "district": "พนอม",
        "amphoe": "ท่าอุเทน",
        "province": "นครพนม",
        "zipcode": 48120,
        "district_code": 480305,
        "amphoe_code": 4803,
        "province_code": 48
    },
    {
        "district": "พะทาย",
        "amphoe": "ท่าอุเทน",
        "province": "นครพนม",
        "zipcode": 48120,
        "district_code": 480306,
        "amphoe_code": 4803,
        "province_code": 48
    },
    {
        "district": "รามราช",
        "amphoe": "ท่าอุเทน",
        "province": "นครพนม",
        "zipcode": 48120,
        "district_code": 480312,
        "amphoe_code": 4803,
        "province_code": 48
    },
    {
        "district": "หนองเทา",
        "amphoe": "ท่าอุเทน",
        "province": "นครพนม",
        "zipcode": 48120,
        "district_code": 480314,
        "amphoe_code": 4803,
        "province_code": 48
    },
    {
        "district": "เวินพระบาท",
        "amphoe": "ท่าอุเทน",
        "province": "นครพนม",
        "zipcode": 48120,
        "district_code": 480311,
        "amphoe_code": 4803,
        "province_code": 48
    },
    {
        "district": "โนนตาล",
        "amphoe": "ท่าอุเทน",
        "province": "นครพนม",
        "zipcode": 48120,
        "district_code": 480302,
        "amphoe_code": 4803,
        "province_code": 48
    },
    {
        "district": "ไชยบุรี",
        "amphoe": "ท่าอุเทน",
        "province": "นครพนม",
        "zipcode": 48120,
        "district_code": 480304,
        "amphoe_code": 4803,
        "province_code": 48
    },
    {
        "district": "กุดฉิม",
        "amphoe": "ธาตุพนม",
        "province": "นครพนม",
        "zipcode": 48110,
        "district_code": 480511,
        "amphoe_code": 4805,
        "province_code": 48
    },
    {
        "district": "ดอนนางหงส์",
        "amphoe": "ธาตุพนม",
        "province": "นครพนม",
        "zipcode": 48110,
        "district_code": 480507,
        "amphoe_code": 4805,
        "province_code": 48
    },
    {
        "district": "ธาตุพนม",
        "amphoe": "ธาตุพนม",
        "province": "นครพนม",
        "zipcode": 48110,
        "district_code": 480501,
        "amphoe_code": 4805,
        "province_code": 48
    },
    {
        "district": "ธาตุพนมเหนือ",
        "amphoe": "ธาตุพนม",
        "province": "นครพนม",
        "zipcode": 48110,
        "district_code": 480512,
        "amphoe_code": 4805,
        "province_code": 48
    },
    {
        "district": "นาถ่อน",
        "amphoe": "ธาตุพนม",
        "province": "นครพนม",
        "zipcode": 48110,
        "district_code": 480505,
        "amphoe_code": 4805,
        "province_code": 48
    },
    {
        "district": "นาหนาด",
        "amphoe": "ธาตุพนม",
        "province": "นครพนม",
        "zipcode": 48110,
        "district_code": 480510,
        "amphoe_code": 4805,
        "province_code": 48
    },
    {
        "district": "น้ำก่ำ",
        "amphoe": "ธาตุพนม",
        "province": "นครพนม",
        "zipcode": 48110,
        "district_code": 480508,
        "amphoe_code": 4805,
        "province_code": 48
    },
    {
        "district": "ฝั่งแดง",
        "amphoe": "ธาตุพนม",
        "province": "นครพนม",
        "zipcode": 48110,
        "district_code": 480502,
        "amphoe_code": 4805,
        "province_code": 48
    },
    {
        "district": "พระกลางทุ่ง",
        "amphoe": "ธาตุพนม",
        "province": "นครพนม",
        "zipcode": 48110,
        "district_code": 480504,
        "amphoe_code": 4805,
        "province_code": 48
    },
    {
        "district": "อุ่มเหม้า",
        "amphoe": "ธาตุพนม",
        "province": "นครพนม",
        "zipcode": 48110,
        "district_code": 480509,
        "amphoe_code": 4805,
        "province_code": 48
    },
    {
        "district": "แสนพัน",
        "amphoe": "ธาตุพนม",
        "province": "นครพนม",
        "zipcode": 48110,
        "district_code": 480506,
        "amphoe_code": 4805,
        "province_code": 48
    },
    {
        "district": "โพนแพง",
        "amphoe": "ธาตุพนม",
        "province": "นครพนม",
        "zipcode": 48110,
        "district_code": 480503,
        "amphoe_code": 4805,
        "province_code": 48
    },
    {
        "district": "ดอนเตย",
        "amphoe": "นาทม",
        "province": "นครพนม",
        "zipcode": 48140,
        "district_code": 480407,
        "amphoe_code": 4811,
        "province_code": 48
    },
    {
        "district": "นาทม",
        "amphoe": "นาทม",
        "province": "นครพนม",
        "zipcode": 48140,
        "district_code": 480405,
        "amphoe_code": 4811,
        "province_code": 48
    },
    {
        "district": "หนองซน",
        "amphoe": "นาทม",
        "province": "นครพนม",
        "zipcode": 48140,
        "district_code": 480406,
        "amphoe_code": 4811,
        "province_code": 48
    },
    {
        "district": "ท่าเรือ",
        "amphoe": "นาหว้า",
        "province": "นครพนม",
        "zipcode": 48180,
        "district_code": 480906,
        "amphoe_code": 4809,
        "province_code": 48
    },
    {
        "district": "นาคูณใหญ่",
        "amphoe": "นาหว้า",
        "province": "นครพนม",
        "zipcode": 48180,
        "district_code": 480904,
        "amphoe_code": 4809,
        "province_code": 48
    },
    {
        "district": "นางัว",
        "amphoe": "นาหว้า",
        "province": "นครพนม",
        "zipcode": 48180,
        "district_code": 480408,
        "amphoe_code": 4809,
        "province_code": 48
    },
    {
        "district": "นาหว้า",
        "amphoe": "นาหว้า",
        "province": "นครพนม",
        "zipcode": 48180,
        "district_code": 480901,
        "amphoe_code": 4809,
        "province_code": 48
    },
    {
        "district": "บ้านเสียว",
        "amphoe": "นาหว้า",
        "province": "นครพนม",
        "zipcode": 48180,
        "district_code": 480903,
        "amphoe_code": 4809,
        "province_code": 48
    },
    {
        "district": "เหล่าพัฒนา",
        "amphoe": "นาหว้า",
        "province": "นครพนม",
        "zipcode": 48180,
        "district_code": 480905,
        "amphoe_code": 4809,
        "province_code": 48
    },
    {
        "district": "ก้านเหลือง",
        "amphoe": "นาแก",
        "province": "นครพนม",
        "zipcode": 48130,
        "district_code": 480707,
        "amphoe_code": 4807,
        "province_code": 48
    },
    {
        "district": "คำพี้",
        "amphoe": "นาแก",
        "province": "นครพนม",
        "zipcode": 48130,
        "district_code": 480713,
        "amphoe_code": 4807,
        "province_code": 48
    },
    {
        "district": "นาคู่",
        "amphoe": "นาแก",
        "province": "นครพนม",
        "zipcode": 48130,
        "district_code": 480704,
        "amphoe_code": 4807,
        "province_code": 48
    },
    {
        "district": "นาเลียง",
        "amphoe": "นาแก",
        "province": "นครพนม",
        "zipcode": 48130,
        "district_code": 480709,
        "amphoe_code": 4807,
        "province_code": 48
    },
    {
        "district": "นาแก",
        "amphoe": "นาแก",
        "province": "นครพนม",
        "zipcode": 48130,
        "district_code": 480701,
        "amphoe_code": 4807,
        "province_code": 48
    },
    {
        "district": "บ้านแก้ง",
        "amphoe": "นาแก",
        "province": "นครพนม",
        "zipcode": 48130,
        "district_code": 480712,
        "amphoe_code": 4807,
        "province_code": 48
    },
    {
        "district": "พระซอง",
        "amphoe": "นาแก",
        "province": "นครพนม",
        "zipcode": 48130,
        "district_code": 480702,
        "amphoe_code": 4807,
        "province_code": 48
    },
    {
        "district": "พิมาน",
        "amphoe": "นาแก",
        "province": "นครพนม",
        "zipcode": 48130,
        "district_code": 480705,
        "amphoe_code": 4807,
        "province_code": 48
    },
    {
        "district": "พุ่มแก",
        "amphoe": "นาแก",
        "province": "นครพนม",
        "zipcode": 48130,
        "district_code": 480706,
        "amphoe_code": 4807,
        "province_code": 48
    },
    {
        "district": "สีชมพู",
        "amphoe": "นาแก",
        "province": "นครพนม",
        "zipcode": 48130,
        "district_code": 480715,
        "amphoe_code": 4807,
        "province_code": 48
    },
    {
        "district": "หนองบ่อ",
        "amphoe": "นาแก",
        "province": "นครพนม",
        "zipcode": 48130,
        "district_code": 480708,
        "amphoe_code": 4807,
        "province_code": 48
    },
    {
        "district": "หนองสังข์",
        "amphoe": "นาแก",
        "province": "นครพนม",
        "zipcode": 48130,
        "district_code": 480703,
        "amphoe_code": 4807,
        "province_code": 48
    },
    {
        "district": "นางัว",
        "amphoe": "บ้านแพง",
        "province": "นครพนม",
        "zipcode": 48140,
        "district_code": 480408,
        "amphoe_code": 4804,
        "province_code": 48
    },
    {
        "district": "นาเข",
        "amphoe": "บ้านแพง",
        "province": "นครพนม",
        "zipcode": 48140,
        "district_code": 480409,
        "amphoe_code": 4804,
        "province_code": 48
    },
    {
        "district": "บ้านแพง",
        "amphoe": "บ้านแพง",
        "province": "นครพนม",
        "zipcode": 48140,
        "district_code": 480401,
        "amphoe_code": 4804,
        "province_code": 48
    },
    {
        "district": "หนองแวง",
        "amphoe": "บ้านแพง",
        "province": "นครพนม",
        "zipcode": 48140,
        "district_code": 480404,
        "amphoe_code": 4804,
        "province_code": 48
    },
    {
        "district": "โพนทอง",
        "amphoe": "บ้านแพง",
        "province": "นครพนม",
        "zipcode": 48140,
        "district_code": 480403,
        "amphoe_code": 4804,
        "province_code": 48
    },
    {
        "district": "ไผ่ล้อม",
        "amphoe": "บ้านแพง",
        "province": "นครพนม",
        "zipcode": 48140,
        "district_code": 480402,
        "amphoe_code": 4804,
        "province_code": 48
    },
    {
        "district": "กุตาไก้",
        "amphoe": "ปลาปาก",
        "province": "นครพนม",
        "zipcode": 48160,
        "district_code": 480203,
        "amphoe_code": 4802,
        "province_code": 48
    },
    {
        "district": "นามะเขือ",
        "amphoe": "ปลาปาก",
        "province": "นครพนม",
        "zipcode": 48160,
        "district_code": 480207,
        "amphoe_code": 4802,
        "province_code": 48
    },
    {
        "district": "ปลาปาก",
        "amphoe": "ปลาปาก",
        "province": "นครพนม",
        "zipcode": 48160,
        "district_code": 480201,
        "amphoe_code": 4802,
        "province_code": 48
    },
    {
        "district": "มหาชัย",
        "amphoe": "ปลาปาก",
        "province": "นครพนม",
        "zipcode": 48160,
        "district_code": 480206,
        "amphoe_code": 4802,
        "province_code": 48
    },
    {
        "district": "หนองฮี",
        "amphoe": "ปลาปาก",
        "province": "นครพนม",
        "zipcode": 48160,
        "district_code": 480202,
        "amphoe_code": 4802,
        "province_code": 48
    },
    {
        "district": "หนองเทาใหญ่",
        "amphoe": "ปลาปาก",
        "province": "นครพนม",
        "zipcode": 48160,
        "district_code": 480208,
        "amphoe_code": 4802,
        "province_code": 48
    },
    {
        "district": "โคกสว่าง",
        "amphoe": "ปลาปาก",
        "province": "นครพนม",
        "zipcode": 48160,
        "district_code": 480204,
        "amphoe_code": 4802,
        "province_code": 48
    },
    {
        "district": "โคกสูง",
        "amphoe": "ปลาปาก",
        "province": "นครพนม",
        "zipcode": 48160,
        "district_code": 480205,
        "amphoe_code": 4802,
        "province_code": 48
    },
    {
        "district": "ยอดชาด",
        "amphoe": "วังยาง",
        "province": "นครพนม",
        "zipcode": 48130,
        "district_code": 480714,
        "amphoe_code": 4812,
        "province_code": 48
    },
    {
        "district": "วังยาง",
        "amphoe": "วังยาง",
        "province": "นครพนม",
        "zipcode": 48130,
        "district_code": 480711,
        "amphoe_code": 4812,
        "province_code": 48
    },
    {
        "district": "หนองโพธิ์",
        "amphoe": "วังยาง",
        "province": "นครพนม",
        "zipcode": 48130,
        "district_code": 480716,
        "amphoe_code": 4812,
        "province_code": 48
    },
    {
        "district": "โคกสี",
        "amphoe": "วังยาง",
        "province": "นครพนม",
        "zipcode": 48130,
        "district_code": 480710,
        "amphoe_code": 4812,
        "province_code": 48
    },
    {
        "district": "ท่าบ่อสงคราม",
        "amphoe": "ศรีสงคราม",
        "province": "นครพนม",
        "zipcode": 48150,
        "district_code": 480805,
        "amphoe_code": 4808,
        "province_code": 48
    },
    {
        "district": "นาคำ",
        "amphoe": "ศรีสงคราม",
        "province": "นครพนม",
        "zipcode": 48150,
        "district_code": 480807,
        "amphoe_code": 4808,
        "province_code": 48
    },
    {
        "district": "นาเดื่อ",
        "amphoe": "ศรีสงคราม",
        "province": "นครพนม",
        "zipcode": 48150,
        "district_code": 480802,
        "amphoe_code": 4808,
        "province_code": 48
    },
    {
        "district": "บ้านข่า",
        "amphoe": "ศรีสงคราม",
        "province": "นครพนม",
        "zipcode": 48150,
        "district_code": 480806,
        "amphoe_code": 4808,
        "province_code": 48
    },
    {
        "district": "บ้านเอื้อง",
        "amphoe": "ศรีสงคราม",
        "province": "นครพนม",
        "zipcode": 48150,
        "district_code": 480803,
        "amphoe_code": 4808,
        "province_code": 48
    },
    {
        "district": "ศรีสงคราม",
        "amphoe": "ศรีสงคราม",
        "province": "นครพนม",
        "zipcode": 48150,
        "district_code": 480801,
        "amphoe_code": 4808,
        "province_code": 48
    },
    {
        "district": "สามผง",
        "amphoe": "ศรีสงคราม",
        "province": "นครพนม",
        "zipcode": 48150,
        "district_code": 480804,
        "amphoe_code": 4808,
        "province_code": 48
    },
    {
        "district": "หาดแพง",
        "amphoe": "ศรีสงคราม",
        "province": "นครพนม",
        "zipcode": 48150,
        "district_code": 480809,
        "amphoe_code": 4808,
        "province_code": 48
    },
    {
        "district": "โพนสว่าง",
        "amphoe": "ศรีสงคราม",
        "province": "นครพนม",
        "zipcode": 48150,
        "district_code": 480808,
        "amphoe_code": 4808,
        "province_code": 48
    },
    {
        "district": "กุรุคุ",
        "amphoe": "เมืองนครพนม",
        "province": "นครพนม",
        "zipcode": 48000,
        "district_code": 480105,
        "amphoe_code": 4801,
        "province_code": 48
    },
    {
        "district": "ขามเฒ่า",
        "amphoe": "เมืองนครพนม",
        "province": "นครพนม",
        "zipcode": 48000,
        "district_code": 480108,
        "amphoe_code": 4801,
        "province_code": 48
    },
    {
        "district": "คำเตย",
        "amphoe": "เมืองนครพนม",
        "province": "นครพนม",
        "zipcode": 48000,
        "district_code": 480111,
        "amphoe_code": 4801,
        "province_code": 48
    },
    {
        "district": "ดงขวาง",
        "amphoe": "เมืองนครพนม",
        "province": "นครพนม",
        "zipcode": 48000,
        "district_code": 480113,
        "amphoe_code": 4801,
        "province_code": 48
    },
    {
        "district": "ท่าค้อ",
        "amphoe": "เมืองนครพนม",
        "province": "นครพนม",
        "zipcode": 48000,
        "district_code": 480110,
        "amphoe_code": 4801,
        "province_code": 48
    },
    {
        "district": "นาทราย",
        "amphoe": "เมืองนครพนม",
        "province": "นครพนม",
        "zipcode": 48000,
        "district_code": 480103,
        "amphoe_code": 4801,
        "province_code": 48
    },
    {
        "district": "นาราชควาย",
        "amphoe": "เมืองนครพนม",
        "province": "นครพนม",
        "zipcode": 48000,
        "district_code": 480104,
        "amphoe_code": 4801,
        "province_code": 48
    },
    {
        "district": "บ้านกลาง",
        "amphoe": "เมืองนครพนม",
        "province": "นครพนม",
        "zipcode": 48000,
        "district_code": 480109,
        "amphoe_code": 4801,
        "province_code": 48
    },
    {
        "district": "บ้านผึ้ง",
        "amphoe": "เมืองนครพนม",
        "province": "นครพนม",
        "zipcode": 48000,
        "district_code": 480106,
        "amphoe_code": 4801,
        "province_code": 48
    },
    {
        "district": "วังตามัว",
        "amphoe": "เมืองนครพนม",
        "province": "นครพนม",
        "zipcode": 48000,
        "district_code": 480114,
        "amphoe_code": 4801,
        "province_code": 48
    },
    {
        "district": "หนองญาติ",
        "amphoe": "เมืองนครพนม",
        "province": "นครพนม",
        "zipcode": 48000,
        "district_code": 480112,
        "amphoe_code": 4801,
        "province_code": 48
    },
    {
        "district": "หนองแสง",
        "amphoe": "เมืองนครพนม",
        "province": "นครพนม",
        "zipcode": 48000,
        "district_code": 480102,
        "amphoe_code": 4801,
        "province_code": 48
    },
    {
        "district": "อาจสามารถ",
        "amphoe": "เมืองนครพนม",
        "province": "นครพนม",
        "zipcode": 48000,
        "district_code": 480107,
        "amphoe_code": 4801,
        "province_code": 48
    },
    {
        "district": "โพธิ์ตาก",
        "amphoe": "เมืองนครพนม",
        "province": "นครพนม",
        "zipcode": 48000,
        "district_code": 480115,
        "amphoe_code": 4801,
        "province_code": 48
    },
    {
        "district": "ในเมือง",
        "amphoe": "เมืองนครพนม",
        "province": "นครพนม",
        "zipcode": 48000,
        "district_code": 480101,
        "amphoe_code": 4801,
        "province_code": 48
    },
    {
        "district": "ท่าลาด",
        "amphoe": "เรณูนคร",
        "province": "นครพนม",
        "zipcode": 48170,
        "district_code": 480603,
        "amphoe_code": 4806,
        "province_code": 48
    },
    {
        "district": "นาขาม",
        "amphoe": "เรณูนคร",
        "province": "นครพนม",
        "zipcode": 48170,
        "district_code": 480609,
        "amphoe_code": 4806,
        "province_code": 48
    },
    {
        "district": "นางาม",
        "amphoe": "เรณูนคร",
        "province": "นครพนม",
        "zipcode": 48170,
        "district_code": 480604,
        "amphoe_code": 4806,
        "province_code": 48
    },
    {
        "district": "หนองย่างชิ้น",
        "amphoe": "เรณูนคร",
        "province": "นครพนม",
        "zipcode": 48170,
        "district_code": 480607,
        "amphoe_code": 4806,
        "province_code": 48
    },
    {
        "district": "เรณู",
        "amphoe": "เรณูนคร",
        "province": "นครพนม",
        "zipcode": 48170,
        "district_code": 480601,
        "amphoe_code": 4806,
        "province_code": 48
    },
    {
        "district": "เรณูใต้",
        "amphoe": "เรณูนคร",
        "province": "นครพนม",
        "zipcode": 48170,
        "district_code": 480608,
        "amphoe_code": 4806,
        "province_code": 48
    },
    {
        "district": "โคกหินแฮ่",
        "amphoe": "เรณูนคร",
        "province": "นครพนม",
        "zipcode": 48170,
        "district_code": 480605,
        "amphoe_code": 4806,
        "province_code": 48
    },
    {
        "district": "โพนทอง",
        "amphoe": "เรณูนคร",
        "province": "นครพนม",
        "zipcode": 48170,
        "district_code": 480403,
        "amphoe_code": 4806,
        "province_code": 48
    },
    {
        "district": "นาขมิ้น",
        "amphoe": "โพนสวรรค์",
        "province": "นครพนม",
        "zipcode": 48190,
        "district_code": 480307,
        "amphoe_code": 4810,
        "province_code": 48
    },
    {
        "district": "นาหัวบ่อ",
        "amphoe": "โพนสวรรค์",
        "province": "นครพนม",
        "zipcode": 48190,
        "district_code": 480313,
        "amphoe_code": 4810,
        "province_code": 48
    },
    {
        "district": "นาใน",
        "amphoe": "โพนสวรรค์",
        "province": "นครพนม",
        "zipcode": 48190,
        "district_code": 481007,
        "amphoe_code": 4810,
        "province_code": 48
    },
    {
        "district": "บ้านค้อ",
        "amphoe": "โพนสวรรค์",
        "province": "นครพนม",
        "zipcode": 48190,
        "district_code": 480310,
        "amphoe_code": 4810,
        "province_code": 48
    },
    {
        "district": "โพนจาน",
        "amphoe": "โพนสวรรค์",
        "province": "นครพนม",
        "zipcode": 48190,
        "district_code": 481006,
        "amphoe_code": 4810,
        "province_code": 48
    },
    {
        "district": "โพนบก",
        "amphoe": "โพนสวรรค์",
        "province": "นครพนม",
        "zipcode": 48190,
        "district_code": 480308,
        "amphoe_code": 4810,
        "province_code": 48
    },
    {
        "district": "โพนสวรรค์",
        "amphoe": "โพนสวรรค์",
        "province": "นครพนม",
        "zipcode": 48190,
        "district_code": 480309,
        "amphoe_code": 4810,
        "province_code": 48
    },
    {
        "district": "ขามทะเลสอ",
        "amphoe": "ขามทะเลสอ",
        "province": "นครราชสีมา",
        "zipcode": 30280,
        "district_code": 301901,
        "amphoe_code": 3019,
        "province_code": 30
    },
    {
        "district": "บึงอ้อ",
        "amphoe": "ขามทะเลสอ",
        "province": "นครราชสีมา",
        "zipcode": 30280,
        "district_code": 301905,
        "amphoe_code": 3019,
        "province_code": 30
    },
    {
        "district": "พันดุง",
        "amphoe": "ขามทะเลสอ",
        "province": "นครราชสีมา",
        "zipcode": 30280,
        "district_code": 301903,
        "amphoe_code": 3019,
        "province_code": 30
    },
    {
        "district": "หนองสรวง",
        "amphoe": "ขามทะเลสอ",
        "province": "นครราชสีมา",
        "zipcode": 30280,
        "district_code": 301904,
        "amphoe_code": 3019,
        "province_code": 30
    },
    {
        "district": "โป่งแดง",
        "amphoe": "ขามทะเลสอ",
        "province": "นครราชสีมา",
        "zipcode": 30280,
        "district_code": 301902,
        "amphoe_code": 3019,
        "province_code": 30
    },
    {
        "district": "ขามสะแกแสง",
        "amphoe": "ขามสะแกแสง",
        "province": "นครราชสีมา",
        "zipcode": 30290,
        "district_code": 301101,
        "amphoe_code": 3011,
        "province_code": 30
    },
    {
        "district": "ชีวึก",
        "amphoe": "ขามสะแกแสง",
        "province": "นครราชสีมา",
        "zipcode": 30290,
        "district_code": 301104,
        "amphoe_code": 3011,
        "province_code": 30
    },
    {
        "district": "พะงาด",
        "amphoe": "ขามสะแกแสง",
        "province": "นครราชสีมา",
        "zipcode": 30290,
        "district_code": 301105,
        "amphoe_code": 3011,
        "province_code": 30
    },
    {
        "district": "หนองหัวฟาน",
        "amphoe": "ขามสะแกแสง",
        "province": "นครราชสีมา",
        "zipcode": 30290,
        "district_code": 301106,
        "amphoe_code": 3011,
        "province_code": 30
    },
    {
        "district": "เมืองนาท",
        "amphoe": "ขามสะแกแสง",
        "province": "นครราชสีมา",
        "zipcode": 30290,
        "district_code": 301103,
        "amphoe_code": 3011,
        "province_code": 30
    },
    {
        "district": "เมืองเกษตร",
        "amphoe": "ขามสะแกแสง",
        "province": "นครราชสีมา",
        "zipcode": 30290,
        "district_code": 301107,
        "amphoe_code": 3011,
        "province_code": 30
    },
    {
        "district": "โนนเมือง",
        "amphoe": "ขามสะแกแสง",
        "province": "นครราชสีมา",
        "zipcode": 30290,
        "district_code": 301102,
        "amphoe_code": 3011,
        "province_code": 30
    },
    {
        "district": "ขามสมบูรณ์",
        "amphoe": "คง",
        "province": "นครราชสีมา",
        "zipcode": 30260,
        "district_code": 300410,
        "amphoe_code": 3004,
        "province_code": 30
    },
    {
        "district": "คูขาด",
        "amphoe": "คง",
        "province": "นครราชสีมา",
        "zipcode": 30260,
        "district_code": 300402,
        "amphoe_code": 3004,
        "province_code": 30
    },
    {
        "district": "ดอนใหญ่",
        "amphoe": "คง",
        "province": "นครราชสีมา",
        "zipcode": 30260,
        "district_code": 300409,
        "amphoe_code": 3004,
        "province_code": 30
    },
    {
        "district": "ตาจั่น",
        "amphoe": "คง",
        "province": "นครราชสีมา",
        "zipcode": 30260,
        "district_code": 300404,
        "amphoe_code": 3004,
        "province_code": 30
    },
    {
        "district": "บ้านปรางค์",
        "amphoe": "คง",
        "province": "นครราชสีมา",
        "zipcode": 30260,
        "district_code": 300405,
        "amphoe_code": 3004,
        "province_code": 30
    },
    {
        "district": "หนองบัว",
        "amphoe": "คง",
        "province": "นครราชสีมา",
        "zipcode": 30260,
        "district_code": 300407,
        "amphoe_code": 3004,
        "province_code": 30
    },
    {
        "district": "หนองมะนาว",
        "amphoe": "คง",
        "province": "นครราชสีมา",
        "zipcode": 30260,
        "district_code": 300406,
        "amphoe_code": 3004,
        "province_code": 30
    },
    {
        "district": "เทพาลัย",
        "amphoe": "คง",
        "province": "นครราชสีมา",
        "zipcode": 30260,
        "district_code": 300403,
        "amphoe_code": 3004,
        "province_code": 30
    },
    {
        "district": "เมืองคง",
        "amphoe": "คง",
        "province": "นครราชสีมา",
        "zipcode": 30260,
        "district_code": 300401,
        "amphoe_code": 3004,
        "province_code": 30
    },
    {
        "district": "โนนเต็ง",
        "amphoe": "คง",
        "province": "นครราชสีมา",
        "zipcode": 30260,
        "district_code": 300408,
        "amphoe_code": 3004,
        "province_code": 30
    },
    {
        "district": "ครบุรี",
        "amphoe": "ครบุรี",
        "province": "นครราชสีมา",
        "zipcode": 30250,
        "district_code": 300203,
        "amphoe_code": 3002,
        "province_code": 30
    },
    {
        "district": "ครบุรีใต้",
        "amphoe": "ครบุรี",
        "province": "นครราชสีมา",
        "zipcode": 30250,
        "district_code": 300210,
        "amphoe_code": 3002,
        "province_code": 30
    },
    {
        "district": "จระเข้หิน",
        "amphoe": "ครบุรี",
        "province": "นครราชสีมา",
        "zipcode": 30250,
        "district_code": 300205,
        "amphoe_code": 3002,
        "province_code": 30
    },
    {
        "district": "ตะแบกบาน",
        "amphoe": "ครบุรี",
        "province": "นครราชสีมา",
        "zipcode": 30250,
        "district_code": 300211,
        "amphoe_code": 3002,
        "province_code": 30
    },
    {
        "district": "บ้านใหม่",
        "amphoe": "ครบุรี",
        "province": "นครราชสีมา",
        "zipcode": 30250,
        "district_code": 300113,
        "amphoe_code": 3002,
        "province_code": 30
    },
    {
        "district": "มาบตะโกเอน",
        "amphoe": "ครบุรี",
        "province": "นครราชสีมา",
        "zipcode": 30250,
        "district_code": 300206,
        "amphoe_code": 3002,
        "province_code": 30
    },
    {
        "district": "ลำเพียก",
        "amphoe": "ครบุรี",
        "province": "นครราชสีมา",
        "zipcode": 30250,
        "district_code": 300209,
        "amphoe_code": 3002,
        "province_code": 30
    },
    {
        "district": "สระว่านพระยา",
        "amphoe": "ครบุรี",
        "province": "นครราชสีมา",
        "zipcode": 30250,
        "district_code": 300212,
        "amphoe_code": 3002,
        "province_code": 30
    },
    {
        "district": "อรพิมพ์",
        "amphoe": "ครบุรี",
        "province": "นครราชสีมา",
        "zipcode": 30250,
        "district_code": 300207,
        "amphoe_code": 3002,
        "province_code": 30
    },
    {
        "district": "เฉลียง",
        "amphoe": "ครบุรี",
        "province": "นครราชสีมา",
        "zipcode": 30250,
        "district_code": 300202,
        "amphoe_code": 3002,
        "province_code": 30
    },
    {
        "district": "แชะ",
        "amphoe": "ครบุรี",
        "province": "นครราชสีมา",
        "zipcode": 30250,
        "district_code": 300201,
        "amphoe_code": 3002,
        "province_code": 30
    },
    {
        "district": "โคกกระชาย",
        "amphoe": "ครบุรี",
        "province": "นครราชสีมา",
        "zipcode": 30250,
        "district_code": 300204,
        "amphoe_code": 3002,
        "province_code": 30
    },
    {
        "district": "คลองเมือง",
        "amphoe": "จักราช",
        "province": "นครราชสีมา",
        "zipcode": 30230,
        "district_code": 300611,
        "amphoe_code": 3006,
        "province_code": 30
    },
    {
        "district": "จักราช",
        "amphoe": "จักราช",
        "province": "นครราชสีมา",
        "zipcode": 30230,
        "district_code": 300601,
        "amphoe_code": 3006,
        "province_code": 30
    },
    {
        "district": "ทองหลาง",
        "amphoe": "จักราช",
        "province": "นครราชสีมา",
        "zipcode": 30230,
        "district_code": 300603,
        "amphoe_code": 3006,
        "province_code": 30
    },
    {
        "district": "ศรีละกอ",
        "amphoe": "จักราช",
        "province": "นครราชสีมา",
        "zipcode": 30230,
        "district_code": 300610,
        "amphoe_code": 3006,
        "province_code": 30
    },
    {
        "district": "สีสุก",
        "amphoe": "จักราช",
        "province": "นครราชสีมา",
        "zipcode": 30230,
        "district_code": 300604,
        "amphoe_code": 3006,
        "province_code": 30
    },
    {
        "district": "หนองขาม",
        "amphoe": "จักราช",
        "province": "นครราชสีมา",
        "zipcode": 30230,
        "district_code": 300605,
        "amphoe_code": 3006,
        "province_code": 30
    },
    {
        "district": "หนองพลวง",
        "amphoe": "จักราช",
        "province": "นครราชสีมา",
        "zipcode": 30230,
        "district_code": 300607,
        "amphoe_code": 3006,
        "province_code": 30
    },
    {
        "district": "หินโคน",
        "amphoe": "จักราช",
        "province": "นครราชสีมา",
        "zipcode": 30230,
        "district_code": 300613,
        "amphoe_code": 3006,
        "province_code": 30
    },
    {
        "district": "ชุมพวง",
        "amphoe": "ชุมพวง",
        "province": "นครราชสีมา",
        "zipcode": 30270,
        "district_code": 301701,
        "amphoe_code": 3017,
        "province_code": 30
    },
    {
        "district": "ตลาดไทร",
        "amphoe": "ชุมพวง",
        "province": "นครราชสีมา",
        "zipcode": 30270,
        "district_code": 301306,
        "amphoe_code": 3017,
        "province_code": 30
    },
    {
        "district": "ท่าลาด",
        "amphoe": "ชุมพวง",
        "province": "นครราชสีมา",
        "zipcode": 30270,
        "district_code": 301703,
        "amphoe_code": 3017,
        "province_code": 30
    },
    {
        "district": "ประสุข",
        "amphoe": "ชุมพวง",
        "province": "นครราชสีมา",
        "zipcode": 30270,
        "district_code": 301702,
        "amphoe_code": 3017,
        "province_code": 30
    },
    {
        "district": "สาหร่าย",
        "amphoe": "ชุมพวง",
        "province": "นครราชสีมา",
        "zipcode": 30270,
        "district_code": 301704,
        "amphoe_code": 3017,
        "province_code": 30
    },
    {
        "district": "หนองหลัก",
        "amphoe": "ชุมพวง",
        "province": "นครราชสีมา",
        "zipcode": 30270,
        "district_code": 301714,
        "amphoe_code": 3017,
        "province_code": 30
    },
    {
        "district": "โนนตูม",
        "amphoe": "ชุมพวง",
        "province": "นครราชสีมา",
        "zipcode": 30270,
        "district_code": 301716,
        "amphoe_code": 3017,
        "province_code": 30
    },
    {
        "district": "โนนยอ",
        "amphoe": "ชุมพวง",
        "province": "นครราชสีมา",
        "zipcode": 30270,
        "district_code": 301717,
        "amphoe_code": 3017,
        "province_code": 30
    },
    {
        "district": "โนนรัง",
        "amphoe": "ชุมพวง",
        "province": "นครราชสีมา",
        "zipcode": 30270,
        "district_code": 301710,
        "amphoe_code": 3017,
        "province_code": 30
    },
    {
        "district": "กุดพิมาน",
        "amphoe": "ด่านขุนทด",
        "province": "นครราชสีมา",
        "zipcode": 30210,
        "district_code": 300801,
        "amphoe_code": 3008,
        "province_code": 30
    },
    {
        "district": "ด่านขุนทด",
        "amphoe": "ด่านขุนทด",
        "province": "นครราชสีมา",
        "zipcode": 30210,
        "district_code": 300802,
        "amphoe_code": 3008,
        "province_code": 30
    },
    {
        "district": "ด่านนอก",
        "amphoe": "ด่านขุนทด",
        "province": "นครราชสีมา",
        "zipcode": 30210,
        "district_code": 300803,
        "amphoe_code": 3008,
        "province_code": 30
    },
    {
        "district": "ด่านใน",
        "amphoe": "ด่านขุนทด",
        "province": "นครราชสีมา",
        "zipcode": 30210,
        "district_code": 300804,
        "amphoe_code": 3008,
        "province_code": 30
    },
    {
        "district": "ตะเคียน",
        "amphoe": "ด่านขุนทด",
        "province": "นครราชสีมา",
        "zipcode": 30210,
        "district_code": 300805,
        "amphoe_code": 3008,
        "province_code": 30
    },
    {
        "district": "บ้านเก่า",
        "amphoe": "ด่านขุนทด",
        "province": "นครราชสีมา",
        "zipcode": 30210,
        "district_code": 300806,
        "amphoe_code": 3008,
        "province_code": 30
    },
    {
        "district": "บ้านแปรง",
        "amphoe": "ด่านขุนทด",
        "province": "นครราชสีมา",
        "zipcode": 36220,
        "district_code": 300807,
        "amphoe_code": 3008,
        "province_code": 30
    },
    {
        "district": "พันชนะ",
        "amphoe": "ด่านขุนทด",
        "province": "นครราชสีมา",
        "zipcode": 30210,
        "district_code": 300808,
        "amphoe_code": 3008,
        "province_code": 30
    },
    {
        "district": "สระจรเข้",
        "amphoe": "ด่านขุนทด",
        "province": "นครราชสีมา",
        "zipcode": 30210,
        "district_code": 300809,
        "amphoe_code": 3008,
        "province_code": 30
    },
    {
        "district": "หนองกราด",
        "amphoe": "ด่านขุนทด",
        "province": "นครราชสีมา",
        "zipcode": 30210,
        "district_code": 300810,
        "amphoe_code": 3008,
        "province_code": 30
    },
    {
        "district": "หนองบัวตะเกียด",
        "amphoe": "ด่านขุนทด",
        "province": "นครราชสีมา",
        "zipcode": 30210,
        "district_code": 300811,
        "amphoe_code": 3008,
        "province_code": 30
    },
    {
        "district": "หนองบัวละคร",
        "amphoe": "ด่านขุนทด",
        "province": "นครราชสีมา",
        "zipcode": 30210,
        "district_code": 300812,
        "amphoe_code": 3008,
        "province_code": 30
    },
    {
        "district": "หนองไทร",
        "amphoe": "ด่านขุนทด",
        "province": "นครราชสีมา",
        "zipcode": 36220,
        "district_code": 300818,
        "amphoe_code": 3008,
        "province_code": 30
    },
    {
        "district": "หินดาด",
        "amphoe": "ด่านขุนทด",
        "province": "นครราชสีมา",
        "zipcode": 30210,
        "district_code": 300813,
        "amphoe_code": 3008,
        "province_code": 30
    },
    {
        "district": "ห้วยบง",
        "amphoe": "ด่านขุนทด",
        "province": "นครราชสีมา",
        "zipcode": 30210,
        "district_code": 300815,
        "amphoe_code": 3008,
        "province_code": 30
    },
    {
        "district": "โนนเมืองพัฒนา",
        "amphoe": "ด่านขุนทด",
        "province": "นครราชสีมา",
        "zipcode": 30210,
        "district_code": 300817,
        "amphoe_code": 3008,
        "province_code": 30
    },
    {
        "district": "บัวลาย",
        "amphoe": "บัวลาย",
        "province": "นครราชสีมา",
        "zipcode": 30120,
        "district_code": 301210,
        "amphoe_code": 3030,
        "province_code": 30
    },
    {
        "district": "หนองหว้า",
        "amphoe": "บัวลาย",
        "province": "นครราชสีมา",
        "zipcode": 30120,
        "district_code": 301209,
        "amphoe_code": 3030,
        "province_code": 30
    },
    {
        "district": "เมืองพะไล",
        "amphoe": "บัวลาย",
        "province": "นครราชสีมา",
        "zipcode": 30120,
        "district_code": 301222,
        "amphoe_code": 3030,
        "province_code": 30
    },
    {
        "district": "โนนจาน",
        "amphoe": "บัวลาย",
        "province": "นครราชสีมา",
        "zipcode": 30120,
        "district_code": 301216,
        "amphoe_code": 3030,
        "province_code": 30
    },
    {
        "district": "กุดจอก",
        "amphoe": "บัวใหญ่",
        "province": "นครราชสีมา",
        "zipcode": 30120,
        "district_code": 301214,
        "amphoe_code": 3012,
        "province_code": 30
    },
    {
        "district": "ขุนทอง",
        "amphoe": "บัวใหญ่",
        "province": "นครราชสีมา",
        "zipcode": 30120,
        "district_code": 301220,
        "amphoe_code": 3012,
        "province_code": 30
    },
    {
        "district": "ดอนตะหนิน",
        "amphoe": "บัวใหญ่",
        "province": "นครราชสีมา",
        "zipcode": 30120,
        "district_code": 301206,
        "amphoe_code": 3012,
        "province_code": 30
    },
    {
        "district": "ด่านช้าง",
        "amphoe": "บัวใหญ่",
        "province": "นครราชสีมา",
        "zipcode": 30120,
        "district_code": 301215,
        "amphoe_code": 3012,
        "province_code": 30
    },
    {
        "district": "บัวใหญ่",
        "amphoe": "บัวใหญ่",
        "province": "นครราชสีมา",
        "zipcode": 30120,
        "district_code": 301201,
        "amphoe_code": 3012,
        "province_code": 30
    },
    {
        "district": "หนองบัวสะอาด",
        "amphoe": "บัวใหญ่",
        "province": "นครราชสีมา",
        "zipcode": 30120,
        "district_code": 301207,
        "amphoe_code": 3012,
        "province_code": 30
    },
    {
        "district": "หนองแจ้งใหญ่",
        "amphoe": "บัวใหญ่",
        "province": "นครราชสีมา",
        "zipcode": 30120,
        "district_code": 301224,
        "amphoe_code": 3012,
        "province_code": 30
    },
    {
        "district": "ห้วยยาง",
        "amphoe": "บัวใหญ่",
        "province": "นครราชสีมา",
        "zipcode": 30120,
        "district_code": 301203,
        "amphoe_code": 3012,
        "province_code": 30
    },
    {
        "district": "เสมาใหญ่",
        "amphoe": "บัวใหญ่",
        "province": "นครราชสีมา",
        "zipcode": 30120,
        "district_code": 301204,
        "amphoe_code": 3012,
        "province_code": 30
    },
    {
        "district": "โนนทองหลาง",
        "amphoe": "บัวใหญ่",
        "province": "นครราชสีมา",
        "zipcode": 30120,
        "district_code": 301208,
        "amphoe_code": 3012,
        "province_code": 30
    },
    {
        "district": "ช่อระกา",
        "amphoe": "บ้านเหลื่อม",
        "province": "นครราชสีมา",
        "zipcode": 30350,
        "district_code": 300504,
        "amphoe_code": 3005,
        "province_code": 30
    },
    {
        "district": "บ้านเหลื่อม",
        "amphoe": "บ้านเหลื่อม",
        "province": "นครราชสีมา",
        "zipcode": 30350,
        "district_code": 300501,
        "amphoe_code": 3005,
        "province_code": 30
    },
    {
        "district": "วังโพธิ์",
        "amphoe": "บ้านเหลื่อม",
        "province": "นครราชสีมา",
        "zipcode": 30350,
        "district_code": 300502,
        "amphoe_code": 3005,
        "province_code": 30
    },
    {
        "district": "โคกกระเบื้อง",
        "amphoe": "บ้านเหลื่อม",
        "province": "นครราชสีมา",
        "zipcode": 30350,
        "district_code": 300503,
        "amphoe_code": 3005,
        "province_code": 30
    },
    {
        "district": "กระทุ่มราย",
        "amphoe": "ประทาย",
        "province": "นครราชสีมา",
        "zipcode": 30180,
        "district_code": 301303,
        "amphoe_code": 3013,
        "province_code": 30
    },
    {
        "district": "ดอนมัน",
        "amphoe": "ประทาย",
        "province": "นครราชสีมา",
        "zipcode": 30180,
        "district_code": 301310,
        "amphoe_code": 3013,
        "province_code": 30
    },
    {
        "district": "ตลาดไทร",
        "amphoe": "ประทาย",
        "province": "นครราชสีมา",
        "zipcode": 30180,
        "district_code": 301306,
        "amphoe_code": 3013,
        "province_code": 30
    },
    {
        "district": "ทุ่งสว่าง",
        "amphoe": "ประทาย",
        "province": "นครราชสีมา",
        "zipcode": 30180,
        "district_code": 301315,
        "amphoe_code": 3013,
        "province_code": 30
    },
    {
        "district": "นางรำ",
        "amphoe": "ประทาย",
        "province": "นครราชสีมา",
        "zipcode": 30180,
        "district_code": 301313,
        "amphoe_code": 3013,
        "province_code": 30
    },
    {
        "district": "ประทาย",
        "amphoe": "ประทาย",
        "province": "นครราชสีมา",
        "zipcode": 30180,
        "district_code": 301301,
        "amphoe_code": 3013,
        "province_code": 30
    },
    {
        "district": "วังไม้แดง",
        "amphoe": "ประทาย",
        "province": "นครราชสีมา",
        "zipcode": 30180,
        "district_code": 301304,
        "amphoe_code": 3013,
        "province_code": 30
    },
    {
        "district": "หนองค่าย",
        "amphoe": "ประทาย",
        "province": "นครราชสีมา",
        "zipcode": 30180,
        "district_code": 301308,
        "amphoe_code": 3013,
        "province_code": 30
    },
    {
        "district": "หนองพลวง",
        "amphoe": "ประทาย",
        "province": "นครราชสีมา",
        "zipcode": 30180,
        "district_code": 300607,
        "amphoe_code": 3013,
        "province_code": 30
    },
    {
        "district": "หันห้วยทราย",
        "amphoe": "ประทาย",
        "province": "นครราชสีมา",
        "zipcode": 30180,
        "district_code": 301309,
        "amphoe_code": 3013,
        "province_code": 30
    },
    {
        "district": "เมืองโดน",
        "amphoe": "ประทาย",
        "province": "นครราชสีมา",
        "zipcode": 30180,
        "district_code": 301318,
        "amphoe_code": 3013,
        "province_code": 30
    },
    {
        "district": "โคกกลาง",
        "amphoe": "ประทาย",
        "province": "นครราชสีมา",
        "zipcode": 30180,
        "district_code": 301317,
        "amphoe_code": 3013,
        "province_code": 30
    },
    {
        "district": "โนนเพ็ด",
        "amphoe": "ประทาย",
        "province": "นครราชสีมา",
        "zipcode": 30180,
        "district_code": 301314,
        "amphoe_code": 3013,
        "province_code": 30
    },
    {
        "district": "งิ้ว",
        "amphoe": "ปักธงชัย",
        "province": "นครราชสีมา",
        "zipcode": 30150,
        "district_code": 301410,
        "amphoe_code": 3014,
        "province_code": 30
    },
    {
        "district": "ดอน",
        "amphoe": "ปักธงชัย",
        "province": "นครราชสีมา",
        "zipcode": 30150,
        "district_code": 301407,
        "amphoe_code": 3014,
        "province_code": 30
    },
    {
        "district": "ตะขบ",
        "amphoe": "ปักธงชัย",
        "province": "นครราชสีมา",
        "zipcode": 30150,
        "district_code": 301405,
        "amphoe_code": 3014,
        "province_code": 30
    },
    {
        "district": "ตะคุ",
        "amphoe": "ปักธงชัย",
        "province": "นครราชสีมา",
        "zipcode": 30150,
        "district_code": 301402,
        "amphoe_code": 3014,
        "province_code": 30
    },
    {
        "district": "ตูม",
        "amphoe": "ปักธงชัย",
        "province": "นครราชสีมา",
        "zipcode": 30150,
        "district_code": 301409,
        "amphoe_code": 3014,
        "province_code": 30
    },
    {
        "district": "ธงชัยเหนือ",
        "amphoe": "ปักธงชัย",
        "province": "นครราชสีมา",
        "zipcode": 30150,
        "district_code": 301417,
        "amphoe_code": 3014,
        "province_code": 30
    },
    {
        "district": "นกออก",
        "amphoe": "ปักธงชัย",
        "province": "นครราชสีมา",
        "zipcode": 30150,
        "district_code": 301406,
        "amphoe_code": 3014,
        "province_code": 30
    },
    {
        "district": "บ่อปลาทอง",
        "amphoe": "ปักธงชัย",
        "province": "นครราชสีมา",
        "zipcode": 30150,
        "district_code": 301420,
        "amphoe_code": 3014,
        "province_code": 30
    },
    {
        "district": "ภูหลวง",
        "amphoe": "ปักธงชัย",
        "province": "นครราชสีมา",
        "zipcode": 30150,
        "district_code": 301416,
        "amphoe_code": 3014,
        "province_code": 30
    },
    {
        "district": "ลำนางแก้ว",
        "amphoe": "ปักธงชัย",
        "province": "นครราชสีมา",
        "zipcode": 30150,
        "district_code": 301412,
        "amphoe_code": 3014,
        "province_code": 30
    },
    {
        "district": "สะแกราช",
        "amphoe": "ปักธงชัย",
        "province": "นครราชสีมา",
        "zipcode": 30150,
        "district_code": 301411,
        "amphoe_code": 3014,
        "province_code": 30
    },
    {
        "district": "สำโรง",
        "amphoe": "ปักธงชัย",
        "province": "นครราชสีมา",
        "zipcode": 30150,
        "district_code": 300904,
        "amphoe_code": 3014,
        "province_code": 30
    },
    {
        "district": "สุขเกษม",
        "amphoe": "ปักธงชัย",
        "province": "นครราชสีมา",
        "zipcode": 30150,
        "district_code": 301418,
        "amphoe_code": 3014,
        "province_code": 30
    },
    {
        "district": "เกษมทรัพย์",
        "amphoe": "ปักธงชัย",
        "province": "นครราชสีมา",
        "zipcode": 30150,
        "district_code": 301419,
        "amphoe_code": 3014,
        "province_code": 30
    },
    {
        "district": "เมืองปัก",
        "amphoe": "ปักธงชัย",
        "province": "นครราชสีมา",
        "zipcode": 30150,
        "district_code": 301401,
        "amphoe_code": 3014,
        "province_code": 30
    },
    {
        "district": "โคกไทย",
        "amphoe": "ปักธงชัย",
        "province": "นครราชสีมา",
        "zipcode": 30150,
        "district_code": 301403,
        "amphoe_code": 3014,
        "province_code": 30
    },
    {
        "district": "กลางดง",
        "amphoe": "ปากช่อง",
        "province": "นครราชสีมา",
        "zipcode": 30320,
        "district_code": 302102,
        "amphoe_code": 3021,
        "province_code": 30
    },
    {
        "district": "ขนงพระ",
        "amphoe": "ปากช่อง",
        "province": "นครราชสีมา",
        "zipcode": 30130,
        "district_code": 302107,
        "amphoe_code": 3021,
        "province_code": 30
    },
    {
        "district": "คลองม่วง",
        "amphoe": "ปากช่อง",
        "province": "นครราชสีมา",
        "zipcode": 30130,
        "district_code": 302109,
        "amphoe_code": 3021,
        "province_code": 30
    },
    {
        "district": "จันทึก",
        "amphoe": "ปากช่อง",
        "province": "นครราชสีมา",
        "zipcode": 30130,
        "district_code": 302103,
        "amphoe_code": 3021,
        "province_code": 30
    },
    {
        "district": "ปากช่อง",
        "amphoe": "ปากช่อง",
        "province": "นครราชสีมา",
        "zipcode": 30130,
        "district_code": 302101,
        "amphoe_code": 3021,
        "province_code": 30
    },
    {
        "district": "พญาเย็น",
        "amphoe": "ปากช่อง",
        "province": "นครราชสีมา",
        "zipcode": 30320,
        "district_code": 302112,
        "amphoe_code": 3021,
        "province_code": 30
    },
    {
        "district": "วังกะทะ",
        "amphoe": "ปากช่อง",
        "province": "นครราชสีมา",
        "zipcode": 30130,
        "district_code": 302104,
        "amphoe_code": 3021,
        "province_code": 30
    },
    {
        "district": "วังไทร",
        "amphoe": "ปากช่อง",
        "province": "นครราชสีมา",
        "zipcode": 30130,
        "district_code": 302111,
        "amphoe_code": 3021,
        "province_code": 30
    },
    {
        "district": "หนองน้ำแดง",
        "amphoe": "ปากช่อง",
        "province": "นครราชสีมา",
        "zipcode": 30130,
        "district_code": 302110,
        "amphoe_code": 3021,
        "province_code": 30
    },
    {
        "district": "หนองสาหร่าย",
        "amphoe": "ปากช่อง",
        "province": "นครราชสีมา",
        "zipcode": 30130,
        "district_code": 302106,
        "amphoe_code": 3021,
        "province_code": 30
    },
    {
        "district": "หมูสี",
        "amphoe": "ปากช่อง",
        "province": "นครราชสีมา",
        "zipcode": 30130,
        "district_code": 302105,
        "amphoe_code": 3021,
        "province_code": 30
    },
    {
        "district": "โป่งตาลอง",
        "amphoe": "ปากช่อง",
        "province": "นครราชสีมา",
        "zipcode": 30130,
        "district_code": 302108,
        "amphoe_code": 3021,
        "province_code": 30
    },
    {
        "district": "ทัพรั้ง",
        "amphoe": "พระทองคำ",
        "province": "นครราชสีมา",
        "zipcode": 30220,
        "district_code": 300912,
        "amphoe_code": 3028,
        "province_code": 30
    },
    {
        "district": "พังเทียม",
        "amphoe": "พระทองคำ",
        "province": "นครราชสีมา",
        "zipcode": 30220,
        "district_code": 300910,
        "amphoe_code": 3028,
        "province_code": 30
    },
    {
        "district": "มาบกราด",
        "amphoe": "พระทองคำ",
        "province": "นครราชสีมา",
        "zipcode": 30220,
        "district_code": 300915,
        "amphoe_code": 3028,
        "province_code": 30
    },
    {
        "district": "สระพระ",
        "amphoe": "พระทองคำ",
        "province": "นครราชสีมา",
        "zipcode": 30220,
        "district_code": 300911,
        "amphoe_code": 3028,
        "province_code": 30
    },
    {
        "district": "หนองหอย",
        "amphoe": "พระทองคำ",
        "province": "นครราชสีมา",
        "zipcode": 30220,
        "district_code": 300913,
        "amphoe_code": 3028,
        "province_code": 30
    },
    {
        "district": "กระชอน",
        "amphoe": "พิมาย",
        "province": "นครราชสีมา",
        "zipcode": 30110,
        "district_code": 301509,
        "amphoe_code": 3015,
        "province_code": 30
    },
    {
        "district": "กระเบื้องใหญ่",
        "amphoe": "พิมาย",
        "province": "นครราชสีมา",
        "zipcode": 30110,
        "district_code": 301504,
        "amphoe_code": 3015,
        "province_code": 30
    },
    {
        "district": "ชีวาน",
        "amphoe": "พิมาย",
        "province": "นครราชสีมา",
        "zipcode": 30110,
        "district_code": 301507,
        "amphoe_code": 3015,
        "province_code": 30
    },
    {
        "district": "ดงใหญ่",
        "amphoe": "พิมาย",
        "province": "นครราชสีมา",
        "zipcode": 30110,
        "district_code": 301510,
        "amphoe_code": 3015,
        "province_code": 30
    },
    {
        "district": "ท่าหลวง",
        "amphoe": "พิมาย",
        "province": "นครราชสีมา",
        "zipcode": 30110,
        "district_code": 301505,
        "amphoe_code": 3015,
        "province_code": 30
    },
    {
        "district": "ธารละหลอด",
        "amphoe": "พิมาย",
        "province": "นครราชสีมา",
        "zipcode": 30110,
        "district_code": 301511,
        "amphoe_code": 3015,
        "province_code": 30
    },
    {
        "district": "นิคมสร้างตนเอง",
        "amphoe": "พิมาย",
        "province": "นครราชสีมา",
        "zipcode": 30110,
        "district_code": 301508,
        "amphoe_code": 3015,
        "province_code": 30
    },
    {
        "district": "รังกาใหญ่",
        "amphoe": "พิมาย",
        "province": "นครราชสีมา",
        "zipcode": 30110,
        "district_code": 301506,
        "amphoe_code": 3015,
        "province_code": 30
    },
    {
        "district": "สัมฤทธิ์",
        "amphoe": "พิมาย",
        "province": "นครราชสีมา",
        "zipcode": 30110,
        "district_code": 301502,
        "amphoe_code": 3015,
        "province_code": 30
    },
    {
        "district": "หนองระเวียง",
        "amphoe": "พิมาย",
        "province": "นครราชสีมา",
        "zipcode": 30110,
        "district_code": 300106,
        "amphoe_code": 3015,
        "province_code": 30
    },
    {
        "district": "โบสถ์",
        "amphoe": "พิมาย",
        "province": "นครราชสีมา",
        "zipcode": 30110,
        "district_code": 301503,
        "amphoe_code": 3015,
        "province_code": 30
    },
    {
        "district": "ในเมือง",
        "amphoe": "พิมาย",
        "province": "นครราชสีมา",
        "zipcode": 30110,
        "district_code": 300101,
        "amphoe_code": 3015,
        "province_code": 30
    },
    {
        "district": "ขุย",
        "amphoe": "ลำทะเมนชัย",
        "province": "นครราชสีมา",
        "zipcode": 30270,
        "district_code": 301707,
        "amphoe_code": 3029,
        "province_code": 30
    },
    {
        "district": "ช่องแมว",
        "amphoe": "ลำทะเมนชัย",
        "province": "นครราชสีมา",
        "zipcode": 30270,
        "district_code": 301706,
        "amphoe_code": 3029,
        "province_code": 30
    },
    {
        "district": "บ้านยาง",
        "amphoe": "ลำทะเมนชัย",
        "province": "นครราชสีมา",
        "zipcode": 30270,
        "district_code": 301711,
        "amphoe_code": 3029,
        "province_code": 30
    },
    {
        "district": "ไพล",
        "amphoe": "ลำทะเมนชัย",
        "province": "นครราชสีมา",
        "zipcode": 30270,
        "district_code": 301715,
        "amphoe_code": 3029,
        "province_code": 30
    },
    {
        "district": "ระเริง",
        "amphoe": "วังน้ำเขียว",
        "province": "นครราชสีมา",
        "zipcode": 30150,
        "district_code": 301414,
        "amphoe_code": 3025,
        "province_code": 30
    },
    {
        "district": "วังน้ำเขียว",
        "amphoe": "วังน้ำเขียว",
        "province": "นครราชสีมา",
        "zipcode": 30370,
        "district_code": 301408,
        "amphoe_code": 3025,
        "province_code": 30
    },
    {
        "district": "วังหมี",
        "amphoe": "วังน้ำเขียว",
        "province": "นครราชสีมา",
        "zipcode": 30370,
        "district_code": 301413,
        "amphoe_code": 3025,
        "province_code": 30
    },
    {
        "district": "อุดมทรัพย์",
        "amphoe": "วังน้ำเขียว",
        "province": "นครราชสีมา",
        "zipcode": 30370,
        "district_code": 301415,
        "amphoe_code": 3025,
        "province_code": 30
    },
    {
        "district": "ไทยสามัคคี",
        "amphoe": "วังน้ำเขียว",
        "province": "นครราชสีมา",
        "zipcode": 30370,
        "district_code": 302505,
        "amphoe_code": 3025,
        "province_code": 30
    },
    {
        "district": "กฤษณา",
        "amphoe": "สีคิ้ว",
        "province": "นครราชสีมา",
        "zipcode": 30140,
        "district_code": 302003,
        "amphoe_code": 3020,
        "province_code": 30
    },
    {
        "district": "กุดน้อย",
        "amphoe": "สีคิ้ว",
        "province": "นครราชสีมา",
        "zipcode": 30140,
        "district_code": 302006,
        "amphoe_code": 3020,
        "province_code": 30
    },
    {
        "district": "คลองไผ่",
        "amphoe": "สีคิ้ว",
        "province": "นครราชสีมา",
        "zipcode": 30340,
        "district_code": 302010,
        "amphoe_code": 3020,
        "province_code": 30
    },
    {
        "district": "ดอนเมือง",
        "amphoe": "สีคิ้ว",
        "province": "นครราชสีมา",
        "zipcode": 30140,
        "district_code": 302011,
        "amphoe_code": 3020,
        "province_code": 30
    },
    {
        "district": "บ้านหัน",
        "amphoe": "สีคิ้ว",
        "province": "นครราชสีมา",
        "zipcode": 30140,
        "district_code": 302002,
        "amphoe_code": 3020,
        "province_code": 30
    },
    {
        "district": "มิตรภาพ",
        "amphoe": "สีคิ้ว",
        "province": "นครราชสีมา",
        "zipcode": 30140,
        "district_code": 302009,
        "amphoe_code": 3020,
        "province_code": 30
    },
    {
        "district": "ลาดบัวขาว",
        "amphoe": "สีคิ้ว",
        "province": "นครราชสีมา",
        "zipcode": 30340,
        "district_code": 302004,
        "amphoe_code": 3020,
        "province_code": 30
    },
    {
        "district": "วังโรงใหญ่",
        "amphoe": "สีคิ้ว",
        "province": "นครราชสีมา",
        "zipcode": 30140,
        "district_code": 302008,
        "amphoe_code": 3020,
        "province_code": 30
    },
    {
        "district": "สีคิ้ว",
        "amphoe": "สีคิ้ว",
        "province": "นครราชสีมา",
        "zipcode": 30140,
        "district_code": 302001,
        "amphoe_code": 3020,
        "province_code": 30
    },
    {
        "district": "หนองน้ำใส",
        "amphoe": "สีคิ้ว",
        "province": "นครราชสีมา",
        "zipcode": 30140,
        "district_code": 302007,
        "amphoe_code": 3020,
        "province_code": 30
    },
    {
        "district": "หนองบัวน้อย",
        "amphoe": "สีคิ้ว",
        "province": "นครราชสีมา",
        "zipcode": 30140,
        "district_code": 302012,
        "amphoe_code": 3020,
        "province_code": 30
    },
    {
        "district": "หนองหญ้าขาว",
        "amphoe": "สีคิ้ว",
        "province": "นครราชสีมา",
        "zipcode": 30140,
        "district_code": 302005,
        "amphoe_code": 3020,
        "province_code": 30
    },
    {
        "district": "สามเมือง",
        "amphoe": "สีดา",
        "province": "นครราชสีมา",
        "zipcode": 30430,
        "district_code": 301218,
        "amphoe_code": 3031,
        "province_code": 30
    },
    {
        "district": "สีดา",
        "amphoe": "สีดา",
        "province": "นครราชสีมา",
        "zipcode": 30430,
        "district_code": 301211,
        "amphoe_code": 3031,
        "province_code": 30
    },
    {
        "district": "หนองตาดใหญ่",
        "amphoe": "สีดา",
        "province": "นครราชสีมา",
        "zipcode": 30430,
        "district_code": 301221,
        "amphoe_code": 3031,
        "province_code": 30
    },
    {
        "district": "โนนประดู่",
        "amphoe": "สีดา",
        "province": "นครราชสีมา",
        "zipcode": 30430,
        "district_code": 301223,
        "amphoe_code": 3031,
        "province_code": 30
    },
    {
        "district": "โพนทอง",
        "amphoe": "สีดา",
        "province": "นครราชสีมา",
        "zipcode": 30430,
        "district_code": 301212,
        "amphoe_code": 3031,
        "province_code": 30
    },
    {
        "district": "กุดจิก",
        "amphoe": "สูงเนิน",
        "province": "นครราชสีมา",
        "zipcode": 30380,
        "district_code": 301811,
        "amphoe_code": 3018,
        "province_code": 30
    },
    {
        "district": "นากลาง",
        "amphoe": "สูงเนิน",
        "province": "นครราชสีมา",
        "zipcode": 30380,
        "district_code": 301809,
        "amphoe_code": 3018,
        "province_code": 30
    },
    {
        "district": "บุ่งขี้เหล็ก",
        "amphoe": "สูงเนิน",
        "province": "นครราชสีมา",
        "zipcode": 30170,
        "district_code": 301804,
        "amphoe_code": 3018,
        "province_code": 30
    },
    {
        "district": "มะเกลือเก่า",
        "amphoe": "สูงเนิน",
        "province": "นครราชสีมา",
        "zipcode": 30170,
        "district_code": 301807,
        "amphoe_code": 3018,
        "province_code": 30
    },
    {
        "district": "มะเกลือใหม่",
        "amphoe": "สูงเนิน",
        "province": "นครราชสีมา",
        "zipcode": 30170,
        "district_code": 301808,
        "amphoe_code": 3018,
        "province_code": 30
    },
    {
        "district": "สูงเนิน",
        "amphoe": "สูงเนิน",
        "province": "นครราชสีมา",
        "zipcode": 30170,
        "district_code": 301801,
        "amphoe_code": 3018,
        "province_code": 30
    },
    {
        "district": "หนองตะไก้",
        "amphoe": "สูงเนิน",
        "province": "นครราชสีมา",
        "zipcode": 30380,
        "district_code": 301810,
        "amphoe_code": 3018,
        "province_code": 30
    },
    {
        "district": "เสมา",
        "amphoe": "สูงเนิน",
        "province": "นครราชสีมา",
        "zipcode": 30170,
        "district_code": 301802,
        "amphoe_code": 3018,
        "province_code": 30
    },
    {
        "district": "โคราช",
        "amphoe": "สูงเนิน",
        "province": "นครราชสีมา",
        "zipcode": 30170,
        "district_code": 301803,
        "amphoe_code": 3018,
        "province_code": 30
    },
    {
        "district": "โค้งยาง",
        "amphoe": "สูงเนิน",
        "province": "นครราชสีมา",
        "zipcode": 30170,
        "district_code": 301806,
        "amphoe_code": 3018,
        "province_code": 30
    },
    {
        "district": "โนนค่า",
        "amphoe": "สูงเนิน",
        "province": "นครราชสีมา",
        "zipcode": 30170,
        "district_code": 301805,
        "amphoe_code": 3018,
        "province_code": 30
    },
    {
        "district": "บ้านใหม่",
        "amphoe": "หนองบุญมาก",
        "province": "นครราชสีมา",
        "zipcode": 30410,
        "district_code": 300113,
        "amphoe_code": 3022,
        "province_code": 30
    },
    {
        "district": "ลุงเขว้า",
        "amphoe": "หนองบุญมาก",
        "province": "นครราชสีมา",
        "zipcode": 30410,
        "district_code": 302207,
        "amphoe_code": 3022,
        "province_code": 30
    },
    {
        "district": "สารภี",
        "amphoe": "หนองบุญมาก",
        "province": "นครราชสีมา",
        "zipcode": 30410,
        "district_code": 302202,
        "amphoe_code": 3022,
        "province_code": 30
    },
    {
        "district": "หนองตะไก้",
        "amphoe": "หนองบุญมาก",
        "province": "นครราชสีมา",
        "zipcode": 30410,
        "district_code": 301810,
        "amphoe_code": 3022,
        "province_code": 30
    },
    {
        "district": "หนองบุนนาก",
        "amphoe": "หนองบุญมาก",
        "province": "นครราชสีมา",
        "zipcode": 30410,
        "district_code": 302201,
        "amphoe_code": 3022,
        "province_code": 30
    },
    {
        "district": "หนองหัวแรต",
        "amphoe": "หนองบุญมาก",
        "province": "นครราชสีมา",
        "zipcode": 30410,
        "district_code": 302204,
        "amphoe_code": 3022,
        "province_code": 30
    },
    {
        "district": "หนองไม้ไผ่",
        "amphoe": "หนองบุญมาก",
        "province": "นครราชสีมา",
        "zipcode": 30410,
        "district_code": 302208,
        "amphoe_code": 3022,
        "province_code": 30
    },
    {
        "district": "แหลมทอง",
        "amphoe": "หนองบุญมาก",
        "province": "นครราชสีมา",
        "zipcode": 30410,
        "district_code": 302205,
        "amphoe_code": 3022,
        "province_code": 30
    },
    {
        "district": "ไทยเจริญ",
        "amphoe": "หนองบุญมาก",
        "province": "นครราชสีมา",
        "zipcode": 30410,
        "district_code": 302203,
        "amphoe_code": 3022,
        "province_code": 30
    },
    {
        "district": "กงรถ",
        "amphoe": "ห้วยแถลง",
        "province": "นครราชสีมา",
        "zipcode": 30240,
        "district_code": 301607,
        "amphoe_code": 3016,
        "province_code": 30
    },
    {
        "district": "งิ้ว",
        "amphoe": "ห้วยแถลง",
        "province": "นครราชสีมา",
        "zipcode": 30240,
        "district_code": 301410,
        "amphoe_code": 3016,
        "province_code": 30
    },
    {
        "district": "ตะโก",
        "amphoe": "ห้วยแถลง",
        "province": "นครราชสีมา",
        "zipcode": 30240,
        "district_code": 301609,
        "amphoe_code": 3016,
        "province_code": 30
    },
    {
        "district": "ทับสวาย",
        "amphoe": "ห้วยแถลง",
        "province": "นครราชสีมา",
        "zipcode": 30240,
        "district_code": 301602,
        "amphoe_code": 3016,
        "province_code": 30
    },
    {
        "district": "หลุ่งตะเคียน",
        "amphoe": "ห้วยแถลง",
        "province": "นครราชสีมา",
        "zipcode": 30240,
        "district_code": 301604,
        "amphoe_code": 3016,
        "province_code": 30
    },
    {
        "district": "หลุ่งประดู่",
        "amphoe": "ห้วยแถลง",
        "province": "นครราชสีมา",
        "zipcode": 30240,
        "district_code": 301608,
        "amphoe_code": 3016,
        "province_code": 30
    },
    {
        "district": "หินดาด",
        "amphoe": "ห้วยแถลง",
        "province": "นครราชสีมา",
        "zipcode": 30240,
        "district_code": 300813,
        "amphoe_code": 3016,
        "province_code": 30
    },
    {
        "district": "ห้วยแคน",
        "amphoe": "ห้วยแถลง",
        "province": "นครราชสีมา",
        "zipcode": 30240,
        "district_code": 301610,
        "amphoe_code": 3016,
        "province_code": 30
    },
    {
        "district": "ห้วยแถลง",
        "amphoe": "ห้วยแถลง",
        "province": "นครราชสีมา",
        "zipcode": 30240,
        "district_code": 301601,
        "amphoe_code": 3016,
        "province_code": 30
    },
    {
        "district": "เมืองพลับพลา",
        "amphoe": "ห้วยแถลง",
        "province": "นครราชสีมา",
        "zipcode": 30240,
        "district_code": 301603,
        "amphoe_code": 3016,
        "province_code": 30
    },
    {
        "district": "ช้างทอง",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "นครราชสีมา",
        "zipcode": 30230,
        "district_code": 300612,
        "amphoe_code": 3032,
        "province_code": 30
    },
    {
        "district": "ท่าช้าง",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "นครราชสีมา",
        "zipcode": 30230,
        "district_code": 300602,
        "amphoe_code": 3032,
        "province_code": 30
    },
    {
        "district": "พระพุทธ",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "นครราชสีมา",
        "zipcode": 30230,
        "district_code": 300609,
        "amphoe_code": 3032,
        "province_code": 30
    },
    {
        "district": "หนองงูเหลือม",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300606,
        "amphoe_code": 3032,
        "province_code": 30
    },
    {
        "district": "หนองยาง",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "นครราชสีมา",
        "zipcode": 30230,
        "district_code": 300608,
        "amphoe_code": 3032,
        "province_code": 30
    },
    {
        "district": "บึงปรือ",
        "amphoe": "เทพารักษ์",
        "province": "นครราชสีมา",
        "zipcode": 30210,
        "district_code": 300819,
        "amphoe_code": 3026,
        "province_code": 30
    },
    {
        "district": "วังยายทอง",
        "amphoe": "เทพารักษ์",
        "province": "นครราชสีมา",
        "zipcode": 30210,
        "district_code": 302604,
        "amphoe_code": 3026,
        "province_code": 30
    },
    {
        "district": "สำนักตะคร้อ",
        "amphoe": "เทพารักษ์",
        "province": "นครราชสีมา",
        "zipcode": 30210,
        "district_code": 300814,
        "amphoe_code": 3026,
        "province_code": 30
    },
    {
        "district": "หนองแวง",
        "amphoe": "เทพารักษ์",
        "province": "นครราชสีมา",
        "zipcode": 30210,
        "district_code": 300816,
        "amphoe_code": 3026,
        "province_code": 30
    },
    {
        "district": "จอหอ",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30310,
        "district_code": 300116,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "ตลาด",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30310,
        "district_code": 300122,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "บ้านเกาะ",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300112,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "บ้านโพธิ์",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30310,
        "district_code": 300115,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "บ้านใหม่",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300113,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "ปรุใหญ่",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300107,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "พลกรัง",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300109,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "พะเนา",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300123,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "พุดซา",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300114,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "มะเริง",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300105,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "สีมุม",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300121,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "สุรนารี",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300120,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "หนองกระทุ่ม",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300124,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "หนองจะบก",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300103,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "หนองบัวศาลา",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300119,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "หนองระเวียง",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300106,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "หนองไข่น้ำ",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30310,
        "district_code": 300125,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "หนองไผ่ล้อม",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300110,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "หมื่นไวย",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300108,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "หัวทะเล",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300111,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "โคกกรวด",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30280,
        "district_code": 300117,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "โคกสูง",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30310,
        "district_code": 300104,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "โพธิ์กลาง",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300102,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "ในเมือง",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300101,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "ไชยมงคล",
        "amphoe": "เมืองนครราชสีมา",
        "province": "นครราชสีมา",
        "zipcode": 30000,
        "district_code": 300118,
        "amphoe_code": 3001,
        "province_code": 30
    },
    {
        "district": "กระเบื้องนอก",
        "amphoe": "เมืองยาง",
        "province": "นครราชสีมา",
        "zipcode": 30270,
        "district_code": 301708,
        "amphoe_code": 3027,
        "province_code": 30
    },
    {
        "district": "ละหานปลาค้าว",
        "amphoe": "เมืองยาง",
        "province": "นครราชสีมา",
        "zipcode": 30270,
        "district_code": 301712,
        "amphoe_code": 3027,
        "province_code": 30
    },
    {
        "district": "เมืองยาง",
        "amphoe": "เมืองยาง",
        "province": "นครราชสีมา",
        "zipcode": 30270,
        "district_code": 301709,
        "amphoe_code": 3027,
        "province_code": 30
    },
    {
        "district": "โนนอุดม",
        "amphoe": "เมืองยาง",
        "province": "นครราชสีมา",
        "zipcode": 30270,
        "district_code": 301713,
        "amphoe_code": 3027,
        "province_code": 30
    },
    {
        "district": "กุดโบสถ์",
        "amphoe": "เสิงสาง",
        "province": "นครราชสีมา",
        "zipcode": 30330,
        "district_code": 300304,
        "amphoe_code": 3003,
        "province_code": 30
    },
    {
        "district": "บ้านราษฎร์",
        "amphoe": "เสิงสาง",
        "province": "นครราชสีมา",
        "zipcode": 30330,
        "district_code": 300306,
        "amphoe_code": 3003,
        "province_code": 30
    },
    {
        "district": "สระตะเคียน",
        "amphoe": "เสิงสาง",
        "province": "นครราชสีมา",
        "zipcode": 30330,
        "district_code": 300302,
        "amphoe_code": 3003,
        "province_code": 30
    },
    {
        "district": "สุขไพบูลย์",
        "amphoe": "เสิงสาง",
        "province": "นครราชสีมา",
        "zipcode": 30330,
        "district_code": 300305,
        "amphoe_code": 3003,
        "province_code": 30
    },
    {
        "district": "เสิงสาง",
        "amphoe": "เสิงสาง",
        "province": "นครราชสีมา",
        "zipcode": 30330,
        "district_code": 300301,
        "amphoe_code": 3003,
        "province_code": 30
    },
    {
        "district": "โนนสมบูรณ์",
        "amphoe": "เสิงสาง",
        "province": "นครราชสีมา",
        "zipcode": 30330,
        "district_code": 300303,
        "amphoe_code": 3003,
        "province_code": 30
    },
    {
        "district": "บึงพะไล",
        "amphoe": "แก้งสนามนาง",
        "province": "นครราชสีมา",
        "zipcode": 30440,
        "district_code": 301205,
        "amphoe_code": 3023,
        "province_code": 30
    },
    {
        "district": "บึงสำโรง",
        "amphoe": "แก้งสนามนาง",
        "province": "นครราชสีมา",
        "zipcode": 30440,
        "district_code": 302305,
        "amphoe_code": 3023,
        "province_code": 30
    },
    {
        "district": "สีสุก",
        "amphoe": "แก้งสนามนาง",
        "province": "นครราชสีมา",
        "zipcode": 30440,
        "district_code": 300604,
        "amphoe_code": 3023,
        "province_code": 30
    },
    {
        "district": "แก้งสนามนาง",
        "amphoe": "แก้งสนามนาง",
        "province": "นครราชสีมา",
        "zipcode": 30440,
        "district_code": 301213,
        "amphoe_code": 3023,
        "province_code": 30
    },
    {
        "district": "โนนสำราญ",
        "amphoe": "แก้งสนามนาง",
        "province": "นครราชสีมา",
        "zipcode": 30440,
        "district_code": 301219,
        "amphoe_code": 3023,
        "province_code": 30
    },
    {
        "district": "กระโทก",
        "amphoe": "โชคชัย",
        "province": "นครราชสีมา",
        "zipcode": 30190,
        "district_code": 300701,
        "amphoe_code": 3007,
        "province_code": 30
    },
    {
        "district": "ด่านเกวียน",
        "amphoe": "โชคชัย",
        "province": "นครราชสีมา",
        "zipcode": 30190,
        "district_code": 300710,
        "amphoe_code": 3007,
        "province_code": 30
    },
    {
        "district": "ทุ่งอรุณ",
        "amphoe": "โชคชัย",
        "province": "นครราชสีมา",
        "zipcode": 30190,
        "district_code": 300704,
        "amphoe_code": 3007,
        "province_code": 30
    },
    {
        "district": "ท่าจะหลุง",
        "amphoe": "โชคชัย",
        "province": "นครราชสีมา",
        "zipcode": 30190,
        "district_code": 300706,
        "amphoe_code": 3007,
        "province_code": 30
    },
    {
        "district": "ท่าลาดขาว",
        "amphoe": "โชคชัย",
        "province": "นครราชสีมา",
        "zipcode": 30190,
        "district_code": 300705,
        "amphoe_code": 3007,
        "province_code": 30
    },
    {
        "district": "ท่าอ่าง",
        "amphoe": "โชคชัย",
        "province": "นครราชสีมา",
        "zipcode": 30190,
        "district_code": 300703,
        "amphoe_code": 3007,
        "province_code": 30
    },
    {
        "district": "ท่าเยี่ยม",
        "amphoe": "โชคชัย",
        "province": "นครราชสีมา",
        "zipcode": 30190,
        "district_code": 300707,
        "amphoe_code": 3007,
        "province_code": 30
    },
    {
        "district": "พลับพลา",
        "amphoe": "โชคชัย",
        "province": "นครราชสีมา",
        "zipcode": 30190,
        "district_code": 300702,
        "amphoe_code": 3007,
        "province_code": 30
    },
    {
        "district": "ละลมใหม่พัฒนา",
        "amphoe": "โชคชัย",
        "province": "นครราชสีมา",
        "zipcode": 30190,
        "district_code": 300709,
        "amphoe_code": 3007,
        "province_code": 30
    },
    {
        "district": "โชคชัย",
        "amphoe": "โชคชัย",
        "province": "นครราชสีมา",
        "zipcode": 30190,
        "district_code": 300708,
        "amphoe_code": 3007,
        "province_code": 30
    },
    {
        "district": "ขามเฒ่า",
        "amphoe": "โนนสูง",
        "province": "นครราชสีมา",
        "zipcode": 30160,
        "district_code": 301011,
        "amphoe_code": 3010,
        "province_code": 30
    },
    {
        "district": "จันอัด",
        "amphoe": "โนนสูง",
        "province": "นครราชสีมา",
        "zipcode": 30160,
        "district_code": 301010,
        "amphoe_code": 3010,
        "province_code": 30
    },
    {
        "district": "ดอนชมพู",
        "amphoe": "โนนสูง",
        "province": "นครราชสีมา",
        "zipcode": 30160,
        "district_code": 301005,
        "amphoe_code": 3010,
        "province_code": 30
    },
    {
        "district": "ดอนหวาย",
        "amphoe": "โนนสูง",
        "province": "นครราชสีมา",
        "zipcode": 30160,
        "district_code": 301015,
        "amphoe_code": 3010,
        "province_code": 30
    },
    {
        "district": "ด่านคล้า",
        "amphoe": "โนนสูง",
        "province": "นครราชสีมา",
        "zipcode": 30160,
        "district_code": 301012,
        "amphoe_code": 3010,
        "province_code": 30
    },
    {
        "district": "ธารปราสาท",
        "amphoe": "โนนสูง",
        "province": "นครราชสีมา",
        "zipcode": 30420,
        "district_code": 301006,
        "amphoe_code": 3010,
        "province_code": 30
    },
    {
        "district": "บิง",
        "amphoe": "โนนสูง",
        "province": "นครราชสีมา",
        "zipcode": 30160,
        "district_code": 301004,
        "amphoe_code": 3010,
        "province_code": 30
    },
    {
        "district": "พลสงคราม",
        "amphoe": "โนนสูง",
        "province": "นครราชสีมา",
        "zipcode": 30160,
        "district_code": 301009,
        "amphoe_code": 3010,
        "province_code": 30
    },
    {
        "district": "มะค่า",
        "amphoe": "โนนสูง",
        "province": "นครราชสีมา",
        "zipcode": 30160,
        "district_code": 300914,
        "amphoe_code": 3010,
        "province_code": 30
    },
    {
        "district": "ลำคอหงษ์",
        "amphoe": "โนนสูง",
        "province": "นครราชสีมา",
        "zipcode": 30160,
        "district_code": 301013,
        "amphoe_code": 3010,
        "province_code": 30
    },
    {
        "district": "ลำมูล",
        "amphoe": "โนนสูง",
        "province": "นครราชสีมา",
        "zipcode": 30160,
        "district_code": 301016,
        "amphoe_code": 3010,
        "province_code": 30
    },
    {
        "district": "หลุมข้าว",
        "amphoe": "โนนสูง",
        "province": "นครราชสีมา",
        "zipcode": 30160,
        "district_code": 301007,
        "amphoe_code": 3010,
        "province_code": 30
    },
    {
        "district": "เมืองปราสาท",
        "amphoe": "โนนสูง",
        "province": "นครราชสีมา",
        "zipcode": 30160,
        "district_code": 301014,
        "amphoe_code": 3010,
        "province_code": 30
    },
    {
        "district": "โตนด",
        "amphoe": "โนนสูง",
        "province": "นครราชสีมา",
        "zipcode": 30160,
        "district_code": 301003,
        "amphoe_code": 3010,
        "province_code": 30
    },
    {
        "district": "โนนสูง",
        "amphoe": "โนนสูง",
        "province": "นครราชสีมา",
        "zipcode": 30160,
        "district_code": 301001,
        "amphoe_code": 3010,
        "province_code": 30
    },
    {
        "district": "ใหม่",
        "amphoe": "โนนสูง",
        "province": "นครราชสีมา",
        "zipcode": 30160,
        "district_code": 301002,
        "amphoe_code": 3010,
        "province_code": 30
    },
    {
        "district": "ดอนยาวใหญ่",
        "amphoe": "โนนแดง",
        "province": "นครราชสีมา",
        "zipcode": 30360,
        "district_code": 301316,
        "amphoe_code": 3024,
        "province_code": 30
    },
    {
        "district": "วังหิน",
        "amphoe": "โนนแดง",
        "province": "นครราชสีมา",
        "zipcode": 30360,
        "district_code": 301305,
        "amphoe_code": 3024,
        "province_code": 30
    },
    {
        "district": "สำพะเนียง",
        "amphoe": "โนนแดง",
        "province": "นครราชสีมา",
        "zipcode": 30360,
        "district_code": 301312,
        "amphoe_code": 3024,
        "province_code": 30
    },
    {
        "district": "โนนตาเถร",
        "amphoe": "โนนแดง",
        "province": "นครราชสีมา",
        "zipcode": 30360,
        "district_code": 301311,
        "amphoe_code": 3024,
        "province_code": 30
    },
    {
        "district": "โนนแดง",
        "amphoe": "โนนแดง",
        "province": "นครราชสีมา",
        "zipcode": 30360,
        "district_code": 301302,
        "amphoe_code": 3024,
        "province_code": 30
    },
    {
        "district": "กำปัง",
        "amphoe": "โนนไทย",
        "province": "นครราชสีมา",
        "zipcode": 30220,
        "district_code": 300903,
        "amphoe_code": 3009,
        "province_code": 30
    },
    {
        "district": "ค้างพลู",
        "amphoe": "โนนไทย",
        "province": "นครราชสีมา",
        "zipcode": 30220,
        "district_code": 300905,
        "amphoe_code": 3009,
        "province_code": 30
    },
    {
        "district": "ด่านจาก",
        "amphoe": "โนนไทย",
        "province": "นครราชสีมา",
        "zipcode": 30220,
        "district_code": 300902,
        "amphoe_code": 3009,
        "province_code": 30
    },
    {
        "district": "ถนนโพธิ์",
        "amphoe": "โนนไทย",
        "province": "นครราชสีมา",
        "zipcode": 30220,
        "district_code": 300909,
        "amphoe_code": 3009,
        "province_code": 30
    },
    {
        "district": "บัลลังก์",
        "amphoe": "โนนไทย",
        "province": "นครราชสีมา",
        "zipcode": 30220,
        "district_code": 300907,
        "amphoe_code": 3009,
        "province_code": 30
    },
    {
        "district": "บ้านวัง",
        "amphoe": "โนนไทย",
        "province": "นครราชสีมา",
        "zipcode": 30220,
        "district_code": 300906,
        "amphoe_code": 3009,
        "province_code": 30
    },
    {
        "district": "มะค่า",
        "amphoe": "โนนไทย",
        "province": "นครราชสีมา",
        "zipcode": 30220,
        "district_code": 300914,
        "amphoe_code": 3009,
        "province_code": 30
    },
    {
        "district": "สายออ",
        "amphoe": "โนนไทย",
        "province": "นครราชสีมา",
        "zipcode": 30220,
        "district_code": 300908,
        "amphoe_code": 3009,
        "province_code": 30
    },
    {
        "district": "สำโรง",
        "amphoe": "โนนไทย",
        "province": "นครราชสีมา",
        "zipcode": 30220,
        "district_code": 300904,
        "amphoe_code": 3009,
        "province_code": 30
    },
    {
        "district": "โนนไทย",
        "amphoe": "โนนไทย",
        "province": "นครราชสีมา",
        "zipcode": 30220,
        "district_code": 300901,
        "amphoe_code": 3009,
        "province_code": 30
    },
    {
        "district": "ขนอม",
        "amphoe": "ขนอม",
        "province": "นครศรีธรรมราช",
        "zipcode": 80210,
        "district_code": 801501,
        "amphoe_code": 8015,
        "province_code": 80
    },
    {
        "district": "ควนทอง",
        "amphoe": "ขนอม",
        "province": "นครศรีธรรมราช",
        "zipcode": 80210,
        "district_code": 801502,
        "amphoe_code": 8015,
        "province_code": 80
    },
    {
        "district": "ท้องเนียน",
        "amphoe": "ขนอม",
        "province": "นครศรีธรรมราช",
        "zipcode": 80210,
        "district_code": 801503,
        "amphoe_code": 8015,
        "province_code": 80
    },
    {
        "district": "ควนหนองคว้า",
        "amphoe": "จุฬาภรณ์",
        "province": "นครศรีธรรมราช",
        "zipcode": 80130,
        "district_code": 801311,
        "amphoe_code": 8019,
        "province_code": 80
    },
    {
        "district": "ทุ่งโพธิ์",
        "amphoe": "จุฬาภรณ์",
        "province": "นครศรีธรรมราช",
        "zipcode": 80130,
        "district_code": 801310,
        "amphoe_code": 8019,
        "province_code": 80
    },
    {
        "district": "นาหมอบุญ",
        "amphoe": "จุฬาภรณ์",
        "province": "นครศรีธรรมราช",
        "zipcode": 80130,
        "district_code": 801309,
        "amphoe_code": 8019,
        "province_code": 80
    },
    {
        "district": "บ้านควนมุด",
        "amphoe": "จุฬาภรณ์",
        "province": "นครศรีธรรมราช",
        "zipcode": 80180,
        "district_code": 800712,
        "amphoe_code": 8019,
        "province_code": 80
    },
    {
        "district": "บ้านชะอวด",
        "amphoe": "จุฬาภรณ์",
        "province": "นครศรีธรรมราช",
        "zipcode": 80180,
        "district_code": 800713,
        "amphoe_code": 8019,
        "province_code": 80
    },
    {
        "district": "สามตำบล",
        "amphoe": "จุฬาภรณ์",
        "province": "นครศรีธรรมราช",
        "zipcode": 80130,
        "district_code": 801307,
        "amphoe_code": 8019,
        "province_code": 80
    },
    {
        "district": "กะเปียด",
        "amphoe": "ฉวาง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80260,
        "district_code": 800406,
        "amphoe_code": 8004,
        "province_code": 80
    },
    {
        "district": "จันดี",
        "amphoe": "ฉวาง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80250,
        "district_code": 800416,
        "amphoe_code": 8004,
        "province_code": 80
    },
    {
        "district": "ฉวาง",
        "amphoe": "ฉวาง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80150,
        "district_code": 800401,
        "amphoe_code": 8004,
        "province_code": 80
    },
    {
        "district": "นากะชะ",
        "amphoe": "ฉวาง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80150,
        "district_code": 800407,
        "amphoe_code": 8004,
        "province_code": 80
    },
    {
        "district": "นาเขลียง",
        "amphoe": "ฉวาง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80260,
        "district_code": 800415,
        "amphoe_code": 8004,
        "province_code": 80
    },
    {
        "district": "นาแว",
        "amphoe": "ฉวาง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80260,
        "district_code": 800404,
        "amphoe_code": 8004,
        "province_code": 80
    },
    {
        "district": "ละอาย",
        "amphoe": "ฉวาง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80250,
        "district_code": 800403,
        "amphoe_code": 8004,
        "province_code": 80
    },
    {
        "district": "ห้วยปริก",
        "amphoe": "ฉวาง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80260,
        "district_code": 800409,
        "amphoe_code": 8004,
        "province_code": 80
    },
    {
        "district": "ไม้เรียง",
        "amphoe": "ฉวาง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80260,
        "district_code": 800405,
        "amphoe_code": 8004,
        "province_code": 80
    },
    {
        "district": "ไสหร้า",
        "amphoe": "ฉวาง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80150,
        "district_code": 800410,
        "amphoe_code": 8004,
        "province_code": 80
    },
    {
        "district": "ขอนหาด",
        "amphoe": "ชะอวด",
        "province": "นครศรีธรรมราช",
        "zipcode": 80180,
        "district_code": 800707,
        "amphoe_code": 8007,
        "province_code": 80
    },
    {
        "district": "ควนหนองหงษ์",
        "amphoe": "ชะอวด",
        "province": "นครศรีธรรมราช",
        "zipcode": 80180,
        "district_code": 800709,
        "amphoe_code": 8007,
        "province_code": 80
    },
    {
        "district": "ชะอวด",
        "amphoe": "ชะอวด",
        "province": "นครศรีธรรมราช",
        "zipcode": 80180,
        "district_code": 800701,
        "amphoe_code": 8007,
        "province_code": 80
    },
    {
        "district": "ท่าประจะ",
        "amphoe": "ชะอวด",
        "province": "นครศรีธรรมราช",
        "zipcode": 80180,
        "district_code": 800703,
        "amphoe_code": 8007,
        "province_code": 80
    },
    {
        "district": "ท่าเสม็ด",
        "amphoe": "ชะอวด",
        "province": "นครศรีธรรมราช",
        "zipcode": 80180,
        "district_code": 800702,
        "amphoe_code": 8007,
        "province_code": 80
    },
    {
        "district": "นางหลง",
        "amphoe": "ชะอวด",
        "province": "นครศรีธรรมราช",
        "zipcode": 80180,
        "district_code": 800711,
        "amphoe_code": 8007,
        "province_code": 80
    },
    {
        "district": "บ้านตูล",
        "amphoe": "ชะอวด",
        "province": "นครศรีธรรมราช",
        "zipcode": 80180,
        "district_code": 800706,
        "amphoe_code": 8007,
        "province_code": 80
    },
    {
        "district": "วังอ่าง",
        "amphoe": "ชะอวด",
        "province": "นครศรีธรรมราช",
        "zipcode": 80180,
        "district_code": 800705,
        "amphoe_code": 8007,
        "province_code": 80
    },
    {
        "district": "เกาะขันธ์",
        "amphoe": "ชะอวด",
        "province": "นครศรีธรรมราช",
        "zipcode": 80180,
        "district_code": 800708,
        "amphoe_code": 8007,
        "province_code": 80
    },
    {
        "district": "เขาพระทอง",
        "amphoe": "ชะอวด",
        "province": "นครศรีธรรมราช",
        "zipcode": 80180,
        "district_code": 800710,
        "amphoe_code": 8007,
        "province_code": 80
    },
    {
        "district": "เคร็ง",
        "amphoe": "ชะอวด",
        "province": "นครศรีธรรมราช",
        "zipcode": 80180,
        "district_code": 800704,
        "amphoe_code": 8007,
        "province_code": 80
    },
    {
        "district": "ช้างกลาง",
        "amphoe": "ช้างกลาง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80250,
        "district_code": 800402,
        "amphoe_code": 8022,
        "province_code": 80
    },
    {
        "district": "สวนขัน",
        "amphoe": "ช้างกลาง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80250,
        "district_code": 800412,
        "amphoe_code": 8022,
        "province_code": 80
    },
    {
        "district": "หลักช้าง",
        "amphoe": "ช้างกลาง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80250,
        "district_code": 800411,
        "amphoe_code": 8022,
        "province_code": 80
    },
    {
        "district": "คลองเส",
        "amphoe": "ถ้ำพรรณรา",
        "province": "นครศรีธรรมราช",
        "zipcode": 80260,
        "district_code": 800413,
        "amphoe_code": 8018,
        "province_code": 80
    },
    {
        "district": "ดุสิต",
        "amphoe": "ถ้ำพรรณรา",
        "province": "นครศรีธรรมราช",
        "zipcode": 80260,
        "district_code": 800414,
        "amphoe_code": 8018,
        "province_code": 80
    },
    {
        "district": "ถ้ำพรรณรา",
        "amphoe": "ถ้ำพรรณรา",
        "province": "นครศรีธรรมราช",
        "zipcode": 80260,
        "district_code": 800408,
        "amphoe_code": 8018,
        "province_code": 80
    },
    {
        "district": "กะปาง",
        "amphoe": "ทุ่งสง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80310,
        "district_code": 800908,
        "amphoe_code": 8009,
        "province_code": 80
    },
    {
        "district": "ควนกรด",
        "amphoe": "ทุ่งสง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80110,
        "district_code": 800904,
        "amphoe_code": 8009,
        "province_code": 80
    },
    {
        "district": "ชะมาย",
        "amphoe": "ทุ่งสง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80110,
        "district_code": 800902,
        "amphoe_code": 8009,
        "province_code": 80
    },
    {
        "district": "ถ้ำใหญ่",
        "amphoe": "ทุ่งสง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80110,
        "district_code": 800911,
        "amphoe_code": 8009,
        "province_code": 80
    },
    {
        "district": "ที่วัง",
        "amphoe": "ทุ่งสง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80110,
        "district_code": 800909,
        "amphoe_code": 8009,
        "province_code": 80
    },
    {
        "district": "นาหลวงเสน",
        "amphoe": "ทุ่งสง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80110,
        "district_code": 800906,
        "amphoe_code": 8009,
        "province_code": 80
    },
    {
        "district": "นาโพธิ์",
        "amphoe": "ทุ่งสง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80110,
        "district_code": 800912,
        "amphoe_code": 8009,
        "province_code": 80
    },
    {
        "district": "นาไม้ไผ่",
        "amphoe": "ทุ่งสง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80110,
        "district_code": 800905,
        "amphoe_code": 8009,
        "province_code": 80
    },
    {
        "district": "น้ำตก",
        "amphoe": "ทุ่งสง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80110,
        "district_code": 800910,
        "amphoe_code": 8009,
        "province_code": 80
    },
    {
        "district": "ปากแพรก",
        "amphoe": "ทุ่งสง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80110,
        "district_code": 800901,
        "amphoe_code": 8009,
        "province_code": 80
    },
    {
        "district": "หนองหงส์",
        "amphoe": "ทุ่งสง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80110,
        "district_code": 800903,
        "amphoe_code": 8009,
        "province_code": 80
    },
    {
        "district": "เขาขาว",
        "amphoe": "ทุ่งสง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80110,
        "district_code": 800913,
        "amphoe_code": 8009,
        "province_code": 80
    },
    {
        "district": "เขาโร",
        "amphoe": "ทุ่งสง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80110,
        "district_code": 800907,
        "amphoe_code": 8009,
        "province_code": 80
    },
    {
        "district": "กรุงหยัน",
        "amphoe": "ทุ่งใหญ่",
        "province": "นครศรีธรรมราช",
        "zipcode": 80240,
        "district_code": 801107,
        "amphoe_code": 8011,
        "province_code": 80
    },
    {
        "district": "กุแหระ",
        "amphoe": "ทุ่งใหญ่",
        "province": "นครศรีธรรมราช",
        "zipcode": 80240,
        "district_code": 801104,
        "amphoe_code": 8011,
        "province_code": 80
    },
    {
        "district": "ทุ่งสัง",
        "amphoe": "ทุ่งใหญ่",
        "province": "นครศรีธรรมราช",
        "zipcode": 80240,
        "district_code": 801102,
        "amphoe_code": 8011,
        "province_code": 80
    },
    {
        "district": "ทุ่งใหญ่",
        "amphoe": "ทุ่งใหญ่",
        "province": "นครศรีธรรมราช",
        "zipcode": 80240,
        "district_code": 801103,
        "amphoe_code": 8011,
        "province_code": 80
    },
    {
        "district": "ท่ายาง",
        "amphoe": "ทุ่งใหญ่",
        "province": "นครศรีธรรมราช",
        "zipcode": 80240,
        "district_code": 801101,
        "amphoe_code": 8011,
        "province_code": 80
    },
    {
        "district": "บางรูป",
        "amphoe": "ทุ่งใหญ่",
        "province": "นครศรีธรรมราช",
        "zipcode": 80240,
        "district_code": 801106,
        "amphoe_code": 8011,
        "province_code": 80
    },
    {
        "district": "ปริก",
        "amphoe": "ทุ่งใหญ่",
        "province": "นครศรีธรรมราช",
        "zipcode": 80240,
        "district_code": 801105,
        "amphoe_code": 8011,
        "province_code": 80
    },
    {
        "district": "กลาย",
        "amphoe": "ท่าศาลา",
        "province": "นครศรีธรรมราช",
        "zipcode": 80160,
        "district_code": 800802,
        "amphoe_code": 8008,
        "province_code": 80
    },
    {
        "district": "ดอนตะโก",
        "amphoe": "ท่าศาลา",
        "province": "นครศรีธรรมราช",
        "zipcode": 80160,
        "district_code": 800810,
        "amphoe_code": 8008,
        "province_code": 80
    },
    {
        "district": "ตลิ่งชัน",
        "amphoe": "ท่าศาลา",
        "province": "นครศรีธรรมราช",
        "zipcode": 80160,
        "district_code": 800811,
        "amphoe_code": 8008,
        "province_code": 80
    },
    {
        "district": "ท่าขึ้น",
        "amphoe": "ท่าศาลา",
        "province": "นครศรีธรรมราช",
        "zipcode": 80160,
        "district_code": 800803,
        "amphoe_code": 8008,
        "province_code": 80
    },
    {
        "district": "ท่าศาลา",
        "amphoe": "ท่าศาลา",
        "province": "นครศรีธรรมราช",
        "zipcode": 80160,
        "district_code": 800801,
        "amphoe_code": 8008,
        "province_code": 80
    },
    {
        "district": "สระแก้ว",
        "amphoe": "ท่าศาลา",
        "province": "นครศรีธรรมราช",
        "zipcode": 80160,
        "district_code": 800806,
        "amphoe_code": 8008,
        "province_code": 80
    },
    {
        "district": "หัวตะพาน",
        "amphoe": "ท่าศาลา",
        "province": "นครศรีธรรมราช",
        "zipcode": 80160,
        "district_code": 800804,
        "amphoe_code": 8008,
        "province_code": 80
    },
    {
        "district": "โพธิ์ทอง",
        "amphoe": "ท่าศาลา",
        "province": "นครศรีธรรมราช",
        "zipcode": 80160,
        "district_code": 800813,
        "amphoe_code": 8008,
        "province_code": 80
    },
    {
        "district": "โมคลาน",
        "amphoe": "ท่าศาลา",
        "province": "นครศรีธรรมราช",
        "zipcode": 80160,
        "district_code": 800807,
        "amphoe_code": 8008,
        "province_code": 80
    },
    {
        "district": "ไทยบุรี",
        "amphoe": "ท่าศาลา",
        "province": "นครศรีธรรมราช",
        "zipcode": 80160,
        "district_code": 800809,
        "amphoe_code": 8008,
        "province_code": 80
    },
    {
        "district": "กรุงชิง",
        "amphoe": "นบพิตำ",
        "province": "นครศรีธรรมราช",
        "zipcode": 80160,
        "district_code": 800812,
        "amphoe_code": 8021,
        "province_code": 80
    },
    {
        "district": "กะหรอ",
        "amphoe": "นบพิตำ",
        "province": "นครศรีธรรมราช",
        "zipcode": 80160,
        "district_code": 800805,
        "amphoe_code": 8021,
        "province_code": 80
    },
    {
        "district": "นบพิตำ",
        "amphoe": "นบพิตำ",
        "province": "นครศรีธรรมราช",
        "zipcode": 80160,
        "district_code": 800808,
        "amphoe_code": 8021,
        "province_code": 80
    },
    {
        "district": "นาเหรง",
        "amphoe": "นบพิตำ",
        "province": "นครศรีธรรมราช",
        "zipcode": 80160,
        "district_code": 800814,
        "amphoe_code": 8021,
        "province_code": 80
    },
    {
        "district": "ทุ่งสง",
        "amphoe": "นาบอน",
        "province": "นครศรีธรรมราช",
        "zipcode": 80220,
        "district_code": 800998,
        "amphoe_code": 8010,
        "province_code": 80
    },
    {
        "district": "นาบอน",
        "amphoe": "นาบอน",
        "province": "นครศรีธรรมราช",
        "zipcode": 80220,
        "district_code": 800999,
        "amphoe_code": 8010,
        "province_code": 80
    },
    {
        "district": "แก้วแสน",
        "amphoe": "นาบอน",
        "province": "นครศรีธรรมราช",
        "zipcode": 80220,
        "district_code": 800997,
        "amphoe_code": 8010,
        "province_code": 80
    },
    {
        "district": "บางขัน",
        "amphoe": "บางขัน",
        "province": "นครศรีธรรมราช",
        "zipcode": 80360,
        "district_code": 800996,
        "amphoe_code": 8017,
        "province_code": 80
    },
    {
        "district": "บ้านนิคม",
        "amphoe": "บางขัน",
        "province": "นครศรีธรรมราช",
        "zipcode": 80360,
        "district_code": 801704,
        "amphoe_code": 8017,
        "province_code": 80
    },
    {
        "district": "บ้านลำนาว",
        "amphoe": "บางขัน",
        "province": "นครศรีธรรมราช",
        "zipcode": 80360,
        "district_code": 800995,
        "amphoe_code": 8017,
        "province_code": 80
    },
    {
        "district": "วังหิน",
        "amphoe": "บางขัน",
        "province": "นครศรีธรรมราช",
        "zipcode": 80360,
        "district_code": 800994,
        "amphoe_code": 8017,
        "province_code": 80
    },
    {
        "district": "ขนาบนาก",
        "amphoe": "ปากพนัง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80140,
        "district_code": 801218,
        "amphoe_code": 8012,
        "province_code": 80
    },
    {
        "district": "คลองกระบือ",
        "amphoe": "ปากพนัง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80370,
        "district_code": 801205,
        "amphoe_code": 8012,
        "province_code": 80
    },
    {
        "district": "คลองน้อย",
        "amphoe": "ปากพนัง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80330,
        "district_code": 801202,
        "amphoe_code": 8012,
        "province_code": 80
    },
    {
        "district": "ชะเมา",
        "amphoe": "ปากพนัง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80330,
        "district_code": 801204,
        "amphoe_code": 8012,
        "province_code": 80
    },
    {
        "district": "ท่าพยา",
        "amphoe": "ปากพนัง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80140,
        "district_code": 801216,
        "amphoe_code": 8012,
        "province_code": 80
    },
    {
        "district": "บางตะพง",
        "amphoe": "ปากพนัง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80370,
        "district_code": 801213,
        "amphoe_code": 8012,
        "province_code": 80
    },
    {
        "district": "บางพระ",
        "amphoe": "ปากพนัง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80140,
        "district_code": 801212,
        "amphoe_code": 8012,
        "province_code": 80
    },
    {
        "district": "บางศาลา",
        "amphoe": "ปากพนัง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80370,
        "district_code": 801211,
        "amphoe_code": 8012,
        "province_code": 80
    },
    {
        "district": "บ้านเพิง",
        "amphoe": "ปากพนัง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80140,
        "district_code": 801215,
        "amphoe_code": 8012,
        "province_code": 80
    },
    {
        "district": "บ้านใหม่",
        "amphoe": "ปากพนัง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80370,
        "district_code": 801207,
        "amphoe_code": 8012,
        "province_code": 80
    },
    {
        "district": "ปากพนัง",
        "amphoe": "ปากพนัง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80140,
        "district_code": 801201,
        "amphoe_code": 8012,
        "province_code": 80
    },
    {
        "district": "ปากพนังฝั่งตะวันตก",
        "amphoe": "ปากพนัง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80370,
        "district_code": 801210,
        "amphoe_code": 8012,
        "province_code": 80
    },
    {
        "district": "ปากพนังฝั่งตะวันออก",
        "amphoe": "ปากพนัง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80140,
        "district_code": 801214,
        "amphoe_code": 8012,
        "province_code": 80
    },
    {
        "district": "ปากแพรก",
        "amphoe": "ปากพนัง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80140,
        "district_code": 800901,
        "amphoe_code": 8012,
        "province_code": 80
    },
    {
        "district": "ป่าระกำ",
        "amphoe": "ปากพนัง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80370,
        "district_code": 801203,
        "amphoe_code": 8012,
        "province_code": 80
    },
    {
        "district": "หูล่อง",
        "amphoe": "ปากพนัง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80370,
        "district_code": 801208,
        "amphoe_code": 8012,
        "province_code": 80
    },
    {
        "district": "เกาะทวด",
        "amphoe": "ปากพนัง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80330,
        "district_code": 801206,
        "amphoe_code": 8012,
        "province_code": 80
    },
    {
        "district": "แหลมตะลุมพุก",
        "amphoe": "ปากพนัง",
        "province": "นครศรีธรรมราช",
        "zipcode": 80140,
        "district_code": 801209,
        "amphoe_code": 8012,
        "province_code": 80
    },
    {
        "district": "ทอนหงส์",
        "amphoe": "พรหมคีรี",
        "province": "นครศรีธรรมราช",
        "zipcode": 80320,
        "district_code": 800204,
        "amphoe_code": 8002,
        "province_code": 80
    },
    {
        "district": "นาเรียง",
        "amphoe": "พรหมคีรี",
        "province": "นครศรีธรรมราช",
        "zipcode": 80320,
        "district_code": 800205,
        "amphoe_code": 8002,
        "province_code": 80
    },
    {
        "district": "บ้านเกาะ",
        "amphoe": "พรหมคีรี",
        "province": "นครศรีธรรมราช",
        "zipcode": 80320,
        "district_code": 800199,
        "amphoe_code": 8002,
        "province_code": 80
    },
    {
        "district": "พรหมโลก",
        "amphoe": "พรหมคีรี",
        "province": "นครศรีธรรมราช",
        "zipcode": 80320,
        "district_code": 800196,
        "amphoe_code": 8002,
        "province_code": 80
    },
    {
        "district": "อินคีรี",
        "amphoe": "พรหมคีรี",
        "province": "นครศรีธรรมราช",
        "zipcode": 80320,
        "district_code": 800195,
        "amphoe_code": 8002,
        "province_code": 80
    },
    {
        "district": "ช้างซ้าย",
        "amphoe": "พระพรหม",
        "province": "นครศรีธรรมราช",
        "zipcode": 80000,
        "district_code": 800110,
        "amphoe_code": 8020,
        "province_code": 80
    },
    {
        "district": "ท้ายสำเภา",
        "amphoe": "พระพรหม",
        "province": "นครศรีธรรมราช",
        "zipcode": 80000,
        "district_code": 800117,
        "amphoe_code": 8020,
        "province_code": 80
    },
    {
        "district": "นาพรุ",
        "amphoe": "พระพรหม",
        "province": "นครศรีธรรมราช",
        "zipcode": 80000,
        "district_code": 800109,
        "amphoe_code": 8020,
        "province_code": 80
    },
    {
        "district": "นาสาร",
        "amphoe": "พระพรหม",
        "province": "นครศรีธรรมราช",
        "zipcode": 80000,
        "district_code": 800111,
        "amphoe_code": 8020,
        "province_code": 80
    },
    {
        "district": "กะทูน",
        "amphoe": "พิปูน",
        "province": "นครศรีธรรมราช",
        "zipcode": 80270,
        "district_code": 800502,
        "amphoe_code": 8005,
        "province_code": 80
    },
    {
        "district": "ควนกลาง",
        "amphoe": "พิปูน",
        "province": "นครศรีธรรมราช",
        "zipcode": 80270,
        "district_code": 800505,
        "amphoe_code": 8005,
        "province_code": 80
    },
    {
        "district": "พิปูน",
        "amphoe": "พิปูน",
        "province": "นครศรีธรรมราช",
        "zipcode": 80270,
        "district_code": 800501,
        "amphoe_code": 8005,
        "province_code": 80
    },
    {
        "district": "ยางค้อม",
        "amphoe": "พิปูน",
        "province": "นครศรีธรรมราช",
        "zipcode": 80270,
        "district_code": 800504,
        "amphoe_code": 8005,
        "province_code": 80
    },
    {
        "district": "เขาพระ",
        "amphoe": "พิปูน",
        "province": "นครศรีธรรมราช",
        "zipcode": 80270,
        "district_code": 800503,
        "amphoe_code": 8005,
        "province_code": 80
    },
    {
        "district": "ควนชุม",
        "amphoe": "ร่อนพิบูลย์",
        "province": "นครศรีธรรมราช",
        "zipcode": 80130,
        "district_code": 801306,
        "amphoe_code": 8013,
        "province_code": 80
    },
    {
        "district": "ควนพัง",
        "amphoe": "ร่อนพิบูลย์",
        "province": "นครศรีธรรมราช",
        "zipcode": 80130,
        "district_code": 801305,
        "amphoe_code": 8013,
        "province_code": 80
    },
    {
        "district": "ควนเกย",
        "amphoe": "ร่อนพิบูลย์",
        "province": "นครศรีธรรมราช",
        "zipcode": 80130,
        "district_code": 801304,
        "amphoe_code": 8013,
        "province_code": 80
    },
    {
        "district": "ร่อนพิบูลย์",
        "amphoe": "ร่อนพิบูลย์",
        "province": "นครศรีธรรมราช",
        "zipcode": 80130,
        "district_code": 801301,
        "amphoe_code": 8013,
        "province_code": 80
    },
    {
        "district": "หินตก",
        "amphoe": "ร่อนพิบูลย์",
        "province": "นครศรีธรรมราช",
        "zipcode": 80350,
        "district_code": 801302,
        "amphoe_code": 8013,
        "province_code": 80
    },
    {
        "district": "เสาธง",
        "amphoe": "ร่อนพิบูลย์",
        "province": "นครศรีธรรมราช",
        "zipcode": 80350,
        "district_code": 801303,
        "amphoe_code": 8013,
        "province_code": 80
    },
    {
        "district": "กำโลน",
        "amphoe": "ลานสกา",
        "province": "นครศรีธรรมราช",
        "zipcode": 80230,
        "district_code": 800304,
        "amphoe_code": 8003,
        "province_code": 80
    },
    {
        "district": "ขุนทะเล",
        "amphoe": "ลานสกา",
        "province": "นครศรีธรรมราช",
        "zipcode": 80230,
        "district_code": 800305,
        "amphoe_code": 8003,
        "province_code": 80
    },
    {
        "district": "ท่าดี",
        "amphoe": "ลานสกา",
        "province": "นครศรีธรรมราช",
        "zipcode": 80230,
        "district_code": 800303,
        "amphoe_code": 8003,
        "province_code": 80
    },
    {
        "district": "ลานสกา",
        "amphoe": "ลานสกา",
        "province": "นครศรีธรรมราช",
        "zipcode": 80230,
        "district_code": 800302,
        "amphoe_code": 8003,
        "province_code": 80
    },
    {
        "district": "เขาแก้ว",
        "amphoe": "ลานสกา",
        "province": "นครศรีธรรมราช",
        "zipcode": 80230,
        "district_code": 800301,
        "amphoe_code": 8003,
        "province_code": 80
    },
    {
        "district": "ฉลอง",
        "amphoe": "สิชล",
        "province": "นครศรีธรรมราช",
        "zipcode": 80120,
        "district_code": 801403,
        "amphoe_code": 8014,
        "province_code": 80
    },
    {
        "district": "ทุ่งปรัง",
        "amphoe": "สิชล",
        "province": "นครศรีธรรมราช",
        "zipcode": 80120,
        "district_code": 801402,
        "amphoe_code": 8014,
        "province_code": 80
    },
    {
        "district": "ทุ่งใส",
        "amphoe": "สิชล",
        "province": "นครศรีธรรมราช",
        "zipcode": 80120,
        "district_code": 801409,
        "amphoe_code": 8014,
        "province_code": 80
    },
    {
        "district": "สิชล",
        "amphoe": "สิชล",
        "province": "นครศรีธรรมราช",
        "zipcode": 80120,
        "district_code": 801401,
        "amphoe_code": 8014,
        "province_code": 80
    },
    {
        "district": "สี่ขีด",
        "amphoe": "สิชล",
        "province": "นครศรีธรรมราช",
        "zipcode": 80120,
        "district_code": 801406,
        "amphoe_code": 8014,
        "province_code": 80
    },
    {
        "district": "เขาน้อย",
        "amphoe": "สิชล",
        "province": "นครศรีธรรมราช",
        "zipcode": 80120,
        "district_code": 801408,
        "amphoe_code": 8014,
        "province_code": 80
    },
    {
        "district": "เทพราช",
        "amphoe": "สิชล",
        "province": "นครศรีธรรมราช",
        "zipcode": 80340,
        "district_code": 801407,
        "amphoe_code": 8014,
        "province_code": 80
    },
    {
        "district": "เปลี่ยน",
        "amphoe": "สิชล",
        "province": "นครศรีธรรมราช",
        "zipcode": 80120,
        "district_code": 801405,
        "amphoe_code": 8014,
        "province_code": 80
    },
    {
        "district": "เสาเภา",
        "amphoe": "สิชล",
        "province": "นครศรีธรรมราช",
        "zipcode": 80340,
        "district_code": 801404,
        "amphoe_code": 8014,
        "province_code": 80
    },
    {
        "district": "ควนชะลิก",
        "amphoe": "หัวไทร",
        "province": "นครศรีธรรมราช",
        "zipcode": 80170,
        "district_code": 801609,
        "amphoe_code": 8016,
        "province_code": 80
    },
    {
        "district": "ทรายขาว",
        "amphoe": "หัวไทร",
        "province": "นครศรีธรรมราช",
        "zipcode": 80170,
        "district_code": 801603,
        "amphoe_code": 8016,
        "province_code": 80
    },
    {
        "district": "ท่าซอม",
        "amphoe": "หัวไทร",
        "province": "นครศรีธรรมราช",
        "zipcode": 80170,
        "district_code": 801608,
        "amphoe_code": 8016,
        "province_code": 80
    },
    {
        "district": "บางนบ",
        "amphoe": "หัวไทร",
        "province": "นครศรีธรรมราช",
        "zipcode": 80170,
        "district_code": 801607,
        "amphoe_code": 8016,
        "province_code": 80
    },
    {
        "district": "บ้านราม",
        "amphoe": "หัวไทร",
        "province": "นครศรีธรรมราช",
        "zipcode": 80170,
        "district_code": 801606,
        "amphoe_code": 8016,
        "province_code": 80
    },
    {
        "district": "รามแก้ว",
        "amphoe": "หัวไทร",
        "province": "นครศรีธรรมราช",
        "zipcode": 80170,
        "district_code": 801610,
        "amphoe_code": 8016,
        "province_code": 80
    },
    {
        "district": "หน้าสตน",
        "amphoe": "หัวไทร",
        "province": "นครศรีธรรมราช",
        "zipcode": 80170,
        "district_code": 801602,
        "amphoe_code": 8016,
        "province_code": 80
    },
    {
        "district": "หัวไทร",
        "amphoe": "หัวไทร",
        "province": "นครศรีธรรมราช",
        "zipcode": 80170,
        "district_code": 801601,
        "amphoe_code": 8016,
        "province_code": 80
    },
    {
        "district": "เกาะเพชร",
        "amphoe": "หัวไทร",
        "province": "นครศรีธรรมราช",
        "zipcode": 80170,
        "district_code": 801611,
        "amphoe_code": 8016,
        "province_code": 80
    },
    {
        "district": "เขาพังไกร",
        "amphoe": "หัวไทร",
        "province": "นครศรีธรรมราช",
        "zipcode": 80170,
        "district_code": 801605,
        "amphoe_code": 8016,
        "province_code": 80
    },
    {
        "district": "แหลม",
        "amphoe": "หัวไทร",
        "province": "นครศรีธรรมราช",
        "zipcode": 80170,
        "district_code": 801604,
        "amphoe_code": 8016,
        "province_code": 80
    },
    {
        "district": "ดอนตรอ",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "นครศรีธรรมราช",
        "zipcode": 80290,
        "district_code": 800608,
        "amphoe_code": 8023,
        "province_code": 80
    },
    {
        "district": "ทางพูน",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "นครศรีธรรมราช",
        "zipcode": 80290,
        "district_code": 801308,
        "amphoe_code": 8023,
        "province_code": 80
    },
    {
        "district": "สวนหลวง",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "นครศรีธรรมราช",
        "zipcode": 80190,
        "district_code": 800609,
        "amphoe_code": 8023,
        "province_code": 80
    },
    {
        "district": "เชียรเขา",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "นครศรีธรรมราช",
        "zipcode": 80190,
        "district_code": 800602,
        "amphoe_code": 8023,
        "province_code": 80
    },
    {
        "district": "การะเกด",
        "amphoe": "เชียรใหญ่",
        "province": "นครศรีธรรมราช",
        "zipcode": 80190,
        "district_code": 800611,
        "amphoe_code": 8006,
        "province_code": 80
    },
    {
        "district": "ท่าขนาน",
        "amphoe": "เชียรใหญ่",
        "province": "นครศรีธรรมราช",
        "zipcode": 80190,
        "district_code": 800603,
        "amphoe_code": 8006,
        "province_code": 80
    },
    {
        "district": "ท้องลำเจียก",
        "amphoe": "เชียรใหญ่",
        "province": "นครศรีธรรมราช",
        "zipcode": 80190,
        "district_code": 800607,
        "amphoe_code": 8006,
        "province_code": 80
    },
    {
        "district": "บ้านกลาง",
        "amphoe": "เชียรใหญ่",
        "province": "นครศรีธรรมราช",
        "zipcode": 80190,
        "district_code": 800604,
        "amphoe_code": 8006,
        "province_code": 80
    },
    {
        "district": "บ้านเนิน",
        "amphoe": "เชียรใหญ่",
        "province": "นครศรีธรรมราช",
        "zipcode": 80190,
        "district_code": 800605,
        "amphoe_code": 8006,
        "province_code": 80
    },
    {
        "district": "เขาพระบาท",
        "amphoe": "เชียรใหญ่",
        "province": "นครศรีธรรมราช",
        "zipcode": 80190,
        "district_code": 800612,
        "amphoe_code": 8006,
        "province_code": 80
    },
    {
        "district": "เชียรใหญ่",
        "amphoe": "เชียรใหญ่",
        "province": "นครศรีธรรมราช",
        "zipcode": 80190,
        "district_code": 800601,
        "amphoe_code": 8006,
        "province_code": 80
    },
    {
        "district": "เสือหึง",
        "amphoe": "เชียรใหญ่",
        "province": "นครศรีธรรมราช",
        "zipcode": 80190,
        "district_code": 800610,
        "amphoe_code": 8006,
        "province_code": 80
    },
    {
        "district": "แม่เจ้าอยู่หัว",
        "amphoe": "เชียรใหญ่",
        "province": "นครศรีธรรมราช",
        "zipcode": 80190,
        "district_code": 800613,
        "amphoe_code": 8006,
        "province_code": 80
    },
    {
        "district": "ไสหมาก",
        "amphoe": "เชียรใหญ่",
        "province": "นครศรีธรรมราช",
        "zipcode": 80190,
        "district_code": 800606,
        "amphoe_code": 8006,
        "province_code": 80
    },
    {
        "district": "กำแพงเซา",
        "amphoe": "เมืองนครศรีธรรมราช",
        "province": "นครศรีธรรมราช",
        "zipcode": 80280,
        "district_code": 800112,
        "amphoe_code": 8001,
        "province_code": 80
    },
    {
        "district": "คลัง",
        "amphoe": "เมืองนครศรีธรรมราช",
        "province": "นครศรีธรรมราช",
        "zipcode": 80000,
        "district_code": 800103,
        "amphoe_code": 8001,
        "province_code": 80
    },
    {
        "district": "ท่างิ้ว",
        "amphoe": "เมืองนครศรีธรรมราช",
        "province": "นครศรีธรรมราช",
        "zipcode": 80280,
        "district_code": 800116,
        "amphoe_code": 8001,
        "province_code": 80
    },
    {
        "district": "ท่าซัก",
        "amphoe": "เมืองนครศรีธรรมราช",
        "province": "นครศรีธรรมราช",
        "zipcode": 80000,
        "district_code": 800121,
        "amphoe_code": 8001,
        "province_code": 80
    },
    {
        "district": "ท่าวัง",
        "amphoe": "เมืองนครศรีธรรมราช",
        "province": "นครศรีธรรมราช",
        "zipcode": 80000,
        "district_code": 800102,
        "amphoe_code": 8001,
        "province_code": 80
    },
    {
        "district": "ท่าเรือ",
        "amphoe": "เมืองนครศรีธรรมราช",
        "province": "นครศรีธรรมราช",
        "zipcode": 80000,
        "district_code": 800122,
        "amphoe_code": 8001,
        "province_code": 80
    },
    {
        "district": "ท่าไร่",
        "amphoe": "เมืองนครศรีธรรมราช",
        "province": "นครศรีธรรมราช",
        "zipcode": 80000,
        "district_code": 800106,
        "amphoe_code": 8001,
        "province_code": 80
    },
    {
        "district": "นาทราย",
        "amphoe": "เมืองนครศรีธรรมราช",
        "province": "นครศรีธรรมราช",
        "zipcode": 80280,
        "district_code": 800108,
        "amphoe_code": 8001,
        "province_code": 80
    },
    {
        "district": "นาเคียน",
        "amphoe": "เมืองนครศรีธรรมราช",
        "province": "นครศรีธรรมราช",
        "zipcode": 80000,
        "district_code": 800115,
        "amphoe_code": 8001,
        "province_code": 80
    },
    {
        "district": "บางจาก",
        "amphoe": "เมืองนครศรีธรรมราช",
        "province": "นครศรีธรรมราช",
        "zipcode": 80330,
        "district_code": 800119,
        "amphoe_code": 8001,
        "province_code": 80
    },
    {
        "district": "ปากนคร",
        "amphoe": "เมืองนครศรีธรรมราช",
        "province": "นครศรีธรรมราช",
        "zipcode": 80000,
        "district_code": 800107,
        "amphoe_code": 8001,
        "province_code": 80
    },
    {
        "district": "ปากพูน",
        "amphoe": "เมืองนครศรีธรรมราช",
        "province": "นครศรีธรรมราช",
        "zipcode": 80000,
        "district_code": 800120,
        "amphoe_code": 8001,
        "province_code": 80
    },
    {
        "district": "มะม่วงสองต้น",
        "amphoe": "เมืองนครศรีธรรมราช",
        "province": "นครศรีธรรมราช",
        "zipcode": 80000,
        "district_code": 800114,
        "amphoe_code": 8001,
        "province_code": 80
    },
    {
        "district": "โพธิ์เสด็จ",
        "amphoe": "เมืองนครศรีธรรมราช",
        "province": "นครศรีธรรมราช",
        "zipcode": 80000,
        "district_code": 800118,
        "amphoe_code": 8001,
        "province_code": 80
    },
    {
        "district": "ในเมือง",
        "amphoe": "เมืองนครศรีธรรมราช",
        "province": "นครศรีธรรมราช",
        "zipcode": 80000,
        "district_code": 800101,
        "amphoe_code": 8001,
        "province_code": 80
    },
    {
        "district": "ไชยมนตรี",
        "amphoe": "เมืองนครศรีธรรมราช",
        "province": "นครศรีธรรมราช",
        "zipcode": 80000,
        "district_code": 800113,
        "amphoe_code": 8001,
        "province_code": 80
    },
    {
        "district": "ชุมตาบง",
        "amphoe": "ชุมตาบง",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601308,
        "amphoe_code": 6015,
        "province_code": 60
    },
    {
        "district": "ปางสวรรค์",
        "amphoe": "ชุมตาบง",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601306,
        "amphoe_code": 6015,
        "province_code": 60
    },
    {
        "district": "ฆะมัง",
        "amphoe": "ชุมแสง",
        "province": "นครสวรรค์",
        "zipcode": 60120,
        "district_code": 600311,
        "amphoe_code": 6003,
        "province_code": 60
    },
    {
        "district": "ชุมแสง",
        "amphoe": "ชุมแสง",
        "province": "นครสวรรค์",
        "zipcode": 60120,
        "district_code": 600301,
        "amphoe_code": 6003,
        "province_code": 60
    },
    {
        "district": "ทับกฤช",
        "amphoe": "ชุมแสง",
        "province": "นครสวรรค์",
        "zipcode": 60250,
        "district_code": 600302,
        "amphoe_code": 6003,
        "province_code": 60
    },
    {
        "district": "ทับกฤชใต้",
        "amphoe": "ชุมแสง",
        "province": "นครสวรรค์",
        "zipcode": 60250,
        "district_code": 600312,
        "amphoe_code": 6003,
        "province_code": 60
    },
    {
        "district": "ท่าไม้",
        "amphoe": "ชุมแสง",
        "province": "นครสวรรค์",
        "zipcode": 60120,
        "district_code": 600305,
        "amphoe_code": 6003,
        "province_code": 60
    },
    {
        "district": "บางเคียน",
        "amphoe": "ชุมแสง",
        "province": "นครสวรรค์",
        "zipcode": 60120,
        "district_code": 600306,
        "amphoe_code": 6003,
        "province_code": 60
    },
    {
        "district": "พันลาน",
        "amphoe": "ชุมแสง",
        "province": "นครสวรรค์",
        "zipcode": 60250,
        "district_code": 600308,
        "amphoe_code": 6003,
        "province_code": 60
    },
    {
        "district": "พิกุล",
        "amphoe": "ชุมแสง",
        "province": "นครสวรรค์",
        "zipcode": 60120,
        "district_code": 600303,
        "amphoe_code": 6003,
        "province_code": 60
    },
    {
        "district": "หนองกระเจา",
        "amphoe": "ชุมแสง",
        "province": "นครสวรรค์",
        "zipcode": 60120,
        "district_code": 600307,
        "amphoe_code": 6003,
        "province_code": 60
    },
    {
        "district": "เกยไชย",
        "amphoe": "ชุมแสง",
        "province": "นครสวรรค์",
        "zipcode": 60120,
        "district_code": 600304,
        "amphoe_code": 6003,
        "province_code": 60
    },
    {
        "district": "โคกหม้อ",
        "amphoe": "ชุมแสง",
        "province": "นครสวรรค์",
        "zipcode": 60120,
        "district_code": 600309,
        "amphoe_code": 6003,
        "province_code": 60
    },
    {
        "district": "ไผ่สิงห์",
        "amphoe": "ชุมแสง",
        "province": "นครสวรรค์",
        "zipcode": 60120,
        "district_code": 600310,
        "amphoe_code": 6003,
        "province_code": 60
    },
    {
        "district": "ตากฟ้า",
        "amphoe": "ตากฟ้า",
        "province": "นครสวรรค์",
        "zipcode": 60190,
        "district_code": 601201,
        "amphoe_code": 6012,
        "province_code": 60
    },
    {
        "district": "พุนกยูง",
        "amphoe": "ตากฟ้า",
        "province": "นครสวรรค์",
        "zipcode": 60190,
        "district_code": 601205,
        "amphoe_code": 6012,
        "province_code": 60
    },
    {
        "district": "ลำพยนต์",
        "amphoe": "ตากฟ้า",
        "province": "นครสวรรค์",
        "zipcode": 60190,
        "district_code": 601202,
        "amphoe_code": 6012,
        "province_code": 60
    },
    {
        "district": "สุขสำราญ",
        "amphoe": "ตากฟ้า",
        "province": "นครสวรรค์",
        "zipcode": 60190,
        "district_code": 601203,
        "amphoe_code": 6012,
        "province_code": 60
    },
    {
        "district": "หนองพิกุล",
        "amphoe": "ตากฟ้า",
        "province": "นครสวรรค์",
        "zipcode": 60190,
        "district_code": 601204,
        "amphoe_code": 6012,
        "province_code": 60
    },
    {
        "district": "อุดมธัญญา",
        "amphoe": "ตากฟ้า",
        "province": "นครสวรรค์",
        "zipcode": 60190,
        "district_code": 601206,
        "amphoe_code": 6012,
        "province_code": 60
    },
    {
        "district": "เขาชายธง",
        "amphoe": "ตากฟ้า",
        "province": "นครสวรรค์",
        "zipcode": 60190,
        "district_code": 601207,
        "amphoe_code": 6012,
        "province_code": 60
    },
    {
        "district": "จันเสน",
        "amphoe": "ตาคลี",
        "province": "นครสวรรค์",
        "zipcode": 60260,
        "district_code": 600703,
        "amphoe_code": 6007,
        "province_code": 60
    },
    {
        "district": "ช่องแค",
        "amphoe": "ตาคลี",
        "province": "นครสวรรค์",
        "zipcode": 60210,
        "district_code": 600702,
        "amphoe_code": 6007,
        "province_code": 60
    },
    {
        "district": "ตาคลี",
        "amphoe": "ตาคลี",
        "province": "นครสวรรค์",
        "zipcode": 60140,
        "district_code": 600701,
        "amphoe_code": 6007,
        "province_code": 60
    },
    {
        "district": "พรหมนิมิต",
        "amphoe": "ตาคลี",
        "province": "นครสวรรค์",
        "zipcode": 60210,
        "district_code": 600710,
        "amphoe_code": 6007,
        "province_code": 60
    },
    {
        "district": "ลาดทิพรส",
        "amphoe": "ตาคลี",
        "province": "นครสวรรค์",
        "zipcode": 60260,
        "district_code": 600709,
        "amphoe_code": 6007,
        "province_code": 60
    },
    {
        "district": "สร้อยทอง",
        "amphoe": "ตาคลี",
        "province": "นครสวรรค์",
        "zipcode": 60210,
        "district_code": 600708,
        "amphoe_code": 6007,
        "province_code": 60
    },
    {
        "district": "หนองหม้อ",
        "amphoe": "ตาคลี",
        "province": "นครสวรรค์",
        "zipcode": 60140,
        "district_code": 600707,
        "amphoe_code": 6007,
        "province_code": 60
    },
    {
        "district": "หนองโพ",
        "amphoe": "ตาคลี",
        "province": "นครสวรรค์",
        "zipcode": 60140,
        "district_code": 600706,
        "amphoe_code": 6007,
        "province_code": 60
    },
    {
        "district": "หัวหวาย",
        "amphoe": "ตาคลี",
        "province": "นครสวรรค์",
        "zipcode": 60140,
        "district_code": 600705,
        "amphoe_code": 6007,
        "province_code": 60
    },
    {
        "district": "ห้วยหอม",
        "amphoe": "ตาคลี",
        "province": "นครสวรรค์",
        "zipcode": 60210,
        "district_code": 600704,
        "amphoe_code": 6007,
        "province_code": 60
    },
    {
        "district": "ดอนคา",
        "amphoe": "ท่าตะโก",
        "province": "นครสวรรค์",
        "zipcode": 60160,
        "district_code": 600806,
        "amphoe_code": 6008,
        "province_code": 60
    },
    {
        "district": "ทำนบ",
        "amphoe": "ท่าตะโก",
        "province": "นครสวรรค์",
        "zipcode": 60160,
        "district_code": 600807,
        "amphoe_code": 6008,
        "province_code": 60
    },
    {
        "district": "ท่าตะโก",
        "amphoe": "ท่าตะโก",
        "province": "นครสวรรค์",
        "zipcode": 60160,
        "district_code": 600801,
        "amphoe_code": 6008,
        "province_code": 60
    },
    {
        "district": "พนมรอก",
        "amphoe": "ท่าตะโก",
        "province": "นครสวรรค์",
        "zipcode": 60160,
        "district_code": 600802,
        "amphoe_code": 6008,
        "province_code": 60
    },
    {
        "district": "พนมเศษ",
        "amphoe": "ท่าตะโก",
        "province": "นครสวรรค์",
        "zipcode": 60160,
        "district_code": 600809,
        "amphoe_code": 6008,
        "province_code": 60
    },
    {
        "district": "วังมหากร",
        "amphoe": "ท่าตะโก",
        "province": "นครสวรรค์",
        "zipcode": 60160,
        "district_code": 600805,
        "amphoe_code": 6008,
        "province_code": 60
    },
    {
        "district": "วังใหญ่",
        "amphoe": "ท่าตะโก",
        "province": "นครสวรรค์",
        "zipcode": 60160,
        "district_code": 600808,
        "amphoe_code": 6008,
        "province_code": 60
    },
    {
        "district": "สายลำโพง",
        "amphoe": "ท่าตะโก",
        "province": "นครสวรรค์",
        "zipcode": 60160,
        "district_code": 600804,
        "amphoe_code": 6008,
        "province_code": 60
    },
    {
        "district": "หนองหลวง",
        "amphoe": "ท่าตะโก",
        "province": "นครสวรรค์",
        "zipcode": 60160,
        "district_code": 600810,
        "amphoe_code": 6008,
        "province_code": 60
    },
    {
        "district": "หัวถนน",
        "amphoe": "ท่าตะโก",
        "province": "นครสวรรค์",
        "zipcode": 60160,
        "district_code": 600803,
        "amphoe_code": 6008,
        "province_code": 60
    },
    {
        "district": "ด่านช้าง",
        "amphoe": "บรรพตพิสัย",
        "province": "นครสวรรค์",
        "zipcode": 60180,
        "district_code": 600509,
        "amphoe_code": 6005,
        "province_code": 60
    },
    {
        "district": "ตาขีด",
        "amphoe": "บรรพตพิสัย",
        "province": "นครสวรรค์",
        "zipcode": 60180,
        "district_code": 600507,
        "amphoe_code": 6005,
        "province_code": 60
    },
    {
        "district": "ตาสัง",
        "amphoe": "บรรพตพิสัย",
        "province": "นครสวรรค์",
        "zipcode": 60180,
        "district_code": 600508,
        "amphoe_code": 6005,
        "province_code": 60
    },
    {
        "district": "ท่างิ้ว",
        "amphoe": "บรรพตพิสัย",
        "province": "นครสวรรค์",
        "zipcode": 60180,
        "district_code": 600501,
        "amphoe_code": 6005,
        "province_code": 60
    },
    {
        "district": "บางตาหงาย",
        "amphoe": "บรรพตพิสัย",
        "province": "นครสวรรค์",
        "zipcode": 60180,
        "district_code": 600502,
        "amphoe_code": 6005,
        "province_code": 60
    },
    {
        "district": "บางแก้ว",
        "amphoe": "บรรพตพิสัย",
        "province": "นครสวรรค์",
        "zipcode": 60180,
        "district_code": 600506,
        "amphoe_code": 6005,
        "province_code": 60
    },
    {
        "district": "บึงปลาทู",
        "amphoe": "บรรพตพิสัย",
        "province": "นครสวรรค์",
        "zipcode": 60180,
        "district_code": 600512,
        "amphoe_code": 6005,
        "province_code": 60
    },
    {
        "district": "บ้านแดน",
        "amphoe": "บรรพตพิสัย",
        "province": "นครสวรรค์",
        "zipcode": 60180,
        "district_code": 600505,
        "amphoe_code": 6005,
        "province_code": 60
    },
    {
        "district": "หนองกรด",
        "amphoe": "บรรพตพิสัย",
        "province": "นครสวรรค์",
        "zipcode": 60180,
        "district_code": 600114,
        "amphoe_code": 6005,
        "province_code": 60
    },
    {
        "district": "หนองตางู",
        "amphoe": "บรรพตพิสัย",
        "province": "นครสวรรค์",
        "zipcode": 60180,
        "district_code": 600511,
        "amphoe_code": 6005,
        "province_code": 60
    },
    {
        "district": "หูกวาง",
        "amphoe": "บรรพตพิสัย",
        "province": "นครสวรรค์",
        "zipcode": 60180,
        "district_code": 600503,
        "amphoe_code": 6005,
        "province_code": 60
    },
    {
        "district": "อ่างทอง",
        "amphoe": "บรรพตพิสัย",
        "province": "นครสวรรค์",
        "zipcode": 60180,
        "district_code": 600504,
        "amphoe_code": 6005,
        "province_code": 60
    },
    {
        "district": "เจริญผล",
        "amphoe": "บรรพตพิสัย",
        "province": "นครสวรรค์",
        "zipcode": 60180,
        "district_code": 600513,
        "amphoe_code": 6005,
        "province_code": 60
    },
    {
        "district": "ท่าน้ำอ้อย",
        "amphoe": "พยุหะคีรี",
        "province": "นครสวรรค์",
        "zipcode": 60130,
        "district_code": 601008,
        "amphoe_code": 6010,
        "province_code": 60
    },
    {
        "district": "นิคมเขาบ่อแก้ว",
        "amphoe": "พยุหะคีรี",
        "province": "นครสวรรค์",
        "zipcode": 60130,
        "district_code": 601003,
        "amphoe_code": 6010,
        "province_code": 60
    },
    {
        "district": "น้ำทรง",
        "amphoe": "พยุหะคีรี",
        "province": "นครสวรรค์",
        "zipcode": 60130,
        "district_code": 601009,
        "amphoe_code": 6010,
        "province_code": 60
    },
    {
        "district": "พยุหะ",
        "amphoe": "พยุหะคีรี",
        "province": "นครสวรรค์",
        "zipcode": 60130,
        "district_code": 601001,
        "amphoe_code": 6010,
        "province_code": 60
    },
    {
        "district": "ม่วงหัก",
        "amphoe": "พยุหะคีรี",
        "province": "นครสวรรค์",
        "zipcode": 60130,
        "district_code": 601004,
        "amphoe_code": 6010,
        "province_code": 60
    },
    {
        "district": "ยางขาว",
        "amphoe": "พยุหะคีรี",
        "province": "นครสวรรค์",
        "zipcode": 60130,
        "district_code": 601005,
        "amphoe_code": 6010,
        "province_code": 60
    },
    {
        "district": "ย่านมัทรี",
        "amphoe": "พยุหะคีรี",
        "province": "นครสวรรค์",
        "zipcode": 60130,
        "district_code": 601006,
        "amphoe_code": 6010,
        "province_code": 60
    },
    {
        "district": "สระทะเล",
        "amphoe": "พยุหะคีรี",
        "province": "นครสวรรค์",
        "zipcode": 60130,
        "district_code": 601011,
        "amphoe_code": 6010,
        "province_code": 60
    },
    {
        "district": "เขากะลา",
        "amphoe": "พยุหะคีรี",
        "province": "นครสวรรค์",
        "zipcode": 60130,
        "district_code": 601010,
        "amphoe_code": 6010,
        "province_code": 60
    },
    {
        "district": "เขาทอง",
        "amphoe": "พยุหะคีรี",
        "province": "นครสวรรค์",
        "zipcode": 60130,
        "district_code": 601007,
        "amphoe_code": 6010,
        "province_code": 60
    },
    {
        "district": "เนินมะกอก",
        "amphoe": "พยุหะคีรี",
        "province": "นครสวรรค์",
        "zipcode": 60130,
        "district_code": 601002,
        "amphoe_code": 6010,
        "province_code": 60
    },
    {
        "district": "บ้านไร่",
        "amphoe": "ลาดยาว",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601109,
        "amphoe_code": 6011,
        "province_code": 60
    },
    {
        "district": "มาบแก",
        "amphoe": "ลาดยาว",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601106,
        "amphoe_code": 6011,
        "province_code": 60
    },
    {
        "district": "ลาดยาว",
        "amphoe": "ลาดยาว",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601101,
        "amphoe_code": 6011,
        "province_code": 60
    },
    {
        "district": "วังม้า",
        "amphoe": "ลาดยาว",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601103,
        "amphoe_code": 6011,
        "province_code": 60
    },
    {
        "district": "วังเมือง",
        "amphoe": "ลาดยาว",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601104,
        "amphoe_code": 6011,
        "province_code": 60
    },
    {
        "district": "ศาลเจ้าไก่ต่อ",
        "amphoe": "ลาดยาว",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601116,
        "amphoe_code": 6011,
        "province_code": 60
    },
    {
        "district": "สระแก้ว",
        "amphoe": "ลาดยาว",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601117,
        "amphoe_code": 6011,
        "province_code": 60
    },
    {
        "district": "สร้อยละคร",
        "amphoe": "ลาดยาว",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601105,
        "amphoe_code": 6011,
        "province_code": 60
    },
    {
        "district": "หนองนมวัว",
        "amphoe": "ลาดยาว",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601108,
        "amphoe_code": 6011,
        "province_code": 60
    },
    {
        "district": "หนองยาว",
        "amphoe": "ลาดยาว",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601107,
        "amphoe_code": 6011,
        "province_code": 60
    },
    {
        "district": "ห้วยน้ำหอม",
        "amphoe": "ลาดยาว",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601102,
        "amphoe_code": 6011,
        "province_code": 60
    },
    {
        "district": "เนินขี้เหล็ก",
        "amphoe": "ลาดยาว",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601110,
        "amphoe_code": 6011,
        "province_code": 60
    },
    {
        "district": "ทุ่งทอง",
        "amphoe": "หนองบัว",
        "province": "นครสวรรค์",
        "zipcode": 60110,
        "district_code": 600408,
        "amphoe_code": 6004,
        "province_code": 60
    },
    {
        "district": "ธารทหาร",
        "amphoe": "หนองบัว",
        "province": "นครสวรรค์",
        "zipcode": 60110,
        "district_code": 600403,
        "amphoe_code": 6004,
        "province_code": 60
    },
    {
        "district": "วังบ่อ",
        "amphoe": "หนองบัว",
        "province": "นครสวรรค์",
        "zipcode": 60110,
        "district_code": 600409,
        "amphoe_code": 6004,
        "province_code": 60
    },
    {
        "district": "หนองกลับ",
        "amphoe": "หนองบัว",
        "province": "นครสวรรค์",
        "zipcode": 60110,
        "district_code": 600402,
        "amphoe_code": 6004,
        "province_code": 60
    },
    {
        "district": "หนองบัว",
        "amphoe": "หนองบัว",
        "province": "นครสวรรค์",
        "zipcode": 60110,
        "district_code": 600401,
        "amphoe_code": 6004,
        "province_code": 60
    },
    {
        "district": "ห้วยถั่วเหนือ",
        "amphoe": "หนองบัว",
        "province": "นครสวรรค์",
        "zipcode": 60110,
        "district_code": 600406,
        "amphoe_code": 6004,
        "province_code": 60
    },
    {
        "district": "ห้วยถั่วใต้",
        "amphoe": "หนองบัว",
        "province": "นครสวรรค์",
        "zipcode": 60110,
        "district_code": 600405,
        "amphoe_code": 6004,
        "province_code": 60
    },
    {
        "district": "ห้วยร่วม",
        "amphoe": "หนองบัว",
        "province": "นครสวรรค์",
        "zipcode": 60110,
        "district_code": 600404,
        "amphoe_code": 6004,
        "province_code": 60
    },
    {
        "district": "ห้วยใหญ่",
        "amphoe": "หนองบัว",
        "province": "นครสวรรค์",
        "zipcode": 60110,
        "district_code": 600407,
        "amphoe_code": 6004,
        "province_code": 60
    },
    {
        "district": "มหาโพธิ",
        "amphoe": "เก้าเลี้ยว",
        "province": "นครสวรรค์",
        "zipcode": 60230,
        "district_code": 600601,
        "amphoe_code": 6006,
        "province_code": 60
    },
    {
        "district": "หนองเต่า",
        "amphoe": "เก้าเลี้ยว",
        "province": "นครสวรรค์",
        "zipcode": 60230,
        "district_code": 600603,
        "amphoe_code": 6006,
        "province_code": 60
    },
    {
        "district": "หัวดง",
        "amphoe": "เก้าเลี้ยว",
        "province": "นครสวรรค์",
        "zipcode": 60230,
        "district_code": 600605,
        "amphoe_code": 6006,
        "province_code": 60
    },
    {
        "district": "เก้าเลี้ยว",
        "amphoe": "เก้าเลี้ยว",
        "province": "นครสวรรค์",
        "zipcode": 60230,
        "district_code": 600602,
        "amphoe_code": 6006,
        "province_code": 60
    },
    {
        "district": "เขาดิน",
        "amphoe": "เก้าเลี้ยว",
        "province": "นครสวรรค์",
        "zipcode": 60230,
        "district_code": 600604,
        "amphoe_code": 6006,
        "province_code": 60
    },
    {
        "district": "กลางแดด",
        "amphoe": "เมืองนครสวรรค์",
        "province": "นครสวรรค์",
        "zipcode": 60000,
        "district_code": 600102,
        "amphoe_code": 6001,
        "province_code": 60
    },
    {
        "district": "ตะเคียนเลื่อน",
        "amphoe": "เมืองนครสวรรค์",
        "province": "นครสวรรค์",
        "zipcode": 60000,
        "district_code": 600105,
        "amphoe_code": 6001,
        "province_code": 60
    },
    {
        "district": "นครสวรรค์ตก",
        "amphoe": "เมืองนครสวรรค์",
        "province": "นครสวรรค์",
        "zipcode": 60000,
        "district_code": 600106,
        "amphoe_code": 6001,
        "province_code": 60
    },
    {
        "district": "นครสวรรค์ออก",
        "amphoe": "เมืองนครสวรรค์",
        "province": "นครสวรรค์",
        "zipcode": 60000,
        "district_code": 600107,
        "amphoe_code": 6001,
        "province_code": 60
    },
    {
        "district": "บางพระหลวง",
        "amphoe": "เมืองนครสวรรค์",
        "province": "นครสวรรค์",
        "zipcode": 60000,
        "district_code": 600108,
        "amphoe_code": 6001,
        "province_code": 60
    },
    {
        "district": "บางม่วง",
        "amphoe": "เมืองนครสวรรค์",
        "province": "นครสวรรค์",
        "zipcode": 60000,
        "district_code": 600109,
        "amphoe_code": 6001,
        "province_code": 60
    },
    {
        "district": "บึงเสนาท",
        "amphoe": "เมืองนครสวรรค์",
        "province": "นครสวรรค์",
        "zipcode": 60000,
        "district_code": 600117,
        "amphoe_code": 6001,
        "province_code": 60
    },
    {
        "district": "บ้านมะเกลือ",
        "amphoe": "เมืองนครสวรรค์",
        "province": "นครสวรรค์",
        "zipcode": 60000,
        "district_code": 600110,
        "amphoe_code": 6001,
        "province_code": 60
    },
    {
        "district": "บ้านแก่ง",
        "amphoe": "เมืองนครสวรรค์",
        "province": "นครสวรรค์",
        "zipcode": 60000,
        "district_code": 600111,
        "amphoe_code": 6001,
        "province_code": 60
    },
    {
        "district": "ปากน้ำโพ",
        "amphoe": "เมืองนครสวรรค์",
        "province": "นครสวรรค์",
        "zipcode": 60000,
        "district_code": 600101,
        "amphoe_code": 6001,
        "province_code": 60
    },
    {
        "district": "พระนอน",
        "amphoe": "เมืองนครสวรรค์",
        "province": "นครสวรรค์",
        "zipcode": 60000,
        "district_code": 600112,
        "amphoe_code": 6001,
        "province_code": 60
    },
    {
        "district": "วัดไทรย์",
        "amphoe": "เมืองนครสวรรค์",
        "province": "นครสวรรค์",
        "zipcode": 60000,
        "district_code": false,
        "amphoe_code": 6001,
        "province_code": 60
    },
    {
        "district": "หนองกรด",
        "amphoe": "เมืองนครสวรรค์",
        "province": "นครสวรรค์",
        "zipcode": 60240,
        "district_code": 600114,
        "amphoe_code": 6001,
        "province_code": 60
    },
    {
        "district": "หนองกระโดน",
        "amphoe": "เมืองนครสวรรค์",
        "province": "นครสวรรค์",
        "zipcode": 60240,
        "district_code": 600115,
        "amphoe_code": 6001,
        "province_code": 60
    },
    {
        "district": "หนองปลิง",
        "amphoe": "เมืองนครสวรรค์",
        "province": "นครสวรรค์",
        "zipcode": 60000,
        "district_code": 600116,
        "amphoe_code": 6001,
        "province_code": 60
    },
    {
        "district": "เกรียงไกร",
        "amphoe": "เมืองนครสวรรค์",
        "province": "นครสวรรค์",
        "zipcode": 60000,
        "district_code": 600103,
        "amphoe_code": 6001,
        "province_code": 60
    },
    {
        "district": "แควใหญ่",
        "amphoe": "เมืองนครสวรรค์",
        "province": "นครสวรรค์",
        "zipcode": 60000,
        "district_code": 600104,
        "amphoe_code": 6001,
        "province_code": 60
    },
    {
        "district": "วังซ่าน",
        "amphoe": "แม่วงก์",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601304,
        "amphoe_code": 6013,
        "province_code": 60
    },
    {
        "district": "เขาชนกัน",
        "amphoe": "แม่วงก์",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601305,
        "amphoe_code": 6013,
        "province_code": 60
    },
    {
        "district": "แม่วงก์",
        "amphoe": "แม่วงก์",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601301,
        "amphoe_code": 6013,
        "province_code": 60
    },
    {
        "district": "แม่เล่ย์",
        "amphoe": "แม่วงก์",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601303,
        "amphoe_code": 6013,
        "province_code": 60
    },
    {
        "district": "แม่เปิน",
        "amphoe": "แม่เปิน",
        "province": "นครสวรรค์",
        "zipcode": 60150,
        "district_code": 601307,
        "amphoe_code": 6014,
        "province_code": 60
    },
    {
        "district": "นากลาง",
        "amphoe": "โกรกพระ",
        "province": "นครสวรรค์",
        "zipcode": 60170,
        "district_code": 600205,
        "amphoe_code": 6002,
        "province_code": 60
    },
    {
        "district": "บางประมุง",
        "amphoe": "โกรกพระ",
        "province": "นครสวรรค์",
        "zipcode": 60170,
        "district_code": 600204,
        "amphoe_code": 6002,
        "province_code": 60
    },
    {
        "district": "บางมะฝ่อ",
        "amphoe": "โกรกพระ",
        "province": "นครสวรรค์",
        "zipcode": 60170,
        "district_code": 600203,
        "amphoe_code": 6002,
        "province_code": 60
    },
    {
        "district": "ยางตาล",
        "amphoe": "โกรกพระ",
        "province": "นครสวรรค์",
        "zipcode": 60170,
        "district_code": 600202,
        "amphoe_code": 6002,
        "province_code": 60
    },
    {
        "district": "ศาลาแดง",
        "amphoe": "โกรกพระ",
        "province": "นครสวรรค์",
        "zipcode": 60170,
        "district_code": 600206,
        "amphoe_code": 6002,
        "province_code": 60
    },
    {
        "district": "หาดสูง",
        "amphoe": "โกรกพระ",
        "province": "นครสวรรค์",
        "zipcode": 60170,
        "district_code": 600209,
        "amphoe_code": 6002,
        "province_code": 60
    },
    {
        "district": "เนินกว้าว",
        "amphoe": "โกรกพระ",
        "province": "นครสวรรค์",
        "zipcode": 60170,
        "district_code": 600207,
        "amphoe_code": 6002,
        "province_code": 60
    },
    {
        "district": "เนินศาลา",
        "amphoe": "โกรกพระ",
        "province": "นครสวรรค์",
        "zipcode": 60170,
        "district_code": 600208,
        "amphoe_code": 6002,
        "province_code": 60
    },
    {
        "district": "โกรกพระ",
        "amphoe": "โกรกพระ",
        "province": "นครสวรรค์",
        "zipcode": 60170,
        "district_code": 600201,
        "amphoe_code": 6002,
        "province_code": 60
    },
    {
        "district": "ตะคร้อ",
        "amphoe": "ไพศาลี",
        "province": "นครสวรรค์",
        "zipcode": 60220,
        "district_code": 600904,
        "amphoe_code": 6009,
        "province_code": 60
    },
    {
        "district": "นาขอม",
        "amphoe": "ไพศาลี",
        "province": "นครสวรรค์",
        "zipcode": 60220,
        "district_code": 600907,
        "amphoe_code": 6009,
        "province_code": 60
    },
    {
        "district": "วังข่อย",
        "amphoe": "ไพศาลี",
        "province": "นครสวรรค์",
        "zipcode": 60220,
        "district_code": 600906,
        "amphoe_code": 6009,
        "province_code": 60
    },
    {
        "district": "วังน้ำลัด",
        "amphoe": "ไพศาลี",
        "province": "นครสวรรค์",
        "zipcode": 60220,
        "district_code": 600903,
        "amphoe_code": 6009,
        "province_code": 60
    },
    {
        "district": "สำโรงชัย",
        "amphoe": "ไพศาลี",
        "province": "นครสวรรค์",
        "zipcode": 60220,
        "district_code": 600902,
        "amphoe_code": 6009,
        "province_code": 60
    },
    {
        "district": "โคกเดื่อ",
        "amphoe": "ไพศาลี",
        "province": "นครสวรรค์",
        "zipcode": 60220,
        "district_code": 600901,
        "amphoe_code": 6009,
        "province_code": 60
    },
    {
        "district": "โพธิ์ประสาท",
        "amphoe": "ไพศาลี",
        "province": "นครสวรรค์",
        "zipcode": 60220,
        "district_code": 600905,
        "amphoe_code": 6009,
        "province_code": 60
    },
    {
        "district": "ไพศาลี",
        "amphoe": "ไพศาลี",
        "province": "นครสวรรค์",
        "zipcode": 60220,
        "district_code": 600908,
        "amphoe_code": 6009,
        "province_code": 60
    },
    {
        "district": "บางกรวย",
        "amphoe": "บางกรวย",
        "province": "นนทบุรี",
        "zipcode": 11130,
        "district_code": 120202,
        "amphoe_code": 1202,
        "province_code": 12
    },
    {
        "district": "บางขนุน",
        "amphoe": "บางกรวย",
        "province": "นนทบุรี",
        "zipcode": 11130,
        "district_code": 120204,
        "amphoe_code": 1202,
        "province_code": 12
    },
    {
        "district": "บางขุนกอง",
        "amphoe": "บางกรวย",
        "province": "นนทบุรี",
        "zipcode": 11130,
        "district_code": 120205,
        "amphoe_code": 1202,
        "province_code": 12
    },
    {
        "district": "บางคูเวียง",
        "amphoe": "บางกรวย",
        "province": "นนทบุรี",
        "zipcode": 11130,
        "district_code": 120206,
        "amphoe_code": 1202,
        "province_code": 12
    },
    {
        "district": "บางสีทอง",
        "amphoe": "บางกรวย",
        "province": "นนทบุรี",
        "zipcode": 11130,
        "district_code": 120203,
        "amphoe_code": 1202,
        "province_code": 12
    },
    {
        "district": "ปลายบาง",
        "amphoe": "บางกรวย",
        "province": "นนทบุรี",
        "zipcode": 11130,
        "district_code": 120208,
        "amphoe_code": 1202,
        "province_code": 12
    },
    {
        "district": "มหาสวัสดิ์",
        "amphoe": "บางกรวย",
        "province": "นนทบุรี",
        "zipcode": 11130,
        "district_code": 120207,
        "amphoe_code": 1202,
        "province_code": 12
    },
    {
        "district": "วัดชลอ",
        "amphoe": "บางกรวย",
        "province": "นนทบุรี",
        "zipcode": 11130,
        "district_code": 120201,
        "amphoe_code": 1202,
        "province_code": 12
    },
    {
        "district": "ศาลากลาง",
        "amphoe": "บางกรวย",
        "province": "นนทบุรี",
        "zipcode": 11130,
        "district_code": 120209,
        "amphoe_code": 1202,
        "province_code": 12
    },
    {
        "district": "บางคูรัด",
        "amphoe": "บางบัวทอง",
        "province": "นนทบุรี",
        "zipcode": 11110,
        "district_code": 120404,
        "amphoe_code": 1204,
        "province_code": 12
    },
    {
        "district": "บางบัวทอง",
        "amphoe": "บางบัวทอง",
        "province": "นนทบุรี",
        "zipcode": 11110,
        "district_code": 120402,
        "amphoe_code": 1204,
        "province_code": 12
    },
    {
        "district": "บางรักพัฒนา",
        "amphoe": "บางบัวทอง",
        "province": "นนทบุรี",
        "zipcode": 11110,
        "district_code": 120408,
        "amphoe_code": 1204,
        "province_code": 12
    },
    {
        "district": "บางรักใหญ่",
        "amphoe": "บางบัวทอง",
        "province": "นนทบุรี",
        "zipcode": 11110,
        "district_code": 120403,
        "amphoe_code": 1204,
        "province_code": 12
    },
    {
        "district": "พิมลราช",
        "amphoe": "บางบัวทอง",
        "province": "นนทบุรี",
        "zipcode": 11110,
        "district_code": 120407,
        "amphoe_code": 1204,
        "province_code": 12
    },
    {
        "district": "ละหาร",
        "amphoe": "บางบัวทอง",
        "province": "นนทบุรี",
        "zipcode": 11110,
        "district_code": 120405,
        "amphoe_code": 1204,
        "province_code": 12
    },
    {
        "district": "ลำโพ",
        "amphoe": "บางบัวทอง",
        "province": "นนทบุรี",
        "zipcode": 11110,
        "district_code": 120406,
        "amphoe_code": 1204,
        "province_code": 12
    },
    {
        "district": "โสนลอย",
        "amphoe": "บางบัวทอง",
        "province": "นนทบุรี",
        "zipcode": 11110,
        "district_code": 120401,
        "amphoe_code": 1204,
        "province_code": 12
    },
    {
        "district": "บางม่วง",
        "amphoe": "บางใหญ่",
        "province": "นนทบุรี",
        "zipcode": 11140,
        "district_code": 120301,
        "amphoe_code": 1203,
        "province_code": 12
    },
    {
        "district": "บางเลน",
        "amphoe": "บางใหญ่",
        "province": "นนทบุรี",
        "zipcode": 11140,
        "district_code": 120303,
        "amphoe_code": 1203,
        "province_code": 12
    },
    {
        "district": "บางแม่นาง",
        "amphoe": "บางใหญ่",
        "province": "นนทบุรี",
        "zipcode": 11140,
        "district_code": 120302,
        "amphoe_code": 1203,
        "province_code": 12
    },
    {
        "district": "บางใหญ่",
        "amphoe": "บางใหญ่",
        "province": "นนทบุรี",
        "zipcode": 11140,
        "district_code": 120305,
        "amphoe_code": 1203,
        "province_code": 12
    },
    {
        "district": "บ้านใหม่",
        "amphoe": "บางใหญ่",
        "province": "นนทบุรี",
        "zipcode": 11140,
        "district_code": 120306,
        "amphoe_code": 1203,
        "province_code": 12
    },
    {
        "district": "เสาธงหิน",
        "amphoe": "บางใหญ่",
        "province": "นนทบุรี",
        "zipcode": 11140,
        "district_code": 120304,
        "amphoe_code": 1203,
        "province_code": 12
    },
    {
        "district": "คลองข่อย",
        "amphoe": "ปากเกร็ด",
        "province": "นนทบุรี",
        "zipcode": 11120,
        "district_code": 120610,
        "amphoe_code": 1206,
        "province_code": 12
    },
    {
        "district": "คลองพระอุดม",
        "amphoe": "ปากเกร็ด",
        "province": "นนทบุรี",
        "zipcode": 11120,
        "district_code": 120606,
        "amphoe_code": 1206,
        "province_code": 12
    },
    {
        "district": "คลองเกลือ",
        "amphoe": "ปากเกร็ด",
        "province": "นนทบุรี",
        "zipcode": 11120,
        "district_code": 120612,
        "amphoe_code": 1206,
        "province_code": 12
    },
    {
        "district": "ท่าอิฐ",
        "amphoe": "ปากเกร็ด",
        "province": "นนทบุรี",
        "zipcode": 11120,
        "district_code": 120607,
        "amphoe_code": 1206,
        "province_code": 12
    },
    {
        "district": "บางตลาด",
        "amphoe": "ปากเกร็ด",
        "province": "นนทบุรี",
        "zipcode": 11120,
        "district_code": 120602,
        "amphoe_code": 1206,
        "province_code": 12
    },
    {
        "district": "บางตะไนย์",
        "amphoe": "ปากเกร็ด",
        "province": "นนทบุรี",
        "zipcode": 11120,
        "district_code": 120605,
        "amphoe_code": 1206,
        "province_code": 12
    },
    {
        "district": "บางพลับ",
        "amphoe": "ปากเกร็ด",
        "province": "นนทบุรี",
        "zipcode": 11120,
        "district_code": 120611,
        "amphoe_code": 1206,
        "province_code": 12
    },
    {
        "district": "บางพูด",
        "amphoe": "ปากเกร็ด",
        "province": "นนทบุรี",
        "zipcode": 11120,
        "district_code": 120604,
        "amphoe_code": 1206,
        "province_code": 12
    },
    {
        "district": "บ้านใหม่",
        "amphoe": "ปากเกร็ด",
        "province": "นนทบุรี",
        "zipcode": 11120,
        "district_code": 120306,
        "amphoe_code": 1206,
        "province_code": 12
    },
    {
        "district": "ปากเกร็ด",
        "amphoe": "ปากเกร็ด",
        "province": "นนทบุรี",
        "zipcode": 11120,
        "district_code": 120601,
        "amphoe_code": 1206,
        "province_code": 12
    },
    {
        "district": "อ้อมเกร็ด",
        "amphoe": "ปากเกร็ด",
        "province": "นนทบุรี",
        "zipcode": 11120,
        "district_code": 120609,
        "amphoe_code": 1206,
        "province_code": 12
    },
    {
        "district": "เกาะเกร็ด",
        "amphoe": "ปากเกร็ด",
        "province": "นนทบุรี",
        "zipcode": 11120,
        "district_code": 120608,
        "amphoe_code": 1206,
        "province_code": 12
    },
    {
        "district": "ตลาดขวัญ",
        "amphoe": "เมืองนนทบุรี",
        "province": "นนทบุรี",
        "zipcode": 11000,
        "district_code": 120102,
        "amphoe_code": 1201,
        "province_code": 12
    },
    {
        "district": "ท่าทราย",
        "amphoe": "เมืองนนทบุรี",
        "province": "นนทบุรี",
        "zipcode": 11000,
        "district_code": 120105,
        "amphoe_code": 1201,
        "province_code": 12
    },
    {
        "district": "บางกระสอ",
        "amphoe": "เมืองนนทบุรี",
        "province": "นนทบุรี",
        "zipcode": 11000,
        "district_code": 120104,
        "amphoe_code": 1201,
        "province_code": 12
    },
    {
        "district": "บางกร่าง",
        "amphoe": "เมืองนนทบุรี",
        "province": "นนทบุรี",
        "zipcode": 11000,
        "district_code": 120108,
        "amphoe_code": 1201,
        "province_code": 12
    },
    {
        "district": "บางรักน้อย",
        "amphoe": "เมืองนนทบุรี",
        "province": "นนทบุรี",
        "zipcode": 11000,
        "district_code": 120110,
        "amphoe_code": 1201,
        "province_code": 12
    },
    {
        "district": "บางศรีเมือง",
        "amphoe": "เมืองนนทบุรี",
        "province": "นนทบุรี",
        "zipcode": 11000,
        "district_code": 120107,
        "amphoe_code": 1201,
        "province_code": 12
    },
    {
        "district": "บางเขน",
        "amphoe": "เมืองนนทบุรี",
        "province": "นนทบุรี",
        "zipcode": 11000,
        "district_code": 120103,
        "amphoe_code": 1201,
        "province_code": 12
    },
    {
        "district": "บางไผ่",
        "amphoe": "เมืองนนทบุรี",
        "province": "นนทบุรี",
        "zipcode": 11000,
        "district_code": 120106,
        "amphoe_code": 1201,
        "province_code": 12
    },
    {
        "district": "สวนใหญ่",
        "amphoe": "เมืองนนทบุรี",
        "province": "นนทบุรี",
        "zipcode": 11000,
        "district_code": 120101,
        "amphoe_code": 1201,
        "province_code": 12
    },
    {
        "district": "ไทรม้า",
        "amphoe": "เมืองนนทบุรี",
        "province": "นนทบุรี",
        "zipcode": 11000,
        "district_code": 120109,
        "amphoe_code": 1201,
        "province_code": 12
    },
    {
        "district": "ขุนศรี",
        "amphoe": "ไทรน้อย",
        "province": "นนทบุรี",
        "zipcode": 11150,
        "district_code": 120505,
        "amphoe_code": 1205,
        "province_code": 12
    },
    {
        "district": "คลองขวาง",
        "amphoe": "ไทรน้อย",
        "province": "นนทบุรี",
        "zipcode": 11150,
        "district_code": 120506,
        "amphoe_code": 1205,
        "province_code": 12
    },
    {
        "district": "ทวีวัฒนา",
        "amphoe": "ไทรน้อย",
        "province": "นนทบุรี",
        "zipcode": 11150,
        "district_code": 120507,
        "amphoe_code": 1205,
        "province_code": 12
    },
    {
        "district": "ราษฎร์นิยม",
        "amphoe": "ไทรน้อย",
        "province": "นนทบุรี",
        "zipcode": 11150,
        "district_code": 120502,
        "amphoe_code": 1205,
        "province_code": 12
    },
    {
        "district": "หนองเพรางาย",
        "amphoe": "ไทรน้อย",
        "province": "นนทบุรี",
        "zipcode": 11150,
        "district_code": 120503,
        "amphoe_code": 1205,
        "province_code": 12
    },
    {
        "district": "ไทรน้อย",
        "amphoe": "ไทรน้อย",
        "province": "นนทบุรี",
        "zipcode": 11150,
        "district_code": 120501,
        "amphoe_code": 1205,
        "province_code": 12
    },
    {
        "district": "ไทรใหญ่",
        "amphoe": "ไทรน้อย",
        "province": "นนทบุรี",
        "zipcode": 11150,
        "district_code": 120504,
        "amphoe_code": 1205,
        "province_code": 12
    },
    {
        "district": "จะแนะ",
        "amphoe": "จะแนะ",
        "province": "นราธิวาส",
        "zipcode": 96220,
        "district_code": 960599,
        "amphoe_code": 9612,
        "province_code": 96
    },
    {
        "district": "ช้างเผือก",
        "amphoe": "จะแนะ",
        "province": "นราธิวาส",
        "zipcode": 96220,
        "district_code": 961204,
        "amphoe_code": 9612,
        "province_code": 96
    },
    {
        "district": "ดุซงญอ",
        "amphoe": "จะแนะ",
        "province": "นราธิวาส",
        "zipcode": 96220,
        "district_code": 960598,
        "amphoe_code": 9612,
        "province_code": 96
    },
    {
        "district": "ผดุงมาตร",
        "amphoe": "จะแนะ",
        "province": "นราธิวาส",
        "zipcode": 96220,
        "district_code": 961203,
        "amphoe_code": 9612,
        "province_code": 96
    },
    {
        "district": "นานาค",
        "amphoe": "ตากใบ",
        "province": "นราธิวาส",
        "zipcode": 96110,
        "district_code": 960207,
        "amphoe_code": 9602,
        "province_code": 96
    },
    {
        "district": "บางขุนทอง",
        "amphoe": "ตากใบ",
        "province": "นราธิวาส",
        "zipcode": 96110,
        "district_code": 960205,
        "amphoe_code": 9602,
        "province_code": 96
    },
    {
        "district": "พร่อน",
        "amphoe": "ตากใบ",
        "province": "นราธิวาส",
        "zipcode": 96110,
        "district_code": 960203,
        "amphoe_code": 9602,
        "province_code": 96
    },
    {
        "district": "ศาลาใหม่",
        "amphoe": "ตากใบ",
        "province": "นราธิวาส",
        "zipcode": 96110,
        "district_code": 960204,
        "amphoe_code": 9602,
        "province_code": 96
    },
    {
        "district": "เกาะสะท้อน",
        "amphoe": "ตากใบ",
        "province": "นราธิวาส",
        "zipcode": 96110,
        "district_code": 960206,
        "amphoe_code": 9602,
        "province_code": 96
    },
    {
        "district": "เจ๊ะเห",
        "amphoe": "ตากใบ",
        "province": "นราธิวาส",
        "zipcode": 96110,
        "district_code": 960201,
        "amphoe_code": 9602,
        "province_code": 96
    },
    {
        "district": "โฆษิต",
        "amphoe": "ตากใบ",
        "province": "นราธิวาส",
        "zipcode": 96110,
        "district_code": 960208,
        "amphoe_code": 9602,
        "province_code": 96
    },
    {
        "district": "ไพรวัน",
        "amphoe": "ตากใบ",
        "province": "นราธิวาส",
        "zipcode": 96110,
        "district_code": 960202,
        "amphoe_code": 9602,
        "province_code": 96
    },
    {
        "district": "กาเยาะมาตี",
        "amphoe": "บาเจาะ",
        "province": "นราธิวาส",
        "zipcode": 96170,
        "district_code": 960303,
        "amphoe_code": 9603,
        "province_code": 96
    },
    {
        "district": "บาเจาะ",
        "amphoe": "บาเจาะ",
        "province": "นราธิวาส",
        "zipcode": 96170,
        "district_code": 960301,
        "amphoe_code": 9603,
        "province_code": 96
    },
    {
        "district": "บาเระเหนือ",
        "amphoe": "บาเจาะ",
        "province": "นราธิวาส",
        "zipcode": 96170,
        "district_code": 960305,
        "amphoe_code": 9603,
        "province_code": 96
    },
    {
        "district": "บาเระใต้",
        "amphoe": "บาเจาะ",
        "province": "นราธิวาส",
        "zipcode": 96170,
        "district_code": 960306,
        "amphoe_code": 9603,
        "province_code": 96
    },
    {
        "district": "ปะลุกาสาเมาะ",
        "amphoe": "บาเจาะ",
        "province": "นราธิวาส",
        "zipcode": 96170,
        "district_code": 960304,
        "amphoe_code": 9603,
        "province_code": 96
    },
    {
        "district": "ลุโบะสาวอ",
        "amphoe": "บาเจาะ",
        "province": "นราธิวาส",
        "zipcode": 96170,
        "district_code": 960302,
        "amphoe_code": 9603,
        "province_code": 96
    },
    {
        "district": "จอเบาะ",
        "amphoe": "ยี่งอ",
        "province": "นราธิวาส",
        "zipcode": 96180,
        "district_code": 960403,
        "amphoe_code": 9604,
        "province_code": 96
    },
    {
        "district": "ตะปอเยาะ",
        "amphoe": "ยี่งอ",
        "province": "นราธิวาส",
        "zipcode": 96180,
        "district_code": 960406,
        "amphoe_code": 9604,
        "province_code": 96
    },
    {
        "district": "ยี่งอ",
        "amphoe": "ยี่งอ",
        "province": "นราธิวาส",
        "zipcode": 96180,
        "district_code": 960401,
        "amphoe_code": 9604,
        "province_code": 96
    },
    {
        "district": "ละหาร",
        "amphoe": "ยี่งอ",
        "province": "นราธิวาส",
        "zipcode": 96180,
        "district_code": 960402,
        "amphoe_code": 9604,
        "province_code": 96
    },
    {
        "district": "ลุโบะบายะ",
        "amphoe": "ยี่งอ",
        "province": "นราธิวาส",
        "zipcode": 96180,
        "district_code": 960404,
        "amphoe_code": 9604,
        "province_code": 96
    },
    {
        "district": "ลุโบะบือซา",
        "amphoe": "ยี่งอ",
        "province": "นราธิวาส",
        "zipcode": 96180,
        "district_code": 960405,
        "amphoe_code": 9604,
        "province_code": 96
    },
    {
        "district": "กาลิซา",
        "amphoe": "ระแงะ",
        "province": "นราธิวาส",
        "zipcode": 96130,
        "district_code": 960507,
        "amphoe_code": 9605,
        "province_code": 96
    },
    {
        "district": "ตันหยงมัส",
        "amphoe": "ระแงะ",
        "province": "นราธิวาส",
        "zipcode": 96130,
        "district_code": 960501,
        "amphoe_code": 9605,
        "province_code": 96
    },
    {
        "district": "ตันหยงลิมอ",
        "amphoe": "ระแงะ",
        "province": "นราธิวาส",
        "zipcode": 96130,
        "district_code": 960502,
        "amphoe_code": 9605,
        "province_code": 96
    },
    {
        "district": "บองอ",
        "amphoe": "ระแงะ",
        "province": "นราธิวาส",
        "zipcode": 96220,
        "district_code": 960506,
        "amphoe_code": 9605,
        "province_code": 96
    },
    {
        "district": "บาโงสะโต",
        "amphoe": "ระแงะ",
        "province": "นราธิวาส",
        "zipcode": 96130,
        "district_code": 960508,
        "amphoe_code": 9605,
        "province_code": 96
    },
    {
        "district": "มะรือโบตก",
        "amphoe": "ระแงะ",
        "province": "นราธิวาส",
        "zipcode": 96130,
        "district_code": 960510,
        "amphoe_code": 9605,
        "province_code": 96
    },
    {
        "district": "เฉลิม",
        "amphoe": "ระแงะ",
        "province": "นราธิวาส",
        "zipcode": 96130,
        "district_code": 960509,
        "amphoe_code": 9605,
        "province_code": 96
    },
    {
        "district": "บาตง",
        "amphoe": "รือเสาะ",
        "province": "นราธิวาส",
        "zipcode": 96150,
        "district_code": 960605,
        "amphoe_code": 9606,
        "province_code": 96
    },
    {
        "district": "รือเสาะ",
        "amphoe": "รือเสาะ",
        "province": "นราธิวาส",
        "zipcode": 96150,
        "district_code": 960601,
        "amphoe_code": 9606,
        "province_code": 96
    },
    {
        "district": "รือเสาะออก",
        "amphoe": "รือเสาะ",
        "province": "นราธิวาส",
        "zipcode": 96150,
        "district_code": 960607,
        "amphoe_code": 9606,
        "province_code": 96
    },
    {
        "district": "ลาโละ",
        "amphoe": "รือเสาะ",
        "province": "นราธิวาส",
        "zipcode": 96150,
        "district_code": 960606,
        "amphoe_code": 9606,
        "province_code": 96
    },
    {
        "district": "สามัคคี",
        "amphoe": "รือเสาะ",
        "province": "นราธิวาส",
        "zipcode": 96150,
        "district_code": 960604,
        "amphoe_code": 9606,
        "province_code": 96
    },
    {
        "district": "สาวอ",
        "amphoe": "รือเสาะ",
        "province": "นราธิวาส",
        "zipcode": 96150,
        "district_code": 960602,
        "amphoe_code": 9606,
        "province_code": 96
    },
    {
        "district": "สุวารี",
        "amphoe": "รือเสาะ",
        "province": "นราธิวาส",
        "zipcode": 96150,
        "district_code": 960609,
        "amphoe_code": 9606,
        "province_code": 96
    },
    {
        "district": "เรียง",
        "amphoe": "รือเสาะ",
        "province": "นราธิวาส",
        "zipcode": 96150,
        "district_code": 960603,
        "amphoe_code": 9606,
        "province_code": 96
    },
    {
        "district": "โคกสะตอ",
        "amphoe": "รือเสาะ",
        "province": "นราธิวาส",
        "zipcode": 96150,
        "district_code": 960608,
        "amphoe_code": 9606,
        "province_code": 96
    },
    {
        "district": "กาหลง",
        "amphoe": "ศรีสาคร",
        "province": "นราธิวาส",
        "zipcode": 96210,
        "district_code": 960705,
        "amphoe_code": 9607,
        "province_code": 96
    },
    {
        "district": "ซากอ",
        "amphoe": "ศรีสาคร",
        "province": "นราธิวาส",
        "zipcode": 96210,
        "district_code": 960701,
        "amphoe_code": 9607,
        "province_code": 96
    },
    {
        "district": "ตะมะยูง",
        "amphoe": "ศรีสาคร",
        "province": "นราธิวาส",
        "zipcode": 96210,
        "district_code": 960698,
        "amphoe_code": 9607,
        "province_code": 96
    },
    {
        "district": "ศรีบรรพต",
        "amphoe": "ศรีสาคร",
        "province": "นราธิวาส",
        "zipcode": 96210,
        "district_code": 960706,
        "amphoe_code": 9607,
        "province_code": 96
    },
    {
        "district": "ศรีสาคร",
        "amphoe": "ศรีสาคร",
        "province": "นราธิวาส",
        "zipcode": 96210,
        "district_code": 960703,
        "amphoe_code": 9607,
        "province_code": 96
    },
    {
        "district": "เชิงคีรี",
        "amphoe": "ศรีสาคร",
        "province": "นราธิวาส",
        "zipcode": 96210,
        "district_code": 960704,
        "amphoe_code": 9607,
        "province_code": 96
    },
    {
        "district": "ภูเขาทอง",
        "amphoe": "สุคิริน",
        "province": "นราธิวาส",
        "zipcode": 96190,
        "district_code": 960904,
        "amphoe_code": 9609,
        "province_code": 96
    },
    {
        "district": "มาโมง",
        "amphoe": "สุคิริน",
        "province": "นราธิวาส",
        "zipcode": 96190,
        "district_code": 960901,
        "amphoe_code": 9609,
        "province_code": 96
    },
    {
        "district": "ร่มไทร",
        "amphoe": "สุคิริน",
        "province": "นราธิวาส",
        "zipcode": 96190,
        "district_code": 960905,
        "amphoe_code": 9609,
        "province_code": 96
    },
    {
        "district": "สุคิริน",
        "amphoe": "สุคิริน",
        "province": "นราธิวาส",
        "zipcode": 96190,
        "district_code": 960902,
        "amphoe_code": 9609,
        "province_code": 96
    },
    {
        "district": "เกียร์",
        "amphoe": "สุคิริน",
        "province": "นราธิวาส",
        "zipcode": 96190,
        "district_code": 960903,
        "amphoe_code": 9609,
        "province_code": 96
    },
    {
        "district": "กาวะ",
        "amphoe": "สุไหงปาดี",
        "province": "นราธิวาส",
        "zipcode": 96140,
        "district_code": 961106,
        "amphoe_code": 9611,
        "province_code": 96
    },
    {
        "district": "ปะลุรู",
        "amphoe": "สุไหงปาดี",
        "province": "นราธิวาส",
        "zipcode": 96140,
        "district_code": 961101,
        "amphoe_code": 9611,
        "province_code": 96
    },
    {
        "district": "ริโก๋",
        "amphoe": "สุไหงปาดี",
        "province": "นราธิวาส",
        "zipcode": 96140,
        "district_code": 961105,
        "amphoe_code": 9611,
        "province_code": 96
    },
    {
        "district": "สากอ",
        "amphoe": "สุไหงปาดี",
        "province": "นราธิวาส",
        "zipcode": 96140,
        "district_code": 961104,
        "amphoe_code": 9611,
        "province_code": 96
    },
    {
        "district": "สุไหงปาดี",
        "amphoe": "สุไหงปาดี",
        "province": "นราธิวาส",
        "zipcode": 96140,
        "district_code": 961102,
        "amphoe_code": 9611,
        "province_code": 96
    },
    {
        "district": "โต๊ะเด็ง",
        "amphoe": "สุไหงปาดี",
        "province": "นราธิวาส",
        "zipcode": 96140,
        "district_code": 961103,
        "amphoe_code": 9611,
        "province_code": 96
    },
    {
        "district": "ปาเสมัส",
        "amphoe": "สุไหงโก-ลก",
        "province": "นราธิวาส",
        "zipcode": 96120,
        "district_code": 961002,
        "amphoe_code": 9610,
        "province_code": 96
    },
    {
        "district": "ปูโยะ",
        "amphoe": "สุไหงโก-ลก",
        "province": "นราธิวาส",
        "zipcode": 96120,
        "district_code": 961004,
        "amphoe_code": 9610,
        "province_code": 96
    },
    {
        "district": "มูโนะ",
        "amphoe": "สุไหงโก-ลก",
        "province": "นราธิวาส",
        "zipcode": 96120,
        "district_code": 961003,
        "amphoe_code": 9610,
        "province_code": 96
    },
    {
        "district": "สุไหงโก-ลก",
        "amphoe": "สุไหงโก-ลก",
        "province": "นราธิวาส",
        "zipcode": 96120,
        "district_code": 961001,
        "amphoe_code": 9610,
        "province_code": 96
    },
    {
        "district": "จวบ",
        "amphoe": "เจาะไอร้อง",
        "province": "นราธิวาส",
        "zipcode": 96130,
        "district_code": 960503,
        "amphoe_code": 9613,
        "province_code": 96
    },
    {
        "district": "บูกิต",
        "amphoe": "เจาะไอร้อง",
        "province": "นราธิวาส",
        "zipcode": 96130,
        "district_code": 960505,
        "amphoe_code": 9613,
        "province_code": 96
    },
    {
        "district": "มะรือโบออก",
        "amphoe": "เจาะไอร้อง",
        "province": "นราธิวาส",
        "zipcode": 96130,
        "district_code": 961303,
        "amphoe_code": 9613,
        "province_code": 96
    },
    {
        "district": "กะลุวอ",
        "amphoe": "เมืองนราธิวาส",
        "province": "นราธิวาส",
        "zipcode": 96000,
        "district_code": 960105,
        "amphoe_code": 9601,
        "province_code": 96
    },
    {
        "district": "กะลุวอเหนือ",
        "amphoe": "เมืองนราธิวาส",
        "province": "นราธิวาส",
        "zipcode": 96000,
        "district_code": 960106,
        "amphoe_code": 9601,
        "province_code": 96
    },
    {
        "district": "บางนาค",
        "amphoe": "เมืองนราธิวาส",
        "province": "นราธิวาส",
        "zipcode": 96000,
        "district_code": 960101,
        "amphoe_code": 9601,
        "province_code": 96
    },
    {
        "district": "บางปอ",
        "amphoe": "เมืองนราธิวาส",
        "province": "นราธิวาส",
        "zipcode": 96000,
        "district_code": 960104,
        "amphoe_code": 9601,
        "province_code": 96
    },
    {
        "district": "มะนังตายอ",
        "amphoe": "เมืองนราธิวาส",
        "province": "นราธิวาส",
        "zipcode": 96000,
        "district_code": 960103,
        "amphoe_code": 9601,
        "province_code": 96
    },
    {
        "district": "ลำภู",
        "amphoe": "เมืองนราธิวาส",
        "province": "นราธิวาส",
        "zipcode": 96000,
        "district_code": 960102,
        "amphoe_code": 9601,
        "province_code": 96
    },
    {
        "district": "โคกเคียน",
        "amphoe": "เมืองนราธิวาส",
        "province": "นราธิวาส",
        "zipcode": 96000,
        "district_code": 960107,
        "amphoe_code": 9601,
        "province_code": 96
    },
    {
        "district": "กายูคละ",
        "amphoe": "แว้ง",
        "province": "นราธิวาส",
        "zipcode": 96160,
        "district_code": 960802,
        "amphoe_code": 9608,
        "province_code": 96
    },
    {
        "district": "ฆอเลาะ",
        "amphoe": "แว้ง",
        "province": "นราธิวาส",
        "zipcode": 96160,
        "district_code": 960803,
        "amphoe_code": 9608,
        "province_code": 96
    },
    {
        "district": "เอราวัณ",
        "amphoe": "แว้ง",
        "province": "นราธิวาส",
        "zipcode": 96160,
        "district_code": 960806,
        "amphoe_code": 9608,
        "province_code": 96
    },
    {
        "district": "แม่ดง",
        "amphoe": "แว้ง",
        "province": "นราธิวาส",
        "zipcode": 96160,
        "district_code": 960805,
        "amphoe_code": 9608,
        "province_code": 96
    },
    {
        "district": "แว้ง",
        "amphoe": "แว้ง",
        "province": "นราธิวาส",
        "zipcode": 96160,
        "district_code": 960801,
        "amphoe_code": 9608,
        "province_code": 96
    },
    {
        "district": "โละจูด",
        "amphoe": "แว้ง",
        "province": "นราธิวาส",
        "zipcode": 96160,
        "district_code": 960804,
        "amphoe_code": 9608,
        "province_code": 96
    },
    {
        "district": "งอบ",
        "amphoe": "ทุ่งช้าง",
        "province": "น่าน",
        "zipcode": 55130,
        "district_code": 550802,
        "amphoe_code": 5508,
        "province_code": 55
    },
    {
        "district": "ทุ่งช้าง",
        "amphoe": "ทุ่งช้าง",
        "province": "น่าน",
        "zipcode": 55130,
        "district_code": 550804,
        "amphoe_code": 5508,
        "province_code": 55
    },
    {
        "district": "ปอน",
        "amphoe": "ทุ่งช้าง",
        "province": "น่าน",
        "zipcode": 55130,
        "district_code": 550801,
        "amphoe_code": 5508,
        "province_code": 55
    },
    {
        "district": "และ",
        "amphoe": "ทุ่งช้าง",
        "province": "น่าน",
        "zipcode": 55130,
        "district_code": 550803,
        "amphoe_code": 5508,
        "province_code": 55
    },
    {
        "district": "จอมพระ",
        "amphoe": "ท่าวังผา",
        "province": "น่าน",
        "zipcode": 55140,
        "district_code": 550607,
        "amphoe_code": 5506,
        "province_code": 55
    },
    {
        "district": "ตาลชุม",
        "amphoe": "ท่าวังผา",
        "province": "น่าน",
        "zipcode": 55140,
        "district_code": 550605,
        "amphoe_code": 5506,
        "province_code": 55
    },
    {
        "district": "ท่าวังผา",
        "amphoe": "ท่าวังผา",
        "province": "น่าน",
        "zipcode": 55140,
        "district_code": 550609,
        "amphoe_code": 5506,
        "province_code": 55
    },
    {
        "district": "ป่าคา",
        "amphoe": "ท่าวังผา",
        "province": "น่าน",
        "zipcode": 55140,
        "district_code": 550602,
        "amphoe_code": 5506,
        "province_code": 55
    },
    {
        "district": "ผาตอ",
        "amphoe": "ท่าวังผา",
        "province": "น่าน",
        "zipcode": 55140,
        "district_code": 550603,
        "amphoe_code": 5506,
        "province_code": 55
    },
    {
        "district": "ผาทอง",
        "amphoe": "ท่าวังผา",
        "province": "น่าน",
        "zipcode": 55140,
        "district_code": 550610,
        "amphoe_code": 5506,
        "province_code": 55
    },
    {
        "district": "ยม",
        "amphoe": "ท่าวังผา",
        "province": "น่าน",
        "zipcode": 55140,
        "district_code": 550604,
        "amphoe_code": 5506,
        "province_code": 55
    },
    {
        "district": "ริม",
        "amphoe": "ท่าวังผา",
        "province": "น่าน",
        "zipcode": 55140,
        "district_code": 550601,
        "amphoe_code": 5506,
        "province_code": 55
    },
    {
        "district": "ศรีภูมิ",
        "amphoe": "ท่าวังผา",
        "province": "น่าน",
        "zipcode": 55140,
        "district_code": 550606,
        "amphoe_code": 5506,
        "province_code": 55
    },
    {
        "district": "แสนทอง",
        "amphoe": "ท่าวังผา",
        "province": "น่าน",
        "zipcode": 55140,
        "district_code": 550608,
        "amphoe_code": 5506,
        "province_code": 55
    },
    {
        "district": "นาน้อย",
        "amphoe": "นาน้อย",
        "province": "น่าน",
        "zipcode": 55150,
        "district_code": 550401,
        "amphoe_code": 5504,
        "province_code": 55
    },
    {
        "district": "น้ำตก",
        "amphoe": "นาน้อย",
        "province": "น่าน",
        "zipcode": 55150,
        "district_code": 550407,
        "amphoe_code": 5504,
        "province_code": 55
    },
    {
        "district": "บัวใหญ่",
        "amphoe": "นาน้อย",
        "province": "น่าน",
        "zipcode": 55150,
        "district_code": 550406,
        "amphoe_code": 5504,
        "province_code": 55
    },
    {
        "district": "ศรีษะเกษ",
        "amphoe": "นาน้อย",
        "province": "น่าน",
        "zipcode": 55150,
        "district_code": 550403,
        "amphoe_code": 5504,
        "province_code": 55
    },
    {
        "district": "สถาน",
        "amphoe": "นาน้อย",
        "province": "น่าน",
        "zipcode": 55150,
        "district_code": 550404,
        "amphoe_code": 5504,
        "province_code": 55
    },
    {
        "district": "สันทะ",
        "amphoe": "นาน้อย",
        "province": "น่าน",
        "zipcode": 55150,
        "district_code": 550405,
        "amphoe_code": 5504,
        "province_code": 55
    },
    {
        "district": "เชียงของ",
        "amphoe": "นาน้อย",
        "province": "น่าน",
        "zipcode": 55150,
        "district_code": 550402,
        "amphoe_code": 5504,
        "province_code": 55
    },
    {
        "district": "นาทะนุง",
        "amphoe": "นาหมื่น",
        "province": "น่าน",
        "zipcode": 55180,
        "district_code": 551001,
        "amphoe_code": 5510,
        "province_code": 55
    },
    {
        "district": "บ่อแก้ว",
        "amphoe": "นาหมื่น",
        "province": "น่าน",
        "zipcode": 55180,
        "district_code": 551002,
        "amphoe_code": 5510,
        "province_code": 55
    },
    {
        "district": "ปิงหลวง",
        "amphoe": "นาหมื่น",
        "province": "น่าน",
        "zipcode": 55180,
        "district_code": 551004,
        "amphoe_code": 5510,
        "province_code": 55
    },
    {
        "district": "เมืองลี",
        "amphoe": "นาหมื่น",
        "province": "น่าน",
        "zipcode": 55180,
        "district_code": 551003,
        "amphoe_code": 5510,
        "province_code": 55
    },
    {
        "district": "ดงพญา",
        "amphoe": "บ่อเกลือ",
        "province": "น่าน",
        "zipcode": 55220,
        "district_code": 551205,
        "amphoe_code": 5512,
        "province_code": 55
    },
    {
        "district": "บ่อเกลือเหนือ",
        "amphoe": "บ่อเกลือ",
        "province": "น่าน",
        "zipcode": 55220,
        "district_code": 550507,
        "amphoe_code": 5512,
        "province_code": 55
    },
    {
        "district": "บ่อเกลือใต้",
        "amphoe": "บ่อเกลือ",
        "province": "น่าน",
        "zipcode": 55220,
        "district_code": 550508,
        "amphoe_code": 5512,
        "province_code": 55
    },
    {
        "district": "ภูฟ้า",
        "amphoe": "บ่อเกลือ",
        "province": "น่าน",
        "zipcode": 55220,
        "district_code": 551204,
        "amphoe_code": 5512,
        "province_code": 55
    },
    {
        "district": "บ้านพี้",
        "amphoe": "บ้านหลวง",
        "province": "น่าน",
        "zipcode": 55190,
        "district_code": 550304,
        "amphoe_code": 5503,
        "province_code": 55
    },
    {
        "district": "บ้านฟ้า",
        "amphoe": "บ้านหลวง",
        "province": "น่าน",
        "zipcode": 55190,
        "district_code": 550198,
        "amphoe_code": 5503,
        "province_code": 55
    },
    {
        "district": "ป่าคาหลวง",
        "amphoe": "บ้านหลวง",
        "province": "น่าน",
        "zipcode": 55190,
        "district_code": 550196,
        "amphoe_code": 5503,
        "province_code": 55
    },
    {
        "district": "สวด",
        "amphoe": "บ้านหลวง",
        "province": "น่าน",
        "zipcode": 55190,
        "district_code": 550303,
        "amphoe_code": 5503,
        "province_code": 55
    },
    {
        "district": "ปัว",
        "amphoe": "ปัว",
        "province": "น่าน",
        "zipcode": 55120,
        "district_code": 550501,
        "amphoe_code": 5505,
        "province_code": 55
    },
    {
        "district": "ป่ากลาง",
        "amphoe": "ปัว",
        "province": "น่าน",
        "zipcode": 55120,
        "district_code": 550513,
        "amphoe_code": 5505,
        "province_code": 55
    },
    {
        "district": "ภูคา",
        "amphoe": "ปัว",
        "province": "น่าน",
        "zipcode": 55120,
        "district_code": 550511,
        "amphoe_code": 5505,
        "province_code": 55
    },
    {
        "district": "วรนคร",
        "amphoe": "ปัว",
        "province": "น่าน",
        "zipcode": 55120,
        "district_code": 550514,
        "amphoe_code": 5505,
        "province_code": 55
    },
    {
        "district": "ศิลาเพชร",
        "amphoe": "ปัว",
        "province": "น่าน",
        "zipcode": 55120,
        "district_code": 550505,
        "amphoe_code": 5505,
        "province_code": 55
    },
    {
        "district": "ศิลาแลง",
        "amphoe": "ปัว",
        "province": "น่าน",
        "zipcode": 55120,
        "district_code": 550504,
        "amphoe_code": 5505,
        "province_code": 55
    },
    {
        "district": "สกาด",
        "amphoe": "ปัว",
        "province": "น่าน",
        "zipcode": 55120,
        "district_code": 550512,
        "amphoe_code": 5505,
        "province_code": 55
    },
    {
        "district": "สถาน",
        "amphoe": "ปัว",
        "province": "น่าน",
        "zipcode": 55120,
        "district_code": 550404,
        "amphoe_code": 5505,
        "province_code": 55
    },
    {
        "district": "อวน",
        "amphoe": "ปัว",
        "province": "น่าน",
        "zipcode": 55120,
        "district_code": 550506,
        "amphoe_code": 5505,
        "province_code": 55
    },
    {
        "district": "เจดีย์ชัย",
        "amphoe": "ปัว",
        "province": "น่าน",
        "zipcode": 55120,
        "district_code": 550510,
        "amphoe_code": 5505,
        "province_code": 55
    },
    {
        "district": "แงง",
        "amphoe": "ปัว",
        "province": "น่าน",
        "zipcode": 55120,
        "district_code": 550502,
        "amphoe_code": 5505,
        "province_code": 55
    },
    {
        "district": "ไชยวัฒนา",
        "amphoe": "ปัว",
        "province": "น่าน",
        "zipcode": 55120,
        "district_code": 550509,
        "amphoe_code": 5505,
        "province_code": 55
    },
    {
        "district": "ท่าน้าว",
        "amphoe": "ภูเพียง",
        "province": "น่าน",
        "zipcode": 55000,
        "district_code": 550112,
        "amphoe_code": 5514,
        "province_code": 55
    },
    {
        "district": "นาปัง",
        "amphoe": "ภูเพียง",
        "province": "น่าน",
        "zipcode": 55000,
        "district_code": 550113,
        "amphoe_code": 5514,
        "province_code": 55
    },
    {
        "district": "น้ำเกี๋ยน",
        "amphoe": "ภูเพียง",
        "province": "น่าน",
        "zipcode": 55000,
        "district_code": 550118,
        "amphoe_code": 5514,
        "province_code": 55
    },
    {
        "district": "น้ำแก่น",
        "amphoe": "ภูเพียง",
        "province": "น่าน",
        "zipcode": 55000,
        "district_code": 550115,
        "amphoe_code": 5514,
        "province_code": 55
    },
    {
        "district": "ฝายแก้ว",
        "amphoe": "ภูเพียง",
        "province": "น่าน",
        "zipcode": 55000,
        "district_code": 550110,
        "amphoe_code": 5514,
        "province_code": 55
    },
    {
        "district": "ม่วงตึ๊ด",
        "amphoe": "ภูเพียง",
        "province": "น่าน",
        "zipcode": 55000,
        "district_code": 550111,
        "amphoe_code": 5514,
        "province_code": 55
    },
    {
        "district": "เมืองจัง",
        "amphoe": "ภูเพียง",
        "province": "น่าน",
        "zipcode": 55000,
        "district_code": 550114,
        "amphoe_code": 5514,
        "province_code": 55
    },
    {
        "district": "ชนแดน",
        "amphoe": "สองแคว",
        "province": "น่าน",
        "zipcode": 55160,
        "district_code": 551302,
        "amphoe_code": 5513,
        "province_code": 55
    },
    {
        "district": "นาไร่หลวง",
        "amphoe": "สองแคว",
        "province": "น่าน",
        "zipcode": 55160,
        "district_code": 550998,
        "amphoe_code": 5513,
        "province_code": 55
    },
    {
        "district": "ยอด",
        "amphoe": "สองแคว",
        "province": "น่าน",
        "zipcode": 55160,
        "district_code": 550999,
        "amphoe_code": 5513,
        "province_code": 55
    },
    {
        "district": "ดู่พงษ์",
        "amphoe": "สันติสุข",
        "province": "น่าน",
        "zipcode": 55210,
        "district_code": 550199,
        "amphoe_code": 5511,
        "province_code": 55
    },
    {
        "district": "ป่าแลวหลวง",
        "amphoe": "สันติสุข",
        "province": "น่าน",
        "zipcode": 55210,
        "district_code": 551102,
        "amphoe_code": 5511,
        "province_code": 55
    },
    {
        "district": "พงษ์",
        "amphoe": "สันติสุข",
        "province": "น่าน",
        "zipcode": 55210,
        "district_code": 550201,
        "amphoe_code": 5511,
        "province_code": 55
    },
    {
        "district": "ขุนน่าน",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "น่าน",
        "zipcode": 55130,
        "district_code": 551203,
        "amphoe_code": 5515,
        "province_code": 55
    },
    {
        "district": "ห้วยโก๋น",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "น่าน",
        "zipcode": 55130,
        "district_code": 550805,
        "amphoe_code": 5515,
        "province_code": 55
    },
    {
        "district": "พญาแก้ว",
        "amphoe": "เชียงกลาง",
        "province": "น่าน",
        "zipcode": 55160,
        "district_code": 550908,
        "amphoe_code": 5509,
        "province_code": 55
    },
    {
        "district": "พระธาตุ",
        "amphoe": "เชียงกลาง",
        "province": "น่าน",
        "zipcode": 55160,
        "district_code": 550904,
        "amphoe_code": 5509,
        "province_code": 55
    },
    {
        "district": "พระพุทธบาท",
        "amphoe": "เชียงกลาง",
        "province": "น่าน",
        "zipcode": 55160,
        "district_code": 550909,
        "amphoe_code": 5509,
        "province_code": 55
    },
    {
        "district": "เชียงกลาง",
        "amphoe": "เชียงกลาง",
        "province": "น่าน",
        "zipcode": 55160,
        "district_code": 550899,
        "amphoe_code": 5509,
        "province_code": 55
    },
    {
        "district": "เชียงคาน",
        "amphoe": "เชียงกลาง",
        "province": "น่าน",
        "zipcode": 55160,
        "district_code": 550903,
        "amphoe_code": 5509,
        "province_code": 55
    },
    {
        "district": "เปือ",
        "amphoe": "เชียงกลาง",
        "province": "น่าน",
        "zipcode": 55160,
        "district_code": 550898,
        "amphoe_code": 5509,
        "province_code": 55
    },
    {
        "district": "กองควาย",
        "amphoe": "เมืองน่าน",
        "province": "น่าน",
        "zipcode": 55000,
        "district_code": 550109,
        "amphoe_code": 5501,
        "province_code": 55
    },
    {
        "district": "ดู่ใต้",
        "amphoe": "เมืองน่าน",
        "province": "น่าน",
        "zipcode": 55000,
        "district_code": 550108,
        "amphoe_code": 5501,
        "province_code": 55
    },
    {
        "district": "ถืมตอง",
        "amphoe": "เมืองน่าน",
        "province": "น่าน",
        "zipcode": 55000,
        "district_code": 550105,
        "amphoe_code": 5501,
        "province_code": 55
    },
    {
        "district": "นาซาว",
        "amphoe": "เมืองน่าน",
        "province": "น่าน",
        "zipcode": 55000,
        "district_code": 550107,
        "amphoe_code": 5501,
        "province_code": 55
    },
    {
        "district": "บ่อ",
        "amphoe": "เมืองน่าน",
        "province": "น่าน",
        "zipcode": 55000,
        "district_code": 550102,
        "amphoe_code": 5501,
        "province_code": 55
    },
    {
        "district": "ผาสิงห์",
        "amphoe": "เมืองน่าน",
        "province": "น่าน",
        "zipcode": 55000,
        "district_code": 550103,
        "amphoe_code": 5501,
        "province_code": 55
    },
    {
        "district": "สวก",
        "amphoe": "เมืองน่าน",
        "province": "น่าน",
        "zipcode": 55000,
        "district_code": 550116,
        "amphoe_code": 5501,
        "province_code": 55
    },
    {
        "district": "สะเนียน",
        "amphoe": "เมืองน่าน",
        "province": "น่าน",
        "zipcode": 55000,
        "district_code": 550117,
        "amphoe_code": 5501,
        "province_code": 55
    },
    {
        "district": "เรือง",
        "amphoe": "เมืองน่าน",
        "province": "น่าน",
        "zipcode": 55000,
        "district_code": 550106,
        "amphoe_code": 5501,
        "province_code": 55
    },
    {
        "district": "ในเวียง",
        "amphoe": "เมืองน่าน",
        "province": "น่าน",
        "zipcode": 55000,
        "district_code": 550101,
        "amphoe_code": 5501,
        "province_code": 55
    },
    {
        "district": "ไชยสถาน",
        "amphoe": "เมืองน่าน",
        "province": "น่าน",
        "zipcode": 55000,
        "district_code": 550104,
        "amphoe_code": 5501,
        "province_code": 55
    },
    {
        "district": "กลางเวียง",
        "amphoe": "เวียงสา",
        "province": "น่าน",
        "zipcode": 55110,
        "district_code": 550701,
        "amphoe_code": 5507,
        "province_code": 55
    },
    {
        "district": "ขึ่ง",
        "amphoe": "เวียงสา",
        "province": "น่าน",
        "zipcode": 55110,
        "district_code": 550702,
        "amphoe_code": 5507,
        "province_code": 55
    },
    {
        "district": "จอมจันทร์",
        "amphoe": "เวียงสา",
        "province": "น่าน",
        "zipcode": 55110,
        "district_code": 550715,
        "amphoe_code": 5507,
        "province_code": 55
    },
    {
        "district": "ตาลชุม",
        "amphoe": "เวียงสา",
        "province": "น่าน",
        "zipcode": 55110,
        "district_code": 550605,
        "amphoe_code": 5507,
        "province_code": 55
    },
    {
        "district": "ทุ่งศรีทอง",
        "amphoe": "เวียงสา",
        "province": "น่าน",
        "zipcode": 55110,
        "district_code": 550717,
        "amphoe_code": 5507,
        "province_code": 55
    },
    {
        "district": "นาเหลือง",
        "amphoe": "เวียงสา",
        "province": "น่าน",
        "zipcode": 55110,
        "district_code": 550705,
        "amphoe_code": 5507,
        "province_code": 55
    },
    {
        "district": "น้ำปั้ว",
        "amphoe": "เวียงสา",
        "province": "น่าน",
        "zipcode": 55110,
        "district_code": 550708,
        "amphoe_code": 5507,
        "province_code": 55
    },
    {
        "district": "น้ำมวบ",
        "amphoe": "เวียงสา",
        "province": "น่าน",
        "zipcode": 55110,
        "district_code": 550707,
        "amphoe_code": 5507,
        "province_code": 55
    },
    {
        "district": "ปงสนุก",
        "amphoe": "เวียงสา",
        "province": "น่าน",
        "zipcode": 55110,
        "district_code": 550710,
        "amphoe_code": 5507,
        "province_code": 55
    },
    {
        "district": "ยาบหัวนา",
        "amphoe": "เวียงสา",
        "province": "น่าน",
        "zipcode": 55110,
        "district_code": 550709,
        "amphoe_code": 5507,
        "province_code": 55
    },
    {
        "district": "ส้าน",
        "amphoe": "เวียงสา",
        "province": "น่าน",
        "zipcode": 55110,
        "district_code": 550706,
        "amphoe_code": 5507,
        "province_code": 55
    },
    {
        "district": "ส้านนาหนองใหม่",
        "amphoe": "เวียงสา",
        "province": "น่าน",
        "zipcode": 55110,
        "district_code": 550712,
        "amphoe_code": 5507,
        "province_code": 55
    },
    {
        "district": "อ่ายนาไลย",
        "amphoe": "เวียงสา",
        "province": "น่าน",
        "zipcode": 55110,
        "district_code": 550711,
        "amphoe_code": 5507,
        "province_code": 55
    },
    {
        "district": "แม่ขะนิง",
        "amphoe": "เวียงสา",
        "province": "น่าน",
        "zipcode": 55110,
        "district_code": 550713,
        "amphoe_code": 5507,
        "province_code": 55
    },
    {
        "district": "แม่สา",
        "amphoe": "เวียงสา",
        "province": "น่าน",
        "zipcode": 55110,
        "district_code": 550716,
        "amphoe_code": 5507,
        "province_code": 55
    },
    {
        "district": "แม่สาคร",
        "amphoe": "เวียงสา",
        "province": "น่าน",
        "zipcode": 55110,
        "district_code": 550714,
        "amphoe_code": 5507,
        "province_code": 55
    },
    {
        "district": "ไหล่น่าน",
        "amphoe": "เวียงสา",
        "province": "น่าน",
        "zipcode": 55110,
        "district_code": 550703,
        "amphoe_code": 5507,
        "province_code": 55
    },
    {
        "district": "น้ำปาย",
        "amphoe": "แม่จริม",
        "province": "น่าน",
        "zipcode": 55170,
        "district_code": 550205,
        "amphoe_code": 5502,
        "province_code": 55
    },
    {
        "district": "น้ำพาง",
        "amphoe": "แม่จริม",
        "province": "น่าน",
        "zipcode": 55170,
        "district_code": 550204,
        "amphoe_code": 5502,
        "province_code": 55
    },
    {
        "district": "หนองแดง",
        "amphoe": "แม่จริม",
        "province": "น่าน",
        "zipcode": 55170,
        "district_code": 550202,
        "amphoe_code": 5502,
        "province_code": 55
    },
    {
        "district": "หมอเมือง",
        "amphoe": "แม่จริม",
        "province": "น่าน",
        "zipcode": 55170,
        "district_code": 550197,
        "amphoe_code": 5502,
        "province_code": 55
    },
    {
        "district": "แม่จริม",
        "amphoe": "แม่จริม",
        "province": "น่าน",
        "zipcode": 55170,
        "district_code": 550206,
        "amphoe_code": 5502,
        "province_code": 55
    },
    {
        "district": "ดงบัง",
        "amphoe": "บึงโขงหลง",
        "province": "บึงกาฬ",
        "zipcode": 38220,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "ท่าดอกคำ",
        "amphoe": "บึงโขงหลง",
        "province": "บึงกาฬ",
        "zipcode": 38220,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "บึงโขงหลง",
        "amphoe": "บึงโขงหลง",
        "province": "บึงกาฬ",
        "zipcode": 38220,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "โพธิ์หมากแข้ง",
        "amphoe": "บึงโขงหลง",
        "province": "บึงกาฬ",
        "zipcode": 38220,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "บุ่งคล้า",
        "amphoe": "บุ่งคล้า",
        "province": "บึงกาฬ",
        "zipcode": 38000,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "หนองเดิ่น",
        "amphoe": "บุ่งคล้า",
        "province": "บึงกาฬ",
        "zipcode": 38000,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "โคกกว้าง",
        "amphoe": "บุ่งคล้า",
        "province": "บึงกาฬ",
        "zipcode": 38000,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "นากั้ง",
        "amphoe": "ปากคาด",
        "province": "บึงกาฬ",
        "zipcode": 38190,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "นาดง",
        "amphoe": "ปากคาด",
        "province": "บึงกาฬ",
        "zipcode": 38190,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "ปากคาด",
        "amphoe": "ปากคาด",
        "province": "บึงกาฬ",
        "zipcode": 38190,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "สมสนุก",
        "amphoe": "ปากคาด",
        "province": "บึงกาฬ",
        "zipcode": 38190,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "หนองยอง",
        "amphoe": "ปากคาด",
        "province": "บึงกาฬ",
        "zipcode": 38190,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "โนนศิลา",
        "amphoe": "ปากคาด",
        "province": "บึงกาฬ",
        "zipcode": 38190,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "ดอนหญ้านาง",
        "amphoe": "พรเจริญ",
        "province": "บึงกาฬ",
        "zipcode": 38180,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "ป่าแฝก",
        "amphoe": "พรเจริญ",
        "province": "บึงกาฬ",
        "zipcode": 38180,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "พรเจริญ",
        "amphoe": "พรเจริญ",
        "province": "บึงกาฬ",
        "zipcode": 38180,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "วังชมภู",
        "amphoe": "พรเจริญ",
        "province": "บึงกาฬ",
        "zipcode": 38180,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "ศรีชมภู",
        "amphoe": "พรเจริญ",
        "province": "บึงกาฬ",
        "zipcode": 38180,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "ศรีสำราญ",
        "amphoe": "พรเจริญ",
        "province": "บึงกาฬ",
        "zipcode": 38180,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "หนองหัวช้าง",
        "amphoe": "พรเจริญ",
        "province": "บึงกาฬ",
        "zipcode": 38180,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "ชุมภูพร",
        "amphoe": "ศรีวิไล",
        "province": "บึงกาฬ",
        "zipcode": 38210,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "นาสะแบง",
        "amphoe": "ศรีวิไล",
        "province": "บึงกาฬ",
        "zipcode": 38210,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "นาสิงห์",
        "amphoe": "ศรีวิไล",
        "province": "บึงกาฬ",
        "zipcode": 38210,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "นาแสง",
        "amphoe": "ศรีวิไล",
        "province": "บึงกาฬ",
        "zipcode": 38210,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "ศรีวิไล",
        "amphoe": "ศรีวิไล",
        "province": "บึงกาฬ",
        "zipcode": 38210,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "ซาง",
        "amphoe": "เซกา",
        "province": "บึงกาฬ",
        "zipcode": 38150,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "ท่ากกแดง",
        "amphoe": "เซกา",
        "province": "บึงกาฬ",
        "zipcode": 38150,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "ท่าสะอาด",
        "amphoe": "เซกา",
        "province": "บึงกาฬ",
        "zipcode": 38150,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "น้ำจั้น",
        "amphoe": "เซกา",
        "province": "บึงกาฬ",
        "zipcode": 38150,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "บ้านต้อง",
        "amphoe": "เซกา",
        "province": "บึงกาฬ",
        "zipcode": 38150,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "ป่งไฮ",
        "amphoe": "เซกา",
        "province": "บึงกาฬ",
        "zipcode": 38150,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "หนองทุ่ม",
        "amphoe": "เซกา",
        "province": "บึงกาฬ",
        "zipcode": 38150,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "เซกา",
        "amphoe": "เซกา",
        "province": "บึงกาฬ",
        "zipcode": 38150,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "โสกก่าม",
        "amphoe": "เซกา",
        "province": "บึงกาฬ",
        "zipcode": 38150,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "คำนาดี",
        "amphoe": "เมืองบึงกาฬ",
        "province": "บึงกาฬ",
        "zipcode": 38000,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "ชัยพร",
        "amphoe": "เมืองบึงกาฬ",
        "province": "บึงกาฬ",
        "zipcode": 38000,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "นาสวรรค์",
        "amphoe": "เมืองบึงกาฬ",
        "province": "บึงกาฬ",
        "zipcode": 38000,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "บึงกาฬ",
        "amphoe": "เมืองบึงกาฬ",
        "province": "บึงกาฬ",
        "zipcode": 38000,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "วิศิษฐ์",
        "amphoe": "เมืองบึงกาฬ",
        "province": "บึงกาฬ",
        "zipcode": 38000,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "โนนสว่าง",
        "amphoe": "เมืองบึงกาฬ",
        "province": "บึงกาฬ",
        "zipcode": 38000,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "หนองเลิง",
        "amphoe": "เมืองบึงกาฬ",
        "province": "บึงกาฬ",
        "zipcode": 38000,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "หอคำ",
        "amphoe": "เมืองบึงกาฬ",
        "province": "บึงกาฬ",
        "zipcode": 38000,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "โคกก่อง",
        "amphoe": "เมืองบึงกาฬ",
        "province": "บึงกาฬ",
        "zipcode": 38000,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "โนนสมบูรณ์",
        "amphoe": "เมืองบึงกาฬ",
        "province": "บึงกาฬ",
        "zipcode": 38000,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "โป่งเปือย",
        "amphoe": "เมืองบึงกาฬ",
        "province": "บึงกาฬ",
        "zipcode": 38000,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "ไคสี",
        "amphoe": "เมืองบึงกาฬ",
        "province": "บึงกาฬ",
        "zipcode": 38000,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "คำแก้ว",
        "amphoe": "โซ่พิสัย",
        "province": "บึงกาฬ",
        "zipcode": 38170,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "ถ้ำเจริญ",
        "amphoe": "โซ่พิสัย",
        "province": "บึงกาฬ",
        "zipcode": 38170,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "บัวตูม",
        "amphoe": "โซ่พิสัย",
        "province": "บึงกาฬ",
        "zipcode": 38170,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "ศรีชมภู",
        "amphoe": "โซ่พิสัย",
        "province": "บึงกาฬ",
        "zipcode": 38170,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "หนองพันทา",
        "amphoe": "โซ่พิสัย",
        "province": "บึงกาฬ",
        "zipcode": 38170,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "เหล่าทอง",
        "amphoe": "โซ่พิสัย",
        "province": "บึงกาฬ",
        "zipcode": 38170,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "โซ่",
        "amphoe": "โซ่พิสัย",
        "province": "บึงกาฬ",
        "zipcode": 38170,
        "district_code": false,
        "amphoe_code": false,
        "province_code": 97
    },
    {
        "district": "กระสัง",
        "amphoe": "กระสัง",
        "province": "บุรีรัมย์",
        "zipcode": 31160,
        "district_code": 310127,
        "amphoe_code": 3103,
        "province_code": 31
    },
    {
        "district": "กันทรารมย์",
        "amphoe": "กระสัง",
        "province": "บุรีรัมย์",
        "zipcode": 31160,
        "district_code": 310310,
        "amphoe_code": 3103,
        "province_code": 31
    },
    {
        "district": "ชุมแสง",
        "amphoe": "กระสัง",
        "province": "บุรีรัมย์",
        "zipcode": 31160,
        "district_code": 310307,
        "amphoe_code": 3103,
        "province_code": 31
    },
    {
        "district": "บ้านปรือ",
        "amphoe": "กระสัง",
        "province": "บุรีรัมย์",
        "zipcode": 31160,
        "district_code": 310308,
        "amphoe_code": 3103,
        "province_code": 31
    },
    {
        "district": "ลำดวน",
        "amphoe": "กระสัง",
        "province": "บุรีรัมย์",
        "zipcode": 31160,
        "district_code": 310302,
        "amphoe_code": 3103,
        "province_code": 31
    },
    {
        "district": "ศรีภูมิ",
        "amphoe": "กระสัง",
        "province": "บุรีรัมย์",
        "zipcode": 31160,
        "district_code": 310311,
        "amphoe_code": 3103,
        "province_code": 31
    },
    {
        "district": "สองชั้น",
        "amphoe": "กระสัง",
        "province": "บุรีรัมย์",
        "zipcode": 31160,
        "district_code": 310303,
        "amphoe_code": 3103,
        "province_code": 31
    },
    {
        "district": "สูงเนิน",
        "amphoe": "กระสัง",
        "province": "บุรีรัมย์",
        "zipcode": 31160,
        "district_code": 310304,
        "amphoe_code": 3103,
        "province_code": 31
    },
    {
        "district": "หนองเต็ง",
        "amphoe": "กระสัง",
        "province": "บุรีรัมย์",
        "zipcode": 31160,
        "district_code": 310305,
        "amphoe_code": 3103,
        "province_code": 31
    },
    {
        "district": "ห้วยสำราญ",
        "amphoe": "กระสัง",
        "province": "บุรีรัมย์",
        "zipcode": 31160,
        "district_code": 310309,
        "amphoe_code": 3103,
        "province_code": 31
    },
    {
        "district": "เมืองไผ่",
        "amphoe": "กระสัง",
        "province": "บุรีรัมย์",
        "zipcode": 31160,
        "district_code": 310306,
        "amphoe_code": 3103,
        "province_code": 31
    },
    {
        "district": "คูเมือง",
        "amphoe": "คูเมือง",
        "province": "บุรีรัมย์",
        "zipcode": 31190,
        "district_code": 310201,
        "amphoe_code": 3102,
        "province_code": 31
    },
    {
        "district": "ตูมใหญ่",
        "amphoe": "คูเมือง",
        "province": "บุรีรัมย์",
        "zipcode": 31190,
        "district_code": 310206,
        "amphoe_code": 3102,
        "province_code": 31
    },
    {
        "district": "บ้านแพ",
        "amphoe": "คูเมือง",
        "province": "บุรีรัมย์",
        "zipcode": 31190,
        "district_code": 310203,
        "amphoe_code": 3102,
        "province_code": 31
    },
    {
        "district": "ปะเคียบ",
        "amphoe": "คูเมือง",
        "province": "บุรีรัมย์",
        "zipcode": 31190,
        "district_code": 310198,
        "amphoe_code": 3102,
        "province_code": 31
    },
    {
        "district": "พรสำราญ",
        "amphoe": "คูเมือง",
        "province": "บุรีรัมย์",
        "zipcode": 31190,
        "district_code": 310204,
        "amphoe_code": 3102,
        "province_code": 31
    },
    {
        "district": "หนองขมาร",
        "amphoe": "คูเมือง",
        "province": "บุรีรัมย์",
        "zipcode": 31190,
        "district_code": 310207,
        "amphoe_code": 3102,
        "province_code": 31
    },
    {
        "district": "หินเหล็กไฟ",
        "amphoe": "คูเมือง",
        "province": "บุรีรัมย์",
        "zipcode": 31190,
        "district_code": 310205,
        "amphoe_code": 3102,
        "province_code": 31
    },
    {
        "district": "ชำนิ",
        "amphoe": "ชำนิ",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310404,
        "amphoe_code": 3118,
        "province_code": 31
    },
    {
        "district": "ช่อผกา",
        "amphoe": "ชำนิ",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310422,
        "amphoe_code": 3118,
        "province_code": 31
    },
    {
        "district": "ละลวด",
        "amphoe": "ชำนิ",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310423,
        "amphoe_code": 3118,
        "province_code": 31
    },
    {
        "district": "หนองปล่อง",
        "amphoe": "ชำนิ",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310407,
        "amphoe_code": 3118,
        "province_code": 31
    },
    {
        "district": "เมืองยาง",
        "amphoe": "ชำนิ",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310410,
        "amphoe_code": 3118,
        "province_code": 31
    },
    {
        "district": "โคกสนวน",
        "amphoe": "ชำนิ",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 311806,
        "amphoe_code": 3118,
        "province_code": 31
    },
    {
        "district": "ก้านเหลือง",
        "amphoe": "นางรอง",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310415,
        "amphoe_code": 3104,
        "province_code": 31
    },
    {
        "district": "ชุมแสง",
        "amphoe": "นางรอง",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310307,
        "amphoe_code": 3104,
        "province_code": 31
    },
    {
        "district": "ถนนหัก",
        "amphoe": "นางรอง",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310413,
        "amphoe_code": 3104,
        "province_code": 31
    },
    {
        "district": "ทรัพย์พระยา",
        "amphoe": "นางรอง",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310418,
        "amphoe_code": 3104,
        "province_code": 31
    },
    {
        "district": "ทุ่งแสงทอง",
        "amphoe": "นางรอง",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310426,
        "amphoe_code": 3104,
        "province_code": 31
    },
    {
        "district": "นางรอง",
        "amphoe": "นางรอง",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310401,
        "amphoe_code": 3104,
        "province_code": 31
    },
    {
        "district": "บ้านสิงห์",
        "amphoe": "นางรอง",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310416,
        "amphoe_code": 3104,
        "province_code": 31
    },
    {
        "district": "ลำไทรโยง",
        "amphoe": "นางรอง",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310417,
        "amphoe_code": 3104,
        "province_code": 31
    },
    {
        "district": "สะเดา",
        "amphoe": "นางรอง",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310403,
        "amphoe_code": 3104,
        "province_code": 31
    },
    {
        "district": "หนองกง",
        "amphoe": "นางรอง",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310408,
        "amphoe_code": 3104,
        "province_code": 31
    },
    {
        "district": "หนองยายพิมพ์",
        "amphoe": "นางรอง",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310424,
        "amphoe_code": 3104,
        "province_code": 31
    },
    {
        "district": "หนองโบสถ์",
        "amphoe": "นางรอง",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310406,
        "amphoe_code": 3104,
        "province_code": 31
    },
    {
        "district": "หนองโสน",
        "amphoe": "นางรอง",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310427,
        "amphoe_code": 3104,
        "province_code": 31
    },
    {
        "district": "หนองไทร",
        "amphoe": "นางรอง",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310414,
        "amphoe_code": 3104,
        "province_code": 31
    },
    {
        "district": "หัวถนน",
        "amphoe": "นางรอง",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310425,
        "amphoe_code": 3104,
        "province_code": 31
    },
    {
        "district": "ดอนกอก",
        "amphoe": "นาโพธิ์",
        "province": "บุรีรัมย์",
        "zipcode": 31230,
        "district_code": 311304,
        "amphoe_code": 3113,
        "province_code": 31
    },
    {
        "district": "นาโพธิ์",
        "amphoe": "นาโพธิ์",
        "province": "บุรีรัมย์",
        "zipcode": 31230,
        "district_code": 311301,
        "amphoe_code": 3113,
        "province_code": 31
    },
    {
        "district": "บ้านคู",
        "amphoe": "นาโพธิ์",
        "province": "บุรีรัมย์",
        "zipcode": 31230,
        "district_code": 311302,
        "amphoe_code": 3113,
        "province_code": 31
    },
    {
        "district": "บ้านดู่",
        "amphoe": "นาโพธิ์",
        "province": "บุรีรัมย์",
        "zipcode": 31230,
        "district_code": 311303,
        "amphoe_code": 3113,
        "province_code": 31
    },
    {
        "district": "ศรีสว่าง",
        "amphoe": "นาโพธิ์",
        "province": "บุรีรัมย์",
        "zipcode": 31230,
        "district_code": 311305,
        "amphoe_code": 3113,
        "province_code": 31
    },
    {
        "district": "จันทบเพชร",
        "amphoe": "บ้านกรวด",
        "province": "บุรีรัมย์",
        "zipcode": 31180,
        "district_code": 310808,
        "amphoe_code": 3108,
        "province_code": 31
    },
    {
        "district": "บึงเจริญ",
        "amphoe": "บ้านกรวด",
        "province": "บุรีรัมย์",
        "zipcode": 31180,
        "district_code": 310807,
        "amphoe_code": 3108,
        "province_code": 31
    },
    {
        "district": "บ้านกรวด",
        "amphoe": "บ้านกรวด",
        "province": "บุรีรัมย์",
        "zipcode": 31180,
        "district_code": 310801,
        "amphoe_code": 3108,
        "province_code": 31
    },
    {
        "district": "ปราสาท",
        "amphoe": "บ้านกรวด",
        "province": "บุรีรัมย์",
        "zipcode": 31180,
        "district_code": 310111,
        "amphoe_code": 3108,
        "province_code": 31
    },
    {
        "district": "สายตะกู",
        "amphoe": "บ้านกรวด",
        "province": "บุรีรัมย์",
        "zipcode": 31180,
        "district_code": 310805,
        "amphoe_code": 3108,
        "province_code": 31
    },
    {
        "district": "หนองไม้งาม",
        "amphoe": "บ้านกรวด",
        "province": "บุรีรัมย์",
        "zipcode": 31180,
        "district_code": 310803,
        "amphoe_code": 3108,
        "province_code": 31
    },
    {
        "district": "หินลาด",
        "amphoe": "บ้านกรวด",
        "province": "บุรีรัมย์",
        "zipcode": 31180,
        "district_code": 310806,
        "amphoe_code": 3108,
        "province_code": 31
    },
    {
        "district": "เขาดินเหนือ",
        "amphoe": "บ้านกรวด",
        "province": "บุรีรัมย์",
        "zipcode": 31180,
        "district_code": 310809,
        "amphoe_code": 3108,
        "province_code": 31
    },
    {
        "district": "โนนเจริญ",
        "amphoe": "บ้านกรวด",
        "province": "บุรีรัมย์",
        "zipcode": 31180,
        "district_code": 310802,
        "amphoe_code": 3108,
        "province_code": 31
    },
    {
        "district": "บ้านด่าน",
        "amphoe": "บ้านด่าน",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310109,
        "amphoe_code": 3121,
        "province_code": 31
    },
    {
        "district": "ปราสาท",
        "amphoe": "บ้านด่าน",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310111,
        "amphoe_code": 3121,
        "province_code": 31
    },
    {
        "district": "วังเหนือ",
        "amphoe": "บ้านด่าน",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310123,
        "amphoe_code": 3121,
        "province_code": 31
    },
    {
        "district": "โนนขวาง",
        "amphoe": "บ้านด่าน",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310115,
        "amphoe_code": 3121,
        "province_code": 31
    },
    {
        "district": "กู่สวนแตง",
        "amphoe": "บ้านใหม่ไชยพจน์",
        "province": "บุรีรัมย์",
        "zipcode": 31120,
        "district_code": 310911,
        "amphoe_code": 3119,
        "province_code": 31
    },
    {
        "district": "ทองหลาง",
        "amphoe": "บ้านใหม่ไชยพจน์",
        "province": "บุรีรัมย์",
        "zipcode": 31120,
        "district_code": 310905,
        "amphoe_code": 3119,
        "province_code": 31
    },
    {
        "district": "หนองเยือง",
        "amphoe": "บ้านใหม่ไชยพจน์",
        "province": "บุรีรัมย์",
        "zipcode": 31120,
        "district_code": 310912,
        "amphoe_code": 3119,
        "province_code": 31
    },
    {
        "district": "หนองแวง",
        "amphoe": "บ้านใหม่ไชยพจน์",
        "province": "บุรีรัมย์",
        "zipcode": 31120,
        "district_code": 310607,
        "amphoe_code": 3119,
        "province_code": 31
    },
    {
        "district": "แดงใหญ่",
        "amphoe": "บ้านใหม่ไชยพจน์",
        "province": "บุรีรัมย์",
        "zipcode": 31120,
        "district_code": 311903,
        "amphoe_code": 3119,
        "province_code": 31
    },
    {
        "district": "จรเข้มาก",
        "amphoe": "ประโคนชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31140,
        "district_code": 310706,
        "amphoe_code": 3107,
        "province_code": 31
    },
    {
        "district": "ตะโกตาพิ",
        "amphoe": "ประโคนชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31140,
        "district_code": 310714,
        "amphoe_code": 3107,
        "province_code": 31
    },
    {
        "district": "บ้านไทร",
        "amphoe": "ประโคนชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31140,
        "district_code": 310703,
        "amphoe_code": 3107,
        "province_code": 31
    },
    {
        "district": "ประทัดบุ",
        "amphoe": "ประโคนชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31140,
        "district_code": 310720,
        "amphoe_code": 3107,
        "province_code": 31
    },
    {
        "district": "ประโคนชัย",
        "amphoe": "ประโคนชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31140,
        "district_code": 310701,
        "amphoe_code": 3107,
        "province_code": 31
    },
    {
        "district": "ปังกู",
        "amphoe": "ประโคนชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31140,
        "district_code": 310707,
        "amphoe_code": 3107,
        "province_code": 31
    },
    {
        "district": "ละเวี้ย",
        "amphoe": "ประโคนชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31140,
        "district_code": 310705,
        "amphoe_code": 3107,
        "province_code": 31
    },
    {
        "district": "สี่เหลี่ยม",
        "amphoe": "ประโคนชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31140,
        "district_code": 310721,
        "amphoe_code": 3107,
        "province_code": 31
    },
    {
        "district": "หนองบอน",
        "amphoe": "ประโคนชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31140,
        "district_code": 310716,
        "amphoe_code": 3107,
        "province_code": 31
    },
    {
        "district": "เขาคอก",
        "amphoe": "ประโคนชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31140,
        "district_code": 310715,
        "amphoe_code": 3107,
        "province_code": 31
    },
    {
        "district": "แสลงโทน",
        "amphoe": "ประโคนชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31140,
        "district_code": 310702,
        "amphoe_code": 3107,
        "province_code": 31
    },
    {
        "district": "โคกตูม",
        "amphoe": "ประโคนชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31140,
        "district_code": 310719,
        "amphoe_code": 3107,
        "province_code": 31
    },
    {
        "district": "โคกมะขาม",
        "amphoe": "ประโคนชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31140,
        "district_code": 310718,
        "amphoe_code": 3107,
        "province_code": 31
    },
    {
        "district": "โคกม้า",
        "amphoe": "ประโคนชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31140,
        "district_code": 310710,
        "amphoe_code": 3107,
        "province_code": 31
    },
    {
        "district": "โคกย่าง",
        "amphoe": "ประโคนชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31140,
        "district_code": 310708,
        "amphoe_code": 3107,
        "province_code": 31
    },
    {
        "district": "ไพศาล",
        "amphoe": "ประโคนชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31140,
        "district_code": 310713,
        "amphoe_code": 3107,
        "province_code": 31
    },
    {
        "district": "ปะคำ",
        "amphoe": "ปะคำ",
        "province": "บุรีรัมย์",
        "zipcode": 31220,
        "district_code": 311201,
        "amphoe_code": 3112,
        "province_code": 31
    },
    {
        "district": "หนองบัว",
        "amphoe": "ปะคำ",
        "province": "บุรีรัมย์",
        "zipcode": 31220,
        "district_code": 311203,
        "amphoe_code": 3112,
        "province_code": 31
    },
    {
        "district": "หูทำนบ",
        "amphoe": "ปะคำ",
        "province": "บุรีรัมย์",
        "zipcode": 31220,
        "district_code": 311205,
        "amphoe_code": 3112,
        "province_code": 31
    },
    {
        "district": "โคกมะม่วง",
        "amphoe": "ปะคำ",
        "province": "บุรีรัมย์",
        "zipcode": 31220,
        "district_code": 311204,
        "amphoe_code": 3112,
        "province_code": 31
    },
    {
        "district": "ไทยเจริญ",
        "amphoe": "ปะคำ",
        "province": "บุรีรัมย์",
        "zipcode": 31220,
        "district_code": 310699,
        "amphoe_code": 3112,
        "province_code": 31
    },
    {
        "district": "จันดุม",
        "amphoe": "พลับพลาชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31250,
        "district_code": 310704,
        "amphoe_code": 3115,
        "province_code": 31
    },
    {
        "district": "ป่าชัน",
        "amphoe": "พลับพลาชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31250,
        "district_code": 310711,
        "amphoe_code": 3115,
        "province_code": 31
    },
    {
        "district": "สะเดา",
        "amphoe": "พลับพลาชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31250,
        "district_code": 310403,
        "amphoe_code": 3115,
        "province_code": 31
    },
    {
        "district": "สำโรง",
        "amphoe": "พลับพลาชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31250,
        "district_code": 310717,
        "amphoe_code": 3115,
        "province_code": 31
    },
    {
        "district": "โคกขมิ้น",
        "amphoe": "พลับพลาชัย",
        "province": "บุรีรัมย์",
        "zipcode": 31250,
        "district_code": 310709,
        "amphoe_code": 3115,
        "province_code": 31
    },
    {
        "district": "บ้านจาน",
        "amphoe": "พุทไธสง",
        "province": "บุรีรัมย์",
        "zipcode": 31120,
        "district_code": 310903,
        "amphoe_code": 3109,
        "province_code": 31
    },
    {
        "district": "บ้านยาง",
        "amphoe": "พุทไธสง",
        "province": "บุรีรัมย์",
        "zipcode": 31120,
        "district_code": 310108,
        "amphoe_code": 3109,
        "province_code": 31
    },
    {
        "district": "บ้านเป้า",
        "amphoe": "พุทไธสง",
        "province": "บุรีรัมย์",
        "zipcode": 31120,
        "district_code": 310906,
        "amphoe_code": 3109,
        "province_code": 31
    },
    {
        "district": "บ้านแวง",
        "amphoe": "พุทไธสง",
        "province": "บุรีรัมย์",
        "zipcode": 31120,
        "district_code": 310907,
        "amphoe_code": 3109,
        "province_code": 31
    },
    {
        "district": "พุทไธสง",
        "amphoe": "พุทไธสง",
        "province": "บุรีรัมย์",
        "zipcode": 31120,
        "district_code": 310901,
        "amphoe_code": 3109,
        "province_code": 31
    },
    {
        "district": "มะเฟือง",
        "amphoe": "พุทไธสง",
        "province": "บุรีรัมย์",
        "zipcode": 31120,
        "district_code": 310902,
        "amphoe_code": 3109,
        "province_code": 31
    },
    {
        "district": "หายโศก",
        "amphoe": "พุทไธสง",
        "province": "บุรีรัมย์",
        "zipcode": 31120,
        "district_code": 310910,
        "amphoe_code": 3109,
        "province_code": 31
    },
    {
        "district": "ตาจง",
        "amphoe": "ละหานทราย",
        "province": "บุรีรัมย์",
        "zipcode": 31170,
        "district_code": 310603,
        "amphoe_code": 3106,
        "province_code": 31
    },
    {
        "district": "ละหานทราย",
        "amphoe": "ละหานทราย",
        "province": "บุรีรัมย์",
        "zipcode": 31170,
        "district_code": 310601,
        "amphoe_code": 3106,
        "province_code": 31
    },
    {
        "district": "สำโรงใหม่",
        "amphoe": "ละหานทราย",
        "province": "บุรีรัมย์",
        "zipcode": 31170,
        "district_code": 310604,
        "amphoe_code": 3106,
        "province_code": 31
    },
    {
        "district": "หนองตะครอง",
        "amphoe": "ละหานทราย",
        "province": "บุรีรัมย์",
        "zipcode": 31170,
        "district_code": 310610,
        "amphoe_code": 3106,
        "province_code": 31
    },
    {
        "district": "หนองแวง",
        "amphoe": "ละหานทราย",
        "province": "บุรีรัมย์",
        "zipcode": 31170,
        "district_code": 310607,
        "amphoe_code": 3106,
        "province_code": 31
    },
    {
        "district": "โคกว่าน",
        "amphoe": "ละหานทราย",
        "province": "บุรีรัมย์",
        "zipcode": 31170,
        "district_code": 310611,
        "amphoe_code": 3106,
        "province_code": 31
    },
    {
        "district": "ตลาดโพธิ์",
        "amphoe": "ลำปลายมาศ",
        "province": "บุรีรัมย์",
        "zipcode": 31130,
        "district_code": 311005,
        "amphoe_code": 3110,
        "province_code": 31
    },
    {
        "district": "ทะเมนชัย",
        "amphoe": "ลำปลายมาศ",
        "province": "บุรีรัมย์",
        "zipcode": 31130,
        "district_code": 311004,
        "amphoe_code": 3110,
        "province_code": 31
    },
    {
        "district": "บุโพธิ์",
        "amphoe": "ลำปลายมาศ",
        "province": "บุรีรัมย์",
        "zipcode": 31130,
        "district_code": 311015,
        "amphoe_code": 3110,
        "province_code": 31
    },
    {
        "district": "บ้านยาง",
        "amphoe": "ลำปลายมาศ",
        "province": "บุรีรัมย์",
        "zipcode": 31130,
        "district_code": 310108,
        "amphoe_code": 3110,
        "province_code": 31
    },
    {
        "district": "ผไทรินทร์",
        "amphoe": "ลำปลายมาศ",
        "province": "บุรีรัมย์",
        "zipcode": 31130,
        "district_code": 311011,
        "amphoe_code": 3110,
        "province_code": 31
    },
    {
        "district": "ลำปลายมาศ",
        "amphoe": "ลำปลายมาศ",
        "province": "บุรีรัมย์",
        "zipcode": 31130,
        "district_code": 311001,
        "amphoe_code": 3110,
        "province_code": 31
    },
    {
        "district": "หนองกะทิง",
        "amphoe": "ลำปลายมาศ",
        "province": "บุรีรัมย์",
        "zipcode": 31130,
        "district_code": 311006,
        "amphoe_code": 3110,
        "province_code": 31
    },
    {
        "district": "หนองคู",
        "amphoe": "ลำปลายมาศ",
        "province": "บุรีรัมย์",
        "zipcode": 31130,
        "district_code": 311002,
        "amphoe_code": 3110,
        "province_code": 31
    },
    {
        "district": "หนองบัวโคก",
        "amphoe": "ลำปลายมาศ",
        "province": "บุรีรัมย์",
        "zipcode": 31130,
        "district_code": 311014,
        "amphoe_code": 3110,
        "province_code": 31
    },
    {
        "district": "หนองโดน",
        "amphoe": "ลำปลายมาศ",
        "province": "บุรีรัมย์",
        "zipcode": 31130,
        "district_code": 311016,
        "amphoe_code": 3110,
        "province_code": 31
    },
    {
        "district": "หินโคน",
        "amphoe": "ลำปลายมาศ",
        "province": "บุรีรัมย์",
        "zipcode": 31130,
        "district_code": 311013,
        "amphoe_code": 3110,
        "province_code": 31
    },
    {
        "district": "เมืองแฝก",
        "amphoe": "ลำปลายมาศ",
        "province": "บุรีรัมย์",
        "zipcode": 31130,
        "district_code": 311009,
        "amphoe_code": 3110,
        "province_code": 31
    },
    {
        "district": "แสลงพัน",
        "amphoe": "ลำปลายมาศ",
        "province": "บุรีรัมย์",
        "zipcode": 31130,
        "district_code": 311003,
        "amphoe_code": 3110,
        "province_code": 31
    },
    {
        "district": "โคกกลาง",
        "amphoe": "ลำปลายมาศ",
        "province": "บุรีรัมย์",
        "zipcode": 31130,
        "district_code": 311007,
        "amphoe_code": 3110,
        "province_code": 31
    },
    {
        "district": "โคกล่าม",
        "amphoe": "ลำปลายมาศ",
        "province": "บุรีรัมย์",
        "zipcode": 31130,
        "district_code": 311012,
        "amphoe_code": 3110,
        "province_code": 31
    },
    {
        "district": "โคกสะอาด",
        "amphoe": "ลำปลายมาศ",
        "province": "บุรีรัมย์",
        "zipcode": 31130,
        "district_code": 311008,
        "amphoe_code": 3110,
        "province_code": 31
    },
    {
        "district": "กระสัง",
        "amphoe": "สตึก",
        "province": "บุรีรัมย์",
        "zipcode": 31150,
        "district_code": 310127,
        "amphoe_code": 3111,
        "province_code": 31
    },
    {
        "district": "ชุมแสง",
        "amphoe": "สตึก",
        "province": "บุรีรัมย์",
        "zipcode": 31150,
        "district_code": 310307,
        "amphoe_code": 3111,
        "province_code": 31
    },
    {
        "district": "ดอนมนต์",
        "amphoe": "สตึก",
        "province": "บุรีรัมย์",
        "zipcode": 31150,
        "district_code": 311109,
        "amphoe_code": 3111,
        "province_code": 31
    },
    {
        "district": "ทุ่งวัง",
        "amphoe": "สตึก",
        "province": "บุรีรัมย์",
        "zipcode": 31150,
        "district_code": 311103,
        "amphoe_code": 3111,
        "province_code": 31
    },
    {
        "district": "ท่าม่วง",
        "amphoe": "สตึก",
        "province": "บุรีรัมย์",
        "zipcode": 31150,
        "district_code": 311111,
        "amphoe_code": 3111,
        "province_code": 31
    },
    {
        "district": "นิคม",
        "amphoe": "สตึก",
        "province": "บุรีรัมย์",
        "zipcode": 31150,
        "district_code": 311102,
        "amphoe_code": 3111,
        "province_code": 31
    },
    {
        "district": "ร่อนทอง",
        "amphoe": "สตึก",
        "province": "บุรีรัมย์",
        "zipcode": 31150,
        "district_code": 311106,
        "amphoe_code": 3111,
        "province_code": 31
    },
    {
        "district": "สตึก",
        "amphoe": "สตึก",
        "province": "บุรีรัมย์",
        "zipcode": 31150,
        "district_code": 311101,
        "amphoe_code": 3111,
        "province_code": 31
    },
    {
        "district": "สนามชัย",
        "amphoe": "สตึก",
        "province": "บุรีรัมย์",
        "zipcode": 31150,
        "district_code": 311114,
        "amphoe_code": 3111,
        "province_code": 31
    },
    {
        "district": "สะแก",
        "amphoe": "สตึก",
        "province": "บุรีรัมย์",
        "zipcode": 31150,
        "district_code": 311112,
        "amphoe_code": 3111,
        "province_code": 31
    },
    {
        "district": "หนองใหญ่",
        "amphoe": "สตึก",
        "province": "บุรีรัมย์",
        "zipcode": 31150,
        "district_code": 311105,
        "amphoe_code": 3111,
        "province_code": 31
    },
    {
        "district": "เมืองแก",
        "amphoe": "สตึก",
        "province": "บุรีรัมย์",
        "zipcode": 31150,
        "district_code": 311104,
        "amphoe_code": 3111,
        "province_code": 31
    },
    {
        "district": "ดอนอะราง",
        "amphoe": "หนองกี่",
        "province": "บุรีรัมย์",
        "zipcode": 31210,
        "district_code": 310496,
        "amphoe_code": 3105,
        "province_code": 31
    },
    {
        "district": "ทุ่งกระตาดพัฒนา",
        "amphoe": "หนองกี่",
        "province": "บุรีรัมย์",
        "zipcode": 31210,
        "district_code": 310506,
        "amphoe_code": 3105,
        "province_code": 31
    },
    {
        "district": "ทุ่งกระเต็น",
        "amphoe": "หนองกี่",
        "province": "บุรีรัมย์",
        "zipcode": 31210,
        "district_code": 310507,
        "amphoe_code": 3105,
        "province_code": 31
    },
    {
        "district": "ท่าโพธิ์ชัย",
        "amphoe": "หนองกี่",
        "province": "บุรีรัมย์",
        "zipcode": 31210,
        "district_code": 310508,
        "amphoe_code": 3105,
        "province_code": 31
    },
    {
        "district": "บุกระสัง",
        "amphoe": "หนองกี่",
        "province": "บุรีรัมย์",
        "zipcode": 31210,
        "district_code": 310510,
        "amphoe_code": 3105,
        "province_code": 31
    },
    {
        "district": "หนองกี่",
        "amphoe": "หนองกี่",
        "province": "บุรีรัมย์",
        "zipcode": 31210,
        "district_code": 310499,
        "amphoe_code": 3105,
        "province_code": 31
    },
    {
        "district": "เมืองไผ่",
        "amphoe": "หนองกี่",
        "province": "บุรีรัมย์",
        "zipcode": 31210,
        "district_code": 310306,
        "amphoe_code": 3105,
        "province_code": 31
    },
    {
        "district": "เย้ยปราสาท",
        "amphoe": "หนองกี่",
        "province": "บุรีรัมย์",
        "zipcode": 31210,
        "district_code": 310498,
        "amphoe_code": 3105,
        "province_code": 31
    },
    {
        "district": "โคกสว่าง",
        "amphoe": "หนองกี่",
        "province": "บุรีรัมย์",
        "zipcode": 31210,
        "district_code": 310505,
        "amphoe_code": 3105,
        "province_code": 31
    },
    {
        "district": "โคกสูง",
        "amphoe": "หนองกี่",
        "province": "บุรีรัมย์",
        "zipcode": 31210,
        "district_code": 310509,
        "amphoe_code": 3105,
        "province_code": 31
    },
    {
        "district": "สระทอง",
        "amphoe": "หนองหงส์",
        "province": "บุรีรัมย์",
        "zipcode": 31240,
        "district_code": 311407,
        "amphoe_code": 3114,
        "province_code": 31
    },
    {
        "district": "สระแก้ว",
        "amphoe": "หนองหงส์",
        "province": "บุรีรัมย์",
        "zipcode": 31240,
        "district_code": 311099,
        "amphoe_code": 3114,
        "province_code": 31
    },
    {
        "district": "หนองชัยศรี",
        "amphoe": "หนองหงส์",
        "province": "บุรีรัมย์",
        "zipcode": 31240,
        "district_code": 311404,
        "amphoe_code": 3114,
        "province_code": 31
    },
    {
        "district": "ห้วยหิน",
        "amphoe": "หนองหงส์",
        "province": "บุรีรัมย์",
        "zipcode": 31240,
        "district_code": 311098,
        "amphoe_code": 3114,
        "province_code": 31
    },
    {
        "district": "เมืองฝ้าย",
        "amphoe": "หนองหงส์",
        "province": "บุรีรัมย์",
        "zipcode": 31240,
        "district_code": 311406,
        "amphoe_code": 3114,
        "province_code": 31
    },
    {
        "district": "เสาเดียว",
        "amphoe": "หนองหงส์",
        "province": "บุรีรัมย์",
        "zipcode": 31240,
        "district_code": 311405,
        "amphoe_code": 3114,
        "province_code": 31
    },
    {
        "district": "ไทยสามัคคี",
        "amphoe": "หนองหงส์",
        "province": "บุรีรัมย์",
        "zipcode": 31240,
        "district_code": 311097,
        "amphoe_code": 3114,
        "province_code": 31
    },
    {
        "district": "ตาเสา",
        "amphoe": "ห้วยราช",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310116,
        "amphoe_code": 3116,
        "province_code": 31
    },
    {
        "district": "บ้านตะโก",
        "amphoe": "ห้วยราช",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310124,
        "amphoe_code": 3116,
        "province_code": 31
    },
    {
        "district": "สนวน",
        "amphoe": "ห้วยราช",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310121,
        "amphoe_code": 3116,
        "province_code": 31
    },
    {
        "district": "สามแวง",
        "amphoe": "ห้วยราช",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310110,
        "amphoe_code": 3116,
        "province_code": 31
    },
    {
        "district": "ห้วยราช",
        "amphoe": "ห้วยราช",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310107,
        "amphoe_code": 3116,
        "province_code": 31
    },
    {
        "district": "ห้วยราชา",
        "amphoe": "ห้วยราช",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 311608,
        "amphoe_code": 3116,
        "province_code": 31
    },
    {
        "district": "เมืองโพธิ์",
        "amphoe": "ห้วยราช",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 311607,
        "amphoe_code": 3116,
        "province_code": 31
    },
    {
        "district": "โคกเหล็ก",
        "amphoe": "ห้วยราช",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 311606,
        "amphoe_code": 3116,
        "province_code": 31
    },
    {
        "district": "ตาเป๊ก",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310402,
        "amphoe_code": 3123,
        "province_code": 31
    },
    {
        "district": "ถาวร",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "บุรีรัมย์",
        "zipcode": 31170,
        "district_code": 310602,
        "amphoe_code": 3123,
        "province_code": 31
    },
    {
        "district": "ยายแย้มวัฒนา",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "บุรีรัมย์",
        "zipcode": 31170,
        "district_code": 310606,
        "amphoe_code": 3123,
        "province_code": 31
    },
    {
        "district": "อีสานเขต",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310419,
        "amphoe_code": 3123,
        "province_code": 31
    },
    {
        "district": "เจริญสุข",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310411,
        "amphoe_code": 3123,
        "province_code": 31
    },
    {
        "district": "กระสัง",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310127,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "กลันทา",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310126,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "ชุมเห็ด",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310120,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "ถลุงเหล็ก",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310113,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "บัวทอง",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310119,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "บ้านบัว",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310104,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "บ้านยาง",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310108,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "พระครู",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310112,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "ลุมปุ๊ก",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310117,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "สวายจีก",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310106,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "สองห้อง",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310118,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "สะแกซำ",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310125,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "สะแกโพรง",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310105,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "หนองตาด",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310114,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "หลักเขต",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310122,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "อิสาณ",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310102,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "เมืองฝาง",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310128,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "เสม็ด",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310103,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "ในเมือง",
        "amphoe": "เมืองบุรีรัมย์",
        "province": "บุรีรัมย์",
        "zipcode": 31000,
        "district_code": 310101,
        "amphoe_code": 3101,
        "province_code": 31
    },
    {
        "district": "ดงพลอง",
        "amphoe": "แคนดง",
        "province": "บุรีรัมย์",
        "zipcode": 31150,
        "district_code": 311108,
        "amphoe_code": 3122,
        "province_code": 31
    },
    {
        "district": "สระบัว",
        "amphoe": "แคนดง",
        "province": "บุรีรัมย์",
        "zipcode": 31150,
        "district_code": 311113,
        "amphoe_code": 3122,
        "province_code": 31
    },
    {
        "district": "หัวฝาย",
        "amphoe": "แคนดง",
        "province": "บุรีรัมย์",
        "zipcode": 31150,
        "district_code": 311116,
        "amphoe_code": 3122,
        "province_code": 31
    },
    {
        "district": "แคนดง",
        "amphoe": "แคนดง",
        "province": "บุรีรัมย์",
        "zipcode": 31150,
        "district_code": 311107,
        "amphoe_code": 3122,
        "province_code": 31
    },
    {
        "district": "ลำนางรอง",
        "amphoe": "โนนดินแดง",
        "province": "บุรีรัมย์",
        "zipcode": 31260,
        "district_code": 310608,
        "amphoe_code": 3120,
        "province_code": 31
    },
    {
        "district": "ส้มป่อย",
        "amphoe": "โนนดินแดง",
        "province": "บุรีรัมย์",
        "zipcode": 31260,
        "district_code": 310609,
        "amphoe_code": 3120,
        "province_code": 31
    },
    {
        "district": "โนนดินแดง",
        "amphoe": "โนนดินแดง",
        "province": "บุรีรัมย์",
        "zipcode": 31260,
        "district_code": 310605,
        "amphoe_code": 3120,
        "province_code": 31
    },
    {
        "district": "ดงอีจาน",
        "amphoe": "โนนสุวรรณ",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310420,
        "amphoe_code": 3117,
        "province_code": 31
    },
    {
        "district": "ทุ่งจังหัน",
        "amphoe": "โนนสุวรรณ",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310409,
        "amphoe_code": 3117,
        "province_code": 31
    },
    {
        "district": "โกรกแก้ว",
        "amphoe": "โนนสุวรรณ",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310421,
        "amphoe_code": 3117,
        "province_code": 31
    },
    {
        "district": "โนนสุวรรณ",
        "amphoe": "โนนสุวรรณ",
        "province": "บุรีรัมย์",
        "zipcode": 31110,
        "district_code": 310412,
        "amphoe_code": 3117,
        "province_code": 31
    },
    {
        "district": "คลองสอง",
        "amphoe": "คลองหลวง",
        "province": "ปทุมธานี",
        "zipcode": 12120,
        "district_code": 130202,
        "amphoe_code": 1302,
        "province_code": 13
    },
    {
        "district": "คลองสาม",
        "amphoe": "คลองหลวง",
        "province": "ปทุมธานี",
        "zipcode": 12120,
        "district_code": 130203,
        "amphoe_code": 1302,
        "province_code": 13
    },
    {
        "district": "คลองสี่",
        "amphoe": "คลองหลวง",
        "province": "ปทุมธานี",
        "zipcode": 12120,
        "district_code": 130204,
        "amphoe_code": 1302,
        "province_code": 13
    },
    {
        "district": "คลองหก",
        "amphoe": "คลองหลวง",
        "province": "ปทุมธานี",
        "zipcode": 12120,
        "district_code": 130206,
        "amphoe_code": 1302,
        "province_code": 13
    },
    {
        "district": "คลองหนึ่ง",
        "amphoe": "คลองหลวง",
        "province": "ปทุมธานี",
        "zipcode": 12120,
        "district_code": 130201,
        "amphoe_code": 1302,
        "province_code": 13
    },
    {
        "district": "คลองห้า",
        "amphoe": "คลองหลวง",
        "province": "ปทุมธานี",
        "zipcode": 12120,
        "district_code": 130205,
        "amphoe_code": 1302,
        "province_code": 13
    },
    {
        "district": "คลองเจ็ด",
        "amphoe": "คลองหลวง",
        "province": "ปทุมธานี",
        "zipcode": 12120,
        "district_code": 130207,
        "amphoe_code": 1302,
        "province_code": 13
    },
    {
        "district": "บึงน้ำรักษ์",
        "amphoe": "ธัญบุรี",
        "province": "ปทุมธานี",
        "zipcode": 12110,
        "district_code": 130306,
        "amphoe_code": 1303,
        "province_code": 13
    },
    {
        "district": "บึงยี่โถ",
        "amphoe": "ธัญบุรี",
        "province": "ปทุมธานี",
        "zipcode": 12130,
        "district_code": 130302,
        "amphoe_code": 1303,
        "province_code": 13
    },
    {
        "district": "บึงสนั่น",
        "amphoe": "ธัญบุรี",
        "province": "ปทุมธานี",
        "zipcode": 12110,
        "district_code": 130305,
        "amphoe_code": 1303,
        "province_code": 13
    },
    {
        "district": "ประชาธิปัตย์",
        "amphoe": "ธัญบุรี",
        "province": "ปทุมธานี",
        "zipcode": 12130,
        "district_code": 130301,
        "amphoe_code": 1303,
        "province_code": 13
    },
    {
        "district": "รังสิต",
        "amphoe": "ธัญบุรี",
        "province": "ปทุมธานี",
        "zipcode": 12110,
        "district_code": 130303,
        "amphoe_code": 1303,
        "province_code": 13
    },
    {
        "district": "ลำผักกูด",
        "amphoe": "ธัญบุรี",
        "province": "ปทุมธานี",
        "zipcode": 12110,
        "district_code": 130304,
        "amphoe_code": 1303,
        "province_code": 13
    },
    {
        "district": "คลองพระอุดม",
        "amphoe": "ลาดหลุมแก้ว",
        "province": "ปทุมธานี",
        "zipcode": 12140,
        "district_code": 130505,
        "amphoe_code": 1305,
        "province_code": 13
    },
    {
        "district": "คูขวาง",
        "amphoe": "ลาดหลุมแก้ว",
        "province": "ปทุมธานี",
        "zipcode": 12140,
        "district_code": 130504,
        "amphoe_code": 1305,
        "province_code": 13
    },
    {
        "district": "คูบางหลวง",
        "amphoe": "ลาดหลุมแก้ว",
        "province": "ปทุมธานี",
        "zipcode": 12140,
        "district_code": 130503,
        "amphoe_code": 1305,
        "province_code": 13
    },
    {
        "district": "บ่อเงิน",
        "amphoe": "ลาดหลุมแก้ว",
        "province": "ปทุมธานี",
        "zipcode": 12140,
        "district_code": 130506,
        "amphoe_code": 1305,
        "province_code": 13
    },
    {
        "district": "ระแหง",
        "amphoe": "ลาดหลุมแก้ว",
        "province": "ปทุมธานี",
        "zipcode": 12140,
        "district_code": 130501,
        "amphoe_code": 1305,
        "province_code": 13
    },
    {
        "district": "ลาดหลุมแก้ว",
        "amphoe": "ลาดหลุมแก้ว",
        "province": "ปทุมธานี",
        "zipcode": 12140,
        "district_code": 130502,
        "amphoe_code": 1305,
        "province_code": 13
    },
    {
        "district": "หน้าไม้",
        "amphoe": "ลาดหลุมแก้ว",
        "province": "ปทุมธานี",
        "zipcode": 12140,
        "district_code": 130507,
        "amphoe_code": 1305,
        "province_code": 13
    },
    {
        "district": "คูคต",
        "amphoe": "ลำลูกกา",
        "province": "ปทุมธานี",
        "zipcode": 12130,
        "district_code": 130601,
        "amphoe_code": 1306,
        "province_code": 13
    },
    {
        "district": "บึงคอไห",
        "amphoe": "ลำลูกกา",
        "province": "ปทุมธานี",
        "zipcode": 12150,
        "district_code": 130607,
        "amphoe_code": 1306,
        "province_code": 13
    },
    {
        "district": "บึงคำพร้อย",
        "amphoe": "ลำลูกกา",
        "province": "ปทุมธานี",
        "zipcode": 12150,
        "district_code": 130603,
        "amphoe_code": 1306,
        "province_code": 13
    },
    {
        "district": "บึงทองหลาง",
        "amphoe": "ลำลูกกา",
        "province": "ปทุมธานี",
        "zipcode": 12150,
        "district_code": 130605,
        "amphoe_code": 1306,
        "province_code": 13
    },
    {
        "district": "พืชอุดม",
        "amphoe": "ลำลูกกา",
        "province": "ปทุมธานี",
        "zipcode": 12150,
        "district_code": 130608,
        "amphoe_code": 1306,
        "province_code": 13
    },
    {
        "district": "ลาดสวาย",
        "amphoe": "ลำลูกกา",
        "province": "ปทุมธานี",
        "zipcode": 12150,
        "district_code": 130602,
        "amphoe_code": 1306,
        "province_code": 13
    },
    {
        "district": "ลำลูกกา",
        "amphoe": "ลำลูกกา",
        "province": "ปทุมธานี",
        "zipcode": 12150,
        "district_code": 130604,
        "amphoe_code": 1306,
        "province_code": 13
    },
    {
        "district": "ลำไทร",
        "amphoe": "ลำลูกกา",
        "province": "ปทุมธานี",
        "zipcode": 12150,
        "district_code": 130606,
        "amphoe_code": 1306,
        "province_code": 13
    },
    {
        "district": "กระแชง",
        "amphoe": "สามโคก",
        "province": "ปทุมธานี",
        "zipcode": 12160,
        "district_code": 130704,
        "amphoe_code": 1307,
        "province_code": 13
    },
    {
        "district": "คลองควาย",
        "amphoe": "สามโคก",
        "province": "ปทุมธานี",
        "zipcode": 12160,
        "district_code": 130702,
        "amphoe_code": 1307,
        "province_code": 13
    },
    {
        "district": "ท้ายเกาะ",
        "amphoe": "สามโคก",
        "province": "ปทุมธานี",
        "zipcode": 12160,
        "district_code": 130711,
        "amphoe_code": 1307,
        "province_code": 13
    },
    {
        "district": "บางกระบือ",
        "amphoe": "สามโคก",
        "province": "ปทุมธานี",
        "zipcode": 12160,
        "district_code": 130710,
        "amphoe_code": 1307,
        "province_code": 13
    },
    {
        "district": "บางเตย",
        "amphoe": "สามโคก",
        "province": "ปทุมธานี",
        "zipcode": 12160,
        "district_code": 130701,
        "amphoe_code": 1307,
        "province_code": 13
    },
    {
        "district": "บางโพธิ์เหนือ",
        "amphoe": "สามโคก",
        "province": "ปทุมธานี",
        "zipcode": 12160,
        "district_code": 130705,
        "amphoe_code": 1307,
        "province_code": 13
    },
    {
        "district": "บ้านงิ้ว",
        "amphoe": "สามโคก",
        "province": "ปทุมธานี",
        "zipcode": 12160,
        "district_code": 130708,
        "amphoe_code": 1307,
        "province_code": 13
    },
    {
        "district": "บ้านปทุม",
        "amphoe": "สามโคก",
        "province": "ปทุมธานี",
        "zipcode": 12160,
        "district_code": 130707,
        "amphoe_code": 1307,
        "province_code": 13
    },
    {
        "district": "สามโคก",
        "amphoe": "สามโคก",
        "province": "ปทุมธานี",
        "zipcode": 12160,
        "district_code": 130703,
        "amphoe_code": 1307,
        "province_code": 13
    },
    {
        "district": "เชียงรากน้อย",
        "amphoe": "สามโคก",
        "province": "ปทุมธานี",
        "zipcode": 12160,
        "district_code": 130709,
        "amphoe_code": 1307,
        "province_code": 13
    },
    {
        "district": "เชียงรากใหญ่",
        "amphoe": "สามโคก",
        "province": "ปทุมธานี",
        "zipcode": 12160,
        "district_code": 130706,
        "amphoe_code": 1307,
        "province_code": 13
    },
    {
        "district": "นพรัตน์",
        "amphoe": "หนองเสือ",
        "province": "ปทุมธานี",
        "zipcode": 12170,
        "district_code": 130407,
        "amphoe_code": 1304,
        "province_code": 13
    },
    {
        "district": "บึงกาสาม",
        "amphoe": "หนองเสือ",
        "province": "ปทุมธานี",
        "zipcode": 12170,
        "district_code": 130403,
        "amphoe_code": 1304,
        "province_code": 13
    },
    {
        "district": "บึงชำอ้อ",
        "amphoe": "หนองเสือ",
        "province": "ปทุมธานี",
        "zipcode": 12170,
        "district_code": 130404,
        "amphoe_code": 1304,
        "province_code": 13
    },
    {
        "district": "บึงบอน",
        "amphoe": "หนองเสือ",
        "province": "ปทุมธานี",
        "zipcode": 12170,
        "district_code": 130402,
        "amphoe_code": 1304,
        "province_code": 13
    },
    {
        "district": "บึงบา",
        "amphoe": "หนองเสือ",
        "province": "ปทุมธานี",
        "zipcode": 12170,
        "district_code": 130401,
        "amphoe_code": 1304,
        "province_code": 13
    },
    {
        "district": "ศาลาครุ",
        "amphoe": "หนองเสือ",
        "province": "ปทุมธานี",
        "zipcode": 12170,
        "district_code": 130406,
        "amphoe_code": 1304,
        "province_code": 13
    },
    {
        "district": "หนองสามวัง",
        "amphoe": "หนองเสือ",
        "province": "ปทุมธานี",
        "zipcode": 12170,
        "district_code": 130405,
        "amphoe_code": 1304,
        "province_code": 13
    },
    {
        "district": "บางกะดี",
        "amphoe": "เมืองปทุมธานี",
        "province": "ปทุมธานี",
        "zipcode": 12000,
        "district_code": 130112,
        "amphoe_code": 1301,
        "province_code": 13
    },
    {
        "district": "บางขะแยง",
        "amphoe": "เมืองปทุมธานี",
        "province": "ปทุมธานี",
        "zipcode": 12000,
        "district_code": 130106,
        "amphoe_code": 1301,
        "province_code": 13
    },
    {
        "district": "บางคูวัด",
        "amphoe": "เมืองปทุมธานี",
        "province": "ปทุมธานี",
        "zipcode": 12000,
        "district_code": 130107,
        "amphoe_code": 1301,
        "province_code": 13
    },
    {
        "district": "บางปรอก",
        "amphoe": "เมืองปทุมธานี",
        "province": "ปทุมธานี",
        "zipcode": 12000,
        "district_code": 130101,
        "amphoe_code": 1301,
        "province_code": 13
    },
    {
        "district": "บางพูด",
        "amphoe": "เมืองปทุมธานี",
        "province": "ปทุมธานี",
        "zipcode": 12000,
        "district_code": 130110,
        "amphoe_code": 1301,
        "province_code": 13
    },
    {
        "district": "บางพูน",
        "amphoe": "เมืองปทุมธานี",
        "province": "ปทุมธานี",
        "zipcode": 12000,
        "district_code": 130111,
        "amphoe_code": 1301,
        "province_code": 13
    },
    {
        "district": "บางหลวง",
        "amphoe": "เมืองปทุมธานี",
        "province": "ปทุมธานี",
        "zipcode": 12000,
        "district_code": 130108,
        "amphoe_code": 1301,
        "province_code": 13
    },
    {
        "district": "บางเดื่อ",
        "amphoe": "เมืองปทุมธานี",
        "province": "ปทุมธานี",
        "zipcode": 12000,
        "district_code": 130109,
        "amphoe_code": 1301,
        "province_code": 13
    },
    {
        "district": "บ้านกระแชง",
        "amphoe": "เมืองปทุมธานี",
        "province": "ปทุมธานี",
        "zipcode": 12000,
        "district_code": 130105,
        "amphoe_code": 1301,
        "province_code": 13
    },
    {
        "district": "บ้านกลาง",
        "amphoe": "เมืองปทุมธานี",
        "province": "ปทุมธานี",
        "zipcode": 12000,
        "district_code": 130103,
        "amphoe_code": 1301,
        "province_code": 13
    },
    {
        "district": "บ้านฉาง",
        "amphoe": "เมืองปทุมธานี",
        "province": "ปทุมธานี",
        "zipcode": 12000,
        "district_code": 130104,
        "amphoe_code": 1301,
        "province_code": 13
    },
    {
        "district": "บ้านใหม่",
        "amphoe": "เมืองปทุมธานี",
        "province": "ปทุมธานี",
        "zipcode": 12000,
        "district_code": 130102,
        "amphoe_code": 1301,
        "province_code": 13
    },
    {
        "district": "สวนพริกไทย",
        "amphoe": "เมืองปทุมธานี",
        "province": "ปทุมธานี",
        "zipcode": 12000,
        "district_code": 130113,
        "amphoe_code": 1301,
        "province_code": 13
    },
    {
        "district": "หลักหก",
        "amphoe": "เมืองปทุมธานี",
        "province": "ปทุมธานี",
        "zipcode": 12000,
        "district_code": 130114,
        "amphoe_code": 1301,
        "province_code": 13
    },
    {
        "district": "กุยบุรี",
        "amphoe": "กุยบุรี",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77150,
        "district_code": 770201,
        "amphoe_code": 7702,
        "province_code": 77
    },
    {
        "district": "กุยเหนือ",
        "amphoe": "กุยบุรี",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77150,
        "district_code": 770202,
        "amphoe_code": 7702,
        "province_code": 77
    },
    {
        "district": "ดอนยายหนู",
        "amphoe": "กุยบุรี",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77150,
        "district_code": 770204,
        "amphoe_code": 7702,
        "province_code": 77
    },
    {
        "district": "สามกระทาย",
        "amphoe": "กุยบุรี",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77150,
        "district_code": 770206,
        "amphoe_code": 7702,
        "province_code": 77
    },
    {
        "district": "หาดขาม",
        "amphoe": "กุยบุรี",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77150,
        "district_code": 770207,
        "amphoe_code": 7702,
        "province_code": 77
    },
    {
        "district": "เขาแดง",
        "amphoe": "กุยบุรี",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77150,
        "district_code": 770203,
        "amphoe_code": 7702,
        "province_code": 77
    },
    {
        "district": "ทับสะแก",
        "amphoe": "ทับสะแก",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77130,
        "district_code": 770301,
        "amphoe_code": 7703,
        "province_code": 77
    },
    {
        "district": "นาหูกวาง",
        "amphoe": "ทับสะแก",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77130,
        "district_code": 770303,
        "amphoe_code": 7703,
        "province_code": 77
    },
    {
        "district": "ห้วยยาง",
        "amphoe": "ทับสะแก",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77130,
        "district_code": 770305,
        "amphoe_code": 7703,
        "province_code": 77
    },
    {
        "district": "อ่างทอง",
        "amphoe": "ทับสะแก",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77130,
        "district_code": 770302,
        "amphoe_code": 7703,
        "province_code": 77
    },
    {
        "district": "เขาล้าน",
        "amphoe": "ทับสะแก",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77130,
        "district_code": 770304,
        "amphoe_code": 7703,
        "province_code": 77
    },
    {
        "district": "แสงอรุณ",
        "amphoe": "ทับสะแก",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77130,
        "district_code": 770306,
        "amphoe_code": 7703,
        "province_code": 77
    },
    {
        "district": "กำเนิดนพคุณ",
        "amphoe": "บางสะพาน",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77140,
        "district_code": 770401,
        "amphoe_code": 7704,
        "province_code": 77
    },
    {
        "district": "ชัยเกษม",
        "amphoe": "บางสะพาน",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77190,
        "district_code": 770405,
        "amphoe_code": 7704,
        "province_code": 77
    },
    {
        "district": "ทองมงคล",
        "amphoe": "บางสะพาน",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77230,
        "district_code": 770406,
        "amphoe_code": 7704,
        "province_code": 77
    },
    {
        "district": "ธงชัย",
        "amphoe": "บางสะพาน",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77190,
        "district_code": 770404,
        "amphoe_code": 7704,
        "province_code": 77
    },
    {
        "district": "พงศ์ประศาสน์",
        "amphoe": "บางสะพาน",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77140,
        "district_code": 770402,
        "amphoe_code": 7704,
        "province_code": 77
    },
    {
        "district": "ร่อนทอง",
        "amphoe": "บางสะพาน",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77230,
        "district_code": 770403,
        "amphoe_code": 7704,
        "province_code": 77
    },
    {
        "district": "แม่รำพึง",
        "amphoe": "บางสะพาน",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77140,
        "district_code": 770407,
        "amphoe_code": 7704,
        "province_code": 77
    },
    {
        "district": "ช้างแรก",
        "amphoe": "บางสะพานน้อย",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77170,
        "district_code": 770504,
        "amphoe_code": 7705,
        "province_code": 77
    },
    {
        "district": "ทรายทอง",
        "amphoe": "บางสะพานน้อย",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77170,
        "district_code": 770503,
        "amphoe_code": 7705,
        "province_code": 77
    },
    {
        "district": "บางสะพาน",
        "amphoe": "บางสะพานน้อย",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77170,
        "district_code": 770502,
        "amphoe_code": 7705,
        "province_code": 77
    },
    {
        "district": "ปากแพรก",
        "amphoe": "บางสะพานน้อย",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77170,
        "district_code": 770501,
        "amphoe_code": 7705,
        "province_code": 77
    },
    {
        "district": "ไชยราช",
        "amphoe": "บางสะพานน้อย",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77170,
        "district_code": 770505,
        "amphoe_code": 7705,
        "province_code": 77
    },
    {
        "district": "ปราณบุรี",
        "amphoe": "ปราณบุรี",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77120,
        "district_code": 770601,
        "amphoe_code": 7706,
        "province_code": 77
    },
    {
        "district": "ปราณบุรี",
        "amphoe": "ปราณบุรี",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77160,
        "district_code": 770601,
        "amphoe_code": 7706,
        "province_code": 77
    },
    {
        "district": "ปากน้ำปราณ",
        "amphoe": "ปราณบุรี",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77220,
        "district_code": 770604,
        "amphoe_code": 7706,
        "province_code": 77
    },
    {
        "district": "วังก์พง",
        "amphoe": "ปราณบุรี",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77120,
        "district_code": 770608,
        "amphoe_code": 7706,
        "province_code": 77
    },
    {
        "district": "วังก์พง",
        "amphoe": "ปราณบุรี",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77160,
        "district_code": 770608,
        "amphoe_code": 7706,
        "province_code": 77
    },
    {
        "district": "หนองตาแต้ม",
        "amphoe": "ปราณบุรี",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77120,
        "district_code": 770607,
        "amphoe_code": 7706,
        "province_code": 77
    },
    {
        "district": "หนองตาแต้ม",
        "amphoe": "ปราณบุรี",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77160,
        "district_code": 770607,
        "amphoe_code": 7706,
        "province_code": 77
    },
    {
        "district": "เขาจ้าว",
        "amphoe": "ปราณบุรี",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77120,
        "district_code": 770609,
        "amphoe_code": 7706,
        "province_code": 77
    },
    {
        "district": "เขาจ้าว",
        "amphoe": "ปราณบุรี",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77160,
        "district_code": 770609,
        "amphoe_code": 7706,
        "province_code": 77
    },
    {
        "district": "เขาน้อย",
        "amphoe": "ปราณบุรี",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77120,
        "district_code": 770602,
        "amphoe_code": 7706,
        "province_code": 77
    },
    {
        "district": "เขาน้อย",
        "amphoe": "ปราณบุรี",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77160,
        "district_code": 770602,
        "amphoe_code": 7706,
        "province_code": 77
    },
    {
        "district": "ศาลาลัย",
        "amphoe": "สามร้อยยอด",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77180,
        "district_code": 770804,
        "amphoe_code": 7708,
        "province_code": 77
    },
    {
        "district": "ศิลาลอย",
        "amphoe": "สามร้อยยอด",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77180,
        "district_code": 770603,
        "amphoe_code": 7708,
        "province_code": 77
    },
    {
        "district": "สามร้อยยอด",
        "amphoe": "สามร้อยยอด",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77120,
        "district_code": 770605,
        "amphoe_code": 7708,
        "province_code": 77
    },
    {
        "district": "สามร้อยยอด",
        "amphoe": "สามร้อยยอด",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77160,
        "district_code": 770605,
        "amphoe_code": 7708,
        "province_code": 77
    },
    {
        "district": "ไร่เก่า",
        "amphoe": "สามร้อยยอด",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77180,
        "district_code": 770606,
        "amphoe_code": 7708,
        "province_code": 77
    },
    {
        "district": "ไร่ใหม่",
        "amphoe": "สามร้อยยอด",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77180,
        "district_code": 770205,
        "amphoe_code": 7708,
        "province_code": 77
    },
    {
        "district": "ทับใต้",
        "amphoe": "หัวหิน",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77110,
        "district_code": 770705,
        "amphoe_code": 7707,
        "province_code": 77
    },
    {
        "district": "บึงนคร",
        "amphoe": "หัวหิน",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77110,
        "district_code": 770707,
        "amphoe_code": 7707,
        "province_code": 77
    },
    {
        "district": "หนองพลับ",
        "amphoe": "หัวหิน",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77110,
        "district_code": 770704,
        "amphoe_code": 7707,
        "province_code": 77
    },
    {
        "district": "หนองแก",
        "amphoe": "หัวหิน",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77110,
        "district_code": 770702,
        "amphoe_code": 7707,
        "province_code": 77
    },
    {
        "district": "หัวหิน",
        "amphoe": "หัวหิน",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77110,
        "district_code": 770701,
        "amphoe_code": 7707,
        "province_code": 77
    },
    {
        "district": "หินเหล็กไฟ",
        "amphoe": "หัวหิน",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77110,
        "district_code": 770703,
        "amphoe_code": 7707,
        "province_code": 77
    },
    {
        "district": "ห้วยสัตว์ใหญ่",
        "amphoe": "หัวหิน",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77110,
        "district_code": 770706,
        "amphoe_code": 7707,
        "province_code": 77
    },
    {
        "district": "คลองวาฬ",
        "amphoe": "เมืองประจวบคีรีขันธ์",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77000,
        "district_code": 770103,
        "amphoe_code": 7701,
        "province_code": 77
    },
    {
        "district": "บ่อนอก",
        "amphoe": "เมืองประจวบคีรีขันธ์",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77210,
        "district_code": 770106,
        "amphoe_code": 7701,
        "province_code": 77
    },
    {
        "district": "ประจวบคีรีขันธ์",
        "amphoe": "เมืองประจวบคีรีขันธ์",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77000,
        "district_code": 770101,
        "amphoe_code": 7701,
        "province_code": 77
    },
    {
        "district": "ห้วยทราย",
        "amphoe": "เมืองประจวบคีรีขันธ์",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77000,
        "district_code": 770104,
        "amphoe_code": 7701,
        "province_code": 77
    },
    {
        "district": "อ่าวน้อย",
        "amphoe": "เมืองประจวบคีรีขันธ์",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77210,
        "district_code": 770105,
        "amphoe_code": 7701,
        "province_code": 77
    },
    {
        "district": "เกาะหลัก",
        "amphoe": "เมืองประจวบคีรีขันธ์",
        "province": "ประจวบคีรีขันธ์",
        "zipcode": 77000,
        "district_code": 770102,
        "amphoe_code": 7701,
        "province_code": 77
    },
    {
        "district": "กบินทร์",
        "amphoe": "กบินทร์บุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25110,
        "district_code": 250201,
        "amphoe_code": 2502,
        "province_code": 25
    },
    {
        "district": "นนทรี",
        "amphoe": "กบินทร์บุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25110,
        "district_code": 250204,
        "amphoe_code": 2502,
        "province_code": 25
    },
    {
        "district": "นาแขม",
        "amphoe": "กบินทร์บุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25110,
        "district_code": 250212,
        "amphoe_code": 2502,
        "province_code": 25
    },
    {
        "district": "บ่อทอง",
        "amphoe": "กบินทร์บุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25110,
        "district_code": 250210,
        "amphoe_code": 2502,
        "province_code": 25
    },
    {
        "district": "บ้านนา",
        "amphoe": "กบินทร์บุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25110,
        "district_code": 250209,
        "amphoe_code": 2502,
        "province_code": 25
    },
    {
        "district": "ย่านรี",
        "amphoe": "กบินทร์บุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25110,
        "district_code": 250205,
        "amphoe_code": 2502,
        "province_code": 25
    },
    {
        "district": "ลาดตะเคียน",
        "amphoe": "กบินทร์บุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25110,
        "district_code": 250208,
        "amphoe_code": 2502,
        "province_code": 25
    },
    {
        "district": "วังดาล",
        "amphoe": "กบินทร์บุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25110,
        "district_code": 250203,
        "amphoe_code": 2502,
        "province_code": 25
    },
    {
        "district": "วังตะเคียน",
        "amphoe": "กบินทร์บุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25110,
        "district_code": 250206,
        "amphoe_code": 2502,
        "province_code": 25
    },
    {
        "district": "วังท่าช้าง",
        "amphoe": "กบินทร์บุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25110,
        "district_code": 250214,
        "amphoe_code": 2502,
        "province_code": 25
    },
    {
        "district": "หนองกี่",
        "amphoe": "กบินทร์บุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25110,
        "district_code": 250211,
        "amphoe_code": 2502,
        "province_code": 25
    },
    {
        "district": "หาดนางแก้ว",
        "amphoe": "กบินทร์บุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25110,
        "district_code": 250207,
        "amphoe_code": 2502,
        "province_code": 25
    },
    {
        "district": "เขาไม้แก้ว",
        "amphoe": "กบินทร์บุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25110,
        "district_code": 250213,
        "amphoe_code": 2502,
        "province_code": 25
    },
    {
        "district": "เมืองเก่า",
        "amphoe": "กบินทร์บุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25240,
        "district_code": 250202,
        "amphoe_code": 2502,
        "province_code": 25
    },
    {
        "district": "ทุ่งโพธิ์",
        "amphoe": "นาดี",
        "province": "ปราจีนบุรี",
        "zipcode": 25220,
        "district_code": 250299,
        "amphoe_code": 2503,
        "province_code": 25
    },
    {
        "district": "นาดี",
        "amphoe": "นาดี",
        "province": "ปราจีนบุรี",
        "zipcode": 25220,
        "district_code": 250297,
        "amphoe_code": 2503,
        "province_code": 25
    },
    {
        "district": "บุพราหมณ์",
        "amphoe": "นาดี",
        "province": "ปราจีนบุรี",
        "zipcode": 25220,
        "district_code": 250306,
        "amphoe_code": 2503,
        "province_code": 25
    },
    {
        "district": "สะพานหิน",
        "amphoe": "นาดี",
        "province": "ปราจีนบุรี",
        "zipcode": 25220,
        "district_code": 250296,
        "amphoe_code": 2503,
        "province_code": 25
    },
    {
        "district": "สำพันตา",
        "amphoe": "นาดี",
        "province": "ปราจีนบุรี",
        "zipcode": 25220,
        "district_code": 250302,
        "amphoe_code": 2503,
        "province_code": 25
    },
    {
        "district": "แก่งดินสอ",
        "amphoe": "นาดี",
        "province": "ปราจีนบุรี",
        "zipcode": 25220,
        "district_code": 250305,
        "amphoe_code": 2503,
        "province_code": 25
    },
    {
        "district": "กระทุ่มแพ้ว",
        "amphoe": "บ้านสร้าง",
        "province": "ปราจีนบุรี",
        "zipcode": 25150,
        "district_code": 250609,
        "amphoe_code": 2506,
        "province_code": 25
    },
    {
        "district": "บางกระเบา",
        "amphoe": "บ้านสร้าง",
        "province": "ปราจีนบุรี",
        "zipcode": 25150,
        "district_code": 250602,
        "amphoe_code": 2506,
        "province_code": 25
    },
    {
        "district": "บางขาม",
        "amphoe": "บ้านสร้าง",
        "province": "ปราจีนบุรี",
        "zipcode": 25150,
        "district_code": 250608,
        "amphoe_code": 2506,
        "province_code": 25
    },
    {
        "district": "บางปลาร้า",
        "amphoe": "บ้านสร้าง",
        "province": "ปราจีนบุรี",
        "zipcode": 25150,
        "district_code": 250607,
        "amphoe_code": 2506,
        "province_code": 25
    },
    {
        "district": "บางพลวง",
        "amphoe": "บ้านสร้าง",
        "province": "ปราจีนบุรี",
        "zipcode": 25150,
        "district_code": 250606,
        "amphoe_code": 2506,
        "province_code": 25
    },
    {
        "district": "บางยาง",
        "amphoe": "บ้านสร้าง",
        "province": "ปราจีนบุรี",
        "zipcode": 25150,
        "district_code": 250604,
        "amphoe_code": 2506,
        "province_code": 25
    },
    {
        "district": "บางเตย",
        "amphoe": "บ้านสร้าง",
        "province": "ปราจีนบุรี",
        "zipcode": 25150,
        "district_code": 250603,
        "amphoe_code": 2506,
        "province_code": 25
    },
    {
        "district": "บางแตน",
        "amphoe": "บ้านสร้าง",
        "province": "ปราจีนบุรี",
        "zipcode": 25150,
        "district_code": 250605,
        "amphoe_code": 2506,
        "province_code": 25
    },
    {
        "district": "บ้านสร้าง",
        "amphoe": "บ้านสร้าง",
        "province": "ปราจีนบุรี",
        "zipcode": 25150,
        "district_code": 250601,
        "amphoe_code": 2506,
        "province_code": 25
    },
    {
        "district": "คำโตนด",
        "amphoe": "ประจันตคาม",
        "province": "ปราจีนบุรี",
        "zipcode": 25130,
        "district_code": 250706,
        "amphoe_code": 2507,
        "province_code": 25
    },
    {
        "district": "ดงบัง",
        "amphoe": "ประจันตคาม",
        "province": "ปราจีนบุรี",
        "zipcode": 25130,
        "district_code": 250705,
        "amphoe_code": 2507,
        "province_code": 25
    },
    {
        "district": "บุฝ้าย",
        "amphoe": "ประจันตคาม",
        "province": "ปราจีนบุรี",
        "zipcode": 25130,
        "district_code": 250707,
        "amphoe_code": 2507,
        "province_code": 25
    },
    {
        "district": "บ้านหอย",
        "amphoe": "ประจันตคาม",
        "province": "ปราจีนบุรี",
        "zipcode": 25130,
        "district_code": 250703,
        "amphoe_code": 2507,
        "province_code": 25
    },
    {
        "district": "ประจันตคาม",
        "amphoe": "ประจันตคาม",
        "province": "ปราจีนบุรี",
        "zipcode": 25130,
        "district_code": 250701,
        "amphoe_code": 2507,
        "province_code": 25
    },
    {
        "district": "หนองแก้ว",
        "amphoe": "ประจันตคาม",
        "province": "ปราจีนบุรี",
        "zipcode": 25130,
        "district_code": 250708,
        "amphoe_code": 2507,
        "province_code": 25
    },
    {
        "district": "หนองแสง",
        "amphoe": "ประจันตคาม",
        "province": "ปราจีนบุรี",
        "zipcode": 25130,
        "district_code": 250704,
        "amphoe_code": 2507,
        "province_code": 25
    },
    {
        "district": "เกาะลอย",
        "amphoe": "ประจันตคาม",
        "province": "ปราจีนบุรี",
        "zipcode": 25130,
        "district_code": 250702,
        "amphoe_code": 2507,
        "province_code": 25
    },
    {
        "district": "โพธิ์งาม",
        "amphoe": "ประจันตคาม",
        "province": "ปราจีนบุรี",
        "zipcode": 25130,
        "district_code": 250709,
        "amphoe_code": 2507,
        "province_code": 25
    },
    {
        "district": "กรอกสมบูรณ์",
        "amphoe": "ศรีมหาโพธิ",
        "province": "ปราจีนบุรี",
        "zipcode": 25140,
        "district_code": 250810,
        "amphoe_code": 2508,
        "province_code": 25
    },
    {
        "district": "ดงกระทงยาม",
        "amphoe": "ศรีมหาโพธิ",
        "province": "ปราจีนบุรี",
        "zipcode": 25140,
        "district_code": 250806,
        "amphoe_code": 2508,
        "province_code": 25
    },
    {
        "district": "ท่าตูม",
        "amphoe": "ศรีมหาโพธิ",
        "province": "ปราจีนบุรี",
        "zipcode": 25140,
        "district_code": 250804,
        "amphoe_code": 2508,
        "province_code": 25
    },
    {
        "district": "บางกุ้ง",
        "amphoe": "ศรีมหาโพธิ",
        "province": "ปราจีนบุรี",
        "zipcode": 25140,
        "district_code": 250805,
        "amphoe_code": 2508,
        "province_code": 25
    },
    {
        "district": "บ้านทาม",
        "amphoe": "ศรีมหาโพธิ",
        "province": "ปราจีนบุรี",
        "zipcode": 25140,
        "district_code": 250803,
        "amphoe_code": 2508,
        "province_code": 25
    },
    {
        "district": "ศรีมหาโพธิ",
        "amphoe": "ศรีมหาโพธิ",
        "province": "ปราจีนบุรี",
        "zipcode": 25140,
        "district_code": 250801,
        "amphoe_code": 2508,
        "province_code": 25
    },
    {
        "district": "สัมพันธ์",
        "amphoe": "ศรีมหาโพธิ",
        "province": "ปราจีนบุรี",
        "zipcode": 25140,
        "district_code": 250802,
        "amphoe_code": 2508,
        "province_code": 25
    },
    {
        "district": "หนองโพรง",
        "amphoe": "ศรีมหาโพธิ",
        "province": "ปราจีนบุรี",
        "zipcode": 25140,
        "district_code": 250807,
        "amphoe_code": 2508,
        "province_code": 25
    },
    {
        "district": "หัวหว้า",
        "amphoe": "ศรีมหาโพธิ",
        "province": "ปราจีนบุรี",
        "zipcode": 25140,
        "district_code": 250808,
        "amphoe_code": 2508,
        "province_code": 25
    },
    {
        "district": "หาดยาง",
        "amphoe": "ศรีมหาโพธิ",
        "province": "ปราจีนบุรี",
        "zipcode": 25140,
        "district_code": 250809,
        "amphoe_code": 2508,
        "province_code": 25
    },
    {
        "district": "คู้ลำพัน",
        "amphoe": "ศรีมโหสถ",
        "province": "ปราจีนบุรี",
        "zipcode": 25190,
        "district_code": 250896,
        "amphoe_code": 2509,
        "province_code": 25
    },
    {
        "district": "โคกปีบ",
        "amphoe": "ศรีมโหสถ",
        "province": "ปราจีนบุรี",
        "zipcode": 25190,
        "district_code": 250897,
        "amphoe_code": 2509,
        "province_code": 25
    },
    {
        "district": "โคกไทย",
        "amphoe": "ศรีมโหสถ",
        "province": "ปราจีนบุรี",
        "zipcode": 25190,
        "district_code": 250898,
        "amphoe_code": 2509,
        "province_code": 25
    },
    {
        "district": "ไผ่ชะเลือด",
        "amphoe": "ศรีมโหสถ",
        "province": "ปราจีนบุรี",
        "zipcode": 25190,
        "district_code": 250899,
        "amphoe_code": 2509,
        "province_code": 25
    },
    {
        "district": "ดงขี้เหล็ก",
        "amphoe": "เมืองปราจีนบุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25000,
        "district_code": 250111,
        "amphoe_code": 2501,
        "province_code": 25
    },
    {
        "district": "ดงพระราม",
        "amphoe": "เมืองปราจีนบุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25000,
        "district_code": 250107,
        "amphoe_code": 2501,
        "province_code": 25
    },
    {
        "district": "ท่างาม",
        "amphoe": "เมืองปราจีนบุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25000,
        "district_code": 250105,
        "amphoe_code": 2501,
        "province_code": 25
    },
    {
        "district": "บางบริบูรณ์",
        "amphoe": "เมืองปราจีนบุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25000,
        "district_code": 250106,
        "amphoe_code": 2501,
        "province_code": 25
    },
    {
        "district": "บางเดชะ",
        "amphoe": "เมืองปราจีนบุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25000,
        "district_code": 250104,
        "amphoe_code": 2501,
        "province_code": 25
    },
    {
        "district": "บ้านพระ",
        "amphoe": "เมืองปราจีนบุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25230,
        "district_code": 250108,
        "amphoe_code": 2501,
        "province_code": 25
    },
    {
        "district": "รอบเมือง",
        "amphoe": "เมืองปราจีนบุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25000,
        "district_code": 250102,
        "amphoe_code": 2501,
        "province_code": 25
    },
    {
        "district": "วัดโบสถ์",
        "amphoe": "เมืองปราจีนบุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25000,
        "district_code": 250103,
        "amphoe_code": 2501,
        "province_code": 25
    },
    {
        "district": "หน้าเมือง",
        "amphoe": "เมืองปราจีนบุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25000,
        "district_code": 250101,
        "amphoe_code": 2501,
        "province_code": 25
    },
    {
        "district": "เนินหอม",
        "amphoe": "เมืองปราจีนบุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25230,
        "district_code": 250112,
        "amphoe_code": 2501,
        "province_code": 25
    },
    {
        "district": "โคกไม้ลาย",
        "amphoe": "เมืองปราจีนบุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25230,
        "district_code": 250109,
        "amphoe_code": 2501,
        "province_code": 25
    },
    {
        "district": "โนนห้อม",
        "amphoe": "เมืองปราจีนบุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25000,
        "district_code": 250113,
        "amphoe_code": 2501,
        "province_code": 25
    },
    {
        "district": "ไม้เค็ด",
        "amphoe": "เมืองปราจีนบุรี",
        "province": "ปราจีนบุรี",
        "zipcode": 25230,
        "district_code": 250110,
        "amphoe_code": 2501,
        "province_code": 25
    },
    {
        "district": "กะรุบี",
        "amphoe": "กะพ้อ",
        "province": "ปัตตานี",
        "zipcode": 94230,
        "district_code": 941101,
        "amphoe_code": 9411,
        "province_code": 94
    },
    {
        "district": "ตะโละดือรามัน",
        "amphoe": "กะพ้อ",
        "province": "ปัตตานี",
        "zipcode": 94230,
        "district_code": 941102,
        "amphoe_code": 9411,
        "province_code": 94
    },
    {
        "district": "ปล่องหอย",
        "amphoe": "กะพ้อ",
        "province": "ปัตตานี",
        "zipcode": 94230,
        "district_code": 941103,
        "amphoe_code": 9411,
        "province_code": 94
    },
    {
        "district": "ตะโละแมะนา",
        "amphoe": "ทุ่งยางแดง",
        "province": "ปัตตานี",
        "zipcode": 94140,
        "district_code": 940601,
        "amphoe_code": 9406,
        "province_code": 94
    },
    {
        "district": "น้ำดำ",
        "amphoe": "ทุ่งยางแดง",
        "province": "ปัตตานี",
        "zipcode": 94140,
        "district_code": 940603,
        "amphoe_code": 9406,
        "province_code": 94
    },
    {
        "district": "ปากู",
        "amphoe": "ทุ่งยางแดง",
        "province": "ปัตตานี",
        "zipcode": 94140,
        "district_code": 940604,
        "amphoe_code": 9406,
        "province_code": 94
    },
    {
        "district": "พิเทน",
        "amphoe": "ทุ่งยางแดง",
        "province": "ปัตตานี",
        "zipcode": 94140,
        "district_code": 940602,
        "amphoe_code": 9406,
        "province_code": 94
    },
    {
        "district": "ควน",
        "amphoe": "ปะนาเระ",
        "province": "ปัตตานี",
        "zipcode": 94190,
        "district_code": 940405,
        "amphoe_code": 9404,
        "province_code": 94
    },
    {
        "district": "คอกกระบือ",
        "amphoe": "ปะนาเระ",
        "province": "ปัตตานี",
        "zipcode": 94130,
        "district_code": 940407,
        "amphoe_code": 9404,
        "province_code": 94
    },
    {
        "district": "ดอน",
        "amphoe": "ปะนาเระ",
        "province": "ปัตตานี",
        "zipcode": 94190,
        "district_code": 940404,
        "amphoe_code": 9404,
        "province_code": 94
    },
    {
        "district": "ท่าข้าม",
        "amphoe": "ปะนาเระ",
        "province": "ปัตตานี",
        "zipcode": 94130,
        "district_code": 940402,
        "amphoe_code": 9404,
        "province_code": 94
    },
    {
        "district": "ท่าน้ำ",
        "amphoe": "ปะนาเระ",
        "province": "ปัตตานี",
        "zipcode": 94130,
        "district_code": 940406,
        "amphoe_code": 9404,
        "province_code": 94
    },
    {
        "district": "บ้านกลาง",
        "amphoe": "ปะนาเระ",
        "province": "ปัตตานี",
        "zipcode": 94130,
        "district_code": 940409,
        "amphoe_code": 9404,
        "province_code": 94
    },
    {
        "district": "บ้านนอก",
        "amphoe": "ปะนาเระ",
        "province": "ปัตตานี",
        "zipcode": 94130,
        "district_code": 940403,
        "amphoe_code": 9404,
        "province_code": 94
    },
    {
        "district": "บ้านน้ำบ่อ",
        "amphoe": "ปะนาเระ",
        "province": "ปัตตานี",
        "zipcode": 94130,
        "district_code": 940410,
        "amphoe_code": 9404,
        "province_code": 94
    },
    {
        "district": "ปะนาเระ",
        "amphoe": "ปะนาเระ",
        "province": "ปัตตานี",
        "zipcode": 94130,
        "district_code": 940401,
        "amphoe_code": 9404,
        "province_code": 94
    },
    {
        "district": "พ่อมิ่ง",
        "amphoe": "ปะนาเระ",
        "province": "ปัตตานี",
        "zipcode": 94130,
        "district_code": 940408,
        "amphoe_code": 9404,
        "province_code": 94
    },
    {
        "district": "กระหวะ",
        "amphoe": "มายอ",
        "province": "ปัตตานี",
        "zipcode": 94190,
        "district_code": 940504,
        "amphoe_code": 9405,
        "province_code": 94
    },
    {
        "district": "กระเสาะ",
        "amphoe": "มายอ",
        "province": "ปัตตานี",
        "zipcode": 94140,
        "district_code": 940507,
        "amphoe_code": 9405,
        "province_code": 94
    },
    {
        "district": "ตรัง",
        "amphoe": "มายอ",
        "province": "ปัตตานี",
        "zipcode": 94140,
        "district_code": 940503,
        "amphoe_code": 9405,
        "province_code": 94
    },
    {
        "district": "ถนน",
        "amphoe": "มายอ",
        "province": "ปัตตานี",
        "zipcode": 94140,
        "district_code": 940502,
        "amphoe_code": 9405,
        "province_code": 94
    },
    {
        "district": "ปะโด",
        "amphoe": "มายอ",
        "province": "ปัตตานี",
        "zipcode": 94140,
        "district_code": 940509,
        "amphoe_code": 9405,
        "province_code": 94
    },
    {
        "district": "ปานัน",
        "amphoe": "มายอ",
        "province": "ปัตตานี",
        "zipcode": 94140,
        "district_code": 940513,
        "amphoe_code": 9405,
        "province_code": 94
    },
    {
        "district": "มายอ",
        "amphoe": "มายอ",
        "province": "ปัตตานี",
        "zipcode": 94140,
        "district_code": 940501,
        "amphoe_code": 9405,
        "province_code": 94
    },
    {
        "district": "ลางา",
        "amphoe": "มายอ",
        "province": "ปัตตานี",
        "zipcode": 94190,
        "district_code": 940506,
        "amphoe_code": 9405,
        "province_code": 94
    },
    {
        "district": "ลุโบะยิไร",
        "amphoe": "มายอ",
        "province": "ปัตตานี",
        "zipcode": 94140,
        "district_code": 940505,
        "amphoe_code": 9405,
        "province_code": 94
    },
    {
        "district": "สะกำ",
        "amphoe": "มายอ",
        "province": "ปัตตานี",
        "zipcode": 94140,
        "district_code": 940512,
        "amphoe_code": 9405,
        "province_code": 94
    },
    {
        "district": "สาคอบน",
        "amphoe": "มายอ",
        "province": "ปัตตานี",
        "zipcode": 94140,
        "district_code": 940510,
        "amphoe_code": 9405,
        "province_code": 94
    },
    {
        "district": "สาคอใต้",
        "amphoe": "มายอ",
        "province": "ปัตตานี",
        "zipcode": 94140,
        "district_code": 940511,
        "amphoe_code": 9405,
        "province_code": 94
    },
    {
        "district": "เกาะจัน",
        "amphoe": "มายอ",
        "province": "ปัตตานี",
        "zipcode": 94140,
        "district_code": 940508,
        "amphoe_code": 9405,
        "province_code": 94
    },
    {
        "district": "กระโด",
        "amphoe": "ยะรัง",
        "province": "ปัตตานี",
        "zipcode": 94160,
        "district_code": 941008,
        "amphoe_code": 9410,
        "province_code": 94
    },
    {
        "district": "กอลำ",
        "amphoe": "ยะรัง",
        "province": "ปัตตานี",
        "zipcode": 94160,
        "district_code": 941011,
        "amphoe_code": 9410,
        "province_code": 94
    },
    {
        "district": "คลองใหม่",
        "amphoe": "ยะรัง",
        "province": "ปัตตานี",
        "zipcode": 94160,
        "district_code": 941009,
        "amphoe_code": 9410,
        "province_code": 94
    },
    {
        "district": "ประจัน",
        "amphoe": "ยะรัง",
        "province": "ปัตตานี",
        "zipcode": 94160,
        "district_code": 941003,
        "amphoe_code": 9410,
        "province_code": 94
    },
    {
        "district": "ปิตูมุดี",
        "amphoe": "ยะรัง",
        "province": "ปัตตานี",
        "zipcode": 94160,
        "district_code": 941006,
        "amphoe_code": 9410,
        "province_code": 94
    },
    {
        "district": "ยะรัง",
        "amphoe": "ยะรัง",
        "province": "ปัตตานี",
        "zipcode": 94160,
        "district_code": 941001,
        "amphoe_code": 9410,
        "province_code": 94
    },
    {
        "district": "ระแว้ง",
        "amphoe": "ยะรัง",
        "province": "ปัตตานี",
        "zipcode": 94160,
        "district_code": 941005,
        "amphoe_code": 9410,
        "province_code": 94
    },
    {
        "district": "วัด",
        "amphoe": "ยะรัง",
        "province": "ปัตตานี",
        "zipcode": 94160,
        "district_code": 941007,
        "amphoe_code": 9410,
        "province_code": 94
    },
    {
        "district": "สะดาวา",
        "amphoe": "ยะรัง",
        "province": "ปัตตานี",
        "zipcode": 94160,
        "district_code": 941002,
        "amphoe_code": 9410,
        "province_code": 94
    },
    {
        "district": "สะนอ",
        "amphoe": "ยะรัง",
        "province": "ปัตตานี",
        "zipcode": 94160,
        "district_code": 941004,
        "amphoe_code": 9410,
        "province_code": 94
    },
    {
        "district": "เขาตูม",
        "amphoe": "ยะรัง",
        "province": "ปัตตานี",
        "zipcode": 94160,
        "district_code": 941012,
        "amphoe_code": 9410,
        "province_code": 94
    },
    {
        "district": "เมาะมาวี",
        "amphoe": "ยะรัง",
        "province": "ปัตตานี",
        "zipcode": 94160,
        "district_code": 941010,
        "amphoe_code": 9410,
        "province_code": 94
    },
    {
        "district": "จะรัง",
        "amphoe": "ยะหริ่ง",
        "province": "ปัตตานี",
        "zipcode": 94150,
        "district_code": 940917,
        "amphoe_code": 9409,
        "province_code": 94
    },
    {
        "district": "ตอหลัง",
        "amphoe": "ยะหริ่ง",
        "province": "ปัตตานี",
        "zipcode": 94150,
        "district_code": 940905,
        "amphoe_code": 9409,
        "province_code": 94
    },
    {
        "district": "ตะโละ",
        "amphoe": "ยะหริ่ง",
        "province": "ปัตตานี",
        "zipcode": 94150,
        "district_code": 940901,
        "amphoe_code": 9409,
        "province_code": 94
    },
    {
        "district": "ตะโละกาโปร์",
        "amphoe": "ยะหริ่ง",
        "province": "ปัตตานี",
        "zipcode": 94150,
        "district_code": 940902,
        "amphoe_code": 9409,
        "province_code": 94
    },
    {
        "district": "ตันหยงจึงงา",
        "amphoe": "ยะหริ่ง",
        "province": "ปัตตานี",
        "zipcode": 94190,
        "district_code": 940904,
        "amphoe_code": 9409,
        "province_code": 94
    },
    {
        "district": "ตันหยงดาลอ",
        "amphoe": "ยะหริ่ง",
        "province": "ปัตตานี",
        "zipcode": 94150,
        "district_code": 940903,
        "amphoe_code": 9409,
        "province_code": 94
    },
    {
        "district": "ตาลีอายร์",
        "amphoe": "ยะหริ่ง",
        "province": "ปัตตานี",
        "zipcode": 94150,
        "district_code": 940907,
        "amphoe_code": 9409,
        "province_code": 94
    },
    {
        "district": "ตาแกะ",
        "amphoe": "ยะหริ่ง",
        "province": "ปัตตานี",
        "zipcode": 94150,
        "district_code": 940906,
        "amphoe_code": 9409,
        "province_code": 94
    },
    {
        "district": "บางปู",
        "amphoe": "ยะหริ่ง",
        "province": "ปัตตานี",
        "zipcode": 94150,
        "district_code": 940909,
        "amphoe_code": 9409,
        "province_code": 94
    },
    {
        "district": "บาโลย",
        "amphoe": "ยะหริ่ง",
        "province": "ปัตตานี",
        "zipcode": 94190,
        "district_code": 940913,
        "amphoe_code": 9409,
        "province_code": 94
    },
    {
        "district": "ปิยามุมัง",
        "amphoe": "ยะหริ่ง",
        "province": "ปัตตานี",
        "zipcode": 94150,
        "district_code": 940911,
        "amphoe_code": 9409,
        "province_code": 94
    },
    {
        "district": "ปุลากง",
        "amphoe": "ยะหริ่ง",
        "province": "ปัตตานี",
        "zipcode": 94150,
        "district_code": 940912,
        "amphoe_code": 9409,
        "province_code": 94
    },
    {
        "district": "มะนังยง",
        "amphoe": "ยะหริ่ง",
        "province": "ปัตตานี",
        "zipcode": 94150,
        "district_code": 940915,
        "amphoe_code": 9409,
        "province_code": 94
    },
    {
        "district": "ยามู",
        "amphoe": "ยะหริ่ง",
        "province": "ปัตตานี",
        "zipcode": 94150,
        "district_code": 940908,
        "amphoe_code": 9409,
        "province_code": 94
    },
    {
        "district": "ราตาปันยัง",
        "amphoe": "ยะหริ่ง",
        "province": "ปัตตานี",
        "zipcode": 94150,
        "district_code": 940916,
        "amphoe_code": 9409,
        "province_code": 94
    },
    {
        "district": "สาบัน",
        "amphoe": "ยะหริ่ง",
        "province": "ปัตตานี",
        "zipcode": 94150,
        "district_code": 940914,
        "amphoe_code": 9409,
        "province_code": 94
    },
    {
        "district": "หนองแรต",
        "amphoe": "ยะหริ่ง",
        "province": "ปัตตานี",
        "zipcode": 94150,
        "district_code": 940910,
        "amphoe_code": 9409,
        "province_code": 94
    },
    {
        "district": "แหลมโพธิ์",
        "amphoe": "ยะหริ่ง",
        "province": "ปัตตานี",
        "zipcode": 94150,
        "district_code": 940918,
        "amphoe_code": 9409,
        "province_code": 94
    },
    {
        "district": "กะดุนง",
        "amphoe": "สายบุรี",
        "province": "ปัตตานี",
        "zipcode": 94110,
        "district_code": 940707,
        "amphoe_code": 9407,
        "province_code": 94
    },
    {
        "district": "ตะบิ้ง",
        "amphoe": "สายบุรี",
        "province": "ปัตตานี",
        "zipcode": 94110,
        "district_code": 940702,
        "amphoe_code": 9407,
        "province_code": 94
    },
    {
        "district": "ตะลุบัน",
        "amphoe": "สายบุรี",
        "province": "ปัตตานี",
        "zipcode": 94110,
        "district_code": 940701,
        "amphoe_code": 9407,
        "province_code": 94
    },
    {
        "district": "ทุ่งคล้า",
        "amphoe": "สายบุรี",
        "province": "ปัตตานี",
        "zipcode": 94190,
        "district_code": 940711,
        "amphoe_code": 9407,
        "province_code": 94
    },
    {
        "district": "บางเก่า",
        "amphoe": "สายบุรี",
        "province": "ปัตตานี",
        "zipcode": 94110,
        "district_code": 940704,
        "amphoe_code": 9407,
        "province_code": 94
    },
    {
        "district": "บือเระ",
        "amphoe": "สายบุรี",
        "province": "ปัตตานี",
        "zipcode": 94110,
        "district_code": 940705,
        "amphoe_code": 9407,
        "province_code": 94
    },
    {
        "district": "ปะเสยะวอ",
        "amphoe": "สายบุรี",
        "province": "ปัตตานี",
        "zipcode": 94110,
        "district_code": 940703,
        "amphoe_code": 9407,
        "province_code": 94
    },
    {
        "district": "มะนังดาลำ",
        "amphoe": "สายบุรี",
        "province": "ปัตตานี",
        "zipcode": 94110,
        "district_code": 940709,
        "amphoe_code": 9407,
        "province_code": 94
    },
    {
        "district": "ละหาร",
        "amphoe": "สายบุรี",
        "province": "ปัตตานี",
        "zipcode": 94110,
        "district_code": 940708,
        "amphoe_code": 9407,
        "province_code": 94
    },
    {
        "district": "เตราะบอน",
        "amphoe": "สายบุรี",
        "province": "ปัตตานี",
        "zipcode": 94110,
        "district_code": 940706,
        "amphoe_code": 9407,
        "province_code": 94
    },
    {
        "district": "แป้น",
        "amphoe": "สายบุรี",
        "province": "ปัตตานี",
        "zipcode": 94110,
        "district_code": 940710,
        "amphoe_code": 9407,
        "province_code": 94
    },
    {
        "district": "คอลอตันหยง",
        "amphoe": "หนองจิก",
        "province": "ปัตตานี",
        "zipcode": 94170,
        "district_code": 940302,
        "amphoe_code": 9403,
        "province_code": 94
    },
    {
        "district": "ดอนรัก",
        "amphoe": "หนองจิก",
        "province": "ปัตตานี",
        "zipcode": 94170,
        "district_code": 940303,
        "amphoe_code": 9403,
        "province_code": 94
    },
    {
        "district": "ดาโต๊ะ",
        "amphoe": "หนองจิก",
        "province": "ปัตตานี",
        "zipcode": 94170,
        "district_code": 940304,
        "amphoe_code": 9403,
        "province_code": 94
    },
    {
        "district": "ตุยง",
        "amphoe": "หนองจิก",
        "province": "ปัตตานี",
        "zipcode": 94170,
        "district_code": 940305,
        "amphoe_code": 9403,
        "province_code": 94
    },
    {
        "district": "ท่ากำชำ",
        "amphoe": "หนองจิก",
        "province": "ปัตตานี",
        "zipcode": 94170,
        "district_code": 940306,
        "amphoe_code": 9403,
        "province_code": 94
    },
    {
        "district": "บางตาวา",
        "amphoe": "หนองจิก",
        "province": "ปัตตานี",
        "zipcode": 94170,
        "district_code": 940309,
        "amphoe_code": 9403,
        "province_code": 94
    },
    {
        "district": "บางเขา",
        "amphoe": "หนองจิก",
        "province": "ปัตตานี",
        "zipcode": 94170,
        "district_code": 940308,
        "amphoe_code": 9403,
        "province_code": 94
    },
    {
        "district": "บ่อทอง",
        "amphoe": "หนองจิก",
        "province": "ปัตตานี",
        "zipcode": 94170,
        "district_code": 940307,
        "amphoe_code": 9403,
        "province_code": 94
    },
    {
        "district": "ปุโละปุโย",
        "amphoe": "หนองจิก",
        "province": "ปัตตานี",
        "zipcode": 94170,
        "district_code": 940310,
        "amphoe_code": 9403,
        "province_code": 94
    },
    {
        "district": "ยาบี",
        "amphoe": "หนองจิก",
        "province": "ปัตตานี",
        "zipcode": 94170,
        "district_code": 940311,
        "amphoe_code": 9403,
        "province_code": 94
    },
    {
        "district": "ลิปะสะโง",
        "amphoe": "หนองจิก",
        "province": "ปัตตานี",
        "zipcode": 94170,
        "district_code": 940312,
        "amphoe_code": 9403,
        "province_code": 94
    },
    {
        "district": "เกาะเปาะ",
        "amphoe": "หนองจิก",
        "province": "ปัตตานี",
        "zipcode": 94170,
        "district_code": 940301,
        "amphoe_code": 9403,
        "province_code": 94
    },
    {
        "district": "กะมิยอ",
        "amphoe": "เมืองปัตตานี",
        "province": "ปัตตานี",
        "zipcode": 94000,
        "district_code": 940107,
        "amphoe_code": 9401,
        "province_code": 94
    },
    {
        "district": "คลองมานิง",
        "amphoe": "เมืองปัตตานี",
        "province": "ปัตตานี",
        "zipcode": 94000,
        "district_code": 940106,
        "amphoe_code": 9401,
        "province_code": 94
    },
    {
        "district": "จะบังติกอ",
        "amphoe": "เมืองปัตตานี",
        "province": "ปัตตานี",
        "zipcode": 94000,
        "district_code": 940103,
        "amphoe_code": 9401,
        "province_code": 94
    },
    {
        "district": "ตะลุโบะ",
        "amphoe": "เมืองปัตตานี",
        "province": "ปัตตานี",
        "zipcode": 94000,
        "district_code": 940111,
        "amphoe_code": 9401,
        "province_code": 94
    },
    {
        "district": "ตันหยงลุโละ",
        "amphoe": "เมืองปัตตานี",
        "province": "ปัตตานี",
        "zipcode": 94000,
        "district_code": 940105,
        "amphoe_code": 9401,
        "province_code": 94
    },
    {
        "district": "บานา",
        "amphoe": "เมืองปัตตานี",
        "province": "ปัตตานี",
        "zipcode": 94000,
        "district_code": 940104,
        "amphoe_code": 9401,
        "province_code": 94
    },
    {
        "district": "บาราเฮาะ",
        "amphoe": "เมืองปัตตานี",
        "province": "ปัตตานี",
        "zipcode": 94000,
        "district_code": 940112,
        "amphoe_code": 9401,
        "province_code": 94
    },
    {
        "district": "บาราโหม",
        "amphoe": "เมืองปัตตานี",
        "province": "ปัตตานี",
        "zipcode": 94000,
        "district_code": 940108,
        "amphoe_code": 9401,
        "province_code": 94
    },
    {
        "district": "ปะกาฮะรัง",
        "amphoe": "เมืองปัตตานี",
        "province": "ปัตตานี",
        "zipcode": 94000,
        "district_code": 940109,
        "amphoe_code": 9401,
        "province_code": 94
    },
    {
        "district": "ปุยุด",
        "amphoe": "เมืองปัตตานี",
        "province": "ปัตตานี",
        "zipcode": 94000,
        "district_code": 940113,
        "amphoe_code": 9401,
        "province_code": 94
    },
    {
        "district": "รูสะมิแล",
        "amphoe": "เมืองปัตตานี",
        "province": "ปัตตานี",
        "zipcode": 94000,
        "district_code": 940110,
        "amphoe_code": 9401,
        "province_code": 94
    },
    {
        "district": "สะบารัง",
        "amphoe": "เมืองปัตตานี",
        "province": "ปัตตานี",
        "zipcode": 94000,
        "district_code": 940101,
        "amphoe_code": 9401,
        "province_code": 94
    },
    {
        "district": "อาเนาะรู",
        "amphoe": "เมืองปัตตานี",
        "province": "ปัตตานี",
        "zipcode": 94000,
        "district_code": 940102,
        "amphoe_code": 9401,
        "province_code": 94
    },
    {
        "district": "ป่าไร่",
        "amphoe": "แม่ลาน",
        "province": "ปัตตานี",
        "zipcode": 94180,
        "district_code": 940210,
        "amphoe_code": 9412,
        "province_code": 94
    },
    {
        "district": "ม่วงเตี้ย",
        "amphoe": "แม่ลาน",
        "province": "ปัตตานี",
        "zipcode": 94180,
        "district_code": 940212,
        "amphoe_code": 9412,
        "province_code": 94
    },
    {
        "district": "แม่ลาน",
        "amphoe": "แม่ลาน",
        "province": "ปัตตานี",
        "zipcode": 94180,
        "district_code": 940209,
        "amphoe_code": 9412,
        "province_code": 94
    },
    {
        "district": "ควนโนรี",
        "amphoe": "โคกโพธิ์",
        "province": "ปัตตานี",
        "zipcode": 94180,
        "district_code": 940214,
        "amphoe_code": 9402,
        "province_code": 94
    },
    {
        "district": "ช้างให้ตก",
        "amphoe": "โคกโพธิ์",
        "province": "ปัตตานี",
        "zipcode": 94120,
        "district_code": 940215,
        "amphoe_code": 9402,
        "province_code": 94
    },
    {
        "district": "ทรายขาว",
        "amphoe": "โคกโพธิ์",
        "province": "ปัตตานี",
        "zipcode": 94120,
        "district_code": 940205,
        "amphoe_code": 9402,
        "province_code": 94
    },
    {
        "district": "ทุ่งพลา",
        "amphoe": "โคกโพธิ์",
        "province": "ปัตตานี",
        "zipcode": 94180,
        "district_code": 940208,
        "amphoe_code": 9402,
        "province_code": 94
    },
    {
        "district": "ท่าเรือ",
        "amphoe": "โคกโพธิ์",
        "province": "ปัตตานี",
        "zipcode": 94120,
        "district_code": 940211,
        "amphoe_code": 9402,
        "province_code": 94
    },
    {
        "district": "นาประดู่",
        "amphoe": "โคกโพธิ์",
        "province": "ปัตตานี",
        "zipcode": 94180,
        "district_code": 940206,
        "amphoe_code": 9402,
        "province_code": 94
    },
    {
        "district": "นาเกตุ",
        "amphoe": "โคกโพธิ์",
        "province": "ปัตตานี",
        "zipcode": 94120,
        "district_code": 940213,
        "amphoe_code": 9402,
        "province_code": 94
    },
    {
        "district": "บางโกระ",
        "amphoe": "โคกโพธิ์",
        "province": "ปัตตานี",
        "zipcode": 94120,
        "district_code": 940203,
        "amphoe_code": 9402,
        "province_code": 94
    },
    {
        "district": "ปากล่อ",
        "amphoe": "โคกโพธิ์",
        "province": "ปัตตานี",
        "zipcode": 94180,
        "district_code": 940207,
        "amphoe_code": 9402,
        "province_code": 94
    },
    {
        "district": "ป่าบอน",
        "amphoe": "โคกโพธิ์",
        "province": "ปัตตานี",
        "zipcode": 94120,
        "district_code": 940204,
        "amphoe_code": 9402,
        "province_code": 94
    },
    {
        "district": "มะกรูด",
        "amphoe": "โคกโพธิ์",
        "province": "ปัตตานี",
        "zipcode": 94120,
        "district_code": 940202,
        "amphoe_code": 9402,
        "province_code": 94
    },
    {
        "district": "โคกโพธิ์",
        "amphoe": "โคกโพธิ์",
        "province": "ปัตตานี",
        "zipcode": 94120,
        "district_code": 940201,
        "amphoe_code": 9402,
        "province_code": 94
    },
    {
        "district": "ดอนทราย",
        "amphoe": "ไม้แก่น",
        "province": "ปัตตานี",
        "zipcode": 94220,
        "district_code": 940804,
        "amphoe_code": 9408,
        "province_code": 94
    },
    {
        "district": "ตะโละไกรทอง",
        "amphoe": "ไม้แก่น",
        "province": "ปัตตานี",
        "zipcode": 94220,
        "district_code": 940803,
        "amphoe_code": 9408,
        "province_code": 94
    },
    {
        "district": "ไทรทอง",
        "amphoe": "ไม้แก่น",
        "province": "ปัตตานี",
        "zipcode": 94220,
        "district_code": 940801,
        "amphoe_code": 9408,
        "province_code": 94
    },
    {
        "district": "ไม้แก่น",
        "amphoe": "ไม้แก่น",
        "province": "ปัตตานี",
        "zipcode": 94220,
        "district_code": 940802,
        "amphoe_code": 9408,
        "province_code": 94
    },
    {
        "district": "จำปา",
        "amphoe": "ท่าเรือ",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13130,
        "district_code": 140199,
        "amphoe_code": 1402,
        "province_code": 14
    },
    {
        "district": "ท่าหลวง",
        "amphoe": "ท่าเรือ",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 18270,
        "district_code": 140203,
        "amphoe_code": 1402,
        "province_code": 14
    },
    {
        "district": "ท่าเจ้าสนุก",
        "amphoe": "ท่าเรือ",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13130,
        "district_code": 140210,
        "amphoe_code": 1402,
        "province_code": 14
    },
    {
        "district": "ท่าเรือ",
        "amphoe": "ท่าเรือ",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13130,
        "district_code": 140201,
        "amphoe_code": 1402,
        "province_code": 14
    },
    {
        "district": "บ้านร่อม",
        "amphoe": "ท่าเรือ",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13130,
        "district_code": 140204,
        "amphoe_code": 1402,
        "province_code": 14
    },
    {
        "district": "ปากท่า",
        "amphoe": "ท่าเรือ",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13130,
        "district_code": 140208,
        "amphoe_code": 1402,
        "province_code": 14
    },
    {
        "district": "วังแดง",
        "amphoe": "ท่าเรือ",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13130,
        "district_code": 140206,
        "amphoe_code": 1402,
        "province_code": 14
    },
    {
        "district": "ศาลาลอย",
        "amphoe": "ท่าเรือ",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13130,
        "district_code": 140205,
        "amphoe_code": 1402,
        "province_code": 14
    },
    {
        "district": "หนองขนาก",
        "amphoe": "ท่าเรือ",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13130,
        "district_code": 140209,
        "amphoe_code": 1402,
        "province_code": 14
    },
    {
        "district": "โพธิ์เอน",
        "amphoe": "ท่าเรือ",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13130,
        "district_code": 140207,
        "amphoe_code": 1402,
        "province_code": 14
    },
    {
        "district": "คลองสะแก",
        "amphoe": "นครหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13260,
        "district_code": 140310,
        "amphoe_code": 1403,
        "province_code": 14
    },
    {
        "district": "ท่าช้าง",
        "amphoe": "นครหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13260,
        "district_code": 140302,
        "amphoe_code": 1403,
        "province_code": 14
    },
    {
        "district": "นครหลวง",
        "amphoe": "นครหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13260,
        "district_code": 140301,
        "amphoe_code": 1403,
        "province_code": 14
    },
    {
        "district": "บางพระครู",
        "amphoe": "นครหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13260,
        "district_code": 140307,
        "amphoe_code": 1403,
        "province_code": 14
    },
    {
        "district": "บางระกำ",
        "amphoe": "นครหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13260,
        "district_code": 140306,
        "amphoe_code": 1403,
        "province_code": 14
    },
    {
        "district": "บ่อโพง",
        "amphoe": "นครหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13260,
        "district_code": 140303,
        "amphoe_code": 1403,
        "province_code": 14
    },
    {
        "district": "บ้านชุ้ง",
        "amphoe": "นครหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13260,
        "district_code": 140304,
        "amphoe_code": 1403,
        "province_code": 14
    },
    {
        "district": "ปากจั่น",
        "amphoe": "นครหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13260,
        "district_code": 140305,
        "amphoe_code": 1403,
        "province_code": 14
    },
    {
        "district": "พระนอน",
        "amphoe": "นครหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13260,
        "district_code": 140312,
        "amphoe_code": 1403,
        "province_code": 14
    },
    {
        "district": "สามไถ",
        "amphoe": "นครหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13260,
        "district_code": 140311,
        "amphoe_code": 1403,
        "province_code": 14
    },
    {
        "district": "หนองปลิง",
        "amphoe": "นครหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13260,
        "district_code": 140309,
        "amphoe_code": 1403,
        "province_code": 14
    },
    {
        "district": "แม่ลา",
        "amphoe": "นครหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13260,
        "district_code": 140308,
        "amphoe_code": 1403,
        "province_code": 14
    },
    {
        "district": "บางซ้าย",
        "amphoe": "บางซ้าย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13270,
        "district_code": 141301,
        "amphoe_code": 1413,
        "province_code": 14
    },
    {
        "district": "ปลายกลัด",
        "amphoe": "บางซ้าย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13270,
        "district_code": 141304,
        "amphoe_code": 1413,
        "province_code": 14
    },
    {
        "district": "วังพัฒนา",
        "amphoe": "บางซ้าย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13270,
        "district_code": 141306,
        "amphoe_code": 1413,
        "province_code": 14
    },
    {
        "district": "เต่าเล่า",
        "amphoe": "บางซ้าย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13270,
        "district_code": 141303,
        "amphoe_code": 1413,
        "province_code": 14
    },
    {
        "district": "เทพมงคล",
        "amphoe": "บางซ้าย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13270,
        "district_code": 141305,
        "amphoe_code": 1413,
        "province_code": 14
    },
    {
        "district": "แก้วฟ้า",
        "amphoe": "บางซ้าย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13270,
        "district_code": 141302,
        "amphoe_code": 1413,
        "province_code": 14
    },
    {
        "district": "กบเจา",
        "amphoe": "บางบาล",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13250,
        "district_code": 140506,
        "amphoe_code": 1405,
        "province_code": 14
    },
    {
        "district": "ทางช้าง",
        "amphoe": "บางบาล",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13250,
        "district_code": 140510,
        "amphoe_code": 1405,
        "province_code": 14
    },
    {
        "district": "น้ำเต้า",
        "amphoe": "บางบาล",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13250,
        "district_code": 140509,
        "amphoe_code": 1405,
        "province_code": 14
    },
    {
        "district": "บางชะนี",
        "amphoe": "บางบาล",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13250,
        "district_code": 140515,
        "amphoe_code": 1405,
        "province_code": 14
    },
    {
        "district": "บางบาล",
        "amphoe": "บางบาล",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13250,
        "district_code": 140501,
        "amphoe_code": 1405,
        "province_code": 14
    },
    {
        "district": "บางหลวง",
        "amphoe": "บางบาล",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13250,
        "district_code": 140512,
        "amphoe_code": 1405,
        "province_code": 14
    },
    {
        "district": "บางหลวงโดด",
        "amphoe": "บางบาล",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13250,
        "district_code": 140513,
        "amphoe_code": 1405,
        "province_code": 14
    },
    {
        "district": "บางหัก",
        "amphoe": "บางบาล",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13250,
        "district_code": 140514,
        "amphoe_code": 1405,
        "province_code": 14
    },
    {
        "district": "บ้านกุ่ม",
        "amphoe": "บางบาล",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13250,
        "district_code": 140516,
        "amphoe_code": 1405,
        "province_code": 14
    },
    {
        "district": "บ้านคลัง",
        "amphoe": "บางบาล",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13250,
        "district_code": 140507,
        "amphoe_code": 1405,
        "province_code": 14
    },
    {
        "district": "พระขาว",
        "amphoe": "บางบาล",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13250,
        "district_code": 140508,
        "amphoe_code": 1405,
        "province_code": 14
    },
    {
        "district": "มหาพราหมณ์",
        "amphoe": "บางบาล",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13250,
        "district_code": 140505,
        "amphoe_code": 1405,
        "province_code": 14
    },
    {
        "district": "วัดตะกู",
        "amphoe": "บางบาล",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13250,
        "district_code": 140511,
        "amphoe_code": 1405,
        "province_code": 14
    },
    {
        "district": "วัดยม",
        "amphoe": "บางบาล",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13250,
        "district_code": 140502,
        "amphoe_code": 1405,
        "province_code": 14
    },
    {
        "district": "สะพานไทย",
        "amphoe": "บางบาล",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13250,
        "district_code": 140504,
        "amphoe_code": 1405,
        "province_code": 14
    },
    {
        "district": "ไทรน้อย",
        "amphoe": "บางบาล",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13250,
        "district_code": 140503,
        "amphoe_code": 1405,
        "province_code": 14
    },
    {
        "district": "ขยาย",
        "amphoe": "บางปะหัน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13220,
        "district_code": 140702,
        "amphoe_code": 1407,
        "province_code": 14
    },
    {
        "district": "ขวัญเมือง",
        "amphoe": "บางปะหัน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13220,
        "district_code": 140712,
        "amphoe_code": 1407,
        "province_code": 14
    },
    {
        "district": "ตานิม",
        "amphoe": "บางปะหัน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13220,
        "district_code": 140709,
        "amphoe_code": 1407,
        "province_code": 14
    },
    {
        "district": "ตาลเอน",
        "amphoe": "บางปะหัน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13220,
        "district_code": 140716,
        "amphoe_code": 1407,
        "province_code": 14
    },
    {
        "district": "ทับน้ำ",
        "amphoe": "บางปะหัน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13220,
        "district_code": 140710,
        "amphoe_code": 1407,
        "province_code": 14
    },
    {
        "district": "ทางกลาง",
        "amphoe": "บางปะหัน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13220,
        "district_code": 140705,
        "amphoe_code": 1407,
        "province_code": 14
    },
    {
        "district": "บางนางร้า",
        "amphoe": "บางปะหัน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13220,
        "district_code": 140708,
        "amphoe_code": 1407,
        "province_code": 14
    },
    {
        "district": "บางปะหัน",
        "amphoe": "บางปะหัน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13220,
        "district_code": 140701,
        "amphoe_code": 1407,
        "province_code": 14
    },
    {
        "district": "บางเดื่อ",
        "amphoe": "บางปะหัน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13220,
        "district_code": 140703,
        "amphoe_code": 1407,
        "province_code": 14
    },
    {
        "district": "บางเพลิง",
        "amphoe": "บางปะหัน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13220,
        "district_code": 140706,
        "amphoe_code": 1407,
        "province_code": 14
    },
    {
        "district": "บ้านขล้อ",
        "amphoe": "บางปะหัน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13220,
        "district_code": 140717,
        "amphoe_code": 1407,
        "province_code": 14
    },
    {
        "district": "บ้านม้า",
        "amphoe": "บางปะหัน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13220,
        "district_code": 140417,
        "amphoe_code": 1407,
        "province_code": 14
    },
    {
        "district": "บ้านลี่",
        "amphoe": "บางปะหัน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13220,
        "district_code": 140713,
        "amphoe_code": 1407,
        "province_code": 14
    },
    {
        "district": "พุทเลา",
        "amphoe": "บางปะหัน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13220,
        "district_code": 140715,
        "amphoe_code": 1407,
        "province_code": 14
    },
    {
        "district": "หันสัง",
        "amphoe": "บางปะหัน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13220,
        "district_code": 140707,
        "amphoe_code": 1407,
        "province_code": 14
    },
    {
        "district": "เสาธง",
        "amphoe": "บางปะหัน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13220,
        "district_code": 140704,
        "amphoe_code": 1407,
        "province_code": 14
    },
    {
        "district": "โพธิ์สามต้น",
        "amphoe": "บางปะหัน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13220,
        "district_code": 140714,
        "amphoe_code": 1407,
        "province_code": 14
    },
    {
        "district": "ขนอนหลวง",
        "amphoe": "บางปะอิน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13160,
        "district_code": 140618,
        "amphoe_code": 1406,
        "province_code": 14
    },
    {
        "district": "คลองจิก",
        "amphoe": "บางปะอิน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13160,
        "district_code": 140606,
        "amphoe_code": 1406,
        "province_code": 14
    },
    {
        "district": "คุ้งลาน",
        "amphoe": "บางปะอิน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13160,
        "district_code": 140614,
        "amphoe_code": 1406,
        "province_code": 14
    },
    {
        "district": "ตลาดเกรียบ",
        "amphoe": "บางปะอิน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13160,
        "district_code": 140617,
        "amphoe_code": 1406,
        "province_code": 14
    },
    {
        "district": "ตลิ่งชัน",
        "amphoe": "บางปะอิน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13160,
        "district_code": 140615,
        "amphoe_code": 1406,
        "province_code": 14
    },
    {
        "district": "บางกระสั้น",
        "amphoe": "บางปะอิน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13160,
        "district_code": 140605,
        "amphoe_code": 1406,
        "province_code": 14
    },
    {
        "district": "บางประแดง",
        "amphoe": "บางปะอิน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13160,
        "district_code": 140609,
        "amphoe_code": 1406,
        "province_code": 14
    },
    {
        "district": "บ้านกรด",
        "amphoe": "บางปะอิน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13160,
        "district_code": 140604,
        "amphoe_code": 1406,
        "province_code": 14
    },
    {
        "district": "บ้านพลับ",
        "amphoe": "บางปะอิน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13160,
        "district_code": 140612,
        "amphoe_code": 1406,
        "province_code": 14
    },
    {
        "district": "บ้านสร้าง",
        "amphoe": "บางปะอิน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13170,
        "district_code": 140616,
        "amphoe_code": 1406,
        "province_code": 14
    },
    {
        "district": "บ้านหว้า",
        "amphoe": "บางปะอิน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13160,
        "district_code": 140607,
        "amphoe_code": 1406,
        "province_code": 14
    },
    {
        "district": "บ้านเลน",
        "amphoe": "บางปะอิน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13160,
        "district_code": 140601,
        "amphoe_code": 1406,
        "province_code": 14
    },
    {
        "district": "บ้านแป้ง",
        "amphoe": "บางปะอิน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13160,
        "district_code": 140404,
        "amphoe_code": 1406,
        "province_code": 14
    },
    {
        "district": "บ้านโพ",
        "amphoe": "บางปะอิน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13160,
        "district_code": 140603,
        "amphoe_code": 1406,
        "province_code": 14
    },
    {
        "district": "วัดยม",
        "amphoe": "บางปะอิน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13160,
        "district_code": 140502,
        "amphoe_code": 1406,
        "province_code": 14
    },
    {
        "district": "สามเรือน",
        "amphoe": "บางปะอิน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13160,
        "district_code": 140610,
        "amphoe_code": 1406,
        "province_code": 14
    },
    {
        "district": "เกาะเกิด",
        "amphoe": "บางปะอิน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13160,
        "district_code": 140611,
        "amphoe_code": 1406,
        "province_code": 14
    },
    {
        "district": "เชียงรากน้อย",
        "amphoe": "บางปะอิน",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13180,
        "district_code": 140422,
        "amphoe_code": 1406,
        "province_code": 14
    },
    {
        "district": "กกแก้วบูรพา",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140415,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "กระแชง",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140410,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "ช่างเหล็ก",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140409,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "ช้างน้อย",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140412,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "ช้างใหญ่",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13290,
        "district_code": 140420,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "บางพลี",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140402,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "บางยี่โท",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140406,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "บางไทร",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140401,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "บ้านกลึง",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140411,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "บ้านม้า",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140417,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "บ้านเกาะ",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140116,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "บ้านแป้ง",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140404,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "ราชคราม",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13290,
        "district_code": 140419,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "สนามชัย",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140403,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "หน้าไม้",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140405,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "ห่อหมก",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140413,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "เชียงรากน้อย",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13290,
        "district_code": 140422,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "แคตก",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140408,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "แคออก",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140407,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "โคกช้าง",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140423,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "โพแตง",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13290,
        "district_code": 140421,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "ไผ่พระ",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140414,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "ไม้ตรา",
        "amphoe": "บางไทร",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13190,
        "district_code": 140416,
        "amphoe_code": 1404,
        "province_code": 14
    },
    {
        "district": "คลองน้อย",
        "amphoe": "บ้านแพรก",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13240,
        "district_code": 141604,
        "amphoe_code": 1416,
        "province_code": 14
    },
    {
        "district": "บ้านแพรก",
        "amphoe": "บ้านแพรก",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13240,
        "district_code": 141601,
        "amphoe_code": 1416,
        "province_code": 14
    },
    {
        "district": "บ้านใหม่",
        "amphoe": "บ้านแพรก",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13240,
        "district_code": 140115,
        "amphoe_code": 1416,
        "province_code": 14
    },
    {
        "district": "สองห้อง",
        "amphoe": "บ้านแพรก",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13240,
        "district_code": 141605,
        "amphoe_code": 1416,
        "province_code": 14
    },
    {
        "district": "สำพะเนียง",
        "amphoe": "บ้านแพรก",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13240,
        "district_code": 141603,
        "amphoe_code": 1416,
        "province_code": 14
    },
    {
        "district": "กุฎี",
        "amphoe": "ผักไห่",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13120,
        "district_code": 140809,
        "amphoe_code": 1408,
        "province_code": 14
    },
    {
        "district": "จักราช",
        "amphoe": "ผักไห่",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13280,
        "district_code": 140812,
        "amphoe_code": 1408,
        "province_code": 14
    },
    {
        "district": "ดอนลาน",
        "amphoe": "ผักไห่",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13280,
        "district_code": 140807,
        "amphoe_code": 1408,
        "province_code": 14
    },
    {
        "district": "ตาลาน",
        "amphoe": "ผักไห่",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13120,
        "district_code": 140805,
        "amphoe_code": 1408,
        "province_code": 14
    },
    {
        "district": "ท่าดินแดง",
        "amphoe": "ผักไห่",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13120,
        "district_code": 140806,
        "amphoe_code": 1408,
        "province_code": 14
    },
    {
        "district": "นาคู",
        "amphoe": "ผักไห่",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13280,
        "district_code": 140808,
        "amphoe_code": 1408,
        "province_code": 14
    },
    {
        "district": "บ้านแค",
        "amphoe": "ผักไห่",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13120,
        "district_code": 140803,
        "amphoe_code": 1408,
        "province_code": 14
    },
    {
        "district": "บ้านใหญ่",
        "amphoe": "ผักไห่",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13120,
        "district_code": 140816,
        "amphoe_code": 1408,
        "province_code": 14
    },
    {
        "district": "ผักไห่",
        "amphoe": "ผักไห่",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13120,
        "district_code": 140801,
        "amphoe_code": 1408,
        "province_code": 14
    },
    {
        "district": "ลาดชิด",
        "amphoe": "ผักไห่",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13120,
        "district_code": 140814,
        "amphoe_code": 1408,
        "province_code": 14
    },
    {
        "district": "ลาดน้ำเค็ม",
        "amphoe": "ผักไห่",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13120,
        "district_code": 140804,
        "amphoe_code": 1408,
        "province_code": 14
    },
    {
        "district": "ลำตะเคียน",
        "amphoe": "ผักไห่",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13280,
        "district_code": 140810,
        "amphoe_code": 1408,
        "province_code": 14
    },
    {
        "district": "หนองน้ำใหญ่",
        "amphoe": "ผักไห่",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13280,
        "district_code": 140813,
        "amphoe_code": 1408,
        "province_code": 14
    },
    {
        "district": "หน้าโคก",
        "amphoe": "ผักไห่",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13120,
        "district_code": 140815,
        "amphoe_code": 1408,
        "province_code": 14
    },
    {
        "district": "อมฤต",
        "amphoe": "ผักไห่",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13120,
        "district_code": 140802,
        "amphoe_code": 1408,
        "province_code": 14
    },
    {
        "district": "โคกช้าง",
        "amphoe": "ผักไห่",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13120,
        "district_code": 140423,
        "amphoe_code": 1408,
        "province_code": 14
    },
    {
        "district": "กะมัง",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140102,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "คลองตะเคียน",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140111,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "คลองสระบัว",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140118,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "คลองสวนพลู",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140117,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "ท่าวาสุกรี",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140105,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "บ้านป้อม",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140120,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "บ้านรุน",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140121,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "บ้านเกาะ",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140116,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "บ้านใหม่",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140115,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "ประตูชัย",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140101,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "ปากกราน",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140107,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "ภูเขาทอง",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140108,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "ลุมพลี",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140114,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "วัดตูม",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140112,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "สวนพริก",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140110,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "สำเภาล่ม",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140109,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "หอรัตนไชย",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140103,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "หันตรา",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140113,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "หัวรอ",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140104,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "เกาะเรียน",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140119,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "ไผ่ลิง",
        "amphoe": "พระนครศรีอยุธยา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13000,
        "district_code": 140106,
        "amphoe_code": 1401,
        "province_code": 14
    },
    {
        "district": "กระจิว",
        "amphoe": "ภาชี",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13140,
        "district_code": 140907,
        "amphoe_code": 1409,
        "province_code": 14
    },
    {
        "district": "ดอนหญ้านาง",
        "amphoe": "ภาชี",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13140,
        "district_code": 140905,
        "amphoe_code": 1409,
        "province_code": 14
    },
    {
        "district": "พระแก้ว",
        "amphoe": "ภาชี",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13140,
        "district_code": 140908,
        "amphoe_code": 1409,
        "province_code": 14
    },
    {
        "district": "ภาชี",
        "amphoe": "ภาชี",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13140,
        "district_code": 140901,
        "amphoe_code": 1409,
        "province_code": 14
    },
    {
        "district": "ระโสม",
        "amphoe": "ภาชี",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13140,
        "district_code": 140903,
        "amphoe_code": 1409,
        "province_code": 14
    },
    {
        "district": "หนองน้ำใส",
        "amphoe": "ภาชี",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13140,
        "district_code": 140904,
        "amphoe_code": 1409,
        "province_code": 14
    },
    {
        "district": "โคกม่วง",
        "amphoe": "ภาชี",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13140,
        "district_code": 140902,
        "amphoe_code": 1409,
        "province_code": 14
    },
    {
        "district": "ไผ่ล้อม",
        "amphoe": "ภาชี",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13140,
        "district_code": 140906,
        "amphoe_code": 1409,
        "province_code": 14
    },
    {
        "district": "กะทุ่ม",
        "amphoe": "มหาราช",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13150,
        "district_code": 141502,
        "amphoe_code": 1415,
        "province_code": 14
    },
    {
        "district": "ท่าตอ",
        "amphoe": "มหาราช",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13150,
        "district_code": 141511,
        "amphoe_code": 1415,
        "province_code": 14
    },
    {
        "district": "น้ำเต้า",
        "amphoe": "มหาราช",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13150,
        "district_code": 140509,
        "amphoe_code": 1415,
        "province_code": 14
    },
    {
        "district": "บางนา",
        "amphoe": "มหาราช",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13150,
        "district_code": 141505,
        "amphoe_code": 1415,
        "province_code": 14
    },
    {
        "district": "บ้านขวาง",
        "amphoe": "มหาราช",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13150,
        "district_code": 141510,
        "amphoe_code": 1415,
        "province_code": 14
    },
    {
        "district": "บ้านนา",
        "amphoe": "มหาราช",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13150,
        "district_code": 141509,
        "amphoe_code": 1415,
        "province_code": 14
    },
    {
        "district": "บ้านใหม่",
        "amphoe": "มหาราช",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13150,
        "district_code": 140115,
        "amphoe_code": 1415,
        "province_code": 14
    },
    {
        "district": "พิตเพียน",
        "amphoe": "มหาราช",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13150,
        "district_code": 141508,
        "amphoe_code": 1415,
        "province_code": 14
    },
    {
        "district": "มหาราช",
        "amphoe": "มหาราช",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13150,
        "district_code": 141503,
        "amphoe_code": 1415,
        "province_code": 14
    },
    {
        "district": "หัวไผ่",
        "amphoe": "มหาราช",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13150,
        "district_code": 141501,
        "amphoe_code": 1415,
        "province_code": 14
    },
    {
        "district": "เจ้าปลุก",
        "amphoe": "มหาราช",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13150,
        "district_code": 141507,
        "amphoe_code": 1415,
        "province_code": 14
    },
    {
        "district": "โรงช้าง",
        "amphoe": "มหาราช",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13150,
        "district_code": 141506,
        "amphoe_code": 1415,
        "province_code": 14
    },
    {
        "district": "คลองพระยาบันลือ",
        "amphoe": "ลาดบัวหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13230,
        "district_code": 141007,
        "amphoe_code": 1410,
        "province_code": 14
    },
    {
        "district": "คู้สลอด",
        "amphoe": "ลาดบัวหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13230,
        "district_code": 141006,
        "amphoe_code": 1410,
        "province_code": 14
    },
    {
        "district": "พระยาบันลือ",
        "amphoe": "ลาดบัวหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13230,
        "district_code": 141004,
        "amphoe_code": 1410,
        "province_code": 14
    },
    {
        "district": "ลาดบัวหลวง",
        "amphoe": "ลาดบัวหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13230,
        "district_code": 141001,
        "amphoe_code": 1410,
        "province_code": 14
    },
    {
        "district": "สามเมือง",
        "amphoe": "ลาดบัวหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13230,
        "district_code": 141003,
        "amphoe_code": 1410,
        "province_code": 14
    },
    {
        "district": "สิงหนาท",
        "amphoe": "ลาดบัวหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13230,
        "district_code": 141005,
        "amphoe_code": 1410,
        "province_code": 14
    },
    {
        "district": "หลักชัย",
        "amphoe": "ลาดบัวหลวง",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13230,
        "district_code": 141002,
        "amphoe_code": 1410,
        "province_code": 14
    },
    {
        "district": "ข้าวงาม",
        "amphoe": "วังน้อย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13170,
        "district_code": 141109,
        "amphoe_code": 1411,
        "province_code": 14
    },
    {
        "district": "ชะแมบ",
        "amphoe": "วังน้อย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13170,
        "district_code": 141110,
        "amphoe_code": 1411,
        "province_code": 14
    },
    {
        "district": "บ่อตาโล่",
        "amphoe": "วังน้อย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13170,
        "district_code": 141102,
        "amphoe_code": 1411,
        "province_code": 14
    },
    {
        "district": "พยอม",
        "amphoe": "วังน้อย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13170,
        "district_code": 141106,
        "amphoe_code": 1411,
        "province_code": 14
    },
    {
        "district": "ลำตาเสา",
        "amphoe": "วังน้อย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13170,
        "district_code": 141101,
        "amphoe_code": 1411,
        "province_code": 14
    },
    {
        "district": "ลำไทร",
        "amphoe": "วังน้อย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13170,
        "district_code": 141104,
        "amphoe_code": 1411,
        "province_code": 14
    },
    {
        "district": "วังจุฬา",
        "amphoe": "วังน้อย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13170,
        "district_code": 141108,
        "amphoe_code": 1411,
        "province_code": 14
    },
    {
        "district": "วังน้อย",
        "amphoe": "วังน้อย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13170,
        "district_code": 141103,
        "amphoe_code": 1411,
        "province_code": 14
    },
    {
        "district": "สนับทึบ",
        "amphoe": "วังน้อย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13170,
        "district_code": 141105,
        "amphoe_code": 1411,
        "province_code": 14
    },
    {
        "district": "หันตะเภา",
        "amphoe": "วังน้อย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13170,
        "district_code": 141107,
        "amphoe_code": 1411,
        "province_code": 14
    },
    {
        "district": "ข้าวเม่า",
        "amphoe": "อุทัย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13210,
        "district_code": 141411,
        "amphoe_code": 1414,
        "province_code": 14
    },
    {
        "district": "คานหาม",
        "amphoe": "อุทัย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13210,
        "district_code": 141401,
        "amphoe_code": 1414,
        "province_code": 14
    },
    {
        "district": "ธนู",
        "amphoe": "อุทัย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13210,
        "district_code": 141410,
        "amphoe_code": 1414,
        "province_code": 14
    },
    {
        "district": "บ้านช้าง",
        "amphoe": "อุทัย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13210,
        "district_code": 141402,
        "amphoe_code": 1414,
        "province_code": 14
    },
    {
        "district": "บ้านหีบ",
        "amphoe": "อุทัย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13210,
        "district_code": 141404,
        "amphoe_code": 1414,
        "province_code": 14
    },
    {
        "district": "สามบัณฑิต",
        "amphoe": "อุทัย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13210,
        "district_code": 141403,
        "amphoe_code": 1414,
        "province_code": 14
    },
    {
        "district": "หนองน้ำส้ม",
        "amphoe": "อุทัย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13210,
        "district_code": 141408,
        "amphoe_code": 1414,
        "province_code": 14
    },
    {
        "district": "หนองไม้ซุง",
        "amphoe": "อุทัย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13210,
        "district_code": 141405,
        "amphoe_code": 1414,
        "province_code": 14
    },
    {
        "district": "อุทัย",
        "amphoe": "อุทัย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13210,
        "district_code": 141406,
        "amphoe_code": 1414,
        "province_code": 14
    },
    {
        "district": "เสนา",
        "amphoe": "อุทัย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13210,
        "district_code": 141201,
        "amphoe_code": 1414,
        "province_code": 14
    },
    {
        "district": "โพสาวหาญ",
        "amphoe": "อุทัย",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13210,
        "district_code": 141409,
        "amphoe_code": 1414,
        "province_code": 14
    },
    {
        "district": "ชายนา",
        "amphoe": "เสนา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13110,
        "district_code": 141212,
        "amphoe_code": 1412,
        "province_code": 14
    },
    {
        "district": "ดอนทอง",
        "amphoe": "เสนา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13110,
        "district_code": 141215,
        "amphoe_code": 1412,
        "province_code": 14
    },
    {
        "district": "บางนมโค",
        "amphoe": "เสนา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13110,
        "district_code": 141205,
        "amphoe_code": 1412,
        "province_code": 14
    },
    {
        "district": "บ้านกระทุ่ม",
        "amphoe": "เสนา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13110,
        "district_code": 141210,
        "amphoe_code": 1412,
        "province_code": 14
    },
    {
        "district": "บ้านหลวง",
        "amphoe": "เสนา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13110,
        "district_code": 141216,
        "amphoe_code": 1412,
        "province_code": 14
    },
    {
        "district": "บ้านแถว",
        "amphoe": "เสนา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13110,
        "district_code": 141211,
        "amphoe_code": 1412,
        "province_code": 14
    },
    {
        "district": "บ้านแพน",
        "amphoe": "เสนา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13110,
        "district_code": 141202,
        "amphoe_code": 1412,
        "province_code": 14
    },
    {
        "district": "บ้านโพธิ์",
        "amphoe": "เสนา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13110,
        "district_code": 141208,
        "amphoe_code": 1412,
        "province_code": 14
    },
    {
        "district": "มารวิชัย",
        "amphoe": "เสนา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13110,
        "district_code": 141207,
        "amphoe_code": 1412,
        "province_code": 14
    },
    {
        "district": "รางจรเข้",
        "amphoe": "เสนา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13110,
        "district_code": 141209,
        "amphoe_code": 1412,
        "province_code": 14
    },
    {
        "district": "ลาดงา",
        "amphoe": "เสนา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13110,
        "district_code": 141214,
        "amphoe_code": 1412,
        "province_code": 14
    },
    {
        "district": "สามกอ",
        "amphoe": "เสนา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13110,
        "district_code": 141204,
        "amphoe_code": 1412,
        "province_code": 14
    },
    {
        "district": "สามตุ่ม",
        "amphoe": "เสนา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13110,
        "district_code": 141213,
        "amphoe_code": 1412,
        "province_code": 14
    },
    {
        "district": "หัวเวียง",
        "amphoe": "เสนา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13110,
        "district_code": 141206,
        "amphoe_code": 1412,
        "province_code": 14
    },
    {
        "district": "เจ้าเจ็ด",
        "amphoe": "เสนา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13110,
        "district_code": 141203,
        "amphoe_code": 1412,
        "province_code": 14
    },
    {
        "district": "เจ้าเสด็จ",
        "amphoe": "เสนา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13110,
        "district_code": 141217,
        "amphoe_code": 1412,
        "province_code": 14
    },
    {
        "district": "เสนา",
        "amphoe": "เสนา",
        "province": "พระนครศรีอยุธยา",
        "zipcode": 13110,
        "district_code": 141201,
        "amphoe_code": 1412,
        "province_code": 14
    },
    {
        "district": "จุน",
        "amphoe": "จุน",
        "province": "พะเยา",
        "zipcode": 56150,
        "district_code": 560202,
        "amphoe_code": 5602,
        "province_code": 56
    },
    {
        "district": "ทุ่งรวงทอง",
        "amphoe": "จุน",
        "province": "พะเยา",
        "zipcode": 56150,
        "district_code": 560205,
        "amphoe_code": 5602,
        "province_code": 56
    },
    {
        "district": "พระธาตุขิงแกง",
        "amphoe": "จุน",
        "province": "พะเยา",
        "zipcode": 56150,
        "district_code": 560207,
        "amphoe_code": 5602,
        "province_code": 56
    },
    {
        "district": "ลอ",
        "amphoe": "จุน",
        "province": "พะเยา",
        "zipcode": 56150,
        "district_code": 560203,
        "amphoe_code": 5602,
        "province_code": 56
    },
    {
        "district": "หงส์หิน",
        "amphoe": "จุน",
        "province": "พะเยา",
        "zipcode": 56150,
        "district_code": 560204,
        "amphoe_code": 5602,
        "province_code": 56
    },
    {
        "district": "ห้วยข้าวก่ำ",
        "amphoe": "จุน",
        "province": "พะเยา",
        "zipcode": 56150,
        "district_code": 560201,
        "amphoe_code": 5602,
        "province_code": 56
    },
    {
        "district": "ห้วยยางขาม",
        "amphoe": "จุน",
        "province": "พะเยา",
        "zipcode": 56150,
        "district_code": 560206,
        "amphoe_code": 5602,
        "province_code": 56
    },
    {
        "district": "คือเวียง",
        "amphoe": "ดอกคำใต้",
        "province": "พะเยา",
        "zipcode": 56120,
        "district_code": 560512,
        "amphoe_code": 5605,
        "province_code": 56
    },
    {
        "district": "ดงสุวรรณ",
        "amphoe": "ดอกคำใต้",
        "province": "พะเยา",
        "zipcode": 56120,
        "district_code": 560509,
        "amphoe_code": 5605,
        "province_code": 56
    },
    {
        "district": "ดอกคำใต้",
        "amphoe": "ดอกคำใต้",
        "province": "พะเยา",
        "zipcode": 56120,
        "district_code": 560501,
        "amphoe_code": 5605,
        "province_code": 56
    },
    {
        "district": "ดอนศรีชุม",
        "amphoe": "ดอกคำใต้",
        "province": "พะเยา",
        "zipcode": 56120,
        "district_code": 560502,
        "amphoe_code": 5605,
        "province_code": 56
    },
    {
        "district": "บุญเกิด",
        "amphoe": "ดอกคำใต้",
        "province": "พะเยา",
        "zipcode": 56120,
        "district_code": 560510,
        "amphoe_code": 5605,
        "province_code": 56
    },
    {
        "district": "บ้านถ้ำ",
        "amphoe": "ดอกคำใต้",
        "province": "พะเยา",
        "zipcode": 56120,
        "district_code": 560503,
        "amphoe_code": 5605,
        "province_code": 56
    },
    {
        "district": "บ้านปิน",
        "amphoe": "ดอกคำใต้",
        "province": "พะเยา",
        "zipcode": 56120,
        "district_code": 560504,
        "amphoe_code": 5605,
        "province_code": 56
    },
    {
        "district": "ป่าซาง",
        "amphoe": "ดอกคำใต้",
        "province": "พะเยา",
        "zipcode": 56120,
        "district_code": 560507,
        "amphoe_code": 5605,
        "province_code": 56
    },
    {
        "district": "สว่างอารมณ์",
        "amphoe": "ดอกคำใต้",
        "province": "พะเยา",
        "zipcode": 56120,
        "district_code": 560511,
        "amphoe_code": 5605,
        "province_code": 56
    },
    {
        "district": "สันโค้ง",
        "amphoe": "ดอกคำใต้",
        "province": "พะเยา",
        "zipcode": 56120,
        "district_code": 560506,
        "amphoe_code": 5605,
        "province_code": 56
    },
    {
        "district": "หนองหล่ม",
        "amphoe": "ดอกคำใต้",
        "province": "พะเยา",
        "zipcode": 56120,
        "district_code": 560508,
        "amphoe_code": 5605,
        "province_code": 56
    },
    {
        "district": "ห้วยลาน",
        "amphoe": "ดอกคำใต้",
        "province": "พะเยา",
        "zipcode": 56120,
        "district_code": 560505,
        "amphoe_code": 5605,
        "province_code": 56
    },
    {
        "district": "ขุนควร",
        "amphoe": "ปง",
        "province": "พะเยา",
        "zipcode": 56140,
        "district_code": 560607,
        "amphoe_code": 5606,
        "province_code": 56
    },
    {
        "district": "ควร",
        "amphoe": "ปง",
        "province": "พะเยา",
        "zipcode": 56140,
        "district_code": 560602,
        "amphoe_code": 5606,
        "province_code": 56
    },
    {
        "district": "งิม",
        "amphoe": "ปง",
        "province": "พะเยา",
        "zipcode": 56140,
        "district_code": 560604,
        "amphoe_code": 5606,
        "province_code": 56
    },
    {
        "district": "นาปรัง",
        "amphoe": "ปง",
        "province": "พะเยา",
        "zipcode": 56140,
        "district_code": 560606,
        "amphoe_code": 5606,
        "province_code": 56
    },
    {
        "district": "ปง",
        "amphoe": "ปง",
        "province": "พะเยา",
        "zipcode": 56140,
        "district_code": 560601,
        "amphoe_code": 5606,
        "province_code": 56
    },
    {
        "district": "ผาช้างน้อย",
        "amphoe": "ปง",
        "province": "พะเยา",
        "zipcode": 56140,
        "district_code": 560605,
        "amphoe_code": 5606,
        "province_code": 56
    },
    {
        "district": "ออย",
        "amphoe": "ปง",
        "province": "พะเยา",
        "zipcode": 56140,
        "district_code": 560603,
        "amphoe_code": 5606,
        "province_code": 56
    },
    {
        "district": "ดงเจน",
        "amphoe": "ภูกามยาว",
        "province": "พะเยา",
        "zipcode": 56000,
        "district_code": 560103,
        "amphoe_code": 5609,
        "province_code": 56
    },
    {
        "district": "ห้วยแก้ว",
        "amphoe": "ภูกามยาว",
        "province": "พะเยา",
        "zipcode": 56000,
        "district_code": 560109,
        "amphoe_code": 5609,
        "province_code": 56
    },
    {
        "district": "แม่อิง",
        "amphoe": "ภูกามยาว",
        "province": "พะเยา",
        "zipcode": 56000,
        "district_code": 560117,
        "amphoe_code": 5609,
        "province_code": 56
    },
    {
        "district": "ทุ่งกล้วย",
        "amphoe": "ภูซาง",
        "province": "พะเยา",
        "zipcode": 56110,
        "district_code": 560302,
        "amphoe_code": 5608,
        "province_code": 56
    },
    {
        "district": "ป่าสัก",
        "amphoe": "ภูซาง",
        "province": "พะเยา",
        "zipcode": 56110,
        "district_code": 560315,
        "amphoe_code": 5608,
        "province_code": 56
    },
    {
        "district": "ภูซาง",
        "amphoe": "ภูซาง",
        "province": "พะเยา",
        "zipcode": 56110,
        "district_code": 560305,
        "amphoe_code": 5608,
        "province_code": 56
    },
    {
        "district": "สบบง",
        "amphoe": "ภูซาง",
        "province": "พะเยา",
        "zipcode": 56110,
        "district_code": 560303,
        "amphoe_code": 5608,
        "province_code": 56
    },
    {
        "district": "เชียงแรง",
        "amphoe": "ภูซาง",
        "province": "พะเยา",
        "zipcode": 56110,
        "district_code": 560304,
        "amphoe_code": 5608,
        "province_code": 56
    },
    {
        "district": "ทุ่งผาสุข",
        "amphoe": "เชียงคำ",
        "province": "พะเยา",
        "zipcode": 56110,
        "district_code": 560314,
        "amphoe_code": 5603,
        "province_code": 56
    },
    {
        "district": "น้ำแวน",
        "amphoe": "เชียงคำ",
        "province": "พะเยา",
        "zipcode": 56110,
        "district_code": 560306,
        "amphoe_code": 5603,
        "province_code": 56
    },
    {
        "district": "ฝายกวาง",
        "amphoe": "เชียงคำ",
        "province": "พะเยา",
        "zipcode": 56110,
        "district_code": 560308,
        "amphoe_code": 5603,
        "province_code": 56
    },
    {
        "district": "ร่มเย็น",
        "amphoe": "เชียงคำ",
        "province": "พะเยา",
        "zipcode": 56110,
        "district_code": 560310,
        "amphoe_code": 5603,
        "province_code": 56
    },
    {
        "district": "หย่วน",
        "amphoe": "เชียงคำ",
        "province": "พะเยา",
        "zipcode": 56110,
        "district_code": 560301,
        "amphoe_code": 5603,
        "province_code": 56
    },
    {
        "district": "อ่างทอง",
        "amphoe": "เชียงคำ",
        "province": "พะเยา",
        "zipcode": 56110,
        "district_code": 560313,
        "amphoe_code": 5603,
        "province_code": 56
    },
    {
        "district": "เจดีย์คำ",
        "amphoe": "เชียงคำ",
        "province": "พะเยา",
        "zipcode": 56110,
        "district_code": 560309,
        "amphoe_code": 5603,
        "province_code": 56
    },
    {
        "district": "เชียงบาน",
        "amphoe": "เชียงคำ",
        "province": "พะเยา",
        "zipcode": 56110,
        "district_code": 560311,
        "amphoe_code": 5603,
        "province_code": 56
    },
    {
        "district": "เวียง",
        "amphoe": "เชียงคำ",
        "province": "พะเยา",
        "zipcode": 56110,
        "district_code": 560101,
        "amphoe_code": 5603,
        "province_code": 56
    },
    {
        "district": "แม่ลาว",
        "amphoe": "เชียงคำ",
        "province": "พะเยา",
        "zipcode": 56110,
        "district_code": 560312,
        "amphoe_code": 5603,
        "province_code": 56
    },
    {
        "district": "บ้านมาง",
        "amphoe": "เชียงม่วน",
        "province": "พะเยา",
        "zipcode": 56160,
        "district_code": 560402,
        "amphoe_code": 5604,
        "province_code": 56
    },
    {
        "district": "สระ",
        "amphoe": "เชียงม่วน",
        "province": "พะเยา",
        "zipcode": 56160,
        "district_code": 560403,
        "amphoe_code": 5604,
        "province_code": 56
    },
    {
        "district": "เชียงม่วน",
        "amphoe": "เชียงม่วน",
        "province": "พะเยา",
        "zipcode": 56160,
        "district_code": 560401,
        "amphoe_code": 5604,
        "province_code": 56
    },
    {
        "district": "จำป่าหวาย",
        "amphoe": "เมืองพะเยา",
        "province": "พะเยา",
        "zipcode": 56000,
        "district_code": 560112,
        "amphoe_code": 5601,
        "province_code": 56
    },
    {
        "district": "ท่าจำปี",
        "amphoe": "เมืองพะเยา",
        "province": "พะเยา",
        "zipcode": 56000,
        "district_code": 560116,
        "amphoe_code": 5601,
        "province_code": 56
    },
    {
        "district": "ท่าวังทอง",
        "amphoe": "เมืองพะเยา",
        "province": "พะเยา",
        "zipcode": 56000,
        "district_code": 560113,
        "amphoe_code": 5601,
        "province_code": 56
    },
    {
        "district": "บ้านตุ่น",
        "amphoe": "เมืองพะเยา",
        "province": "พะเยา",
        "zipcode": 56000,
        "district_code": 560105,
        "amphoe_code": 5601,
        "province_code": 56
    },
    {
        "district": "บ้านต๊ำ",
        "amphoe": "เมืองพะเยา",
        "province": "พะเยา",
        "zipcode": 56000,
        "district_code": 560106,
        "amphoe_code": 5601,
        "province_code": 56
    },
    {
        "district": "บ้านต๋อม",
        "amphoe": "เมืองพะเยา",
        "province": "พะเยา",
        "zipcode": 56000,
        "district_code": 560107,
        "amphoe_code": 5601,
        "province_code": 56
    },
    {
        "district": "บ้านสาง",
        "amphoe": "เมืองพะเยา",
        "province": "พะเยา",
        "zipcode": 56000,
        "district_code": 560115,
        "amphoe_code": 5601,
        "province_code": 56
    },
    {
        "district": "บ้านใหม่",
        "amphoe": "เมืองพะเยา",
        "province": "พะเยา",
        "zipcode": 56000,
        "district_code": 560111,
        "amphoe_code": 5601,
        "province_code": 56
    },
    {
        "district": "สันป่าม่วง",
        "amphoe": "เมืองพะเยา",
        "province": "พะเยา",
        "zipcode": 56000,
        "district_code": 560118,
        "amphoe_code": 5601,
        "province_code": 56
    },
    {
        "district": "เวียง",
        "amphoe": "เมืองพะเยา",
        "province": "พะเยา",
        "zipcode": 56000,
        "district_code": 560101,
        "amphoe_code": 5601,
        "province_code": 56
    },
    {
        "district": "แม่กา",
        "amphoe": "เมืองพะเยา",
        "province": "พะเยา",
        "zipcode": 56000,
        "district_code": 560110,
        "amphoe_code": 5601,
        "province_code": 56
    },
    {
        "district": "แม่ต๋ำ",
        "amphoe": "เมืองพะเยา",
        "province": "พะเยา",
        "zipcode": 56000,
        "district_code": 560102,
        "amphoe_code": 5601,
        "province_code": 56
    },
    {
        "district": "แม่นาเรือ",
        "amphoe": "เมืองพะเยา",
        "province": "พะเยา",
        "zipcode": 56000,
        "district_code": 560104,
        "amphoe_code": 5601,
        "province_code": 56
    },
    {
        "district": "แม่ปืม",
        "amphoe": "เมืองพะเยา",
        "province": "พะเยา",
        "zipcode": 56000,
        "district_code": 560108,
        "amphoe_code": 5601,
        "province_code": 56
    },
    {
        "district": "แม่ใส",
        "amphoe": "เมืองพะเยา",
        "province": "พะเยา",
        "zipcode": 56000,
        "district_code": 560114,
        "amphoe_code": 5601,
        "province_code": 56
    },
    {
        "district": "บ้านเหล่า",
        "amphoe": "แม่ใจ",
        "province": "พะเยา",
        "zipcode": 56130,
        "district_code": 560705,
        "amphoe_code": 5607,
        "province_code": 56
    },
    {
        "district": "ป่าแฝก",
        "amphoe": "แม่ใจ",
        "province": "พะเยา",
        "zipcode": 56130,
        "district_code": 560704,
        "amphoe_code": 5607,
        "province_code": 56
    },
    {
        "district": "ศรีถ้อย",
        "amphoe": "แม่ใจ",
        "province": "พะเยา",
        "zipcode": 56130,
        "district_code": 560702,
        "amphoe_code": 5607,
        "province_code": 56
    },
    {
        "district": "เจริญราษฎร์",
        "amphoe": "แม่ใจ",
        "province": "พะเยา",
        "zipcode": 56130,
        "district_code": 560706,
        "amphoe_code": 5607,
        "province_code": 56
    },
    {
        "district": "แม่สุก",
        "amphoe": "แม่ใจ",
        "province": "พะเยา",
        "zipcode": 56130,
        "district_code": 560703,
        "amphoe_code": 5607,
        "province_code": 56
    },
    {
        "district": "แม่ใจ",
        "amphoe": "แม่ใจ",
        "province": "พะเยา",
        "zipcode": 56130,
        "district_code": 560701,
        "amphoe_code": 5607,
        "province_code": 56
    },
    {
        "district": "กะปง",
        "amphoe": "กะปง",
        "province": "พังงา",
        "zipcode": 82170,
        "district_code": 820301,
        "amphoe_code": 8203,
        "province_code": 82
    },
    {
        "district": "ท่านา",
        "amphoe": "กะปง",
        "province": "พังงา",
        "zipcode": 82170,
        "district_code": 820302,
        "amphoe_code": 8203,
        "province_code": 82
    },
    {
        "district": "รมณีย์",
        "amphoe": "กะปง",
        "province": "พังงา",
        "zipcode": 82170,
        "district_code": 820305,
        "amphoe_code": 8203,
        "province_code": 82
    },
    {
        "district": "เหมาะ",
        "amphoe": "กะปง",
        "province": "พังงา",
        "zipcode": 82170,
        "district_code": 820303,
        "amphoe_code": 8203,
        "province_code": 82
    },
    {
        "district": "เหล",
        "amphoe": "กะปง",
        "province": "พังงา",
        "zipcode": 82170,
        "district_code": 820304,
        "amphoe_code": 8203,
        "province_code": 82
    },
    {
        "district": "คุระ",
        "amphoe": "คุระบุรี",
        "province": "พังงา",
        "zipcode": 82150,
        "district_code": 820601,
        "amphoe_code": 8206,
        "province_code": 82
    },
    {
        "district": "บางวัน",
        "amphoe": "คุระบุรี",
        "province": "พังงา",
        "zipcode": 82150,
        "district_code": 820602,
        "amphoe_code": 8206,
        "province_code": 82
    },
    {
        "district": "เกาะพระทอง",
        "amphoe": "คุระบุรี",
        "province": "พังงา",
        "zipcode": 82150,
        "district_code": 820603,
        "amphoe_code": 8206,
        "province_code": 82
    },
    {
        "district": "แม่นางขาว",
        "amphoe": "คุระบุรี",
        "province": "พังงา",
        "zipcode": 82150,
        "district_code": 820605,
        "amphoe_code": 8206,
        "province_code": 82
    },
    {
        "district": "กระโสม",
        "amphoe": "ตะกั่วทุ่ง",
        "province": "พังงา",
        "zipcode": 82130,
        "district_code": 820402,
        "amphoe_code": 8204,
        "province_code": 82
    },
    {
        "district": "กะไหล",
        "amphoe": "ตะกั่วทุ่ง",
        "province": "พังงา",
        "zipcode": 82130,
        "district_code": 820403,
        "amphoe_code": 8204,
        "province_code": 82
    },
    {
        "district": "คลองเคียน",
        "amphoe": "ตะกั่วทุ่ง",
        "province": "พังงา",
        "zipcode": 82130,
        "district_code": 820407,
        "amphoe_code": 8204,
        "province_code": 82
    },
    {
        "district": "ถ้ำ",
        "amphoe": "ตะกั่วทุ่ง",
        "province": "พังงา",
        "zipcode": 82130,
        "district_code": 820401,
        "amphoe_code": 8204,
        "province_code": 82
    },
    {
        "district": "ท่าอยู่",
        "amphoe": "ตะกั่วทุ่ง",
        "province": "พังงา",
        "zipcode": 82130,
        "district_code": 820404,
        "amphoe_code": 8204,
        "province_code": 82
    },
    {
        "district": "หล่อยูง",
        "amphoe": "ตะกั่วทุ่ง",
        "province": "พังงา",
        "zipcode": 82140,
        "district_code": 820405,
        "amphoe_code": 8204,
        "province_code": 82
    },
    {
        "district": "โคกกลอย",
        "amphoe": "ตะกั่วทุ่ง",
        "province": "พังงา",
        "zipcode": 82140,
        "district_code": 820406,
        "amphoe_code": 8204,
        "province_code": 82
    },
    {
        "district": "คึกคัก",
        "amphoe": "ตะกั่วป่า",
        "province": "พังงา",
        "zipcode": 82220,
        "district_code": 820507,
        "amphoe_code": 8205,
        "province_code": 82
    },
    {
        "district": "ตะกั่วป่า",
        "amphoe": "ตะกั่วป่า",
        "province": "พังงา",
        "zipcode": 82110,
        "district_code": 820501,
        "amphoe_code": 8205,
        "province_code": 82
    },
    {
        "district": "ตำตัว",
        "amphoe": "ตะกั่วป่า",
        "province": "พังงา",
        "zipcode": 82110,
        "district_code": 820505,
        "amphoe_code": 8205,
        "province_code": 82
    },
    {
        "district": "บางนายสี",
        "amphoe": "ตะกั่วป่า",
        "province": "พังงา",
        "zipcode": 82110,
        "district_code": 820502,
        "amphoe_code": 8205,
        "province_code": 82
    },
    {
        "district": "บางม่วง",
        "amphoe": "ตะกั่วป่า",
        "province": "พังงา",
        "zipcode": 82190,
        "district_code": 820504,
        "amphoe_code": 8205,
        "province_code": 82
    },
    {
        "district": "บางไทร",
        "amphoe": "ตะกั่วป่า",
        "province": "พังงา",
        "zipcode": 82110,
        "district_code": 820503,
        "amphoe_code": 8205,
        "province_code": 82
    },
    {
        "district": "เกาะคอเขา",
        "amphoe": "ตะกั่วป่า",
        "province": "พังงา",
        "zipcode": 82190,
        "district_code": 820508,
        "amphoe_code": 8205,
        "province_code": 82
    },
    {
        "district": "โคกเคียน",
        "amphoe": "ตะกั่วป่า",
        "province": "พังงา",
        "zipcode": 82110,
        "district_code": 820506,
        "amphoe_code": 8205,
        "province_code": 82
    },
    {
        "district": "ถ้ำทองหลาง",
        "amphoe": "ทับปุด",
        "province": "พังงา",
        "zipcode": 82180,
        "district_code": 820704,
        "amphoe_code": 8207,
        "province_code": 82
    },
    {
        "district": "ทับปุด",
        "amphoe": "ทับปุด",
        "province": "พังงา",
        "zipcode": 82180,
        "district_code": 820701,
        "amphoe_code": 8207,
        "province_code": 82
    },
    {
        "district": "บางเหรียง",
        "amphoe": "ทับปุด",
        "province": "พังงา",
        "zipcode": 82180,
        "district_code": 820706,
        "amphoe_code": 8207,
        "province_code": 82
    },
    {
        "district": "บ่อแสน",
        "amphoe": "ทับปุด",
        "province": "พังงา",
        "zipcode": 82180,
        "district_code": 820703,
        "amphoe_code": 8207,
        "province_code": 82
    },
    {
        "district": "มะรุ่ย",
        "amphoe": "ทับปุด",
        "province": "พังงา",
        "zipcode": 82180,
        "district_code": 820702,
        "amphoe_code": 8207,
        "province_code": 82
    },
    {
        "district": "โคกเจริญ",
        "amphoe": "ทับปุด",
        "province": "พังงา",
        "zipcode": 82180,
        "district_code": 820705,
        "amphoe_code": 8207,
        "province_code": 82
    },
    {
        "district": "ทุ่งมะพร้าว",
        "amphoe": "ท้ายเหมือง",
        "province": "พังงา",
        "zipcode": 82120,
        "district_code": 820804,
        "amphoe_code": 8208,
        "province_code": 82
    },
    {
        "district": "ท้ายเหมือง",
        "amphoe": "ท้ายเหมือง",
        "province": "พังงา",
        "zipcode": 82120,
        "district_code": 820801,
        "amphoe_code": 8208,
        "province_code": 82
    },
    {
        "district": "นาเตย",
        "amphoe": "ท้ายเหมือง",
        "province": "พังงา",
        "zipcode": 82120,
        "district_code": 820802,
        "amphoe_code": 8208,
        "province_code": 82
    },
    {
        "district": "บางทอง",
        "amphoe": "ท้ายเหมือง",
        "province": "พังงา",
        "zipcode": 82120,
        "district_code": 820803,
        "amphoe_code": 8208,
        "province_code": 82
    },
    {
        "district": "ลำภี",
        "amphoe": "ท้ายเหมือง",
        "province": "พังงา",
        "zipcode": 82120,
        "district_code": 820805,
        "amphoe_code": 8208,
        "province_code": 82
    },
    {
        "district": "ลำแก่น",
        "amphoe": "ท้ายเหมือง",
        "province": "พังงา",
        "zipcode": 82210,
        "district_code": 820806,
        "amphoe_code": 8208,
        "province_code": 82
    },
    {
        "district": "พรุใน",
        "amphoe": "เกาะยาว",
        "province": "พังงา",
        "zipcode": 83000,
        "district_code": 820203,
        "amphoe_code": 8202,
        "province_code": 82
    },
    {
        "district": "เกาะยาวน้อย",
        "amphoe": "เกาะยาว",
        "province": "พังงา",
        "zipcode": 82160,
        "district_code": 820199,
        "amphoe_code": 8202,
        "province_code": 82
    },
    {
        "district": "เกาะยาวใหญ่",
        "amphoe": "เกาะยาว",
        "province": "พังงา",
        "zipcode": 82160,
        "district_code": 820198,
        "amphoe_code": 8202,
        "province_code": 82
    },
    {
        "district": "ตากแดด",
        "amphoe": "เมืองพังงา",
        "province": "พังงา",
        "zipcode": 82000,
        "district_code": 820105,
        "amphoe_code": 8201,
        "province_code": 82
    },
    {
        "district": "ถ้ำน้ำผุด",
        "amphoe": "เมืองพังงา",
        "province": "พังงา",
        "zipcode": 82000,
        "district_code": 820103,
        "amphoe_code": 8201,
        "province_code": 82
    },
    {
        "district": "ทุ่งคาโงก",
        "amphoe": "เมืองพังงา",
        "province": "พังงา",
        "zipcode": 82000,
        "district_code": 820107,
        "amphoe_code": 8201,
        "province_code": 82
    },
    {
        "district": "ท้ายช้าง",
        "amphoe": "เมืองพังงา",
        "province": "พังงา",
        "zipcode": 82000,
        "district_code": 820101,
        "amphoe_code": 8201,
        "province_code": 82
    },
    {
        "district": "นบปริง",
        "amphoe": "เมืองพังงา",
        "province": "พังงา",
        "zipcode": 82000,
        "district_code": 820102,
        "amphoe_code": 8201,
        "province_code": 82
    },
    {
        "district": "บางเตย",
        "amphoe": "เมืองพังงา",
        "province": "พังงา",
        "zipcode": 82000,
        "district_code": 820104,
        "amphoe_code": 8201,
        "province_code": 82
    },
    {
        "district": "ป่ากอ",
        "amphoe": "เมืองพังงา",
        "province": "พังงา",
        "zipcode": 82000,
        "district_code": 820109,
        "amphoe_code": 8201,
        "province_code": 82
    },
    {
        "district": "สองแพรก",
        "amphoe": "เมืองพังงา",
        "province": "พังงา",
        "zipcode": 82000,
        "district_code": 820106,
        "amphoe_code": 8201,
        "province_code": 82
    },
    {
        "district": "เกาะปันหยี",
        "amphoe": "เมืองพังงา",
        "province": "พังงา",
        "zipcode": 82000,
        "district_code": 820108,
        "amphoe_code": 8201,
        "province_code": 82
    },
    {
        "district": "กงหรา",
        "amphoe": "กงหรา",
        "province": "พัทลุง",
        "zipcode": 93180,
        "district_code": 930201,
        "amphoe_code": 9302,
        "province_code": 93
    },
    {
        "district": "คลองทรายขาว",
        "amphoe": "กงหรา",
        "province": "พัทลุง",
        "zipcode": 93180,
        "district_code": 930204,
        "amphoe_code": 9302,
        "province_code": 93
    },
    {
        "district": "คลองเฉลิม",
        "amphoe": "กงหรา",
        "province": "พัทลุง",
        "zipcode": 93180,
        "district_code": 930203,
        "amphoe_code": 9302,
        "province_code": 93
    },
    {
        "district": "ชะรัด",
        "amphoe": "กงหรา",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930202,
        "amphoe_code": 9302,
        "province_code": 93
    },
    {
        "district": "สมหวัง",
        "amphoe": "กงหรา",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930205,
        "amphoe_code": 9302,
        "province_code": 93
    },
    {
        "district": "ควนขนุน",
        "amphoe": "ควนขนุน",
        "province": "พัทลุง",
        "zipcode": 93110,
        "district_code": 930302,
        "amphoe_code": 9305,
        "province_code": 93
    },
    {
        "district": "ชะมวง",
        "amphoe": "ควนขนุน",
        "province": "พัทลุง",
        "zipcode": 93110,
        "district_code": 930513,
        "amphoe_code": 9305,
        "province_code": 93
    },
    {
        "district": "ดอนทราย",
        "amphoe": "ควนขนุน",
        "province": "พัทลุง",
        "zipcode": 93110,
        "district_code": 930510,
        "amphoe_code": 9305,
        "province_code": 93
    },
    {
        "district": "ทะเลน้อย",
        "amphoe": "ควนขนุน",
        "province": "พัทลุง",
        "zipcode": 93150,
        "district_code": 930502,
        "amphoe_code": 9305,
        "province_code": 93
    },
    {
        "district": "นาขยาด",
        "amphoe": "ควนขนุน",
        "province": "พัทลุง",
        "zipcode": 93110,
        "district_code": 930504,
        "amphoe_code": 9305,
        "province_code": 93
    },
    {
        "district": "ปันแต",
        "amphoe": "ควนขนุน",
        "province": "พัทลุง",
        "zipcode": 93110,
        "district_code": 930508,
        "amphoe_code": 9305,
        "province_code": 93
    },
    {
        "district": "พนมวังก์",
        "amphoe": "ควนขนุน",
        "province": "พัทลุง",
        "zipcode": 93110,
        "district_code": 930505,
        "amphoe_code": 9305,
        "province_code": 93
    },
    {
        "district": "พนางตุง",
        "amphoe": "ควนขนุน",
        "province": "พัทลุง",
        "zipcode": 93150,
        "district_code": 930512,
        "amphoe_code": 9305,
        "province_code": 93
    },
    {
        "district": "มะกอกเหนือ",
        "amphoe": "ควนขนุน",
        "province": "พัทลุง",
        "zipcode": 93150,
        "district_code": 930511,
        "amphoe_code": 9305,
        "province_code": 93
    },
    {
        "district": "แพรกหา",
        "amphoe": "ควนขนุน",
        "province": "พัทลุง",
        "zipcode": 93110,
        "district_code": 930516,
        "amphoe_code": 9305,
        "province_code": 93
    },
    {
        "district": "แหลมโตนด",
        "amphoe": "ควนขนุน",
        "province": "พัทลุง",
        "zipcode": 93110,
        "district_code": 930506,
        "amphoe_code": 9305,
        "province_code": 93
    },
    {
        "district": "โตนดด้วน",
        "amphoe": "ควนขนุน",
        "province": "พัทลุง",
        "zipcode": 93110,
        "district_code": 930509,
        "amphoe_code": 9305,
        "province_code": 93
    },
    {
        "district": "คลองใหญ่",
        "amphoe": "ตะโหมด",
        "province": "พัทลุง",
        "zipcode": 93160,
        "district_code": 930396,
        "amphoe_code": 9304,
        "province_code": 93
    },
    {
        "district": "ตะโหมด",
        "amphoe": "ตะโหมด",
        "province": "พัทลุง",
        "zipcode": 93160,
        "district_code": 930397,
        "amphoe_code": 9304,
        "province_code": 93
    },
    {
        "district": "แม่ขรี",
        "amphoe": "ตะโหมด",
        "province": "พัทลุง",
        "zipcode": 93160,
        "district_code": 930399,
        "amphoe_code": 9304,
        "province_code": 93
    },
    {
        "district": "ท่ามะเดื่อ",
        "amphoe": "บางแก้ว",
        "province": "พัทลุง",
        "zipcode": 93140,
        "district_code": 930303,
        "amphoe_code": 9309,
        "province_code": 93
    },
    {
        "district": "นาปะขอ",
        "amphoe": "บางแก้ว",
        "province": "พัทลุง",
        "zipcode": 93140,
        "district_code": 930304,
        "amphoe_code": 9309,
        "province_code": 93
    },
    {
        "district": "โคกสัก",
        "amphoe": "บางแก้ว",
        "province": "พัทลุง",
        "zipcode": 93140,
        "district_code": 930308,
        "amphoe_code": 9309,
        "province_code": 93
    },
    {
        "district": "ดอนทราย",
        "amphoe": "ปากพะยูน",
        "province": "พัทลุง",
        "zipcode": 93120,
        "district_code": 930510,
        "amphoe_code": 9306,
        "province_code": 93
    },
    {
        "district": "ดอนประดู่",
        "amphoe": "ปากพะยูน",
        "province": "พัทลุง",
        "zipcode": 93120,
        "district_code": 930602,
        "amphoe_code": 9306,
        "province_code": 93
    },
    {
        "district": "ปากพะยูน",
        "amphoe": "ปากพะยูน",
        "province": "พัทลุง",
        "zipcode": 93120,
        "district_code": 930601,
        "amphoe_code": 9306,
        "province_code": 93
    },
    {
        "district": "ฝาละมี",
        "amphoe": "ปากพะยูน",
        "province": "พัทลุง",
        "zipcode": 93120,
        "district_code": 930605,
        "amphoe_code": 9306,
        "province_code": 93
    },
    {
        "district": "หารเทา",
        "amphoe": "ปากพะยูน",
        "province": "พัทลุง",
        "zipcode": 93120,
        "district_code": 930606,
        "amphoe_code": 9306,
        "province_code": 93
    },
    {
        "district": "เกาะนางคำ",
        "amphoe": "ปากพะยูน",
        "province": "พัทลุง",
        "zipcode": 93120,
        "district_code": 930603,
        "amphoe_code": 9306,
        "province_code": 93
    },
    {
        "district": "เกาะหมาก",
        "amphoe": "ปากพะยูน",
        "province": "พัทลุง",
        "zipcode": 93120,
        "district_code": 930604,
        "amphoe_code": 9306,
        "province_code": 93
    },
    {
        "district": "ทุ่งนารี",
        "amphoe": "ป่าบอน",
        "province": "พัทลุง",
        "zipcode": 93170,
        "district_code": 930804,
        "amphoe_code": 9308,
        "province_code": 93
    },
    {
        "district": "ป่าบอน",
        "amphoe": "ป่าบอน",
        "province": "พัทลุง",
        "zipcode": 93170,
        "district_code": 930699,
        "amphoe_code": 9308,
        "province_code": 93
    },
    {
        "district": "วังใหม่",
        "amphoe": "ป่าบอน",
        "province": "พัทลุง",
        "zipcode": 93170,
        "district_code": 930806,
        "amphoe_code": 9308,
        "province_code": 93
    },
    {
        "district": "หนองธง",
        "amphoe": "ป่าบอน",
        "province": "พัทลุง",
        "zipcode": 93170,
        "district_code": 930803,
        "amphoe_code": 9308,
        "province_code": 93
    },
    {
        "district": "โคกทราย",
        "amphoe": "ป่าบอน",
        "province": "พัทลุง",
        "zipcode": 93170,
        "district_code": 930698,
        "amphoe_code": 9308,
        "province_code": 93
    },
    {
        "district": "บ้านพร้าว",
        "amphoe": "ป่าพะยอม",
        "province": "พัทลุง",
        "zipcode": 93210,
        "district_code": 930514,
        "amphoe_code": 9310,
        "province_code": 93
    },
    {
        "district": "ป่าพะยอม",
        "amphoe": "ป่าพะยอม",
        "province": "พัทลุง",
        "zipcode": 93210,
        "district_code": 930507,
        "amphoe_code": 9310,
        "province_code": 93
    },
    {
        "district": "ลานข่อย",
        "amphoe": "ป่าพะยอม",
        "province": "พัทลุง",
        "zipcode": 93210,
        "district_code": 930515,
        "amphoe_code": 9310,
        "province_code": 93
    },
    {
        "district": "เกาะเต่า",
        "amphoe": "ป่าพะยอม",
        "province": "พัทลุง",
        "zipcode": 93210,
        "district_code": 930503,
        "amphoe_code": 9310,
        "province_code": 93
    },
    {
        "district": "ชุมพล",
        "amphoe": "ศรีนครินทร์",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930118,
        "amphoe_code": 9311,
        "province_code": 93
    },
    {
        "district": "บ้านนา",
        "amphoe": "ศรีนครินทร์",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930102,
        "amphoe_code": 9311,
        "province_code": 93
    },
    {
        "district": "ลำสินธุ์",
        "amphoe": "ศรีนครินทร์",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930116,
        "amphoe_code": 9311,
        "province_code": 93
    },
    {
        "district": "อ่างทอง",
        "amphoe": "ศรีนครินทร์",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930117,
        "amphoe_code": 9311,
        "province_code": 93
    },
    {
        "district": "ตะแพน",
        "amphoe": "ศรีบรรพต",
        "province": "พัทลุง",
        "zipcode": 93190,
        "district_code": 930703,
        "amphoe_code": 9307,
        "province_code": 93
    },
    {
        "district": "เขาปู่",
        "amphoe": "ศรีบรรพต",
        "province": "พัทลุง",
        "zipcode": 93190,
        "district_code": 930702,
        "amphoe_code": 9307,
        "province_code": 93
    },
    {
        "district": "เขาย่า",
        "amphoe": "ศรีบรรพต",
        "province": "พัทลุง",
        "zipcode": 93190,
        "district_code": 930701,
        "amphoe_code": 9307,
        "province_code": 93
    },
    {
        "district": "ควนขนุน",
        "amphoe": "เขาชัยสน",
        "province": "พัทลุง",
        "zipcode": 93130,
        "district_code": 930302,
        "amphoe_code": 9303,
        "province_code": 93
    },
    {
        "district": "จองถนน",
        "amphoe": "เขาชัยสน",
        "province": "พัทลุง",
        "zipcode": 93130,
        "district_code": 930305,
        "amphoe_code": 9303,
        "province_code": 93
    },
    {
        "district": "หานโพธิ์",
        "amphoe": "เขาชัยสน",
        "province": "พัทลุง",
        "zipcode": 93130,
        "district_code": 930306,
        "amphoe_code": 9303,
        "province_code": 93
    },
    {
        "district": "เขาชัยสน",
        "amphoe": "เขาชัยสน",
        "province": "พัทลุง",
        "zipcode": 93130,
        "district_code": 930301,
        "amphoe_code": 9303,
        "province_code": 93
    },
    {
        "district": "โคกม่วง",
        "amphoe": "เขาชัยสน",
        "province": "พัทลุง",
        "zipcode": 93130,
        "district_code": 930307,
        "amphoe_code": 9303,
        "province_code": 93
    },
    {
        "district": "ควนมะพร้าว",
        "amphoe": "เมืองพัทลุง",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930111,
        "amphoe_code": 9301,
        "province_code": 93
    },
    {
        "district": "คูหาสวรรค์",
        "amphoe": "เมืองพัทลุง",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930101,
        "amphoe_code": 9301,
        "province_code": 93
    },
    {
        "district": "ชัยบุรี",
        "amphoe": "เมืองพัทลุง",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930113,
        "amphoe_code": 9301,
        "province_code": 93
    },
    {
        "district": "ตำนาน",
        "amphoe": "เมืองพัทลุง",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930110,
        "amphoe_code": 9301,
        "province_code": 93
    },
    {
        "district": "ท่ามิหรำ",
        "amphoe": "เมืองพัทลุง",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930104,
        "amphoe_code": 9301,
        "province_code": 93
    },
    {
        "district": "ท่าแค",
        "amphoe": "เมืองพัทลุง",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930108,
        "amphoe_code": 9301,
        "province_code": 93
    },
    {
        "district": "นาท่อม",
        "amphoe": "เมืองพัทลุง",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930106,
        "amphoe_code": 9301,
        "province_code": 93
    },
    {
        "district": "นาโหนด",
        "amphoe": "เมืองพัทลุง",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930114,
        "amphoe_code": 9301,
        "province_code": 93
    },
    {
        "district": "ปรางหมู่",
        "amphoe": "เมืองพัทลุง",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930107,
        "amphoe_code": 9301,
        "province_code": 93
    },
    {
        "district": "พญาขัน",
        "amphoe": "เมืองพัทลุง",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930115,
        "amphoe_code": 9301,
        "province_code": 93
    },
    {
        "district": "ร่มเมือง",
        "amphoe": "เมืองพัทลุง",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930112,
        "amphoe_code": 9301,
        "province_code": 93
    },
    {
        "district": "ลำปำ",
        "amphoe": "เมืองพัทลุง",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930109,
        "amphoe_code": 9301,
        "province_code": 93
    },
    {
        "district": "เขาเจียก",
        "amphoe": "เมืองพัทลุง",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930103,
        "amphoe_code": 9301,
        "province_code": 93
    },
    {
        "district": "โคกชะงาย",
        "amphoe": "เมืองพัทลุง",
        "province": "พัทลุง",
        "zipcode": 93000,
        "district_code": 930105,
        "amphoe_code": 9301,
        "province_code": 93
    },
    {
        "district": "วังงิ้ว",
        "amphoe": "ดงเจริญ",
        "province": "พิจิตร",
        "zipcode": 66210,
        "district_code": 660513,
        "amphoe_code": 6611,
        "province_code": 66
    },
    {
        "district": "วังงิ้วใต้",
        "amphoe": "ดงเจริญ",
        "province": "พิจิตร",
        "zipcode": 66210,
        "district_code": 660515,
        "amphoe_code": 6611,
        "province_code": 66
    },
    {
        "district": "สำนักขุนเณร",
        "amphoe": "ดงเจริญ",
        "province": "พิจิตร",
        "zipcode": 66210,
        "district_code": 660510,
        "amphoe_code": 6611,
        "province_code": 66
    },
    {
        "district": "ห้วยพุก",
        "amphoe": "ดงเจริญ",
        "province": "พิจิตร",
        "zipcode": 66210,
        "district_code": 660511,
        "amphoe_code": 6611,
        "province_code": 66
    },
    {
        "district": "ห้วยร่วม",
        "amphoe": "ดงเจริญ",
        "province": "พิจิตร",
        "zipcode": 66210,
        "district_code": 660512,
        "amphoe_code": 6611,
        "province_code": 66
    },
    {
        "district": "คลองคูณ",
        "amphoe": "ตะพานหิน",
        "province": "พิจิตร",
        "zipcode": 66110,
        "district_code": 660408,
        "amphoe_code": 6604,
        "province_code": 66
    },
    {
        "district": "งิ้วราย",
        "amphoe": "ตะพานหิน",
        "province": "พิจิตร",
        "zipcode": 66110,
        "district_code": 660402,
        "amphoe_code": 6604,
        "province_code": 66
    },
    {
        "district": "ดงตะขบ",
        "amphoe": "ตะพานหิน",
        "province": "พิจิตร",
        "zipcode": 66110,
        "district_code": 660407,
        "amphoe_code": 6604,
        "province_code": 66
    },
    {
        "district": "ตะพานหิน",
        "amphoe": "ตะพานหิน",
        "province": "พิจิตร",
        "zipcode": 66110,
        "district_code": 660401,
        "amphoe_code": 6604,
        "province_code": 66
    },
    {
        "district": "ทับหมัน",
        "amphoe": "ตะพานหิน",
        "province": "พิจิตร",
        "zipcode": 66110,
        "district_code": 660412,
        "amphoe_code": 6604,
        "province_code": 66
    },
    {
        "district": "ทุ่งโพธิ์",
        "amphoe": "ตะพานหิน",
        "province": "พิจิตร",
        "zipcode": 66150,
        "district_code": 660406,
        "amphoe_code": 6604,
        "province_code": 66
    },
    {
        "district": "วังสำโรง",
        "amphoe": "ตะพานหิน",
        "province": "พิจิตร",
        "zipcode": 66110,
        "district_code": 660409,
        "amphoe_code": 6604,
        "province_code": 66
    },
    {
        "district": "วังหลุม",
        "amphoe": "ตะพานหิน",
        "province": "พิจิตร",
        "zipcode": 66150,
        "district_code": 660411,
        "amphoe_code": 6604,
        "province_code": 66
    },
    {
        "district": "วังหว้า",
        "amphoe": "ตะพานหิน",
        "province": "พิจิตร",
        "zipcode": 66110,
        "district_code": 660410,
        "amphoe_code": 6604,
        "province_code": 66
    },
    {
        "district": "หนองพยอม",
        "amphoe": "ตะพานหิน",
        "province": "พิจิตร",
        "zipcode": 66110,
        "district_code": 660405,
        "amphoe_code": 6604,
        "province_code": 66
    },
    {
        "district": "ห้วยเกตุ",
        "amphoe": "ตะพานหิน",
        "province": "พิจิตร",
        "zipcode": 66110,
        "district_code": 660403,
        "amphoe_code": 6604,
        "province_code": 66
    },
    {
        "district": "ไทรโรงโขน",
        "amphoe": "ตะพานหิน",
        "province": "พิจิตร",
        "zipcode": 66110,
        "district_code": 660404,
        "amphoe_code": 6604,
        "province_code": 66
    },
    {
        "district": "ไผ่หลวง",
        "amphoe": "ตะพานหิน",
        "province": "พิจิตร",
        "zipcode": 66110,
        "district_code": 660413,
        "amphoe_code": 6604,
        "province_code": 66
    },
    {
        "district": "ทับคล้อ",
        "amphoe": "ทับคล้อ",
        "province": "พิจิตร",
        "zipcode": 66150,
        "district_code": 660499,
        "amphoe_code": 6608,
        "province_code": 66
    },
    {
        "district": "ท้ายทุ่ง",
        "amphoe": "ทับคล้อ",
        "province": "พิจิตร",
        "zipcode": 66150,
        "district_code": 660496,
        "amphoe_code": 6608,
        "province_code": 66
    },
    {
        "district": "เขาทราย",
        "amphoe": "ทับคล้อ",
        "province": "พิจิตร",
        "zipcode": 66230,
        "district_code": 660498,
        "amphoe_code": 6608,
        "province_code": 66
    },
    {
        "district": "เขาเจ็ดลูก",
        "amphoe": "ทับคล้อ",
        "province": "พิจิตร",
        "zipcode": 66230,
        "district_code": 660497,
        "amphoe_code": 6608,
        "province_code": 66
    },
    {
        "district": "บางมูลนาก",
        "amphoe": "บางมูลนาก",
        "province": "พิจิตร",
        "zipcode": 66120,
        "district_code": 660501,
        "amphoe_code": 6605,
        "province_code": 66
    },
    {
        "district": "บางไผ่",
        "amphoe": "บางมูลนาก",
        "province": "พิจิตร",
        "zipcode": 66120,
        "district_code": 660502,
        "amphoe_code": 6605,
        "province_code": 66
    },
    {
        "district": "ภูมิ",
        "amphoe": "บางมูลนาก",
        "province": "พิจิตร",
        "zipcode": 66120,
        "district_code": 660506,
        "amphoe_code": 6605,
        "province_code": 66
    },
    {
        "district": "ลำประดา",
        "amphoe": "บางมูลนาก",
        "province": "พิจิตร",
        "zipcode": 66120,
        "district_code": 660514,
        "amphoe_code": 6605,
        "province_code": 66
    },
    {
        "district": "วังกรด",
        "amphoe": "บางมูลนาก",
        "province": "พิจิตร",
        "zipcode": 66120,
        "district_code": 660507,
        "amphoe_code": 6605,
        "province_code": 66
    },
    {
        "district": "วังตะกู",
        "amphoe": "บางมูลนาก",
        "province": "พิจิตร",
        "zipcode": 66210,
        "district_code": 660509,
        "amphoe_code": 6605,
        "province_code": 66
    },
    {
        "district": "วังสำโรง",
        "amphoe": "บางมูลนาก",
        "province": "พิจิตร",
        "zipcode": 66120,
        "district_code": 660409,
        "amphoe_code": 6605,
        "province_code": 66
    },
    {
        "district": "หอไกร",
        "amphoe": "บางมูลนาก",
        "province": "พิจิตร",
        "zipcode": 66120,
        "district_code": 660503,
        "amphoe_code": 6605,
        "province_code": 66
    },
    {
        "district": "ห้วยเขน",
        "amphoe": "บางมูลนาก",
        "province": "พิจิตร",
        "zipcode": 66120,
        "district_code": 660508,
        "amphoe_code": 6605,
        "province_code": 66
    },
    {
        "district": "เนินมะกอก",
        "amphoe": "บางมูลนาก",
        "province": "พิจิตร",
        "zipcode": 66120,
        "district_code": 660504,
        "amphoe_code": 6605,
        "province_code": 66
    },
    {
        "district": "บางลาย",
        "amphoe": "บึงนาราง",
        "province": "พิจิตร",
        "zipcode": 66130,
        "district_code": 660609,
        "amphoe_code": 6610,
        "province_code": 66
    },
    {
        "district": "บึงนาราง",
        "amphoe": "บึงนาราง",
        "province": "พิจิตร",
        "zipcode": 66130,
        "district_code": 660610,
        "amphoe_code": 6610,
        "province_code": 66
    },
    {
        "district": "ห้วยแก้ว",
        "amphoe": "บึงนาราง",
        "province": "พิจิตร",
        "zipcode": 66130,
        "district_code": 660616,
        "amphoe_code": 6610,
        "province_code": 66
    },
    {
        "district": "แหลมรัง",
        "amphoe": "บึงนาราง",
        "province": "พิจิตร",
        "zipcode": 66130,
        "district_code": 660615,
        "amphoe_code": 6610,
        "province_code": 66
    },
    {
        "district": "โพธิ์ไทรงาม",
        "amphoe": "บึงนาราง",
        "province": "พิจิตร",
        "zipcode": 66130,
        "district_code": 660614,
        "amphoe_code": 6610,
        "province_code": 66
    },
    {
        "district": "บึงบัว",
        "amphoe": "วชิรบารมี",
        "province": "พิจิตร",
        "zipcode": 66140,
        "district_code": 660709,
        "amphoe_code": 6612,
        "province_code": 66
    },
    {
        "district": "บ้านนา",
        "amphoe": "วชิรบารมี",
        "province": "พิจิตร",
        "zipcode": 66140,
        "district_code": 660705,
        "amphoe_code": 6612,
        "province_code": 66
    },
    {
        "district": "วังโมกข์",
        "amphoe": "วชิรบารมี",
        "province": "พิจิตร",
        "zipcode": 66140,
        "district_code": 660708,
        "amphoe_code": 6612,
        "province_code": 66
    },
    {
        "district": "หนองหลุม",
        "amphoe": "วชิรบารมี",
        "province": "พิจิตร",
        "zipcode": 66220,
        "district_code": 660704,
        "amphoe_code": 6612,
        "province_code": 66
    },
    {
        "district": "วังทรายพูน",
        "amphoe": "วังทรายพูน",
        "province": "พิจิตร",
        "zipcode": 66180,
        "district_code": 660199,
        "amphoe_code": 6602,
        "province_code": 66
    },
    {
        "district": "หนองปลาไหล",
        "amphoe": "วังทรายพูน",
        "province": "พิจิตร",
        "zipcode": 66180,
        "district_code": 660198,
        "amphoe_code": 6602,
        "province_code": 66
    },
    {
        "district": "หนองปล้อง",
        "amphoe": "วังทรายพูน",
        "province": "พิจิตร",
        "zipcode": 66180,
        "district_code": 660114,
        "amphoe_code": 6602,
        "province_code": 66
    },
    {
        "district": "หนองพระ",
        "amphoe": "วังทรายพูน",
        "province": "พิจิตร",
        "zipcode": 66180,
        "district_code": 660197,
        "amphoe_code": 6602,
        "province_code": 66
    },
    {
        "district": "คลองทราย",
        "amphoe": "สากเหล็ก",
        "province": "พิจิตร",
        "zipcode": 66160,
        "district_code": 660118,
        "amphoe_code": 6609,
        "province_code": 66
    },
    {
        "district": "ท่าเยี่ยม",
        "amphoe": "สากเหล็ก",
        "province": "พิจิตร",
        "zipcode": 66160,
        "district_code": 660117,
        "amphoe_code": 6609,
        "province_code": 66
    },
    {
        "district": "วังทับไทร",
        "amphoe": "สากเหล็ก",
        "province": "พิจิตร",
        "zipcode": 66160,
        "district_code": 660905,
        "amphoe_code": 6609,
        "province_code": 66
    },
    {
        "district": "สากเหล็ก",
        "amphoe": "สากเหล็ก",
        "province": "พิจิตร",
        "zipcode": 66160,
        "district_code": 660116,
        "amphoe_code": 6609,
        "province_code": 66
    },
    {
        "district": "หนองหญ้าไทร",
        "amphoe": "สากเหล็ก",
        "province": "พิจิตร",
        "zipcode": 66160,
        "district_code": 660904,
        "amphoe_code": 6609,
        "province_code": 66
    },
    {
        "district": "กำแพงดิน",
        "amphoe": "สามง่าม",
        "province": "พิจิตร",
        "zipcode": 66220,
        "district_code": 660702,
        "amphoe_code": 6607,
        "province_code": 66
    },
    {
        "district": "รังนก",
        "amphoe": "สามง่าม",
        "province": "พิจิตร",
        "zipcode": 66140,
        "district_code": 660703,
        "amphoe_code": 6607,
        "province_code": 66
    },
    {
        "district": "สามง่าม",
        "amphoe": "สามง่าม",
        "province": "พิจิตร",
        "zipcode": 66140,
        "district_code": 660701,
        "amphoe_code": 6607,
        "province_code": 66
    },
    {
        "district": "หนองโสน",
        "amphoe": "สามง่าม",
        "province": "พิจิตร",
        "zipcode": 66140,
        "district_code": 660707,
        "amphoe_code": 6607,
        "province_code": 66
    },
    {
        "district": "เนินปอ",
        "amphoe": "สามง่าม",
        "province": "พิจิตร",
        "zipcode": 66140,
        "district_code": 660706,
        "amphoe_code": 6607,
        "province_code": 66
    },
    {
        "district": "คลองคะเชนทร์",
        "amphoe": "เมืองพิจิตร",
        "province": "พิจิตร",
        "zipcode": 66000,
        "district_code": 660106,
        "amphoe_code": 6601,
        "province_code": 66
    },
    {
        "district": "ฆะมัง",
        "amphoe": "เมืองพิจิตร",
        "province": "พิจิตร",
        "zipcode": 66000,
        "district_code": 660111,
        "amphoe_code": 6601,
        "province_code": 66
    },
    {
        "district": "ดงกลาง",
        "amphoe": "เมืองพิจิตร",
        "province": "พิจิตร",
        "zipcode": 66170,
        "district_code": 660120,
        "amphoe_code": 6601,
        "province_code": 66
    },
    {
        "district": "ดงป่าคำ",
        "amphoe": "เมืองพิจิตร",
        "province": "พิจิตร",
        "zipcode": 66170,
        "district_code": 660112,
        "amphoe_code": 6601,
        "province_code": 66
    },
    {
        "district": "ท่าหลวง",
        "amphoe": "เมืองพิจิตร",
        "province": "พิจิตร",
        "zipcode": 66000,
        "district_code": 660109,
        "amphoe_code": 6601,
        "province_code": 66
    },
    {
        "district": "ท่าฬ่อ",
        "amphoe": "เมืองพิจิตร",
        "province": "พิจิตร",
        "zipcode": 66000,
        "district_code": 660104,
        "amphoe_code": 6601,
        "province_code": 66
    },
    {
        "district": "บ้านบุ่ง",
        "amphoe": "เมืองพิจิตร",
        "province": "พิจิตร",
        "zipcode": 66000,
        "district_code": 660110,
        "amphoe_code": 6601,
        "province_code": 66
    },
    {
        "district": "ปากทาง",
        "amphoe": "เมืองพิจิตร",
        "province": "พิจิตร",
        "zipcode": 66000,
        "district_code": 660105,
        "amphoe_code": 6601,
        "province_code": 66
    },
    {
        "district": "ป่ามะคาบ",
        "amphoe": "เมืองพิจิตร",
        "province": "พิจิตร",
        "zipcode": 66000,
        "district_code": 660115,
        "amphoe_code": 6601,
        "province_code": 66
    },
    {
        "district": "ย่านยาว",
        "amphoe": "เมืองพิจิตร",
        "province": "พิจิตร",
        "zipcode": 66000,
        "district_code": 660103,
        "amphoe_code": 6601,
        "province_code": 66
    },
    {
        "district": "สายคำโห้",
        "amphoe": "เมืองพิจิตร",
        "province": "พิจิตร",
        "zipcode": 66000,
        "district_code": 660119,
        "amphoe_code": 6601,
        "province_code": 66
    },
    {
        "district": "หัวดง",
        "amphoe": "เมืองพิจิตร",
        "province": "พิจิตร",
        "zipcode": 66170,
        "district_code": 660113,
        "amphoe_code": 6601,
        "province_code": 66
    },
    {
        "district": "เมืองเก่า",
        "amphoe": "เมืองพิจิตร",
        "province": "พิจิตร",
        "zipcode": 66000,
        "district_code": 660108,
        "amphoe_code": 6601,
        "province_code": 66
    },
    {
        "district": "โรงช้าง",
        "amphoe": "เมืองพิจิตร",
        "province": "พิจิตร",
        "zipcode": 66000,
        "district_code": 660107,
        "amphoe_code": 6601,
        "province_code": 66
    },
    {
        "district": "ในเมือง",
        "amphoe": "เมืองพิจิตร",
        "province": "พิจิตร",
        "zipcode": 66000,
        "district_code": 660101,
        "amphoe_code": 6601,
        "province_code": 66
    },
    {
        "district": "ไผ่ขวาง",
        "amphoe": "เมืองพิจิตร",
        "province": "พิจิตร",
        "zipcode": 66000,
        "district_code": 660102,
        "amphoe_code": 6601,
        "province_code": 66
    },
    {
        "district": "ทะนง",
        "amphoe": "โพทะเล",
        "province": "พิจิตร",
        "zipcode": 66130,
        "district_code": 660603,
        "amphoe_code": 6606,
        "province_code": 66
    },
    {
        "district": "ทุ่งน้อย",
        "amphoe": "โพทะเล",
        "province": "พิจิตร",
        "zipcode": 66130,
        "district_code": 660605,
        "amphoe_code": 6606,
        "province_code": 66
    },
    {
        "district": "ท่าขมิ้น",
        "amphoe": "โพทะเล",
        "province": "พิจิตร",
        "zipcode": 66130,
        "district_code": 660606,
        "amphoe_code": 6606,
        "province_code": 66
    },
    {
        "district": "ท่านั่ง",
        "amphoe": "โพทะเล",
        "province": "พิจิตร",
        "zipcode": 66130,
        "district_code": 660611,
        "amphoe_code": 6606,
        "province_code": 66
    },
    {
        "district": "ท่าบัว",
        "amphoe": "โพทะเล",
        "province": "พิจิตร",
        "zipcode": 66130,
        "district_code": 660604,
        "amphoe_code": 6606,
        "province_code": 66
    },
    {
        "district": "ท่าเสา",
        "amphoe": "โพทะเล",
        "province": "พิจิตร",
        "zipcode": 66130,
        "district_code": 660607,
        "amphoe_code": 6606,
        "province_code": 66
    },
    {
        "district": "ท้ายน้ำ",
        "amphoe": "โพทะเล",
        "province": "พิจิตร",
        "zipcode": 66130,
        "district_code": 660602,
        "amphoe_code": 6606,
        "province_code": 66
    },
    {
        "district": "บางคลาน",
        "amphoe": "โพทะเล",
        "province": "พิจิตร",
        "zipcode": 66130,
        "district_code": 660608,
        "amphoe_code": 6606,
        "province_code": 66
    },
    {
        "district": "บ้านน้อย",
        "amphoe": "โพทะเล",
        "province": "พิจิตร",
        "zipcode": 66130,
        "district_code": 660612,
        "amphoe_code": 6606,
        "province_code": 66
    },
    {
        "district": "วัดขวาง",
        "amphoe": "โพทะเล",
        "province": "พิจิตร",
        "zipcode": 66130,
        "district_code": 660613,
        "amphoe_code": 6606,
        "province_code": 66
    },
    {
        "district": "โพทะเล",
        "amphoe": "โพทะเล",
        "province": "พิจิตร",
        "zipcode": 66130,
        "district_code": 660601,
        "amphoe_code": 6606,
        "province_code": 66
    },
    {
        "district": "ดงเสือเหลือง",
        "amphoe": "โพธิ์ประทับช้าง",
        "province": "พิจิตร",
        "zipcode": 66190,
        "district_code": 660305,
        "amphoe_code": 6603,
        "province_code": 66
    },
    {
        "district": "ทุ่งใหญ่",
        "amphoe": "โพธิ์ประทับช้าง",
        "province": "พิจิตร",
        "zipcode": 66190,
        "district_code": 660307,
        "amphoe_code": 6603,
        "province_code": 66
    },
    {
        "district": "วังจิก",
        "amphoe": "โพธิ์ประทับช้าง",
        "province": "พิจิตร",
        "zipcode": 66190,
        "district_code": 660193,
        "amphoe_code": 6603,
        "province_code": 66
    },
    {
        "district": "เนินสว่าง",
        "amphoe": "โพธิ์ประทับช้าง",
        "province": "พิจิตร",
        "zipcode": 66190,
        "district_code": 660306,
        "amphoe_code": 6603,
        "province_code": 66
    },
    {
        "district": "โพธิ์ประทับช้าง",
        "amphoe": "โพธิ์ประทับช้าง",
        "province": "พิจิตร",
        "zipcode": 66190,
        "district_code": 660194,
        "amphoe_code": 6603,
        "province_code": 66
    },
    {
        "district": "ไผ่ท่าโพ",
        "amphoe": "โพธิ์ประทับช้าง",
        "province": "พิจิตร",
        "zipcode": 66190,
        "district_code": 660195,
        "amphoe_code": 6603,
        "province_code": 66
    },
    {
        "district": "ไผ่รอบ",
        "amphoe": "โพธิ์ประทับช้าง",
        "province": "พิจิตร",
        "zipcode": 66190,
        "district_code": 660192,
        "amphoe_code": 6603,
        "province_code": 66
    },
    {
        "district": "ชาติตระการ",
        "amphoe": "ชาติตระการ",
        "province": "พิษณุโลก",
        "zipcode": 65170,
        "district_code": 650302,
        "amphoe_code": 6503,
        "province_code": 65
    },
    {
        "district": "ท่าสะแก",
        "amphoe": "ชาติตระการ",
        "province": "พิษณุโลก",
        "zipcode": 65170,
        "district_code": 650306,
        "amphoe_code": 6503,
        "province_code": 65
    },
    {
        "district": "บ่อภาค",
        "amphoe": "ชาติตระการ",
        "province": "พิษณุโลก",
        "zipcode": 65170,
        "district_code": 650305,
        "amphoe_code": 6503,
        "province_code": 65
    },
    {
        "district": "บ้านดง",
        "amphoe": "ชาติตระการ",
        "province": "พิษณุโลก",
        "zipcode": 65170,
        "district_code": 650304,
        "amphoe_code": 6503,
        "province_code": 65
    },
    {
        "district": "ป่าแดง",
        "amphoe": "ชาติตระการ",
        "province": "พิษณุโลก",
        "zipcode": 65170,
        "district_code": 650301,
        "amphoe_code": 6503,
        "province_code": 65
    },
    {
        "district": "สวนเมี่ยง",
        "amphoe": "ชาติตระการ",
        "province": "พิษณุโลก",
        "zipcode": 65170,
        "district_code": 650303,
        "amphoe_code": 6503,
        "province_code": 65
    },
    {
        "district": "นครชุม",
        "amphoe": "นครไทย",
        "province": "พิษณุโลก",
        "zipcode": 65120,
        "district_code": 650206,
        "amphoe_code": 6502,
        "province_code": 65
    },
    {
        "district": "นครไทย",
        "amphoe": "นครไทย",
        "province": "พิษณุโลก",
        "zipcode": 65120,
        "district_code": 650201,
        "amphoe_code": 6502,
        "province_code": 65
    },
    {
        "district": "นาบัว",
        "amphoe": "นครไทย",
        "province": "พิษณุโลก",
        "zipcode": 65120,
        "district_code": 650205,
        "amphoe_code": 6502,
        "province_code": 65
    },
    {
        "district": "น้ำกุ่ม",
        "amphoe": "นครไทย",
        "province": "พิษณุโลก",
        "zipcode": 65120,
        "district_code": 650207,
        "amphoe_code": 6502,
        "province_code": 65
    },
    {
        "district": "บ่อโพธิ์",
        "amphoe": "นครไทย",
        "province": "พิษณุโลก",
        "zipcode": 65120,
        "district_code": 650209,
        "amphoe_code": 6502,
        "province_code": 65
    },
    {
        "district": "บ้านพร้าว",
        "amphoe": "นครไทย",
        "province": "พิษณุโลก",
        "zipcode": 65120,
        "district_code": 650210,
        "amphoe_code": 6502,
        "province_code": 65
    },
    {
        "district": "บ้านแยง",
        "amphoe": "นครไทย",
        "province": "พิษณุโลก",
        "zipcode": 65120,
        "district_code": 650203,
        "amphoe_code": 6502,
        "province_code": 65
    },
    {
        "district": "ยางโกลน",
        "amphoe": "นครไทย",
        "province": "พิษณุโลก",
        "zipcode": 65120,
        "district_code": 650208,
        "amphoe_code": 6502,
        "province_code": 65
    },
    {
        "district": "หนองกะท้าว",
        "amphoe": "นครไทย",
        "province": "พิษณุโลก",
        "zipcode": 65120,
        "district_code": 650202,
        "amphoe_code": 6502,
        "province_code": 65
    },
    {
        "district": "ห้วยเฮี้ย",
        "amphoe": "นครไทย",
        "province": "พิษณุโลก",
        "zipcode": 65120,
        "district_code": 650211,
        "amphoe_code": 6502,
        "province_code": 65
    },
    {
        "district": "เนินเพิ่ม",
        "amphoe": "นครไทย",
        "province": "พิษณุโลก",
        "zipcode": 65120,
        "district_code": 650204,
        "amphoe_code": 6502,
        "province_code": 65
    },
    {
        "district": "ท่าตาล",
        "amphoe": "บางกระทุ่ม",
        "province": "พิษณุโลก",
        "zipcode": 65110,
        "district_code": 650505,
        "amphoe_code": 6505,
        "province_code": 65
    },
    {
        "district": "นครป่าหมาก",
        "amphoe": "บางกระทุ่ม",
        "province": "พิษณุโลก",
        "zipcode": 65110,
        "district_code": 650507,
        "amphoe_code": 6505,
        "province_code": 65
    },
    {
        "district": "บางกระทุ่ม",
        "amphoe": "บางกระทุ่ม",
        "province": "พิษณุโลก",
        "zipcode": 65110,
        "district_code": 650501,
        "amphoe_code": 6505,
        "province_code": 65
    },
    {
        "district": "บ้านไร่",
        "amphoe": "บางกระทุ่ม",
        "province": "พิษณุโลก",
        "zipcode": 65110,
        "district_code": 650502,
        "amphoe_code": 6505,
        "province_code": 65
    },
    {
        "district": "วัดตายม",
        "amphoe": "บางกระทุ่ม",
        "province": "พิษณุโลก",
        "zipcode": 65210,
        "district_code": 650509,
        "amphoe_code": 6505,
        "province_code": 65
    },
    {
        "district": "สนามคลี",
        "amphoe": "บางกระทุ่ม",
        "province": "พิษณุโลก",
        "zipcode": 65110,
        "district_code": 650504,
        "amphoe_code": 6505,
        "province_code": 65
    },
    {
        "district": "เนินกุ่ม",
        "amphoe": "บางกระทุ่ม",
        "province": "พิษณุโลก",
        "zipcode": 65210,
        "district_code": 650508,
        "amphoe_code": 6505,
        "province_code": 65
    },
    {
        "district": "โคกสลุด",
        "amphoe": "บางกระทุ่ม",
        "province": "พิษณุโลก",
        "zipcode": 65110,
        "district_code": 650503,
        "amphoe_code": 6505,
        "province_code": 65
    },
    {
        "district": "ไผ่ล้อม",
        "amphoe": "บางกระทุ่ม",
        "province": "พิษณุโลก",
        "zipcode": 65110,
        "district_code": 650506,
        "amphoe_code": 6505,
        "province_code": 65
    },
    {
        "district": "คุยม่วง",
        "amphoe": "บางระกำ",
        "province": "พิษณุโลก",
        "zipcode": 65240,
        "district_code": 650411,
        "amphoe_code": 6504,
        "province_code": 65
    },
    {
        "district": "ชุมแสงสงคราม",
        "amphoe": "บางระกำ",
        "province": "พิษณุโลก",
        "zipcode": 65240,
        "district_code": 650407,
        "amphoe_code": 6504,
        "province_code": 65
    },
    {
        "district": "ท่านางงาม",
        "amphoe": "บางระกำ",
        "province": "พิษณุโลก",
        "zipcode": 65140,
        "district_code": 650410,
        "amphoe_code": 6504,
        "province_code": 65
    },
    {
        "district": "นิคมพัฒนา",
        "amphoe": "บางระกำ",
        "province": "พิษณุโลก",
        "zipcode": 65140,
        "district_code": 650408,
        "amphoe_code": 6504,
        "province_code": 65
    },
    {
        "district": "บางระกำ",
        "amphoe": "บางระกำ",
        "province": "พิษณุโลก",
        "zipcode": 65140,
        "district_code": 650401,
        "amphoe_code": 6504,
        "province_code": 65
    },
    {
        "district": "บึงกอก",
        "amphoe": "บางระกำ",
        "province": "พิษณุโลก",
        "zipcode": 65140,
        "district_code": 650405,
        "amphoe_code": 6504,
        "province_code": 65
    },
    {
        "district": "บ่อทอง",
        "amphoe": "บางระกำ",
        "province": "พิษณุโลก",
        "zipcode": 65140,
        "district_code": 650409,
        "amphoe_code": 6504,
        "province_code": 65
    },
    {
        "district": "ปลักแรด",
        "amphoe": "บางระกำ",
        "province": "พิษณุโลก",
        "zipcode": 65140,
        "district_code": 650402,
        "amphoe_code": 6504,
        "province_code": 65
    },
    {
        "district": "พันเสา",
        "amphoe": "บางระกำ",
        "province": "พิษณุโลก",
        "zipcode": 65140,
        "district_code": 650403,
        "amphoe_code": 6504,
        "province_code": 65
    },
    {
        "district": "วังอิทก",
        "amphoe": "บางระกำ",
        "province": "พิษณุโลก",
        "zipcode": 65140,
        "district_code": 650404,
        "amphoe_code": 6504,
        "province_code": 65
    },
    {
        "district": "หนองกุลา",
        "amphoe": "บางระกำ",
        "province": "พิษณุโลก",
        "zipcode": 65140,
        "district_code": 650406,
        "amphoe_code": 6504,
        "province_code": 65
    },
    {
        "district": "ดงประคำ",
        "amphoe": "พรหมพิราม",
        "province": "พิษณุโลก",
        "zipcode": 65180,
        "district_code": 650612,
        "amphoe_code": 6506,
        "province_code": 65
    },
    {
        "district": "ตลุกเทียม",
        "amphoe": "พรหมพิราม",
        "province": "พิษณุโลก",
        "zipcode": 65180,
        "district_code": 650607,
        "amphoe_code": 6506,
        "province_code": 65
    },
    {
        "district": "ทับยายเชียง",
        "amphoe": "พรหมพิราม",
        "province": "พิษณุโลก",
        "zipcode": 65150,
        "district_code": 650611,
        "amphoe_code": 6506,
        "province_code": 65
    },
    {
        "district": "ท่าช้าง",
        "amphoe": "พรหมพิราม",
        "province": "พิษณุโลก",
        "zipcode": 65150,
        "district_code": 650602,
        "amphoe_code": 6506,
        "province_code": 65
    },
    {
        "district": "พรหมพิราม",
        "amphoe": "พรหมพิราม",
        "province": "พิษณุโลก",
        "zipcode": 65150,
        "district_code": 650601,
        "amphoe_code": 6506,
        "province_code": 65
    },
    {
        "district": "มะตูม",
        "amphoe": "พรหมพิราม",
        "province": "พิษณุโลก",
        "zipcode": 65150,
        "district_code": 650604,
        "amphoe_code": 6506,
        "province_code": 65
    },
    {
        "district": "มะต้อง",
        "amphoe": "พรหมพิราม",
        "province": "พิษณุโลก",
        "zipcode": 65180,
        "district_code": 650610,
        "amphoe_code": 6506,
        "province_code": 65
    },
    {
        "district": "วงฆ้อง",
        "amphoe": "พรหมพิราม",
        "province": "พิษณุโลก",
        "zipcode": 65180,
        "district_code": 650603,
        "amphoe_code": 6506,
        "province_code": 65
    },
    {
        "district": "วังวน",
        "amphoe": "พรหมพิราม",
        "province": "พิษณุโลก",
        "zipcode": 65150,
        "district_code": 650608,
        "amphoe_code": 6506,
        "province_code": 65
    },
    {
        "district": "ศรีภิรมย์",
        "amphoe": "พรหมพิราม",
        "province": "พิษณุโลก",
        "zipcode": 65180,
        "district_code": 650606,
        "amphoe_code": 6506,
        "province_code": 65
    },
    {
        "district": "หนองแขม",
        "amphoe": "พรหมพิราม",
        "province": "พิษณุโลก",
        "zipcode": 65150,
        "district_code": 650609,
        "amphoe_code": 6506,
        "province_code": 65
    },
    {
        "district": "หอกลอง",
        "amphoe": "พรหมพิราม",
        "province": "พิษณุโลก",
        "zipcode": 65150,
        "district_code": 650605,
        "amphoe_code": 6506,
        "province_code": 65
    },
    {
        "district": "ชัยนาม",
        "amphoe": "วังทอง",
        "province": "พิษณุโลก",
        "zipcode": 65130,
        "district_code": 650810,
        "amphoe_code": 6508,
        "province_code": 65
    },
    {
        "district": "ดินทอง",
        "amphoe": "วังทอง",
        "province": "พิษณุโลก",
        "zipcode": 65130,
        "district_code": 650811,
        "amphoe_code": 6508,
        "province_code": 65
    },
    {
        "district": "ท่าหมื่นราม",
        "amphoe": "วังทอง",
        "province": "พิษณุโลก",
        "zipcode": 65130,
        "district_code": 650807,
        "amphoe_code": 6508,
        "province_code": 65
    },
    {
        "district": "บ้านกลาง",
        "amphoe": "วังทอง",
        "province": "พิษณุโลก",
        "zipcode": 65220,
        "district_code": 650804,
        "amphoe_code": 6508,
        "province_code": 65
    },
    {
        "district": "พันชาลี",
        "amphoe": "วังทอง",
        "province": "พิษณุโลก",
        "zipcode": 65130,
        "district_code": 650802,
        "amphoe_code": 6508,
        "province_code": 65
    },
    {
        "district": "วังทอง",
        "amphoe": "วังทอง",
        "province": "พิษณุโลก",
        "zipcode": 65130,
        "district_code": 650801,
        "amphoe_code": 6508,
        "province_code": 65
    },
    {
        "district": "วังนกแอ่น",
        "amphoe": "วังทอง",
        "province": "พิษณุโลก",
        "zipcode": 65130,
        "district_code": 650808,
        "amphoe_code": 6508,
        "province_code": 65
    },
    {
        "district": "วังพิกุล",
        "amphoe": "วังทอง",
        "province": "พิษณุโลก",
        "zipcode": 65130,
        "district_code": 650805,
        "amphoe_code": 6508,
        "province_code": 65
    },
    {
        "district": "หนองพระ",
        "amphoe": "วังทอง",
        "province": "พิษณุโลก",
        "zipcode": 65130,
        "district_code": 650809,
        "amphoe_code": 6508,
        "province_code": 65
    },
    {
        "district": "แก่งโสภา",
        "amphoe": "วังทอง",
        "province": "พิษณุโลก",
        "zipcode": 65220,
        "district_code": 650806,
        "amphoe_code": 6508,
        "province_code": 65
    },
    {
        "district": "แม่ระกา",
        "amphoe": "วังทอง",
        "province": "พิษณุโลก",
        "zipcode": 65130,
        "district_code": 650803,
        "amphoe_code": 6508,
        "province_code": 65
    },
    {
        "district": "คันโช้ง",
        "amphoe": "วัดโบสถ์",
        "province": "พิษณุโลก",
        "zipcode": 65160,
        "district_code": 650706,
        "amphoe_code": 6507,
        "province_code": 65
    },
    {
        "district": "ท่างาม",
        "amphoe": "วัดโบสถ์",
        "province": "พิษณุโลก",
        "zipcode": 65160,
        "district_code": 650702,
        "amphoe_code": 6507,
        "province_code": 65
    },
    {
        "district": "ท้อแท้",
        "amphoe": "วัดโบสถ์",
        "province": "พิษณุโลก",
        "zipcode": 65160,
        "district_code": 650703,
        "amphoe_code": 6507,
        "province_code": 65
    },
    {
        "district": "บ้านยาง",
        "amphoe": "วัดโบสถ์",
        "province": "พิษณุโลก",
        "zipcode": 65160,
        "district_code": 650704,
        "amphoe_code": 6507,
        "province_code": 65
    },
    {
        "district": "วัดโบสถ์",
        "amphoe": "วัดโบสถ์",
        "province": "พิษณุโลก",
        "zipcode": 65160,
        "district_code": 650701,
        "amphoe_code": 6507,
        "province_code": 65
    },
    {
        "district": "หินลาด",
        "amphoe": "วัดโบสถ์",
        "province": "พิษณุโลก",
        "zipcode": 65160,
        "district_code": 650705,
        "amphoe_code": 6507,
        "province_code": 65
    },
    {
        "district": "ชมพู",
        "amphoe": "เนินมะปราง",
        "province": "พิษณุโลก",
        "zipcode": 65190,
        "district_code": 650899,
        "amphoe_code": 6509,
        "province_code": 65
    },
    {
        "district": "บ้านน้อยซุ้มขี้เหล็ก",
        "amphoe": "เนินมะปราง",
        "province": "พิษณุโลก",
        "zipcode": 65190,
        "district_code": 650895,
        "amphoe_code": 6509,
        "province_code": 65
    },
    {
        "district": "บ้านมุง",
        "amphoe": "เนินมะปราง",
        "province": "พิษณุโลก",
        "zipcode": 65190,
        "district_code": 650898,
        "amphoe_code": 6509,
        "province_code": 65
    },
    {
        "district": "วังยาง",
        "amphoe": "เนินมะปราง",
        "province": "พิษณุโลก",
        "zipcode": 65190,
        "district_code": 650907,
        "amphoe_code": 6509,
        "province_code": 65
    },
    {
        "district": "วังโพรง",
        "amphoe": "เนินมะปราง",
        "province": "พิษณุโลก",
        "zipcode": 65190,
        "district_code": 650896,
        "amphoe_code": 6509,
        "province_code": 65
    },
    {
        "district": "เนินมะปราง",
        "amphoe": "เนินมะปราง",
        "province": "พิษณุโลก",
        "zipcode": 65190,
        "district_code": 650906,
        "amphoe_code": 6509,
        "province_code": 65
    },
    {
        "district": "ไทรย้อย",
        "amphoe": "เนินมะปราง",
        "province": "พิษณุโลก",
        "zipcode": 65190,
        "district_code": 650897,
        "amphoe_code": 6509,
        "province_code": 65
    },
    {
        "district": "งิ้วงาม",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65230,
        "district_code": 650120,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "จอมทอง",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65000,
        "district_code": 650112,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "ดอนทอง",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65000,
        "district_code": 650108,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "ท่าทอง",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65000,
        "district_code": 650105,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "ท่าโพธิ์",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65000,
        "district_code": 650106,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "บึงพระ",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65000,
        "district_code": 650118,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "บ้านกร่าง",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65000,
        "district_code": 650113,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "บ้านคลอง",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65000,
        "district_code": 650114,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "บ้านป่า",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65000,
        "district_code": 650109,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "ปากโทก",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65000,
        "district_code": 650110,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "พลายชุมพล",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65000,
        "district_code": 650115,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "มะขามสูง",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65000,
        "district_code": 650116,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "วังน้ำคู้",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65230,
        "district_code": 650102,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "วัดจันทร์",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65000,
        "district_code": 650103,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "วัดพริก",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65230,
        "district_code": 650104,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "สมอแข",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65000,
        "district_code": 650107,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "หัวรอ",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65000,
        "district_code": 650111,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "อรัญญิก",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65000,
        "district_code": 650117,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "ในเมือง",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65000,
        "district_code": 650101,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "ไผ่ขอดอน",
        "amphoe": "เมืองพิษณุโลก",
        "province": "พิษณุโลก",
        "zipcode": 65000,
        "district_code": 650119,
        "amphoe_code": 6501,
        "province_code": 65
    },
    {
        "district": "กมลา",
        "amphoe": "กะทู้",
        "province": "ภูเก็ต",
        "zipcode": 83120,
        "district_code": 830203,
        "amphoe_code": 8302,
        "province_code": 83
    },
    {
        "district": "กะทู้",
        "amphoe": "กะทู้",
        "province": "ภูเก็ต",
        "zipcode": 83120,
        "district_code": 830201,
        "amphoe_code": 8302,
        "province_code": 83
    },
    {
        "district": "ป่าตอง",
        "amphoe": "กะทู้",
        "province": "ภูเก็ต",
        "zipcode": 83150,
        "district_code": 830202,
        "amphoe_code": 8302,
        "province_code": 83
    },
    {
        "district": "ป่าคลอก",
        "amphoe": "ถลาง",
        "province": "ภูเก็ต",
        "zipcode": 83110,
        "district_code": 830304,
        "amphoe_code": 8303,
        "province_code": 83
    },
    {
        "district": "ศรีสุนทร",
        "amphoe": "ถลาง",
        "province": "ภูเก็ต",
        "zipcode": 83110,
        "district_code": 830302,
        "amphoe_code": 8303,
        "province_code": 83
    },
    {
        "district": "สาคู",
        "amphoe": "ถลาง",
        "province": "ภูเก็ต",
        "zipcode": 83110,
        "district_code": 830306,
        "amphoe_code": 8303,
        "province_code": 83
    },
    {
        "district": "เชิงทะเล",
        "amphoe": "ถลาง",
        "province": "ภูเก็ต",
        "zipcode": 83110,
        "district_code": 830303,
        "amphoe_code": 8303,
        "province_code": 83
    },
    {
        "district": "เทพกระษัตรี",
        "amphoe": "ถลาง",
        "province": "ภูเก็ต",
        "zipcode": 83110,
        "district_code": 830301,
        "amphoe_code": 8303,
        "province_code": 83
    },
    {
        "district": "ไม้ขาว",
        "amphoe": "ถลาง",
        "province": "ภูเก็ต",
        "zipcode": 83110,
        "district_code": 830305,
        "amphoe_code": 8303,
        "province_code": 83
    },
    {
        "district": "กะรน",
        "amphoe": "เมืองภูเก็ต",
        "province": "ภูเก็ต",
        "zipcode": 83100,
        "district_code": 830108,
        "amphoe_code": 8301,
        "province_code": 83
    },
    {
        "district": "ฉลอง",
        "amphoe": "เมืองภูเก็ต",
        "province": "ภูเก็ต",
        "zipcode": 83000,
        "district_code": 830106,
        "amphoe_code": 8301,
        "province_code": 83
    },
    {
        "district": "ฉลอง",
        "amphoe": "เมืองภูเก็ต",
        "province": "ภูเก็ต",
        "zipcode": 83130,
        "district_code": 830106,
        "amphoe_code": 8301,
        "province_code": 83
    },
    {
        "district": "ตลาดเหนือ",
        "amphoe": "เมืองภูเก็ต",
        "province": "ภูเก็ต",
        "zipcode": 83000,
        "district_code": 830102,
        "amphoe_code": 8301,
        "province_code": 83
    },
    {
        "district": "ตลาดใหญ่",
        "amphoe": "เมืองภูเก็ต",
        "province": "ภูเก็ต",
        "zipcode": 83000,
        "district_code": 830101,
        "amphoe_code": 8301,
        "province_code": 83
    },
    {
        "district": "รัษฎา",
        "amphoe": "เมืองภูเก็ต",
        "province": "ภูเก็ต",
        "zipcode": 83000,
        "district_code": 830104,
        "amphoe_code": 8301,
        "province_code": 83
    },
    {
        "district": "ราไวย์",
        "amphoe": "เมืองภูเก็ต",
        "province": "ภูเก็ต",
        "zipcode": 83100,
        "district_code": 830107,
        "amphoe_code": 8301,
        "province_code": 83
    },
    {
        "district": "ราไวย์",
        "amphoe": "เมืองภูเก็ต",
        "province": "ภูเก็ต",
        "zipcode": 83130,
        "district_code": 830107,
        "amphoe_code": 8301,
        "province_code": 83
    },
    {
        "district": "วิชิต",
        "amphoe": "เมืองภูเก็ต",
        "province": "ภูเก็ต",
        "zipcode": 83000,
        "district_code": 830105,
        "amphoe_code": 8301,
        "province_code": 83
    },
    {
        "district": "เกาะแก้ว",
        "amphoe": "เมืองภูเก็ต",
        "province": "ภูเก็ต",
        "zipcode": 83000,
        "district_code": 830103,
        "amphoe_code": 8301,
        "province_code": 83
    },
    {
        "district": "กุดใส้จ่อ",
        "amphoe": "กันทรวิชัย",
        "province": "มหาสารคาม",
        "zipcode": 44150,
        "district_code": 440409,
        "amphoe_code": 4404,
        "province_code": 44
    },
    {
        "district": "ขามเฒ่าพัฒนา",
        "amphoe": "กันทรวิชัย",
        "province": "มหาสารคาม",
        "zipcode": 44150,
        "district_code": 440410,
        "amphoe_code": 4404,
        "province_code": 44
    },
    {
        "district": "ขามเรียง",
        "amphoe": "กันทรวิชัย",
        "province": "มหาสารคาม",
        "zipcode": 44150,
        "district_code": 440406,
        "amphoe_code": 4404,
        "province_code": 44
    },
    {
        "district": "คันธารราษฎร์",
        "amphoe": "กันทรวิชัย",
        "province": "มหาสารคาม",
        "zipcode": 44150,
        "district_code": 440402,
        "amphoe_code": 4404,
        "province_code": 44
    },
    {
        "district": "ท่าขอนยาง",
        "amphoe": "กันทรวิชัย",
        "province": "มหาสารคาม",
        "zipcode": 44150,
        "district_code": 440404,
        "amphoe_code": 4404,
        "province_code": 44
    },
    {
        "district": "นาสีนวน",
        "amphoe": "กันทรวิชัย",
        "province": "มหาสารคาม",
        "zipcode": 44150,
        "district_code": 440405,
        "amphoe_code": 4404,
        "province_code": 44
    },
    {
        "district": "มะค่า",
        "amphoe": "กันทรวิชัย",
        "province": "มหาสารคาม",
        "zipcode": 44150,
        "district_code": 440403,
        "amphoe_code": 4404,
        "province_code": 44
    },
    {
        "district": "ศรีสุข",
        "amphoe": "กันทรวิชัย",
        "province": "มหาสารคาม",
        "zipcode": 44150,
        "district_code": 440408,
        "amphoe_code": 4404,
        "province_code": 44
    },
    {
        "district": "เขวาใหญ่",
        "amphoe": "กันทรวิชัย",
        "province": "มหาสารคาม",
        "zipcode": 44150,
        "district_code": 440407,
        "amphoe_code": 4404,
        "province_code": 44
    },
    {
        "district": "โคกพระ",
        "amphoe": "กันทรวิชัย",
        "province": "มหาสารคาม",
        "zipcode": 44150,
        "district_code": 440401,
        "amphoe_code": 4404,
        "province_code": 44
    },
    {
        "district": "กุดรัง",
        "amphoe": "กุดรัง",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440603,
        "amphoe_code": 4412,
        "province_code": 44
    },
    {
        "district": "นาโพธิ์",
        "amphoe": "กุดรัง",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440612,
        "amphoe_code": 4412,
        "province_code": 44
    },
    {
        "district": "หนองแวง",
        "amphoe": "กุดรัง",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440614,
        "amphoe_code": 4412,
        "province_code": 44
    },
    {
        "district": "ห้วยเตย",
        "amphoe": "กุดรัง",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440617,
        "amphoe_code": 4412,
        "province_code": 44
    },
    {
        "district": "เลิงแฝก",
        "amphoe": "กุดรัง",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440609,
        "amphoe_code": 4412,
        "province_code": 44
    },
    {
        "district": "กุดปลาดุก",
        "amphoe": "ชื่นชม",
        "province": "มหาสารคาม",
        "zipcode": 44160,
        "district_code": 440509,
        "amphoe_code": 4413,
        "province_code": 44
    },
    {
        "district": "ชื่นชม",
        "amphoe": "ชื่นชม",
        "province": "มหาสารคาม",
        "zipcode": 44160,
        "district_code": 440502,
        "amphoe_code": 4413,
        "province_code": 44
    },
    {
        "district": "หนองกุง",
        "amphoe": "ชื่นชม",
        "province": "มหาสารคาม",
        "zipcode": 44160,
        "district_code": 440204,
        "amphoe_code": 4413,
        "province_code": 44
    },
    {
        "district": "เหล่าดอกไม้",
        "amphoe": "ชื่นชม",
        "province": "มหาสารคาม",
        "zipcode": 44160,
        "district_code": 440504,
        "amphoe_code": 4413,
        "province_code": 44
    },
    {
        "district": "กู่สันตรัตน์",
        "amphoe": "นาดูน",
        "province": "มหาสารคาม",
        "zipcode": 44180,
        "district_code": 441008,
        "amphoe_code": 4410,
        "province_code": 44
    },
    {
        "district": "ดงดวน",
        "amphoe": "นาดูน",
        "province": "มหาสารคาม",
        "zipcode": 44180,
        "district_code": 441005,
        "amphoe_code": 4410,
        "province_code": 44
    },
    {
        "district": "ดงบัง",
        "amphoe": "นาดูน",
        "province": "มหาสารคาม",
        "zipcode": 44180,
        "district_code": 441004,
        "amphoe_code": 4410,
        "province_code": 44
    },
    {
        "district": "ดงยาง",
        "amphoe": "นาดูน",
        "province": "มหาสารคาม",
        "zipcode": 44180,
        "district_code": 441007,
        "amphoe_code": 4410,
        "province_code": 44
    },
    {
        "district": "นาดูน",
        "amphoe": "นาดูน",
        "province": "มหาสารคาม",
        "zipcode": 44180,
        "district_code": 440998,
        "amphoe_code": 4410,
        "province_code": 44
    },
    {
        "district": "พระธาตุ",
        "amphoe": "นาดูน",
        "province": "มหาสารคาม",
        "zipcode": 44180,
        "district_code": 441009,
        "amphoe_code": 4410,
        "province_code": 44
    },
    {
        "district": "หนองคู",
        "amphoe": "นาดูน",
        "province": "มหาสารคาม",
        "zipcode": 44180,
        "district_code": 440999,
        "amphoe_code": 4410,
        "province_code": 44
    },
    {
        "district": "หนองไผ่",
        "amphoe": "นาดูน",
        "province": "มหาสารคาม",
        "zipcode": 44180,
        "district_code": 440997,
        "amphoe_code": 4410,
        "province_code": 44
    },
    {
        "district": "หัวดง",
        "amphoe": "นาดูน",
        "province": "มหาสารคาม",
        "zipcode": 44180,
        "district_code": 441006,
        "amphoe_code": 4410,
        "province_code": 44
    },
    {
        "district": "นาเชือก",
        "amphoe": "นาเชือก",
        "province": "มหาสารคาม",
        "zipcode": 44170,
        "district_code": 440701,
        "amphoe_code": 4407,
        "province_code": 44
    },
    {
        "district": "ปอพาน",
        "amphoe": "นาเชือก",
        "province": "มหาสารคาม",
        "zipcode": 44170,
        "district_code": 440706,
        "amphoe_code": 4407,
        "province_code": 44
    },
    {
        "district": "สันป่าตอง",
        "amphoe": "นาเชือก",
        "province": "มหาสารคาม",
        "zipcode": 44170,
        "district_code": 440710,
        "amphoe_code": 4407,
        "province_code": 44
    },
    {
        "district": "สำโรง",
        "amphoe": "นาเชือก",
        "province": "มหาสารคาม",
        "zipcode": 44170,
        "district_code": 440702,
        "amphoe_code": 4407,
        "province_code": 44
    },
    {
        "district": "หนองกุง",
        "amphoe": "นาเชือก",
        "province": "มหาสารคาม",
        "zipcode": 44170,
        "district_code": 440204,
        "amphoe_code": 4407,
        "province_code": 44
    },
    {
        "district": "หนองเม็ก",
        "amphoe": "นาเชือก",
        "province": "มหาสารคาม",
        "zipcode": 44170,
        "district_code": 440707,
        "amphoe_code": 4407,
        "province_code": 44
    },
    {
        "district": "หนองเรือ",
        "amphoe": "นาเชือก",
        "province": "มหาสารคาม",
        "zipcode": 44170,
        "district_code": 440708,
        "amphoe_code": 4407,
        "province_code": 44
    },
    {
        "district": "หนองแดง",
        "amphoe": "นาเชือก",
        "province": "มหาสารคาม",
        "zipcode": 44170,
        "district_code": 440703,
        "amphoe_code": 4407,
        "province_code": 44
    },
    {
        "district": "หนองโพธิ์",
        "amphoe": "นาเชือก",
        "province": "มหาสารคาม",
        "zipcode": 44170,
        "district_code": 440705,
        "amphoe_code": 4407,
        "province_code": 44
    },
    {
        "district": "เขวาไร่",
        "amphoe": "นาเชือก",
        "province": "มหาสารคาม",
        "zipcode": 44170,
        "district_code": 440304,
        "amphoe_code": 4407,
        "province_code": 44
    },
    {
        "district": "กำพี้",
        "amphoe": "บรบือ",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440606,
        "amphoe_code": 4406,
        "province_code": 44
    },
    {
        "district": "ดอนงัว",
        "amphoe": "บรบือ",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440620,
        "amphoe_code": 4406,
        "province_code": 44
    },
    {
        "district": "บรบือ",
        "amphoe": "บรบือ",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440601,
        "amphoe_code": 4406,
        "province_code": 44
    },
    {
        "district": "บัวมาศ",
        "amphoe": "บรบือ",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440611,
        "amphoe_code": 4406,
        "province_code": 44
    },
    {
        "district": "บ่อใหญ่",
        "amphoe": "บรบือ",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440602,
        "amphoe_code": 4406,
        "province_code": 44
    },
    {
        "district": "ยาง",
        "amphoe": "บรบือ",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440616,
        "amphoe_code": 4406,
        "province_code": 44
    },
    {
        "district": "วังใหม่",
        "amphoe": "บรบือ",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440615,
        "amphoe_code": 4406,
        "province_code": 44
    },
    {
        "district": "วังไชย",
        "amphoe": "บรบือ",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440604,
        "amphoe_code": 4406,
        "province_code": 44
    },
    {
        "district": "หนองคูขาด",
        "amphoe": "บรบือ",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440613,
        "amphoe_code": 4406,
        "province_code": 44
    },
    {
        "district": "หนองจิก",
        "amphoe": "บรบือ",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440610,
        "amphoe_code": 4406,
        "province_code": 44
    },
    {
        "district": "หนองม่วง",
        "amphoe": "บรบือ",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440605,
        "amphoe_code": 4406,
        "province_code": 44
    },
    {
        "district": "หนองสิม",
        "amphoe": "บรบือ",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440618,
        "amphoe_code": 4406,
        "province_code": 44
    },
    {
        "district": "หนองโก",
        "amphoe": "บรบือ",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440619,
        "amphoe_code": 4406,
        "province_code": 44
    },
    {
        "district": "โนนราษี",
        "amphoe": "บรบือ",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440607,
        "amphoe_code": 4406,
        "province_code": 44
    },
    {
        "district": "โนนแดง",
        "amphoe": "บรบือ",
        "province": "มหาสารคาม",
        "zipcode": 44130,
        "district_code": 440608,
        "amphoe_code": 4406,
        "province_code": 44
    },
    {
        "district": "ก้ามปู",
        "amphoe": "พยัคฆภูมิพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44110,
        "district_code": 440802,
        "amphoe_code": 4408,
        "province_code": 44
    },
    {
        "district": "นาสีนวล",
        "amphoe": "พยัคฆภูมิพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44110,
        "district_code": 440805,
        "amphoe_code": 4408,
        "province_code": 44
    },
    {
        "district": "ปะหลาน",
        "amphoe": "พยัคฆภูมิพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44110,
        "district_code": 440801,
        "amphoe_code": 4408,
        "province_code": 44
    },
    {
        "district": "ภารแอ่น",
        "amphoe": "พยัคฆภูมิพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44110,
        "district_code": 440820,
        "amphoe_code": 4408,
        "province_code": 44
    },
    {
        "district": "ราษฎร์พัฒนา",
        "amphoe": "พยัคฆภูมิพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44110,
        "district_code": 440818,
        "amphoe_code": 4408,
        "province_code": 44
    },
    {
        "district": "ราษฎร์เจริญ",
        "amphoe": "พยัคฆภูมิพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44110,
        "district_code": 440809,
        "amphoe_code": 4408,
        "province_code": 44
    },
    {
        "district": "ลานสะแก",
        "amphoe": "พยัคฆภูมิพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44110,
        "district_code": 440815,
        "amphoe_code": 4408,
        "province_code": 44
    },
    {
        "district": "หนองบัว",
        "amphoe": "พยัคฆภูมิพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44110,
        "district_code": 440308,
        "amphoe_code": 4408,
        "province_code": 44
    },
    {
        "district": "หนองบัวแก้ว",
        "amphoe": "พยัคฆภูมิพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44110,
        "district_code": 440810,
        "amphoe_code": 4408,
        "province_code": 44
    },
    {
        "district": "เมืองเตา",
        "amphoe": "พยัคฆภูมิพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44110,
        "district_code": 440812,
        "amphoe_code": 4408,
        "province_code": 44
    },
    {
        "district": "เมืองเสือ",
        "amphoe": "พยัคฆภูมิพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44110,
        "district_code": 440819,
        "amphoe_code": 4408,
        "province_code": 44
    },
    {
        "district": "เม็กดำ",
        "amphoe": "พยัคฆภูมิพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44110,
        "district_code": 440804,
        "amphoe_code": 4408,
        "province_code": 44
    },
    {
        "district": "เวียงชัย",
        "amphoe": "พยัคฆภูมิพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44110,
        "district_code": 440816,
        "amphoe_code": 4408,
        "province_code": 44
    },
    {
        "district": "เวียงสะอาด",
        "amphoe": "พยัคฆภูมิพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44110,
        "district_code": 440803,
        "amphoe_code": 4408,
        "province_code": 44
    },
    {
        "district": "ขามเรียน",
        "amphoe": "ยางสีสุราช",
        "province": "มหาสารคาม",
        "zipcode": 44210,
        "district_code": 440808,
        "amphoe_code": 4411,
        "province_code": 44
    },
    {
        "district": "ดงเมือง",
        "amphoe": "ยางสีสุราช",
        "province": "มหาสารคาม",
        "zipcode": 44210,
        "district_code": 440806,
        "amphoe_code": 4411,
        "province_code": 44
    },
    {
        "district": "นาภู",
        "amphoe": "ยางสีสุราช",
        "province": "มหาสารคาม",
        "zipcode": 44210,
        "district_code": 440811,
        "amphoe_code": 4411,
        "province_code": 44
    },
    {
        "district": "บ้านกู่",
        "amphoe": "ยางสีสุราช",
        "province": "มหาสารคาม",
        "zipcode": 44210,
        "district_code": 440813,
        "amphoe_code": 4411,
        "province_code": 44
    },
    {
        "district": "ยางสีสุราช",
        "amphoe": "ยางสีสุราช",
        "province": "มหาสารคาม",
        "zipcode": 44210,
        "district_code": 440814,
        "amphoe_code": 4411,
        "province_code": 44
    },
    {
        "district": "หนองบัวสันตุ",
        "amphoe": "ยางสีสุราช",
        "province": "มหาสารคาม",
        "zipcode": 44210,
        "district_code": 441107,
        "amphoe_code": 4411,
        "province_code": 44
    },
    {
        "district": "แวงดง",
        "amphoe": "ยางสีสุราช",
        "province": "มหาสารคาม",
        "zipcode": 44210,
        "district_code": 440807,
        "amphoe_code": 4411,
        "province_code": 44
    },
    {
        "district": "ขามป้อม",
        "amphoe": "วาปีปทุม",
        "province": "มหาสารคาม",
        "zipcode": 44120,
        "district_code": 440902,
        "amphoe_code": 4409,
        "province_code": 44
    },
    {
        "district": "งัวบา",
        "amphoe": "วาปีปทุม",
        "province": "มหาสารคาม",
        "zipcode": 44120,
        "district_code": 440908,
        "amphoe_code": 4409,
        "province_code": 44
    },
    {
        "district": "ดงใหญ่",
        "amphoe": "วาปีปทุม",
        "province": "มหาสารคาม",
        "zipcode": 44120,
        "district_code": 440904,
        "amphoe_code": 4409,
        "province_code": 44
    },
    {
        "district": "นาข่า",
        "amphoe": "วาปีปทุม",
        "province": "มหาสารคาม",
        "zipcode": 44120,
        "district_code": 440909,
        "amphoe_code": 4409,
        "province_code": 44
    },
    {
        "district": "บ้านหวาย",
        "amphoe": "วาปีปทุม",
        "province": "มหาสารคาม",
        "zipcode": 44120,
        "district_code": 440910,
        "amphoe_code": 4409,
        "province_code": 44
    },
    {
        "district": "ประชาพัฒนา",
        "amphoe": "วาปีปทุม",
        "province": "มหาสารคาม",
        "zipcode": 44120,
        "district_code": 440912,
        "amphoe_code": 4409,
        "province_code": 44
    },
    {
        "district": "หนองทุ่ม",
        "amphoe": "วาปีปทุม",
        "province": "มหาสารคาม",
        "zipcode": 44120,
        "district_code": 440913,
        "amphoe_code": 4409,
        "province_code": 44
    },
    {
        "district": "หนองแสง",
        "amphoe": "วาปีปทุม",
        "province": "มหาสารคาม",
        "zipcode": 44120,
        "district_code": 440901,
        "amphoe_code": 4409,
        "province_code": 44
    },
    {
        "district": "หนองแสน",
        "amphoe": "วาปีปทุม",
        "province": "มหาสารคาม",
        "zipcode": 44120,
        "district_code": 440914,
        "amphoe_code": 4409,
        "province_code": 44
    },
    {
        "district": "หนองไฮ",
        "amphoe": "วาปีปทุม",
        "province": "มหาสารคาม",
        "zipcode": 44120,
        "district_code": 440911,
        "amphoe_code": 4409,
        "province_code": 44
    },
    {
        "district": "หัวเรือ",
        "amphoe": "วาปีปทุม",
        "province": "มหาสารคาม",
        "zipcode": 44120,
        "district_code": 440906,
        "amphoe_code": 4409,
        "province_code": 44
    },
    {
        "district": "เสือโก้ก",
        "amphoe": "วาปีปทุม",
        "province": "มหาสารคาม",
        "zipcode": 44120,
        "district_code": 440903,
        "amphoe_code": 4409,
        "province_code": 44
    },
    {
        "district": "แคน",
        "amphoe": "วาปีปทุม",
        "province": "มหาสารคาม",
        "zipcode": 44120,
        "district_code": 440907,
        "amphoe_code": 4409,
        "province_code": 44
    },
    {
        "district": "โคกสีทองหลาง",
        "amphoe": "วาปีปทุม",
        "province": "มหาสารคาม",
        "zipcode": 44120,
        "district_code": 440915,
        "amphoe_code": 4409,
        "province_code": 44
    },
    {
        "district": "โพธิ์ชัย",
        "amphoe": "วาปีปทุม",
        "province": "มหาสารคาม",
        "zipcode": 44120,
        "district_code": 440905,
        "amphoe_code": 4409,
        "province_code": 44
    },
    {
        "district": "กู่ทอง",
        "amphoe": "เชียงยืน",
        "province": "มหาสารคาม",
        "zipcode": 44160,
        "district_code": 440506,
        "amphoe_code": 4405,
        "province_code": 44
    },
    {
        "district": "ดอนเงิน",
        "amphoe": "เชียงยืน",
        "province": "มหาสารคาม",
        "zipcode": 44160,
        "district_code": 440505,
        "amphoe_code": 4405,
        "province_code": 44
    },
    {
        "district": "นาทอง",
        "amphoe": "เชียงยืน",
        "province": "มหาสารคาม",
        "zipcode": 44160,
        "district_code": 440507,
        "amphoe_code": 4405,
        "province_code": 44
    },
    {
        "district": "หนองซอน",
        "amphoe": "เชียงยืน",
        "province": "มหาสารคาม",
        "zipcode": 44160,
        "district_code": 440503,
        "amphoe_code": 4405,
        "province_code": 44
    },
    {
        "district": "เชียงยืน",
        "amphoe": "เชียงยืน",
        "province": "มหาสารคาม",
        "zipcode": 44160,
        "district_code": 440501,
        "amphoe_code": 4405,
        "province_code": 44
    },
    {
        "district": "เสือเฒ่า",
        "amphoe": "เชียงยืน",
        "province": "มหาสารคาม",
        "zipcode": 44160,
        "district_code": 440508,
        "amphoe_code": 4405,
        "province_code": 44
    },
    {
        "district": "เหล่าบัวบาน",
        "amphoe": "เชียงยืน",
        "province": "มหาสารคาม",
        "zipcode": 44160,
        "district_code": 440512,
        "amphoe_code": 4405,
        "province_code": 44
    },
    {
        "district": "โพนทอง",
        "amphoe": "เชียงยืน",
        "province": "มหาสารคาม",
        "zipcode": 44160,
        "district_code": 440511,
        "amphoe_code": 4405,
        "province_code": 44
    },
    {
        "district": "ดอนหว่าน",
        "amphoe": "เมืองมหาสารคาม",
        "province": "มหาสารคาม",
        "zipcode": 44000,
        "district_code": 440106,
        "amphoe_code": 4401,
        "province_code": 44
    },
    {
        "district": "ตลาด",
        "amphoe": "เมืองมหาสารคาม",
        "province": "มหาสารคาม",
        "zipcode": 44000,
        "district_code": 440101,
        "amphoe_code": 4401,
        "province_code": 44
    },
    {
        "district": "ท่าตูม",
        "amphoe": "เมืองมหาสารคาม",
        "province": "มหาสารคาม",
        "zipcode": 44000,
        "district_code": 440103,
        "amphoe_code": 4401,
        "province_code": 44
    },
    {
        "district": "ท่าสองคอน",
        "amphoe": "เมืองมหาสารคาม",
        "province": "มหาสารคาม",
        "zipcode": 44000,
        "district_code": 440109,
        "amphoe_code": 4401,
        "province_code": 44
    },
    {
        "district": "บัวค้อ",
        "amphoe": "เมืองมหาสารคาม",
        "province": "มหาสารคาม",
        "zipcode": 44000,
        "district_code": 440114,
        "amphoe_code": 4401,
        "province_code": 44
    },
    {
        "district": "ลาดพัฒนา",
        "amphoe": "เมืองมหาสารคาม",
        "province": "มหาสารคาม",
        "zipcode": 44000,
        "district_code": 440110,
        "amphoe_code": 4401,
        "province_code": 44
    },
    {
        "district": "หนองปลิง",
        "amphoe": "เมืองมหาสารคาม",
        "province": "มหาสารคาม",
        "zipcode": 44000,
        "district_code": 440111,
        "amphoe_code": 4401,
        "province_code": 44
    },
    {
        "district": "หนองโน",
        "amphoe": "เมืองมหาสารคาม",
        "province": "มหาสารคาม",
        "zipcode": 44000,
        "district_code": 440113,
        "amphoe_code": 4401,
        "province_code": 44
    },
    {
        "district": "ห้วยแอ่ง",
        "amphoe": "เมืองมหาสารคาม",
        "province": "มหาสารคาม",
        "zipcode": 44000,
        "district_code": 440112,
        "amphoe_code": 4401,
        "province_code": 44
    },
    {
        "district": "เกิ้ง",
        "amphoe": "เมืองมหาสารคาม",
        "province": "มหาสารคาม",
        "zipcode": 44000,
        "district_code": 440107,
        "amphoe_code": 4401,
        "province_code": 44
    },
    {
        "district": "เขวา",
        "amphoe": "เมืองมหาสารคาม",
        "province": "มหาสารคาม",
        "zipcode": 44000,
        "district_code": 440102,
        "amphoe_code": 4401,
        "province_code": 44
    },
    {
        "district": "แก่งเลิงจาน",
        "amphoe": "เมืองมหาสารคาม",
        "province": "มหาสารคาม",
        "zipcode": 44000,
        "district_code": 440108,
        "amphoe_code": 4401,
        "province_code": 44
    },
    {
        "district": "แวงน่าง",
        "amphoe": "เมืองมหาสารคาม",
        "province": "มหาสารคาม",
        "zipcode": 44000,
        "district_code": 440104,
        "amphoe_code": 4401,
        "province_code": 44
    },
    {
        "district": "โคกก่อ",
        "amphoe": "เมืองมหาสารคาม",
        "province": "มหาสารคาม",
        "zipcode": 44000,
        "district_code": 440105,
        "amphoe_code": 4401,
        "province_code": 44
    },
    {
        "district": "มิตรภาพ",
        "amphoe": "แกดำ",
        "province": "มหาสารคาม",
        "zipcode": 44190,
        "district_code": 440203,
        "amphoe_code": 4402,
        "province_code": 44
    },
    {
        "district": "วังแสง",
        "amphoe": "แกดำ",
        "province": "มหาสารคาม",
        "zipcode": 44190,
        "district_code": 440202,
        "amphoe_code": 4402,
        "province_code": 44
    },
    {
        "district": "หนองกุง",
        "amphoe": "แกดำ",
        "province": "มหาสารคาม",
        "zipcode": 44190,
        "district_code": 440204,
        "amphoe_code": 4402,
        "province_code": 44
    },
    {
        "district": "แกดำ",
        "amphoe": "แกดำ",
        "province": "มหาสารคาม",
        "zipcode": 44190,
        "district_code": 440201,
        "amphoe_code": 4402,
        "province_code": 44
    },
    {
        "district": "โนนภิบาล",
        "amphoe": "แกดำ",
        "province": "มหาสารคาม",
        "zipcode": 44190,
        "district_code": 440205,
        "amphoe_code": 4402,
        "province_code": 44
    },
    {
        "district": "ดอนกลาง",
        "amphoe": "โกสุมพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44140,
        "district_code": 440317,
        "amphoe_code": 4403,
        "province_code": 44
    },
    {
        "district": "ยางท่าแจ้ง",
        "amphoe": "โกสุมพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44140,
        "district_code": 440313,
        "amphoe_code": 4403,
        "province_code": 44
    },
    {
        "district": "ยางน้อย",
        "amphoe": "โกสุมพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44140,
        "district_code": 440302,
        "amphoe_code": 4403,
        "province_code": 44
    },
    {
        "district": "วังยาว",
        "amphoe": "โกสุมพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44140,
        "district_code": 440303,
        "amphoe_code": 4403,
        "province_code": 44
    },
    {
        "district": "หนองกุงสวรรค์",
        "amphoe": "โกสุมพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44140,
        "district_code": 440315,
        "amphoe_code": 4403,
        "province_code": 44
    },
    {
        "district": "หนองบอน",
        "amphoe": "โกสุมพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44140,
        "district_code": 440311,
        "amphoe_code": 4403,
        "province_code": 44
    },
    {
        "district": "หนองบัว",
        "amphoe": "โกสุมพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44140,
        "district_code": 440308,
        "amphoe_code": 4403,
        "province_code": 44
    },
    {
        "district": "หนองเหล็ก",
        "amphoe": "โกสุมพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44140,
        "district_code": 440307,
        "amphoe_code": 4403,
        "province_code": 44
    },
    {
        "district": "หัวขวาง",
        "amphoe": "โกสุมพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44140,
        "district_code": 440301,
        "amphoe_code": 4403,
        "province_code": 44
    },
    {
        "district": "เขวาไร่",
        "amphoe": "โกสุมพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44140,
        "district_code": 440304,
        "amphoe_code": 4403,
        "province_code": 44
    },
    {
        "district": "เขื่อน",
        "amphoe": "โกสุมพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44140,
        "district_code": 440310,
        "amphoe_code": 4403,
        "province_code": 44
    },
    {
        "district": "เลิงใต้",
        "amphoe": "โกสุมพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44140,
        "district_code": 440316,
        "amphoe_code": 4403,
        "province_code": 44
    },
    {
        "district": "เหล่า",
        "amphoe": "โกสุมพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44140,
        "district_code": 440309,
        "amphoe_code": 4403,
        "province_code": 44
    },
    {
        "district": "แก้งแก",
        "amphoe": "โกสุมพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44140,
        "district_code": 440306,
        "amphoe_code": 4403,
        "province_code": 44
    },
    {
        "district": "แพง",
        "amphoe": "โกสุมพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44140,
        "district_code": 440305,
        "amphoe_code": 4403,
        "province_code": 44
    },
    {
        "district": "แห่ใต้",
        "amphoe": "โกสุมพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44140,
        "district_code": 440314,
        "amphoe_code": 4403,
        "province_code": 44
    },
    {
        "district": "โพนงาม",
        "amphoe": "โกสุมพิสัย",
        "province": "มหาสารคาม",
        "zipcode": 44140,
        "district_code": 440312,
        "amphoe_code": 4403,
        "province_code": 44
    },
    {
        "district": "คำชะอี",
        "amphoe": "คำชะอี",
        "province": "มุกดาหาร",
        "zipcode": 49110,
        "district_code": 490504,
        "amphoe_code": 4905,
        "province_code": 49
    },
    {
        "district": "คำบก",
        "amphoe": "คำชะอี",
        "province": "มุกดาหาร",
        "zipcode": 49110,
        "district_code": 490512,
        "amphoe_code": 4905,
        "province_code": 49
    },
    {
        "district": "น้ำเที่ยง",
        "amphoe": "คำชะอี",
        "province": "มุกดาหาร",
        "zipcode": 49110,
        "district_code": 490514,
        "amphoe_code": 4905,
        "province_code": 49
    },
    {
        "district": "บ้านค้อ",
        "amphoe": "คำชะอี",
        "province": "มุกดาหาร",
        "zipcode": 49110,
        "district_code": 490506,
        "amphoe_code": 4905,
        "province_code": 49
    },
    {
        "district": "บ้านซ่ง",
        "amphoe": "คำชะอี",
        "province": "มุกดาหาร",
        "zipcode": 49110,
        "district_code": 490503,
        "amphoe_code": 4905,
        "province_code": 49
    },
    {
        "district": "บ้านเหล่า",
        "amphoe": "คำชะอี",
        "province": "มุกดาหาร",
        "zipcode": 49110,
        "district_code": 490507,
        "amphoe_code": 4905,
        "province_code": 49
    },
    {
        "district": "หนองเอี่ยน",
        "amphoe": "คำชะอี",
        "province": "มุกดาหาร",
        "zipcode": 49110,
        "district_code": 490505,
        "amphoe_code": 4905,
        "province_code": 49
    },
    {
        "district": "เหล่าสร้างถ่อ",
        "amphoe": "คำชะอี",
        "province": "มุกดาหาร",
        "zipcode": 49110,
        "district_code": 490511,
        "amphoe_code": 4905,
        "province_code": 49
    },
    {
        "district": "โพนงาม",
        "amphoe": "คำชะอี",
        "province": "มุกดาหาร",
        "zipcode": 49110,
        "district_code": 490508,
        "amphoe_code": 4905,
        "province_code": 49
    },
    {
        "district": "กกตูม",
        "amphoe": "ดงหลวง",
        "province": "มุกดาหาร",
        "zipcode": 49140,
        "district_code": 490403,
        "amphoe_code": 4904,
        "province_code": 49
    },
    {
        "district": "ชะโนดน้อย",
        "amphoe": "ดงหลวง",
        "province": "มุกดาหาร",
        "zipcode": 49140,
        "district_code": 490405,
        "amphoe_code": 4904,
        "province_code": 49
    },
    {
        "district": "ดงหลวง",
        "amphoe": "ดงหลวง",
        "province": "มุกดาหาร",
        "zipcode": 49140,
        "district_code": 490401,
        "amphoe_code": 4904,
        "province_code": 49
    },
    {
        "district": "พังแดง",
        "amphoe": "ดงหลวง",
        "province": "มุกดาหาร",
        "zipcode": 49140,
        "district_code": 490406,
        "amphoe_code": 4904,
        "province_code": 49
    },
    {
        "district": "หนองบัว",
        "amphoe": "ดงหลวง",
        "province": "มุกดาหาร",
        "zipcode": 49140,
        "district_code": 490402,
        "amphoe_code": 4904,
        "province_code": 49
    },
    {
        "district": "หนองแคน",
        "amphoe": "ดงหลวง",
        "province": "มุกดาหาร",
        "zipcode": 49140,
        "district_code": 490404,
        "amphoe_code": 4904,
        "province_code": 49
    },
    {
        "district": "ดอนตาล",
        "amphoe": "ดอนตาล",
        "province": "มุกดาหาร",
        "zipcode": 49120,
        "district_code": 490301,
        "amphoe_code": 4903,
        "province_code": 49
    },
    {
        "district": "นาสะเม็ง",
        "amphoe": "ดอนตาล",
        "province": "มุกดาหาร",
        "zipcode": 49120,
        "district_code": 490306,
        "amphoe_code": 4903,
        "province_code": 49
    },
    {
        "district": "บ้านบาก",
        "amphoe": "ดอนตาล",
        "province": "มุกดาหาร",
        "zipcode": 49120,
        "district_code": 490305,
        "amphoe_code": 4903,
        "province_code": 49
    },
    {
        "district": "บ้านแก้ง",
        "amphoe": "ดอนตาล",
        "province": "มุกดาหาร",
        "zipcode": 49120,
        "district_code": 490307,
        "amphoe_code": 4903,
        "province_code": 49
    },
    {
        "district": "ป่าไร่",
        "amphoe": "ดอนตาล",
        "province": "มุกดาหาร",
        "zipcode": 49120,
        "district_code": 490303,
        "amphoe_code": 4903,
        "province_code": 49
    },
    {
        "district": "เหล่าหมี",
        "amphoe": "ดอนตาล",
        "province": "มุกดาหาร",
        "zipcode": 49120,
        "district_code": 490304,
        "amphoe_code": 4903,
        "province_code": 49
    },
    {
        "district": "โพธิ์ไทร",
        "amphoe": "ดอนตาล",
        "province": "มุกดาหาร",
        "zipcode": 49120,
        "district_code": 490302,
        "amphoe_code": 4903,
        "province_code": 49
    },
    {
        "district": "กกแดง",
        "amphoe": "นิคมคำสร้อย",
        "province": "มุกดาหาร",
        "zipcode": 49130,
        "district_code": 490195,
        "amphoe_code": 4902,
        "province_code": 49
    },
    {
        "district": "นากอก",
        "amphoe": "นิคมคำสร้อย",
        "province": "มุกดาหาร",
        "zipcode": 49130,
        "district_code": 490196,
        "amphoe_code": 4902,
        "province_code": 49
    },
    {
        "district": "นาอุดม",
        "amphoe": "นิคมคำสร้อย",
        "province": "มุกดาหาร",
        "zipcode": 49130,
        "district_code": 490205,
        "amphoe_code": 4902,
        "province_code": 49
    },
    {
        "district": "นิคมคำสร้อย",
        "amphoe": "นิคมคำสร้อย",
        "province": "มุกดาหาร",
        "zipcode": 49130,
        "district_code": 490201,
        "amphoe_code": 4902,
        "province_code": 49
    },
    {
        "district": "ร่มเกล้า",
        "amphoe": "นิคมคำสร้อย",
        "province": "มุกดาหาร",
        "zipcode": 49130,
        "district_code": 490207,
        "amphoe_code": 4902,
        "province_code": 49
    },
    {
        "district": "หนองแวง",
        "amphoe": "นิคมคำสร้อย",
        "province": "มุกดาหาร",
        "zipcode": 49130,
        "district_code": 490194,
        "amphoe_code": 4902,
        "province_code": 49
    },
    {
        "district": "โชคชัย",
        "amphoe": "นิคมคำสร้อย",
        "province": "มุกดาหาร",
        "zipcode": 49130,
        "district_code": 490206,
        "amphoe_code": 4902,
        "province_code": 49
    },
    {
        "district": "บ้านเป้า",
        "amphoe": "หนองสูง",
        "province": "มุกดาหาร",
        "zipcode": 49160,
        "district_code": 490510,
        "amphoe_code": 4907,
        "province_code": 49
    },
    {
        "district": "ภูวง",
        "amphoe": "หนองสูง",
        "province": "มุกดาหาร",
        "zipcode": 49160,
        "district_code": 490513,
        "amphoe_code": 4907,
        "province_code": 49
    },
    {
        "district": "หนองสูง",
        "amphoe": "หนองสูง",
        "province": "มุกดาหาร",
        "zipcode": 49160,
        "district_code": 490502,
        "amphoe_code": 4907,
        "province_code": 49
    },
    {
        "district": "หนองสูงเหนือ",
        "amphoe": "หนองสูง",
        "province": "มุกดาหาร",
        "zipcode": 49160,
        "district_code": 490706,
        "amphoe_code": 4907,
        "province_code": 49
    },
    {
        "district": "หนองสูงใต้",
        "amphoe": "หนองสูง",
        "province": "มุกดาหาร",
        "zipcode": 49160,
        "district_code": 490501,
        "amphoe_code": 4907,
        "province_code": 49
    },
    {
        "district": "โนนยาง",
        "amphoe": "หนองสูง",
        "province": "มุกดาหาร",
        "zipcode": 49160,
        "district_code": 490509,
        "amphoe_code": 4907,
        "province_code": 49
    },
    {
        "district": "ชะโนด",
        "amphoe": "หว้านใหญ่",
        "province": "มุกดาหาร",
        "zipcode": 49150,
        "district_code": 490604,
        "amphoe_code": 4906,
        "province_code": 49
    },
    {
        "district": "ดงหมู",
        "amphoe": "หว้านใหญ่",
        "province": "มุกดาหาร",
        "zipcode": 49150,
        "district_code": 490605,
        "amphoe_code": 4906,
        "province_code": 49
    },
    {
        "district": "บางทรายน้อย",
        "amphoe": "หว้านใหญ่",
        "province": "มุกดาหาร",
        "zipcode": 49150,
        "district_code": 490198,
        "amphoe_code": 4906,
        "province_code": 49
    },
    {
        "district": "ป่งขาม",
        "amphoe": "หว้านใหญ่",
        "province": "มุกดาหาร",
        "zipcode": 49150,
        "district_code": 490602,
        "amphoe_code": 4906,
        "province_code": 49
    },
    {
        "district": "หว้านใหญ่",
        "amphoe": "หว้านใหญ่",
        "province": "มุกดาหาร",
        "zipcode": 49150,
        "district_code": 490199,
        "amphoe_code": 4906,
        "province_code": 49
    },
    {
        "district": "กุดแข้",
        "amphoe": "เมืองมุกดาหาร",
        "province": "มุกดาหาร",
        "zipcode": 49000,
        "district_code": 490113,
        "amphoe_code": 4901,
        "province_code": 49
    },
    {
        "district": "คำป่าหลาย",
        "amphoe": "เมืองมุกดาหาร",
        "province": "มุกดาหาร",
        "zipcode": 49000,
        "district_code": 490109,
        "amphoe_code": 4901,
        "province_code": 49
    },
    {
        "district": "คำอาฮวน",
        "amphoe": "เมืองมุกดาหาร",
        "province": "มุกดาหาร",
        "zipcode": 49000,
        "district_code": 490110,
        "amphoe_code": 4901,
        "province_code": 49
    },
    {
        "district": "ดงมอน",
        "amphoe": "เมืองมุกดาหาร",
        "province": "มุกดาหาร",
        "zipcode": 49000,
        "district_code": 490112,
        "amphoe_code": 4901,
        "province_code": 49
    },
    {
        "district": "ดงเย็น",
        "amphoe": "เมืองมุกดาหาร",
        "province": "มุกดาหาร",
        "zipcode": 49000,
        "district_code": 490111,
        "amphoe_code": 4901,
        "province_code": 49
    },
    {
        "district": "นาสีนวน",
        "amphoe": "เมืองมุกดาหาร",
        "province": "มุกดาหาร",
        "zipcode": 49000,
        "district_code": 490108,
        "amphoe_code": 4901,
        "province_code": 49
    },
    {
        "district": "นาโสก",
        "amphoe": "เมืองมุกดาหาร",
        "province": "มุกดาหาร",
        "zipcode": 49000,
        "district_code": 490107,
        "amphoe_code": 4901,
        "province_code": 49
    },
    {
        "district": "บางทรายใหญ่",
        "amphoe": "เมืองมุกดาหาร",
        "province": "มุกดาหาร",
        "zipcode": 49000,
        "district_code": 490104,
        "amphoe_code": 4901,
        "province_code": 49
    },
    {
        "district": "บ้านโคก",
        "amphoe": "เมืองมุกดาหาร",
        "province": "มุกดาหาร",
        "zipcode": 49000,
        "district_code": 490103,
        "amphoe_code": 4901,
        "province_code": 49
    },
    {
        "district": "ผึ่งแดด",
        "amphoe": "เมืองมุกดาหาร",
        "province": "มุกดาหาร",
        "zipcode": 49000,
        "district_code": 490106,
        "amphoe_code": 4901,
        "province_code": 49
    },
    {
        "district": "มุกดาหาร",
        "amphoe": "เมืองมุกดาหาร",
        "province": "มุกดาหาร",
        "zipcode": 49000,
        "district_code": 490101,
        "amphoe_code": 4901,
        "province_code": 49
    },
    {
        "district": "ศรีบุญเรือง",
        "amphoe": "เมืองมุกดาหาร",
        "province": "มุกดาหาร",
        "zipcode": 49000,
        "district_code": 490102,
        "amphoe_code": 4901,
        "province_code": 49
    },
    {
        "district": "โพนทราย",
        "amphoe": "เมืองมุกดาหาร",
        "province": "มุกดาหาร",
        "zipcode": 49000,
        "district_code": 490105,
        "amphoe_code": 4901,
        "province_code": 49
    },
    {
        "district": "กรงปินัง",
        "amphoe": "กรงปินัง",
        "province": "ยะลา",
        "zipcode": 95000,
        "district_code": 950113,
        "amphoe_code": 9508,
        "province_code": 95
    },
    {
        "district": "ปุโรง",
        "amphoe": "กรงปินัง",
        "province": "ยะลา",
        "zipcode": 95000,
        "district_code": 950105,
        "amphoe_code": 9508,
        "province_code": 95
    },
    {
        "district": "สะเอะ",
        "amphoe": "กรงปินัง",
        "province": "ยะลา",
        "zipcode": 95000,
        "district_code": 950107,
        "amphoe_code": 9508,
        "province_code": 95
    },
    {
        "district": "ห้วยกระทิง",
        "amphoe": "กรงปินัง",
        "province": "ยะลา",
        "zipcode": 95000,
        "district_code": 950117,
        "amphoe_code": 9508,
        "province_code": 95
    },
    {
        "district": "กาบัง",
        "amphoe": "กาบัง",
        "province": "ยะลา",
        "zipcode": 95120,
        "district_code": 950505,
        "amphoe_code": 9507,
        "province_code": 95
    },
    {
        "district": "บาละ",
        "amphoe": "กาบัง",
        "province": "ยะลา",
        "zipcode": 95120,
        "district_code": 950509,
        "amphoe_code": 9507,
        "province_code": 95
    },
    {
        "district": "คีรีเขต",
        "amphoe": "ธารโต",
        "province": "ยะลา",
        "zipcode": 95150,
        "district_code": 950404,
        "amphoe_code": 9504,
        "province_code": 95
    },
    {
        "district": "ธารโต",
        "amphoe": "ธารโต",
        "province": "ยะลา",
        "zipcode": 95150,
        "district_code": 950399,
        "amphoe_code": 9504,
        "province_code": 95
    },
    {
        "district": "บ้านแหร",
        "amphoe": "ธารโต",
        "province": "ยะลา",
        "zipcode": 95150,
        "district_code": 950398,
        "amphoe_code": 9504,
        "province_code": 95
    },
    {
        "district": "แม่หวาด",
        "amphoe": "ธารโต",
        "province": "ยะลา",
        "zipcode": 95170,
        "district_code": 950397,
        "amphoe_code": 9504,
        "province_code": 95
    },
    {
        "district": "ตลิ่งชัน",
        "amphoe": "บันนังสตา",
        "province": "ยะลา",
        "zipcode": 95130,
        "district_code": 950305,
        "amphoe_code": 9503,
        "province_code": 95
    },
    {
        "district": "ตาเนาะปูเต๊ะ",
        "amphoe": "บันนังสตา",
        "province": "ยะลา",
        "zipcode": 95130,
        "district_code": 950303,
        "amphoe_code": 9503,
        "province_code": 95
    },
    {
        "district": "ถ้ำทะลุ",
        "amphoe": "บันนังสตา",
        "province": "ยะลา",
        "zipcode": 95130,
        "district_code": 950304,
        "amphoe_code": 9503,
        "province_code": 95
    },
    {
        "district": "บันนังสตา",
        "amphoe": "บันนังสตา",
        "province": "ยะลา",
        "zipcode": 95130,
        "district_code": 950301,
        "amphoe_code": 9503,
        "province_code": 95
    },
    {
        "district": "บาเจาะ",
        "amphoe": "บันนังสตา",
        "province": "ยะลา",
        "zipcode": 95130,
        "district_code": 950302,
        "amphoe_code": 9503,
        "province_code": 95
    },
    {
        "district": "เขื่อนบางลาง",
        "amphoe": "บันนังสตา",
        "province": "ยะลา",
        "zipcode": 95130,
        "district_code": 950306,
        "amphoe_code": 9503,
        "province_code": 95
    },
    {
        "district": "กาตอง",
        "amphoe": "ยะหา",
        "province": "ยะลา",
        "zipcode": 95120,
        "district_code": 950508,
        "amphoe_code": 9505,
        "province_code": 95
    },
    {
        "district": "ตาชี",
        "amphoe": "ยะหา",
        "province": "ยะลา",
        "zipcode": 95120,
        "district_code": 950506,
        "amphoe_code": 9505,
        "province_code": 95
    },
    {
        "district": "บาโงยซิแน",
        "amphoe": "ยะหา",
        "province": "ยะลา",
        "zipcode": 95120,
        "district_code": 950507,
        "amphoe_code": 9505,
        "province_code": 95
    },
    {
        "district": "บาโร๊ะ",
        "amphoe": "ยะหา",
        "province": "ยะลา",
        "zipcode": 95120,
        "district_code": 950504,
        "amphoe_code": 9505,
        "province_code": 95
    },
    {
        "district": "ปะแต",
        "amphoe": "ยะหา",
        "province": "ยะลา",
        "zipcode": 95120,
        "district_code": 950503,
        "amphoe_code": 9505,
        "province_code": 95
    },
    {
        "district": "ยะหา",
        "amphoe": "ยะหา",
        "province": "ยะลา",
        "zipcode": 95120,
        "district_code": 950501,
        "amphoe_code": 9505,
        "province_code": 95
    },
    {
        "district": "ละแอ",
        "amphoe": "ยะหา",
        "province": "ยะลา",
        "zipcode": 95120,
        "district_code": 950502,
        "amphoe_code": 9505,
        "province_code": 95
    },
    {
        "district": "กอตอตือร๊ะ",
        "amphoe": "รามัน",
        "province": "ยะลา",
        "zipcode": 95140,
        "district_code": 950604,
        "amphoe_code": 9506,
        "province_code": 95
    },
    {
        "district": "กายูบอเกาะ",
        "amphoe": "รามัน",
        "province": "ยะลา",
        "zipcode": 95140,
        "district_code": 950601,
        "amphoe_code": 9506,
        "province_code": 95
    },
    {
        "district": "กาลอ",
        "amphoe": "รามัน",
        "province": "ยะลา",
        "zipcode": 95140,
        "district_code": 950603,
        "amphoe_code": 9506,
        "province_code": 95
    },
    {
        "district": "กาลูปัง",
        "amphoe": "รามัน",
        "province": "ยะลา",
        "zipcode": 95140,
        "district_code": 950602,
        "amphoe_code": 9506,
        "province_code": 95
    },
    {
        "district": "จะกว๊ะ",
        "amphoe": "รามัน",
        "province": "ยะลา",
        "zipcode": 95140,
        "district_code": 950607,
        "amphoe_code": 9506,
        "province_code": 95
    },
    {
        "district": "ตะโล๊ะหะลอ",
        "amphoe": "รามัน",
        "province": "ยะลา",
        "zipcode": 95140,
        "district_code": 950616,
        "amphoe_code": 9506,
        "province_code": 95
    },
    {
        "district": "ท่าธง",
        "amphoe": "รามัน",
        "province": "ยะลา",
        "zipcode": 95140,
        "district_code": 950608,
        "amphoe_code": 9506,
        "province_code": 95
    },
    {
        "district": "บาลอ",
        "amphoe": "รามัน",
        "province": "ยะลา",
        "zipcode": 95140,
        "district_code": 950610,
        "amphoe_code": 9506,
        "province_code": 95
    },
    {
        "district": "บาโงย",
        "amphoe": "รามัน",
        "province": "ยะลา",
        "zipcode": 95140,
        "district_code": 950611,
        "amphoe_code": 9506,
        "province_code": 95
    },
    {
        "district": "บือมัง",
        "amphoe": "รามัน",
        "province": "ยะลา",
        "zipcode": 95140,
        "district_code": 950612,
        "amphoe_code": 9506,
        "province_code": 95
    },
    {
        "district": "ยะต๊ะ",
        "amphoe": "รามัน",
        "province": "ยะลา",
        "zipcode": 95140,
        "district_code": 950613,
        "amphoe_code": 9506,
        "province_code": 95
    },
    {
        "district": "วังพญา",
        "amphoe": "รามัน",
        "province": "ยะลา",
        "zipcode": 95140,
        "district_code": 950614,
        "amphoe_code": 9506,
        "province_code": 95
    },
    {
        "district": "อาซ่อง",
        "amphoe": "รามัน",
        "province": "ยะลา",
        "zipcode": 95140,
        "district_code": 950615,
        "amphoe_code": 9506,
        "province_code": 95
    },
    {
        "district": "เกะรอ",
        "amphoe": "รามัน",
        "province": "ยะลา",
        "zipcode": 95140,
        "district_code": 950606,
        "amphoe_code": 9506,
        "province_code": 95
    },
    {
        "district": "เนินงาม",
        "amphoe": "รามัน",
        "province": "ยะลา",
        "zipcode": 95140,
        "district_code": 950609,
        "amphoe_code": 9506,
        "province_code": 95
    },
    {
        "district": "โกตาบารู",
        "amphoe": "รามัน",
        "province": "ยะลา",
        "zipcode": 95140,
        "district_code": 950605,
        "amphoe_code": 9506,
        "province_code": 95
    },
    {
        "district": "ตาเนาะแมเราะ",
        "amphoe": "เบตง",
        "province": "ยะลา",
        "zipcode": 95110,
        "district_code": 950203,
        "amphoe_code": 9502,
        "province_code": 95
    },
    {
        "district": "ธารน้ำทิพย์",
        "amphoe": "เบตง",
        "province": "ยะลา",
        "zipcode": 95110,
        "district_code": 950205,
        "amphoe_code": 9502,
        "province_code": 95
    },
    {
        "district": "ยะรม",
        "amphoe": "เบตง",
        "province": "ยะลา",
        "zipcode": 95110,
        "district_code": 950202,
        "amphoe_code": 9502,
        "province_code": 95
    },
    {
        "district": "อัยเยอร์เวง",
        "amphoe": "เบตง",
        "province": "ยะลา",
        "zipcode": 95110,
        "district_code": 950204,
        "amphoe_code": 9502,
        "province_code": 95
    },
    {
        "district": "เบตง",
        "amphoe": "เบตง",
        "province": "ยะลา",
        "zipcode": 95110,
        "district_code": 950201,
        "amphoe_code": 9502,
        "province_code": 95
    },
    {
        "district": "ตาเซะ",
        "amphoe": "เมืองยะลา",
        "province": "ยะลา",
        "zipcode": 95000,
        "district_code": 950118,
        "amphoe_code": 9501,
        "province_code": 95
    },
    {
        "district": "ท่าสาป",
        "amphoe": "เมืองยะลา",
        "province": "ยะลา",
        "zipcode": 95000,
        "district_code": 950108,
        "amphoe_code": 9501,
        "province_code": 95
    },
    {
        "district": "บันนังสาเรง",
        "amphoe": "เมืองยะลา",
        "province": "ยะลา",
        "zipcode": 95000,
        "district_code": 950115,
        "amphoe_code": 9501,
        "province_code": 95
    },
    {
        "district": "บุดี",
        "amphoe": "เมืองยะลา",
        "province": "ยะลา",
        "zipcode": 95000,
        "district_code": 950102,
        "amphoe_code": 9501,
        "province_code": 95
    },
    {
        "district": "พร่อน",
        "amphoe": "เมืองยะลา",
        "province": "ยะลา",
        "zipcode": 95160,
        "district_code": 950114,
        "amphoe_code": 9501,
        "province_code": 95
    },
    {
        "district": "ยะลา",
        "amphoe": "เมืองยะลา",
        "province": "ยะลา",
        "zipcode": 95000,
        "district_code": 950106,
        "amphoe_code": 9501,
        "province_code": 95
    },
    {
        "district": "ยุโป",
        "amphoe": "เมืองยะลา",
        "province": "ยะลา",
        "zipcode": 95000,
        "district_code": 950103,
        "amphoe_code": 9501,
        "province_code": 95
    },
    {
        "district": "ลำพะยา",
        "amphoe": "เมืองยะลา",
        "province": "ยะลา",
        "zipcode": 95160,
        "district_code": 950111,
        "amphoe_code": 9501,
        "province_code": 95
    },
    {
        "district": "ลำใหม่",
        "amphoe": "เมืองยะลา",
        "province": "ยะลา",
        "zipcode": 95160,
        "district_code": 950109,
        "amphoe_code": 9501,
        "province_code": 95
    },
    {
        "district": "ลิดล",
        "amphoe": "เมืองยะลา",
        "province": "ยะลา",
        "zipcode": 95160,
        "district_code": 950104,
        "amphoe_code": 9501,
        "province_code": 95
    },
    {
        "district": "สะเตง",
        "amphoe": "เมืองยะลา",
        "province": "ยะลา",
        "zipcode": 95000,
        "district_code": 950101,
        "amphoe_code": 9501,
        "province_code": 95
    },
    {
        "district": "สะเตงนอก",
        "amphoe": "เมืองยะลา",
        "province": "ยะลา",
        "zipcode": 95000,
        "district_code": 950116,
        "amphoe_code": 9501,
        "province_code": 95
    },
    {
        "district": "หน้าถ้ำ",
        "amphoe": "เมืองยะลา",
        "province": "ยะลา",
        "zipcode": 95000,
        "district_code": 950110,
        "amphoe_code": 9501,
        "province_code": 95
    },
    {
        "district": "เปาะเส้ง",
        "amphoe": "เมืองยะลา",
        "province": "ยะลา",
        "zipcode": 95000,
        "district_code": 950112,
        "amphoe_code": 9501,
        "province_code": 95
    },
    {
        "district": "กำแมด",
        "amphoe": "กุดชุม",
        "province": "ยโสธร",
        "zipcode": 35140,
        "district_code": 350303,
        "amphoe_code": 3503,
        "province_code": 35
    },
    {
        "district": "กุดชุม",
        "amphoe": "กุดชุม",
        "province": "ยโสธร",
        "zipcode": 35140,
        "district_code": 350301,
        "amphoe_code": 3503,
        "province_code": 35
    },
    {
        "district": "คำน้ำสร้าง",
        "amphoe": "กุดชุม",
        "province": "ยโสธร",
        "zipcode": 35140,
        "district_code": 350308,
        "amphoe_code": 3503,
        "province_code": 35
    },
    {
        "district": "นาโส่",
        "amphoe": "กุดชุม",
        "province": "ยโสธร",
        "zipcode": 35140,
        "district_code": 350304,
        "amphoe_code": 3503,
        "province_code": 35
    },
    {
        "district": "หนองหมี",
        "amphoe": "กุดชุม",
        "province": "ยโสธร",
        "zipcode": 35140,
        "district_code": 350306,
        "amphoe_code": 3503,
        "province_code": 35
    },
    {
        "district": "หนองแหน",
        "amphoe": "กุดชุม",
        "province": "ยโสธร",
        "zipcode": 35140,
        "district_code": 350309,
        "amphoe_code": 3503,
        "province_code": 35
    },
    {
        "district": "ห้วยแก้ง",
        "amphoe": "กุดชุม",
        "province": "ยโสธร",
        "zipcode": 35140,
        "district_code": 350305,
        "amphoe_code": 3503,
        "province_code": 35
    },
    {
        "district": "โนนเปือย",
        "amphoe": "กุดชุม",
        "province": "ยโสธร",
        "zipcode": 35140,
        "district_code": 350302,
        "amphoe_code": 3503,
        "province_code": 35
    },
    {
        "district": "โพนงาม",
        "amphoe": "กุดชุม",
        "province": "ยโสธร",
        "zipcode": 35140,
        "district_code": 350307,
        "amphoe_code": 3503,
        "province_code": 35
    },
    {
        "district": "กุดกุง",
        "amphoe": "คำเขื่อนแก้ว",
        "province": "ยโสธร",
        "zipcode": 35110,
        "district_code": 350410,
        "amphoe_code": 3504,
        "province_code": 35
    },
    {
        "district": "กู่จาน",
        "amphoe": "คำเขื่อนแก้ว",
        "province": "ยโสธร",
        "zipcode": 35110,
        "district_code": 350408,
        "amphoe_code": 3504,
        "province_code": 35
    },
    {
        "district": "ดงเจริญ",
        "amphoe": "คำเขื่อนแก้ว",
        "province": "ยโสธร",
        "zipcode": 35110,
        "district_code": 350413,
        "amphoe_code": 3504,
        "province_code": 35
    },
    {
        "district": "ดงแคนใหญ่",
        "amphoe": "คำเขื่อนแก้ว",
        "province": "ยโสธร",
        "zipcode": 35180,
        "district_code": 350407,
        "amphoe_code": 3504,
        "province_code": 35
    },
    {
        "district": "ทุ่งมน",
        "amphoe": "คำเขื่อนแก้ว",
        "province": "ยโสธร",
        "zipcode": 35110,
        "district_code": 350405,
        "amphoe_code": 3504,
        "province_code": 35
    },
    {
        "district": "นาคำ",
        "amphoe": "คำเขื่อนแก้ว",
        "province": "ยโสธร",
        "zipcode": 35180,
        "district_code": 350406,
        "amphoe_code": 3504,
        "province_code": 35
    },
    {
        "district": "นาแก",
        "amphoe": "คำเขื่อนแก้ว",
        "province": "ยโสธร",
        "zipcode": 35180,
        "district_code": 350409,
        "amphoe_code": 3504,
        "province_code": 35
    },
    {
        "district": "ย่อ",
        "amphoe": "คำเขื่อนแก้ว",
        "province": "ยโสธร",
        "zipcode": 35110,
        "district_code": 350402,
        "amphoe_code": 3504,
        "province_code": 35
    },
    {
        "district": "ลุมพุก",
        "amphoe": "คำเขื่อนแก้ว",
        "province": "ยโสธร",
        "zipcode": 35110,
        "district_code": 350401,
        "amphoe_code": 3504,
        "province_code": 35
    },
    {
        "district": "สงเปือย",
        "amphoe": "คำเขื่อนแก้ว",
        "province": "ยโสธร",
        "zipcode": 35110,
        "district_code": 350403,
        "amphoe_code": 3504,
        "province_code": 35
    },
    {
        "district": "เหล่าไฮ",
        "amphoe": "คำเขื่อนแก้ว",
        "province": "ยโสธร",
        "zipcode": 35110,
        "district_code": 350411,
        "amphoe_code": 3504,
        "province_code": 35
    },
    {
        "district": "แคนน้อย",
        "amphoe": "คำเขื่อนแก้ว",
        "province": "ยโสธร",
        "zipcode": 35180,
        "district_code": 350412,
        "amphoe_code": 3504,
        "province_code": 35
    },
    {
        "district": "โพนทัน",
        "amphoe": "คำเขื่อนแก้ว",
        "province": "ยโสธร",
        "zipcode": 35110,
        "district_code": 350404,
        "amphoe_code": 3504,
        "province_code": 35
    },
    {
        "district": "กุดน้ำใส",
        "amphoe": "ค้อวัง",
        "province": "ยโสธร",
        "zipcode": 35160,
        "district_code": 350698,
        "amphoe_code": 3507,
        "province_code": 35
    },
    {
        "district": "ค้อวัง",
        "amphoe": "ค้อวัง",
        "province": "ยโสธร",
        "zipcode": 35160,
        "district_code": 350696,
        "amphoe_code": 3507,
        "province_code": 35
    },
    {
        "district": "น้ำอ้อม",
        "amphoe": "ค้อวัง",
        "province": "ยโสธร",
        "zipcode": 35160,
        "district_code": 350697,
        "amphoe_code": 3507,
        "province_code": 35
    },
    {
        "district": "ฟ้าห่วน",
        "amphoe": "ค้อวัง",
        "province": "ยโสธร",
        "zipcode": 35160,
        "district_code": 350699,
        "amphoe_code": 3507,
        "province_code": 35
    },
    {
        "district": "ดงมะไฟ",
        "amphoe": "ทรายมูล",
        "province": "ยโสธร",
        "zipcode": 35170,
        "district_code": 350197,
        "amphoe_code": 3502,
        "province_code": 35
    },
    {
        "district": "ดู่ลาด",
        "amphoe": "ทรายมูล",
        "province": "ยโสธร",
        "zipcode": 35170,
        "district_code": 350202,
        "amphoe_code": 3502,
        "province_code": 35
    },
    {
        "district": "ทรายมูล",
        "amphoe": "ทรายมูล",
        "province": "ยโสธร",
        "zipcode": 35170,
        "district_code": 350199,
        "amphoe_code": 3502,
        "province_code": 35
    },
    {
        "district": "นาเวียง",
        "amphoe": "ทรายมูล",
        "province": "ยโสธร",
        "zipcode": 35170,
        "district_code": 350196,
        "amphoe_code": 3502,
        "province_code": 35
    },
    {
        "district": "ไผ่",
        "amphoe": "ทรายมูล",
        "province": "ยโสธร",
        "zipcode": 35170,
        "district_code": 350205,
        "amphoe_code": 3502,
        "province_code": 35
    },
    {
        "district": "กระจาย",
        "amphoe": "ป่าติ้ว",
        "province": "ยโสธร",
        "zipcode": 35150,
        "district_code": 350502,
        "amphoe_code": 3505,
        "province_code": 35
    },
    {
        "district": "ศรีฐาน",
        "amphoe": "ป่าติ้ว",
        "province": "ยโสธร",
        "zipcode": 35150,
        "district_code": 350505,
        "amphoe_code": 3505,
        "province_code": 35
    },
    {
        "district": "เชียงเพ็ง",
        "amphoe": "ป่าติ้ว",
        "province": "ยโสธร",
        "zipcode": 35150,
        "district_code": 350504,
        "amphoe_code": 3505,
        "province_code": 35
    },
    {
        "district": "โคกนาโก",
        "amphoe": "ป่าติ้ว",
        "province": "ยโสธร",
        "zipcode": 35150,
        "district_code": 350503,
        "amphoe_code": 3505,
        "province_code": 35
    },
    {
        "district": "โพธิ์ไทร",
        "amphoe": "ป่าติ้ว",
        "province": "ยโสธร",
        "zipcode": 35150,
        "district_code": 350501,
        "amphoe_code": 3505,
        "province_code": 35
    },
    {
        "district": "คูเมือง",
        "amphoe": "มหาชนะชัย",
        "province": "ยโสธร",
        "zipcode": 35130,
        "district_code": 350603,
        "amphoe_code": 3506,
        "province_code": 35
    },
    {
        "district": "บากเรือ",
        "amphoe": "มหาชนะชัย",
        "province": "ยโสธร",
        "zipcode": 35130,
        "district_code": 350605,
        "amphoe_code": 3506,
        "province_code": 35
    },
    {
        "district": "บึงแก",
        "amphoe": "มหาชนะชัย",
        "province": "ยโสธร",
        "zipcode": 35130,
        "district_code": 350608,
        "amphoe_code": 3506,
        "province_code": 35
    },
    {
        "district": "ผือฮี",
        "amphoe": "มหาชนะชัย",
        "province": "ยโสธร",
        "zipcode": 35130,
        "district_code": 350604,
        "amphoe_code": 3506,
        "province_code": 35
    },
    {
        "district": "พระเสาร์",
        "amphoe": "มหาชนะชัย",
        "province": "ยโสธร",
        "zipcode": 35130,
        "district_code": 350609,
        "amphoe_code": 3506,
        "province_code": 35
    },
    {
        "district": "ฟ้าหยาด",
        "amphoe": "มหาชนะชัย",
        "province": "ยโสธร",
        "zipcode": 35130,
        "district_code": 350601,
        "amphoe_code": 3506,
        "province_code": 35
    },
    {
        "district": "ม่วง",
        "amphoe": "มหาชนะชัย",
        "province": "ยโสธร",
        "zipcode": 35130,
        "district_code": 350606,
        "amphoe_code": 3506,
        "province_code": 35
    },
    {
        "district": "สงยาง",
        "amphoe": "มหาชนะชัย",
        "province": "ยโสธร",
        "zipcode": 35130,
        "district_code": 350610,
        "amphoe_code": 3506,
        "province_code": 35
    },
    {
        "district": "หัวเมือง",
        "amphoe": "มหาชนะชัย",
        "province": "ยโสธร",
        "zipcode": 35130,
        "district_code": 350602,
        "amphoe_code": 3506,
        "province_code": 35
    },
    {
        "district": "โนนทราย",
        "amphoe": "มหาชนะชัย",
        "province": "ยโสธร",
        "zipcode": 35130,
        "district_code": 350607,
        "amphoe_code": 3506,
        "province_code": 35
    },
    {
        "district": "ขั้นไดใหญ่",
        "amphoe": "เมืองยโสธร",
        "province": "ยโสธร",
        "zipcode": 35000,
        "district_code": 350108,
        "amphoe_code": 3501,
        "province_code": 35
    },
    {
        "district": "ขุมเงิน",
        "amphoe": "เมืองยโสธร",
        "province": "ยโสธร",
        "zipcode": 35000,
        "district_code": 350115,
        "amphoe_code": 3501,
        "province_code": 35
    },
    {
        "district": "ค้อเหนือ",
        "amphoe": "เมืองยโสธร",
        "province": "ยโสธร",
        "zipcode": 35000,
        "district_code": 350105,
        "amphoe_code": 3501,
        "province_code": 35
    },
    {
        "district": "ดู่ทุ่ง",
        "amphoe": "เมืองยโสธร",
        "province": "ยโสธร",
        "zipcode": 35000,
        "district_code": 350106,
        "amphoe_code": 3501,
        "province_code": 35
    },
    {
        "district": "ตาดทอง",
        "amphoe": "เมืองยโสธร",
        "province": "ยโสธร",
        "zipcode": 35000,
        "district_code": 350103,
        "amphoe_code": 3501,
        "province_code": 35
    },
    {
        "district": "ทุ่งนางโอก",
        "amphoe": "เมืองยโสธร",
        "province": "ยโสธร",
        "zipcode": 35000,
        "district_code": 350116,
        "amphoe_code": 3501,
        "province_code": 35
    },
    {
        "district": "ทุ่งแต้",
        "amphoe": "เมืองยโสธร",
        "province": "ยโสธร",
        "zipcode": 35000,
        "district_code": 350109,
        "amphoe_code": 3501,
        "province_code": 35
    },
    {
        "district": "นาสะไมย์",
        "amphoe": "เมืองยโสธร",
        "province": "ยโสธร",
        "zipcode": 35000,
        "district_code": 350111,
        "amphoe_code": 3501,
        "province_code": 35
    },
    {
        "district": "น้ำคำใหญ่",
        "amphoe": "เมืองยโสธร",
        "province": "ยโสธร",
        "zipcode": 35000,
        "district_code": 350102,
        "amphoe_code": 3501,
        "province_code": 35
    },
    {
        "district": "สำราญ",
        "amphoe": "เมืองยโสธร",
        "province": "ยโสธร",
        "zipcode": 35000,
        "district_code": 350104,
        "amphoe_code": 3501,
        "province_code": 35
    },
    {
        "district": "สิงห์",
        "amphoe": "เมืองยโสธร",
        "province": "ยโสธร",
        "zipcode": 35000,
        "district_code": 350110,
        "amphoe_code": 3501,
        "province_code": 35
    },
    {
        "district": "หนองคู",
        "amphoe": "เมืองยโสธร",
        "province": "ยโสธร",
        "zipcode": 35000,
        "district_code": 350114,
        "amphoe_code": 3501,
        "province_code": 35
    },
    {
        "district": "หนองหิน",
        "amphoe": "เมืองยโสธร",
        "province": "ยโสธร",
        "zipcode": 35000,
        "district_code": 350113,
        "amphoe_code": 3501,
        "province_code": 35
    },
    {
        "district": "หนองเป็ด",
        "amphoe": "เมืองยโสธร",
        "province": "ยโสธร",
        "zipcode": 35000,
        "district_code": 350118,
        "amphoe_code": 3501,
        "province_code": 35
    },
    {
        "district": "หนองเรือ",
        "amphoe": "เมืองยโสธร",
        "province": "ยโสธร",
        "zipcode": 35000,
        "district_code": 350117,
        "amphoe_code": 3501,
        "province_code": 35
    },
    {
        "district": "เขื่องคำ",
        "amphoe": "เมืองยโสธร",
        "province": "ยโสธร",
        "zipcode": 35000,
        "district_code": 350112,
        "amphoe_code": 3501,
        "province_code": 35
    },
    {
        "district": "เดิด",
        "amphoe": "เมืองยโสธร",
        "province": "ยโสธร",
        "zipcode": 35000,
        "district_code": 350107,
        "amphoe_code": 3501,
        "province_code": 35
    },
    {
        "district": "ในเมือง",
        "amphoe": "เมืองยโสธร",
        "province": "ยโสธร",
        "zipcode": 35000,
        "district_code": 350101,
        "amphoe_code": 3501,
        "province_code": 35
    },
    {
        "district": "กุดเชียงหมี",
        "amphoe": "เลิงนกทา",
        "province": "ยโสธร",
        "zipcode": 35120,
        "district_code": 350807,
        "amphoe_code": 3508,
        "province_code": 35
    },
    {
        "district": "กุดแห่",
        "amphoe": "เลิงนกทา",
        "province": "ยโสธร",
        "zipcode": 35120,
        "district_code": 350811,
        "amphoe_code": 3508,
        "province_code": 35
    },
    {
        "district": "บุ่งค้า",
        "amphoe": "เลิงนกทา",
        "province": "ยโสธร",
        "zipcode": 35120,
        "district_code": 350802,
        "amphoe_code": 3508,
        "province_code": 35
    },
    {
        "district": "ศรีแก้ว",
        "amphoe": "เลิงนกทา",
        "province": "ยโสธร",
        "zipcode": 35120,
        "district_code": 350814,
        "amphoe_code": 3508,
        "province_code": 35
    },
    {
        "district": "สร้างมิ่ง",
        "amphoe": "เลิงนกทา",
        "province": "ยโสธร",
        "zipcode": 35120,
        "district_code": 350813,
        "amphoe_code": 3508,
        "province_code": 35
    },
    {
        "district": "สวาท",
        "amphoe": "เลิงนกทา",
        "province": "ยโสธร",
        "zipcode": 35120,
        "district_code": 350803,
        "amphoe_code": 3508,
        "province_code": 35
    },
    {
        "district": "สามัคคี",
        "amphoe": "เลิงนกทา",
        "province": "ยโสธร",
        "zipcode": 35120,
        "district_code": 350806,
        "amphoe_code": 3508,
        "province_code": 35
    },
    {
        "district": "สามแยก",
        "amphoe": "เลิงนกทา",
        "province": "ยโสธร",
        "zipcode": 35120,
        "district_code": 350810,
        "amphoe_code": 3508,
        "province_code": 35
    },
    {
        "district": "ห้องแซง",
        "amphoe": "เลิงนกทา",
        "province": "ยโสธร",
        "zipcode": 35120,
        "district_code": 350805,
        "amphoe_code": 3508,
        "province_code": 35
    },
    {
        "district": "โคกสำราญ",
        "amphoe": "เลิงนกทา",
        "province": "ยโสธร",
        "zipcode": 35120,
        "district_code": 350812,
        "amphoe_code": 3508,
        "province_code": 35
    },
    {
        "district": "คำเตย",
        "amphoe": "ไทยเจริญ",
        "province": "ยโสธร",
        "zipcode": 35120,
        "district_code": 350808,
        "amphoe_code": 3509,
        "province_code": 35
    },
    {
        "district": "คำไผ่",
        "amphoe": "ไทยเจริญ",
        "province": "ยโสธร",
        "zipcode": 35120,
        "district_code": 350809,
        "amphoe_code": 3509,
        "province_code": 35
    },
    {
        "district": "น้ำคำ",
        "amphoe": "ไทยเจริญ",
        "province": "ยโสธร",
        "zipcode": 35120,
        "district_code": 350801,
        "amphoe_code": 3509,
        "province_code": 35
    },
    {
        "district": "ส้มผ่อ",
        "amphoe": "ไทยเจริญ",
        "province": "ยโสธร",
        "zipcode": 35120,
        "district_code": 350804,
        "amphoe_code": 3509,
        "province_code": 35
    },
    {
        "district": "ไทยเจริญ",
        "amphoe": "ไทยเจริญ",
        "province": "ยโสธร",
        "zipcode": 35120,
        "district_code": 350815,
        "amphoe_code": 3509,
        "province_code": 35
    },
    {
        "district": "จ.ป.ร.",
        "amphoe": "กระบุรี",
        "province": "ระนอง",
        "zipcode": 85110,
        "district_code": 850406,
        "amphoe_code": 8504,
        "province_code": 85
    },
    {
        "district": "น้ำจืด",
        "amphoe": "กระบุรี",
        "province": "ระนอง",
        "zipcode": 85110,
        "district_code": 850401,
        "amphoe_code": 8504,
        "province_code": 85
    },
    {
        "district": "น้ำจืดน้อย",
        "amphoe": "กระบุรี",
        "province": "ระนอง",
        "zipcode": 85110,
        "district_code": 850402,
        "amphoe_code": 8504,
        "province_code": 85
    },
    {
        "district": "บางใหญ่",
        "amphoe": "กระบุรี",
        "province": "ระนอง",
        "zipcode": 85110,
        "district_code": 850407,
        "amphoe_code": 8504,
        "province_code": 85
    },
    {
        "district": "ปากจั่น",
        "amphoe": "กระบุรี",
        "province": "ระนอง",
        "zipcode": 85110,
        "district_code": 850404,
        "amphoe_code": 8504,
        "province_code": 85
    },
    {
        "district": "มะมุ",
        "amphoe": "กระบุรี",
        "province": "ระนอง",
        "zipcode": 85110,
        "district_code": 850403,
        "amphoe_code": 8504,
        "province_code": 85
    },
    {
        "district": "ลำเลียง",
        "amphoe": "กระบุรี",
        "province": "ระนอง",
        "zipcode": 85110,
        "district_code": 850405,
        "amphoe_code": 8504,
        "province_code": 85
    },
    {
        "district": "กะเปอร์",
        "amphoe": "กะเปอร์",
        "province": "ระนอง",
        "zipcode": 85120,
        "district_code": 850302,
        "amphoe_code": 8503,
        "province_code": 85
    },
    {
        "district": "บางหิน",
        "amphoe": "กะเปอร์",
        "province": "ระนอง",
        "zipcode": 85120,
        "district_code": 850305,
        "amphoe_code": 8503,
        "province_code": 85
    },
    {
        "district": "บ้านนา",
        "amphoe": "กะเปอร์",
        "province": "ระนอง",
        "zipcode": 85120,
        "district_code": 850304,
        "amphoe_code": 8503,
        "province_code": 85
    },
    {
        "district": "ม่วงกลวง",
        "amphoe": "กะเปอร์",
        "province": "ระนอง",
        "zipcode": 85120,
        "district_code": 850301,
        "amphoe_code": 8503,
        "province_code": 85
    },
    {
        "district": "เชี่ยวเหลียง",
        "amphoe": "กะเปอร์",
        "province": "ระนอง",
        "zipcode": 85120,
        "district_code": 850303,
        "amphoe_code": 8503,
        "province_code": 85
    },
    {
        "district": "บางพระเหนือ",
        "amphoe": "ละอุ่น",
        "province": "ระนอง",
        "zipcode": 85130,
        "district_code": 850204,
        "amphoe_code": 8502,
        "province_code": 85
    },
    {
        "district": "บางพระใต้",
        "amphoe": "ละอุ่น",
        "province": "ระนอง",
        "zipcode": 85130,
        "district_code": 850203,
        "amphoe_code": 8502,
        "province_code": 85
    },
    {
        "district": "บางแก้ว",
        "amphoe": "ละอุ่น",
        "province": "ระนอง",
        "zipcode": 85130,
        "district_code": 850205,
        "amphoe_code": 8502,
        "province_code": 85
    },
    {
        "district": "ละอุ่นเหนือ",
        "amphoe": "ละอุ่น",
        "province": "ระนอง",
        "zipcode": 85130,
        "district_code": 850202,
        "amphoe_code": 8502,
        "province_code": 85
    },
    {
        "district": "ละอุ่นใต้",
        "amphoe": "ละอุ่น",
        "province": "ระนอง",
        "zipcode": 85130,
        "district_code": 850201,
        "amphoe_code": 8502,
        "province_code": 85
    },
    {
        "district": "ในวงเหนือ",
        "amphoe": "ละอุ่น",
        "province": "ระนอง",
        "zipcode": 85130,
        "district_code": 850206,
        "amphoe_code": 8502,
        "province_code": 85
    },
    {
        "district": "ในวงใต้",
        "amphoe": "ละอุ่น",
        "province": "ระนอง",
        "zipcode": 85130,
        "district_code": 850207,
        "amphoe_code": 8502,
        "province_code": 85
    },
    {
        "district": "กำพวน",
        "amphoe": "สุขสำราญ",
        "province": "ระนอง",
        "zipcode": 85120,
        "district_code": 850307,
        "amphoe_code": 8505,
        "province_code": 85
    },
    {
        "district": "นาคา",
        "amphoe": "สุขสำราญ",
        "province": "ระนอง",
        "zipcode": 85120,
        "district_code": 850306,
        "amphoe_code": 8505,
        "province_code": 85
    },
    {
        "district": "ทรายแดง",
        "amphoe": "เมืองระนอง",
        "province": "ระนอง",
        "zipcode": 85130,
        "district_code": 850108,
        "amphoe_code": 8501,
        "province_code": 85
    },
    {
        "district": "บางนอน",
        "amphoe": "เมืองระนอง",
        "province": "ระนอง",
        "zipcode": 85000,
        "district_code": 850106,
        "amphoe_code": 8501,
        "province_code": 85
    },
    {
        "district": "บางริ้น",
        "amphoe": "เมืองระนอง",
        "province": "ระนอง",
        "zipcode": 85000,
        "district_code": 850104,
        "amphoe_code": 8501,
        "province_code": 85
    },
    {
        "district": "ปากน้ำ",
        "amphoe": "เมืองระนอง",
        "province": "ระนอง",
        "zipcode": 85000,
        "district_code": 850105,
        "amphoe_code": 8501,
        "province_code": 85
    },
    {
        "district": "ราชกรูด",
        "amphoe": "เมืองระนอง",
        "province": "ระนอง",
        "zipcode": 85000,
        "district_code": 850102,
        "amphoe_code": 8501,
        "province_code": 85
    },
    {
        "district": "หงาว",
        "amphoe": "เมืองระนอง",
        "province": "ระนอง",
        "zipcode": 85000,
        "district_code": 850103,
        "amphoe_code": 8501,
        "province_code": 85
    },
    {
        "district": "หาดส้มแป้น",
        "amphoe": "เมืองระนอง",
        "province": "ระนอง",
        "zipcode": 85000,
        "district_code": 850107,
        "amphoe_code": 8501,
        "province_code": 85
    },
    {
        "district": "เกาะพยาม",
        "amphoe": "เมืองระนอง",
        "province": "ระนอง",
        "zipcode": 85000,
        "district_code": 850109,
        "amphoe_code": 8501,
        "province_code": 85
    },
    {
        "district": "เขานิเวศน์",
        "amphoe": "เมืองระนอง",
        "province": "ระนอง",
        "zipcode": 85000,
        "district_code": 850101,
        "amphoe_code": 8501,
        "province_code": 85
    },
    {
        "district": "นิคมพัฒนา",
        "amphoe": "นิคมพัฒนา",
        "province": "ระยอง",
        "zipcode": 21180,
        "district_code": 210510,
        "amphoe_code": 2108,
        "province_code": 21
    },
    {
        "district": "พนานิคม",
        "amphoe": "นิคมพัฒนา",
        "province": "ระยอง",
        "zipcode": 21180,
        "district_code": 210509,
        "amphoe_code": 2108,
        "province_code": 21
    },
    {
        "district": "มะขามคู่",
        "amphoe": "นิคมพัฒนา",
        "province": "ระยอง",
        "zipcode": 21180,
        "district_code": 210511,
        "amphoe_code": 2108,
        "province_code": 21
    },
    {
        "district": "มาบข่า",
        "amphoe": "นิคมพัฒนา",
        "province": "ระยอง",
        "zipcode": 21180,
        "district_code": 210508,
        "amphoe_code": 2108,
        "province_code": 21
    },
    {
        "district": "ชากบก",
        "amphoe": "บ้านค่าย",
        "province": "ระยอง",
        "zipcode": 21120,
        "district_code": 210507,
        "amphoe_code": 2105,
        "province_code": 21
    },
    {
        "district": "ตาขัน",
        "amphoe": "บ้านค่าย",
        "province": "ระยอง",
        "zipcode": 21120,
        "district_code": 210504,
        "amphoe_code": 2105,
        "province_code": 21
    },
    {
        "district": "บางบุตร",
        "amphoe": "บ้านค่าย",
        "province": "ระยอง",
        "zipcode": 21120,
        "district_code": 210505,
        "amphoe_code": 2105,
        "province_code": 21
    },
    {
        "district": "บ้านค่าย",
        "amphoe": "บ้านค่าย",
        "province": "ระยอง",
        "zipcode": 21120,
        "district_code": 210501,
        "amphoe_code": 2105,
        "province_code": 21
    },
    {
        "district": "หนองตะพาน",
        "amphoe": "บ้านค่าย",
        "province": "ระยอง",
        "zipcode": 21120,
        "district_code": 210503,
        "amphoe_code": 2105,
        "province_code": 21
    },
    {
        "district": "หนองบัว",
        "amphoe": "บ้านค่าย",
        "province": "ระยอง",
        "zipcode": 21120,
        "district_code": 210506,
        "amphoe_code": 2105,
        "province_code": 21
    },
    {
        "district": "หนองละลอก",
        "amphoe": "บ้านค่าย",
        "province": "ระยอง",
        "zipcode": 21120,
        "district_code": 210502,
        "amphoe_code": 2105,
        "province_code": 21
    },
    {
        "district": "บ้านฉาง",
        "amphoe": "บ้านฉาง",
        "province": "ระยอง",
        "zipcode": 21130,
        "district_code": 210203,
        "amphoe_code": 2102,
        "province_code": 21
    },
    {
        "district": "พลา",
        "amphoe": "บ้านฉาง",
        "province": "ระยอง",
        "zipcode": 21130,
        "district_code": 210199,
        "amphoe_code": 2102,
        "province_code": 21
    },
    {
        "district": "สำนักท้อน",
        "amphoe": "บ้านฉาง",
        "province": "ระยอง",
        "zipcode": 21130,
        "district_code": 210198,
        "amphoe_code": 2102,
        "province_code": 21
    },
    {
        "district": "ตาสิทธิ์",
        "amphoe": "ปลวกแดง",
        "province": "ระยอง",
        "zipcode": 21140,
        "district_code": 210598,
        "amphoe_code": 2106,
        "province_code": 21
    },
    {
        "district": "ปลวกแดง",
        "amphoe": "ปลวกแดง",
        "province": "ระยอง",
        "zipcode": 21140,
        "district_code": 210599,
        "amphoe_code": 2106,
        "province_code": 21
    },
    {
        "district": "มาบยางพร",
        "amphoe": "ปลวกแดง",
        "province": "ระยอง",
        "zipcode": 21140,
        "district_code": 210595,
        "amphoe_code": 2106,
        "province_code": 21
    },
    {
        "district": "ละหาร",
        "amphoe": "ปลวกแดง",
        "province": "ระยอง",
        "zipcode": 21140,
        "district_code": 210597,
        "amphoe_code": 2106,
        "province_code": 21
    },
    {
        "district": "หนองไร่",
        "amphoe": "ปลวกแดง",
        "province": "ระยอง",
        "zipcode": 21140,
        "district_code": 210594,
        "amphoe_code": 2106,
        "province_code": 21
    },
    {
        "district": "แม่น้ำคู้",
        "amphoe": "ปลวกแดง",
        "province": "ระยอง",
        "zipcode": 21140,
        "district_code": 210596,
        "amphoe_code": 2106,
        "province_code": 21
    },
    {
        "district": "ชุมแสง",
        "amphoe": "วังจันทร์",
        "province": "ระยอง",
        "zipcode": 21210,
        "district_code": 210398,
        "amphoe_code": 2104,
        "province_code": 21
    },
    {
        "district": "ป่ายุบใน",
        "amphoe": "วังจันทร์",
        "province": "ระยอง",
        "zipcode": 21210,
        "district_code": 210403,
        "amphoe_code": 2104,
        "province_code": 21
    },
    {
        "district": "พลงตาเอี่ยม",
        "amphoe": "วังจันทร์",
        "province": "ระยอง",
        "zipcode": 21210,
        "district_code": 210404,
        "amphoe_code": 2104,
        "province_code": 21
    },
    {
        "district": "วังจันทร์",
        "amphoe": "วังจันทร์",
        "province": "ระยอง",
        "zipcode": 21210,
        "district_code": 210399,
        "amphoe_code": 2104,
        "province_code": 21
    },
    {
        "district": "ชำฆ้อ",
        "amphoe": "เขาชะเมา",
        "province": "ระยอง",
        "zipcode": 21110,
        "district_code": 210315,
        "amphoe_code": 2107,
        "province_code": 21
    },
    {
        "district": "น้ำเป็น",
        "amphoe": "เขาชะเมา",
        "province": "ระยอง",
        "zipcode": 21110,
        "district_code": 210314,
        "amphoe_code": 2107,
        "province_code": 21
    },
    {
        "district": "ห้วยทับมอญ",
        "amphoe": "เขาชะเมา",
        "province": "ระยอง",
        "zipcode": 21110,
        "district_code": 210316,
        "amphoe_code": 2107,
        "province_code": 21
    },
    {
        "district": "เขาน้อย",
        "amphoe": "เขาชะเมา",
        "province": "ระยอง",
        "zipcode": 21110,
        "district_code": 210319,
        "amphoe_code": 2107,
        "province_code": 21
    },
    {
        "district": "กะเฉด",
        "amphoe": "เมืองระยอง",
        "province": "ระยอง",
        "zipcode": 21100,
        "district_code": 210110,
        "amphoe_code": 2101,
        "province_code": 21
    },
    {
        "district": "ตะพง",
        "amphoe": "เมืองระยอง",
        "province": "ระยอง",
        "zipcode": 21000,
        "district_code": 210103,
        "amphoe_code": 2101,
        "province_code": 21
    },
    {
        "district": "ทับมา",
        "amphoe": "เมืองระยอง",
        "province": "ระยอง",
        "zipcode": 21000,
        "district_code": 210111,
        "amphoe_code": 2101,
        "province_code": 21
    },
    {
        "district": "ท่าประดู่",
        "amphoe": "เมืองระยอง",
        "province": "ระยอง",
        "zipcode": 21000,
        "district_code": 210101,
        "amphoe_code": 2101,
        "province_code": 21
    },
    {
        "district": "นาตาขวัญ",
        "amphoe": "เมืองระยอง",
        "province": "ระยอง",
        "zipcode": 21000,
        "district_code": 210108,
        "amphoe_code": 2101,
        "province_code": 21
    },
    {
        "district": "น้ำคอก",
        "amphoe": "เมืองระยอง",
        "province": "ระยอง",
        "zipcode": 21000,
        "district_code": 210112,
        "amphoe_code": 2101,
        "province_code": 21
    },
    {
        "district": "บ้านแลง",
        "amphoe": "เมืองระยอง",
        "province": "ระยอง",
        "zipcode": 21000,
        "district_code": 210107,
        "amphoe_code": 2101,
        "province_code": 21
    },
    {
        "district": "ปากน้ำ",
        "amphoe": "เมืองระยอง",
        "province": "ระยอง",
        "zipcode": 21000,
        "district_code": 210104,
        "amphoe_code": 2101,
        "province_code": 21
    },
    {
        "district": "มาบตาพุด",
        "amphoe": "เมืองระยอง",
        "province": "ระยอง",
        "zipcode": 21150,
        "district_code": 210114,
        "amphoe_code": 2101,
        "province_code": 21
    },
    {
        "district": "สำนักทอง",
        "amphoe": "เมืองระยอง",
        "province": "ระยอง",
        "zipcode": 21100,
        "district_code": 210115,
        "amphoe_code": 2101,
        "province_code": 21
    },
    {
        "district": "ห้วยโป่ง",
        "amphoe": "เมืองระยอง",
        "province": "ระยอง",
        "zipcode": 21150,
        "district_code": 210113,
        "amphoe_code": 2101,
        "province_code": 21
    },
    {
        "district": "เชิงเนิน",
        "amphoe": "เมืองระยอง",
        "province": "ระยอง",
        "zipcode": 21000,
        "district_code": 210102,
        "amphoe_code": 2101,
        "province_code": 21
    },
    {
        "district": "เนินพระ",
        "amphoe": "เมืองระยอง",
        "province": "ระยอง",
        "zipcode": 21000,
        "district_code": 210109,
        "amphoe_code": 2101,
        "province_code": 21
    },
    {
        "district": "เนินพระ",
        "amphoe": "เมืองระยอง",
        "province": "ระยอง",
        "zipcode": 21150,
        "district_code": 210109,
        "amphoe_code": 2101,
        "province_code": 21
    },
    {
        "district": "เพ",
        "amphoe": "เมืองระยอง",
        "province": "ระยอง",
        "zipcode": 21160,
        "district_code": 210105,
        "amphoe_code": 2101,
        "province_code": 21
    },
    {
        "district": "แกลง",
        "amphoe": "เมืองระยอง",
        "province": "ระยอง",
        "zipcode": 21160,
        "district_code": 210106,
        "amphoe_code": 2101,
        "province_code": 21
    },
    {
        "district": "กระแสบน",
        "amphoe": "แกลง",
        "province": "ระยอง",
        "zipcode": 21110,
        "district_code": 210307,
        "amphoe_code": 2103,
        "province_code": 21
    },
    {
        "district": "กร่ำ",
        "amphoe": "แกลง",
        "province": "ระยอง",
        "zipcode": 21190,
        "district_code": 210305,
        "amphoe_code": 2103,
        "province_code": 21
    },
    {
        "district": "กองดิน",
        "amphoe": "แกลง",
        "province": "ระยอง",
        "zipcode": 22160,
        "district_code": 210310,
        "amphoe_code": 2103,
        "province_code": 21
    },
    {
        "district": "คลองปูน",
        "amphoe": "แกลง",
        "province": "ระยอง",
        "zipcode": 21170,
        "district_code": 210311,
        "amphoe_code": 2103,
        "province_code": 21
    },
    {
        "district": "ชากพง",
        "amphoe": "แกลง",
        "province": "ระยอง",
        "zipcode": 21190,
        "district_code": 210306,
        "amphoe_code": 2103,
        "province_code": 21
    },
    {
        "district": "ชากโดน",
        "amphoe": "แกลง",
        "province": "ระยอง",
        "zipcode": 21110,
        "district_code": 210303,
        "amphoe_code": 2103,
        "province_code": 21
    },
    {
        "district": "ทางเกวียน",
        "amphoe": "แกลง",
        "province": "ระยอง",
        "zipcode": 21110,
        "district_code": 210301,
        "amphoe_code": 2103,
        "province_code": 21
    },
    {
        "district": "ทุ่งควายกิน",
        "amphoe": "แกลง",
        "province": "ระยอง",
        "zipcode": 21110,
        "district_code": 210309,
        "amphoe_code": 2103,
        "province_code": 21
    },
    {
        "district": "บ้านนา",
        "amphoe": "แกลง",
        "province": "ระยอง",
        "zipcode": 21110,
        "district_code": 210308,
        "amphoe_code": 2103,
        "province_code": 21
    },
    {
        "district": "ปากน้ำกระแส",
        "amphoe": "แกลง",
        "province": "ระยอง",
        "zipcode": 21170,
        "district_code": 210313,
        "amphoe_code": 2103,
        "province_code": 21
    },
    {
        "district": "พังราด",
        "amphoe": "แกลง",
        "province": "ระยอง",
        "zipcode": 21170,
        "district_code": 210312,
        "amphoe_code": 2103,
        "province_code": 21
    },
    {
        "district": "วังหว้า",
        "amphoe": "แกลง",
        "province": "ระยอง",
        "zipcode": 21110,
        "district_code": 210302,
        "amphoe_code": 2103,
        "province_code": 21
    },
    {
        "district": "สองสลึง",
        "amphoe": "แกลง",
        "province": "ระยอง",
        "zipcode": 21110,
        "district_code": 210318,
        "amphoe_code": 2103,
        "province_code": 21
    },
    {
        "district": "ห้วยยาง",
        "amphoe": "แกลง",
        "province": "ระยอง",
        "zipcode": 21110,
        "district_code": 210317,
        "amphoe_code": 2103,
        "province_code": 21
    },
    {
        "district": "เนินฆ้อ",
        "amphoe": "แกลง",
        "province": "ระยอง",
        "zipcode": 21110,
        "district_code": 210304,
        "amphoe_code": 2103,
        "province_code": 21
    },
    {
        "district": "จอมบึง",
        "amphoe": "จอมบึง",
        "province": "ราชบุรี",
        "zipcode": 70150,
        "district_code": 700201,
        "amphoe_code": 7002,
        "province_code": 70
    },
    {
        "district": "ด่านทับตะโก",
        "amphoe": "จอมบึง",
        "province": "ราชบุรี",
        "zipcode": 70150,
        "district_code": 700204,
        "amphoe_code": 7002,
        "province_code": 70
    },
    {
        "district": "ปากช่อง",
        "amphoe": "จอมบึง",
        "province": "ราชบุรี",
        "zipcode": 70150,
        "district_code": 700202,
        "amphoe_code": 7002,
        "province_code": 70
    },
    {
        "district": "รางบัว",
        "amphoe": "จอมบึง",
        "province": "ราชบุรี",
        "zipcode": 70150,
        "district_code": 700206,
        "amphoe_code": 7002,
        "province_code": 70
    },
    {
        "district": "เบิกไพร",
        "amphoe": "จอมบึง",
        "province": "ราชบุรี",
        "zipcode": 70150,
        "district_code": 700203,
        "amphoe_code": 7002,
        "province_code": 70
    },
    {
        "district": "แก้มอ้น",
        "amphoe": "จอมบึง",
        "province": "ราชบุรี",
        "zipcode": 70150,
        "district_code": 700205,
        "amphoe_code": 7002,
        "province_code": 70
    },
    {
        "district": "ขุนพิทักษ์",
        "amphoe": "ดำเนินสะดวก",
        "province": "ราชบุรี",
        "zipcode": 70130,
        "district_code": 700412,
        "amphoe_code": 7004,
        "province_code": 70
    },
    {
        "district": "ดอนกรวย",
        "amphoe": "ดำเนินสะดวก",
        "province": "ราชบุรี",
        "zipcode": 70130,
        "district_code": 700405,
        "amphoe_code": 7004,
        "province_code": 70
    },
    {
        "district": "ดอนคลัง",
        "amphoe": "ดำเนินสะดวก",
        "province": "ราชบุรี",
        "zipcode": 70130,
        "district_code": 700406,
        "amphoe_code": 7004,
        "province_code": 70
    },
    {
        "district": "ดอนไผ่",
        "amphoe": "ดำเนินสะดวก",
        "province": "ราชบุรี",
        "zipcode": 70130,
        "district_code": 700413,
        "amphoe_code": 7004,
        "province_code": 70
    },
    {
        "district": "ดำเนินสะดวก",
        "amphoe": "ดำเนินสะดวก",
        "province": "ราชบุรี",
        "zipcode": 70130,
        "district_code": 700401,
        "amphoe_code": 7004,
        "province_code": 70
    },
    {
        "district": "ตาหลวง",
        "amphoe": "ดำเนินสะดวก",
        "province": "ราชบุรี",
        "zipcode": 70130,
        "district_code": 700404,
        "amphoe_code": 7004,
        "province_code": 70
    },
    {
        "district": "ท่านัด",
        "amphoe": "ดำเนินสะดวก",
        "province": "ราชบุรี",
        "zipcode": 70130,
        "district_code": 700411,
        "amphoe_code": 7004,
        "province_code": 70
    },
    {
        "district": "บัวงาม",
        "amphoe": "ดำเนินสะดวก",
        "province": "ราชบุรี",
        "zipcode": 70210,
        "district_code": 700407,
        "amphoe_code": 7004,
        "province_code": 70
    },
    {
        "district": "บ้านไร่",
        "amphoe": "ดำเนินสะดวก",
        "province": "ราชบุรี",
        "zipcode": 70130,
        "district_code": 700122,
        "amphoe_code": 7004,
        "province_code": 70
    },
    {
        "district": "ประสาทสิทธิ์",
        "amphoe": "ดำเนินสะดวก",
        "province": "ราชบุรี",
        "zipcode": 70210,
        "district_code": 700402,
        "amphoe_code": 7004,
        "province_code": 70
    },
    {
        "district": "ศรีสุราษฎร์",
        "amphoe": "ดำเนินสะดวก",
        "province": "ราชบุรี",
        "zipcode": 70130,
        "district_code": 700403,
        "amphoe_code": 7004,
        "province_code": 70
    },
    {
        "district": "สี่หมื่น",
        "amphoe": "ดำเนินสะดวก",
        "province": "ราชบุรี",
        "zipcode": 70130,
        "district_code": 700410,
        "amphoe_code": 7004,
        "province_code": 70
    },
    {
        "district": "แพงพวย",
        "amphoe": "ดำเนินสะดวก",
        "province": "ราชบุรี",
        "zipcode": 70130,
        "district_code": 700409,
        "amphoe_code": 7004,
        "province_code": 70
    },
    {
        "district": "ดอนคา",
        "amphoe": "บางแพ",
        "province": "ราชบุรี",
        "zipcode": 70160,
        "district_code": 700606,
        "amphoe_code": 7006,
        "province_code": 70
    },
    {
        "district": "ดอนใหญ่",
        "amphoe": "บางแพ",
        "province": "ราชบุรี",
        "zipcode": 70160,
        "district_code": 700605,
        "amphoe_code": 7006,
        "province_code": 70
    },
    {
        "district": "บางแพ",
        "amphoe": "บางแพ",
        "province": "ราชบุรี",
        "zipcode": 70160,
        "district_code": 700601,
        "amphoe_code": 7006,
        "province_code": 70
    },
    {
        "district": "วังเย็น",
        "amphoe": "บางแพ",
        "province": "ราชบุรี",
        "zipcode": 70160,
        "district_code": 700602,
        "amphoe_code": 7006,
        "province_code": 70
    },
    {
        "district": "วัดแก้ว",
        "amphoe": "บางแพ",
        "province": "ราชบุรี",
        "zipcode": 70160,
        "district_code": 700604,
        "amphoe_code": 7006,
        "province_code": 70
    },
    {
        "district": "หัวโพ",
        "amphoe": "บางแพ",
        "province": "ราชบุรี",
        "zipcode": 70160,
        "district_code": 700603,
        "amphoe_code": 7006,
        "province_code": 70
    },
    {
        "district": "โพหัก",
        "amphoe": "บางแพ",
        "province": "ราชบุรี",
        "zipcode": 70160,
        "district_code": 700607,
        "amphoe_code": 7006,
        "province_code": 70
    },
    {
        "district": "บ้านคา",
        "amphoe": "บ้านคา",
        "province": "ราชบุรี",
        "zipcode": 70180,
        "district_code": 700305,
        "amphoe_code": 7010,
        "province_code": 70
    },
    {
        "district": "บ้านบึง",
        "amphoe": "บ้านคา",
        "province": "ราชบุรี",
        "zipcode": 70180,
        "district_code": 700303,
        "amphoe_code": 7010,
        "province_code": 70
    },
    {
        "district": "หนองพันจันทร์",
        "amphoe": "บ้านคา",
        "province": "ราชบุรี",
        "zipcode": 70180,
        "district_code": 700306,
        "amphoe_code": 7010,
        "province_code": 70
    },
    {
        "district": "กรับใหญ่",
        "amphoe": "บ้านโป่ง",
        "province": "ราชบุรี",
        "zipcode": 70190,
        "district_code": 700503,
        "amphoe_code": 7005,
        "province_code": 70
    },
    {
        "district": "คุ้งพยอม",
        "amphoe": "บ้านโป่ง",
        "province": "ราชบุรี",
        "zipcode": 70110,
        "district_code": 700511,
        "amphoe_code": 7005,
        "province_code": 70
    },
    {
        "district": "ดอนกระเบื้อง",
        "amphoe": "บ้านโป่ง",
        "province": "ราชบุรี",
        "zipcode": 70110,
        "district_code": 700507,
        "amphoe_code": 7005,
        "province_code": 70
    },
    {
        "district": "ท่าผา",
        "amphoe": "บ้านโป่ง",
        "province": "ราชบุรี",
        "zipcode": 70110,
        "district_code": 700502,
        "amphoe_code": 7005,
        "province_code": 70
    },
    {
        "district": "นครชุมน์",
        "amphoe": "บ้านโป่ง",
        "province": "ราชบุรี",
        "zipcode": 70110,
        "district_code": 700509,
        "amphoe_code": 7005,
        "province_code": 70
    },
    {
        "district": "บ้านม่วง",
        "amphoe": "บ้านโป่ง",
        "province": "ราชบุรี",
        "zipcode": 70110,
        "district_code": 700510,
        "amphoe_code": 7005,
        "province_code": 70
    },
    {
        "district": "บ้านโป่ง",
        "amphoe": "บ้านโป่ง",
        "province": "ราชบุรี",
        "zipcode": 70110,
        "district_code": 700501,
        "amphoe_code": 7005,
        "province_code": 70
    },
    {
        "district": "ปากแรต",
        "amphoe": "บ้านโป่ง",
        "province": "ราชบุรี",
        "zipcode": 70110,
        "district_code": 700504,
        "amphoe_code": 7005,
        "province_code": 70
    },
    {
        "district": "ลาดบัวขาว",
        "amphoe": "บ้านโป่ง",
        "province": "ราชบุรี",
        "zipcode": 70110,
        "district_code": 700515,
        "amphoe_code": 7005,
        "province_code": 70
    },
    {
        "district": "สวนกล้วย",
        "amphoe": "บ้านโป่ง",
        "province": "ราชบุรี",
        "zipcode": 70110,
        "district_code": 700508,
        "amphoe_code": 7005,
        "province_code": 70
    },
    {
        "district": "หนองกบ",
        "amphoe": "บ้านโป่ง",
        "province": "ราชบุรี",
        "zipcode": 70110,
        "district_code": 700505,
        "amphoe_code": 7005,
        "province_code": 70
    },
    {
        "district": "หนองปลาหมอ",
        "amphoe": "บ้านโป่ง",
        "province": "ราชบุรี",
        "zipcode": 70110,
        "district_code": 700512,
        "amphoe_code": 7005,
        "province_code": 70
    },
    {
        "district": "หนองอ้อ",
        "amphoe": "บ้านโป่ง",
        "province": "ราชบุรี",
        "zipcode": 70110,
        "district_code": 700506,
        "amphoe_code": 7005,
        "province_code": 70
    },
    {
        "district": "เขาขลุง",
        "amphoe": "บ้านโป่ง",
        "province": "ราชบุรี",
        "zipcode": 70110,
        "district_code": 700513,
        "amphoe_code": 7005,
        "province_code": 70
    },
    {
        "district": "เบิกไพร",
        "amphoe": "บ้านโป่ง",
        "province": "ราชบุรี",
        "zipcode": 70110,
        "district_code": 700203,
        "amphoe_code": 7005,
        "province_code": 70
    },
    {
        "district": "ดอนทราย",
        "amphoe": "ปากท่อ",
        "province": "ราชบุรี",
        "zipcode": 70140,
        "district_code": 700708,
        "amphoe_code": 7008,
        "province_code": 70
    },
    {
        "district": "ทุ่งหลวง",
        "amphoe": "ปากท่อ",
        "province": "ราชบุรี",
        "zipcode": 70140,
        "district_code": 700801,
        "amphoe_code": 7008,
        "province_code": 70
    },
    {
        "district": "บ่อกระดาน",
        "amphoe": "ปากท่อ",
        "province": "ราชบุรี",
        "zipcode": 70140,
        "district_code": 700809,
        "amphoe_code": 7008,
        "province_code": 70
    },
    {
        "district": "ปากท่อ",
        "amphoe": "ปากท่อ",
        "province": "ราชบุรี",
        "zipcode": 70140,
        "district_code": 700805,
        "amphoe_code": 7008,
        "province_code": 70
    },
    {
        "district": "ป่าไก่",
        "amphoe": "ปากท่อ",
        "province": "ราชบุรี",
        "zipcode": 70140,
        "district_code": 700806,
        "amphoe_code": 7008,
        "province_code": 70
    },
    {
        "district": "ยางหัก",
        "amphoe": "ปากท่อ",
        "province": "ราชบุรี",
        "zipcode": 70140,
        "district_code": 700810,
        "amphoe_code": 7008,
        "province_code": 70
    },
    {
        "district": "วังมะนาว",
        "amphoe": "ปากท่อ",
        "province": "ราชบุรี",
        "zipcode": 70140,
        "district_code": 700802,
        "amphoe_code": 7008,
        "province_code": 70
    },
    {
        "district": "วัดยางงาม",
        "amphoe": "ปากท่อ",
        "province": "ราชบุรี",
        "zipcode": 70140,
        "district_code": 700807,
        "amphoe_code": 7008,
        "province_code": 70
    },
    {
        "district": "วันดาว",
        "amphoe": "ปากท่อ",
        "province": "ราชบุรี",
        "zipcode": 70140,
        "district_code": 700811,
        "amphoe_code": 7008,
        "province_code": 70
    },
    {
        "district": "หนองกระทุ่ม",
        "amphoe": "ปากท่อ",
        "province": "ราชบุรี",
        "zipcode": 70140,
        "district_code": 700804,
        "amphoe_code": 7008,
        "province_code": 70
    },
    {
        "district": "ห้วยยางโทน",
        "amphoe": "ปากท่อ",
        "province": "ราชบุรี",
        "zipcode": 70140,
        "district_code": 700812,
        "amphoe_code": 7008,
        "province_code": 70
    },
    {
        "district": "อ่างหิน",
        "amphoe": "ปากท่อ",
        "province": "ราชบุรี",
        "zipcode": 70140,
        "district_code": 700808,
        "amphoe_code": 7008,
        "province_code": 70
    },
    {
        "district": "จอมประทัด",
        "amphoe": "วัดเพลง",
        "province": "ราชบุรี",
        "zipcode": 70170,
        "district_code": 700902,
        "amphoe_code": 7009,
        "province_code": 70
    },
    {
        "district": "วัดเพลง",
        "amphoe": "วัดเพลง",
        "province": "ราชบุรี",
        "zipcode": 70170,
        "district_code": 700903,
        "amphoe_code": 7009,
        "province_code": 70
    },
    {
        "district": "เกาะศาลพระ",
        "amphoe": "วัดเพลง",
        "province": "ราชบุรี",
        "zipcode": 70170,
        "district_code": 700901,
        "amphoe_code": 7009,
        "province_code": 70
    },
    {
        "district": "ตะนาวศรี",
        "amphoe": "สวนผึ้ง",
        "province": "ราชบุรี",
        "zipcode": 70180,
        "district_code": 700307,
        "amphoe_code": 7003,
        "province_code": 70
    },
    {
        "district": "ท่าเคย",
        "amphoe": "สวนผึ้ง",
        "province": "ราชบุรี",
        "zipcode": 70180,
        "district_code": 700304,
        "amphoe_code": 7003,
        "province_code": 70
    },
    {
        "district": "ป่าหวาย",
        "amphoe": "สวนผึ้ง",
        "province": "ราชบุรี",
        "zipcode": 70180,
        "district_code": 700297,
        "amphoe_code": 7003,
        "province_code": 70
    },
    {
        "district": "สวนผึ้ง",
        "amphoe": "สวนผึ้ง",
        "province": "ราชบุรี",
        "zipcode": 70180,
        "district_code": 700299,
        "amphoe_code": 7003,
        "province_code": 70
    },
    {
        "district": "คุ้งกระถิน",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700107,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "คุ้งน้ำวน",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700106,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "คูบัว",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700120,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "ดอนตะโก",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700103,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "ดอนแร่",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700113,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "ท่าราบ",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700121,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "น้ำพุ",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700112,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "บางป่า",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700118,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "บ้านไร่",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700122,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "พงสวาย",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700119,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "พิกุลทอง",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700111,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "สามเรือน",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700110,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "หนองกลางนา",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700104,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "หน้าเมือง",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700101,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "หลุมดิน",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700117,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "หินกอง",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700114,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "ห้วยไผ่",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700105,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "อ่างทอง",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700108,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "เกาะพลับพลา",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700116,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "เขาแร้ง",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700115,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "เจดีย์หัก",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700102,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "โคกหม้อ",
        "amphoe": "เมืองราชบุรี",
        "province": "ราชบุรี",
        "zipcode": 70000,
        "district_code": 700109,
        "amphoe_code": 7001,
        "province_code": 70
    },
    {
        "district": "คลองข่อย",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700710,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "คลองตาคต",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700705,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "ชำแระ",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700711,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "ดอนกระเบื้อง",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700507,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "ดอนทราย",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700708,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "ท่าชุมพล",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700713,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "ธรรมเสน",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700717,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "นางแก้ว",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700716,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "บางโตนด",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700714,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "บ้านฆ้อง",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700706,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "บ้านสิงห์",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700707,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "บ้านเลือก",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700704,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "สร้อยฟ้า",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700712,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "หนองกวาง",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700719,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "หนองโพ",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700703,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "เขาชะงุ้ม",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700718,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "เจ็ดเสมียน",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700709,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "เตาปูน",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700715,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "โพธาราม",
        "amphoe": "โพธาราม",
        "province": "ราชบุรี",
        "zipcode": 70120,
        "district_code": 700701,
        "amphoe_code": 7007,
        "province_code": 70
    },
    {
        "district": "ดงกลาง",
        "amphoe": "จตุรพักตรพิมาน",
        "province": "ร้อยเอ็ด",
        "zipcode": 45180,
        "district_code": 450407,
        "amphoe_code": false,
        "province_code": 45
    },
    {
        "district": "ดงแดง",
        "amphoe": "จตุรพักตรพิมาน",
        "province": "ร้อยเอ็ด",
        "zipcode": 45180,
        "district_code": 450406,
        "amphoe_code": false,
        "province_code": 45
    },
    {
        "district": "ดู่น้อย",
        "amphoe": "จตุรพักตรพิมาน",
        "province": "ร้อยเอ็ด",
        "zipcode": 45180,
        "district_code": 450411,
        "amphoe_code": false,
        "province_code": 45
    },
    {
        "district": "น้ำใส",
        "amphoe": "จตุรพักตรพิมาน",
        "province": "ร้อยเอ็ด",
        "zipcode": 45180,
        "district_code": 450405,
        "amphoe_code": false,
        "province_code": 45
    },
    {
        "district": "ป่าสังข์",
        "amphoe": "จตุรพักตรพิมาน",
        "province": "ร้อยเอ็ด",
        "zipcode": 45180,
        "district_code": 450408,
        "amphoe_code": false,
        "province_code": 45
    },
    {
        "district": "ลิ้นฟ้า",
        "amphoe": "จตุรพักตรพิมาน",
        "province": "ร้อยเอ็ด",
        "zipcode": 45180,
        "district_code": 450410,
        "amphoe_code": false,
        "province_code": 45
    },
    {
        "district": "ศรีโคตร",
        "amphoe": "จตุรพักตรพิมาน",
        "province": "ร้อยเอ็ด",
        "zipcode": 45180,
        "district_code": 450412,
        "amphoe_code": false,
        "province_code": 45
    },
    {
        "district": "หนองผือ",
        "amphoe": "จตุรพักตรพิมาน",
        "province": "ร้อยเอ็ด",
        "zipcode": 45180,
        "district_code": 450402,
        "amphoe_code": false,
        "province_code": 45
    },
    {
        "district": "หัวช้าง",
        "amphoe": "จตุรพักตรพิมาน",
        "province": "ร้อยเอ็ด",
        "zipcode": 45180,
        "district_code": 450401,
        "amphoe_code": false,
        "province_code": 45
    },
    {
        "district": "อีง่อง",
        "amphoe": "จตุรพักตรพิมาน",
        "province": "ร้อยเอ็ด",
        "zipcode": 45180,
        "district_code": 450409,
        "amphoe_code": false,
        "province_code": 45
    },
    {
        "district": "เมืองหงส์",
        "amphoe": "จตุรพักตรพิมาน",
        "province": "ร้อยเอ็ด",
        "zipcode": 45180,
        "district_code": 450403,
        "amphoe_code": false,
        "province_code": 45
    },
    {
        "district": "โคกล่าม",
        "amphoe": "จตุรพักตรพิมาน",
        "province": "ร้อยเอ็ด",
        "zipcode": 45180,
        "district_code": 450404,
        "amphoe_code": false,
        "province_code": 45
    },
    {
        "district": "จังหาร",
        "amphoe": "จังหาร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450115,
        "amphoe_code": 4517,
        "province_code": 45
    },
    {
        "district": "ดงสิงห์",
        "amphoe": "จังหาร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450111,
        "amphoe_code": 4517,
        "province_code": 45
    },
    {
        "district": "ดินดำ",
        "amphoe": "จังหาร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450116,
        "amphoe_code": 4517,
        "province_code": 45
    },
    {
        "district": "ปาฝา",
        "amphoe": "จังหาร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450107,
        "amphoe_code": 4517,
        "province_code": 45
    },
    {
        "district": "ผักแว่น",
        "amphoe": "จังหาร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 451707,
        "amphoe_code": 4517,
        "province_code": 45
    },
    {
        "district": "ม่วงลาด",
        "amphoe": "จังหาร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450113,
        "amphoe_code": 4517,
        "province_code": 45
    },
    {
        "district": "ยางใหญ่",
        "amphoe": "จังหาร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 451706,
        "amphoe_code": 4517,
        "province_code": 45
    },
    {
        "district": "แสนชาติ",
        "amphoe": "จังหาร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 451708,
        "amphoe_code": 4517,
        "province_code": 45
    },
    {
        "district": "ทุ่งเขาหลวง",
        "amphoe": "ทุ่งเขาหลวง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450523,
        "amphoe_code": 4520,
        "province_code": 45
    },
    {
        "district": "บึงงาม",
        "amphoe": "ทุ่งเขาหลวง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450512,
        "amphoe_code": 4520,
        "province_code": 45
    },
    {
        "district": "มะบ้า",
        "amphoe": "ทุ่งเขาหลวง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450509,
        "amphoe_code": 4520,
        "province_code": 45
    },
    {
        "district": "เทอดไทย",
        "amphoe": "ทุ่งเขาหลวง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450516,
        "amphoe_code": 4520,
        "province_code": 45
    },
    {
        "district": "เหล่า",
        "amphoe": "ทุ่งเขาหลวง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450508,
        "amphoe_code": 4520,
        "province_code": 45
    },
    {
        "district": "ธงธานี",
        "amphoe": "ธวัชบุรี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450502,
        "amphoe_code": 4505,
        "province_code": 45
    },
    {
        "district": "ธวัชบุรี",
        "amphoe": "ธวัชบุรี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450504,
        "amphoe_code": 4505,
        "province_code": 45
    },
    {
        "district": "นิเวศน์",
        "amphoe": "ธวัชบุรี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450501,
        "amphoe_code": 4505,
        "province_code": 45
    },
    {
        "district": "บึงนคร",
        "amphoe": "ธวัชบุรี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450520,
        "amphoe_code": 4505,
        "province_code": 45
    },
    {
        "district": "มะอึ",
        "amphoe": "ธวัชบุรี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450507,
        "amphoe_code": 4505,
        "province_code": 45
    },
    {
        "district": "ราชธานี",
        "amphoe": "ธวัชบุรี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450522,
        "amphoe_code": 4505,
        "province_code": 45
    },
    {
        "district": "หนองพอก",
        "amphoe": "ธวัชบุรี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450524,
        "amphoe_code": 4505,
        "province_code": 45
    },
    {
        "district": "หนองไผ่",
        "amphoe": "ธวัชบุรี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450503,
        "amphoe_code": 4505,
        "province_code": 45
    },
    {
        "district": "อุ่มเม้า",
        "amphoe": "ธวัชบุรี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450506,
        "amphoe_code": 4505,
        "province_code": 45
    },
    {
        "district": "เขวาทุ่ง",
        "amphoe": "ธวัชบุรี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450510,
        "amphoe_code": 4505,
        "province_code": 45
    },
    {
        "district": "เมืองน้อย",
        "amphoe": "ธวัชบุรี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450517,
        "amphoe_code": 4505,
        "province_code": 45
    },
    {
        "district": "ไพศาล",
        "amphoe": "ธวัชบุรี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450515,
        "amphoe_code": 4505,
        "province_code": 45
    },
    {
        "district": "ขี้เหล็ก",
        "amphoe": "ปทุมรัตต์",
        "province": "ร้อยเอ็ด",
        "zipcode": 45190,
        "district_code": 450308,
        "amphoe_code": 4503,
        "province_code": 45
    },
    {
        "district": "ดอกล้ำ",
        "amphoe": "ปทุมรัตต์",
        "province": "ร้อยเอ็ด",
        "zipcode": 45190,
        "district_code": 450302,
        "amphoe_code": 4503,
        "province_code": 45
    },
    {
        "district": "บัวแดง",
        "amphoe": "ปทุมรัตต์",
        "province": "ร้อยเอ็ด",
        "zipcode": 45190,
        "district_code": 450301,
        "amphoe_code": 4503,
        "province_code": 45
    },
    {
        "district": "สระบัว",
        "amphoe": "ปทุมรัตต์",
        "province": "ร้อยเอ็ด",
        "zipcode": 45190,
        "district_code": 450306,
        "amphoe_code": 4503,
        "province_code": 45
    },
    {
        "district": "หนองแคน",
        "amphoe": "ปทุมรัตต์",
        "province": "ร้อยเอ็ด",
        "zipcode": 45190,
        "district_code": 450303,
        "amphoe_code": 4503,
        "province_code": 45
    },
    {
        "district": "โนนสง่า",
        "amphoe": "ปทุมรัตต์",
        "province": "ร้อยเอ็ด",
        "zipcode": 45190,
        "district_code": 450307,
        "amphoe_code": 4503,
        "province_code": 45
    },
    {
        "district": "โนนสวรรค์",
        "amphoe": "ปทุมรัตต์",
        "province": "ร้อยเอ็ด",
        "zipcode": 45190,
        "district_code": 450305,
        "amphoe_code": 4503,
        "province_code": 45
    },
    {
        "district": "โพนสูง",
        "amphoe": "ปทุมรัตต์",
        "province": "ร้อยเอ็ด",
        "zipcode": 45190,
        "district_code": 450304,
        "amphoe_code": 4503,
        "province_code": 45
    },
    {
        "district": "กุดน้ำใส",
        "amphoe": "พนมไพร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45140,
        "district_code": 450603,
        "amphoe_code": 4506,
        "province_code": 45
    },
    {
        "district": "คำไฮ",
        "amphoe": "พนมไพร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45140,
        "district_code": 450613,
        "amphoe_code": 4506,
        "province_code": 45
    },
    {
        "district": "ค้อใหญ่",
        "amphoe": "พนมไพร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45140,
        "district_code": 450615,
        "amphoe_code": 4506,
        "province_code": 45
    },
    {
        "district": "ชานุวรรณ",
        "amphoe": "พนมไพร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45140,
        "district_code": 450617,
        "amphoe_code": 4506,
        "province_code": 45
    },
    {
        "district": "นานวล",
        "amphoe": "พนมไพร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45140,
        "district_code": 450612,
        "amphoe_code": 4506,
        "province_code": 45
    },
    {
        "district": "พนมไพร",
        "amphoe": "พนมไพร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45140,
        "district_code": 450601,
        "amphoe_code": 4506,
        "province_code": 45
    },
    {
        "district": "วารีสวัสดิ์",
        "amphoe": "พนมไพร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45140,
        "district_code": 450606,
        "amphoe_code": 4506,
        "province_code": 45
    },
    {
        "district": "สระแก้ว",
        "amphoe": "พนมไพร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45140,
        "district_code": 450614,
        "amphoe_code": 4506,
        "province_code": 45
    },
    {
        "district": "หนองทัพไทย",
        "amphoe": "พนมไพร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45140,
        "district_code": 450604,
        "amphoe_code": 4506,
        "province_code": 45
    },
    {
        "district": "แสนสุข",
        "amphoe": "พนมไพร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45140,
        "district_code": 450602,
        "amphoe_code": 4506,
        "province_code": 45
    },
    {
        "district": "โคกสว่าง",
        "amphoe": "พนมไพร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45140,
        "district_code": 450607,
        "amphoe_code": 4506,
        "province_code": 45
    },
    {
        "district": "โพธิ์ชัย",
        "amphoe": "พนมไพร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45140,
        "district_code": 450611,
        "amphoe_code": 4506,
        "province_code": 45
    },
    {
        "district": "โพธิ์ใหญ่",
        "amphoe": "พนมไพร",
        "province": "ร้อยเอ็ด",
        "zipcode": 45140,
        "district_code": 450605,
        "amphoe_code": 4506,
        "province_code": 45
    },
    {
        "district": "บ้านบาก",
        "amphoe": "ศรีสมเด็จ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 451608,
        "amphoe_code": 4516,
        "province_code": 45
    },
    {
        "district": "ศรีสมเด็จ",
        "amphoe": "ศรีสมเด็จ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450119,
        "amphoe_code": 4516,
        "province_code": 45
    },
    {
        "district": "สวนจิก",
        "amphoe": "ศรีสมเด็จ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45280,
        "district_code": 450112,
        "amphoe_code": 4516,
        "province_code": 45
    },
    {
        "district": "หนองแวงควง",
        "amphoe": "ศรีสมเด็จ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 451607,
        "amphoe_code": 4516,
        "province_code": 45
    },
    {
        "district": "หนองใหญ่",
        "amphoe": "ศรีสมเด็จ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450121,
        "amphoe_code": 4516,
        "province_code": 45
    },
    {
        "district": "เมืองเปลือย",
        "amphoe": "ศรีสมเด็จ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450122,
        "amphoe_code": 4516,
        "province_code": 45
    },
    {
        "district": "โพธิ์ทอง",
        "amphoe": "ศรีสมเด็จ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450114,
        "amphoe_code": 4516,
        "province_code": 45
    },
    {
        "district": "โพธิ์สัย",
        "amphoe": "ศรีสมเด็จ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45280,
        "district_code": 451606,
        "amphoe_code": 4516,
        "province_code": 45
    },
    {
        "district": "จำปาขัน",
        "amphoe": "สุวรรณภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45130,
        "district_code": 451115,
        "amphoe_code": 4511,
        "province_code": 45
    },
    {
        "district": "ช้างเผือก",
        "amphoe": "สุวรรณภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45130,
        "district_code": 451112,
        "amphoe_code": 4511,
        "province_code": 45
    },
    {
        "district": "ดอกไม้",
        "amphoe": "สุวรรณภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45130,
        "district_code": 451102,
        "amphoe_code": 4511,
        "province_code": 45
    },
    {
        "district": "ทุ่งกุลา",
        "amphoe": "สุวรรณภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45130,
        "district_code": 451113,
        "amphoe_code": 4511,
        "province_code": 45
    },
    {
        "district": "ทุ่งศรีเมือง",
        "amphoe": "สุวรรณภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45130,
        "district_code": 451114,
        "amphoe_code": 4511,
        "province_code": 45
    },
    {
        "district": "ทุ่งหลวง",
        "amphoe": "สุวรรณภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45130,
        "district_code": 451108,
        "amphoe_code": 4511,
        "province_code": 45
    },
    {
        "district": "นาใหญ่",
        "amphoe": "สุวรรณภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45130,
        "district_code": 451103,
        "amphoe_code": 4511,
        "province_code": 45
    },
    {
        "district": "น้ำคำ",
        "amphoe": "สุวรรณภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45130,
        "district_code": 451110,
        "amphoe_code": 4511,
        "province_code": 45
    },
    {
        "district": "บ่อพันขัน",
        "amphoe": "สุวรรณภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45130,
        "district_code": 451107,
        "amphoe_code": 4511,
        "province_code": 45
    },
    {
        "district": "สระคู",
        "amphoe": "สุวรรณภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45130,
        "district_code": 451101,
        "amphoe_code": 4511,
        "province_code": 45
    },
    {
        "district": "หัวช้าง",
        "amphoe": "สุวรรณภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45130,
        "district_code": 450401,
        "amphoe_code": 4511,
        "province_code": 45
    },
    {
        "district": "หัวโทน",
        "amphoe": "สุวรรณภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45130,
        "district_code": 451106,
        "amphoe_code": 4511,
        "province_code": 45
    },
    {
        "district": "หินกอง",
        "amphoe": "สุวรรณภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45130,
        "district_code": 451104,
        "amphoe_code": 4511,
        "province_code": 45
    },
    {
        "district": "ห้วยหินลาด",
        "amphoe": "สุวรรณภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45130,
        "district_code": 451111,
        "amphoe_code": 4511,
        "province_code": 45
    },
    {
        "district": "เมืองทุ่ง",
        "amphoe": "สุวรรณภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45130,
        "district_code": 451105,
        "amphoe_code": 4511,
        "province_code": 45
    },
    {
        "district": "กกโพธิ์",
        "amphoe": "หนองพอก",
        "province": "ร้อยเอ็ด",
        "zipcode": 45210,
        "district_code": 450904,
        "amphoe_code": 4509,
        "province_code": 45
    },
    {
        "district": "ท่าสีดา",
        "amphoe": "หนองพอก",
        "province": "ร้อยเอ็ด",
        "zipcode": 45210,
        "district_code": 450909,
        "amphoe_code": 4509,
        "province_code": 45
    },
    {
        "district": "บึงงาม",
        "amphoe": "หนองพอก",
        "province": "ร้อยเอ็ด",
        "zipcode": 45210,
        "district_code": 450512,
        "amphoe_code": 4509,
        "province_code": 45
    },
    {
        "district": "ผาน้ำย้อย",
        "amphoe": "หนองพอก",
        "province": "ร้อยเอ็ด",
        "zipcode": 45210,
        "district_code": 450908,
        "amphoe_code": 4509,
        "province_code": 45
    },
    {
        "district": "ภูเขาทอง",
        "amphoe": "หนองพอก",
        "province": "ร้อยเอ็ด",
        "zipcode": 45210,
        "district_code": 450903,
        "amphoe_code": 4509,
        "province_code": 45
    },
    {
        "district": "รอบเมือง",
        "amphoe": "หนองพอก",
        "province": "ร้อยเอ็ด",
        "zipcode": 45210,
        "district_code": 450102,
        "amphoe_code": 4509,
        "province_code": 45
    },
    {
        "district": "หนองขุ่นใหญ่",
        "amphoe": "หนองพอก",
        "province": "ร้อยเอ็ด",
        "zipcode": 45210,
        "district_code": 450906,
        "amphoe_code": 4509,
        "province_code": 45
    },
    {
        "district": "หนองพอก",
        "amphoe": "หนองพอก",
        "province": "ร้อยเอ็ด",
        "zipcode": 45210,
        "district_code": 450524,
        "amphoe_code": 4509,
        "province_code": 45
    },
    {
        "district": "โคกสว่าง",
        "amphoe": "หนองพอก",
        "province": "ร้อยเอ็ด",
        "zipcode": 45210,
        "district_code": 450607,
        "amphoe_code": 4509,
        "province_code": 45
    },
    {
        "district": "ดูกอึ่ง",
        "amphoe": "หนองฮี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45140,
        "district_code": 450610,
        "amphoe_code": 4519,
        "province_code": 45
    },
    {
        "district": "สาวแห",
        "amphoe": "หนองฮี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45140,
        "district_code": 450616,
        "amphoe_code": 4519,
        "province_code": 45
    },
    {
        "district": "หนองฮี",
        "amphoe": "หนองฮี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45140,
        "district_code": 450608,
        "amphoe_code": 4519,
        "province_code": 45
    },
    {
        "district": "เด่นราษฎร์",
        "amphoe": "หนองฮี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45140,
        "district_code": 450609,
        "amphoe_code": 4519,
        "province_code": 45
    },
    {
        "district": "ขี้เหล็ก",
        "amphoe": "อาจสามารถ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45160,
        "district_code": 450308,
        "amphoe_code": 4514,
        "province_code": 45
    },
    {
        "district": "บ้านดู่",
        "amphoe": "อาจสามารถ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45160,
        "district_code": 451410,
        "amphoe_code": 4514,
        "province_code": 45
    },
    {
        "district": "บ้านแจ้ง",
        "amphoe": "อาจสามารถ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45160,
        "district_code": 451403,
        "amphoe_code": 4514,
        "province_code": 45
    },
    {
        "district": "หนองขาม",
        "amphoe": "อาจสามารถ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45160,
        "district_code": 451406,
        "amphoe_code": 4514,
        "province_code": 45
    },
    {
        "district": "หนองบัว",
        "amphoe": "อาจสามารถ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45160,
        "district_code": 451408,
        "amphoe_code": 4514,
        "province_code": 45
    },
    {
        "district": "หนองหมื่นถ่าน",
        "amphoe": "อาจสามารถ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45160,
        "district_code": 451405,
        "amphoe_code": 4514,
        "province_code": 45
    },
    {
        "district": "หน่อม",
        "amphoe": "อาจสามารถ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45160,
        "district_code": 451404,
        "amphoe_code": 4514,
        "province_code": 45
    },
    {
        "district": "อาจสามารถ",
        "amphoe": "อาจสามารถ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45160,
        "district_code": 451401,
        "amphoe_code": 4514,
        "province_code": 45
    },
    {
        "district": "โพนเมือง",
        "amphoe": "อาจสามารถ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45160,
        "district_code": 451402,
        "amphoe_code": 4514,
        "province_code": 45
    },
    {
        "district": "โหรา",
        "amphoe": "อาจสามารถ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45160,
        "district_code": 451407,
        "amphoe_code": 4514,
        "province_code": 45
    },
    {
        "district": "กำแพง",
        "amphoe": "เกษตรวิสัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45150,
        "district_code": 450208,
        "amphoe_code": 4502,
        "province_code": 45
    },
    {
        "district": "กู่กาสิงห์",
        "amphoe": "เกษตรวิสัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45150,
        "district_code": 450209,
        "amphoe_code": 4502,
        "province_code": 45
    },
    {
        "district": "ดงครั่งน้อย",
        "amphoe": "เกษตรวิสัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45150,
        "district_code": 450213,
        "amphoe_code": 4502,
        "province_code": 45
    },
    {
        "district": "ดงครั่งใหญ่",
        "amphoe": "เกษตรวิสัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45150,
        "district_code": 450205,
        "amphoe_code": 4502,
        "province_code": 45
    },
    {
        "district": "ทุ่งทอง",
        "amphoe": "เกษตรวิสัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45150,
        "district_code": 450212,
        "amphoe_code": 4502,
        "province_code": 45
    },
    {
        "district": "น้ำอ้อม",
        "amphoe": "เกษตรวิสัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45150,
        "district_code": 450210,
        "amphoe_code": 4502,
        "province_code": 45
    },
    {
        "district": "บ้านฝาง",
        "amphoe": "เกษตรวิสัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45150,
        "district_code": 450206,
        "amphoe_code": 4502,
        "province_code": 45
    },
    {
        "district": "สิงห์โคก",
        "amphoe": "เกษตรวิสัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45150,
        "district_code": 450204,
        "amphoe_code": 4502,
        "province_code": 45
    },
    {
        "district": "หนองแวง",
        "amphoe": "เกษตรวิสัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45150,
        "district_code": 450118,
        "amphoe_code": 4502,
        "province_code": 45
    },
    {
        "district": "เกษตรวิสัย",
        "amphoe": "เกษตรวิสัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45150,
        "district_code": 450201,
        "amphoe_code": 4502,
        "province_code": 45
    },
    {
        "district": "เมืองบัว",
        "amphoe": "เกษตรวิสัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45150,
        "district_code": 450202,
        "amphoe_code": 4502,
        "province_code": 45
    },
    {
        "district": "เหล่าหลวง",
        "amphoe": "เกษตรวิสัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45150,
        "district_code": 450203,
        "amphoe_code": 4502,
        "province_code": 45
    },
    {
        "district": "โนนสว่าง",
        "amphoe": "เกษตรวิสัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45150,
        "district_code": 450211,
        "amphoe_code": 4502,
        "province_code": 45
    },
    {
        "district": "บ้านเขือง",
        "amphoe": "เชียงขวัญ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450513,
        "amphoe_code": 4518,
        "province_code": 45
    },
    {
        "district": "พระธาตุ",
        "amphoe": "เชียงขวัญ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450511,
        "amphoe_code": 4518,
        "province_code": 45
    },
    {
        "district": "พระเจ้า",
        "amphoe": "เชียงขวัญ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450514,
        "amphoe_code": 4518,
        "province_code": 45
    },
    {
        "district": "พลับพลา",
        "amphoe": "เชียงขวัญ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450521,
        "amphoe_code": 4518,
        "province_code": 45
    },
    {
        "district": "หมูม้น",
        "amphoe": "เชียงขวัญ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45170,
        "district_code": 450505,
        "amphoe_code": 4518,
        "province_code": 45
    },
    {
        "district": "เชียงขวัญ",
        "amphoe": "เชียงขวัญ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450519,
        "amphoe_code": 4518,
        "province_code": 45
    },
    {
        "district": "ชมสะอาด",
        "amphoe": "เมยวดี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45250,
        "district_code": 451504,
        "amphoe_code": 4515,
        "province_code": 45
    },
    {
        "district": "ชุมพร",
        "amphoe": "เมยวดี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45250,
        "district_code": 450794,
        "amphoe_code": 4515,
        "province_code": 45
    },
    {
        "district": "บุ่งเลิศ",
        "amphoe": "เมยวดี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45250,
        "district_code": 451503,
        "amphoe_code": 4515,
        "province_code": 45
    },
    {
        "district": "เมยวดี",
        "amphoe": "เมยวดี",
        "province": "ร้อยเอ็ด",
        "zipcode": 45250,
        "district_code": 450795,
        "amphoe_code": 4515,
        "province_code": 45
    },
    {
        "district": "ขอนแก่น",
        "amphoe": "เมืองร้อยเอ็ด",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450104,
        "amphoe_code": 4501,
        "province_code": 45
    },
    {
        "district": "ดงลาน",
        "amphoe": "เมืองร้อยเอ็ด",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450120,
        "amphoe_code": 4501,
        "province_code": 45
    },
    {
        "district": "นาโพธิ์",
        "amphoe": "เมืองร้อยเอ็ด",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450105,
        "amphoe_code": 4501,
        "province_code": 45
    },
    {
        "district": "ปอภาร",
        "amphoe": "เมืองร้อยเอ็ด",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": false,
        "amphoe_code": 4501,
        "province_code": 45
    },
    {
        "district": "รอบเมือง",
        "amphoe": "เมืองร้อยเอ็ด",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450102,
        "amphoe_code": 4501,
        "province_code": 45
    },
    {
        "district": "สะอาดสมบูรณ์",
        "amphoe": "เมืองร้อยเอ็ด",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450106,
        "amphoe_code": 4501,
        "province_code": 45
    },
    {
        "district": "สีแก้ว",
        "amphoe": "เมืองร้อยเอ็ด",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450108,
        "amphoe_code": 4501,
        "province_code": 45
    },
    {
        "district": "หนองแก้ว",
        "amphoe": "เมืองร้อยเอ็ด",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450117,
        "amphoe_code": 4501,
        "province_code": 45
    },
    {
        "district": "หนองแวง",
        "amphoe": "เมืองร้อยเอ็ด",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450118,
        "amphoe_code": 4501,
        "province_code": 45
    },
    {
        "district": "เมืองทอง",
        "amphoe": "เมืองร้อยเอ็ด",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450125,
        "amphoe_code": 4501,
        "province_code": 45
    },
    {
        "district": "เหนือเมือง",
        "amphoe": "เมืองร้อยเอ็ด",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450103,
        "amphoe_code": 4501,
        "province_code": 45
    },
    {
        "district": "แคนใหญ่",
        "amphoe": "เมืองร้อยเอ็ด",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450123,
        "amphoe_code": 4501,
        "province_code": 45
    },
    {
        "district": "โนนตาล",
        "amphoe": "เมืองร้อยเอ็ด",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450124,
        "amphoe_code": 4501,
        "province_code": 45
    },
    {
        "district": "โนนรัง",
        "amphoe": "เมืองร้อยเอ็ด",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450110,
        "amphoe_code": 4501,
        "province_code": 45
    },
    {
        "district": "ในเมือง",
        "amphoe": "เมืองร้อยเอ็ด",
        "province": "ร้อยเอ็ด",
        "zipcode": 45000,
        "district_code": 450101,
        "amphoe_code": 4501,
        "province_code": 45
    },
    {
        "district": "กกกุง",
        "amphoe": "เมืองสรวง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45220,
        "district_code": 451204,
        "amphoe_code": 4512,
        "province_code": 45
    },
    {
        "district": "คูเมือง",
        "amphoe": "เมืองสรวง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45220,
        "district_code": 451203,
        "amphoe_code": 4512,
        "province_code": 45
    },
    {
        "district": "หนองผือ",
        "amphoe": "เมืองสรวง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45220,
        "district_code": 450402,
        "amphoe_code": 4512,
        "province_code": 45
    },
    {
        "district": "หนองหิน",
        "amphoe": "เมืองสรวง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45220,
        "district_code": 451202,
        "amphoe_code": 4512,
        "province_code": 45
    },
    {
        "district": "เมืองสรวง",
        "amphoe": "เมืองสรวง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45220,
        "district_code": 451205,
        "amphoe_code": 4512,
        "province_code": 45
    },
    {
        "district": "กลาง",
        "amphoe": "เสลภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45120,
        "district_code": 451001,
        "amphoe_code": 4510,
        "province_code": 45
    },
    {
        "district": "ขวัญเมือง",
        "amphoe": "เสลภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45120,
        "district_code": 451017,
        "amphoe_code": 4510,
        "province_code": 45
    },
    {
        "district": "ขวาว",
        "amphoe": "เสลภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45120,
        "district_code": 451008,
        "amphoe_code": 4510,
        "province_code": 45
    },
    {
        "district": "ท่าม่วง",
        "amphoe": "เสลภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45120,
        "district_code": 451007,
        "amphoe_code": 4510,
        "province_code": 45
    },
    {
        "district": "นางาม",
        "amphoe": "เสลภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45120,
        "district_code": 451002,
        "amphoe_code": 4510,
        "province_code": 45
    },
    {
        "district": "นาเมือง",
        "amphoe": "เสลภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45120,
        "district_code": 451005,
        "amphoe_code": 4510,
        "province_code": 45
    },
    {
        "district": "นาเลิง",
        "amphoe": "เสลภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45120,
        "district_code": 451012,
        "amphoe_code": 4510,
        "province_code": 45
    },
    {
        "district": "นาแซง",
        "amphoe": "เสลภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45120,
        "district_code": 451004,
        "amphoe_code": 4510,
        "province_code": 45
    },
    {
        "district": "บึงเกลือ",
        "amphoe": "เสลภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45120,
        "district_code": 451018,
        "amphoe_code": 4510,
        "province_code": 45
    },
    {
        "district": "พรสวรรค์",
        "amphoe": "เสลภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45120,
        "district_code": 451016,
        "amphoe_code": 4510,
        "province_code": 45
    },
    {
        "district": "ภูเงิน",
        "amphoe": "เสลภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45120,
        "district_code": 451010,
        "amphoe_code": 4510,
        "province_code": 45
    },
    {
        "district": "วังหลวง",
        "amphoe": "เสลภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45120,
        "district_code": 451006,
        "amphoe_code": 4510,
        "province_code": 45
    },
    {
        "district": "ศรีวิลัย",
        "amphoe": "เสลภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45120,
        "district_code": 451014,
        "amphoe_code": 4510,
        "province_code": 45
    },
    {
        "district": "หนองหลวง",
        "amphoe": "เสลภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45120,
        "district_code": 451015,
        "amphoe_code": 4510,
        "province_code": 45
    },
    {
        "district": "เกาะแก้ว",
        "amphoe": "เสลภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45120,
        "district_code": 451011,
        "amphoe_code": 4510,
        "province_code": 45
    },
    {
        "district": "เมืองไพร",
        "amphoe": "เสลภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45120,
        "district_code": 451003,
        "amphoe_code": 4510,
        "province_code": 45
    },
    {
        "district": "เหล่าน้อย",
        "amphoe": "เสลภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45120,
        "district_code": 451013,
        "amphoe_code": 4510,
        "province_code": 45
    },
    {
        "district": "โพธิ์ทอง",
        "amphoe": "เสลภูมิ",
        "province": "ร้อยเอ็ด",
        "zipcode": 45120,
        "district_code": 450114,
        "amphoe_code": 4510,
        "province_code": 45
    },
    {
        "district": "ขามเปี้ย",
        "amphoe": "โพธิ์ชัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45230,
        "district_code": 450801,
        "amphoe_code": 4508,
        "province_code": 45
    },
    {
        "district": "คำพอุง",
        "amphoe": "โพธิ์ชัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45230,
        "district_code": 450806,
        "amphoe_code": 4508,
        "province_code": 45
    },
    {
        "district": "ดอนโอง",
        "amphoe": "โพธิ์ชัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45230,
        "district_code": 450808,
        "amphoe_code": 4508,
        "province_code": 45
    },
    {
        "district": "บัวคำ",
        "amphoe": "โพธิ์ชัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45230,
        "district_code": 450803,
        "amphoe_code": 4508,
        "province_code": 45
    },
    {
        "district": "สะอาด",
        "amphoe": "โพธิ์ชัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45230,
        "district_code": 450805,
        "amphoe_code": 4508,
        "province_code": 45
    },
    {
        "district": "หนองตาไก้",
        "amphoe": "โพธิ์ชัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45230,
        "district_code": 450807,
        "amphoe_code": 4508,
        "province_code": 45
    },
    {
        "district": "อัคคะคำ",
        "amphoe": "โพธิ์ชัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45230,
        "district_code": 450797,
        "amphoe_code": 4508,
        "province_code": 45
    },
    {
        "district": "เชียงใหม่",
        "amphoe": "โพธิ์ชัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45230,
        "district_code": 450798,
        "amphoe_code": 4508,
        "province_code": 45
    },
    {
        "district": "โพธิ์ศรี",
        "amphoe": "โพธิ์ชัย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45230,
        "district_code": 450809,
        "amphoe_code": 4508,
        "province_code": 45
    },
    {
        "district": "ท่าหาดยาว",
        "amphoe": "โพนทราย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45240,
        "district_code": 451305,
        "amphoe_code": 4513,
        "province_code": 45
    },
    {
        "district": "ยางคำ",
        "amphoe": "โพนทราย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45240,
        "district_code": 451304,
        "amphoe_code": 4513,
        "province_code": 45
    },
    {
        "district": "ศรีสว่าง",
        "amphoe": "โพนทราย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45240,
        "district_code": 451303,
        "amphoe_code": 4513,
        "province_code": 45
    },
    {
        "district": "สามขา",
        "amphoe": "โพนทราย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45240,
        "district_code": 451302,
        "amphoe_code": 4513,
        "province_code": 45
    },
    {
        "district": "โพนทราย",
        "amphoe": "โพนทราย",
        "province": "ร้อยเอ็ด",
        "zipcode": 45240,
        "district_code": 451301,
        "amphoe_code": 4513,
        "province_code": 45
    },
    {
        "district": "คำนาดี",
        "amphoe": "โพนทอง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45110,
        "district_code": 450710,
        "amphoe_code": 4507,
        "province_code": 45
    },
    {
        "district": "นาอุดม",
        "amphoe": "โพนทอง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45110,
        "district_code": 450703,
        "amphoe_code": 4507,
        "province_code": 45
    },
    {
        "district": "พรมสวรรค์",
        "amphoe": "โพนทอง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45110,
        "district_code": 450711,
        "amphoe_code": 4507,
        "province_code": 45
    },
    {
        "district": "วังสามัคคี",
        "amphoe": "โพนทอง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45110,
        "district_code": 450713,
        "amphoe_code": 4507,
        "province_code": 45
    },
    {
        "district": "สระนกแก้ว",
        "amphoe": "โพนทอง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45110,
        "district_code": 450712,
        "amphoe_code": 4507,
        "province_code": 45
    },
    {
        "district": "สว่าง",
        "amphoe": "โพนทอง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45110,
        "district_code": 450704,
        "amphoe_code": 4507,
        "province_code": 45
    },
    {
        "district": "หนองใหญ่",
        "amphoe": "โพนทอง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45110,
        "district_code": 450121,
        "amphoe_code": 4507,
        "province_code": 45
    },
    {
        "district": "อุ่มเม่า",
        "amphoe": "โพนทอง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45110,
        "district_code": 450709,
        "amphoe_code": 4507,
        "province_code": 45
    },
    {
        "district": "แวง",
        "amphoe": "โพนทอง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45110,
        "district_code": 450701,
        "amphoe_code": 4507,
        "province_code": 45
    },
    {
        "district": "โคกกกม่วง",
        "amphoe": "โพนทอง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45110,
        "district_code": 450702,
        "amphoe_code": 4507,
        "province_code": 45
    },
    {
        "district": "โคกสูง",
        "amphoe": "โพนทอง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45110,
        "district_code": 450714,
        "amphoe_code": 4507,
        "province_code": 45
    },
    {
        "district": "โนนชัยศรี",
        "amphoe": "โพนทอง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45110,
        "district_code": 450707,
        "amphoe_code": 4507,
        "province_code": 45
    },
    {
        "district": "โพธิ์ทอง",
        "amphoe": "โพนทอง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45110,
        "district_code": 450114,
        "amphoe_code": 4507,
        "province_code": 45
    },
    {
        "district": "โพธิ์ศรีสว่าง",
        "amphoe": "โพนทอง",
        "province": "ร้อยเอ็ด",
        "zipcode": 45110,
        "district_code": 450708,
        "amphoe_code": 4507,
        "province_code": 45
    },
    {
        "district": "ชัยนารายณ์",
        "amphoe": "ชัยบาดาล",
        "province": "ลพบุรี",
        "zipcode": 15130,
        "district_code": 160402,
        "amphoe_code": 1604,
        "province_code": 16
    },
    {
        "district": "ชัยบาดาล",
        "amphoe": "ชัยบาดาล",
        "province": "ลพบุรี",
        "zipcode": 15230,
        "district_code": 160418,
        "amphoe_code": 1604,
        "province_code": 16
    },
    {
        "district": "ซับตะเคียน",
        "amphoe": "ชัยบาดาล",
        "province": "ลพบุรี",
        "zipcode": 15130,
        "district_code": 160409,
        "amphoe_code": 1604,
        "province_code": 16
    },
    {
        "district": "ท่าดินดำ",
        "amphoe": "ชัยบาดาล",
        "province": "ลพบุรี",
        "zipcode": 15130,
        "district_code": 160407,
        "amphoe_code": 1604,
        "province_code": 16
    },
    {
        "district": "ท่ามะนาว",
        "amphoe": "ชัยบาดาล",
        "province": "ลพบุรี",
        "zipcode": 15130,
        "district_code": 160414,
        "amphoe_code": 1604,
        "province_code": 16
    },
    {
        "district": "นาโสม",
        "amphoe": "ชัยบาดาล",
        "province": "ลพบุรี",
        "zipcode": 15190,
        "district_code": 160410,
        "amphoe_code": 1604,
        "province_code": 16
    },
    {
        "district": "นิคมลำนารายณ์",
        "amphoe": "ชัยบาดาล",
        "province": "ลพบุรี",
        "zipcode": 15130,
        "district_code": 160417,
        "amphoe_code": 1604,
        "province_code": 16
    },
    {
        "district": "บัวชุม",
        "amphoe": "ชัยบาดาล",
        "province": "ลพบุรี",
        "zipcode": 15130,
        "district_code": 160406,
        "amphoe_code": 1604,
        "province_code": 16
    },
    {
        "district": "บ้านใหม่สามัคคี",
        "amphoe": "ชัยบาดาล",
        "province": "ลพบุรี",
        "zipcode": 15130,
        "district_code": 160419,
        "amphoe_code": 1604,
        "province_code": 16
    },
    {
        "district": "มะกอกหวาน",
        "amphoe": "ชัยบาดาล",
        "province": "ลพบุรี",
        "zipcode": 15230,
        "district_code": 160408,
        "amphoe_code": 1604,
        "province_code": 16
    },
    {
        "district": "ม่วงค่อม",
        "amphoe": "ชัยบาดาล",
        "province": "ลพบุรี",
        "zipcode": 15230,
        "district_code": 160405,
        "amphoe_code": 1604,
        "province_code": 16
    },
    {
        "district": "ลำนารายณ์",
        "amphoe": "ชัยบาดาล",
        "province": "ลพบุรี",
        "zipcode": 15130,
        "district_code": 160401,
        "amphoe_code": 1604,
        "province_code": 16
    },
    {
        "district": "ศิลาทิพย์",
        "amphoe": "ชัยบาดาล",
        "province": "ลพบุรี",
        "zipcode": 15130,
        "district_code": 160403,
        "amphoe_code": 1604,
        "province_code": 16
    },
    {
        "district": "หนองยายโต๊ะ",
        "amphoe": "ชัยบาดาล",
        "province": "ลพบุรี",
        "zipcode": 15130,
        "district_code": 160411,
        "amphoe_code": 1604,
        "province_code": 16
    },
    {
        "district": "ห้วยหิน",
        "amphoe": "ชัยบาดาล",
        "province": "ลพบุรี",
        "zipcode": 15130,
        "district_code": 160404,
        "amphoe_code": 1604,
        "province_code": 16
    },
    {
        "district": "เกาะรัง",
        "amphoe": "ชัยบาดาล",
        "province": "ลพบุรี",
        "zipcode": 15130,
        "district_code": 160412,
        "amphoe_code": 1604,
        "province_code": 16
    },
    {
        "district": "เขาแหลม",
        "amphoe": "ชัยบาดาล",
        "province": "ลพบุรี",
        "zipcode": 15130,
        "district_code": 160422,
        "amphoe_code": 1604,
        "province_code": 16
    },
    {
        "district": "ท่าวุ้ง",
        "amphoe": "ท่าวุ้ง",
        "province": "ลพบุรี",
        "zipcode": 15150,
        "district_code": 160501,
        "amphoe_code": 1605,
        "province_code": 16
    },
    {
        "district": "บางคู้",
        "amphoe": "ท่าวุ้ง",
        "province": "ลพบุรี",
        "zipcode": 15150,
        "district_code": 160502,
        "amphoe_code": 1605,
        "province_code": 16
    },
    {
        "district": "บางงา",
        "amphoe": "ท่าวุ้ง",
        "province": "ลพบุรี",
        "zipcode": 15150,
        "district_code": 160505,
        "amphoe_code": 1605,
        "province_code": 16
    },
    {
        "district": "บางลี่",
        "amphoe": "ท่าวุ้ง",
        "province": "ลพบุรี",
        "zipcode": 15150,
        "district_code": 160504,
        "amphoe_code": 1605,
        "province_code": 16
    },
    {
        "district": "บ้านเบิก",
        "amphoe": "ท่าวุ้ง",
        "province": "ลพบุรี",
        "zipcode": 15150,
        "district_code": 160510,
        "amphoe_code": 1605,
        "province_code": 16
    },
    {
        "district": "มุจลินท์",
        "amphoe": "ท่าวุ้ง",
        "province": "ลพบุรี",
        "zipcode": 15150,
        "district_code": 160511,
        "amphoe_code": 1605,
        "province_code": 16
    },
    {
        "district": "ลาดสาลี่",
        "amphoe": "ท่าวุ้ง",
        "province": "ลพบุรี",
        "zipcode": 15150,
        "district_code": 160509,
        "amphoe_code": 1605,
        "province_code": 16
    },
    {
        "district": "หัวสำโรง",
        "amphoe": "ท่าวุ้ง",
        "province": "ลพบุรี",
        "zipcode": 15150,
        "district_code": 160508,
        "amphoe_code": 1605,
        "province_code": 16
    },
    {
        "district": "เขาสมอคอน",
        "amphoe": "ท่าวุ้ง",
        "province": "ลพบุรี",
        "zipcode": 15180,
        "district_code": 160507,
        "amphoe_code": 1605,
        "province_code": 16
    },
    {
        "district": "โคกสลุด",
        "amphoe": "ท่าวุ้ง",
        "province": "ลพบุรี",
        "zipcode": 15150,
        "district_code": 160506,
        "amphoe_code": 1605,
        "province_code": 16
    },
    {
        "district": "โพตลาดแก้ว",
        "amphoe": "ท่าวุ้ง",
        "province": "ลพบุรี",
        "zipcode": 15150,
        "district_code": 160503,
        "amphoe_code": 1605,
        "province_code": 16
    },
    {
        "district": "ซับจำปา",
        "amphoe": "ท่าหลวง",
        "province": "ลพบุรี",
        "zipcode": 15230,
        "district_code": 160497,
        "amphoe_code": 1607,
        "province_code": 16
    },
    {
        "district": "ทะเลวังวัด",
        "amphoe": "ท่าหลวง",
        "province": "ลพบุรี",
        "zipcode": 15230,
        "district_code": 160705,
        "amphoe_code": 1607,
        "province_code": 16
    },
    {
        "district": "ท่าหลวง",
        "amphoe": "ท่าหลวง",
        "province": "ลพบุรี",
        "zipcode": 15230,
        "district_code": 160499,
        "amphoe_code": 1607,
        "province_code": 16
    },
    {
        "district": "หนองผักแว่น",
        "amphoe": "ท่าหลวง",
        "province": "ลพบุรี",
        "zipcode": 15230,
        "district_code": 160496,
        "amphoe_code": 1607,
        "province_code": 16
    },
    {
        "district": "หัวลำ",
        "amphoe": "ท่าหลวง",
        "province": "ลพบุรี",
        "zipcode": 15230,
        "district_code": 160706,
        "amphoe_code": 1607,
        "province_code": 16
    },
    {
        "district": "แก่งผักกูด",
        "amphoe": "ท่าหลวง",
        "province": "ลพบุรี",
        "zipcode": 15230,
        "district_code": 160498,
        "amphoe_code": 1607,
        "province_code": 16
    },
    {
        "district": "ชอนม่วง",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160615,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "ดงพลับ",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160604,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "ดอนดึง",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160614,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "บางกะพี้",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160610,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "บางขาม",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15180,
        "district_code": 160613,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "บางพึ่ง",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160608,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "บ้านกล้วย",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160603,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "บ้านชี",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15180,
        "district_code": 160605,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "บ้านทราย",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160602,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "บ้านหมี่",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160619,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "พุคา",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160606,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "มหาสอน",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160618,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "สนามแจง",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160622,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "สายห้วยแก้ว",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160617,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "หนองกระเบียน",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160616,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "หนองทรายขาว",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160609,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "หนองเต่า",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160611,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "หนองเมือง",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160621,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "หินปัก",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160607,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "เชียงงา",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160620,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "โพนทอง",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160612,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "ไผ่ใหญ่",
        "amphoe": "บ้านหมี่",
        "province": "ลพบุรี",
        "zipcode": 15110,
        "district_code": 160601,
        "amphoe_code": 1606,
        "province_code": 16
    },
    {
        "district": "ชอนน้อย",
        "amphoe": "พัฒนานิคม",
        "province": "ลพบุรี",
        "zipcode": 15140,
        "district_code": 160206,
        "amphoe_code": 1602,
        "province_code": 16
    },
    {
        "district": "ช่องสาริกา",
        "amphoe": "พัฒนานิคม",
        "province": "ลพบุรี",
        "zipcode": 15220,
        "district_code": 160202,
        "amphoe_code": 1602,
        "province_code": 16
    },
    {
        "district": "ดีลัง",
        "amphoe": "พัฒนานิคม",
        "province": "ลพบุรี",
        "zipcode": 15220,
        "district_code": 160204,
        "amphoe_code": 1602,
        "province_code": 16
    },
    {
        "district": "น้ำสุด",
        "amphoe": "พัฒนานิคม",
        "province": "ลพบุรี",
        "zipcode": 15140,
        "district_code": 160209,
        "amphoe_code": 1602,
        "province_code": 16
    },
    {
        "district": "พัฒนานิคม",
        "amphoe": "พัฒนานิคม",
        "province": "ลพบุรี",
        "zipcode": 15140,
        "district_code": 160201,
        "amphoe_code": 1602,
        "province_code": 16
    },
    {
        "district": "มะนาวหวาน",
        "amphoe": "พัฒนานิคม",
        "province": "ลพบุรี",
        "zipcode": 15140,
        "district_code": 160203,
        "amphoe_code": 1602,
        "province_code": 16
    },
    {
        "district": "หนองบัว",
        "amphoe": "พัฒนานิคม",
        "province": "ลพบุรี",
        "zipcode": 15140,
        "district_code": 160207,
        "amphoe_code": 1602,
        "province_code": 16
    },
    {
        "district": "ห้วยขุนราม",
        "amphoe": "พัฒนานิคม",
        "province": "ลพบุรี",
        "zipcode": 18220,
        "district_code": 160208,
        "amphoe_code": 1602,
        "province_code": 16
    },
    {
        "district": "โคกสลุง",
        "amphoe": "พัฒนานิคม",
        "province": "ลพบุรี",
        "zipcode": 15140,
        "district_code": 160205,
        "amphoe_code": 1602,
        "province_code": 16
    },
    {
        "district": "กุดตาเพชร",
        "amphoe": "ลำสนธิ",
        "province": "ลพบุรี",
        "zipcode": 15190,
        "district_code": 160415,
        "amphoe_code": 1610,
        "province_code": 16
    },
    {
        "district": "ซับสมบูรณ์",
        "amphoe": "ลำสนธิ",
        "province": "ลพบุรี",
        "zipcode": 15190,
        "district_code": 160420,
        "amphoe_code": 1610,
        "province_code": 16
    },
    {
        "district": "ลำสนธิ",
        "amphoe": "ลำสนธิ",
        "province": "ลพบุรี",
        "zipcode": 15190,
        "district_code": 160416,
        "amphoe_code": 1610,
        "province_code": 16
    },
    {
        "district": "หนองรี",
        "amphoe": "ลำสนธิ",
        "province": "ลพบุรี",
        "zipcode": 15190,
        "district_code": 160413,
        "amphoe_code": 1610,
        "province_code": 16
    },
    {
        "district": "เขาน้อย",
        "amphoe": "ลำสนธิ",
        "province": "ลพบุรี",
        "zipcode": 15130,
        "district_code": 161006,
        "amphoe_code": 1610,
        "province_code": 16
    },
    {
        "district": "เขารวก",
        "amphoe": "ลำสนธิ",
        "province": "ลพบุรี",
        "zipcode": 15190,
        "district_code": 160421,
        "amphoe_code": 1610,
        "province_code": 16
    },
    {
        "district": "ทุ่งท่าช้าง",
        "amphoe": "สระโบสถ์",
        "province": "ลพบุรี",
        "zipcode": 15240,
        "district_code": 160397,
        "amphoe_code": 1608,
        "province_code": 16
    },
    {
        "district": "นิยมชัย",
        "amphoe": "สระโบสถ์",
        "province": "ลพบุรี",
        "zipcode": 15240,
        "district_code": 160805,
        "amphoe_code": 1608,
        "province_code": 16
    },
    {
        "district": "มหาโพธิ",
        "amphoe": "สระโบสถ์",
        "province": "ลพบุรี",
        "zipcode": 15240,
        "district_code": 160802,
        "amphoe_code": 1608,
        "province_code": 16
    },
    {
        "district": "สระโบสถ์",
        "amphoe": "สระโบสถ์",
        "province": "ลพบุรี",
        "zipcode": 15240,
        "district_code": 160399,
        "amphoe_code": 1608,
        "province_code": 16
    },
    {
        "district": "ห้วยใหญ่",
        "amphoe": "สระโบสถ์",
        "province": "ลพบุรี",
        "zipcode": 15240,
        "district_code": 160804,
        "amphoe_code": 1608,
        "province_code": 16
    },
    {
        "district": "ชอนสมบูรณ์",
        "amphoe": "หนองม่วง",
        "province": "ลพบุรี",
        "zipcode": 15170,
        "district_code": 160315,
        "amphoe_code": 1611,
        "province_code": 16
    },
    {
        "district": "ชอนสารเดช",
        "amphoe": "หนองม่วง",
        "province": "ลพบุรี",
        "zipcode": 15170,
        "district_code": 160311,
        "amphoe_code": 1611,
        "province_code": 16
    },
    {
        "district": "ดงดินแดง",
        "amphoe": "หนองม่วง",
        "province": "ลพบุรี",
        "zipcode": 15170,
        "district_code": 160319,
        "amphoe_code": 1611,
        "province_code": 16
    },
    {
        "district": "บ่อทอง",
        "amphoe": "หนองม่วง",
        "province": "ลพบุรี",
        "zipcode": 15170,
        "district_code": 160313,
        "amphoe_code": 1611,
        "province_code": 16
    },
    {
        "district": "ยางโทน",
        "amphoe": "หนองม่วง",
        "province": "ลพบุรี",
        "zipcode": 15170,
        "district_code": 160314,
        "amphoe_code": 1611,
        "province_code": 16
    },
    {
        "district": "หนองม่วง",
        "amphoe": "หนองม่วง",
        "province": "ลพบุรี",
        "zipcode": 15170,
        "district_code": 160312,
        "amphoe_code": 1611,
        "province_code": 16
    },
    {
        "district": "กกโก",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160103,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "งิ้วราย",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160110,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "ดอนโพธิ์",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160111,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "ตะลุง",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160112,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "ถนนใหญ่",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160125,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "ทะเลชุบศร",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160101,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "ท่าศาลา",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160115,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "ท่าหิน",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160102,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "ท่าแค",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160114,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "ท้ายตลาด",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160119,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "นิคมสร้างตนเอง",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160116,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "บางขันหมาก",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160117,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "บ้านข่อย",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160118,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "ป่าตาล",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160120,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "พรหมมาสตร์",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160121,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "สี่คลอง",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160124,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "เขาพระงาม",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15160,
        "district_code": 160105,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "เขาสามยอด",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160106,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "โก่งธนู",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 13240,
        "district_code": 160104,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "โคกกะเทียม",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15160,
        "district_code": 160107,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "โคกตูม",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15210,
        "district_code": 160109,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "โคกลำพาน",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160108,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "โพธิ์ตรุ",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160123,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "โพธิ์เก้าต้น",
        "amphoe": "เมืองลพบุรี",
        "province": "ลพบุรี",
        "zipcode": 15000,
        "district_code": 160122,
        "amphoe_code": 1601,
        "province_code": 16
    },
    {
        "district": "คลองเกตุ",
        "amphoe": "โคกสำโรง",
        "province": "ลพบุรี",
        "zipcode": 15120,
        "district_code": 160306,
        "amphoe_code": 1603,
        "province_code": 16
    },
    {
        "district": "ดงมะรุม",
        "amphoe": "โคกสำโรง",
        "province": "ลพบุรี",
        "zipcode": 15120,
        "district_code": 160310,
        "amphoe_code": 1603,
        "province_code": 16
    },
    {
        "district": "ถลุงเหล็ก",
        "amphoe": "โคกสำโรง",
        "province": "ลพบุรี",
        "zipcode": 15120,
        "district_code": 160303,
        "amphoe_code": 1603,
        "province_code": 16
    },
    {
        "district": "วังขอนขว้าง",
        "amphoe": "โคกสำโรง",
        "province": "ลพบุรี",
        "zipcode": 15120,
        "district_code": 160318,
        "amphoe_code": 1603,
        "province_code": 16
    },
    {
        "district": "วังจั่น",
        "amphoe": "โคกสำโรง",
        "province": "ลพบุรี",
        "zipcode": 15120,
        "district_code": 160320,
        "amphoe_code": 1603,
        "province_code": 16
    },
    {
        "district": "วังเพลิง",
        "amphoe": "โคกสำโรง",
        "province": "ลพบุรี",
        "zipcode": 15120,
        "district_code": 160309,
        "amphoe_code": 1603,
        "province_code": 16
    },
    {
        "district": "สะแกราบ",
        "amphoe": "โคกสำโรง",
        "province": "ลพบุรี",
        "zipcode": 15120,
        "district_code": 160307,
        "amphoe_code": 1603,
        "province_code": 16
    },
    {
        "district": "หนองแขม",
        "amphoe": "โคกสำโรง",
        "province": "ลพบุรี",
        "zipcode": 15120,
        "district_code": 160322,
        "amphoe_code": 1603,
        "province_code": 16
    },
    {
        "district": "หลุมข้าว",
        "amphoe": "โคกสำโรง",
        "province": "ลพบุรี",
        "zipcode": 15120,
        "district_code": 160304,
        "amphoe_code": 1603,
        "province_code": 16
    },
    {
        "district": "ห้วยโป่ง",
        "amphoe": "โคกสำโรง",
        "province": "ลพบุรี",
        "zipcode": 15120,
        "district_code": 160305,
        "amphoe_code": 1603,
        "province_code": 16
    },
    {
        "district": "เกาะแก้ว",
        "amphoe": "โคกสำโรง",
        "province": "ลพบุรี",
        "zipcode": 15120,
        "district_code": 160302,
        "amphoe_code": 1603,
        "province_code": 16
    },
    {
        "district": "เพนียด",
        "amphoe": "โคกสำโรง",
        "province": "ลพบุรี",
        "zipcode": 15120,
        "district_code": 160308,
        "amphoe_code": 1603,
        "province_code": 16
    },
    {
        "district": "โคกสำโรง",
        "amphoe": "โคกสำโรง",
        "province": "ลพบุรี",
        "zipcode": 15120,
        "district_code": 160301,
        "amphoe_code": 1603,
        "province_code": 16
    },
    {
        "district": "ยางราก",
        "amphoe": "โคกเจริญ",
        "province": "ลพบุรี",
        "zipcode": 15250,
        "district_code": 160317,
        "amphoe_code": 1609,
        "province_code": 16
    },
    {
        "district": "วังทอง",
        "amphoe": "โคกเจริญ",
        "province": "ลพบุรี",
        "zipcode": 15250,
        "district_code": 160323,
        "amphoe_code": 1609,
        "province_code": 16
    },
    {
        "district": "หนองมะค่า",
        "amphoe": "โคกเจริญ",
        "province": "ลพบุรี",
        "zipcode": 15250,
        "district_code": 160321,
        "amphoe_code": 1609,
        "province_code": 16
    },
    {
        "district": "โคกเจริญ",
        "amphoe": "โคกเจริญ",
        "province": "ลพบุรี",
        "zipcode": 15250,
        "district_code": 160316,
        "amphoe_code": 1609,
        "province_code": 16
    },
    {
        "district": "โคกแสมสาร",
        "amphoe": "โคกเจริญ",
        "province": "ลพบุรี",
        "zipcode": 15250,
        "district_code": 160905,
        "amphoe_code": 1609,
        "province_code": 16
    },
    {
        "district": "นาแก",
        "amphoe": "งาว",
        "province": "ลำปาง",
        "zipcode": 52110,
        "district_code": 520506,
        "amphoe_code": 5205,
        "province_code": 52
    },
    {
        "district": "บ้านร้อง",
        "amphoe": "งาว",
        "province": "ลำปาง",
        "zipcode": 52110,
        "district_code": 520504,
        "amphoe_code": 5205,
        "province_code": 52
    },
    {
        "district": "บ้านหวด",
        "amphoe": "งาว",
        "province": "ลำปาง",
        "zipcode": 52110,
        "district_code": 520509,
        "amphoe_code": 5205,
        "province_code": 52
    },
    {
        "district": "บ้านอ้อน",
        "amphoe": "งาว",
        "province": "ลำปาง",
        "zipcode": 52110,
        "district_code": 520507,
        "amphoe_code": 5205,
        "province_code": 52
    },
    {
        "district": "บ้านแหง",
        "amphoe": "งาว",
        "province": "ลำปาง",
        "zipcode": 52110,
        "district_code": 520508,
        "amphoe_code": 5205,
        "province_code": 52
    },
    {
        "district": "บ้านโป่ง",
        "amphoe": "งาว",
        "province": "ลำปาง",
        "zipcode": 52110,
        "district_code": 520503,
        "amphoe_code": 5205,
        "province_code": 52
    },
    {
        "district": "ปงเตา",
        "amphoe": "งาว",
        "province": "ลำปาง",
        "zipcode": 52110,
        "district_code": 520505,
        "amphoe_code": 5205,
        "province_code": 52
    },
    {
        "district": "หลวงเหนือ",
        "amphoe": "งาว",
        "province": "ลำปาง",
        "zipcode": 52110,
        "district_code": 520501,
        "amphoe_code": 5205,
        "province_code": 52
    },
    {
        "district": "หลวงใต้",
        "amphoe": "งาว",
        "province": "ลำปาง",
        "zipcode": 52110,
        "district_code": 520502,
        "amphoe_code": 5205,
        "province_code": 52
    },
    {
        "district": "แม่ตีบ",
        "amphoe": "งาว",
        "province": "ลำปาง",
        "zipcode": 52110,
        "district_code": 520510,
        "amphoe_code": 5205,
        "province_code": 52
    },
    {
        "district": "ทุ่งฮั้ว",
        "amphoe": "วังเหนือ",
        "province": "ลำปาง",
        "zipcode": 52140,
        "district_code": 520701,
        "amphoe_code": 5207,
        "province_code": 52
    },
    {
        "district": "ร่องเคาะ",
        "amphoe": "วังเหนือ",
        "province": "ลำปาง",
        "zipcode": 52140,
        "district_code": 520704,
        "amphoe_code": 5207,
        "province_code": 52
    },
    {
        "district": "วังซ้าย",
        "amphoe": "วังเหนือ",
        "province": "ลำปาง",
        "zipcode": 52140,
        "district_code": 520706,
        "amphoe_code": 5207,
        "province_code": 52
    },
    {
        "district": "วังทรายคำ",
        "amphoe": "วังเหนือ",
        "province": "ลำปาง",
        "zipcode": 52140,
        "district_code": 520708,
        "amphoe_code": 5207,
        "province_code": 52
    },
    {
        "district": "วังทอง",
        "amphoe": "วังเหนือ",
        "province": "ลำปาง",
        "zipcode": 52140,
        "district_code": 520705,
        "amphoe_code": 5207,
        "province_code": 52
    },
    {
        "district": "วังเหนือ",
        "amphoe": "วังเหนือ",
        "province": "ลำปาง",
        "zipcode": 52140,
        "district_code": 520702,
        "amphoe_code": 5207,
        "province_code": 52
    },
    {
        "district": "วังแก้ว",
        "amphoe": "วังเหนือ",
        "province": "ลำปาง",
        "zipcode": 52140,
        "district_code": 520707,
        "amphoe_code": 5207,
        "province_code": 52
    },
    {
        "district": "วังใต้",
        "amphoe": "วังเหนือ",
        "province": "ลำปาง",
        "zipcode": 52140,
        "district_code": 520703,
        "amphoe_code": 5207,
        "province_code": 52
    },
    {
        "district": "นายาง",
        "amphoe": "สบปราบ",
        "province": "ลำปาง",
        "zipcode": 52170,
        "district_code": 521104,
        "amphoe_code": 5211,
        "province_code": 52
    },
    {
        "district": "สบปราบ",
        "amphoe": "สบปราบ",
        "province": "ลำปาง",
        "zipcode": 52170,
        "district_code": 521101,
        "amphoe_code": 5211,
        "province_code": 52
    },
    {
        "district": "สมัย",
        "amphoe": "สบปราบ",
        "province": "ลำปาง",
        "zipcode": 52170,
        "district_code": 521102,
        "amphoe_code": 5211,
        "province_code": 52
    },
    {
        "district": "แม่กัวะ",
        "amphoe": "สบปราบ",
        "province": "ลำปาง",
        "zipcode": 52170,
        "district_code": 521103,
        "amphoe_code": 5211,
        "province_code": 52
    },
    {
        "district": "ปงยางคก",
        "amphoe": "ห้างฉัตร",
        "province": "ลำปาง",
        "zipcode": 52190,
        "district_code": 521204,
        "amphoe_code": 5212,
        "province_code": 52
    },
    {
        "district": "วอแก้ว",
        "amphoe": "ห้างฉัตร",
        "province": "ลำปาง",
        "zipcode": 52190,
        "district_code": 521207,
        "amphoe_code": 5212,
        "province_code": 52
    },
    {
        "district": "หนองหล่ม",
        "amphoe": "ห้างฉัตร",
        "province": "ลำปาง",
        "zipcode": 52190,
        "district_code": 521202,
        "amphoe_code": 5212,
        "province_code": 52
    },
    {
        "district": "ห้างฉัตร",
        "amphoe": "ห้างฉัตร",
        "province": "ลำปาง",
        "zipcode": 52190,
        "district_code": 521201,
        "amphoe_code": 5212,
        "province_code": 52
    },
    {
        "district": "เมืองยาว",
        "amphoe": "ห้างฉัตร",
        "province": "ลำปาง",
        "zipcode": 52190,
        "district_code": 521203,
        "amphoe_code": 5212,
        "province_code": 52
    },
    {
        "district": "เวียงตาล",
        "amphoe": "ห้างฉัตร",
        "province": "ลำปาง",
        "zipcode": 52190,
        "district_code": 521205,
        "amphoe_code": 5212,
        "province_code": 52
    },
    {
        "district": "แม่สัน",
        "amphoe": "ห้างฉัตร",
        "province": "ลำปาง",
        "zipcode": 52190,
        "district_code": 521206,
        "amphoe_code": 5212,
        "province_code": 52
    },
    {
        "district": "ท่าผา",
        "amphoe": "เกาะคา",
        "province": "ลำปาง",
        "zipcode": 52130,
        "district_code": 520308,
        "amphoe_code": 5203,
        "province_code": 52
    },
    {
        "district": "นาแก้ว",
        "amphoe": "เกาะคา",
        "province": "ลำปาง",
        "zipcode": 52130,
        "district_code": 520302,
        "amphoe_code": 5203,
        "province_code": 52
    },
    {
        "district": "นาแส่ง",
        "amphoe": "เกาะคา",
        "province": "ลำปาง",
        "zipcode": 52130,
        "district_code": 520307,
        "amphoe_code": 5203,
        "province_code": 52
    },
    {
        "district": "ลำปางหลวง",
        "amphoe": "เกาะคา",
        "province": "ลำปาง",
        "zipcode": 52130,
        "district_code": 520301,
        "amphoe_code": 5203,
        "province_code": 52
    },
    {
        "district": "วังพร้าว",
        "amphoe": "เกาะคา",
        "province": "ลำปาง",
        "zipcode": 52130,
        "district_code": 520304,
        "amphoe_code": 5203,
        "province_code": 52
    },
    {
        "district": "ศาลา",
        "amphoe": "เกาะคา",
        "province": "ลำปาง",
        "zipcode": 52130,
        "district_code": 520305,
        "amphoe_code": 5203,
        "province_code": 52
    },
    {
        "district": "เกาะคา",
        "amphoe": "เกาะคา",
        "province": "ลำปาง",
        "zipcode": 52130,
        "district_code": 520306,
        "amphoe_code": 5203,
        "province_code": 52
    },
    {
        "district": "ใหม่พัฒนา",
        "amphoe": "เกาะคา",
        "province": "ลำปาง",
        "zipcode": 52130,
        "district_code": 520309,
        "amphoe_code": 5203,
        "province_code": 52
    },
    {
        "district": "ไหล่หิน",
        "amphoe": "เกาะคา",
        "province": "ลำปาง",
        "zipcode": 52130,
        "district_code": 520303,
        "amphoe_code": 5203,
        "province_code": 52
    },
    {
        "district": "นาโป่ง",
        "amphoe": "เถิน",
        "province": "ลำปาง",
        "zipcode": 52160,
        "district_code": 520806,
        "amphoe_code": 5208,
        "province_code": 52
    },
    {
        "district": "ล้อมแรด",
        "amphoe": "เถิน",
        "province": "ลำปาง",
        "zipcode": 52160,
        "district_code": 520801,
        "amphoe_code": 5208,
        "province_code": 52
    },
    {
        "district": "เถินบุรี",
        "amphoe": "เถิน",
        "province": "ลำปาง",
        "zipcode": 52160,
        "district_code": 520808,
        "amphoe_code": 5208,
        "province_code": 52
    },
    {
        "district": "เวียงมอก",
        "amphoe": "เถิน",
        "province": "ลำปาง",
        "zipcode": 52160,
        "district_code": 520805,
        "amphoe_code": 5208,
        "province_code": 52
    },
    {
        "district": "แม่ถอด",
        "amphoe": "เถิน",
        "province": "ลำปาง",
        "zipcode": 52160,
        "district_code": 520807,
        "amphoe_code": 5208,
        "province_code": 52
    },
    {
        "district": "แม่ปะ",
        "amphoe": "เถิน",
        "province": "ลำปาง",
        "zipcode": 52160,
        "district_code": 520803,
        "amphoe_code": 5208,
        "province_code": 52
    },
    {
        "district": "แม่มอก",
        "amphoe": "เถิน",
        "province": "ลำปาง",
        "zipcode": 52160,
        "district_code": 520804,
        "amphoe_code": 5208,
        "province_code": 52
    },
    {
        "district": "แม่วะ",
        "amphoe": "เถิน",
        "province": "ลำปาง",
        "zipcode": 52230,
        "district_code": 520802,
        "amphoe_code": 5208,
        "province_code": 52
    },
    {
        "district": "ทุ่งกว๋าว",
        "amphoe": "เมืองปาน",
        "province": "ลำปาง",
        "zipcode": 52240,
        "district_code": 520697,
        "amphoe_code": 5213,
        "province_code": 52
    },
    {
        "district": "บ้านขอ",
        "amphoe": "เมืองปาน",
        "province": "ลำปาง",
        "zipcode": 52240,
        "district_code": 520698,
        "amphoe_code": 5213,
        "province_code": 52
    },
    {
        "district": "หัวเมือง",
        "amphoe": "เมืองปาน",
        "province": "ลำปาง",
        "zipcode": 52240,
        "district_code": 521305,
        "amphoe_code": 5213,
        "province_code": 52
    },
    {
        "district": "เมืองปาน",
        "amphoe": "เมืองปาน",
        "province": "ลำปาง",
        "zipcode": 52240,
        "district_code": 520699,
        "amphoe_code": 5213,
        "province_code": 52
    },
    {
        "district": "แจ้ซ้อน",
        "amphoe": "เมืองปาน",
        "province": "ลำปาง",
        "zipcode": 52240,
        "district_code": 520696,
        "amphoe_code": 5213,
        "province_code": 52
    },
    {
        "district": "กล้วยแพะ",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52000,
        "district_code": 520107,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "ชมพู",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52100,
        "district_code": 520106,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "ต้นธงชัย",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52000,
        "district_code": 520117,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "ทุ่งฝาย",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52000,
        "district_code": 520112,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "นิคมพัฒนา",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52000,
        "district_code": 520118,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "บุญนาคพัฒนา",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52000,
        "district_code": 520119,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "บ่อแฮ้ว",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52100,
        "district_code": 520116,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "บ้านค่า",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52100,
        "district_code": 520115,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "บ้านเป้า",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52100,
        "district_code": 520114,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "บ้านเสด็จ",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52000,
        "district_code": 520110,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "บ้านเอื้อม",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52100,
        "district_code": 520113,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "บ้านแลง",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52000,
        "district_code": 520109,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "ปงแสนทอง",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52100,
        "district_code": 520108,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "พระบาท",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52000,
        "district_code": 520105,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "พิชัย",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52000,
        "district_code": 520111,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "สบตุ๋ย",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52100,
        "district_code": 520104,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "สวนดอก",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52100,
        "district_code": 520103,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "หัวเวียง",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52000,
        "district_code": 520102,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "เวียงเหนือ",
        "amphoe": "เมืองลำปาง",
        "province": "ลำปาง",
        "zipcode": 52000,
        "district_code": 520101,
        "amphoe_code": 5201,
        "province_code": 52
    },
    {
        "district": "ทุ่งงาม",
        "amphoe": "เสริมงาม",
        "province": "ลำปาง",
        "zipcode": 52210,
        "district_code": 520401,
        "amphoe_code": 5204,
        "province_code": 52
    },
    {
        "district": "เสริมกลาง",
        "amphoe": "เสริมงาม",
        "province": "ลำปาง",
        "zipcode": 52210,
        "district_code": 520404,
        "amphoe_code": 5204,
        "province_code": 52
    },
    {
        "district": "เสริมขวา",
        "amphoe": "เสริมงาม",
        "province": "ลำปาง",
        "zipcode": 52210,
        "district_code": 520402,
        "amphoe_code": 5204,
        "province_code": 52
    },
    {
        "district": "เสริมซ้าย",
        "amphoe": "เสริมงาม",
        "province": "ลำปาง",
        "zipcode": 52210,
        "district_code": 520403,
        "amphoe_code": 5204,
        "province_code": 52
    },
    {
        "district": "ทุ่งผึ้ง",
        "amphoe": "แจ้ห่ม",
        "province": "ลำปาง",
        "zipcode": 52120,
        "district_code": 520606,
        "amphoe_code": 5206,
        "province_code": 52
    },
    {
        "district": "บ้านสา",
        "amphoe": "แจ้ห่ม",
        "province": "ลำปาง",
        "zipcode": 52120,
        "district_code": 520602,
        "amphoe_code": 5206,
        "province_code": 52
    },
    {
        "district": "ปงดอน",
        "amphoe": "แจ้ห่ม",
        "province": "ลำปาง",
        "zipcode": 52120,
        "district_code": 520603,
        "amphoe_code": 5206,
        "province_code": 52
    },
    {
        "district": "วิเชตนคร",
        "amphoe": "แจ้ห่ม",
        "province": "ลำปาง",
        "zipcode": 52120,
        "district_code": 520607,
        "amphoe_code": 5206,
        "province_code": 52
    },
    {
        "district": "เมืองมาย",
        "amphoe": "แจ้ห่ม",
        "province": "ลำปาง",
        "zipcode": 52120,
        "district_code": 520605,
        "amphoe_code": 5206,
        "province_code": 52
    },
    {
        "district": "แจ้ห่ม",
        "amphoe": "แจ้ห่ม",
        "province": "ลำปาง",
        "zipcode": 52120,
        "district_code": 520601,
        "amphoe_code": 5206,
        "province_code": 52
    },
    {
        "district": "แม่สุก",
        "amphoe": "แจ้ห่ม",
        "province": "ลำปาง",
        "zipcode": 52120,
        "district_code": 520604,
        "amphoe_code": 5206,
        "province_code": 52
    },
    {
        "district": "ดอนไฟ",
        "amphoe": "แม่ทะ",
        "province": "ลำปาง",
        "zipcode": 52150,
        "district_code": 521007,
        "amphoe_code": 5210,
        "province_code": 52
    },
    {
        "district": "นาครัว",
        "amphoe": "แม่ทะ",
        "province": "ลำปาง",
        "zipcode": 52150,
        "district_code": 521002,
        "amphoe_code": 5210,
        "province_code": 52
    },
    {
        "district": "น้ำโจ้",
        "amphoe": "แม่ทะ",
        "province": "ลำปาง",
        "zipcode": 52150,
        "district_code": 521006,
        "amphoe_code": 5210,
        "province_code": 52
    },
    {
        "district": "บ้านกิ่ว",
        "amphoe": "แม่ทะ",
        "province": "ลำปาง",
        "zipcode": 52150,
        "district_code": 521004,
        "amphoe_code": 5210,
        "province_code": 52
    },
    {
        "district": "บ้านบอม",
        "amphoe": "แม่ทะ",
        "province": "ลำปาง",
        "zipcode": 52150,
        "district_code": 521005,
        "amphoe_code": 5210,
        "province_code": 52
    },
    {
        "district": "ป่าตัน",
        "amphoe": "แม่ทะ",
        "province": "ลำปาง",
        "zipcode": 52150,
        "district_code": 521003,
        "amphoe_code": 5210,
        "province_code": 52
    },
    {
        "district": "วังเงิน",
        "amphoe": "แม่ทะ",
        "province": "ลำปาง",
        "zipcode": 52150,
        "district_code": 521010,
        "amphoe_code": 5210,
        "province_code": 52
    },
    {
        "district": "สันดอนแก้ว",
        "amphoe": "แม่ทะ",
        "province": "ลำปาง",
        "zipcode": 52150,
        "district_code": 521011,
        "amphoe_code": 5210,
        "province_code": 52
    },
    {
        "district": "หัวเสือ",
        "amphoe": "แม่ทะ",
        "province": "ลำปาง",
        "zipcode": 52150,
        "district_code": 521008,
        "amphoe_code": 5210,
        "province_code": 52
    },
    {
        "district": "แม่ทะ",
        "amphoe": "แม่ทะ",
        "province": "ลำปาง",
        "zipcode": 52150,
        "district_code": 521001,
        "amphoe_code": 5210,
        "province_code": 52
    },
    {
        "district": "ผาปัง",
        "amphoe": "แม่พริก",
        "province": "ลำปาง",
        "zipcode": 52180,
        "district_code": 520902,
        "amphoe_code": 5209,
        "province_code": 52
    },
    {
        "district": "พระบาทวังตวง",
        "amphoe": "แม่พริก",
        "province": "ลำปาง",
        "zipcode": 52230,
        "district_code": 520904,
        "amphoe_code": 5209,
        "province_code": 52
    },
    {
        "district": "แม่ปุ",
        "amphoe": "แม่พริก",
        "province": "ลำปาง",
        "zipcode": 52180,
        "district_code": 520903,
        "amphoe_code": 5209,
        "province_code": 52
    },
    {
        "district": "แม่พริก",
        "amphoe": "แม่พริก",
        "province": "ลำปาง",
        "zipcode": 52180,
        "district_code": 520901,
        "amphoe_code": 5209,
        "province_code": 52
    },
    {
        "district": "จางเหนือ",
        "amphoe": "แม่เมาะ",
        "province": "ลำปาง",
        "zipcode": 52220,
        "district_code": 520203,
        "amphoe_code": 5202,
        "province_code": 52
    },
    {
        "district": "นาสัก",
        "amphoe": "แม่เมาะ",
        "province": "ลำปาง",
        "zipcode": 52220,
        "district_code": 520198,
        "amphoe_code": 5202,
        "province_code": 52
    },
    {
        "district": "บ้านดง",
        "amphoe": "แม่เมาะ",
        "province": "ลำปาง",
        "zipcode": 52220,
        "district_code": 520199,
        "amphoe_code": 5202,
        "province_code": 52
    },
    {
        "district": "สบป้าด",
        "amphoe": "แม่เมาะ",
        "province": "ลำปาง",
        "zipcode": 52220,
        "district_code": 520205,
        "amphoe_code": 5202,
        "province_code": 52
    },
    {
        "district": "แม่เมาะ",
        "amphoe": "แม่เมาะ",
        "province": "ลำปาง",
        "zipcode": 52220,
        "district_code": 520204,
        "amphoe_code": 5202,
        "province_code": 52
    },
    {
        "district": "ตะเคียนปม",
        "amphoe": "ทุ่งหัวช้าง",
        "province": "ลำพูน",
        "zipcode": 51160,
        "district_code": 510503,
        "amphoe_code": 5105,
        "province_code": 51
    },
    {
        "district": "ทุ่งหัวช้าง",
        "amphoe": "ทุ่งหัวช้าง",
        "province": "ลำพูน",
        "zipcode": 51160,
        "district_code": 510499,
        "amphoe_code": 5105,
        "province_code": 51
    },
    {
        "district": "บ้านปวง",
        "amphoe": "ทุ่งหัวช้าง",
        "province": "ลำพูน",
        "zipcode": 51160,
        "district_code": 510498,
        "amphoe_code": 5105,
        "province_code": 51
    },
    {
        "district": "บ้านธิ",
        "amphoe": "บ้านธิ",
        "province": "ลำพูน",
        "zipcode": 51180,
        "district_code": 510114,
        "amphoe_code": 5107,
        "province_code": 51
    },
    {
        "district": "ห้วยยาบ",
        "amphoe": "บ้านธิ",
        "province": "ลำพูน",
        "zipcode": 51180,
        "district_code": 510115,
        "amphoe_code": 5107,
        "province_code": 51
    },
    {
        "district": "บ้านโฮ่ง",
        "amphoe": "บ้านโฮ่ง",
        "province": "ลำพูน",
        "zipcode": 51130,
        "district_code": 510301,
        "amphoe_code": 5103,
        "province_code": 51
    },
    {
        "district": "ป่าพลู",
        "amphoe": "บ้านโฮ่ง",
        "province": "ลำพูน",
        "zipcode": 51130,
        "district_code": 510302,
        "amphoe_code": 5103,
        "province_code": 51
    },
    {
        "district": "ศรีเตี้ย",
        "amphoe": "บ้านโฮ่ง",
        "province": "ลำพูน",
        "zipcode": 51130,
        "district_code": 510304,
        "amphoe_code": 5103,
        "province_code": 51
    },
    {
        "district": "หนองปลาสะวาย",
        "amphoe": "บ้านโฮ่ง",
        "province": "ลำพูน",
        "zipcode": 51130,
        "district_code": 510305,
        "amphoe_code": 5103,
        "province_code": 51
    },
    {
        "district": "เหล่ายาว",
        "amphoe": "บ้านโฮ่ง",
        "province": "ลำพูน",
        "zipcode": 51130,
        "district_code": 510303,
        "amphoe_code": 5103,
        "province_code": 51
    },
    {
        "district": "ท่าตุ้ม",
        "amphoe": "ป่าซาง",
        "province": "ลำพูน",
        "zipcode": 51120,
        "district_code": 510607,
        "amphoe_code": 5106,
        "province_code": 51
    },
    {
        "district": "นครเจดีย์",
        "amphoe": "ป่าซาง",
        "province": "ลำพูน",
        "zipcode": 51120,
        "district_code": 510611,
        "amphoe_code": 5106,
        "province_code": 51
    },
    {
        "district": "น้ำดิบ",
        "amphoe": "ป่าซาง",
        "province": "ลำพูน",
        "zipcode": 51120,
        "district_code": 510608,
        "amphoe_code": 5106,
        "province_code": 51
    },
    {
        "district": "บ้านเรือน",
        "amphoe": "ป่าซาง",
        "province": "ลำพูน",
        "zipcode": 51120,
        "district_code": 510605,
        "amphoe_code": 5106,
        "province_code": 51
    },
    {
        "district": "ปากบ่อง",
        "amphoe": "ป่าซาง",
        "province": "ลำพูน",
        "zipcode": 51120,
        "district_code": 510601,
        "amphoe_code": 5106,
        "province_code": 51
    },
    {
        "district": "ป่าซาง",
        "amphoe": "ป่าซาง",
        "province": "ลำพูน",
        "zipcode": 51120,
        "district_code": 510602,
        "amphoe_code": 5106,
        "province_code": 51
    },
    {
        "district": "มะกอก",
        "amphoe": "ป่าซาง",
        "province": "ลำพูน",
        "zipcode": 51120,
        "district_code": 510606,
        "amphoe_code": 5106,
        "province_code": 51
    },
    {
        "district": "ม่วงน้อย",
        "amphoe": "ป่าซาง",
        "province": "ลำพูน",
        "zipcode": 51120,
        "district_code": 510604,
        "amphoe_code": 5106,
        "province_code": 51
    },
    {
        "district": "แม่แรง",
        "amphoe": "ป่าซาง",
        "province": "ลำพูน",
        "zipcode": 51120,
        "district_code": 510603,
        "amphoe_code": 5106,
        "province_code": 51
    },
    {
        "district": "ก้อ",
        "amphoe": "ลี้",
        "province": "ลำพูน",
        "zipcode": 51110,
        "district_code": 510405,
        "amphoe_code": 5104,
        "province_code": 51
    },
    {
        "district": "ดงดำ",
        "amphoe": "ลี้",
        "province": "ลำพูน",
        "zipcode": 51110,
        "district_code": 510404,
        "amphoe_code": 5104,
        "province_code": 51
    },
    {
        "district": "นาทราย",
        "amphoe": "ลี้",
        "province": "ลำพูน",
        "zipcode": 51110,
        "district_code": 510403,
        "amphoe_code": 5104,
        "province_code": 51
    },
    {
        "district": "ป่าไผ่",
        "amphoe": "ลี้",
        "province": "ลำพูน",
        "zipcode": 51110,
        "district_code": 510408,
        "amphoe_code": 5104,
        "province_code": 51
    },
    {
        "district": "ลี้",
        "amphoe": "ลี้",
        "province": "ลำพูน",
        "zipcode": 51110,
        "district_code": 510401,
        "amphoe_code": 5104,
        "province_code": 51
    },
    {
        "district": "ศรีวิชัย",
        "amphoe": "ลี้",
        "province": "ลำพูน",
        "zipcode": 51110,
        "district_code": 510409,
        "amphoe_code": 5104,
        "province_code": 51
    },
    {
        "district": "แม่ตืน",
        "amphoe": "ลี้",
        "province": "ลำพูน",
        "zipcode": 51110,
        "district_code": 510402,
        "amphoe_code": 5104,
        "province_code": 51
    },
    {
        "district": "แม่ลาน",
        "amphoe": "ลี้",
        "province": "ลำพูน",
        "zipcode": 51110,
        "district_code": 510406,
        "amphoe_code": 5104,
        "province_code": 51
    },
    {
        "district": "ต้นธง",
        "amphoe": "เมืองลำพูน",
        "province": "ลำพูน",
        "zipcode": 51000,
        "district_code": 510107,
        "amphoe_code": 5101,
        "province_code": 51
    },
    {
        "district": "บ้านกลาง",
        "amphoe": "เมืองลำพูน",
        "province": "ลำพูน",
        "zipcode": 51000,
        "district_code": 510112,
        "amphoe_code": 5101,
        "province_code": 51
    },
    {
        "district": "บ้านแป้น",
        "amphoe": "เมืองลำพูน",
        "province": "ลำพูน",
        "zipcode": 51000,
        "district_code": 510108,
        "amphoe_code": 5101,
        "province_code": 51
    },
    {
        "district": "ประตูป่า",
        "amphoe": "เมืองลำพูน",
        "province": "ลำพูน",
        "zipcode": 51000,
        "district_code": 510105,
        "amphoe_code": 5101,
        "province_code": 51
    },
    {
        "district": "ป่าสัก",
        "amphoe": "เมืองลำพูน",
        "province": "ลำพูน",
        "zipcode": 51000,
        "district_code": 510110,
        "amphoe_code": 5101,
        "province_code": 51
    },
    {
        "district": "มะเขือแจ้",
        "amphoe": "เมืองลำพูน",
        "province": "ลำพูน",
        "zipcode": 51000,
        "district_code": 510113,
        "amphoe_code": 5101,
        "province_code": 51
    },
    {
        "district": "ริมปิง",
        "amphoe": "เมืองลำพูน",
        "province": "ลำพูน",
        "zipcode": 51000,
        "district_code": 510106,
        "amphoe_code": 5101,
        "province_code": 51
    },
    {
        "district": "ศรีบัวบาน",
        "amphoe": "เมืองลำพูน",
        "province": "ลำพูน",
        "zipcode": 51000,
        "district_code": 510116,
        "amphoe_code": 5101,
        "province_code": 51
    },
    {
        "district": "หนองช้างคืน",
        "amphoe": "เมืองลำพูน",
        "province": "ลำพูน",
        "zipcode": 51150,
        "district_code": 510104,
        "amphoe_code": 5101,
        "province_code": 51
    },
    {
        "district": "หนองหนาม",
        "amphoe": "เมืองลำพูน",
        "province": "ลำพูน",
        "zipcode": 51000,
        "district_code": 510117,
        "amphoe_code": 5101,
        "province_code": 51
    },
    {
        "district": "อุโมงค์",
        "amphoe": "เมืองลำพูน",
        "province": "ลำพูน",
        "zipcode": 51150,
        "district_code": 510103,
        "amphoe_code": 5101,
        "province_code": 51
    },
    {
        "district": "เวียงยอง",
        "amphoe": "เมืองลำพูน",
        "province": "ลำพูน",
        "zipcode": 51000,
        "district_code": 510111,
        "amphoe_code": 5101,
        "province_code": 51
    },
    {
        "district": "เหมืองง่า",
        "amphoe": "เมืองลำพูน",
        "province": "ลำพูน",
        "zipcode": 51000,
        "district_code": 510102,
        "amphoe_code": 5101,
        "province_code": 51
    },
    {
        "district": "เหมืองจี้",
        "amphoe": "เมืองลำพูน",
        "province": "ลำพูน",
        "zipcode": 51000,
        "district_code": 510109,
        "amphoe_code": 5101,
        "province_code": 51
    },
    {
        "district": "ในเมือง",
        "amphoe": "เมืองลำพูน",
        "province": "ลำพูน",
        "zipcode": 51000,
        "district_code": 510101,
        "amphoe_code": 5101,
        "province_code": 51
    },
    {
        "district": "วังผาง",
        "amphoe": "เวียงหนองล่อง",
        "province": "ลำพูน",
        "zipcode": 51120,
        "district_code": 510609,
        "amphoe_code": 5108,
        "province_code": 51
    },
    {
        "district": "หนองยวง",
        "amphoe": "เวียงหนองล่อง",
        "province": "ลำพูน",
        "zipcode": 51120,
        "district_code": 510612,
        "amphoe_code": 5108,
        "province_code": 51
    },
    {
        "district": "หนองล่อง",
        "amphoe": "เวียงหนองล่อง",
        "province": "ลำพูน",
        "zipcode": 51120,
        "district_code": 510610,
        "amphoe_code": 5108,
        "province_code": 51
    },
    {
        "district": "ทากาศ",
        "amphoe": "แม่ทา",
        "province": "ลำพูน",
        "zipcode": 51170,
        "district_code": 510203,
        "amphoe_code": 5102,
        "province_code": 51
    },
    {
        "district": "ทาขุมเงิน",
        "amphoe": "แม่ทา",
        "province": "ลำพูน",
        "zipcode": 51170,
        "district_code": 510204,
        "amphoe_code": 5102,
        "province_code": 51
    },
    {
        "district": "ทาทุ่งหลวง",
        "amphoe": "แม่ทา",
        "province": "ลำพูน",
        "zipcode": 51170,
        "district_code": 510205,
        "amphoe_code": 5102,
        "province_code": 51
    },
    {
        "district": "ทาปลาดุก",
        "amphoe": "แม่ทา",
        "province": "ลำพูน",
        "zipcode": 51140,
        "district_code": 510201,
        "amphoe_code": 5102,
        "province_code": 51
    },
    {
        "district": "ทาสบเส้า",
        "amphoe": "แม่ทา",
        "province": "ลำพูน",
        "zipcode": 51140,
        "district_code": 510202,
        "amphoe_code": 5102,
        "province_code": 51
    },
    {
        "district": "ทาแม่ลอบ",
        "amphoe": "แม่ทา",
        "province": "ลำพูน",
        "zipcode": 51170,
        "district_code": 510206,
        "amphoe_code": 5102,
        "province_code": 51
    },
    {
        "district": "กระแชง",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330414,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "กุดเสลา",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330402,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "ขนุน",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330420,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "จานใหญ่",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330411,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "ชำ",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330413,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "ตระกาจ",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330409,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "ทุ่งใหญ่",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330424,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "น้ำอ้อม",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330406,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "บึงมะลู",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330401,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "ภูผาหมอก",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330425,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "ภูเงิน",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330412,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "รุง",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330408,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "ละลาย",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330407,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "สวนกล้วย",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330421,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "สังเม็ก",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330405,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "หนองหญ้าลาด",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330416,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "เมือง",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330403,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "เวียงเหนือ",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330423,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "เสาธงชัย",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330419,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "โนนสำราญ",
        "amphoe": "กันทรลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330415,
        "amphoe_code": 3304,
        "province_code": 33
    },
    {
        "district": "คำเนียม",
        "amphoe": "กันทรารมย์",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330320,
        "amphoe_code": 3303,
        "province_code": 33
    },
    {
        "district": "จาน",
        "amphoe": "กันทรารมย์",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330105,
        "amphoe_code": 3303,
        "province_code": 33
    },
    {
        "district": "ดูน",
        "amphoe": "กันทรารมย์",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330301,
        "amphoe_code": 3303,
        "province_code": 33
    },
    {
        "district": "ดู่",
        "amphoe": "กันทรารมย์",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330313,
        "amphoe_code": 3303,
        "province_code": 33
    },
    {
        "district": "ทาม",
        "amphoe": "กันทรารมย์",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330307,
        "amphoe_code": 3303,
        "province_code": 33
    },
    {
        "district": "บัวน้อย",
        "amphoe": "กันทรารมย์",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330311,
        "amphoe_code": 3303,
        "province_code": 33
    },
    {
        "district": "ผักแพว",
        "amphoe": "กันทรารมย์",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330314,
        "amphoe_code": 3303,
        "province_code": 33
    },
    {
        "district": "ยาง",
        "amphoe": "กันทรารมย์",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330304,
        "amphoe_code": 3303,
        "province_code": 33
    },
    {
        "district": "ละทาย",
        "amphoe": "กันทรารมย์",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330308,
        "amphoe_code": 3303,
        "province_code": 33
    },
    {
        "district": "หนองบัว",
        "amphoe": "กันทรารมย์",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330312,
        "amphoe_code": 3303,
        "province_code": 33
    },
    {
        "district": "หนองหัวช้าง",
        "amphoe": "กันทรารมย์",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330303,
        "amphoe_code": 3303,
        "province_code": 33
    },
    {
        "district": "หนองแก้ว",
        "amphoe": "กันทรารมย์",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330121,
        "amphoe_code": 3303,
        "province_code": 33
    },
    {
        "district": "หนองแวง",
        "amphoe": "กันทรารมย์",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330305,
        "amphoe_code": 3303,
        "province_code": 33
    },
    {
        "district": "อีปาด",
        "amphoe": "กันทรารมย์",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330310,
        "amphoe_code": 3303,
        "province_code": 33
    },
    {
        "district": "เมืองน้อย",
        "amphoe": "กันทรารมย์",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330309,
        "amphoe_code": 3303,
        "province_code": 33
    },
    {
        "district": "โนนสัง",
        "amphoe": "กันทรารมย์",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330302,
        "amphoe_code": 3303,
        "province_code": 33
    },
    {
        "district": "กฤษณา",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330524,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "กันทรารมย์",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330501,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "จะกง",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330502,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "ดองกำเม็ด",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330504,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "ตะเคียน",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330513,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "ตาอุด",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330508,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "นิคมพัฒนา",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330515,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "ปราสาท",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330518,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "ปรือใหญ่",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330506,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "ลมศักดิ์",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330525,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "ศรีตระกูล",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330527,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "ศรีสะอาด",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330528,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "สะเดาใหญ่",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330507,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "สำโรงตาเจ็น",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330521,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "หนองฉลอง",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330526,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "หัวเสือ",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330511,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "ห้วยสำราญ",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330522,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "ห้วยเหนือ",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330509,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "ห้วยใต้",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330510,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "โคกเพชร",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330517,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "โสน",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330505,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "ใจดี",
        "amphoe": "ขุขันธ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330503,
        "amphoe_code": 3305,
        "province_code": 33
    },
    {
        "district": "กระหวัน",
        "amphoe": "ขุนหาญ",
        "province": "ศรีสะเกษ",
        "zipcode": 33150,
        "district_code": 330806,
        "amphoe_code": 3308,
        "province_code": 33
    },
    {
        "district": "กันทรอม",
        "amphoe": "ขุนหาญ",
        "province": "ศรีสะเกษ",
        "zipcode": 33150,
        "district_code": 330809,
        "amphoe_code": 3308,
        "province_code": 33
    },
    {
        "district": "ขุนหาญ",
        "amphoe": "ขุนหาญ",
        "province": "ศรีสะเกษ",
        "zipcode": 33150,
        "district_code": 330807,
        "amphoe_code": 3308,
        "province_code": 33
    },
    {
        "district": "บักดอง",
        "amphoe": "ขุนหาญ",
        "province": "ศรีสะเกษ",
        "zipcode": 33150,
        "district_code": 330802,
        "amphoe_code": 3308,
        "province_code": 33
    },
    {
        "district": "พราน",
        "amphoe": "ขุนหาญ",
        "province": "ศรีสะเกษ",
        "zipcode": 33150,
        "district_code": 330803,
        "amphoe_code": 3308,
        "province_code": 33
    },
    {
        "district": "ภูฝ้าย",
        "amphoe": "ขุนหาญ",
        "province": "ศรีสะเกษ",
        "zipcode": 33150,
        "district_code": 330810,
        "amphoe_code": 3308,
        "province_code": 33
    },
    {
        "district": "สิ",
        "amphoe": "ขุนหาญ",
        "province": "ศรีสะเกษ",
        "zipcode": 33150,
        "district_code": 330801,
        "amphoe_code": 3308,
        "province_code": 33
    },
    {
        "district": "ห้วยจันทร์",
        "amphoe": "ขุนหาญ",
        "province": "ศรีสะเกษ",
        "zipcode": 33150,
        "district_code": 330812,
        "amphoe_code": 3308,
        "province_code": 33
    },
    {
        "district": "โนนสูง",
        "amphoe": "ขุนหาญ",
        "province": "ศรีสะเกษ",
        "zipcode": 33150,
        "district_code": 330808,
        "amphoe_code": 3308,
        "province_code": 33
    },
    {
        "district": "โพธิ์กระสังข์",
        "amphoe": "ขุนหาญ",
        "province": "ศรีสะเกษ",
        "zipcode": 33150,
        "district_code": 330811,
        "amphoe_code": 3308,
        "province_code": 33
    },
    {
        "district": "โพธิ์วงศ์",
        "amphoe": "ขุนหาญ",
        "province": "ศรีสะเกษ",
        "zipcode": 33150,
        "district_code": 330804,
        "amphoe_code": 3308,
        "province_code": 33
    },
    {
        "district": "ไพร",
        "amphoe": "ขุนหาญ",
        "province": "ศรีสะเกษ",
        "zipcode": 33150,
        "district_code": 330805,
        "amphoe_code": 3308,
        "province_code": 33
    },
    {
        "district": "คูบ",
        "amphoe": "น้ำเกลี้ยง",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 331506,
        "amphoe_code": 3315,
        "province_code": 33
    },
    {
        "district": "ตองปิด",
        "amphoe": "น้ำเกลี้ยง",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330394,
        "amphoe_code": 3315,
        "province_code": 33
    },
    {
        "district": "น้ำเกลี้ยง",
        "amphoe": "น้ำเกลี้ยง",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330318,
        "amphoe_code": 3315,
        "province_code": 33
    },
    {
        "district": "รุ่งระวี",
        "amphoe": "น้ำเกลี้ยง",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 331505,
        "amphoe_code": 3315,
        "province_code": 33
    },
    {
        "district": "ละเอาะ",
        "amphoe": "น้ำเกลี้ยง",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330317,
        "amphoe_code": 3315,
        "province_code": 33
    },
    {
        "district": "เขิน",
        "amphoe": "น้ำเกลี้ยง",
        "province": "ศรีสะเกษ",
        "zipcode": 33130,
        "district_code": 330319,
        "amphoe_code": 3315,
        "province_code": 33
    },
    {
        "district": "บึงบูรพ์",
        "amphoe": "บึงบูรพ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33220,
        "district_code": 331102,
        "amphoe_code": 3311,
        "province_code": 33
    },
    {
        "district": "เป๊าะ",
        "amphoe": "บึงบูรพ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33220,
        "district_code": 331099,
        "amphoe_code": 3311,
        "province_code": 33
    },
    {
        "district": "กู่",
        "amphoe": "ปรางค์กู่",
        "province": "ศรีสะเกษ",
        "zipcode": 33170,
        "district_code": 330702,
        "amphoe_code": 3307,
        "province_code": 33
    },
    {
        "district": "ดู่",
        "amphoe": "ปรางค์กู่",
        "province": "ศรีสะเกษ",
        "zipcode": 33170,
        "district_code": 330313,
        "amphoe_code": 3307,
        "province_code": 33
    },
    {
        "district": "ตูม",
        "amphoe": "ปรางค์กู่",
        "province": "ศรีสะเกษ",
        "zipcode": 33170,
        "district_code": 330499,
        "amphoe_code": 3307,
        "province_code": 33
    },
    {
        "district": "พิมาย",
        "amphoe": "ปรางค์กู่",
        "province": "ศรีสะเกษ",
        "zipcode": 33170,
        "district_code": 330701,
        "amphoe_code": 3307,
        "province_code": 33
    },
    {
        "district": "พิมายเหนือ",
        "amphoe": "ปรางค์กู่",
        "province": "ศรีสะเกษ",
        "zipcode": 33170,
        "district_code": 330710,
        "amphoe_code": 3307,
        "province_code": 33
    },
    {
        "district": "สมอ",
        "amphoe": "ปรางค์กู่",
        "province": "ศรีสะเกษ",
        "zipcode": 33170,
        "district_code": 330705,
        "amphoe_code": 3307,
        "province_code": 33
    },
    {
        "district": "สวาย",
        "amphoe": "ปรางค์กู่",
        "province": "ศรีสะเกษ",
        "zipcode": 33170,
        "district_code": 330709,
        "amphoe_code": 3307,
        "province_code": 33
    },
    {
        "district": "สำโรงปราสาท",
        "amphoe": "ปรางค์กู่",
        "province": "ศรีสะเกษ",
        "zipcode": 33170,
        "district_code": 330707,
        "amphoe_code": 3307,
        "province_code": 33
    },
    {
        "district": "หนองเชียงทูน",
        "amphoe": "ปรางค์กู่",
        "province": "ศรีสะเกษ",
        "zipcode": 33170,
        "district_code": 330703,
        "amphoe_code": 3307,
        "province_code": 33
    },
    {
        "district": "โพธิ์ศรี",
        "amphoe": "ปรางค์กู่",
        "province": "ศรีสะเกษ",
        "zipcode": 33170,
        "district_code": 330706,
        "amphoe_code": 3307,
        "province_code": 33
    },
    {
        "district": "ตำแย",
        "amphoe": "พยุห์",
        "province": "ศรีสะเกษ",
        "zipcode": 33230,
        "district_code": 330114,
        "amphoe_code": 3320,
        "province_code": 33
    },
    {
        "district": "พยุห์",
        "amphoe": "พยุห์",
        "province": "ศรีสะเกษ",
        "zipcode": 33230,
        "district_code": 330110,
        "amphoe_code": 3320,
        "province_code": 33
    },
    {
        "district": "พรหมสวัสดิ์",
        "amphoe": "พยุห์",
        "province": "ศรีสะเกษ",
        "zipcode": 33230,
        "district_code": 330109,
        "amphoe_code": 3320,
        "province_code": 33
    },
    {
        "district": "หนองค้า",
        "amphoe": "พยุห์",
        "province": "ศรีสะเกษ",
        "zipcode": 33230,
        "district_code": 330128,
        "amphoe_code": 3320,
        "province_code": 33
    },
    {
        "district": "โนนเพ็ก",
        "amphoe": "พยุห์",
        "province": "ศรีสะเกษ",
        "zipcode": 33230,
        "district_code": 330108,
        "amphoe_code": 3320,
        "province_code": 33
    },
    {
        "district": "ดงรัก",
        "amphoe": "ภูสิงห์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330523,
        "amphoe_code": 3317,
        "province_code": 33
    },
    {
        "district": "ตะเคียนราม",
        "amphoe": "ภูสิงห์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330519,
        "amphoe_code": 3317,
        "province_code": 33
    },
    {
        "district": "ละลม",
        "amphoe": "ภูสิงห์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330512,
        "amphoe_code": 3317,
        "province_code": 33
    },
    {
        "district": "ห้วยตามอญ",
        "amphoe": "ภูสิงห์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330516,
        "amphoe_code": 3317,
        "province_code": 33
    },
    {
        "district": "ห้วยตึ๊กชู",
        "amphoe": "ภูสิงห์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 331703,
        "amphoe_code": 3317,
        "province_code": 33
    },
    {
        "district": "โคกตาล",
        "amphoe": "ภูสิงห์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 330514,
        "amphoe_code": 3317,
        "province_code": 33
    },
    {
        "district": "ไพรพัฒนา",
        "amphoe": "ภูสิงห์",
        "province": "ศรีสะเกษ",
        "zipcode": 33140,
        "district_code": 331707,
        "amphoe_code": 3317,
        "province_code": 33
    },
    {
        "district": "กุดเมืองฮาม",
        "amphoe": "ยางชุมน้อย",
        "province": "ศรีสะเกษ",
        "zipcode": 33190,
        "district_code": 330205,
        "amphoe_code": 3302,
        "province_code": 33
    },
    {
        "district": "คอนกาม",
        "amphoe": "ยางชุมน้อย",
        "province": "ศรีสะเกษ",
        "zipcode": 33190,
        "district_code": 330199,
        "amphoe_code": 3302,
        "province_code": 33
    },
    {
        "district": "บึงบอน",
        "amphoe": "ยางชุมน้อย",
        "province": "ศรีสะเกษ",
        "zipcode": 33190,
        "district_code": 330206,
        "amphoe_code": 3302,
        "province_code": 33
    },
    {
        "district": "ยางชุมน้อย",
        "amphoe": "ยางชุมน้อย",
        "province": "ศรีสะเกษ",
        "zipcode": 33190,
        "district_code": 330201,
        "amphoe_code": 3302,
        "province_code": 33
    },
    {
        "district": "ยางชุมใหญ่",
        "amphoe": "ยางชุมน้อย",
        "province": "ศรีสะเกษ",
        "zipcode": 33190,
        "district_code": 330207,
        "amphoe_code": 3302,
        "province_code": 33
    },
    {
        "district": "ลิ้นฟ้า",
        "amphoe": "ยางชุมน้อย",
        "province": "ศรีสะเกษ",
        "zipcode": 33190,
        "district_code": 330202,
        "amphoe_code": 3302,
        "province_code": 33
    },
    {
        "district": "โนนคูณ",
        "amphoe": "ยางชุมน้อย",
        "province": "ศรีสะเกษ",
        "zipcode": 33190,
        "district_code": 330204,
        "amphoe_code": 3302,
        "province_code": 33
    },
    {
        "district": "จิกสังข์ทอง",
        "amphoe": "ราษีไศล",
        "province": "ศรีสะเกษ",
        "zipcode": 33160,
        "district_code": 330906,
        "amphoe_code": 3309,
        "province_code": 33
    },
    {
        "district": "ดู่",
        "amphoe": "ราษีไศล",
        "province": "ศรีสะเกษ",
        "zipcode": 33160,
        "district_code": 330313,
        "amphoe_code": 3309,
        "province_code": 33
    },
    {
        "district": "ด่าน",
        "amphoe": "ราษีไศล",
        "province": "ศรีสะเกษ",
        "zipcode": 33160,
        "district_code": 330907,
        "amphoe_code": 3309,
        "province_code": 33
    },
    {
        "district": "บัวหุ่ง",
        "amphoe": "ราษีไศล",
        "province": "ศรีสะเกษ",
        "zipcode": 33160,
        "district_code": 330910,
        "amphoe_code": 3309,
        "province_code": 33
    },
    {
        "district": "สร้างปี่",
        "amphoe": "ราษีไศล",
        "province": "ศรีสะเกษ",
        "zipcode": 33160,
        "district_code": 330915,
        "amphoe_code": 3309,
        "province_code": 33
    },
    {
        "district": "ส้มป่อย",
        "amphoe": "ราษีไศล",
        "province": "ศรีสะเกษ",
        "zipcode": 33160,
        "district_code": 330912,
        "amphoe_code": 3309,
        "province_code": 33
    },
    {
        "district": "หนองหมี",
        "amphoe": "ราษีไศล",
        "province": "ศรีสะเกษ",
        "zipcode": 33160,
        "district_code": 330913,
        "amphoe_code": 3309,
        "province_code": 33
    },
    {
        "district": "หนองอึ่ง",
        "amphoe": "ราษีไศล",
        "province": "ศรีสะเกษ",
        "zipcode": 33160,
        "district_code": 330909,
        "amphoe_code": 3309,
        "province_code": 33
    },
    {
        "district": "หนองแค",
        "amphoe": "ราษีไศล",
        "province": "ศรีสะเกษ",
        "zipcode": 33160,
        "district_code": 330903,
        "amphoe_code": 3309,
        "province_code": 33
    },
    {
        "district": "หว้านคำ",
        "amphoe": "ราษีไศล",
        "province": "ศรีสะเกษ",
        "zipcode": 33160,
        "district_code": 330914,
        "amphoe_code": 3309,
        "province_code": 33
    },
    {
        "district": "เมืองคง",
        "amphoe": "ราษีไศล",
        "province": "ศรีสะเกษ",
        "zipcode": 33160,
        "district_code": 330901,
        "amphoe_code": 3309,
        "province_code": 33
    },
    {
        "district": "เมืองแคน",
        "amphoe": "ราษีไศล",
        "province": "ศรีสะเกษ",
        "zipcode": 33160,
        "district_code": 330902,
        "amphoe_code": 3309,
        "province_code": 33
    },
    {
        "district": "ไผ่",
        "amphoe": "ราษีไศล",
        "province": "ศรีสะเกษ",
        "zipcode": 33160,
        "district_code": 330911,
        "amphoe_code": 3309,
        "province_code": 33
    },
    {
        "district": "ดวนใหญ่",
        "amphoe": "วังหิน",
        "province": "ศรีสะเกษ",
        "zipcode": 33270,
        "district_code": 330120,
        "amphoe_code": 3316,
        "province_code": 33
    },
    {
        "district": "ทุ่งสว่าง",
        "amphoe": "วังหิน",
        "province": "ศรีสะเกษ",
        "zipcode": 33270,
        "district_code": 331606,
        "amphoe_code": 3316,
        "province_code": 33
    },
    {
        "district": "ธาตุ",
        "amphoe": "วังหิน",
        "province": "ศรีสะเกษ",
        "zipcode": 33270,
        "district_code": 330113,
        "amphoe_code": 3316,
        "province_code": 33
    },
    {
        "district": "บุสูง",
        "amphoe": "วังหิน",
        "province": "ศรีสะเกษ",
        "zipcode": 33270,
        "district_code": 330117,
        "amphoe_code": 3316,
        "province_code": 33
    },
    {
        "district": "บ่อแก้ว",
        "amphoe": "วังหิน",
        "province": "ศรีสะเกษ",
        "zipcode": 33270,
        "district_code": 330125,
        "amphoe_code": 3316,
        "province_code": 33
    },
    {
        "district": "วังหิน",
        "amphoe": "วังหิน",
        "province": "ศรีสะเกษ",
        "zipcode": 33270,
        "district_code": 331607,
        "amphoe_code": 3316,
        "province_code": 33
    },
    {
        "district": "ศรีสำราญ",
        "amphoe": "วังหิน",
        "province": "ศรีสะเกษ",
        "zipcode": 33270,
        "district_code": 330126,
        "amphoe_code": 3316,
        "province_code": 33
    },
    {
        "district": "โพนยาง",
        "amphoe": "วังหิน",
        "province": "ศรีสะเกษ",
        "zipcode": 33270,
        "district_code": 331608,
        "amphoe_code": 3316,
        "province_code": 33
    },
    {
        "district": "ตูม",
        "amphoe": "ศรีรัตนะ",
        "province": "ศรีสะเกษ",
        "zipcode": 33240,
        "district_code": 330499,
        "amphoe_code": 3314,
        "province_code": 33
    },
    {
        "district": "พิงพวย",
        "amphoe": "ศรีรัตนะ",
        "province": "ศรีสะเกษ",
        "zipcode": 33240,
        "district_code": 330497,
        "amphoe_code": 3314,
        "province_code": 33
    },
    {
        "district": "ศรีแก้ว",
        "amphoe": "ศรีรัตนะ",
        "province": "ศรีสะเกษ",
        "zipcode": 33240,
        "district_code": 330498,
        "amphoe_code": 3314,
        "province_code": 33
    },
    {
        "district": "ศรีโนนงาม",
        "amphoe": "ศรีรัตนะ",
        "province": "ศรีสะเกษ",
        "zipcode": 33240,
        "district_code": 331406,
        "amphoe_code": 3314,
        "province_code": 33
    },
    {
        "district": "สระเยาว์",
        "amphoe": "ศรีรัตนะ",
        "province": "ศรีสะเกษ",
        "zipcode": 33240,
        "district_code": 330496,
        "amphoe_code": 3314,
        "province_code": 33
    },
    {
        "district": "สะพุง",
        "amphoe": "ศรีรัตนะ",
        "province": "ศรีสะเกษ",
        "zipcode": 33240,
        "district_code": 331407,
        "amphoe_code": 3314,
        "province_code": 33
    },
    {
        "district": "เสื่องข้าว",
        "amphoe": "ศรีรัตนะ",
        "province": "ศรีสะเกษ",
        "zipcode": 33240,
        "district_code": 331405,
        "amphoe_code": 3314,
        "province_code": 33
    },
    {
        "district": "กุง",
        "amphoe": "ศิลาลาด",
        "province": "ศรีสะเกษ",
        "zipcode": 33160,
        "district_code": 330904,
        "amphoe_code": 3322,
        "province_code": 33
    },
    {
        "district": "คลีกลิ้ง",
        "amphoe": "ศิลาลาด",
        "province": "ศรีสะเกษ",
        "zipcode": 33160,
        "district_code": 330905,
        "amphoe_code": 3322,
        "province_code": 33
    },
    {
        "district": "หนองบัวดง",
        "amphoe": "ศิลาลาด",
        "province": "ศรีสะเกษ",
        "zipcode": 33160,
        "district_code": 330917,
        "amphoe_code": 3322,
        "province_code": 33
    },
    {
        "district": "โจดม่วง",
        "amphoe": "ศิลาลาด",
        "province": "ศรีสะเกษ",
        "zipcode": 33160,
        "district_code": 330916,
        "amphoe_code": 3322,
        "province_code": 33
    },
    {
        "district": "กล้วยกว้าง",
        "amphoe": "ห้วยทับทัน",
        "province": "ศรีสะเกษ",
        "zipcode": 33210,
        "district_code": 331097,
        "amphoe_code": 3312,
        "province_code": 33
    },
    {
        "district": "จานแสนไชย",
        "amphoe": "ห้วยทับทัน",
        "province": "ศรีสะเกษ",
        "zipcode": 33210,
        "district_code": 331205,
        "amphoe_code": 3312,
        "province_code": 33
    },
    {
        "district": "ปราสาท",
        "amphoe": "ห้วยทับทัน",
        "province": "ศรีสะเกษ",
        "zipcode": 33210,
        "district_code": 330518,
        "amphoe_code": 3312,
        "province_code": 33
    },
    {
        "district": "ผักไหม",
        "amphoe": "ห้วยทับทัน",
        "province": "ศรีสะเกษ",
        "zipcode": 33210,
        "district_code": 331096,
        "amphoe_code": 3312,
        "province_code": 33
    },
    {
        "district": "ห้วยทับทัน",
        "amphoe": "ห้วยทับทัน",
        "province": "ศรีสะเกษ",
        "zipcode": 33210,
        "district_code": 331098,
        "amphoe_code": 3312,
        "province_code": 33
    },
    {
        "district": "เมืองหลวง",
        "amphoe": "ห้วยทับทัน",
        "province": "ศรีสะเกษ",
        "zipcode": 33210,
        "district_code": 331202,
        "amphoe_code": 3312,
        "province_code": 33
    },
    {
        "district": "กำแพง",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331001,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "ก้านเหลือง",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331003,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "ขะยูง",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331008,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "ตาเกษ",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331010,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "ทุ่งไชย",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331004,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "ปะอาว",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331017,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "รังแร้ง",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331012,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "สระกำแพงใหญ่",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331022,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "สำโรง",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331005,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "หนองห้าง",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331018,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "หนองไฮ",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 330119,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "หัวช้าง",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331011,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "อี่หล่ำ",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331002,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "แขม",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331006,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "แข้",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331015,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "แต้",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331014,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "โคกจาน",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331025,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "โคกหล่าม",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331024,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "โพธิ์ชัย",
        "amphoe": "อุทุมพรพิสัย",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331016,
        "amphoe_code": 3310,
        "province_code": 33
    },
    {
        "district": "ท่าคล้อ",
        "amphoe": "เบญจลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330418,
        "amphoe_code": 3319,
        "province_code": 33
    },
    {
        "district": "หนองงูเหลือม",
        "amphoe": "เบญจลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330417,
        "amphoe_code": 3319,
        "province_code": 33
    },
    {
        "district": "หนองหว้า",
        "amphoe": "เบญจลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330404,
        "amphoe_code": 3319,
        "province_code": 33
    },
    {
        "district": "หนองฮาง",
        "amphoe": "เบญจลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330422,
        "amphoe_code": 3319,
        "province_code": 33
    },
    {
        "district": "เสียว",
        "amphoe": "เบญจลักษ์",
        "province": "ศรีสะเกษ",
        "zipcode": 33110,
        "district_code": 330410,
        "amphoe_code": 3319,
        "province_code": 33
    },
    {
        "district": "ตาโกน",
        "amphoe": "เมืองจันทร์",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331009,
        "amphoe_code": 3318,
        "province_code": 33
    },
    {
        "district": "หนองใหญ่",
        "amphoe": "เมืองจันทร์",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331023,
        "amphoe_code": 3318,
        "province_code": 33
    },
    {
        "district": "เมืองจันทร์",
        "amphoe": "เมืองจันทร์",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331013,
        "amphoe_code": 3318,
        "province_code": 33
    },
    {
        "district": "คูซอด",
        "amphoe": "เมืองศรีสะเกษ",
        "province": "ศรีสะเกษ",
        "zipcode": 33000,
        "district_code": 330103,
        "amphoe_code": 3301,
        "province_code": 33
    },
    {
        "district": "จาน",
        "amphoe": "เมืองศรีสะเกษ",
        "province": "ศรีสะเกษ",
        "zipcode": 33000,
        "district_code": 330105,
        "amphoe_code": 3301,
        "province_code": 33
    },
    {
        "district": "ซำ",
        "amphoe": "เมืองศรีสะเกษ",
        "province": "ศรีสะเกษ",
        "zipcode": 33000,
        "district_code": 330104,
        "amphoe_code": 3301,
        "province_code": 33
    },
    {
        "district": "ตะดอบ",
        "amphoe": "เมืองศรีสะเกษ",
        "province": "ศรีสะเกษ",
        "zipcode": 33000,
        "district_code": 330106,
        "amphoe_code": 3301,
        "province_code": 33
    },
    {
        "district": "ทุ่ม",
        "amphoe": "เมืองศรีสะเกษ",
        "province": "ศรีสะเกษ",
        "zipcode": 33000,
        "district_code": 330118,
        "amphoe_code": 3301,
        "province_code": 33
    },
    {
        "district": "น้ำคำ",
        "amphoe": "เมืองศรีสะเกษ",
        "province": "ศรีสะเกษ",
        "zipcode": 33000,
        "district_code": 330122,
        "amphoe_code": 3301,
        "province_code": 33
    },
    {
        "district": "หญ้าปล้อง",
        "amphoe": "เมืองศรีสะเกษ",
        "province": "ศรีสะเกษ",
        "zipcode": 33000,
        "district_code": 330116,
        "amphoe_code": 3301,
        "province_code": 33
    },
    {
        "district": "หนองครก",
        "amphoe": "เมืองศรีสะเกษ",
        "province": "ศรีสะเกษ",
        "zipcode": 33000,
        "district_code": 330107,
        "amphoe_code": 3301,
        "province_code": 33
    },
    {
        "district": "หนองแก้ว",
        "amphoe": "เมืองศรีสะเกษ",
        "province": "ศรีสะเกษ",
        "zipcode": 33000,
        "district_code": 330121,
        "amphoe_code": 3301,
        "province_code": 33
    },
    {
        "district": "หนองไผ่",
        "amphoe": "เมืองศรีสะเกษ",
        "province": "ศรีสะเกษ",
        "zipcode": 33000,
        "district_code": 330127,
        "amphoe_code": 3301,
        "province_code": 33
    },
    {
        "district": "หนองไฮ",
        "amphoe": "เมืองศรีสะเกษ",
        "province": "ศรีสะเกษ",
        "zipcode": 33000,
        "district_code": 330119,
        "amphoe_code": 3301,
        "province_code": 33
    },
    {
        "district": "หมากเขียบ",
        "amphoe": "เมืองศรีสะเกษ",
        "province": "ศรีสะเกษ",
        "zipcode": 33000,
        "district_code": 330124,
        "amphoe_code": 3301,
        "province_code": 33
    },
    {
        "district": "เมืองเหนือ",
        "amphoe": "เมืองศรีสะเกษ",
        "province": "ศรีสะเกษ",
        "zipcode": 33000,
        "district_code": 330101,
        "amphoe_code": 3301,
        "province_code": 33
    },
    {
        "district": "เมืองใต้",
        "amphoe": "เมืองศรีสะเกษ",
        "province": "ศรีสะเกษ",
        "zipcode": 33000,
        "district_code": 330102,
        "amphoe_code": 3301,
        "province_code": 33
    },
    {
        "district": "โพธิ์",
        "amphoe": "เมืองศรีสะเกษ",
        "province": "ศรีสะเกษ",
        "zipcode": 33000,
        "district_code": 330123,
        "amphoe_code": 3301,
        "province_code": 33
    },
    {
        "district": "โพนข่า",
        "amphoe": "เมืองศรีสะเกษ",
        "province": "ศรีสะเกษ",
        "zipcode": 33000,
        "district_code": 330111,
        "amphoe_code": 3301,
        "province_code": 33
    },
    {
        "district": "โพนค้อ",
        "amphoe": "เมืองศรีสะเกษ",
        "province": "ศรีสะเกษ",
        "zipcode": 33000,
        "district_code": 330112,
        "amphoe_code": 3301,
        "province_code": 33
    },
    {
        "district": "โพนเขวา",
        "amphoe": "เมืองศรีสะเกษ",
        "province": "ศรีสะเกษ",
        "zipcode": 33000,
        "district_code": 330115,
        "amphoe_code": 3301,
        "province_code": 33
    },
    {
        "district": "บก",
        "amphoe": "โนนคูณ",
        "province": "ศรีสะเกษ",
        "zipcode": 33250,
        "district_code": 330398,
        "amphoe_code": 3313,
        "province_code": 33
    },
    {
        "district": "หนองกุง",
        "amphoe": "โนนคูณ",
        "province": "ศรีสะเกษ",
        "zipcode": 33250,
        "district_code": 330396,
        "amphoe_code": 3313,
        "province_code": 33
    },
    {
        "district": "เหล่ากวาง",
        "amphoe": "โนนคูณ",
        "province": "ศรีสะเกษ",
        "zipcode": 33250,
        "district_code": 331305,
        "amphoe_code": 3313,
        "province_code": 33
    },
    {
        "district": "โนนค้อ",
        "amphoe": "โนนคูณ",
        "province": "ศรีสะเกษ",
        "zipcode": 33250,
        "district_code": 330399,
        "amphoe_code": 3313,
        "province_code": 33
    },
    {
        "district": "โพธิ์",
        "amphoe": "โนนคูณ",
        "province": "ศรีสะเกษ",
        "zipcode": 33250,
        "district_code": 330123,
        "amphoe_code": 3313,
        "province_code": 33
    },
    {
        "district": "ผือใหญ่",
        "amphoe": "โพธิ์ศรีสุวรรณ",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331026,
        "amphoe_code": 3321,
        "province_code": 33
    },
    {
        "district": "หนองม้า",
        "amphoe": "โพธิ์ศรีสุวรรณ",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331021,
        "amphoe_code": 3321,
        "province_code": 33
    },
    {
        "district": "อีเซ",
        "amphoe": "โพธิ์ศรีสุวรรณ",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331027,
        "amphoe_code": 3321,
        "province_code": 33
    },
    {
        "district": "เสียว",
        "amphoe": "โพธิ์ศรีสุวรรณ",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 330410,
        "amphoe_code": 3321,
        "province_code": 33
    },
    {
        "district": "โดด",
        "amphoe": "โพธิ์ศรีสุวรรณ",
        "province": "ศรีสะเกษ",
        "zipcode": 33120,
        "district_code": 331019,
        "amphoe_code": 3321,
        "province_code": 33
    },
    {
        "district": "ดินแดง",
        "amphoe": "ไพรบึง",
        "province": "ศรีสะเกษ",
        "zipcode": 33180,
        "district_code": 330602,
        "amphoe_code": 3306,
        "province_code": 33
    },
    {
        "district": "ปราสาทเยอ",
        "amphoe": "ไพรบึง",
        "province": "ศรีสะเกษ",
        "zipcode": 33180,
        "district_code": 330603,
        "amphoe_code": 3306,
        "province_code": 33
    },
    {
        "district": "สำโรงพลัน",
        "amphoe": "ไพรบึง",
        "province": "ศรีสะเกษ",
        "zipcode": 33180,
        "district_code": 330604,
        "amphoe_code": 3306,
        "province_code": 33
    },
    {
        "district": "สุขสวัสดิ์",
        "amphoe": "ไพรบึง",
        "province": "ศรีสะเกษ",
        "zipcode": 33180,
        "district_code": 330605,
        "amphoe_code": 3306,
        "province_code": 33
    },
    {
        "district": "โนนปูน",
        "amphoe": "ไพรบึง",
        "province": "ศรีสะเกษ",
        "zipcode": 33180,
        "district_code": 330606,
        "amphoe_code": 3306,
        "province_code": 33
    },
    {
        "district": "ไพรบึง",
        "amphoe": "ไพรบึง",
        "province": "ศรีสะเกษ",
        "zipcode": 33180,
        "district_code": 330601,
        "amphoe_code": 3306,
        "province_code": 33
    },
    {
        "district": "กุดบาก",
        "amphoe": "กุดบาก",
        "province": "สกลนคร",
        "zipcode": 47180,
        "district_code": 470301,
        "amphoe_code": 4703,
        "province_code": 47
    },
    {
        "district": "กุดไห",
        "amphoe": "กุดบาก",
        "province": "สกลนคร",
        "zipcode": 47180,
        "district_code": 470305,
        "amphoe_code": 4703,
        "province_code": 47
    },
    {
        "district": "นาม่อง",
        "amphoe": "กุดบาก",
        "province": "สกลนคร",
        "zipcode": 47180,
        "district_code": 470303,
        "amphoe_code": 4703,
        "province_code": 47
    },
    {
        "district": "กุสุมาลย์",
        "amphoe": "กุสุมาลย์",
        "province": "สกลนคร",
        "zipcode": 47210,
        "district_code": 470201,
        "amphoe_code": 4702,
        "province_code": 47
    },
    {
        "district": "นาเพียง",
        "amphoe": "กุสุมาลย์",
        "province": "สกลนคร",
        "zipcode": 47230,
        "district_code": 470203,
        "amphoe_code": 4702,
        "province_code": 47
    },
    {
        "district": "นาโพธิ์",
        "amphoe": "กุสุมาลย์",
        "province": "สกลนคร",
        "zipcode": 47210,
        "district_code": 470202,
        "amphoe_code": 4702,
        "province_code": 47
    },
    {
        "district": "อุ่มจาน",
        "amphoe": "กุสุมาลย์",
        "province": "สกลนคร",
        "zipcode": 47230,
        "district_code": 470205,
        "amphoe_code": 4702,
        "province_code": 47
    },
    {
        "district": "โพธิ์ไพศาล",
        "amphoe": "กุสุมาลย์",
        "province": "สกลนคร",
        "zipcode": 47210,
        "district_code": false,
        "amphoe_code": 4702,
        "province_code": 47
    },
    {
        "district": "คำตากล้า",
        "amphoe": "คำตากล้า",
        "province": "สกลนคร",
        "zipcode": 47250,
        "district_code": 470901,
        "amphoe_code": 4709,
        "province_code": 47
    },
    {
        "district": "นาแต้",
        "amphoe": "คำตากล้า",
        "province": "สกลนคร",
        "zipcode": 47250,
        "district_code": 470903,
        "amphoe_code": 4709,
        "province_code": 47
    },
    {
        "district": "หนองบัวสิม",
        "amphoe": "คำตากล้า",
        "province": "สกลนคร",
        "zipcode": 47250,
        "district_code": 470902,
        "amphoe_code": 4709,
        "province_code": 47
    },
    {
        "district": "แพด",
        "amphoe": "คำตากล้า",
        "province": "สกลนคร",
        "zipcode": 47250,
        "district_code": 470904,
        "amphoe_code": 4709,
        "province_code": 47
    },
    {
        "district": "นิคมน้ำอูน",
        "amphoe": "นิคมน้ำอูน",
        "province": "สกลนคร",
        "zipcode": 47270,
        "district_code": 470701,
        "amphoe_code": 4707,
        "province_code": 47
    },
    {
        "district": "สุวรรณคาม",
        "amphoe": "นิคมน้ำอูน",
        "province": "สกลนคร",
        "zipcode": 47270,
        "district_code": 470704,
        "amphoe_code": 4707,
        "province_code": 47
    },
    {
        "district": "หนองบัว",
        "amphoe": "นิคมน้ำอูน",
        "province": "สกลนคร",
        "zipcode": 47270,
        "district_code": 470703,
        "amphoe_code": 4707,
        "province_code": 47
    },
    {
        "district": "หนองปลิง",
        "amphoe": "นิคมน้ำอูน",
        "province": "สกลนคร",
        "zipcode": 47270,
        "district_code": 470702,
        "amphoe_code": 4707,
        "province_code": 47
    },
    {
        "district": "ดงหม้อทอง",
        "amphoe": "บ้านม่วง",
        "province": "สกลนคร",
        "zipcode": 47140,
        "district_code": 471003,
        "amphoe_code": 4710,
        "province_code": 47
    },
    {
        "district": "ดงหม้อทองใต้",
        "amphoe": "บ้านม่วง",
        "province": "สกลนคร",
        "zipcode": 47140,
        "district_code": 471005,
        "amphoe_code": 4710,
        "province_code": 47
    },
    {
        "district": "ดงเหนือ",
        "amphoe": "บ้านม่วง",
        "province": "สกลนคร",
        "zipcode": 47140,
        "district_code": 471004,
        "amphoe_code": 4710,
        "province_code": 47
    },
    {
        "district": "บ่อแก้ว",
        "amphoe": "บ้านม่วง",
        "province": "สกลนคร",
        "zipcode": 47140,
        "district_code": 471009,
        "amphoe_code": 4710,
        "province_code": 47
    },
    {
        "district": "มาย",
        "amphoe": "บ้านม่วง",
        "province": "สกลนคร",
        "zipcode": 47140,
        "district_code": 471002,
        "amphoe_code": 4710,
        "province_code": 47
    },
    {
        "district": "ม่วง",
        "amphoe": "บ้านม่วง",
        "province": "สกลนคร",
        "zipcode": 47140,
        "district_code": 471001,
        "amphoe_code": 4710,
        "province_code": 47
    },
    {
        "district": "หนองกวั่ง",
        "amphoe": "บ้านม่วง",
        "province": "สกลนคร",
        "zipcode": 47140,
        "district_code": 471008,
        "amphoe_code": 4710,
        "province_code": 47
    },
    {
        "district": "ห้วยหลัว",
        "amphoe": "บ้านม่วง",
        "province": "สกลนคร",
        "zipcode": 47140,
        "district_code": 471006,
        "amphoe_code": 4710,
        "province_code": 47
    },
    {
        "district": "โนนสะอาด",
        "amphoe": "บ้านม่วง",
        "province": "สกลนคร",
        "zipcode": 47140,
        "district_code": 471007,
        "amphoe_code": 4710,
        "province_code": 47
    },
    {
        "district": "ช้างมิ่ง",
        "amphoe": "พรรณานิคม",
        "province": "สกลนคร",
        "zipcode": 47130,
        "district_code": 470406,
        "amphoe_code": 4704,
        "province_code": 47
    },
    {
        "district": "นาหัวบ่อ",
        "amphoe": "พรรณานิคม",
        "province": "สกลนคร",
        "zipcode": 47220,
        "district_code": 470404,
        "amphoe_code": 4704,
        "province_code": 47
    },
    {
        "district": "นาใน",
        "amphoe": "พรรณานิคม",
        "province": "สกลนคร",
        "zipcode": 47130,
        "district_code": 470407,
        "amphoe_code": 4704,
        "province_code": 47
    },
    {
        "district": "บะฮี",
        "amphoe": "พรรณานิคม",
        "province": "สกลนคร",
        "zipcode": 47130,
        "district_code": 470409,
        "amphoe_code": 4704,
        "province_code": 47
    },
    {
        "district": "พรรณา",
        "amphoe": "พรรณานิคม",
        "province": "สกลนคร",
        "zipcode": 47130,
        "district_code": 470401,
        "amphoe_code": 4704,
        "province_code": 47
    },
    {
        "district": "พอกน้อย",
        "amphoe": "พรรณานิคม",
        "province": "สกลนคร",
        "zipcode": 47220,
        "district_code": 470403,
        "amphoe_code": 4704,
        "province_code": 47
    },
    {
        "district": "วังยาง",
        "amphoe": "พรรณานิคม",
        "province": "สกลนคร",
        "zipcode": 47130,
        "district_code": 470402,
        "amphoe_code": 4704,
        "province_code": 47
    },
    {
        "district": "สว่าง",
        "amphoe": "พรรณานิคม",
        "province": "สกลนคร",
        "zipcode": 47130,
        "district_code": 470408,
        "amphoe_code": 4704,
        "province_code": 47
    },
    {
        "district": "เชิงชุม",
        "amphoe": "พรรณานิคม",
        "province": "สกลนคร",
        "zipcode": 47130,
        "district_code": 470410,
        "amphoe_code": 4704,
        "province_code": 47
    },
    {
        "district": "ไร่",
        "amphoe": "พรรณานิคม",
        "province": "สกลนคร",
        "zipcode": 47130,
        "district_code": 470405,
        "amphoe_code": 4704,
        "province_code": 47
    },
    {
        "district": "ต้นผึ้ง",
        "amphoe": "พังโคน",
        "province": "สกลนคร",
        "zipcode": 47160,
        "district_code": 470505,
        "amphoe_code": 4705,
        "province_code": 47
    },
    {
        "district": "พังโคน",
        "amphoe": "พังโคน",
        "province": "สกลนคร",
        "zipcode": 47160,
        "district_code": 470501,
        "amphoe_code": 4705,
        "province_code": 47
    },
    {
        "district": "ม่วงไข่",
        "amphoe": "พังโคน",
        "province": "สกลนคร",
        "zipcode": 47160,
        "district_code": 470502,
        "amphoe_code": 4705,
        "province_code": 47
    },
    {
        "district": "แร่",
        "amphoe": "พังโคน",
        "province": "สกลนคร",
        "zipcode": 47160,
        "district_code": 470503,
        "amphoe_code": 4705,
        "province_code": 47
    },
    {
        "district": "ไฮหย่อง",
        "amphoe": "พังโคน",
        "province": "สกลนคร",
        "zipcode": 47160,
        "district_code": 470504,
        "amphoe_code": 4705,
        "province_code": 47
    },
    {
        "district": "กกปลาซิว",
        "amphoe": "ภูพาน",
        "province": "สกลนคร",
        "zipcode": 47180,
        "district_code": 470110,
        "amphoe_code": 4718,
        "province_code": 47
    },
    {
        "district": "สร้างค้อ",
        "amphoe": "ภูพาน",
        "province": "สกลนคร",
        "zipcode": 47180,
        "district_code": 470304,
        "amphoe_code": 4718,
        "province_code": 47
    },
    {
        "district": "หลุบเลา",
        "amphoe": "ภูพาน",
        "province": "สกลนคร",
        "zipcode": 47180,
        "district_code": 470306,
        "amphoe_code": 4718,
        "province_code": 47
    },
    {
        "district": "โคกภู",
        "amphoe": "ภูพาน",
        "province": "สกลนคร",
        "zipcode": 47180,
        "district_code": 470302,
        "amphoe_code": 4718,
        "province_code": 47
    },
    {
        "district": "กุดเรือคำ",
        "amphoe": "วานรนิวาส",
        "province": "สกลนคร",
        "zipcode": 47120,
        "district_code": 470813,
        "amphoe_code": 4708,
        "province_code": 47
    },
    {
        "district": "ขัวก่าย",
        "amphoe": "วานรนิวาส",
        "province": "สกลนคร",
        "zipcode": 47120,
        "district_code": 470803,
        "amphoe_code": 4708,
        "province_code": 47
    },
    {
        "district": "คอนสวรรค์",
        "amphoe": "วานรนิวาส",
        "province": "สกลนคร",
        "zipcode": 47120,
        "district_code": 470812,
        "amphoe_code": 4708,
        "province_code": 47
    },
    {
        "district": "คูสะคาม",
        "amphoe": "วานรนิวาส",
        "province": "สกลนคร",
        "zipcode": 47120,
        "district_code": 470805,
        "amphoe_code": 4708,
        "province_code": 47
    },
    {
        "district": "ธาตุ",
        "amphoe": "วานรนิวาส",
        "province": "สกลนคร",
        "zipcode": 47120,
        "district_code": 470806,
        "amphoe_code": 4708,
        "province_code": 47
    },
    {
        "district": "นาคำ",
        "amphoe": "วานรนิวาส",
        "province": "สกลนคร",
        "zipcode": 47120,
        "district_code": 470811,
        "amphoe_code": 4708,
        "province_code": 47
    },
    {
        "district": "นาซอ",
        "amphoe": "วานรนิวาส",
        "province": "สกลนคร",
        "zipcode": 47120,
        "district_code": 470809,
        "amphoe_code": 4708,
        "province_code": 47
    },
    {
        "district": "วานรนิวาส",
        "amphoe": "วานรนิวาส",
        "province": "สกลนคร",
        "zipcode": 47120,
        "district_code": 470801,
        "amphoe_code": 4708,
        "province_code": 47
    },
    {
        "district": "ศรีวิชัย",
        "amphoe": "วานรนิวาส",
        "province": "สกลนคร",
        "zipcode": 47120,
        "district_code": 470808,
        "amphoe_code": 4708,
        "province_code": 47
    },
    {
        "district": "หนองสนม",
        "amphoe": "วานรนิวาส",
        "province": "สกลนคร",
        "zipcode": 47120,
        "district_code": 470804,
        "amphoe_code": 4708,
        "province_code": 47
    },
    {
        "district": "หนองแวง",
        "amphoe": "วานรนิวาส",
        "province": "สกลนคร",
        "zipcode": 47120,
        "district_code": 470807,
        "amphoe_code": 4708,
        "province_code": 47
    },
    {
        "district": "หนองแวงใต้",
        "amphoe": "วานรนิวาส",
        "province": "สกลนคร",
        "zipcode": 47120,
        "district_code": 470814,
        "amphoe_code": 4708,
        "province_code": 47
    },
    {
        "district": "อินทร์แปลง",
        "amphoe": "วานรนิวาส",
        "province": "สกลนคร",
        "zipcode": 47120,
        "district_code": 470810,
        "amphoe_code": 4708,
        "province_code": 47
    },
    {
        "district": "เดื่อศรีคันไชย",
        "amphoe": "วานรนิวาส",
        "province": "สกลนคร",
        "zipcode": 47120,
        "district_code": 470802,
        "amphoe_code": 4708,
        "province_code": 47
    },
    {
        "district": "คำบ่อ",
        "amphoe": "วาริชภูมิ",
        "province": "สกลนคร",
        "zipcode": 47150,
        "district_code": 470604,
        "amphoe_code": 4706,
        "province_code": 47
    },
    {
        "district": "ค้อเขียว",
        "amphoe": "วาริชภูมิ",
        "province": "สกลนคร",
        "zipcode": 47150,
        "district_code": 470605,
        "amphoe_code": 4706,
        "province_code": 47
    },
    {
        "district": "ปลาโหล",
        "amphoe": "วาริชภูมิ",
        "province": "สกลนคร",
        "zipcode": 47150,
        "district_code": 470602,
        "amphoe_code": 4706,
        "province_code": 47
    },
    {
        "district": "วาริชภูมิ",
        "amphoe": "วาริชภูมิ",
        "province": "สกลนคร",
        "zipcode": 47150,
        "district_code": 470601,
        "amphoe_code": 4706,
        "province_code": 47
    },
    {
        "district": "หนองลาด",
        "amphoe": "วาริชภูมิ",
        "province": "สกลนคร",
        "zipcode": 47150,
        "district_code": 470118,
        "amphoe_code": 4706,
        "province_code": 47
    },
    {
        "district": "คำสะอาด",
        "amphoe": "สว่างแดนดิน",
        "province": "สกลนคร",
        "zipcode": 47110,
        "district_code": 471203,
        "amphoe_code": 4712,
        "province_code": 47
    },
    {
        "district": "ค้อใต้",
        "amphoe": "สว่างแดนดิน",
        "province": "สกลนคร",
        "zipcode": 47110,
        "district_code": 471212,
        "amphoe_code": 4712,
        "province_code": 47
    },
    {
        "district": "ตาลเนิ้ง",
        "amphoe": "สว่างแดนดิน",
        "province": "สกลนคร",
        "zipcode": 47240,
        "district_code": 471217,
        "amphoe_code": 4712,
        "province_code": 47
    },
    {
        "district": "ตาลโกน",
        "amphoe": "สว่างแดนดิน",
        "province": "สกลนคร",
        "zipcode": 47240,
        "district_code": 471216,
        "amphoe_code": 4712,
        "province_code": 47
    },
    {
        "district": "ทรายมูล",
        "amphoe": "สว่างแดนดิน",
        "province": "สกลนคร",
        "zipcode": 47110,
        "district_code": 471215,
        "amphoe_code": 4712,
        "province_code": 47
    },
    {
        "district": "ธาตุทอง",
        "amphoe": "สว่างแดนดิน",
        "province": "สกลนคร",
        "zipcode": 47240,
        "district_code": 471220,
        "amphoe_code": 4712,
        "province_code": 47
    },
    {
        "district": "บงเหนือ",
        "amphoe": "สว่างแดนดิน",
        "province": "สกลนคร",
        "zipcode": 47110,
        "district_code": 471206,
        "amphoe_code": 4712,
        "province_code": 47
    },
    {
        "district": "บงใต้",
        "amphoe": "สว่างแดนดิน",
        "province": "สกลนคร",
        "zipcode": 47110,
        "district_code": 471211,
        "amphoe_code": 4712,
        "province_code": 47
    },
    {
        "district": "บ้านต้าย",
        "amphoe": "สว่างแดนดิน",
        "province": "สกลนคร",
        "zipcode": 47110,
        "district_code": 471204,
        "amphoe_code": 4712,
        "province_code": 47
    },
    {
        "district": "บ้านถ่อน",
        "amphoe": "สว่างแดนดิน",
        "province": "สกลนคร",
        "zipcode": 47110,
        "district_code": 471221,
        "amphoe_code": 4712,
        "province_code": 47
    },
    {
        "district": "พันนา",
        "amphoe": "สว่างแดนดิน",
        "province": "สกลนคร",
        "zipcode": 47240,
        "district_code": 471213,
        "amphoe_code": 4712,
        "province_code": 47
    },
    {
        "district": "สว่างแดนดิน",
        "amphoe": "สว่างแดนดิน",
        "province": "สกลนคร",
        "zipcode": 47110,
        "district_code": 471201,
        "amphoe_code": 4712,
        "province_code": 47
    },
    {
        "district": "หนองหลวง",
        "amphoe": "สว่างแดนดิน",
        "province": "สกลนคร",
        "zipcode": 47110,
        "district_code": 471210,
        "amphoe_code": 4712,
        "province_code": 47
    },
    {
        "district": "แวง",
        "amphoe": "สว่างแดนดิน",
        "province": "สกลนคร",
        "zipcode": 47240,
        "district_code": 471214,
        "amphoe_code": 4712,
        "province_code": 47
    },
    {
        "district": "โคกสี",
        "amphoe": "สว่างแดนดิน",
        "province": "สกลนคร",
        "zipcode": 47110,
        "district_code": 471208,
        "amphoe_code": 4712,
        "province_code": 47
    },
    {
        "district": "โพนสูง",
        "amphoe": "สว่างแดนดิน",
        "province": "สกลนคร",
        "zipcode": 47110,
        "district_code": 471207,
        "amphoe_code": 4712,
        "province_code": 47
    },
    {
        "district": "ท่าศิลา",
        "amphoe": "ส่องดาว",
        "province": "สกลนคร",
        "zipcode": 47190,
        "district_code": 471302,
        "amphoe_code": 4713,
        "province_code": 47
    },
    {
        "district": "ปทุมวาปี",
        "amphoe": "ส่องดาว",
        "province": "สกลนคร",
        "zipcode": 47190,
        "district_code": 471304,
        "amphoe_code": 4713,
        "province_code": 47
    },
    {
        "district": "วัฒนา",
        "amphoe": "ส่องดาว",
        "province": "สกลนคร",
        "zipcode": 47190,
        "district_code": 471303,
        "amphoe_code": 4713,
        "province_code": 47
    },
    {
        "district": "ส่องดาว",
        "amphoe": "ส่องดาว",
        "province": "สกลนคร",
        "zipcode": 47190,
        "district_code": 471301,
        "amphoe_code": 4713,
        "province_code": 47
    },
    {
        "district": "ท่าก้อน",
        "amphoe": "อากาศอำนวย",
        "province": "สกลนคร",
        "zipcode": 47170,
        "district_code": 471105,
        "amphoe_code": 4711,
        "province_code": 47
    },
    {
        "district": "นาฮี",
        "amphoe": "อากาศอำนวย",
        "province": "สกลนคร",
        "zipcode": 47170,
        "district_code": 471106,
        "amphoe_code": 4711,
        "province_code": 47
    },
    {
        "district": "บะหว้า",
        "amphoe": "อากาศอำนวย",
        "province": "สกลนคร",
        "zipcode": 47170,
        "district_code": 471107,
        "amphoe_code": 4711,
        "province_code": 47
    },
    {
        "district": "วาใหญ่",
        "amphoe": "อากาศอำนวย",
        "province": "สกลนคร",
        "zipcode": 47170,
        "district_code": 471103,
        "amphoe_code": 4711,
        "province_code": 47
    },
    {
        "district": "สามัคคีพัฒนา",
        "amphoe": "อากาศอำนวย",
        "province": "สกลนคร",
        "zipcode": 47170,
        "district_code": 471108,
        "amphoe_code": 4711,
        "province_code": 47
    },
    {
        "district": "อากาศ",
        "amphoe": "อากาศอำนวย",
        "province": "สกลนคร",
        "zipcode": 47170,
        "district_code": 471101,
        "amphoe_code": 4711,
        "province_code": 47
    },
    {
        "district": "โพนงาม",
        "amphoe": "อากาศอำนวย",
        "province": "สกลนคร",
        "zipcode": 47170,
        "district_code": 471104,
        "amphoe_code": 4711,
        "province_code": 47
    },
    {
        "district": "โพนแพง",
        "amphoe": "อากาศอำนวย",
        "province": "สกลนคร",
        "zipcode": 47170,
        "district_code": 471102,
        "amphoe_code": 4711,
        "province_code": 47
    },
    {
        "district": "ทุ่งแก",
        "amphoe": "เจริญศิลป์",
        "province": "สกลนคร",
        "zipcode": 47290,
        "district_code": 471209,
        "amphoe_code": 4716,
        "province_code": 47
    },
    {
        "district": "บ้านเหล่า",
        "amphoe": "เจริญศิลป์",
        "province": "สกลนคร",
        "zipcode": 47290,
        "district_code": 471202,
        "amphoe_code": 4716,
        "province_code": 47
    },
    {
        "district": "หนองแปน",
        "amphoe": "เจริญศิลป์",
        "province": "สกลนคร",
        "zipcode": 47290,
        "district_code": 471219,
        "amphoe_code": 4716,
        "province_code": 47
    },
    {
        "district": "เจริญศิลป์",
        "amphoe": "เจริญศิลป์",
        "province": "สกลนคร",
        "zipcode": 47290,
        "district_code": 471205,
        "amphoe_code": 4716,
        "province_code": 47
    },
    {
        "district": "โคกศิลา",
        "amphoe": "เจริญศิลป์",
        "province": "สกลนคร",
        "zipcode": 47290,
        "district_code": 471218,
        "amphoe_code": 4716,
        "province_code": 47
    },
    {
        "district": "จันทร์เพ็ญ",
        "amphoe": "เต่างอย",
        "province": "สกลนคร",
        "zipcode": 47260,
        "district_code": 471404,
        "amphoe_code": 4714,
        "province_code": 47
    },
    {
        "district": "นาตาล",
        "amphoe": "เต่างอย",
        "province": "สกลนคร",
        "zipcode": 47260,
        "district_code": 471403,
        "amphoe_code": 4714,
        "province_code": 47
    },
    {
        "district": "บึงทวาย",
        "amphoe": "เต่างอย",
        "province": "สกลนคร",
        "zipcode": 47260,
        "district_code": 471402,
        "amphoe_code": 4714,
        "province_code": 47
    },
    {
        "district": "เต่างอย",
        "amphoe": "เต่างอย",
        "province": "สกลนคร",
        "zipcode": 47260,
        "district_code": 470199,
        "amphoe_code": 4714,
        "province_code": 47
    },
    {
        "district": "ขมิ้น",
        "amphoe": "เมืองสกลนคร",
        "province": "สกลนคร",
        "zipcode": 47220,
        "district_code": 470102,
        "amphoe_code": 4701,
        "province_code": 47
    },
    {
        "district": "งิ้วด่อน",
        "amphoe": "เมืองสกลนคร",
        "province": "สกลนคร",
        "zipcode": 47000,
        "district_code": 470103,
        "amphoe_code": 4701,
        "province_code": 47
    },
    {
        "district": "ดงชน",
        "amphoe": "เมืองสกลนคร",
        "province": "สกลนคร",
        "zipcode": 47000,
        "district_code": 470111,
        "amphoe_code": 4701,
        "province_code": 47
    },
    {
        "district": "ดงมะไฟ",
        "amphoe": "เมืองสกลนคร",
        "province": "สกลนคร",
        "zipcode": 47000,
        "district_code": 470115,
        "amphoe_code": 4701,
        "province_code": 47
    },
    {
        "district": "ท่าแร่",
        "amphoe": "เมืองสกลนคร",
        "province": "สกลนคร",
        "zipcode": 47230,
        "district_code": 470107,
        "amphoe_code": 4701,
        "province_code": 47
    },
    {
        "district": "ธาตุนาเวง",
        "amphoe": "เมืองสกลนคร",
        "province": "สกลนคร",
        "zipcode": 47000,
        "district_code": 470116,
        "amphoe_code": 4701,
        "province_code": 47
    },
    {
        "district": "ธาตุเชิงชุม",
        "amphoe": "เมืองสกลนคร",
        "province": "สกลนคร",
        "zipcode": 47000,
        "district_code": 470101,
        "amphoe_code": 4701,
        "province_code": 47
    },
    {
        "district": "พังขว้าง",
        "amphoe": "เมืองสกลนคร",
        "province": "สกลนคร",
        "zipcode": 47000,
        "district_code": 470113,
        "amphoe_code": 4701,
        "province_code": 47
    },
    {
        "district": "ม่วงลาย",
        "amphoe": "เมืองสกลนคร",
        "province": "สกลนคร",
        "zipcode": 47000,
        "district_code": 470109,
        "amphoe_code": 4701,
        "province_code": 47
    },
    {
        "district": "หนองลาด",
        "amphoe": "เมืองสกลนคร",
        "province": "สกลนคร",
        "zipcode": 47220,
        "district_code": 470118,
        "amphoe_code": 4701,
        "province_code": 47
    },
    {
        "district": "ห้วยยาง",
        "amphoe": "เมืองสกลนคร",
        "province": "สกลนคร",
        "zipcode": 47000,
        "district_code": 470112,
        "amphoe_code": 4701,
        "province_code": 47
    },
    {
        "district": "ฮางโฮง",
        "amphoe": "เมืองสกลนคร",
        "province": "สกลนคร",
        "zipcode": 47000,
        "district_code": 470120,
        "amphoe_code": 4701,
        "province_code": 47
    },
    {
        "district": "เชียงเครือ",
        "amphoe": "เมืองสกลนคร",
        "province": "สกลนคร",
        "zipcode": 47000,
        "district_code": 470106,
        "amphoe_code": 4701,
        "province_code": 47
    },
    {
        "district": "เหล่าปอแดง",
        "amphoe": "เมืองสกลนคร",
        "province": "สกลนคร",
        "zipcode": 47000,
        "district_code": 470117,
        "amphoe_code": 4701,
        "province_code": 47
    },
    {
        "district": "โคกก่อง",
        "amphoe": "เมืองสกลนคร",
        "province": "สกลนคร",
        "zipcode": 47000,
        "district_code": 470121,
        "amphoe_code": 4701,
        "province_code": 47
    },
    {
        "district": "โนนหอม",
        "amphoe": "เมืองสกลนคร",
        "province": "สกลนคร",
        "zipcode": 47000,
        "district_code": 470104,
        "amphoe_code": 4701,
        "province_code": 47
    },
    {
        "district": "ด่านม่วงคำ",
        "amphoe": "โคกศรีสุพรรณ",
        "province": "สกลนคร",
        "zipcode": 47280,
        "district_code": 471503,
        "amphoe_code": 4715,
        "province_code": 47
    },
    {
        "district": "ตองโขบ",
        "amphoe": "โคกศรีสุพรรณ",
        "province": "สกลนคร",
        "zipcode": 47280,
        "district_code": 470198,
        "amphoe_code": 4715,
        "province_code": 47
    },
    {
        "district": "เหล่าโพนค้อ",
        "amphoe": "โคกศรีสุพรรณ",
        "province": "สกลนคร",
        "zipcode": 47280,
        "district_code": 470197,
        "amphoe_code": 4715,
        "province_code": 47
    },
    {
        "district": "แมดนาท่ม",
        "amphoe": "โคกศรีสุพรรณ",
        "province": "สกลนคร",
        "zipcode": 47280,
        "district_code": 471504,
        "amphoe_code": 4715,
        "province_code": 47
    },
    {
        "district": "นาตงวัฒนา",
        "amphoe": "โพนนาแก้ว",
        "province": "สกลนคร",
        "zipcode": 47230,
        "district_code": 470105,
        "amphoe_code": 4717,
        "province_code": 47
    },
    {
        "district": "นาแก้ว",
        "amphoe": "โพนนาแก้ว",
        "province": "สกลนคร",
        "zipcode": 47230,
        "district_code": 470114,
        "amphoe_code": 4717,
        "province_code": 47
    },
    {
        "district": "บ้านแป้น",
        "amphoe": "โพนนาแก้ว",
        "province": "สกลนคร",
        "zipcode": 47230,
        "district_code": 470119,
        "amphoe_code": 4717,
        "province_code": 47
    },
    {
        "district": "บ้านโพน",
        "amphoe": "โพนนาแก้ว",
        "province": "สกลนคร",
        "zipcode": 47230,
        "district_code": 470108,
        "amphoe_code": 4717,
        "province_code": 47
    },
    {
        "district": "เชียงสือ",
        "amphoe": "โพนนาแก้ว",
        "province": "สกลนคร",
        "zipcode": 47230,
        "district_code": 471705,
        "amphoe_code": 4717,
        "province_code": 47
    },
    {
        "district": "กระแสสินธุ์",
        "amphoe": "กระแสสินธุ์",
        "province": "สงขลา",
        "zipcode": 90270,
        "district_code": 900804,
        "amphoe_code": 9008,
        "province_code": 90
    },
    {
        "district": "เกาะใหญ่",
        "amphoe": "กระแสสินธุ์",
        "province": "สงขลา",
        "zipcode": 90270,
        "district_code": 900799,
        "amphoe_code": 9008,
        "province_code": 90
    },
    {
        "district": "เชิงแส",
        "amphoe": "กระแสสินธุ์",
        "province": "สงขลา",
        "zipcode": 90270,
        "district_code": 900797,
        "amphoe_code": 9008,
        "province_code": 90
    },
    {
        "district": "โรง",
        "amphoe": "กระแสสินธุ์",
        "province": "สงขลา",
        "zipcode": 90270,
        "district_code": 900798,
        "amphoe_code": 9008,
        "province_code": 90
    },
    {
        "district": "คลองหลา",
        "amphoe": "คลองหอยโข่ง",
        "province": "สงขลา",
        "zipcode": 90115,
        "district_code": 901604,
        "amphoe_code": 9016,
        "province_code": 90
    },
    {
        "district": "คลองหอยโข่ง",
        "amphoe": "คลองหอยโข่ง",
        "province": "สงขลา",
        "zipcode": 90230,
        "district_code": 901106,
        "amphoe_code": 9016,
        "province_code": 90
    },
    {
        "district": "ทุ่งลาน",
        "amphoe": "คลองหอยโข่ง",
        "province": "สงขลา",
        "zipcode": 90230,
        "district_code": 901109,
        "amphoe_code": 9016,
        "province_code": 90
    },
    {
        "district": "โคกม่วง",
        "amphoe": "คลองหอยโข่ง",
        "province": "สงขลา",
        "zipcode": 90230,
        "district_code": 901121,
        "amphoe_code": 9016,
        "province_code": 90
    },
    {
        "district": "โคกม่วง",
        "amphoe": "คลองหอยโข่ง",
        "province": "สงขลา",
        "zipcode": 90115,
        "district_code": 901121,
        "amphoe_code": 9016,
        "province_code": 90
    },
    {
        "district": "ควนโส",
        "amphoe": "ควนเนียง",
        "province": "สงขลา",
        "zipcode": 90220,
        "district_code": 900905,
        "amphoe_code": 9013,
        "province_code": 90
    },
    {
        "district": "บางเหรียง",
        "amphoe": "ควนเนียง",
        "province": "สงขลา",
        "zipcode": 90220,
        "district_code": 900907,
        "amphoe_code": 9013,
        "province_code": 90
    },
    {
        "district": "รัตภูมิ",
        "amphoe": "ควนเนียง",
        "province": "สงขลา",
        "zipcode": 90220,
        "district_code": 900906,
        "amphoe_code": 9013,
        "province_code": 90
    },
    {
        "district": "ห้วยลึก",
        "amphoe": "ควนเนียง",
        "province": "สงขลา",
        "zipcode": 90220,
        "district_code": 900908,
        "amphoe_code": 9013,
        "province_code": 90
    },
    {
        "district": "ขุนตัดหวาย",
        "amphoe": "จะนะ",
        "province": "สงขลา",
        "zipcode": 90130,
        "district_code": 900308,
        "amphoe_code": 9003,
        "province_code": 90
    },
    {
        "district": "คลองเปียะ",
        "amphoe": "จะนะ",
        "province": "สงขลา",
        "zipcode": 90130,
        "district_code": 900313,
        "amphoe_code": 9003,
        "province_code": 90
    },
    {
        "district": "คู",
        "amphoe": "จะนะ",
        "province": "สงขลา",
        "zipcode": 90130,
        "district_code": 900311,
        "amphoe_code": 9003,
        "province_code": 90
    },
    {
        "district": "จะโหนง",
        "amphoe": "จะนะ",
        "province": "สงขลา",
        "zipcode": 90130,
        "district_code": 900310,
        "amphoe_code": 9003,
        "province_code": 90
    },
    {
        "district": "ตลิ่งชัน",
        "amphoe": "จะนะ",
        "province": "สงขลา",
        "zipcode": 90130,
        "district_code": 900314,
        "amphoe_code": 9003,
        "province_code": 90
    },
    {
        "district": "ท่าหมอไทร",
        "amphoe": "จะนะ",
        "province": "สงขลา",
        "zipcode": 90130,
        "district_code": 900309,
        "amphoe_code": 9003,
        "province_code": 90
    },
    {
        "district": "นาทับ",
        "amphoe": "จะนะ",
        "province": "สงขลา",
        "zipcode": 90130,
        "district_code": 900306,
        "amphoe_code": 9003,
        "province_code": 90
    },
    {
        "district": "นาหว้า",
        "amphoe": "จะนะ",
        "province": "สงขลา",
        "zipcode": 90130,
        "district_code": 900305,
        "amphoe_code": 9003,
        "province_code": 90
    },
    {
        "district": "น้ำขาว",
        "amphoe": "จะนะ",
        "province": "สงขลา",
        "zipcode": 90130,
        "district_code": 900307,
        "amphoe_code": 9003,
        "province_code": 90
    },
    {
        "district": "บ้านนา",
        "amphoe": "จะนะ",
        "province": "สงขลา",
        "zipcode": 90130,
        "district_code": 900301,
        "amphoe_code": 9003,
        "province_code": 90
    },
    {
        "district": "ป่าชิง",
        "amphoe": "จะนะ",
        "province": "สงขลา",
        "zipcode": 90130,
        "district_code": 900302,
        "amphoe_code": 9003,
        "province_code": 90
    },
    {
        "district": "สะกอม",
        "amphoe": "จะนะ",
        "province": "สงขลา",
        "zipcode": 90130,
        "district_code": 900304,
        "amphoe_code": 9003,
        "province_code": 90
    },
    {
        "district": "สะพานไม้แก่น",
        "amphoe": "จะนะ",
        "province": "สงขลา",
        "zipcode": 90130,
        "district_code": 900303,
        "amphoe_code": 9003,
        "province_code": 90
    },
    {
        "district": "แค",
        "amphoe": "จะนะ",
        "province": "สงขลา",
        "zipcode": 90130,
        "district_code": 900312,
        "amphoe_code": 9003,
        "province_code": 90
    },
    {
        "district": "คลองกวาง",
        "amphoe": "นาทวี",
        "province": "สงขลา",
        "zipcode": 90160,
        "district_code": 900410,
        "amphoe_code": 9004,
        "province_code": 90
    },
    {
        "district": "คลองทราย",
        "amphoe": "นาทวี",
        "province": "สงขลา",
        "zipcode": 90160,
        "district_code": 900404,
        "amphoe_code": 9004,
        "province_code": 90
    },
    {
        "district": "ฉาง",
        "amphoe": "นาทวี",
        "province": "สงขลา",
        "zipcode": 90160,
        "district_code": 900402,
        "amphoe_code": 9004,
        "province_code": 90
    },
    {
        "district": "ทับช้าง",
        "amphoe": "นาทวี",
        "province": "สงขลา",
        "zipcode": 90160,
        "district_code": 900408,
        "amphoe_code": 9004,
        "province_code": 90
    },
    {
        "district": "ท่าประดู่",
        "amphoe": "นาทวี",
        "province": "สงขลา",
        "zipcode": 90160,
        "district_code": 900406,
        "amphoe_code": 9004,
        "province_code": 90
    },
    {
        "district": "นาทวี",
        "amphoe": "นาทวี",
        "province": "สงขลา",
        "zipcode": 90160,
        "district_code": 900401,
        "amphoe_code": 9004,
        "province_code": 90
    },
    {
        "district": "นาหมอศรี",
        "amphoe": "นาทวี",
        "province": "สงขลา",
        "zipcode": 90160,
        "district_code": 900403,
        "amphoe_code": 9004,
        "province_code": 90
    },
    {
        "district": "ประกอบ",
        "amphoe": "นาทวี",
        "province": "สงขลา",
        "zipcode": 90160,
        "district_code": 900409,
        "amphoe_code": 9004,
        "province_code": 90
    },
    {
        "district": "ปลักหนู",
        "amphoe": "นาทวี",
        "province": "สงขลา",
        "zipcode": 90160,
        "district_code": 900405,
        "amphoe_code": 9004,
        "province_code": 90
    },
    {
        "district": "สะท้อน",
        "amphoe": "นาทวี",
        "province": "สงขลา",
        "zipcode": 90160,
        "district_code": 900407,
        "amphoe_code": 9004,
        "province_code": 90
    },
    {
        "district": "คลองหรัง",
        "amphoe": "นาหม่อม",
        "province": "สงขลา",
        "zipcode": 90310,
        "district_code": 901196,
        "amphoe_code": 9012,
        "province_code": 90
    },
    {
        "district": "ทุ่งขมิ้น",
        "amphoe": "นาหม่อม",
        "province": "สงขลา",
        "zipcode": 90310,
        "district_code": 901197,
        "amphoe_code": 9012,
        "province_code": 90
    },
    {
        "district": "นาหม่อม",
        "amphoe": "นาหม่อม",
        "province": "สงขลา",
        "zipcode": 90310,
        "district_code": 901199,
        "amphoe_code": 9012,
        "province_code": 90
    },
    {
        "district": "พิจิตร",
        "amphoe": "นาหม่อม",
        "province": "สงขลา",
        "zipcode": 90310,
        "district_code": 901198,
        "amphoe_code": 9012,
        "province_code": 90
    },
    {
        "district": "ท่าช้าง",
        "amphoe": "บางกล่ำ",
        "province": "สงขลา",
        "zipcode": 90110,
        "district_code": 901110,
        "amphoe_code": 9014,
        "province_code": 90
    },
    {
        "district": "บางกล่ำ",
        "amphoe": "บางกล่ำ",
        "province": "สงขลา",
        "zipcode": 90110,
        "district_code": 901115,
        "amphoe_code": 9014,
        "province_code": 90
    },
    {
        "district": "บ้านหาร",
        "amphoe": "บางกล่ำ",
        "province": "สงขลา",
        "zipcode": 90110,
        "district_code": 901117,
        "amphoe_code": 9014,
        "province_code": 90
    },
    {
        "district": "แม่ทอม",
        "amphoe": "บางกล่ำ",
        "province": "สงขลา",
        "zipcode": 90110,
        "district_code": 901119,
        "amphoe_code": 9014,
        "province_code": 90
    },
    {
        "district": "คลองแดน",
        "amphoe": "ระโนด",
        "province": "สงขลา",
        "zipcode": 90140,
        "district_code": 900702,
        "amphoe_code": 9007,
        "province_code": 90
    },
    {
        "district": "ตะเครียะ",
        "amphoe": "ระโนด",
        "province": "สงขลา",
        "zipcode": 90140,
        "district_code": 900703,
        "amphoe_code": 9007,
        "province_code": 90
    },
    {
        "district": "ท่าบอน",
        "amphoe": "ระโนด",
        "province": "สงขลา",
        "zipcode": 90140,
        "district_code": 900704,
        "amphoe_code": 9007,
        "province_code": 90
    },
    {
        "district": "บ่อตรุ",
        "amphoe": "ระโนด",
        "province": "สงขลา",
        "zipcode": 90140,
        "district_code": 900706,
        "amphoe_code": 9007,
        "province_code": 90
    },
    {
        "district": "บ้านขาว",
        "amphoe": "ระโนด",
        "province": "สงขลา",
        "zipcode": 90140,
        "district_code": 900711,
        "amphoe_code": 9007,
        "province_code": 90
    },
    {
        "district": "บ้านใหม่",
        "amphoe": "ระโนด",
        "province": "สงขลา",
        "zipcode": 90140,
        "district_code": 900705,
        "amphoe_code": 9007,
        "province_code": 90
    },
    {
        "district": "ปากแตระ",
        "amphoe": "ระโนด",
        "province": "สงขลา",
        "zipcode": 90140,
        "district_code": 900707,
        "amphoe_code": 9007,
        "province_code": 90
    },
    {
        "district": "พังยาง",
        "amphoe": "ระโนด",
        "province": "สงขลา",
        "zipcode": 90140,
        "district_code": 900708,
        "amphoe_code": 9007,
        "province_code": 90
    },
    {
        "district": "ระวะ",
        "amphoe": "ระโนด",
        "province": "สงขลา",
        "zipcode": 90140,
        "district_code": 900709,
        "amphoe_code": 9007,
        "province_code": 90
    },
    {
        "district": "ระโนด",
        "amphoe": "ระโนด",
        "province": "สงขลา",
        "zipcode": 90140,
        "district_code": 900701,
        "amphoe_code": 9007,
        "province_code": 90
    },
    {
        "district": "วัดสน",
        "amphoe": "ระโนด",
        "province": "สงขลา",
        "zipcode": 90140,
        "district_code": 900710,
        "amphoe_code": 9007,
        "province_code": 90
    },
    {
        "district": "แดนสงวน",
        "amphoe": "ระโนด",
        "province": "สงขลา",
        "zipcode": 90140,
        "district_code": 900712,
        "amphoe_code": 9007,
        "province_code": 90
    },
    {
        "district": "กำแพงเพชร",
        "amphoe": "รัตภูมิ",
        "province": "สงขลา",
        "zipcode": 90180,
        "district_code": 900901,
        "amphoe_code": 9009,
        "province_code": 90
    },
    {
        "district": "ควนรู",
        "amphoe": "รัตภูมิ",
        "province": "สงขลา",
        "zipcode": 90220,
        "district_code": 900904,
        "amphoe_code": 9009,
        "province_code": 90
    },
    {
        "district": "คูหาใต้",
        "amphoe": "รัตภูมิ",
        "province": "สงขลา",
        "zipcode": 90180,
        "district_code": 900903,
        "amphoe_code": 9009,
        "province_code": 90
    },
    {
        "district": "ท่าชะมวง",
        "amphoe": "รัตภูมิ",
        "province": "สงขลา",
        "zipcode": 90180,
        "district_code": 900902,
        "amphoe_code": 9009,
        "province_code": 90
    },
    {
        "district": "เขาพระ",
        "amphoe": "รัตภูมิ",
        "province": "สงขลา",
        "zipcode": 90180,
        "district_code": 900909,
        "amphoe_code": 9009,
        "province_code": 90
    },
    {
        "district": "กระดังงา",
        "amphoe": "สทิงพระ",
        "province": "สงขลา",
        "zipcode": 90190,
        "district_code": 900202,
        "amphoe_code": 9002,
        "province_code": 90
    },
    {
        "district": "คลองรี",
        "amphoe": "สทิงพระ",
        "province": "สงขลา",
        "zipcode": 90190,
        "district_code": 900206,
        "amphoe_code": 9002,
        "province_code": 90
    },
    {
        "district": "คูขุด",
        "amphoe": "สทิงพระ",
        "province": "สงขลา",
        "zipcode": 90190,
        "district_code": 900207,
        "amphoe_code": 9002,
        "province_code": 90
    },
    {
        "district": "จะทิ้งพระ",
        "amphoe": "สทิงพระ",
        "province": "สงขลา",
        "zipcode": 90190,
        "district_code": 900201,
        "amphoe_code": 9002,
        "province_code": 90
    },
    {
        "district": "ชุมพล",
        "amphoe": "สทิงพระ",
        "province": "สงขลา",
        "zipcode": 90190,
        "district_code": 900205,
        "amphoe_code": 9002,
        "province_code": 90
    },
    {
        "district": "ดีหลวง",
        "amphoe": "สทิงพระ",
        "province": "สงขลา",
        "zipcode": 90190,
        "district_code": 900204,
        "amphoe_code": 9002,
        "province_code": 90
    },
    {
        "district": "ท่าหิน",
        "amphoe": "สทิงพระ",
        "province": "สงขลา",
        "zipcode": 90190,
        "district_code": 900208,
        "amphoe_code": 9002,
        "province_code": 90
    },
    {
        "district": "บ่อดาน",
        "amphoe": "สทิงพระ",
        "province": "สงขลา",
        "zipcode": 90190,
        "district_code": 900211,
        "amphoe_code": 9002,
        "province_code": 90
    },
    {
        "district": "บ่อแดง",
        "amphoe": "สทิงพระ",
        "province": "สงขลา",
        "zipcode": 90190,
        "district_code": 900210,
        "amphoe_code": 9002,
        "province_code": 90
    },
    {
        "district": "วัดจันทร์",
        "amphoe": "สทิงพระ",
        "province": "สงขลา",
        "zipcode": 90190,
        "district_code": 900209,
        "amphoe_code": 9002,
        "province_code": 90
    },
    {
        "district": "สนามชัย",
        "amphoe": "สทิงพระ",
        "province": "สงขลา",
        "zipcode": 90190,
        "district_code": 900203,
        "amphoe_code": 9002,
        "province_code": 90
    },
    {
        "district": "คูหา",
        "amphoe": "สะบ้าย้อย",
        "province": "สงขลา",
        "zipcode": 90210,
        "district_code": 900606,
        "amphoe_code": 9006,
        "province_code": 90
    },
    {
        "district": "จะแหน",
        "amphoe": "สะบ้าย้อย",
        "province": "สงขลา",
        "zipcode": 90210,
        "district_code": 900605,
        "amphoe_code": 9006,
        "province_code": 90
    },
    {
        "district": "ทุ่งพอ",
        "amphoe": "สะบ้าย้อย",
        "province": "สงขลา",
        "zipcode": 90210,
        "district_code": 900602,
        "amphoe_code": 9006,
        "province_code": 90
    },
    {
        "district": "ธารคีรี",
        "amphoe": "สะบ้าย้อย",
        "province": "สงขลา",
        "zipcode": 90210,
        "district_code": 900609,
        "amphoe_code": 9006,
        "province_code": 90
    },
    {
        "district": "บาโหย",
        "amphoe": "สะบ้าย้อย",
        "province": "สงขลา",
        "zipcode": 90210,
        "district_code": 900608,
        "amphoe_code": 9006,
        "province_code": 90
    },
    {
        "district": "บ้านโหนด",
        "amphoe": "สะบ้าย้อย",
        "province": "สงขลา",
        "zipcode": 90210,
        "district_code": 900604,
        "amphoe_code": 9006,
        "province_code": 90
    },
    {
        "district": "สะบ้าย้อย",
        "amphoe": "สะบ้าย้อย",
        "province": "สงขลา",
        "zipcode": 90210,
        "district_code": 900601,
        "amphoe_code": 9006,
        "province_code": 90
    },
    {
        "district": "เขาแดง",
        "amphoe": "สะบ้าย้อย",
        "province": "สงขลา",
        "zipcode": 90210,
        "district_code": 900607,
        "amphoe_code": 9006,
        "province_code": 90
    },
    {
        "district": "เปียน",
        "amphoe": "สะบ้าย้อย",
        "province": "สงขลา",
        "zipcode": 90210,
        "district_code": 900603,
        "amphoe_code": 9006,
        "province_code": 90
    },
    {
        "district": "ทุ่งหมอ",
        "amphoe": "สะเดา",
        "province": "สงขลา",
        "zipcode": 90240,
        "district_code": 901005,
        "amphoe_code": 9010,
        "province_code": 90
    },
    {
        "district": "ท่าโพธิ์",
        "amphoe": "สะเดา",
        "province": "สงขลา",
        "zipcode": 90170,
        "district_code": 901006,
        "amphoe_code": 9010,
        "province_code": 90
    },
    {
        "district": "ปริก",
        "amphoe": "สะเดา",
        "province": "สงขลา",
        "zipcode": 90120,
        "district_code": 901002,
        "amphoe_code": 9010,
        "province_code": 90
    },
    {
        "district": "ปาดังเบซาร์",
        "amphoe": "สะเดา",
        "province": "สงขลา",
        "zipcode": 90240,
        "district_code": 901007,
        "amphoe_code": 9010,
        "province_code": 90
    },
    {
        "district": "พังลา",
        "amphoe": "สะเดา",
        "province": "สงขลา",
        "zipcode": 90170,
        "district_code": 901003,
        "amphoe_code": 9010,
        "province_code": 90
    },
    {
        "district": "สะเดา",
        "amphoe": "สะเดา",
        "province": "สงขลา",
        "zipcode": 90120,
        "district_code": 901001,
        "amphoe_code": 9010,
        "province_code": 90
    },
    {
        "district": "สำนักขาม",
        "amphoe": "สะเดา",
        "province": "สงขลา",
        "zipcode": 90320,
        "district_code": 901008,
        "amphoe_code": 9010,
        "province_code": 90
    },
    {
        "district": "สำนักแต้ว",
        "amphoe": "สะเดา",
        "province": "สงขลา",
        "zipcode": 90120,
        "district_code": 901004,
        "amphoe_code": 9010,
        "province_code": 90
    },
    {
        "district": "เขามีเกียรติ",
        "amphoe": "สะเดา",
        "province": "สงขลา",
        "zipcode": 90170,
        "district_code": 901009,
        "amphoe_code": 9010,
        "province_code": 90
    },
    {
        "district": "ชะแล้",
        "amphoe": "สิงหนคร",
        "province": "สงขลา",
        "zipcode": 90330,
        "district_code": 900112,
        "amphoe_code": 9015,
        "province_code": 90
    },
    {
        "district": "ชิงโค",
        "amphoe": "สิงหนคร",
        "province": "สงขลา",
        "zipcode": 90280,
        "district_code": 900107,
        "amphoe_code": 9015,
        "province_code": 90
    },
    {
        "district": "ทำนบ",
        "amphoe": "สิงหนคร",
        "province": "สงขลา",
        "zipcode": 90280,
        "district_code": 900109,
        "amphoe_code": 9015,
        "province_code": 90
    },
    {
        "district": "บางเขียด",
        "amphoe": "สิงหนคร",
        "province": "สงขลา",
        "zipcode": 90330,
        "district_code": 900116,
        "amphoe_code": 9015,
        "province_code": 90
    },
    {
        "district": "ปากรอ",
        "amphoe": "สิงหนคร",
        "province": "สงขลา",
        "zipcode": 90330,
        "district_code": 900113,
        "amphoe_code": 9015,
        "province_code": 90
    },
    {
        "district": "ป่าขาด",
        "amphoe": "สิงหนคร",
        "province": "สงขลา",
        "zipcode": 90330,
        "district_code": 900114,
        "amphoe_code": 9015,
        "province_code": 90
    },
    {
        "district": "ม่วงงาม",
        "amphoe": "สิงหนคร",
        "province": "สงขลา",
        "zipcode": 90330,
        "district_code": 900117,
        "amphoe_code": 9015,
        "province_code": 90
    },
    {
        "district": "รำแดง",
        "amphoe": "สิงหนคร",
        "province": "สงขลา",
        "zipcode": 90330,
        "district_code": 900110,
        "amphoe_code": 9015,
        "province_code": 90
    },
    {
        "district": "วัดขนุน",
        "amphoe": "สิงหนคร",
        "province": "สงขลา",
        "zipcode": 90330,
        "district_code": 900111,
        "amphoe_code": 9015,
        "province_code": 90
    },
    {
        "district": "สทิงหม้อ",
        "amphoe": "สิงหนคร",
        "province": "สงขลา",
        "zipcode": 90280,
        "district_code": 900108,
        "amphoe_code": 9015,
        "province_code": 90
    },
    {
        "district": "หัวเขา",
        "amphoe": "สิงหนคร",
        "province": "สงขลา",
        "zipcode": 90280,
        "district_code": 900115,
        "amphoe_code": 9015,
        "province_code": 90
    },
    {
        "district": "คลองอู่ตะเภา",
        "amphoe": "หาดใหญ่",
        "province": "สงขลา",
        "zipcode": 90110,
        "district_code": 901107,
        "amphoe_code": 9011,
        "province_code": 90
    },
    {
        "district": "คลองแห",
        "amphoe": "หาดใหญ่",
        "province": "สงขลา",
        "zipcode": 90110,
        "district_code": 901105,
        "amphoe_code": 9011,
        "province_code": 90
    },
    {
        "district": "ควนลัง",
        "amphoe": "หาดใหญ่",
        "province": "สงขลา",
        "zipcode": 90110,
        "district_code": 901102,
        "amphoe_code": 9011,
        "province_code": 90
    },
    {
        "district": "คอหงส์",
        "amphoe": "หาดใหญ่",
        "province": "สงขลา",
        "zipcode": 90110,
        "district_code": 901104,
        "amphoe_code": 9011,
        "province_code": 90
    },
    {
        "district": "คูเต่า",
        "amphoe": "หาดใหญ่",
        "province": "สงขลา",
        "zipcode": 90110,
        "district_code": 901103,
        "amphoe_code": 9011,
        "province_code": 90
    },
    {
        "district": "ฉลุง",
        "amphoe": "หาดใหญ่",
        "province": "สงขลา",
        "zipcode": 90110,
        "district_code": 901108,
        "amphoe_code": 9011,
        "province_code": 90
    },
    {
        "district": "ทุ่งตำเสา",
        "amphoe": "หาดใหญ่",
        "province": "สงขลา",
        "zipcode": 90110,
        "district_code": 901112,
        "amphoe_code": 9011,
        "province_code": 90
    },
    {
        "district": "ทุ่งใหญ่",
        "amphoe": "หาดใหญ่",
        "province": "สงขลา",
        "zipcode": 90110,
        "district_code": 901111,
        "amphoe_code": 9011,
        "province_code": 90
    },
    {
        "district": "ท่าข้าม",
        "amphoe": "หาดใหญ่",
        "province": "สงขลา",
        "zipcode": 90110,
        "district_code": 901113,
        "amphoe_code": 9011,
        "province_code": 90
    },
    {
        "district": "น้ำน้อย",
        "amphoe": "หาดใหญ่",
        "province": "สงขลา",
        "zipcode": 90110,
        "district_code": 901114,
        "amphoe_code": 9011,
        "province_code": 90
    },
    {
        "district": "บ้านพรุ",
        "amphoe": "หาดใหญ่",
        "province": "สงขลา",
        "zipcode": 90250,
        "district_code": 901116,
        "amphoe_code": 9011,
        "province_code": 90
    },
    {
        "district": "พะตง",
        "amphoe": "หาดใหญ่",
        "province": "สงขลา",
        "zipcode": 90230,
        "district_code": 901118,
        "amphoe_code": 9011,
        "province_code": 90
    },
    {
        "district": "หาดใหญ่",
        "amphoe": "หาดใหญ่",
        "province": "สงขลา",
        "zipcode": 90110,
        "district_code": 901101,
        "amphoe_code": 9011,
        "province_code": 90
    },
    {
        "district": "ท่าม่วง",
        "amphoe": "เทพา",
        "province": "สงขลา",
        "zipcode": 90150,
        "district_code": 900505,
        "amphoe_code": 9005,
        "province_code": 90
    },
    {
        "district": "ปากบาง",
        "amphoe": "เทพา",
        "province": "สงขลา",
        "zipcode": 90150,
        "district_code": 900502,
        "amphoe_code": 9005,
        "province_code": 90
    },
    {
        "district": "ลำไพล",
        "amphoe": "เทพา",
        "province": "สงขลา",
        "zipcode": 90260,
        "district_code": 900504,
        "amphoe_code": 9005,
        "province_code": 90
    },
    {
        "district": "วังใหญ่",
        "amphoe": "เทพา",
        "province": "สงขลา",
        "zipcode": 90260,
        "district_code": 900506,
        "amphoe_code": 9005,
        "province_code": 90
    },
    {
        "district": "สะกอม",
        "amphoe": "เทพา",
        "province": "สงขลา",
        "zipcode": 90150,
        "district_code": 900304,
        "amphoe_code": 9005,
        "province_code": 90
    },
    {
        "district": "เกาะสะบ้า",
        "amphoe": "เทพา",
        "province": "สงขลา",
        "zipcode": 90150,
        "district_code": 900503,
        "amphoe_code": 9005,
        "province_code": 90
    },
    {
        "district": "เทพา",
        "amphoe": "เทพา",
        "province": "สงขลา",
        "zipcode": 90150,
        "district_code": 900501,
        "amphoe_code": 9005,
        "province_code": 90
    },
    {
        "district": "ทุ่งหวัง",
        "amphoe": "เมืองสงขลา",
        "province": "สงขลา",
        "zipcode": 90000,
        "district_code": 900105,
        "amphoe_code": 9001,
        "province_code": 90
    },
    {
        "district": "บ่อยาง",
        "amphoe": "เมืองสงขลา",
        "province": "สงขลา",
        "zipcode": 90000,
        "district_code": 900101,
        "amphoe_code": 9001,
        "province_code": 90
    },
    {
        "district": "พะวง",
        "amphoe": "เมืองสงขลา",
        "province": "สงขลา",
        "zipcode": 90100,
        "district_code": 900104,
        "amphoe_code": 9001,
        "province_code": 90
    },
    {
        "district": "เกาะยอ",
        "amphoe": "เมืองสงขลา",
        "province": "สงขลา",
        "zipcode": 90100,
        "district_code": 900106,
        "amphoe_code": 9001,
        "province_code": 90
    },
    {
        "district": "เกาะแต้ว",
        "amphoe": "เมืองสงขลา",
        "province": "สงขลา",
        "zipcode": 90000,
        "district_code": 900103,
        "amphoe_code": 9001,
        "province_code": 90
    },
    {
        "district": "เขารูปช้าง",
        "amphoe": "เมืองสงขลา",
        "province": "สงขลา",
        "zipcode": 90000,
        "district_code": 900102,
        "amphoe_code": 9001,
        "province_code": 90
    },
    {
        "district": "ควนกาหลง",
        "amphoe": "ควนกาหลง",
        "province": "สตูล",
        "zipcode": 91130,
        "district_code": 910302,
        "amphoe_code": 9103,
        "province_code": 91
    },
    {
        "district": "ทุ่งนุ้ย",
        "amphoe": "ควนกาหลง",
        "province": "สตูล",
        "zipcode": 91130,
        "district_code": 910301,
        "amphoe_code": 9103,
        "province_code": 91
    },
    {
        "district": "อุไดเจริญ",
        "amphoe": "ควนกาหลง",
        "province": "สตูล",
        "zipcode": 91130,
        "district_code": false,
        "amphoe_code": 9103,
        "province_code": 91
    },
    {
        "district": "ควนสตอ",
        "amphoe": "ควนโดน",
        "province": "สตูล",
        "zipcode": 91160,
        "district_code": 910202,
        "amphoe_code": 9102,
        "province_code": 91
    },
    {
        "district": "ควนโดน",
        "amphoe": "ควนโดน",
        "province": "สตูล",
        "zipcode": 91160,
        "district_code": 910201,
        "amphoe_code": 9102,
        "province_code": 91
    },
    {
        "district": "ย่านซื่อ",
        "amphoe": "ควนโดน",
        "province": "สตูล",
        "zipcode": 91160,
        "district_code": 910203,
        "amphoe_code": 9102,
        "province_code": 91
    },
    {
        "district": "วังประจัน",
        "amphoe": "ควนโดน",
        "province": "สตูล",
        "zipcode": 91160,
        "district_code": 910204,
        "amphoe_code": 9102,
        "province_code": 91
    },
    {
        "district": "ขอนคลาน",
        "amphoe": "ทุ่งหว้า",
        "province": "สตูล",
        "zipcode": 91120,
        "district_code": 910603,
        "amphoe_code": 9106,
        "province_code": 91
    },
    {
        "district": "ทุ่งบุหลัง",
        "amphoe": "ทุ่งหว้า",
        "province": "สตูล",
        "zipcode": 91120,
        "district_code": 910604,
        "amphoe_code": 9106,
        "province_code": 91
    },
    {
        "district": "ทุ่งหว้า",
        "amphoe": "ทุ่งหว้า",
        "province": "สตูล",
        "zipcode": 91120,
        "district_code": 910601,
        "amphoe_code": 9106,
        "province_code": 91
    },
    {
        "district": "นาทอน",
        "amphoe": "ทุ่งหว้า",
        "province": "สตูล",
        "zipcode": 91120,
        "district_code": 910602,
        "amphoe_code": 9106,
        "province_code": 91
    },
    {
        "district": "ป่าแก่บ่อหิน",
        "amphoe": "ทุ่งหว้า",
        "province": "สตูล",
        "zipcode": 91120,
        "district_code": 910605,
        "amphoe_code": 9106,
        "province_code": 91
    },
    {
        "district": "ท่าเรือ",
        "amphoe": "ท่าแพ",
        "province": "สตูล",
        "zipcode": 91150,
        "district_code": 910404,
        "amphoe_code": 9104,
        "province_code": 91
    },
    {
        "district": "ท่าแพ",
        "amphoe": "ท่าแพ",
        "province": "สตูล",
        "zipcode": 91150,
        "district_code": 910199,
        "amphoe_code": 9104,
        "province_code": 91
    },
    {
        "district": "สาคร",
        "amphoe": "ท่าแพ",
        "province": "สตูล",
        "zipcode": 91150,
        "district_code": 910403,
        "amphoe_code": 9104,
        "province_code": 91
    },
    {
        "district": "แป-ระ",
        "amphoe": "ท่าแพ",
        "province": "สตูล",
        "zipcode": 91150,
        "district_code": 910402,
        "amphoe_code": 9104,
        "province_code": 91
    },
    {
        "district": "นิคมพัฒนา",
        "amphoe": "มะนัง",
        "province": "สตูล",
        "zipcode": 91130,
        "district_code": 910304,
        "amphoe_code": 9107,
        "province_code": 91
    },
    {
        "district": "ปาล์มพัฒนา",
        "amphoe": "มะนัง",
        "province": "สตูล",
        "zipcode": 91130,
        "district_code": 910305,
        "amphoe_code": 9107,
        "province_code": 91
    },
    {
        "district": "กำแพง",
        "amphoe": "ละงู",
        "province": "สตูล",
        "zipcode": 91110,
        "district_code": 910501,
        "amphoe_code": 9105,
        "province_code": 91
    },
    {
        "district": "น้ำผุด",
        "amphoe": "ละงู",
        "province": "สตูล",
        "zipcode": 91110,
        "district_code": 910505,
        "amphoe_code": 9105,
        "province_code": 91
    },
    {
        "district": "ปากน้ำ",
        "amphoe": "ละงู",
        "province": "สตูล",
        "zipcode": 91110,
        "district_code": 910504,
        "amphoe_code": 9105,
        "province_code": 91
    },
    {
        "district": "ละงู",
        "amphoe": "ละงู",
        "province": "สตูล",
        "zipcode": 91110,
        "district_code": 910502,
        "amphoe_code": 9105,
        "province_code": 91
    },
    {
        "district": "เขาขาว",
        "amphoe": "ละงู",
        "province": "สตูล",
        "zipcode": 91110,
        "district_code": 910503,
        "amphoe_code": 9105,
        "province_code": 91
    },
    {
        "district": "แหลมสน",
        "amphoe": "ละงู",
        "province": "สตูล",
        "zipcode": 91110,
        "district_code": 910506,
        "amphoe_code": 9105,
        "province_code": 91
    },
    {
        "district": "คลองขุด",
        "amphoe": "เมืองสตูล",
        "province": "สตูล",
        "zipcode": 91000,
        "district_code": 910102,
        "amphoe_code": 9101,
        "province_code": 91
    },
    {
        "district": "ควนขัน",
        "amphoe": "เมืองสตูล",
        "province": "สตูล",
        "zipcode": 91000,
        "district_code": 910103,
        "amphoe_code": 9101,
        "province_code": 91
    },
    {
        "district": "ควนโพธิ์",
        "amphoe": "เมืองสตูล",
        "province": "สตูล",
        "zipcode": 91140,
        "district_code": 910111,
        "amphoe_code": 9101,
        "province_code": 91
    },
    {
        "district": "ฉลุง",
        "amphoe": "เมืองสตูล",
        "province": "สตูล",
        "zipcode": 91140,
        "district_code": 910105,
        "amphoe_code": 9101,
        "province_code": 91
    },
    {
        "district": "ตันหยงโป",
        "amphoe": "เมืองสตูล",
        "province": "สตูล",
        "zipcode": 91000,
        "district_code": 910107,
        "amphoe_code": 9101,
        "province_code": 91
    },
    {
        "district": "ตำมะลัง",
        "amphoe": "เมืองสตูล",
        "province": "สตูล",
        "zipcode": 91000,
        "district_code": 910109,
        "amphoe_code": 9101,
        "province_code": 91
    },
    {
        "district": "บ้านควน",
        "amphoe": "เมืองสตูล",
        "province": "สตูล",
        "zipcode": 91140,
        "district_code": 910104,
        "amphoe_code": 9101,
        "province_code": 91
    },
    {
        "district": "ปูยู",
        "amphoe": "เมืองสตูล",
        "province": "สตูล",
        "zipcode": 91000,
        "district_code": 910110,
        "amphoe_code": 9101,
        "province_code": 91
    },
    {
        "district": "พิมาน",
        "amphoe": "เมืองสตูล",
        "province": "สตูล",
        "zipcode": 91000,
        "district_code": 910101,
        "amphoe_code": 9101,
        "province_code": 91
    },
    {
        "district": "เกตรี",
        "amphoe": "เมืองสตูล",
        "province": "สตูล",
        "zipcode": 91140,
        "district_code": 910112,
        "amphoe_code": 9101,
        "province_code": 91
    },
    {
        "district": "เกาะสาหร่าย",
        "amphoe": "เมืองสตูล",
        "province": "สตูล",
        "zipcode": 91000,
        "district_code": 910106,
        "amphoe_code": 9101,
        "province_code": 91
    },
    {
        "district": "เจ๊ะบิลัง",
        "amphoe": "เมืองสตูล",
        "province": "สตูล",
        "zipcode": 91000,
        "district_code": 910108,
        "amphoe_code": 9101,
        "province_code": 91
    },
    {
        "district": "คลองด่าน",
        "amphoe": "บางบ่อ",
        "province": "สมุทรปราการ",
        "zipcode": 10550,
        "district_code": 110205,
        "amphoe_code": 1102,
        "province_code": 11
    },
    {
        "district": "คลองนิยมยาตรา",
        "amphoe": "บางบ่อ",
        "province": "สมุทรปราการ",
        "zipcode": 10560,
        "district_code": 110208,
        "amphoe_code": 1102,
        "province_code": 11
    },
    {
        "district": "คลองสวน",
        "amphoe": "บางบ่อ",
        "province": "สมุทรปราการ",
        "zipcode": 10560,
        "district_code": 110206,
        "amphoe_code": 1102,
        "province_code": 11
    },
    {
        "district": "บางบ่อ",
        "amphoe": "บางบ่อ",
        "province": "สมุทรปราการ",
        "zipcode": 10560,
        "district_code": 110201,
        "amphoe_code": 1102,
        "province_code": 11
    },
    {
        "district": "บางพลีน้อย",
        "amphoe": "บางบ่อ",
        "province": "สมุทรปราการ",
        "zipcode": 10560,
        "district_code": 110203,
        "amphoe_code": 1102,
        "province_code": 11
    },
    {
        "district": "บางเพรียง",
        "amphoe": "บางบ่อ",
        "province": "สมุทรปราการ",
        "zipcode": 10560,
        "district_code": 110204,
        "amphoe_code": 1102,
        "province_code": 11
    },
    {
        "district": "บ้านระกาศ",
        "amphoe": "บางบ่อ",
        "province": "สมุทรปราการ",
        "zipcode": 10560,
        "district_code": 110202,
        "amphoe_code": 1102,
        "province_code": 11
    },
    {
        "district": "เปร็ง",
        "amphoe": "บางบ่อ",
        "province": "สมุทรปราการ",
        "zipcode": 10560,
        "district_code": 110207,
        "amphoe_code": 1102,
        "province_code": 11
    },
    {
        "district": "บางปลา",
        "amphoe": "บางพลี",
        "province": "สมุทรปราการ",
        "zipcode": 10540,
        "district_code": 110303,
        "amphoe_code": 1103,
        "province_code": 11
    },
    {
        "district": "บางพลีใหญ่",
        "amphoe": "บางพลี",
        "province": "สมุทรปราการ",
        "zipcode": 10540,
        "district_code": 110301,
        "amphoe_code": 1103,
        "province_code": 11
    },
    {
        "district": "บางแก้ว",
        "amphoe": "บางพลี",
        "province": "สมุทรปราการ",
        "zipcode": 10540,
        "district_code": 110302,
        "amphoe_code": 1103,
        "province_code": 11
    },
    {
        "district": "บางโฉลง",
        "amphoe": "บางพลี",
        "province": "สมุทรปราการ",
        "zipcode": 10540,
        "district_code": 110304,
        "amphoe_code": 1103,
        "province_code": 11
    },
    {
        "district": "ราชาเทวะ",
        "amphoe": "บางพลี",
        "province": "สมุทรปราการ",
        "zipcode": 10540,
        "district_code": 110308,
        "amphoe_code": 1103,
        "province_code": 11
    },
    {
        "district": "หนองปรือ",
        "amphoe": "บางพลี",
        "province": "สมุทรปราการ",
        "zipcode": 10540,
        "district_code": 110309,
        "amphoe_code": 1103,
        "province_code": 11
    },
    {
        "district": "บางเสาธง",
        "amphoe": "บางเสาธง",
        "province": "สมุทรปราการ",
        "zipcode": 10570,
        "district_code": 110305,
        "amphoe_code": 1106,
        "province_code": 11
    },
    {
        "district": "ศีรษะจรเข้น้อย",
        "amphoe": "บางเสาธง",
        "province": "สมุทรปราการ",
        "zipcode": 10570,
        "district_code": 110602,
        "amphoe_code": 1106,
        "province_code": 11
    },
    {
        "district": "ศีรษะจรเข้ใหญ่",
        "amphoe": "บางเสาธง",
        "province": "สมุทรปราการ",
        "zipcode": 10570,
        "district_code": 110603,
        "amphoe_code": 1106,
        "province_code": 11
    },
    {
        "district": "ตลาด",
        "amphoe": "พระประแดง",
        "province": "สมุทรปราการ",
        "zipcode": 10130,
        "district_code": 110401,
        "amphoe_code": 1104,
        "province_code": 11
    },
    {
        "district": "ทรงคนอง",
        "amphoe": "พระประแดง",
        "province": "สมุทรปราการ",
        "zipcode": 10130,
        "district_code": 110413,
        "amphoe_code": 1104,
        "province_code": 11
    },
    {
        "district": "บางกระสอบ",
        "amphoe": "พระประแดง",
        "province": "สมุทรปราการ",
        "zipcode": 10130,
        "district_code": 110411,
        "amphoe_code": 1104,
        "province_code": 11
    },
    {
        "district": "บางกอบัว",
        "amphoe": "พระประแดง",
        "province": "สมุทรปราการ",
        "zipcode": 10130,
        "district_code": 110412,
        "amphoe_code": 1104,
        "province_code": 11
    },
    {
        "district": "บางกะเจ้า",
        "amphoe": "พระประแดง",
        "province": "สมุทรปราการ",
        "zipcode": 10130,
        "district_code": 110409,
        "amphoe_code": 1104,
        "province_code": 11
    },
    {
        "district": "บางครุ",
        "amphoe": "พระประแดง",
        "province": "สมุทรปราการ",
        "zipcode": 10130,
        "district_code": 110404,
        "amphoe_code": 1104,
        "province_code": 11
    },
    {
        "district": "บางจาก",
        "amphoe": "พระประแดง",
        "province": "สมุทรปราการ",
        "zipcode": 10130,
        "district_code": 110403,
        "amphoe_code": 1104,
        "province_code": 11
    },
    {
        "district": "บางน้ำผึ้ง",
        "amphoe": "พระประแดง",
        "province": "สมุทรปราการ",
        "zipcode": 10130,
        "district_code": 110410,
        "amphoe_code": 1104,
        "province_code": 11
    },
    {
        "district": "บางพึ่ง",
        "amphoe": "พระประแดง",
        "province": "สมุทรปราการ",
        "zipcode": 10130,
        "district_code": 110402,
        "amphoe_code": 1104,
        "province_code": 11
    },
    {
        "district": "บางยอ",
        "amphoe": "พระประแดง",
        "province": "สมุทรปราการ",
        "zipcode": 10130,
        "district_code": 110408,
        "amphoe_code": 1104,
        "province_code": 11
    },
    {
        "district": "บางหญ้าแพรก",
        "amphoe": "พระประแดง",
        "province": "สมุทรปราการ",
        "zipcode": 10130,
        "district_code": 110405,
        "amphoe_code": 1104,
        "province_code": 11
    },
    {
        "district": "บางหัวเสือ",
        "amphoe": "พระประแดง",
        "province": "สมุทรปราการ",
        "zipcode": 10130,
        "district_code": 110406,
        "amphoe_code": 1104,
        "province_code": 11
    },
    {
        "district": "สำโรง",
        "amphoe": "พระประแดง",
        "province": "สมุทรปราการ",
        "zipcode": 10130,
        "district_code": 110414,
        "amphoe_code": 1104,
        "province_code": 11
    },
    {
        "district": "สำโรงกลาง",
        "amphoe": "พระประแดง",
        "province": "สมุทรปราการ",
        "zipcode": 10130,
        "district_code": 110415,
        "amphoe_code": 1104,
        "province_code": 11
    },
    {
        "district": "สำโรงใต้",
        "amphoe": "พระประแดง",
        "province": "สมุทรปราการ",
        "zipcode": 10130,
        "district_code": 110407,
        "amphoe_code": 1104,
        "province_code": 11
    },
    {
        "district": "นาเกลือ",
        "amphoe": "พระสมุทรเจดีย์",
        "province": "สมุทรปราการ",
        "zipcode": 10290,
        "district_code": 110105,
        "amphoe_code": 1105,
        "province_code": 11
    },
    {
        "district": "บ้านคลองสวน",
        "amphoe": "พระสมุทรเจดีย์",
        "province": "สมุทรปราการ",
        "zipcode": 10290,
        "district_code": 110198,
        "amphoe_code": 1105,
        "province_code": 11
    },
    {
        "district": "ปากคลองบางปลากด",
        "amphoe": "พระสมุทรเจดีย์",
        "province": "สมุทรปราการ",
        "zipcode": 10290,
        "district_code": 110109,
        "amphoe_code": 1105,
        "province_code": 11
    },
    {
        "district": "แหลมฟ้าผ่า",
        "amphoe": "พระสมุทรเจดีย์",
        "province": "สมุทรปราการ",
        "zipcode": 10290,
        "district_code": 110106,
        "amphoe_code": 1105,
        "province_code": 11
    },
    {
        "district": "ในคลองบางปลากด",
        "amphoe": "พระสมุทรเจดีย์",
        "province": "สมุทรปราการ",
        "zipcode": 10290,
        "district_code": 110107,
        "amphoe_code": 1105,
        "province_code": 11
    },
    {
        "district": "ท้ายบ้าน",
        "amphoe": "เมืองสมุทรปราการ",
        "province": "สมุทรปราการ",
        "zipcode": 10280,
        "district_code": 110104,
        "amphoe_code": 1101,
        "province_code": 11
    },
    {
        "district": "ท้ายบ้านใหม่",
        "amphoe": "เมืองสมุทรปราการ",
        "province": "สมุทรปราการ",
        "zipcode": 10280,
        "district_code": 110116,
        "amphoe_code": 1101,
        "province_code": 11
    },
    {
        "district": "บางด้วน",
        "amphoe": "เมืองสมุทรปราการ",
        "province": "สมุทรปราการ",
        "zipcode": 10270,
        "district_code": 110113,
        "amphoe_code": 1101,
        "province_code": 11
    },
    {
        "district": "บางปู",
        "amphoe": "เมืองสมุทรปราการ",
        "province": "สมุทรปราการ",
        "zipcode": 10280,
        "district_code": 110112,
        "amphoe_code": 1101,
        "province_code": 11
    },
    {
        "district": "บางปูใหม่",
        "amphoe": "เมืองสมุทรปราการ",
        "province": "สมุทรปราการ",
        "zipcode": 10280,
        "district_code": 110108,
        "amphoe_code": 1101,
        "province_code": 11
    },
    {
        "district": "บางเมือง",
        "amphoe": "เมืองสมุทรปราการ",
        "province": "สมุทรปราการ",
        "zipcode": 10270,
        "district_code": 110103,
        "amphoe_code": 1101,
        "province_code": 11
    },
    {
        "district": "บางเมืองใหม่",
        "amphoe": "เมืองสมุทรปราการ",
        "province": "สมุทรปราการ",
        "zipcode": 10270,
        "district_code": 110114,
        "amphoe_code": 1101,
        "province_code": 11
    },
    {
        "district": "บางโปรง",
        "amphoe": "เมืองสมุทรปราการ",
        "province": "สมุทรปราการ",
        "zipcode": 10270,
        "district_code": 110111,
        "amphoe_code": 1101,
        "province_code": 11
    },
    {
        "district": "ปากน้ำ",
        "amphoe": "เมืองสมุทรปราการ",
        "province": "สมุทรปราการ",
        "zipcode": 10270,
        "district_code": 110101,
        "amphoe_code": 1101,
        "province_code": 11
    },
    {
        "district": "สำโรงเหนือ",
        "amphoe": "เมืองสมุทรปราการ",
        "province": "สมุทรปราการ",
        "zipcode": 10270,
        "district_code": 110102,
        "amphoe_code": 1101,
        "province_code": 11
    },
    {
        "district": "เทพารักษ์",
        "amphoe": "เมืองสมุทรปราการ",
        "province": "สมุทรปราการ",
        "zipcode": 10270,
        "district_code": 110115,
        "amphoe_code": 1101,
        "province_code": 11
    },
    {
        "district": "แพรกษา",
        "amphoe": "เมืองสมุทรปราการ",
        "province": "สมุทรปราการ",
        "zipcode": 10280,
        "district_code": 110110,
        "amphoe_code": 1101,
        "province_code": 11
    },
    {
        "district": "แพรกษาใหม่",
        "amphoe": "เมืองสมุทรปราการ",
        "province": "สมุทรปราการ",
        "zipcode": 10280,
        "district_code": 110117,
        "amphoe_code": 1101,
        "province_code": 11
    },
    {
        "district": "กระดังงา",
        "amphoe": "บางคนที",
        "province": "สมุทรสงคราม",
        "zipcode": 75120,
        "district_code": 750201,
        "amphoe_code": 7502,
        "province_code": 75
    },
    {
        "district": "จอมปลวก",
        "amphoe": "บางคนที",
        "province": "สมุทรสงคราม",
        "zipcode": 75120,
        "district_code": 750209,
        "amphoe_code": 7502,
        "province_code": 75
    },
    {
        "district": "ดอนมะโนรา",
        "amphoe": "บางคนที",
        "province": "สมุทรสงคราม",
        "zipcode": 75120,
        "district_code": 750206,
        "amphoe_code": 7502,
        "province_code": 75
    },
    {
        "district": "บางกระบือ",
        "amphoe": "บางคนที",
        "province": "สมุทรสงคราม",
        "zipcode": 75120,
        "district_code": 750212,
        "amphoe_code": 7502,
        "province_code": 75
    },
    {
        "district": "บางกุ้ง",
        "amphoe": "บางคนที",
        "province": "สมุทรสงคราม",
        "zipcode": 75120,
        "district_code": 750208,
        "amphoe_code": 7502,
        "province_code": 75
    },
    {
        "district": "บางคนที",
        "amphoe": "บางคนที",
        "province": "สมุทรสงคราม",
        "zipcode": 75120,
        "district_code": 750205,
        "amphoe_code": 7502,
        "province_code": 75
    },
    {
        "district": "บางนกแขวก",
        "amphoe": "บางคนที",
        "province": "สมุทรสงคราม",
        "zipcode": 75120,
        "district_code": 750210,
        "amphoe_code": 7502,
        "province_code": 75
    },
    {
        "district": "บางพรม",
        "amphoe": "บางคนที",
        "province": "สมุทรสงคราม",
        "zipcode": 75120,
        "district_code": 750207,
        "amphoe_code": 7502,
        "province_code": 75
    },
    {
        "district": "บางยี่รงค์",
        "amphoe": "บางคนที",
        "province": "สมุทรสงคราม",
        "zipcode": 75120,
        "district_code": 750203,
        "amphoe_code": 7502,
        "province_code": 75
    },
    {
        "district": "บางสะแก",
        "amphoe": "บางคนที",
        "province": "สมุทรสงคราม",
        "zipcode": 75120,
        "district_code": 750202,
        "amphoe_code": 7502,
        "province_code": 75
    },
    {
        "district": "บ้านปราโมทย์",
        "amphoe": "บางคนที",
        "province": "สมุทรสงคราม",
        "zipcode": 75120,
        "district_code": 750213,
        "amphoe_code": 7502,
        "province_code": 75
    },
    {
        "district": "ยายแพง",
        "amphoe": "บางคนที",
        "province": "สมุทรสงคราม",
        "zipcode": 75120,
        "district_code": 750211,
        "amphoe_code": 7502,
        "province_code": 75
    },
    {
        "district": "โรงหีบ",
        "amphoe": "บางคนที",
        "province": "สมุทรสงคราม",
        "zipcode": 75120,
        "district_code": 750204,
        "amphoe_code": 7502,
        "province_code": 75
    },
    {
        "district": "ท่าคา",
        "amphoe": "อัมพวา",
        "province": "สมุทรสงคราม",
        "zipcode": 75110,
        "district_code": 750303,
        "amphoe_code": 7503,
        "province_code": 75
    },
    {
        "district": "บางช้าง",
        "amphoe": "อัมพวา",
        "province": "สมุทรสงคราม",
        "zipcode": 75110,
        "district_code": 750306,
        "amphoe_code": 7503,
        "province_code": 75
    },
    {
        "district": "บางนางลี่",
        "amphoe": "อัมพวา",
        "province": "สมุทรสงคราม",
        "zipcode": 75110,
        "district_code": 750312,
        "amphoe_code": 7503,
        "province_code": 75
    },
    {
        "district": "บางแค",
        "amphoe": "อัมพวา",
        "province": "สมุทรสงคราม",
        "zipcode": 75110,
        "district_code": 750309,
        "amphoe_code": 7503,
        "province_code": 75
    },
    {
        "district": "ปลายโพงพาง",
        "amphoe": "อัมพวา",
        "province": "สมุทรสงคราม",
        "zipcode": 75110,
        "district_code": 750308,
        "amphoe_code": 7503,
        "province_code": 75
    },
    {
        "district": "ยี่สาร",
        "amphoe": "อัมพวา",
        "province": "สมุทรสงคราม",
        "zipcode": 75110,
        "district_code": 750311,
        "amphoe_code": 7503,
        "province_code": 75
    },
    {
        "district": "วัดประดู่",
        "amphoe": "อัมพวา",
        "province": "สมุทรสงคราม",
        "zipcode": 75110,
        "district_code": 750304,
        "amphoe_code": 7503,
        "province_code": 75
    },
    {
        "district": "สวนหลวง",
        "amphoe": "อัมพวา",
        "province": "สมุทรสงคราม",
        "zipcode": 75110,
        "district_code": 750302,
        "amphoe_code": 7503,
        "province_code": 75
    },
    {
        "district": "อัมพวา",
        "amphoe": "อัมพวา",
        "province": "สมุทรสงคราม",
        "zipcode": 75110,
        "district_code": 750301,
        "amphoe_code": 7503,
        "province_code": 75
    },
    {
        "district": "เหมืองใหม่",
        "amphoe": "อัมพวา",
        "province": "สมุทรสงคราม",
        "zipcode": 75110,
        "district_code": 750305,
        "amphoe_code": 7503,
        "province_code": 75
    },
    {
        "district": "แควอ้อม",
        "amphoe": "อัมพวา",
        "province": "สมุทรสงคราม",
        "zipcode": 75110,
        "district_code": 750307,
        "amphoe_code": 7503,
        "province_code": 75
    },
    {
        "district": "แพรกหนามแดง",
        "amphoe": "อัมพวา",
        "province": "สมุทรสงคราม",
        "zipcode": 75110,
        "district_code": 750310,
        "amphoe_code": 7503,
        "province_code": 75
    },
    {
        "district": "คลองเขิน",
        "amphoe": "เมืองสมุทรสงคราม",
        "province": "สมุทรสงคราม",
        "zipcode": 75000,
        "district_code": 750108,
        "amphoe_code": 7501,
        "province_code": 75
    },
    {
        "district": "คลองโคน",
        "amphoe": "เมืองสมุทรสงคราม",
        "province": "สมุทรสงคราม",
        "zipcode": 75000,
        "district_code": 750109,
        "amphoe_code": 7501,
        "province_code": 75
    },
    {
        "district": "ท้ายหาด",
        "amphoe": "เมืองสมุทรสงคราม",
        "province": "สมุทรสงคราม",
        "zipcode": 75000,
        "district_code": 750106,
        "amphoe_code": 7501,
        "province_code": 75
    },
    {
        "district": "นางตะเคียน",
        "amphoe": "เมืองสมุทรสงคราม",
        "province": "สมุทรสงคราม",
        "zipcode": 75000,
        "district_code": 750110,
        "amphoe_code": 7501,
        "province_code": 75
    },
    {
        "district": "บางขันแตก",
        "amphoe": "เมืองสมุทรสงคราม",
        "province": "สมุทรสงคราม",
        "zipcode": 75000,
        "district_code": 750102,
        "amphoe_code": 7501,
        "province_code": 75
    },
    {
        "district": "บางจะเกร็ง",
        "amphoe": "เมืองสมุทรสงคราม",
        "province": "สมุทรสงคราม",
        "zipcode": 75000,
        "district_code": 750111,
        "amphoe_code": 7501,
        "province_code": 75
    },
    {
        "district": "บางแก้ว",
        "amphoe": "เมืองสมุทรสงคราม",
        "province": "สมุทรสงคราม",
        "zipcode": 75000,
        "district_code": 750105,
        "amphoe_code": 7501,
        "province_code": 75
    },
    {
        "district": "บ้านปรก",
        "amphoe": "เมืองสมุทรสงคราม",
        "province": "สมุทรสงคราม",
        "zipcode": 75000,
        "district_code": 750104,
        "amphoe_code": 7501,
        "province_code": 75
    },
    {
        "district": "ลาดใหญ่",
        "amphoe": "เมืองสมุทรสงคราม",
        "province": "สมุทรสงคราม",
        "zipcode": 75000,
        "district_code": 750103,
        "amphoe_code": 7501,
        "province_code": 75
    },
    {
        "district": "แม่กลอง",
        "amphoe": "เมืองสมุทรสงคราม",
        "province": "สมุทรสงคราม",
        "zipcode": 75000,
        "district_code": 750101,
        "amphoe_code": 7501,
        "province_code": 75
    },
    {
        "district": "แหลมใหญ่",
        "amphoe": "เมืองสมุทรสงคราม",
        "province": "สมุทรสงคราม",
        "zipcode": 75000,
        "district_code": 750107,
        "amphoe_code": 7501,
        "province_code": 75
    },
    {
        "district": "คลองมะเดื่อ",
        "amphoe": "กระทุ่มแบน",
        "province": "สมุทรสาคร",
        "zipcode": 74110,
        "district_code": 740206,
        "amphoe_code": 7402,
        "province_code": 74
    },
    {
        "district": "ดอนไก่ดี",
        "amphoe": "กระทุ่มแบน",
        "province": "สมุทรสาคร",
        "zipcode": 74110,
        "district_code": 740208,
        "amphoe_code": 7402,
        "province_code": 74
    },
    {
        "district": "ตลาดกระทุ่มแบน",
        "amphoe": "กระทุ่มแบน",
        "province": "สมุทรสาคร",
        "zipcode": 74110,
        "district_code": 740201,
        "amphoe_code": 7402,
        "province_code": 74
    },
    {
        "district": "ท่าเสา",
        "amphoe": "กระทุ่มแบน",
        "province": "สมุทรสาคร",
        "zipcode": 74110,
        "district_code": 740210,
        "amphoe_code": 7402,
        "province_code": 74
    },
    {
        "district": "ท่าไม้",
        "amphoe": "กระทุ่มแบน",
        "province": "สมุทรสาคร",
        "zipcode": 74110,
        "district_code": 740203,
        "amphoe_code": 7402,
        "province_code": 74
    },
    {
        "district": "บางยาง",
        "amphoe": "กระทุ่มแบน",
        "province": "สมุทรสาคร",
        "zipcode": 74110,
        "district_code": 740205,
        "amphoe_code": 7402,
        "province_code": 74
    },
    {
        "district": "สวนหลวง",
        "amphoe": "กระทุ่มแบน",
        "province": "สมุทรสาคร",
        "zipcode": 74110,
        "district_code": 740204,
        "amphoe_code": 7402,
        "province_code": 74
    },
    {
        "district": "หนองนกไข่",
        "amphoe": "กระทุ่มแบน",
        "province": "สมุทรสาคร",
        "zipcode": 74110,
        "district_code": 740207,
        "amphoe_code": 7402,
        "province_code": 74
    },
    {
        "district": "อ้อมน้อย",
        "amphoe": "กระทุ่มแบน",
        "province": "สมุทรสาคร",
        "zipcode": 74130,
        "district_code": 740202,
        "amphoe_code": 7402,
        "province_code": 74
    },
    {
        "district": "แคราย",
        "amphoe": "กระทุ่มแบน",
        "province": "สมุทรสาคร",
        "zipcode": 74110,
        "district_code": 740209,
        "amphoe_code": 7402,
        "province_code": 74
    },
    {
        "district": "คลองตัน",
        "amphoe": "บ้านแพ้ว",
        "province": "สมุทรสาคร",
        "zipcode": 74120,
        "district_code": 740309,
        "amphoe_code": 7403,
        "province_code": 74
    },
    {
        "district": "บ้านแพ้ว",
        "amphoe": "บ้านแพ้ว",
        "province": "สมุทรสาคร",
        "zipcode": 74120,
        "district_code": 740301,
        "amphoe_code": 7403,
        "province_code": 74
    },
    {
        "district": "ยกกระบัตร",
        "amphoe": "บ้านแพ้ว",
        "province": "สมุทรสาคร",
        "zipcode": 74120,
        "district_code": 740303,
        "amphoe_code": 7403,
        "province_code": 74
    },
    {
        "district": "สวนส้ม",
        "amphoe": "บ้านแพ้ว",
        "province": "สมุทรสาคร",
        "zipcode": 74120,
        "district_code": 740311,
        "amphoe_code": 7403,
        "province_code": 74
    },
    {
        "district": "หนองบัว",
        "amphoe": "บ้านแพ้ว",
        "province": "สมุทรสาคร",
        "zipcode": 74120,
        "district_code": 740306,
        "amphoe_code": 7403,
        "province_code": 74
    },
    {
        "district": "หนองสองห้อง",
        "amphoe": "บ้านแพ้ว",
        "province": "สมุทรสาคร",
        "zipcode": 74120,
        "district_code": 740305,
        "amphoe_code": 7403,
        "province_code": 74
    },
    {
        "district": "หลักสอง",
        "amphoe": "บ้านแพ้ว",
        "province": "สมุทรสาคร",
        "zipcode": 74120,
        "district_code": 740307,
        "amphoe_code": 7403,
        "province_code": 74
    },
    {
        "district": "หลักสาม",
        "amphoe": "บ้านแพ้ว",
        "province": "สมุทรสาคร",
        "zipcode": 74120,
        "district_code": 740302,
        "amphoe_code": 7403,
        "province_code": 74
    },
    {
        "district": "อำแพง",
        "amphoe": "บ้านแพ้ว",
        "province": "สมุทรสาคร",
        "zipcode": 74120,
        "district_code": 740310,
        "amphoe_code": 7403,
        "province_code": 74
    },
    {
        "district": "เกษตรพัฒนา",
        "amphoe": "บ้านแพ้ว",
        "province": "สมุทรสาคร",
        "zipcode": 74120,
        "district_code": 740312,
        "amphoe_code": 7403,
        "province_code": 74
    },
    {
        "district": "เจ็ดริ้ว",
        "amphoe": "บ้านแพ้ว",
        "province": "สมุทรสาคร",
        "zipcode": 74120,
        "district_code": 740308,
        "amphoe_code": 7403,
        "province_code": 74
    },
    {
        "district": "โรงเข้",
        "amphoe": "บ้านแพ้ว",
        "province": "สมุทรสาคร",
        "zipcode": 74120,
        "district_code": 740304,
        "amphoe_code": 7403,
        "province_code": 74
    },
    {
        "district": "กาหลง",
        "amphoe": "เมืองสมุทรสาคร",
        "province": "สมุทรสาคร",
        "zipcode": 74000,
        "district_code": 740106,
        "amphoe_code": 7401,
        "province_code": 74
    },
    {
        "district": "คอกกระบือ",
        "amphoe": "เมืองสมุทรสาคร",
        "province": "สมุทรสาคร",
        "zipcode": 74000,
        "district_code": 740111,
        "amphoe_code": 7401,
        "province_code": 74
    },
    {
        "district": "ชัยมงคล",
        "amphoe": "เมืองสมุทรสาคร",
        "province": "สมุทรสาคร",
        "zipcode": 74000,
        "district_code": 740118,
        "amphoe_code": 7401,
        "province_code": 74
    },
    {
        "district": "ท่าจีน",
        "amphoe": "เมืองสมุทรสาคร",
        "province": "สมุทรสาคร",
        "zipcode": 74000,
        "district_code": 740108,
        "amphoe_code": 7401,
        "province_code": 74
    },
    {
        "district": "ท่าฉลอม",
        "amphoe": "เมืองสมุทรสาคร",
        "province": "สมุทรสาคร",
        "zipcode": 74000,
        "district_code": 740102,
        "amphoe_code": 7401,
        "province_code": 74
    },
    {
        "district": "ท่าทราย",
        "amphoe": "เมืองสมุทรสาคร",
        "province": "สมุทรสาคร",
        "zipcode": 74000,
        "district_code": 740110,
        "amphoe_code": 7401,
        "province_code": 74
    },
    {
        "district": "นาดี",
        "amphoe": "เมืองสมุทรสาคร",
        "province": "สมุทรสาคร",
        "zipcode": 74000,
        "district_code": 740109,
        "amphoe_code": 7401,
        "province_code": 74
    },
    {
        "district": "นาโคก",
        "amphoe": "เมืองสมุทรสาคร",
        "province": "สมุทรสาคร",
        "zipcode": 74000,
        "district_code": 740107,
        "amphoe_code": 7401,
        "province_code": 74
    },
    {
        "district": "บางกระเจ้า",
        "amphoe": "เมืองสมุทรสาคร",
        "province": "สมุทรสาคร",
        "zipcode": 74000,
        "district_code": 740116,
        "amphoe_code": 7401,
        "province_code": 74
    },
    {
        "district": "บางน้ำจืด",
        "amphoe": "เมืองสมุทรสาคร",
        "province": "สมุทรสาคร",
        "zipcode": 74000,
        "district_code": 740112,
        "amphoe_code": 7401,
        "province_code": 74
    },
    {
        "district": "บางหญ้าแพรก",
        "amphoe": "เมืองสมุทรสาคร",
        "province": "สมุทรสาคร",
        "zipcode": 74000,
        "district_code": 740117,
        "amphoe_code": 7401,
        "province_code": 74
    },
    {
        "district": "บางโทรัด",
        "amphoe": "เมืองสมุทรสาคร",
        "province": "สมุทรสาคร",
        "zipcode": 74000,
        "district_code": 740105,
        "amphoe_code": 7401,
        "province_code": 74
    },
    {
        "district": "บ้านบ่อ",
        "amphoe": "เมืองสมุทรสาคร",
        "province": "สมุทรสาคร",
        "zipcode": 74000,
        "district_code": 740104,
        "amphoe_code": 7401,
        "province_code": 74
    },
    {
        "district": "บ้านเกาะ",
        "amphoe": "เมืองสมุทรสาคร",
        "province": "สมุทรสาคร",
        "zipcode": 74000,
        "district_code": 740115,
        "amphoe_code": 7401,
        "province_code": 74
    },
    {
        "district": "พันท้ายนรสิงห์",
        "amphoe": "เมืองสมุทรสาคร",
        "province": "สมุทรสาคร",
        "zipcode": 74000,
        "district_code": 740113,
        "amphoe_code": 7401,
        "province_code": 74
    },
    {
        "district": "มหาชัย",
        "amphoe": "เมืองสมุทรสาคร",
        "province": "สมุทรสาคร",
        "zipcode": 74000,
        "district_code": 740101,
        "amphoe_code": 7401,
        "province_code": 74
    },
    {
        "district": "โกรกกราก",
        "amphoe": "เมืองสมุทรสาคร",
        "province": "สมุทรสาคร",
        "zipcode": 74000,
        "district_code": 740103,
        "amphoe_code": 7401,
        "province_code": 74
    },
    {
        "district": "โคกขาม",
        "amphoe": "เมืองสมุทรสาคร",
        "province": "สมุทรสาคร",
        "zipcode": 74000,
        "district_code": 740114,
        "amphoe_code": 7401,
        "province_code": 74
    },
    {
        "district": "ดงตะงาว",
        "amphoe": "ดอนพุด",
        "province": "สระบุรี",
        "zipcode": 18210,
        "district_code": 190696,
        "amphoe_code": 1907,
        "province_code": 19
    },
    {
        "district": "ดอนพุด",
        "amphoe": "ดอนพุด",
        "province": "สระบุรี",
        "zipcode": 18210,
        "district_code": 190699,
        "amphoe_code": 1907,
        "province_code": 19
    },
    {
        "district": "บ้านหลวง",
        "amphoe": "ดอนพุด",
        "province": "สระบุรี",
        "zipcode": 18210,
        "district_code": 190697,
        "amphoe_code": 1907,
        "province_code": 19
    },
    {
        "district": "ไผ่หลิ่ว",
        "amphoe": "ดอนพุด",
        "province": "สระบุรี",
        "zipcode": 18210,
        "district_code": 190698,
        "amphoe_code": 1907,
        "province_code": 19
    },
    {
        "district": "ตลาดน้อย",
        "amphoe": "บ้านหมอ",
        "province": "สระบุรี",
        "zipcode": 18130,
        "district_code": 190604,
        "amphoe_code": 1906,
        "province_code": 19
    },
    {
        "district": "บางโขมด",
        "amphoe": "บ้านหมอ",
        "province": "สระบุรี",
        "zipcode": 18130,
        "district_code": 190602,
        "amphoe_code": 1906,
        "province_code": 19
    },
    {
        "district": "บ้านครัว",
        "amphoe": "บ้านหมอ",
        "province": "สระบุรี",
        "zipcode": 18270,
        "district_code": 190608,
        "amphoe_code": 1906,
        "province_code": 19
    },
    {
        "district": "บ้านหมอ",
        "amphoe": "บ้านหมอ",
        "province": "สระบุรี",
        "zipcode": 18130,
        "district_code": 190601,
        "amphoe_code": 1906,
        "province_code": 19
    },
    {
        "district": "สร่างโศก",
        "amphoe": "บ้านหมอ",
        "province": "สระบุรี",
        "zipcode": 18130,
        "district_code": 190603,
        "amphoe_code": 1906,
        "province_code": 19
    },
    {
        "district": "หนองบัว",
        "amphoe": "บ้านหมอ",
        "province": "สระบุรี",
        "zipcode": 18130,
        "district_code": 190609,
        "amphoe_code": 1906,
        "province_code": 19
    },
    {
        "district": "หรเทพ",
        "amphoe": "บ้านหมอ",
        "province": "สระบุรี",
        "zipcode": 18130,
        "district_code": 190605,
        "amphoe_code": 1906,
        "province_code": 19
    },
    {
        "district": "โคกใหญ่",
        "amphoe": "บ้านหมอ",
        "province": "สระบุรี",
        "zipcode": 18130,
        "district_code": 190606,
        "amphoe_code": 1906,
        "province_code": 19
    },
    {
        "district": "ไผ่ขวาง",
        "amphoe": "บ้านหมอ",
        "province": "สระบุรี",
        "zipcode": 18130,
        "district_code": 190607,
        "amphoe_code": 1906,
        "province_code": 19
    },
    {
        "district": "ขุนโขลน",
        "amphoe": "พระพุทธบาท",
        "province": "สระบุรี",
        "zipcode": 18120,
        "district_code": 190902,
        "amphoe_code": 1909,
        "province_code": 19
    },
    {
        "district": "ธารเกษม",
        "amphoe": "พระพุทธบาท",
        "province": "สระบุรี",
        "zipcode": 18120,
        "district_code": 190903,
        "amphoe_code": 1909,
        "province_code": 19
    },
    {
        "district": "นายาว",
        "amphoe": "พระพุทธบาท",
        "province": "สระบุรี",
        "zipcode": 18120,
        "district_code": 190904,
        "amphoe_code": 1909,
        "province_code": 19
    },
    {
        "district": "พระพุทธบาท",
        "amphoe": "พระพุทธบาท",
        "province": "สระบุรี",
        "zipcode": 18120,
        "district_code": 190901,
        "amphoe_code": 1909,
        "province_code": 19
    },
    {
        "district": "พุกร่าง",
        "amphoe": "พระพุทธบาท",
        "province": "สระบุรี",
        "zipcode": 18120,
        "district_code": 190908,
        "amphoe_code": 1909,
        "province_code": 19
    },
    {
        "district": "พุคำจาน",
        "amphoe": "พระพุทธบาท",
        "province": "สระบุรี",
        "zipcode": 18120,
        "district_code": 190905,
        "amphoe_code": 1909,
        "province_code": 19
    },
    {
        "district": "หนองแก",
        "amphoe": "พระพุทธบาท",
        "province": "สระบุรี",
        "zipcode": 18120,
        "district_code": 190909,
        "amphoe_code": 1909,
        "province_code": 19
    },
    {
        "district": "ห้วยป่าหวาย",
        "amphoe": "พระพุทธบาท",
        "province": "สระบุรี",
        "zipcode": 18120,
        "district_code": 190907,
        "amphoe_code": 1909,
        "province_code": 19
    },
    {
        "district": "เขาวง",
        "amphoe": "พระพุทธบาท",
        "province": "สระบุรี",
        "zipcode": 18120,
        "district_code": 190906,
        "amphoe_code": 1909,
        "province_code": 19
    },
    {
        "district": "ซับสนุ่น",
        "amphoe": "มวกเหล็ก",
        "province": "สระบุรี",
        "zipcode": 18220,
        "district_code": 191109,
        "amphoe_code": 1911,
        "province_code": 19
    },
    {
        "district": "มวกเหล็ก",
        "amphoe": "มวกเหล็ก",
        "province": "สระบุรี",
        "zipcode": 18180,
        "district_code": 191101,
        "amphoe_code": 1911,
        "province_code": 19
    },
    {
        "district": "มิตรภาพ",
        "amphoe": "มวกเหล็ก",
        "province": "สระบุรี",
        "zipcode": 18180,
        "district_code": 191102,
        "amphoe_code": 1911,
        "province_code": 19
    },
    {
        "district": "ลำพญากลาง",
        "amphoe": "มวกเหล็ก",
        "province": "สระบุรี",
        "zipcode": 30130,
        "district_code": 191107,
        "amphoe_code": 1911,
        "province_code": 19
    },
    {
        "district": "ลำสมพุง",
        "amphoe": "มวกเหล็ก",
        "province": "สระบุรี",
        "zipcode": 30130,
        "district_code": 191105,
        "amphoe_code": 1911,
        "province_code": 19
    },
    {
        "district": "หนองย่างเสือ",
        "amphoe": "มวกเหล็ก",
        "province": "สระบุรี",
        "zipcode": 18180,
        "district_code": 191104,
        "amphoe_code": 1911,
        "province_code": 19
    },
    {
        "district": "คำพราน",
        "amphoe": "วังม่วง",
        "province": "สระบุรี",
        "zipcode": 18220,
        "district_code": 191106,
        "amphoe_code": 1912,
        "province_code": 19
    },
    {
        "district": "วังม่วง",
        "amphoe": "วังม่วง",
        "province": "สระบุรี",
        "zipcode": 18220,
        "district_code": 191108,
        "amphoe_code": 1912,
        "province_code": 19
    },
    {
        "district": "แสลงพัน",
        "amphoe": "วังม่วง",
        "province": "สระบุรี",
        "zipcode": 18220,
        "district_code": 191103,
        "amphoe_code": 1912,
        "province_code": 19
    },
    {
        "district": "คลองเรือ",
        "amphoe": "วิหารแดง",
        "province": "สระบุรี",
        "zipcode": 18150,
        "district_code": 190403,
        "amphoe_code": 1904,
        "province_code": 19
    },
    {
        "district": "บ้านลำ",
        "amphoe": "วิหารแดง",
        "province": "สระบุรี",
        "zipcode": 18150,
        "district_code": 190402,
        "amphoe_code": 1904,
        "province_code": 19
    },
    {
        "district": "วิหารแดง",
        "amphoe": "วิหารแดง",
        "province": "สระบุรี",
        "zipcode": 18150,
        "district_code": 190404,
        "amphoe_code": 1904,
        "province_code": 19
    },
    {
        "district": "หนองสรวง",
        "amphoe": "วิหารแดง",
        "province": "สระบุรี",
        "zipcode": 18150,
        "district_code": 190405,
        "amphoe_code": 1904,
        "province_code": 19
    },
    {
        "district": "หนองหมู",
        "amphoe": "วิหารแดง",
        "province": "สระบุรี",
        "zipcode": 18150,
        "district_code": 190401,
        "amphoe_code": 1904,
        "province_code": 19
    },
    {
        "district": "เจริญธรรม",
        "amphoe": "วิหารแดง",
        "province": "สระบุรี",
        "zipcode": 18150,
        "district_code": 190406,
        "amphoe_code": 1904,
        "province_code": 19
    },
    {
        "district": "กุ่มหัก",
        "amphoe": "หนองแค",
        "province": "สระบุรี",
        "zipcode": 18140,
        "district_code": 190302,
        "amphoe_code": 1903,
        "province_code": 19
    },
    {
        "district": "คชสิทธิ์",
        "amphoe": "หนองแค",
        "province": "สระบุรี",
        "zipcode": 18250,
        "district_code": 190303,
        "amphoe_code": 1903,
        "province_code": 19
    },
    {
        "district": "บัวลอย",
        "amphoe": "หนองแค",
        "province": "สระบุรี",
        "zipcode": 18230,
        "district_code": 190306,
        "amphoe_code": 1903,
        "province_code": 19
    },
    {
        "district": "หนองจรเข้",
        "amphoe": "หนองแค",
        "province": "สระบุรี",
        "zipcode": 18140,
        "district_code": 190314,
        "amphoe_code": 1903,
        "province_code": 19
    },
    {
        "district": "หนองจิก",
        "amphoe": "หนองแค",
        "province": "สระบุรี",
        "zipcode": 18230,
        "district_code": 190313,
        "amphoe_code": 1903,
        "province_code": 19
    },
    {
        "district": "หนองนาก",
        "amphoe": "หนองแค",
        "province": "สระบุรี",
        "zipcode": 18230,
        "district_code": 190315,
        "amphoe_code": 1903,
        "province_code": 19
    },
    {
        "district": "หนองปลาหมอ",
        "amphoe": "หนองแค",
        "province": "สระบุรี",
        "zipcode": 18140,
        "district_code": 190316,
        "amphoe_code": 1903,
        "province_code": 19
    },
    {
        "district": "หนองปลิง",
        "amphoe": "หนองแค",
        "province": "สระบุรี",
        "zipcode": 18140,
        "district_code": 190317,
        "amphoe_code": 1903,
        "province_code": 19
    },
    {
        "district": "หนองแขม",
        "amphoe": "หนองแค",
        "province": "สระบุรี",
        "zipcode": 18140,
        "district_code": 190312,
        "amphoe_code": 1903,
        "province_code": 19
    },
    {
        "district": "หนองแค",
        "amphoe": "หนองแค",
        "province": "สระบุรี",
        "zipcode": 18140,
        "district_code": 190301,
        "amphoe_code": 1903,
        "province_code": 19
    },
    {
        "district": "หนองโรง",
        "amphoe": "หนองแค",
        "province": "สระบุรี",
        "zipcode": 18140,
        "district_code": 190318,
        "amphoe_code": 1903,
        "province_code": 19
    },
    {
        "district": "หนองไข่น้ำ",
        "amphoe": "หนองแค",
        "province": "สระบุรี",
        "zipcode": 18140,
        "district_code": 190311,
        "amphoe_code": 1903,
        "province_code": 19
    },
    {
        "district": "ห้วยขมิ้น",
        "amphoe": "หนองแค",
        "province": "สระบุรี",
        "zipcode": 18230,
        "district_code": 190309,
        "amphoe_code": 1903,
        "province_code": 19
    },
    {
        "district": "ห้วยทราย",
        "amphoe": "หนองแค",
        "province": "สระบุรี",
        "zipcode": 18230,
        "district_code": 190310,
        "amphoe_code": 1903,
        "province_code": 19
    },
    {
        "district": "โคกตูม",
        "amphoe": "หนองแค",
        "province": "สระบุรี",
        "zipcode": 18250,
        "district_code": 190304,
        "amphoe_code": 1903,
        "province_code": 19
    },
    {
        "district": "โคกแย้",
        "amphoe": "หนองแค",
        "province": "สระบุรี",
        "zipcode": 18230,
        "district_code": 190305,
        "amphoe_code": 1903,
        "province_code": 19
    },
    {
        "district": "โพนทอง",
        "amphoe": "หนองแค",
        "province": "สระบุรี",
        "zipcode": 18250,
        "district_code": 190308,
        "amphoe_code": 1903,
        "province_code": 19
    },
    {
        "district": "ไผ่ต่ำ",
        "amphoe": "หนองแค",
        "province": "สระบุรี",
        "zipcode": 18140,
        "district_code": 190307,
        "amphoe_code": 1903,
        "province_code": 19
    },
    {
        "district": "ม่วงหวาน",
        "amphoe": "หนองแซง",
        "province": "สระบุรี",
        "zipcode": 18170,
        "district_code": 190508,
        "amphoe_code": 1905,
        "province_code": 19
    },
    {
        "district": "หนองกบ",
        "amphoe": "หนองแซง",
        "province": "สระบุรี",
        "zipcode": 18170,
        "district_code": 190505,
        "amphoe_code": 1905,
        "province_code": 19
    },
    {
        "district": "หนองควายโซ",
        "amphoe": "หนองแซง",
        "province": "สระบุรี",
        "zipcode": 18170,
        "district_code": 190502,
        "amphoe_code": 1905,
        "province_code": 19
    },
    {
        "district": "หนองสีดา",
        "amphoe": "หนองแซง",
        "province": "สระบุรี",
        "zipcode": 18170,
        "district_code": 190504,
        "amphoe_code": 1905,
        "province_code": 19
    },
    {
        "district": "หนองหัวโพ",
        "amphoe": "หนองแซง",
        "province": "สระบุรี",
        "zipcode": 18170,
        "district_code": 190503,
        "amphoe_code": 1905,
        "province_code": 19
    },
    {
        "district": "หนองแซง",
        "amphoe": "หนองแซง",
        "province": "สระบุรี",
        "zipcode": 18170,
        "district_code": 190501,
        "amphoe_code": 1905,
        "province_code": 19
    },
    {
        "district": "เขาดิน",
        "amphoe": "หนองแซง",
        "province": "สระบุรี",
        "zipcode": 18170,
        "district_code": 190509,
        "amphoe_code": 1905,
        "province_code": 19
    },
    {
        "district": "โคกสะอาด",
        "amphoe": "หนองแซง",
        "province": "สระบุรี",
        "zipcode": 18170,
        "district_code": 190507,
        "amphoe_code": 1905,
        "province_code": 19
    },
    {
        "district": "ไก่เส่า",
        "amphoe": "หนองแซง",
        "province": "สระบุรี",
        "zipcode": 18170,
        "district_code": 190506,
        "amphoe_code": 1905,
        "province_code": 19
    },
    {
        "district": "ดอนทอง",
        "amphoe": "หนองโดน",
        "province": "สระบุรี",
        "zipcode": 18190,
        "district_code": 190803,
        "amphoe_code": 1908,
        "province_code": 19
    },
    {
        "district": "บ้านกลับ",
        "amphoe": "หนองโดน",
        "province": "สระบุรี",
        "zipcode": 18190,
        "district_code": 190802,
        "amphoe_code": 1908,
        "province_code": 19
    },
    {
        "district": "บ้านโปร่ง",
        "amphoe": "หนองโดน",
        "province": "สระบุรี",
        "zipcode": 18190,
        "district_code": 190804,
        "amphoe_code": 1908,
        "province_code": 19
    },
    {
        "district": "หนองโดน",
        "amphoe": "หนองโดน",
        "province": "สระบุรี",
        "zipcode": 18190,
        "district_code": 190801,
        "amphoe_code": 1908,
        "province_code": 19
    },
    {
        "district": "บ้านแก้ง",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "สระบุรี",
        "zipcode": 18000,
        "district_code": 190115,
        "amphoe_code": 1913,
        "province_code": 19
    },
    {
        "district": "ผึ้งรวง",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "สระบุรี",
        "zipcode": 18000,
        "district_code": 190116,
        "amphoe_code": 1913,
        "province_code": 19
    },
    {
        "district": "พุแค",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "สระบุรี",
        "zipcode": 18240,
        "district_code": 190103,
        "amphoe_code": 1913,
        "province_code": 19
    },
    {
        "district": "หน้าพระลาน",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "สระบุรี",
        "zipcode": 18240,
        "district_code": 190102,
        "amphoe_code": 1913,
        "province_code": 19
    },
    {
        "district": "ห้วยบง",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "สระบุรี",
        "zipcode": 18000,
        "district_code": 190104,
        "amphoe_code": 1913,
        "province_code": 19
    },
    {
        "district": "เขาดินพัฒนา",
        "amphoe": "เฉลิมพระเกียรติ",
        "province": "สระบุรี",
        "zipcode": 18000,
        "district_code": 190117,
        "amphoe_code": 1913,
        "province_code": 19
    },
    {
        "district": "กุดนกเปล้า",
        "amphoe": "เมืองสระบุรี",
        "province": "สระบุรี",
        "zipcode": 18000,
        "district_code": 190112,
        "amphoe_code": 1901,
        "province_code": 19
    },
    {
        "district": "ดาวเรือง",
        "amphoe": "เมืองสระบุรี",
        "province": "สระบุรี",
        "zipcode": 18000,
        "district_code": 190105,
        "amphoe_code": 1901,
        "province_code": 19
    },
    {
        "district": "ตลิ่งชัน",
        "amphoe": "เมืองสระบุรี",
        "province": "สระบุรี",
        "zipcode": 18000,
        "district_code": 190113,
        "amphoe_code": 1901,
        "province_code": 19
    },
    {
        "district": "ตะกุด",
        "amphoe": "เมืองสระบุรี",
        "province": "สระบุรี",
        "zipcode": 18000,
        "district_code": 190114,
        "amphoe_code": 1901,
        "province_code": 19
    },
    {
        "district": "นาโฉง",
        "amphoe": "เมืองสระบุรี",
        "province": "สระบุรี",
        "zipcode": 18000,
        "district_code": 190106,
        "amphoe_code": 1901,
        "province_code": 19
    },
    {
        "district": "ปากข้าวสาร",
        "amphoe": "เมืองสระบุรี",
        "province": "สระบุรี",
        "zipcode": 18000,
        "district_code": 190110,
        "amphoe_code": 1901,
        "province_code": 19
    },
    {
        "district": "ปากเพรียว",
        "amphoe": "เมืองสระบุรี",
        "province": "สระบุรี",
        "zipcode": 18000,
        "district_code": 190101,
        "amphoe_code": 1901,
        "province_code": 19
    },
    {
        "district": "หนองปลาไหล",
        "amphoe": "เมืองสระบุรี",
        "province": "สระบุรี",
        "zipcode": 18000,
        "district_code": 190111,
        "amphoe_code": 1901,
        "province_code": 19
    },
    {
        "district": "หนองยาว",
        "amphoe": "เมืองสระบุรี",
        "province": "สระบุรี",
        "zipcode": 18000,
        "district_code": 190109,
        "amphoe_code": 1901,
        "province_code": 19
    },
    {
        "district": "หนองโน",
        "amphoe": "เมืองสระบุรี",
        "province": "สระบุรี",
        "zipcode": 18000,
        "district_code": 190108,
        "amphoe_code": 1901,
        "province_code": 19
    },
    {
        "district": "โคกสว่าง",
        "amphoe": "เมืองสระบุรี",
        "province": "สระบุรี",
        "zipcode": 18000,
        "district_code": 190107,
        "amphoe_code": 1901,
        "province_code": 19
    },
    {
        "district": "งิ้วงาม",
        "amphoe": "เสาไห้",
        "province": "สระบุรี",
        "zipcode": 18160,
        "district_code": 191004,
        "amphoe_code": 1910,
        "province_code": 19
    },
    {
        "district": "ต้นตาล",
        "amphoe": "เสาไห้",
        "province": "สระบุรี",
        "zipcode": 18160,
        "district_code": 191006,
        "amphoe_code": 1910,
        "province_code": 19
    },
    {
        "district": "ท่าช้าง",
        "amphoe": "เสาไห้",
        "province": "สระบุรี",
        "zipcode": 18160,
        "district_code": 191007,
        "amphoe_code": 1910,
        "province_code": 19
    },
    {
        "district": "บ้านยาง",
        "amphoe": "เสาไห้",
        "province": "สระบุรี",
        "zipcode": 18160,
        "district_code": 191002,
        "amphoe_code": 1910,
        "province_code": 19
    },
    {
        "district": "พระยาทด",
        "amphoe": "เสาไห้",
        "province": "สระบุรี",
        "zipcode": 18160,
        "district_code": 191008,
        "amphoe_code": 1910,
        "province_code": 19
    },
    {
        "district": "ม่วงงาม",
        "amphoe": "เสาไห้",
        "province": "สระบุรี",
        "zipcode": 18160,
        "district_code": 191009,
        "amphoe_code": 1910,
        "province_code": 19
    },
    {
        "district": "ศาลารีไทย",
        "amphoe": "เสาไห้",
        "province": "สระบุรี",
        "zipcode": 18160,
        "district_code": 191005,
        "amphoe_code": 1910,
        "province_code": 19
    },
    {
        "district": "สวนดอกไม้",
        "amphoe": "เสาไห้",
        "province": "สระบุรี",
        "zipcode": 18160,
        "district_code": 191012,
        "amphoe_code": 1910,
        "province_code": 19
    },
    {
        "district": "หัวปลวก",
        "amphoe": "เสาไห้",
        "province": "สระบุรี",
        "zipcode": 18160,
        "district_code": 191003,
        "amphoe_code": 1910,
        "province_code": 19
    },
    {
        "district": "เมืองเก่า",
        "amphoe": "เสาไห้",
        "province": "สระบุรี",
        "zipcode": 18160,
        "district_code": 191011,
        "amphoe_code": 1910,
        "province_code": 19
    },
    {
        "district": "เริงราง",
        "amphoe": "เสาไห้",
        "province": "สระบุรี",
        "zipcode": 18160,
        "district_code": 191010,
        "amphoe_code": 1910,
        "province_code": 19
    },
    {
        "district": "เสาไห้",
        "amphoe": "เสาไห้",
        "province": "สระบุรี",
        "zipcode": 18160,
        "district_code": 191001,
        "amphoe_code": 1910,
        "province_code": 19
    },
    {
        "district": "ชะอม",
        "amphoe": "แก่งคอย",
        "province": "สระบุรี",
        "zipcode": 18110,
        "district_code": 190210,
        "amphoe_code": 1902,
        "province_code": 19
    },
    {
        "district": "ชำผักแพว",
        "amphoe": "แก่งคอย",
        "province": "สระบุรี",
        "zipcode": 18110,
        "district_code": 190213,
        "amphoe_code": 1902,
        "province_code": 19
    },
    {
        "district": "ตาลเดี่ยว",
        "amphoe": "แก่งคอย",
        "province": "สระบุรี",
        "zipcode": 18110,
        "district_code": 190203,
        "amphoe_code": 1902,
        "province_code": 19
    },
    {
        "district": "ทับกวาง",
        "amphoe": "แก่งคอย",
        "province": "สระบุรี",
        "zipcode": 18260,
        "district_code": 190202,
        "amphoe_code": 1902,
        "province_code": 19
    },
    {
        "district": "ท่าคล้อ",
        "amphoe": "แก่งคอย",
        "province": "สระบุรี",
        "zipcode": 18110,
        "district_code": 190205,
        "amphoe_code": 1902,
        "province_code": 19
    },
    {
        "district": "ท่าตูม",
        "amphoe": "แก่งคอย",
        "province": "สระบุรี",
        "zipcode": 18110,
        "district_code": 190209,
        "amphoe_code": 1902,
        "province_code": 19
    },
    {
        "district": "ท่ามะปราง",
        "amphoe": "แก่งคอย",
        "province": "สระบุรี",
        "zipcode": 18110,
        "district_code": 190215,
        "amphoe_code": 1902,
        "province_code": 19
    },
    {
        "district": "บ้านธาตุ",
        "amphoe": "แก่งคอย",
        "province": "สระบุรี",
        "zipcode": 18110,
        "district_code": 190207,
        "amphoe_code": 1902,
        "province_code": 19
    },
    {
        "district": "บ้านป่า",
        "amphoe": "แก่งคอย",
        "province": "สระบุรี",
        "zipcode": 18110,
        "district_code": 190208,
        "amphoe_code": 1902,
        "province_code": 19
    },
    {
        "district": "สองคอน",
        "amphoe": "แก่งคอย",
        "province": "สระบุรี",
        "zipcode": 18110,
        "district_code": 190211,
        "amphoe_code": 1902,
        "province_code": 19
    },
    {
        "district": "หินซ้อน",
        "amphoe": "แก่งคอย",
        "province": "สระบุรี",
        "zipcode": 18110,
        "district_code": 190206,
        "amphoe_code": 1902,
        "province_code": 19
    },
    {
        "district": "ห้วยแห้ง",
        "amphoe": "แก่งคอย",
        "province": "สระบุรี",
        "zipcode": 18110,
        "district_code": 190204,
        "amphoe_code": 1902,
        "province_code": 19
    },
    {
        "district": "เตาปูน",
        "amphoe": "แก่งคอย",
        "province": "สระบุรี",
        "zipcode": 18110,
        "district_code": 190212,
        "amphoe_code": 1902,
        "province_code": 19
    },
    {
        "district": "แก่งคอย",
        "amphoe": "แก่งคอย",
        "province": "สระบุรี",
        "zipcode": 18110,
        "district_code": 190201,
        "amphoe_code": 1902,
        "province_code": 19
    },
    {
        "district": "คลองหาด",
        "amphoe": "คลองหาด",
        "province": "สระแก้ว",
        "zipcode": 27260,
        "district_code": 270201,
        "amphoe_code": 2702,
        "province_code": 27
    },
    {
        "district": "คลองไก่เถื่อน",
        "amphoe": "คลองหาด",
        "province": "สระแก้ว",
        "zipcode": 27260,
        "district_code": 270205,
        "amphoe_code": 2702,
        "province_code": 27
    },
    {
        "district": "ซับมะกรูด",
        "amphoe": "คลองหาด",
        "province": "สระแก้ว",
        "zipcode": 27260,
        "district_code": 270203,
        "amphoe_code": 2702,
        "province_code": 27
    },
    {
        "district": "เบญจขร",
        "amphoe": "คลองหาด",
        "province": "สระแก้ว",
        "zipcode": 27260,
        "district_code": 270206,
        "amphoe_code": 2702,
        "province_code": 27
    },
    {
        "district": "ไทยอุดม",
        "amphoe": "คลองหาด",
        "province": "สระแก้ว",
        "zipcode": 27260,
        "district_code": 270202,
        "amphoe_code": 2702,
        "province_code": 27
    },
    {
        "district": "ไทรทอง",
        "amphoe": "คลองหาด",
        "province": "สระแก้ว",
        "zipcode": 27260,
        "district_code": 270207,
        "amphoe_code": 2702,
        "province_code": 27
    },
    {
        "district": "ไทรเดี่ยว",
        "amphoe": "คลองหาด",
        "province": "สระแก้ว",
        "zipcode": 27260,
        "district_code": 270204,
        "amphoe_code": 2702,
        "province_code": 27
    },
    {
        "district": "ตาพระยา",
        "amphoe": "ตาพระยา",
        "province": "สระแก้ว",
        "zipcode": 27180,
        "district_code": 270301,
        "amphoe_code": 2703,
        "province_code": 27
    },
    {
        "district": "ทัพราช",
        "amphoe": "ตาพระยา",
        "province": "สระแก้ว",
        "zipcode": 27180,
        "district_code": 270306,
        "amphoe_code": 2703,
        "province_code": 27
    },
    {
        "district": "ทัพเสด็จ",
        "amphoe": "ตาพระยา",
        "province": "สระแก้ว",
        "zipcode": 27180,
        "district_code": 270302,
        "amphoe_code": 2703,
        "province_code": 27
    },
    {
        "district": "ทัพไทย",
        "amphoe": "ตาพระยา",
        "province": "สระแก้ว",
        "zipcode": 27180,
        "district_code": 270307,
        "amphoe_code": 2703,
        "province_code": 27
    },
    {
        "district": "โคคลาน",
        "amphoe": "ตาพระยา",
        "province": "สระแก้ว",
        "zipcode": 27180,
        "district_code": 270309,
        "amphoe_code": 2703,
        "province_code": 27
    },
    {
        "district": "คลองหินปูน",
        "amphoe": "วังน้ำเย็น",
        "province": "สระแก้ว",
        "zipcode": 27210,
        "district_code": 270405,
        "amphoe_code": 2704,
        "province_code": 27
    },
    {
        "district": "ตาหลังใน",
        "amphoe": "วังน้ำเย็น",
        "province": "สระแก้ว",
        "zipcode": 27210,
        "district_code": 270403,
        "amphoe_code": 2704,
        "province_code": 27
    },
    {
        "district": "ทุ่งมหาเจริญ",
        "amphoe": "วังน้ำเย็น",
        "province": "สระแก้ว",
        "zipcode": 27210,
        "district_code": 270406,
        "amphoe_code": 2704,
        "province_code": 27
    },
    {
        "district": "วังน้ำเย็น",
        "amphoe": "วังน้ำเย็น",
        "province": "สระแก้ว",
        "zipcode": 27210,
        "district_code": 270401,
        "amphoe_code": 2704,
        "province_code": 27
    },
    {
        "district": "วังทอง",
        "amphoe": "วังสมบูรณ์",
        "province": "สระแก้ว",
        "zipcode": 27250,
        "district_code": 270407,
        "amphoe_code": 2709,
        "province_code": 27
    },
    {
        "district": "วังสมบูรณ์",
        "amphoe": "วังสมบูรณ์",
        "province": "สระแก้ว",
        "zipcode": 27250,
        "district_code": 270402,
        "amphoe_code": 2709,
        "province_code": 27
    },
    {
        "district": "วังใหม่",
        "amphoe": "วังสมบูรณ์",
        "province": "สระแก้ว",
        "zipcode": 27250,
        "district_code": 270404,
        "amphoe_code": 2709,
        "province_code": 27
    },
    {
        "district": "ช่องกุ่ม",
        "amphoe": "วัฒนานคร",
        "province": "สระแก้ว",
        "zipcode": 27160,
        "district_code": 270506,
        "amphoe_code": 2705,
        "province_code": 27
    },
    {
        "district": "ท่าเกวียน",
        "amphoe": "วัฒนานคร",
        "province": "สระแก้ว",
        "zipcode": 27160,
        "district_code": 270502,
        "amphoe_code": 2705,
        "province_code": 27
    },
    {
        "district": "ผักขะ",
        "amphoe": "วัฒนานคร",
        "province": "สระแก้ว",
        "zipcode": 27160,
        "district_code": 270503,
        "amphoe_code": 2705,
        "province_code": 27
    },
    {
        "district": "วัฒนานคร",
        "amphoe": "วัฒนานคร",
        "province": "สระแก้ว",
        "zipcode": 27160,
        "district_code": 270501,
        "amphoe_code": 2705,
        "province_code": 27
    },
    {
        "district": "หนองตะเคียนบอน",
        "amphoe": "วัฒนานคร",
        "province": "สระแก้ว",
        "zipcode": 27160,
        "district_code": 270510,
        "amphoe_code": 2705,
        "province_code": 27
    },
    {
        "district": "หนองน้ำใส",
        "amphoe": "วัฒนานคร",
        "province": "สระแก้ว",
        "zipcode": 27160,
        "district_code": 270505,
        "amphoe_code": 2705,
        "province_code": 27
    },
    {
        "district": "หนองหมากฝ้าย",
        "amphoe": "วัฒนานคร",
        "province": "สระแก้ว",
        "zipcode": 27160,
        "district_code": 270509,
        "amphoe_code": 2705,
        "province_code": 27
    },
    {
        "district": "หนองแวง",
        "amphoe": "วัฒนานคร",
        "province": "สระแก้ว",
        "zipcode": 27160,
        "district_code": 270304,
        "amphoe_code": 2705,
        "province_code": 27
    },
    {
        "district": "ห้วยโจด",
        "amphoe": "วัฒนานคร",
        "province": "สระแก้ว",
        "zipcode": 27160,
        "district_code": 270511,
        "amphoe_code": 2705,
        "province_code": 27
    },
    {
        "district": "แซร์ออ",
        "amphoe": "วัฒนานคร",
        "province": "สระแก้ว",
        "zipcode": 27160,
        "district_code": 270508,
        "amphoe_code": 2705,
        "province_code": 27
    },
    {
        "district": "โนนหมากเค็ง",
        "amphoe": "วัฒนานคร",
        "province": "สระแก้ว",
        "zipcode": 27160,
        "district_code": 270504,
        "amphoe_code": 2705,
        "province_code": 27
    },
    {
        "district": "คลองทับจันทร์",
        "amphoe": "อรัญประเทศ",
        "province": "สระแก้ว",
        "zipcode": 27120,
        "district_code": 270611,
        "amphoe_code": 2706,
        "province_code": 27
    },
    {
        "district": "คลองน้ำใส",
        "amphoe": "อรัญประเทศ",
        "province": "สระแก้ว",
        "zipcode": 27120,
        "district_code": 270604,
        "amphoe_code": 2706,
        "province_code": 27
    },
    {
        "district": "ทับพริก",
        "amphoe": "อรัญประเทศ",
        "province": "สระแก้ว",
        "zipcode": 27120,
        "district_code": 270607,
        "amphoe_code": 2706,
        "province_code": 27
    },
    {
        "district": "ท่าข้าม",
        "amphoe": "อรัญประเทศ",
        "province": "สระแก้ว",
        "zipcode": 27120,
        "district_code": 270605,
        "amphoe_code": 2706,
        "province_code": 27
    },
    {
        "district": "บ้านด่าน",
        "amphoe": "อรัญประเทศ",
        "province": "สระแก้ว",
        "zipcode": 27120,
        "district_code": 270613,
        "amphoe_code": 2706,
        "province_code": 27
    },
    {
        "district": "บ้านใหม่หนองไทร",
        "amphoe": "อรัญประเทศ",
        "province": "สระแก้ว",
        "zipcode": 27120,
        "district_code": 270608,
        "amphoe_code": 2706,
        "province_code": 27
    },
    {
        "district": "ป่าไร่",
        "amphoe": "อรัญประเทศ",
        "province": "สระแก้ว",
        "zipcode": 27120,
        "district_code": 270606,
        "amphoe_code": 2706,
        "province_code": 27
    },
    {
        "district": "ผ่านศึก",
        "amphoe": "อรัญประเทศ",
        "province": "สระแก้ว",
        "zipcode": 27120,
        "district_code": 270609,
        "amphoe_code": 2706,
        "province_code": 27
    },
    {
        "district": "ฟากห้วย",
        "amphoe": "อรัญประเทศ",
        "province": "สระแก้ว",
        "zipcode": 27120,
        "district_code": 270612,
        "amphoe_code": 2706,
        "province_code": 27
    },
    {
        "district": "หนองสังข์",
        "amphoe": "อรัญประเทศ",
        "province": "สระแก้ว",
        "zipcode": 27120,
        "district_code": 270610,
        "amphoe_code": 2706,
        "province_code": 27
    },
    {
        "district": "หันทราย",
        "amphoe": "อรัญประเทศ",
        "province": "สระแก้ว",
        "zipcode": 27120,
        "district_code": 270603,
        "amphoe_code": 2706,
        "province_code": 27
    },
    {
        "district": "อรัญประเทศ",
        "amphoe": "อรัญประเทศ",
        "province": "สระแก้ว",
        "zipcode": 27120,
        "district_code": 270601,
        "amphoe_code": 2706,
        "province_code": 27
    },
    {
        "district": "เมืองไผ่",
        "amphoe": "อรัญประเทศ",
        "province": "สระแก้ว",
        "zipcode": 27120,
        "district_code": 270602,
        "amphoe_code": 2706,
        "province_code": 27
    },
    {
        "district": "พระเพลิง",
        "amphoe": "เขาฉกรรจ์",
        "province": "สระแก้ว",
        "zipcode": 27000,
        "district_code": 270110,
        "amphoe_code": 2707,
        "province_code": 27
    },
    {
        "district": "หนองหว้า",
        "amphoe": "เขาฉกรรจ์",
        "province": "สระแก้ว",
        "zipcode": 27000,
        "district_code": 270109,
        "amphoe_code": 2707,
        "province_code": 27
    },
    {
        "district": "เขาฉกรรจ์",
        "amphoe": "เขาฉกรรจ์",
        "province": "สระแก้ว",
        "zipcode": 27000,
        "district_code": 270107,
        "amphoe_code": 2707,
        "province_code": 27
    },
    {
        "district": "เขาสามสิบ",
        "amphoe": "เขาฉกรรจ์",
        "province": "สระแก้ว",
        "zipcode": 27000,
        "district_code": 270112,
        "amphoe_code": 2707,
        "province_code": 27
    },
    {
        "district": "ท่าเกษม",
        "amphoe": "เมืองสระแก้ว",
        "province": "สระแก้ว",
        "zipcode": 27000,
        "district_code": 270106,
        "amphoe_code": 2701,
        "province_code": 27
    },
    {
        "district": "ท่าแยก",
        "amphoe": "เมืองสระแก้ว",
        "province": "สระแก้ว",
        "zipcode": 27000,
        "district_code": 270105,
        "amphoe_code": 2701,
        "province_code": 27
    },
    {
        "district": "บ้านแก้ง",
        "amphoe": "เมืองสระแก้ว",
        "province": "สระแก้ว",
        "zipcode": 27000,
        "district_code": 270102,
        "amphoe_code": 2701,
        "province_code": 27
    },
    {
        "district": "ศาลาลำดวน",
        "amphoe": "เมืองสระแก้ว",
        "province": "สระแก้ว",
        "zipcode": 27000,
        "district_code": 270103,
        "amphoe_code": 2701,
        "province_code": 27
    },
    {
        "district": "สระขวัญ",
        "amphoe": "เมืองสระแก้ว",
        "province": "สระแก้ว",
        "zipcode": 27000,
        "district_code": 270108,
        "amphoe_code": 2701,
        "province_code": 27
    },
    {
        "district": "สระแก้ว",
        "amphoe": "เมืองสระแก้ว",
        "province": "สระแก้ว",
        "zipcode": 27000,
        "district_code": 270101,
        "amphoe_code": 2701,
        "province_code": 27
    },
    {
        "district": "หนองบอน",
        "amphoe": "เมืองสระแก้ว",
        "province": "สระแก้ว",
        "zipcode": 27000,
        "district_code": 270111,
        "amphoe_code": 2701,
        "province_code": 27
    },
    {
        "district": "โคกปี่ฆ้อง",
        "amphoe": "เมืองสระแก้ว",
        "province": "สระแก้ว",
        "zipcode": 27000,
        "district_code": 270104,
        "amphoe_code": 2701,
        "province_code": 27
    },
    {
        "district": "หนองม่วง",
        "amphoe": "โคกสูง",
        "province": "สระแก้ว",
        "zipcode": 27180,
        "district_code": 270305,
        "amphoe_code": 2708,
        "province_code": 27
    },
    {
        "district": "หนองแวง",
        "amphoe": "โคกสูง",
        "province": "สระแก้ว",
        "zipcode": 27180,
        "district_code": 270304,
        "amphoe_code": 2708,
        "province_code": 27
    },
    {
        "district": "โคกสูง",
        "amphoe": "โคกสูง",
        "province": "สระแก้ว",
        "zipcode": 27120,
        "district_code": 270303,
        "amphoe_code": 2708,
        "province_code": 27
    },
    {
        "district": "โนนหมากมุ่น",
        "amphoe": "โคกสูง",
        "province": "สระแก้ว",
        "zipcode": 27120,
        "district_code": 270308,
        "amphoe_code": 2708,
        "province_code": 27
    },
    {
        "district": "คอทราย",
        "amphoe": "ค่ายบางระจัน",
        "province": "สิงห์บุรี",
        "zipcode": 16150,
        "district_code": 170305,
        "amphoe_code": 1703,
        "province_code": 17
    },
    {
        "district": "ท่าข้าม",
        "amphoe": "ค่ายบางระจัน",
        "province": "สิงห์บุรี",
        "zipcode": 16150,
        "district_code": 170304,
        "amphoe_code": 1703,
        "province_code": 17
    },
    {
        "district": "บางระจัน",
        "amphoe": "ค่ายบางระจัน",
        "province": "สิงห์บุรี",
        "zipcode": 16150,
        "district_code": 170302,
        "amphoe_code": 1703,
        "province_code": 17
    },
    {
        "district": "หนองกระทุ่ม",
        "amphoe": "ค่ายบางระจัน",
        "province": "สิงห์บุรี",
        "zipcode": 16150,
        "district_code": 170306,
        "amphoe_code": 1703,
        "province_code": 17
    },
    {
        "district": "โพทะเล",
        "amphoe": "ค่ายบางระจัน",
        "province": "สิงห์บุรี",
        "zipcode": 16150,
        "district_code": 170301,
        "amphoe_code": 1703,
        "province_code": 17
    },
    {
        "district": "โพสังโฆ",
        "amphoe": "ค่ายบางระจัน",
        "province": "สิงห์บุรี",
        "zipcode": 16150,
        "district_code": 170303,
        "amphoe_code": 1703,
        "province_code": 17
    },
    {
        "district": "ถอนสมอ",
        "amphoe": "ท่าช้าง",
        "province": "สิงห์บุรี",
        "zipcode": 16140,
        "district_code": 170501,
        "amphoe_code": 1705,
        "province_code": 17
    },
    {
        "district": "พิกุลทอง",
        "amphoe": "ท่าช้าง",
        "province": "สิงห์บุรี",
        "zipcode": 16140,
        "district_code": 170504,
        "amphoe_code": 1705,
        "province_code": 17
    },
    {
        "district": "วิหารขาว",
        "amphoe": "ท่าช้าง",
        "province": "สิงห์บุรี",
        "zipcode": 16140,
        "district_code": 170503,
        "amphoe_code": 1705,
        "province_code": 17
    },
    {
        "district": "โพประจักษ์",
        "amphoe": "ท่าช้าง",
        "province": "สิงห์บุรี",
        "zipcode": 16140,
        "district_code": 170502,
        "amphoe_code": 1705,
        "province_code": 17
    },
    {
        "district": "บ้านจ่า",
        "amphoe": "บางระจัน",
        "province": "สิงห์บุรี",
        "zipcode": 16130,
        "district_code": 170206,
        "amphoe_code": 1702,
        "province_code": 17
    },
    {
        "district": "พักทัน",
        "amphoe": "บางระจัน",
        "province": "สิงห์บุรี",
        "zipcode": 16130,
        "district_code": 170207,
        "amphoe_code": 1702,
        "province_code": 17
    },
    {
        "district": "สระแจง",
        "amphoe": "บางระจัน",
        "province": "สิงห์บุรี",
        "zipcode": 16130,
        "district_code": 170208,
        "amphoe_code": 1702,
        "province_code": 17
    },
    {
        "district": "สิงห์",
        "amphoe": "บางระจัน",
        "province": "สิงห์บุรี",
        "zipcode": 16130,
        "district_code": 170201,
        "amphoe_code": 1702,
        "province_code": 17
    },
    {
        "district": "เชิงกลัด",
        "amphoe": "บางระจัน",
        "province": "สิงห์บุรี",
        "zipcode": 16130,
        "district_code": 170203,
        "amphoe_code": 1702,
        "province_code": 17
    },
    {
        "district": "แม่ลา",
        "amphoe": "บางระจัน",
        "province": "สิงห์บุรี",
        "zipcode": 16130,
        "district_code": 170205,
        "amphoe_code": 1702,
        "province_code": 17
    },
    {
        "district": "โพชนไก่",
        "amphoe": "บางระจัน",
        "province": "สิงห์บุรี",
        "zipcode": 16130,
        "district_code": 170204,
        "amphoe_code": 1702,
        "province_code": 17
    },
    {
        "district": "ไม้ดัด",
        "amphoe": "บางระจัน",
        "province": "สิงห์บุรี",
        "zipcode": 16130,
        "district_code": 170202,
        "amphoe_code": 1702,
        "province_code": 17
    },
    {
        "district": "บางน้ำเชี่ยว",
        "amphoe": "พรหมบุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16120,
        "district_code": 170403,
        "amphoe_code": 1704,
        "province_code": 17
    },
    {
        "district": "บ้านหม้อ",
        "amphoe": "พรหมบุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16120,
        "district_code": 170404,
        "amphoe_code": 1704,
        "province_code": 17
    },
    {
        "district": "บ้านแป้ง",
        "amphoe": "พรหมบุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16120,
        "district_code": 170405,
        "amphoe_code": 1704,
        "province_code": 17
    },
    {
        "district": "พรหมบุรี",
        "amphoe": "พรหมบุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16160,
        "district_code": 170402,
        "amphoe_code": 1704,
        "province_code": 17
    },
    {
        "district": "พระงาม",
        "amphoe": "พรหมบุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16120,
        "district_code": 170401,
        "amphoe_code": 1704,
        "province_code": 17
    },
    {
        "district": "หัวป่า",
        "amphoe": "พรหมบุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16120,
        "district_code": 170406,
        "amphoe_code": 1704,
        "province_code": 17
    },
    {
        "district": "โรงช้าง",
        "amphoe": "พรหมบุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16120,
        "district_code": 170407,
        "amphoe_code": 1704,
        "province_code": 17
    },
    {
        "district": "งิ้วราย",
        "amphoe": "อินทร์บุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16110,
        "district_code": 170604,
        "amphoe_code": 1706,
        "province_code": 17
    },
    {
        "district": "ชีน้ำร้าย",
        "amphoe": "อินทร์บุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16110,
        "district_code": 170605,
        "amphoe_code": 1706,
        "province_code": 17
    },
    {
        "district": "ทองเอน",
        "amphoe": "อินทร์บุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16110,
        "district_code": 170608,
        "amphoe_code": 1706,
        "province_code": 17
    },
    {
        "district": "ทับยา",
        "amphoe": "อินทร์บุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16110,
        "district_code": 170603,
        "amphoe_code": 1706,
        "province_code": 17
    },
    {
        "district": "ท่างาม",
        "amphoe": "อินทร์บุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16110,
        "district_code": 170606,
        "amphoe_code": 1706,
        "province_code": 17
    },
    {
        "district": "น้ำตาล",
        "amphoe": "อินทร์บุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16110,
        "district_code": 170607,
        "amphoe_code": 1706,
        "province_code": 17
    },
    {
        "district": "ประศุก",
        "amphoe": "อินทร์บุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16110,
        "district_code": 170602,
        "amphoe_code": 1706,
        "province_code": 17
    },
    {
        "district": "ห้วยชัน",
        "amphoe": "อินทร์บุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16110,
        "district_code": 170609,
        "amphoe_code": 1706,
        "province_code": 17
    },
    {
        "district": "อินทร์บุรี",
        "amphoe": "อินทร์บุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16110,
        "district_code": 170601,
        "amphoe_code": 1706,
        "province_code": 17
    },
    {
        "district": "โพธิ์ชัย",
        "amphoe": "อินทร์บุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16110,
        "district_code": 170610,
        "amphoe_code": 1706,
        "province_code": 17
    },
    {
        "district": "จักรสีห์",
        "amphoe": "เมืองสิงห์บุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16000,
        "district_code": 170107,
        "amphoe_code": 1701,
        "province_code": 17
    },
    {
        "district": "ต้นโพธิ์",
        "amphoe": "เมืองสิงห์บุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16000,
        "district_code": 170106,
        "amphoe_code": 1701,
        "province_code": 17
    },
    {
        "district": "บางกระบือ",
        "amphoe": "เมืองสิงห์บุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16000,
        "district_code": 170108,
        "amphoe_code": 1701,
        "province_code": 17
    },
    {
        "district": "บางพุทรา",
        "amphoe": "เมืองสิงห์บุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16000,
        "district_code": 170101,
        "amphoe_code": 1701,
        "province_code": 17
    },
    {
        "district": "บางมัญ",
        "amphoe": "เมืองสิงห์บุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16000,
        "district_code": 170102,
        "amphoe_code": 1701,
        "province_code": 17
    },
    {
        "district": "ม่วงหมู่",
        "amphoe": "เมืองสิงห์บุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16000,
        "district_code": 170104,
        "amphoe_code": 1701,
        "province_code": 17
    },
    {
        "district": "หัวไผ่",
        "amphoe": "เมืองสิงห์บุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16000,
        "district_code": 170105,
        "amphoe_code": 1701,
        "province_code": 17
    },
    {
        "district": "โพกรวม",
        "amphoe": "เมืองสิงห์บุรี",
        "province": "สิงห์บุรี",
        "zipcode": 16000,
        "district_code": 170103,
        "amphoe_code": 1701,
        "province_code": 17
    },
    {
        "district": "ดอนเจดีย์",
        "amphoe": "ดอนเจดีย์",
        "province": "สุพรรณบุรี",
        "zipcode": 72170,
        "district_code": 720601,
        "amphoe_code": 7206,
        "province_code": 72
    },
    {
        "district": "ทะเลบก",
        "amphoe": "ดอนเจดีย์",
        "province": "สุพรรณบุรี",
        "zipcode": 72250,
        "district_code": 720605,
        "amphoe_code": 7206,
        "province_code": 72
    },
    {
        "district": "สระกระโจม",
        "amphoe": "ดอนเจดีย์",
        "province": "สุพรรณบุรี",
        "zipcode": 72250,
        "district_code": 720604,
        "amphoe_code": 7206,
        "province_code": 72
    },
    {
        "district": "หนองสาหร่าย",
        "amphoe": "ดอนเจดีย์",
        "province": "สุพรรณบุรี",
        "zipcode": 72170,
        "district_code": 720602,
        "amphoe_code": 7206,
        "province_code": 72
    },
    {
        "district": "ไร่รถ",
        "amphoe": "ดอนเจดีย์",
        "province": "สุพรรณบุรี",
        "zipcode": 72170,
        "district_code": 720603,
        "amphoe_code": 7206,
        "province_code": 72
    },
    {
        "district": "ด่านช้าง",
        "amphoe": "ด่านช้าง",
        "province": "สุพรรณบุรี",
        "zipcode": 72180,
        "district_code": 720298,
        "amphoe_code": 7203,
        "province_code": 72
    },
    {
        "district": "นิคมกระเสียว",
        "amphoe": "ด่านช้าง",
        "province": "สุพรรณบุรี",
        "zipcode": 72180,
        "district_code": 720306,
        "amphoe_code": 7203,
        "province_code": 72
    },
    {
        "district": "วังคัน",
        "amphoe": "ด่านช้าง",
        "province": "สุพรรณบุรี",
        "zipcode": 72180,
        "district_code": 720305,
        "amphoe_code": 7203,
        "province_code": 72
    },
    {
        "district": "วังยาว",
        "amphoe": "ด่านช้าง",
        "province": "สุพรรณบุรี",
        "zipcode": 72180,
        "district_code": 720307,
        "amphoe_code": 7203,
        "province_code": 72
    },
    {
        "district": "หนองมะค่าโมง",
        "amphoe": "ด่านช้าง",
        "province": "สุพรรณบุรี",
        "zipcode": 72180,
        "district_code": 720299,
        "amphoe_code": 7203,
        "province_code": 72
    },
    {
        "district": "ห้วยขมิ้น",
        "amphoe": "ด่านช้าง",
        "province": "สุพรรณบุรี",
        "zipcode": 72180,
        "district_code": 720297,
        "amphoe_code": 7203,
        "province_code": 72
    },
    {
        "district": "องค์พระ",
        "amphoe": "ด่านช้าง",
        "province": "สุพรรณบุรี",
        "zipcode": 72180,
        "district_code": 720296,
        "amphoe_code": 7203,
        "province_code": 72
    },
    {
        "district": "กฤษณา",
        "amphoe": "บางปลาม้า",
        "province": "สุพรรณบุรี",
        "zipcode": 72150,
        "district_code": 720405,
        "amphoe_code": 7204,
        "province_code": 72
    },
    {
        "district": "จรเข้ใหญ่",
        "amphoe": "บางปลาม้า",
        "province": "สุพรรณบุรี",
        "zipcode": 72150,
        "district_code": 720409,
        "amphoe_code": 7204,
        "province_code": 72
    },
    {
        "district": "ตะค่า",
        "amphoe": "บางปลาม้า",
        "province": "สุพรรณบุรี",
        "zipcode": 72150,
        "district_code": 720403,
        "amphoe_code": 7204,
        "province_code": 72
    },
    {
        "district": "บางปลาม้า",
        "amphoe": "บางปลาม้า",
        "province": "สุพรรณบุรี",
        "zipcode": 72150,
        "district_code": 720402,
        "amphoe_code": 7204,
        "province_code": 72
    },
    {
        "district": "บางใหญ่",
        "amphoe": "บางปลาม้า",
        "province": "สุพรรณบุรี",
        "zipcode": 72150,
        "district_code": 720404,
        "amphoe_code": 7204,
        "province_code": 72
    },
    {
        "district": "บ้านแหลม",
        "amphoe": "บางปลาม้า",
        "province": "สุพรรณบุรี",
        "zipcode": 72150,
        "district_code": 720410,
        "amphoe_code": 7204,
        "province_code": 72
    },
    {
        "district": "มะขามล้ม",
        "amphoe": "บางปลาม้า",
        "province": "สุพรรณบุรี",
        "zipcode": 72150,
        "district_code": 720411,
        "amphoe_code": 7204,
        "province_code": 72
    },
    {
        "district": "วังน้ำเย็น",
        "amphoe": "บางปลาม้า",
        "province": "สุพรรณบุรี",
        "zipcode": 72150,
        "district_code": 720412,
        "amphoe_code": 7204,
        "province_code": 72
    },
    {
        "district": "วัดดาว",
        "amphoe": "บางปลาม้า",
        "province": "สุพรรณบุรี",
        "zipcode": 72150,
        "district_code": 720414,
        "amphoe_code": 7204,
        "province_code": 72
    },
    {
        "district": "วัดโบสถ์",
        "amphoe": "บางปลาม้า",
        "province": "สุพรรณบุรี",
        "zipcode": 72150,
        "district_code": 720413,
        "amphoe_code": 7204,
        "province_code": 72
    },
    {
        "district": "สาลี",
        "amphoe": "บางปลาม้า",
        "province": "สุพรรณบุรี",
        "zipcode": 72150,
        "district_code": 720406,
        "amphoe_code": 7204,
        "province_code": 72
    },
    {
        "district": "องครักษ์",
        "amphoe": "บางปลาม้า",
        "province": "สุพรรณบุรี",
        "zipcode": 72150,
        "district_code": 720408,
        "amphoe_code": 7204,
        "province_code": 72
    },
    {
        "district": "โคกคราม",
        "amphoe": "บางปลาม้า",
        "province": "สุพรรณบุรี",
        "zipcode": 72150,
        "district_code": 720401,
        "amphoe_code": 7204,
        "province_code": 72
    },
    {
        "district": "ไผ่กองดิน",
        "amphoe": "บางปลาม้า",
        "province": "สุพรรณบุรี",
        "zipcode": 72150,
        "district_code": 720407,
        "amphoe_code": 7204,
        "province_code": 72
    },
    {
        "district": "ดอนปรู",
        "amphoe": "ศรีประจันต์",
        "province": "สุพรรณบุรี",
        "zipcode": 72140,
        "district_code": 720505,
        "amphoe_code": 7205,
        "province_code": 72
    },
    {
        "district": "บางงาม",
        "amphoe": "ศรีประจันต์",
        "province": "สุพรรณบุรี",
        "zipcode": 72140,
        "district_code": 720504,
        "amphoe_code": 7205,
        "province_code": 72
    },
    {
        "district": "บ้านกร่าง",
        "amphoe": "ศรีประจันต์",
        "province": "สุพรรณบุรี",
        "zipcode": 72140,
        "district_code": 720502,
        "amphoe_code": 7205,
        "province_code": 72
    },
    {
        "district": "ปลายนา",
        "amphoe": "ศรีประจันต์",
        "province": "สุพรรณบุรี",
        "zipcode": 72140,
        "district_code": 720506,
        "amphoe_code": 7205,
        "province_code": 72
    },
    {
        "district": "มดแดง",
        "amphoe": "ศรีประจันต์",
        "province": "สุพรรณบุรี",
        "zipcode": 72140,
        "district_code": 720503,
        "amphoe_code": 7205,
        "province_code": 72
    },
    {
        "district": "วังน้ำซับ",
        "amphoe": "ศรีประจันต์",
        "province": "สุพรรณบุรี",
        "zipcode": 72140,
        "district_code": 720508,
        "amphoe_code": 7205,
        "province_code": 72
    },
    {
        "district": "วังยาง",
        "amphoe": "ศรีประจันต์",
        "province": "สุพรรณบุรี",
        "zipcode": 72140,
        "district_code": 720509,
        "amphoe_code": 7205,
        "province_code": 72
    },
    {
        "district": "วังหว้า",
        "amphoe": "ศรีประจันต์",
        "province": "สุพรรณบุรี",
        "zipcode": 72140,
        "district_code": 720507,
        "amphoe_code": 7205,
        "province_code": 72
    },
    {
        "district": "ศรีประจันต์",
        "amphoe": "ศรีประจันต์",
        "province": "สุพรรณบุรี",
        "zipcode": 72140,
        "district_code": 720501,
        "amphoe_code": 7205,
        "province_code": 72
    },
    {
        "district": "ดอนมะนาว",
        "amphoe": "สองพี่น้อง",
        "province": "สุพรรณบุรี",
        "zipcode": 72110,
        "district_code": 720715,
        "amphoe_code": 7207,
        "province_code": 72
    },
    {
        "district": "ต้นตาล",
        "amphoe": "สองพี่น้อง",
        "province": "สุพรรณบุรี",
        "zipcode": 72110,
        "district_code": 720710,
        "amphoe_code": 7207,
        "province_code": 72
    },
    {
        "district": "ทุ่งคอก",
        "amphoe": "สองพี่น้อง",
        "province": "สุพรรณบุรี",
        "zipcode": 72110,
        "district_code": 720712,
        "amphoe_code": 7207,
        "province_code": 72
    },
    {
        "district": "บางตะเคียน",
        "amphoe": "สองพี่น้อง",
        "province": "สุพรรณบุรี",
        "zipcode": 72110,
        "district_code": 720704,
        "amphoe_code": 7207,
        "province_code": 72
    },
    {
        "district": "บางตาเถร",
        "amphoe": "สองพี่น้อง",
        "province": "สุพรรณบุรี",
        "zipcode": 72110,
        "district_code": 720703,
        "amphoe_code": 7207,
        "province_code": 72
    },
    {
        "district": "บางพลับ",
        "amphoe": "สองพี่น้อง",
        "province": "สุพรรณบุรี",
        "zipcode": 72110,
        "district_code": 720707,
        "amphoe_code": 7207,
        "province_code": 72
    },
    {
        "district": "บางเลน",
        "amphoe": "สองพี่น้อง",
        "province": "สุพรรณบุรี",
        "zipcode": 72110,
        "district_code": 720702,
        "amphoe_code": 7207,
        "province_code": 72
    },
    {
        "district": "บ่อสุพรรณ",
        "amphoe": "สองพี่น้อง",
        "province": "สุพรรณบุรี",
        "zipcode": 72190,
        "district_code": 720714,
        "amphoe_code": 7207,
        "province_code": 72
    },
    {
        "district": "บ้านกุ่ม",
        "amphoe": "สองพี่น้อง",
        "province": "สุพรรณบุรี",
        "zipcode": 72110,
        "district_code": 720705,
        "amphoe_code": 7207,
        "province_code": 72
    },
    {
        "district": "บ้านช้าง",
        "amphoe": "สองพี่น้อง",
        "province": "สุพรรณบุรี",
        "zipcode": 72110,
        "district_code": 720709,
        "amphoe_code": 7207,
        "province_code": 72
    },
    {
        "district": "ศรีสำราญ",
        "amphoe": "สองพี่น้อง",
        "province": "สุพรรณบุรี",
        "zipcode": 72110,
        "district_code": 720711,
        "amphoe_code": 7207,
        "province_code": 72
    },
    {
        "district": "สองพี่น้อง",
        "amphoe": "สองพี่น้อง",
        "province": "สุพรรณบุรี",
        "zipcode": 72110,
        "district_code": 720701,
        "amphoe_code": 7207,
        "province_code": 72
    },
    {
        "district": "หนองบ่อ",
        "amphoe": "สองพี่น้อง",
        "province": "สุพรรณบุรี",
        "zipcode": 72190,
        "district_code": 720713,
        "amphoe_code": 7207,
        "province_code": 72
    },
    {
        "district": "หัวโพธิ์",
        "amphoe": "สองพี่น้อง",
        "province": "สุพรรณบุรี",
        "zipcode": 72110,
        "district_code": 720706,
        "amphoe_code": 7207,
        "province_code": 72
    },
    {
        "district": "เนินพระปรางค์",
        "amphoe": "สองพี่น้อง",
        "province": "สุพรรณบุรี",
        "zipcode": 72110,
        "district_code": 720708,
        "amphoe_code": 7207,
        "province_code": 72
    },
    {
        "district": "กระเสียว",
        "amphoe": "สามชุก",
        "province": "สุพรรณบุรี",
        "zipcode": 72130,
        "district_code": 720807,
        "amphoe_code": 7208,
        "province_code": 72
    },
    {
        "district": "บ้านสระ",
        "amphoe": "สามชุก",
        "province": "สุพรรณบุรี",
        "zipcode": 72130,
        "district_code": 720805,
        "amphoe_code": 7208,
        "province_code": 72
    },
    {
        "district": "ย่านยาว",
        "amphoe": "สามชุก",
        "province": "สุพรรณบุรี",
        "zipcode": 72130,
        "district_code": 720801,
        "amphoe_code": 7208,
        "province_code": 72
    },
    {
        "district": "วังลึก",
        "amphoe": "สามชุก",
        "province": "สุพรรณบุรี",
        "zipcode": 72130,
        "district_code": 720802,
        "amphoe_code": 7208,
        "province_code": 72
    },
    {
        "district": "สามชุก",
        "amphoe": "สามชุก",
        "province": "สุพรรณบุรี",
        "zipcode": 72130,
        "district_code": 720803,
        "amphoe_code": 7208,
        "province_code": 72
    },
    {
        "district": "หนองผักนาก",
        "amphoe": "สามชุก",
        "province": "สุพรรณบุรี",
        "zipcode": 72130,
        "district_code": 720804,
        "amphoe_code": 7208,
        "province_code": 72
    },
    {
        "district": "หนองสะเดา",
        "amphoe": "สามชุก",
        "province": "สุพรรณบุรี",
        "zipcode": 72130,
        "district_code": 720806,
        "amphoe_code": 7208,
        "province_code": 72
    },
    {
        "district": "ทัพหลวง",
        "amphoe": "หนองหญ้าไซ",
        "province": "สุพรรณบุรี",
        "zipcode": 72240,
        "district_code": 721006,
        "amphoe_code": 7210,
        "province_code": 72
    },
    {
        "district": "หนองขาม",
        "amphoe": "หนองหญ้าไซ",
        "province": "สุพรรณบุรี",
        "zipcode": 72240,
        "district_code": 721005,
        "amphoe_code": 7210,
        "province_code": 72
    },
    {
        "district": "หนองราชวัตร",
        "amphoe": "หนองหญ้าไซ",
        "province": "สุพรรณบุรี",
        "zipcode": 72240,
        "district_code": 720898,
        "amphoe_code": 7210,
        "province_code": 72
    },
    {
        "district": "หนองหญ้าไซ",
        "amphoe": "หนองหญ้าไซ",
        "province": "สุพรรณบุรี",
        "zipcode": 72240,
        "district_code": 720899,
        "amphoe_code": 7210,
        "province_code": 72
    },
    {
        "district": "หนองโพธิ์",
        "amphoe": "หนองหญ้าไซ",
        "province": "สุพรรณบุรี",
        "zipcode": 72240,
        "district_code": 720897,
        "amphoe_code": 7210,
        "province_code": 72
    },
    {
        "district": "แจงงาม",
        "amphoe": "หนองหญ้าไซ",
        "province": "สุพรรณบุรี",
        "zipcode": 72240,
        "district_code": 720896,
        "amphoe_code": 7210,
        "province_code": 72
    },
    {
        "district": "กระจัน",
        "amphoe": "อู่ทอง",
        "province": "สุพรรณบุรี",
        "zipcode": 72160,
        "district_code": 720913,
        "amphoe_code": 7209,
        "province_code": 72
    },
    {
        "district": "จรเข้สามพัน",
        "amphoe": "อู่ทอง",
        "province": "สุพรรณบุรี",
        "zipcode": 71170,
        "district_code": 720903,
        "amphoe_code": 7209,
        "province_code": 72
    },
    {
        "district": "ดอนคา",
        "amphoe": "อู่ทอง",
        "province": "สุพรรณบุรี",
        "zipcode": 72160,
        "district_code": 720908,
        "amphoe_code": 7209,
        "province_code": 72
    },
    {
        "district": "ดอนมะเกลือ",
        "amphoe": "อู่ทอง",
        "province": "สุพรรณบุรี",
        "zipcode": 72220,
        "district_code": 720906,
        "amphoe_code": 7209,
        "province_code": 72
    },
    {
        "district": "บ้านดอน",
        "amphoe": "อู่ทอง",
        "province": "สุพรรณบุรี",
        "zipcode": 72160,
        "district_code": 720904,
        "amphoe_code": 7209,
        "province_code": 72
    },
    {
        "district": "บ้านโข้ง",
        "amphoe": "อู่ทอง",
        "province": "สุพรรณบุรี",
        "zipcode": 72160,
        "district_code": 720910,
        "amphoe_code": 7209,
        "province_code": 72
    },
    {
        "district": "พลับพลาไชย",
        "amphoe": "อู่ทอง",
        "province": "สุพรรณบุรี",
        "zipcode": 72160,
        "district_code": 720909,
        "amphoe_code": 7209,
        "province_code": 72
    },
    {
        "district": "ยุ้งทะลาย",
        "amphoe": "อู่ทอง",
        "province": "สุพรรณบุรี",
        "zipcode": 72160,
        "district_code": 720905,
        "amphoe_code": 7209,
        "province_code": 72
    },
    {
        "district": "สระพังลาน",
        "amphoe": "อู่ทอง",
        "province": "สุพรรณบุรี",
        "zipcode": 72220,
        "district_code": 720912,
        "amphoe_code": 7209,
        "province_code": 72
    },
    {
        "district": "สระยายโสม",
        "amphoe": "อู่ทอง",
        "province": "สุพรรณบุรี",
        "zipcode": 72220,
        "district_code": 720902,
        "amphoe_code": 7209,
        "province_code": 72
    },
    {
        "district": "หนองโอ่ง",
        "amphoe": "อู่ทอง",
        "province": "สุพรรณบุรี",
        "zipcode": 72160,
        "district_code": 720907,
        "amphoe_code": 7209,
        "province_code": 72
    },
    {
        "district": "อู่ทอง",
        "amphoe": "อู่ทอง",
        "province": "สุพรรณบุรี",
        "zipcode": 72160,
        "district_code": 720901,
        "amphoe_code": 7209,
        "province_code": 72
    },
    {
        "district": "เจดีย์",
        "amphoe": "อู่ทอง",
        "province": "สุพรรณบุรี",
        "zipcode": 72160,
        "district_code": 720911,
        "amphoe_code": 7209,
        "province_code": 72
    },
    {
        "district": "ทุ่งคลี",
        "amphoe": "เดิมบางนางบวช",
        "province": "สุพรรณบุรี",
        "zipcode": 72120,
        "district_code": 720206,
        "amphoe_code": 7202,
        "province_code": 72
    },
    {
        "district": "นางบวช",
        "amphoe": "เดิมบางนางบวช",
        "province": "สุพรรณบุรี",
        "zipcode": 72120,
        "district_code": 720203,
        "amphoe_code": 7202,
        "province_code": 72
    },
    {
        "district": "บ่อกรุ",
        "amphoe": "เดิมบางนางบวช",
        "province": "สุพรรณบุรี",
        "zipcode": 72120,
        "district_code": 720210,
        "amphoe_code": 7202,
        "province_code": 72
    },
    {
        "district": "ปากน้ำ",
        "amphoe": "เดิมบางนางบวช",
        "province": "สุพรรณบุรี",
        "zipcode": 72120,
        "district_code": 720205,
        "amphoe_code": 7202,
        "province_code": 72
    },
    {
        "district": "ป่าสะแก",
        "amphoe": "เดิมบางนางบวช",
        "province": "สุพรรณบุรี",
        "zipcode": 72120,
        "district_code": 720212,
        "amphoe_code": 7202,
        "province_code": 72
    },
    {
        "district": "ยางนอน",
        "amphoe": "เดิมบางนางบวช",
        "province": "สุพรรณบุรี",
        "zipcode": 72120,
        "district_code": 720213,
        "amphoe_code": 7202,
        "province_code": 72
    },
    {
        "district": "วังศรีราช",
        "amphoe": "เดิมบางนางบวช",
        "province": "สุพรรณบุรี",
        "zipcode": 72120,
        "district_code": 720211,
        "amphoe_code": 7202,
        "province_code": 72
    },
    {
        "district": "หนองกระทุ่ม",
        "amphoe": "เดิมบางนางบวช",
        "province": "สุพรรณบุรี",
        "zipcode": 72120,
        "district_code": 720214,
        "amphoe_code": 7202,
        "province_code": 72
    },
    {
        "district": "หัวนา",
        "amphoe": "เดิมบางนางบวช",
        "province": "สุพรรณบุรี",
        "zipcode": 72120,
        "district_code": 720209,
        "amphoe_code": 7202,
        "province_code": 72
    },
    {
        "district": "หัวเขา",
        "amphoe": "เดิมบางนางบวช",
        "province": "สุพรรณบุรี",
        "zipcode": 72120,
        "district_code": 720208,
        "amphoe_code": 7202,
        "province_code": 72
    },
    {
        "district": "เขาดิน",
        "amphoe": "เดิมบางนางบวช",
        "province": "สุพรรณบุรี",
        "zipcode": 72120,
        "district_code": 720204,
        "amphoe_code": 7202,
        "province_code": 72
    },
    {
        "district": "เขาพระ",
        "amphoe": "เดิมบางนางบวช",
        "province": "สุพรรณบุรี",
        "zipcode": 72120,
        "district_code": 720201,
        "amphoe_code": 7202,
        "province_code": 72
    },
    {
        "district": "เดิมบาง",
        "amphoe": "เดิมบางนางบวช",
        "province": "สุพรรณบุรี",
        "zipcode": 72120,
        "district_code": 720202,
        "amphoe_code": 7202,
        "province_code": 72
    },
    {
        "district": "โคกช้าง",
        "amphoe": "เดิมบางนางบวช",
        "province": "สุพรรณบุรี",
        "zipcode": 72120,
        "district_code": 720207,
        "amphoe_code": 7202,
        "province_code": 72
    },
    {
        "district": "ดอนกำยาน",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72000,
        "district_code": 720110,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "ดอนตาล",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72000,
        "district_code": 720107,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "ดอนมะสังข์",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72000,
        "district_code": 720108,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "ดอนโพธิ์ทอง",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72000,
        "district_code": 720111,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "ตลิ่งชัน",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72230,
        "district_code": 720114,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "ทับตีเหล็ก",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72000,
        "district_code": 720103,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "ท่าพี่เลี้ยง",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72000,
        "district_code": 720101,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "ท่าระหัด",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72000,
        "district_code": 720104,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "บางกุ้ง",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72210,
        "district_code": 720115,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "บ้านโพธิ์",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72000,
        "district_code": 720112,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "พิหารแดง",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72000,
        "district_code": 720109,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "รั้วใหญ่",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72000,
        "district_code": 720102,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "ศาลาขาว",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72210,
        "district_code": 720116,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "สนามคลี",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72230,
        "district_code": 720120,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "สนามชัย",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72000,
        "district_code": 720118,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "สระแก้ว",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72230,
        "district_code": 720113,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "สวนแตง",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72210,
        "district_code": 720117,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "โคกโคเฒ่า",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72000,
        "district_code": 720106,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "โพธิ์พระยา",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72000,
        "district_code": 720119,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "ไผ่ขวาง",
        "amphoe": "เมืองสุพรรณบุรี",
        "province": "สุพรรณบุรี",
        "zipcode": 72000,
        "district_code": 720105,
        "amphoe_code": 7201,
        "province_code": 72
    },
    {
        "district": "กรูด",
        "amphoe": "กาญจนดิษฐ์",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84160,
        "district_code": 840205,
        "amphoe_code": 8402,
        "province_code": 84
    },
    {
        "district": "กะแดะ",
        "amphoe": "กาญจนดิษฐ์",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84160,
        "district_code": 840203,
        "amphoe_code": 8402,
        "province_code": 84
    },
    {
        "district": "คลองสระ",
        "amphoe": "กาญจนดิษฐ์",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84160,
        "district_code": 840213,
        "amphoe_code": 8402,
        "province_code": 84
    },
    {
        "district": "ช้างขวา",
        "amphoe": "กาญจนดิษฐ์",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84160,
        "district_code": 840210,
        "amphoe_code": 8402,
        "province_code": 84
    },
    {
        "district": "ช้างซ้าย",
        "amphoe": "กาญจนดิษฐ์",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84160,
        "district_code": 840206,
        "amphoe_code": 8402,
        "province_code": 84
    },
    {
        "district": "ตะเคียนทอง",
        "amphoe": "กาญจนดิษฐ์",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84160,
        "district_code": 840209,
        "amphoe_code": 8402,
        "province_code": 84
    },
    {
        "district": "ทุ่งกง",
        "amphoe": "กาญจนดิษฐ์",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84290,
        "district_code": 840204,
        "amphoe_code": 8402,
        "province_code": 84
    },
    {
        "district": "ทุ่งรัง",
        "amphoe": "กาญจนดิษฐ์",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84290,
        "district_code": 840212,
        "amphoe_code": 8402,
        "province_code": 84
    },
    {
        "district": "ท่าทอง",
        "amphoe": "กาญจนดิษฐ์",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84160,
        "district_code": 840202,
        "amphoe_code": 8402,
        "province_code": 84
    },
    {
        "district": "ท่าทองใหม่",
        "amphoe": "กาญจนดิษฐ์",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84290,
        "district_code": 840201,
        "amphoe_code": 8402,
        "province_code": 84
    },
    {
        "district": "ท่าอุแท",
        "amphoe": "กาญจนดิษฐ์",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84160,
        "district_code": 840211,
        "amphoe_code": 8402,
        "province_code": 84
    },
    {
        "district": "ป่าร่อน",
        "amphoe": "กาญจนดิษฐ์",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84160,
        "district_code": 840208,
        "amphoe_code": 8402,
        "province_code": 84
    },
    {
        "district": "พลายวาส",
        "amphoe": "กาญจนดิษฐ์",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84160,
        "district_code": 840207,
        "amphoe_code": 8402,
        "province_code": 84
    },
    {
        "district": "กะเปา",
        "amphoe": "คีรีรัฐนิคม",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84180,
        "district_code": 840806,
        "amphoe_code": 8408,
        "province_code": 84
    },
    {
        "district": "ถ้ำสิงขร",
        "amphoe": "คีรีรัฐนิคม",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84180,
        "district_code": 840809,
        "amphoe_code": 8408,
        "province_code": 84
    },
    {
        "district": "ท่ากระดาน",
        "amphoe": "คีรีรัฐนิคม",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84180,
        "district_code": 840807,
        "amphoe_code": 8408,
        "province_code": 84
    },
    {
        "district": "ท่าขนอน",
        "amphoe": "คีรีรัฐนิคม",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84180,
        "district_code": 840801,
        "amphoe_code": 8408,
        "province_code": 84
    },
    {
        "district": "น้ำหัก",
        "amphoe": "คีรีรัฐนิคม",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84180,
        "district_code": 840803,
        "amphoe_code": 8408,
        "province_code": 84
    },
    {
        "district": "บ้านทำเนียบ",
        "amphoe": "คีรีรัฐนิคม",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84180,
        "district_code": 840810,
        "amphoe_code": 8408,
        "province_code": 84
    },
    {
        "district": "บ้านยาง",
        "amphoe": "คีรีรัฐนิคม",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84180,
        "district_code": 840802,
        "amphoe_code": 8408,
        "province_code": 84
    },
    {
        "district": "ย่านยาว",
        "amphoe": "คีรีรัฐนิคม",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84180,
        "district_code": 840808,
        "amphoe_code": 8408,
        "province_code": 84
    },
    {
        "district": "คลองน้อย",
        "amphoe": "ชัยบุรี",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84350,
        "district_code": 840107,
        "amphoe_code": 8418,
        "province_code": 84
    },
    {
        "district": "ชัยบุรี",
        "amphoe": "ชัยบุรี",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84350,
        "district_code": 841698,
        "amphoe_code": 8418,
        "province_code": 84
    },
    {
        "district": "สองแพรก",
        "amphoe": "ชัยบุรี",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84350,
        "district_code": 841699,
        "amphoe_code": 8418,
        "province_code": 84
    },
    {
        "district": "ไทรทอง",
        "amphoe": "ชัยบุรี",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84350,
        "district_code": 841804,
        "amphoe_code": 8418,
        "province_code": 84
    },
    {
        "district": "ชลคราม",
        "amphoe": "ดอนสัก",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84160,
        "district_code": 840302,
        "amphoe_code": 8403,
        "province_code": 84
    },
    {
        "district": "ดอนสัก",
        "amphoe": "ดอนสัก",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84220,
        "district_code": 840301,
        "amphoe_code": 8403,
        "province_code": 84
    },
    {
        "district": "ปากแพรก",
        "amphoe": "ดอนสัก",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84340,
        "district_code": 840304,
        "amphoe_code": 8403,
        "province_code": 84
    },
    {
        "district": "ไชยคราม",
        "amphoe": "ดอนสัก",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84220,
        "district_code": 840303,
        "amphoe_code": 8403,
        "province_code": 84
    },
    {
        "district": "คลองไทร",
        "amphoe": "ท่าฉาง",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84150,
        "district_code": 841103,
        "amphoe_code": 8411,
        "province_code": 84
    },
    {
        "district": "ท่าฉาง",
        "amphoe": "ท่าฉาง",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84150,
        "district_code": 841101,
        "amphoe_code": 8411,
        "province_code": 84
    },
    {
        "district": "ท่าเคย",
        "amphoe": "ท่าฉาง",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84150,
        "district_code": 841102,
        "amphoe_code": 8411,
        "province_code": 84
    },
    {
        "district": "ปากฉลุย",
        "amphoe": "ท่าฉาง",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84150,
        "district_code": 841106,
        "amphoe_code": 8411,
        "province_code": 84
    },
    {
        "district": "เขาถ่าน",
        "amphoe": "ท่าฉาง",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84150,
        "district_code": 841104,
        "amphoe_code": 8411,
        "province_code": 84
    },
    {
        "district": "เสวียด",
        "amphoe": "ท่าฉาง",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84150,
        "district_code": 841105,
        "amphoe_code": 8411,
        "province_code": 84
    },
    {
        "district": "คลองพา",
        "amphoe": "ท่าชนะ",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84170,
        "district_code": 840706,
        "amphoe_code": 8407,
        "province_code": 84
    },
    {
        "district": "คันธุลี",
        "amphoe": "ท่าชนะ",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84170,
        "district_code": 840704,
        "amphoe_code": 8407,
        "province_code": 84
    },
    {
        "district": "ท่าชนะ",
        "amphoe": "ท่าชนะ",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84170,
        "district_code": 840701,
        "amphoe_code": 8407,
        "province_code": 84
    },
    {
        "district": "ประสงค์",
        "amphoe": "ท่าชนะ",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84170,
        "district_code": 840703,
        "amphoe_code": 8407,
        "province_code": 84
    },
    {
        "district": "วัง",
        "amphoe": "ท่าชนะ",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84170,
        "district_code": 840705,
        "amphoe_code": 8407,
        "province_code": 84
    },
    {
        "district": "สมอทอง",
        "amphoe": "ท่าชนะ",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84170,
        "district_code": 840702,
        "amphoe_code": 8407,
        "province_code": 84
    },
    {
        "district": "พรุไทย",
        "amphoe": "บ้านตาขุน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84230,
        "district_code": 840903,
        "amphoe_code": 8409,
        "province_code": 84
    },
    {
        "district": "พะแสง",
        "amphoe": "บ้านตาขุน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84230,
        "district_code": 840902,
        "amphoe_code": 8409,
        "province_code": 84
    },
    {
        "district": "เขาพัง",
        "amphoe": "บ้านตาขุน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84230,
        "district_code": 840904,
        "amphoe_code": 8409,
        "province_code": 84
    },
    {
        "district": "เขาวง",
        "amphoe": "บ้านตาขุน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84230,
        "district_code": 840901,
        "amphoe_code": 8409,
        "province_code": 84
    },
    {
        "district": "คลองปราบ",
        "amphoe": "บ้านนาสาร",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84120,
        "district_code": 841208,
        "amphoe_code": 8412,
        "province_code": 84
    },
    {
        "district": "ควนศรี",
        "amphoe": "บ้านนาสาร",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84270,
        "district_code": 841206,
        "amphoe_code": 8412,
        "province_code": 84
    },
    {
        "district": "ควนสุบรรณ",
        "amphoe": "บ้านนาสาร",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84120,
        "district_code": 841207,
        "amphoe_code": 8412,
        "province_code": 84
    },
    {
        "district": "ทุ่งเตา",
        "amphoe": "บ้านนาสาร",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84120,
        "district_code": 841203,
        "amphoe_code": 8412,
        "province_code": 84
    },
    {
        "district": "ทุ่งเตาใหม่",
        "amphoe": "บ้านนาสาร",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84120,
        "district_code": 841210,
        "amphoe_code": 8412,
        "province_code": 84
    },
    {
        "district": "ท่าชี",
        "amphoe": "บ้านนาสาร",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84120,
        "district_code": 841205,
        "amphoe_code": 8412,
        "province_code": 84
    },
    {
        "district": "นาสาร",
        "amphoe": "บ้านนาสาร",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84120,
        "district_code": 841201,
        "amphoe_code": 8412,
        "province_code": 84
    },
    {
        "district": "น้ำพุ",
        "amphoe": "บ้านนาสาร",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84120,
        "district_code": 841209,
        "amphoe_code": 8412,
        "province_code": 84
    },
    {
        "district": "พรุพี",
        "amphoe": "บ้านนาสาร",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84270,
        "district_code": 841202,
        "amphoe_code": 8412,
        "province_code": 84
    },
    {
        "district": "ลำพูน",
        "amphoe": "บ้านนาสาร",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84120,
        "district_code": 841204,
        "amphoe_code": 8412,
        "province_code": 84
    },
    {
        "district": "เพิ่มพูนทรัพย์",
        "amphoe": "บ้านนาสาร",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84120,
        "district_code": 841211,
        "amphoe_code": 8412,
        "province_code": 84
    },
    {
        "district": "ทรัพย์ทวี",
        "amphoe": "บ้านนาเดิม",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84240,
        "district_code": 841303,
        "amphoe_code": 8413,
        "province_code": 84
    },
    {
        "district": "ท่าเรือ",
        "amphoe": "บ้านนาเดิม",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84240,
        "district_code": 841298,
        "amphoe_code": 8413,
        "province_code": 84
    },
    {
        "district": "นาใต้",
        "amphoe": "บ้านนาเดิม",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84240,
        "district_code": 841304,
        "amphoe_code": 8413,
        "province_code": 84
    },
    {
        "district": "บ้านนา",
        "amphoe": "บ้านนาเดิม",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84240,
        "district_code": 841299,
        "amphoe_code": 8413,
        "province_code": 84
    },
    {
        "district": "คลองชะอุ่น",
        "amphoe": "พนม",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84250,
        "district_code": 841006,
        "amphoe_code": 8410,
        "province_code": 84
    },
    {
        "district": "คลองศก",
        "amphoe": "พนม",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84250,
        "district_code": 841003,
        "amphoe_code": 8410,
        "province_code": 84
    },
    {
        "district": "ต้นยวน",
        "amphoe": "พนม",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84250,
        "district_code": 841002,
        "amphoe_code": 8410,
        "province_code": 84
    },
    {
        "district": "พนม",
        "amphoe": "พนม",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84250,
        "district_code": 841001,
        "amphoe_code": 8410,
        "province_code": 84
    },
    {
        "district": "พลูเถื่อน",
        "amphoe": "พนม",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84250,
        "district_code": 841004,
        "amphoe_code": 8410,
        "province_code": 84
    },
    {
        "district": "พังกาญจน์",
        "amphoe": "พนม",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84250,
        "district_code": 841005,
        "amphoe_code": 8410,
        "province_code": 84
    },
    {
        "district": "บางสวรรค์",
        "amphoe": "พระแสง",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84210,
        "district_code": 841603,
        "amphoe_code": 8416,
        "province_code": 84
    },
    {
        "district": "สาคู",
        "amphoe": "พระแสง",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84210,
        "district_code": 841607,
        "amphoe_code": 8416,
        "province_code": 84
    },
    {
        "district": "สินปุน",
        "amphoe": "พระแสง",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84210,
        "district_code": 841602,
        "amphoe_code": 8416,
        "province_code": 84
    },
    {
        "district": "สินเจริญ",
        "amphoe": "พระแสง",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84210,
        "district_code": 841605,
        "amphoe_code": 8416,
        "province_code": 84
    },
    {
        "district": "อิปัน",
        "amphoe": "พระแสง",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84210,
        "district_code": 841601,
        "amphoe_code": 8416,
        "province_code": 84
    },
    {
        "district": "ไทรขึง",
        "amphoe": "พระแสง",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84210,
        "district_code": 841604,
        "amphoe_code": 8416,
        "province_code": 84
    },
    {
        "district": "ไทรโสภา",
        "amphoe": "พระแสง",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84210,
        "district_code": 841606,
        "amphoe_code": 8416,
        "province_code": 84
    },
    {
        "district": "กรูด",
        "amphoe": "พุนพิน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84130,
        "district_code": 840205,
        "amphoe_code": 8417,
        "province_code": 84
    },
    {
        "district": "ตะปาน",
        "amphoe": "พุนพิน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84130,
        "district_code": 841716,
        "amphoe_code": 8417,
        "province_code": 84
    },
    {
        "district": "ท่าข้าม",
        "amphoe": "พุนพิน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84130,
        "district_code": 841701,
        "amphoe_code": 8417,
        "province_code": 84
    },
    {
        "district": "ท่าสะท้อน",
        "amphoe": "พุนพิน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84130,
        "district_code": 841702,
        "amphoe_code": 8417,
        "province_code": 84
    },
    {
        "district": "ท่าโรงช้าง",
        "amphoe": "พุนพิน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84130,
        "district_code": 841706,
        "amphoe_code": 8417,
        "province_code": 84
    },
    {
        "district": "น้ำรอบ",
        "amphoe": "พุนพิน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84130,
        "district_code": 841711,
        "amphoe_code": 8417,
        "province_code": 84
    },
    {
        "district": "บางงอน",
        "amphoe": "พุนพิน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84130,
        "district_code": 841709,
        "amphoe_code": 8417,
        "province_code": 84
    },
    {
        "district": "บางมะเดื่อ",
        "amphoe": "พุนพิน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84130,
        "district_code": 841704,
        "amphoe_code": 8417,
        "province_code": 84
    },
    {
        "district": "บางเดือน",
        "amphoe": "พุนพิน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84130,
        "district_code": 841705,
        "amphoe_code": 8417,
        "province_code": 84
    },
    {
        "district": "พุนพิน",
        "amphoe": "พุนพิน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84130,
        "district_code": 841708,
        "amphoe_code": 8417,
        "province_code": 84
    },
    {
        "district": "มะลวน",
        "amphoe": "พุนพิน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84130,
        "district_code": 841712,
        "amphoe_code": 8417,
        "province_code": 84
    },
    {
        "district": "ลีเล็ด",
        "amphoe": "พุนพิน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84130,
        "district_code": 841703,
        "amphoe_code": 8417,
        "province_code": 84
    },
    {
        "district": "ศรีวิชัย",
        "amphoe": "พุนพิน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84130,
        "district_code": 841710,
        "amphoe_code": 8417,
        "province_code": 84
    },
    {
        "district": "หนองไทร",
        "amphoe": "พุนพิน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84130,
        "district_code": 841714,
        "amphoe_code": 8417,
        "province_code": 84
    },
    {
        "district": "หัวเตย",
        "amphoe": "พุนพิน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84130,
        "district_code": 841713,
        "amphoe_code": 8417,
        "province_code": 84
    },
    {
        "district": "เขาหัวควาย",
        "amphoe": "พุนพิน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84130,
        "district_code": 841715,
        "amphoe_code": 8417,
        "province_code": 84
    },
    {
        "district": "ตะกุกเหนือ",
        "amphoe": "วิภาวดี",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84180,
        "district_code": 840805,
        "amphoe_code": 8419,
        "province_code": 84
    },
    {
        "district": "ตะกุกใต้",
        "amphoe": "วิภาวดี",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84180,
        "district_code": 840804,
        "amphoe_code": 8419,
        "province_code": 84
    },
    {
        "district": "บ้านใต้",
        "amphoe": "เกาะพะงัน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84280,
        "district_code": 840502,
        "amphoe_code": 8405,
        "province_code": 84
    },
    {
        "district": "เกาะพะงัน",
        "amphoe": "เกาะพะงัน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84280,
        "district_code": 840501,
        "amphoe_code": 8405,
        "province_code": 84
    },
    {
        "district": "เกาะเต่า",
        "amphoe": "เกาะพะงัน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84280,
        "district_code": 840503,
        "amphoe_code": 8405,
        "province_code": 84
    },
    {
        "district": "เกาะเต่า",
        "amphoe": "เกาะพะงัน",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84360,
        "district_code": 840503,
        "amphoe_code": 8405,
        "province_code": 84
    },
    {
        "district": "ตลิ่งงาม",
        "amphoe": "เกาะสมุย",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84140,
        "district_code": 840403,
        "amphoe_code": 8404,
        "province_code": 84
    },
    {
        "district": "บ่อผุด",
        "amphoe": "เกาะสมุย",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84320,
        "district_code": 840406,
        "amphoe_code": 8404,
        "province_code": 84
    },
    {
        "district": "มะเร็ต",
        "amphoe": "เกาะสมุย",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84310,
        "district_code": 840405,
        "amphoe_code": 8404,
        "province_code": 84
    },
    {
        "district": "ลิปะน้อย",
        "amphoe": "เกาะสมุย",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84140,
        "district_code": 840402,
        "amphoe_code": 8404,
        "province_code": 84
    },
    {
        "district": "หน้าเมือง",
        "amphoe": "เกาะสมุย",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84140,
        "district_code": 840404,
        "amphoe_code": 8404,
        "province_code": 84
    },
    {
        "district": "อ่างทอง",
        "amphoe": "เกาะสมุย",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84140,
        "district_code": 840401,
        "amphoe_code": 8404,
        "province_code": 84
    },
    {
        "district": "แม่น้ำ",
        "amphoe": "เกาะสมุย",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84330,
        "district_code": 840407,
        "amphoe_code": 8404,
        "province_code": 84
    },
    {
        "district": "บ้านเสด็จ",
        "amphoe": "เคียนซา",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84260,
        "district_code": 841405,
        "amphoe_code": 8414,
        "province_code": 84
    },
    {
        "district": "พ่วงพรมคร",
        "amphoe": "เคียนซา",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84210,
        "district_code": 841402,
        "amphoe_code": 8414,
        "province_code": 84
    },
    {
        "district": "อรัญคามวารี",
        "amphoe": "เคียนซา",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84260,
        "district_code": 841404,
        "amphoe_code": 8414,
        "province_code": 84
    },
    {
        "district": "เขาตอก",
        "amphoe": "เคียนซา",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84260,
        "district_code": 841403,
        "amphoe_code": 8414,
        "province_code": 84
    },
    {
        "district": "เคียนซา",
        "amphoe": "เคียนซา",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84260,
        "district_code": 841401,
        "amphoe_code": 8414,
        "province_code": 84
    },
    {
        "district": "ขุนทะเล",
        "amphoe": "เมืองสุราษฎร์ธานี",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84100,
        "district_code": 840104,
        "amphoe_code": 8401,
        "province_code": 84
    },
    {
        "district": "คลองฉนาก",
        "amphoe": "เมืองสุราษฎร์ธานี",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84000,
        "district_code": 840111,
        "amphoe_code": 8401,
        "province_code": 84
    },
    {
        "district": "คลองน้อย",
        "amphoe": "เมืองสุราษฎร์ธานี",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84000,
        "district_code": 840107,
        "amphoe_code": 8401,
        "province_code": 84
    },
    {
        "district": "ตลาด",
        "amphoe": "เมืองสุราษฎร์ธานี",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84000,
        "district_code": 840101,
        "amphoe_code": 8401,
        "province_code": 84
    },
    {
        "district": "บางกุ้ง",
        "amphoe": "เมืองสุราษฎร์ธานี",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84000,
        "district_code": 840110,
        "amphoe_code": 8401,
        "province_code": 84
    },
    {
        "district": "บางชนะ",
        "amphoe": "เมืองสุราษฎร์ธานี",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84000,
        "district_code": 840106,
        "amphoe_code": 8401,
        "province_code": 84
    },
    {
        "district": "บางโพธิ์",
        "amphoe": "เมืองสุราษฎร์ธานี",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84000,
        "district_code": 840109,
        "amphoe_code": 8401,
        "province_code": 84
    },
    {
        "district": "บางใบไม้",
        "amphoe": "เมืองสุราษฎร์ธานี",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84000,
        "district_code": 840105,
        "amphoe_code": 8401,
        "province_code": 84
    },
    {
        "district": "บางไทร",
        "amphoe": "เมืองสุราษฎร์ธานี",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84000,
        "district_code": 840108,
        "amphoe_code": 8401,
        "province_code": 84
    },
    {
        "district": "มะขามเตี้ย",
        "amphoe": "เมืองสุราษฎร์ธานี",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84000,
        "district_code": 840102,
        "amphoe_code": 8401,
        "province_code": 84
    },
    {
        "district": "วัดประดู่",
        "amphoe": "เมืองสุราษฎร์ธานี",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84000,
        "district_code": 840103,
        "amphoe_code": 8401,
        "province_code": 84
    },
    {
        "district": "คลองฉนวน",
        "amphoe": "เวียงสระ",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84190,
        "district_code": 841503,
        "amphoe_code": 8415,
        "province_code": 84
    },
    {
        "district": "ทุ่งหลวง",
        "amphoe": "เวียงสระ",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84190,
        "district_code": 841504,
        "amphoe_code": 8415,
        "province_code": 84
    },
    {
        "district": "บ้านส้อง",
        "amphoe": "เวียงสระ",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84190,
        "district_code": 841502,
        "amphoe_code": 8415,
        "province_code": 84
    },
    {
        "district": "เขานิพันธ์",
        "amphoe": "เวียงสระ",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84190,
        "district_code": 841505,
        "amphoe_code": 8415,
        "province_code": 84
    },
    {
        "district": "เวียงสระ",
        "amphoe": "เวียงสระ",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84190,
        "district_code": 841501,
        "amphoe_code": 8415,
        "province_code": 84
    },
    {
        "district": "ตลาดไชยา",
        "amphoe": "ไชยา",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84110,
        "district_code": 840601,
        "amphoe_code": 8406,
        "province_code": 84
    },
    {
        "district": "ตะกรบ",
        "amphoe": "ไชยา",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84110,
        "district_code": 840607,
        "amphoe_code": 8406,
        "province_code": 84
    },
    {
        "district": "ทุ่ง",
        "amphoe": "ไชยา",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84110,
        "district_code": 840605,
        "amphoe_code": 8406,
        "province_code": 84
    },
    {
        "district": "ปากหมาก",
        "amphoe": "ไชยา",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84110,
        "district_code": 840609,
        "amphoe_code": 8406,
        "province_code": 84
    },
    {
        "district": "ป่าเว",
        "amphoe": "ไชยา",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84110,
        "district_code": 840606,
        "amphoe_code": 8406,
        "province_code": 84
    },
    {
        "district": "พุมเรียง",
        "amphoe": "ไชยา",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84110,
        "district_code": 840602,
        "amphoe_code": 8406,
        "province_code": 84
    },
    {
        "district": "เลม็ด",
        "amphoe": "ไชยา",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84110,
        "district_code": 840603,
        "amphoe_code": 8406,
        "province_code": 84
    },
    {
        "district": "เวียง",
        "amphoe": "ไชยา",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84110,
        "district_code": 840604,
        "amphoe_code": 8406,
        "province_code": 84
    },
    {
        "district": "โมถ่าย",
        "amphoe": "ไชยา",
        "province": "สุราษฎร์ธานี",
        "zipcode": 84110,
        "district_code": 840608,
        "amphoe_code": 8406,
        "province_code": 84
    },
    {
        "district": "กาบเชิง",
        "amphoe": "กาบเชิง",
        "province": "สุรินทร์",
        "zipcode": 32210,
        "district_code": 320599,
        "amphoe_code": 3206,
        "province_code": 32
    },
    {
        "district": "คูตัน",
        "amphoe": "กาบเชิง",
        "province": "สุรินทร์",
        "zipcode": 32210,
        "district_code": 320596,
        "amphoe_code": 3206,
        "province_code": 32
    },
    {
        "district": "ด่าน",
        "amphoe": "กาบเชิง",
        "province": "สุรินทร์",
        "zipcode": 32210,
        "district_code": 320595,
        "amphoe_code": 3206,
        "province_code": 32
    },
    {
        "district": "ตะเคียน",
        "amphoe": "กาบเชิง",
        "province": "สุรินทร์",
        "zipcode": 32210,
        "district_code": 320610,
        "amphoe_code": 3206,
        "province_code": 32
    },
    {
        "district": "แนงมุด",
        "amphoe": "กาบเชิง",
        "province": "สุรินทร์",
        "zipcode": 32210,
        "district_code": 320606,
        "amphoe_code": 3206,
        "province_code": 32
    },
    {
        "district": "โคกตะเคียน",
        "amphoe": "กาบเชิง",
        "province": "สุรินทร์",
        "zipcode": 32210,
        "district_code": 320607,
        "amphoe_code": 3206,
        "province_code": 32
    },
    {
        "district": "กระหาด",
        "amphoe": "จอมพระ",
        "province": "สุรินทร์",
        "zipcode": 32180,
        "district_code": 320403,
        "amphoe_code": 3204,
        "province_code": 32
    },
    {
        "district": "จอมพระ",
        "amphoe": "จอมพระ",
        "province": "สุรินทร์",
        "zipcode": 32180,
        "district_code": 320401,
        "amphoe_code": 3204,
        "province_code": 32
    },
    {
        "district": "ชุมแสง",
        "amphoe": "จอมพระ",
        "province": "สุรินทร์",
        "zipcode": 32180,
        "district_code": 320408,
        "amphoe_code": 3204,
        "province_code": 32
    },
    {
        "district": "บุแกรง",
        "amphoe": "จอมพระ",
        "province": "สุรินทร์",
        "zipcode": 32180,
        "district_code": 320404,
        "amphoe_code": 3204,
        "province_code": 32
    },
    {
        "district": "บ้านผือ",
        "amphoe": "จอมพระ",
        "province": "สุรินทร์",
        "zipcode": 32180,
        "district_code": 320406,
        "amphoe_code": 3204,
        "province_code": 32
    },
    {
        "district": "ลุ่มระวี",
        "amphoe": "จอมพระ",
        "province": "สุรินทร์",
        "zipcode": 32180,
        "district_code": 320407,
        "amphoe_code": 3204,
        "province_code": 32
    },
    {
        "district": "หนองสนิท",
        "amphoe": "จอมพระ",
        "province": "สุรินทร์",
        "zipcode": 32180,
        "district_code": 320405,
        "amphoe_code": 3204,
        "province_code": 32
    },
    {
        "district": "เป็นสุข",
        "amphoe": "จอมพระ",
        "province": "สุรินทร์",
        "zipcode": 32180,
        "district_code": 320409,
        "amphoe_code": 3204,
        "province_code": 32
    },
    {
        "district": "เมืองลีง",
        "amphoe": "จอมพระ",
        "province": "สุรินทร์",
        "zipcode": 32180,
        "district_code": 320402,
        "amphoe_code": 3204,
        "province_code": 32
    },
    {
        "district": "กระเบื้อง",
        "amphoe": "ชุมพลบุรี",
        "province": "สุรินทร์",
        "zipcode": 32190,
        "district_code": 320208,
        "amphoe_code": 3202,
        "province_code": 32
    },
    {
        "district": "ชุมพลบุรี",
        "amphoe": "ชุมพลบุรี",
        "province": "สุรินทร์",
        "zipcode": 32190,
        "district_code": 320201,
        "amphoe_code": 3202,
        "province_code": 32
    },
    {
        "district": "นาหนองไผ่",
        "amphoe": "ชุมพลบุรี",
        "province": "สุรินทร์",
        "zipcode": 32190,
        "district_code": 320202,
        "amphoe_code": 3202,
        "province_code": 32
    },
    {
        "district": "ยะวึก",
        "amphoe": "ชุมพลบุรี",
        "province": "สุรินทร์",
        "zipcode": 32190,
        "district_code": 320205,
        "amphoe_code": 3202,
        "province_code": 32
    },
    {
        "district": "ศรีณรงค์",
        "amphoe": "ชุมพลบุรี",
        "province": "สุรินทร์",
        "zipcode": 32190,
        "district_code": 320204,
        "amphoe_code": 3202,
        "province_code": 32
    },
    {
        "district": "สระขุด",
        "amphoe": "ชุมพลบุรี",
        "province": "สุรินทร์",
        "zipcode": 32190,
        "district_code": 320207,
        "amphoe_code": 3202,
        "province_code": 32
    },
    {
        "district": "หนองเรือ",
        "amphoe": "ชุมพลบุรี",
        "province": "สุรินทร์",
        "zipcode": 32190,
        "district_code": 320209,
        "amphoe_code": 3202,
        "province_code": 32
    },
    {
        "district": "เมืองบัว",
        "amphoe": "ชุมพลบุรี",
        "province": "สุรินทร์",
        "zipcode": 32190,
        "district_code": 320206,
        "amphoe_code": 3202,
        "province_code": 32
    },
    {
        "district": "ไพรขลา",
        "amphoe": "ชุมพลบุรี",
        "province": "สุรินทร์",
        "zipcode": 32190,
        "district_code": 320203,
        "amphoe_code": 3202,
        "province_code": 32
    },
    {
        "district": "กระโพ",
        "amphoe": "ท่าตูม",
        "province": "สุรินทร์",
        "zipcode": 32120,
        "district_code": 320302,
        "amphoe_code": 3203,
        "province_code": 32
    },
    {
        "district": "ทุ่งกุลา",
        "amphoe": "ท่าตูม",
        "province": "สุรินทร์",
        "zipcode": 32120,
        "district_code": 320310,
        "amphoe_code": 3203,
        "province_code": 32
    },
    {
        "district": "ท่าตูม",
        "amphoe": "ท่าตูม",
        "province": "สุรินทร์",
        "zipcode": 32120,
        "district_code": 320301,
        "amphoe_code": 3203,
        "province_code": 32
    },
    {
        "district": "บะ",
        "amphoe": "ท่าตูม",
        "province": "สุรินทร์",
        "zipcode": 32120,
        "district_code": 320306,
        "amphoe_code": 3203,
        "province_code": 32
    },
    {
        "district": "บัวโคก",
        "amphoe": "ท่าตูม",
        "province": "สุรินทร์",
        "zipcode": 32120,
        "district_code": 320308,
        "amphoe_code": 3203,
        "province_code": 32
    },
    {
        "district": "พรมเทพ",
        "amphoe": "ท่าตูม",
        "province": "สุรินทร์",
        "zipcode": 32120,
        "district_code": 320303,
        "amphoe_code": 3203,
        "province_code": 32
    },
    {
        "district": "หนองบัว",
        "amphoe": "ท่าตูม",
        "province": "สุรินทร์",
        "zipcode": 32120,
        "district_code": 320307,
        "amphoe_code": 3203,
        "province_code": 32
    },
    {
        "district": "หนองเมธี",
        "amphoe": "ท่าตูม",
        "province": "สุรินทร์",
        "zipcode": 32120,
        "district_code": 320309,
        "amphoe_code": 3203,
        "province_code": 32
    },
    {
        "district": "เมืองแก",
        "amphoe": "ท่าตูม",
        "province": "สุรินทร์",
        "zipcode": 32120,
        "district_code": 320305,
        "amphoe_code": 3203,
        "province_code": 32
    },
    {
        "district": "โพนครก",
        "amphoe": "ท่าตูม",
        "province": "สุรินทร์",
        "zipcode": 32120,
        "district_code": 320304,
        "amphoe_code": 3203,
        "province_code": 32
    },
    {
        "district": "จรัส",
        "amphoe": "บัวเชด",
        "province": "สุรินทร์",
        "zipcode": 32230,
        "district_code": 321303,
        "amphoe_code": 3213,
        "province_code": 32
    },
    {
        "district": "ตาวัง",
        "amphoe": "บัวเชด",
        "province": "สุรินทร์",
        "zipcode": 32230,
        "district_code": 321304,
        "amphoe_code": 3213,
        "province_code": 32
    },
    {
        "district": "บัวเชด",
        "amphoe": "บัวเชด",
        "province": "สุรินทร์",
        "zipcode": 32230,
        "district_code": 321301,
        "amphoe_code": 3213,
        "province_code": 32
    },
    {
        "district": "สะเดา",
        "amphoe": "บัวเชด",
        "province": "สุรินทร์",
        "zipcode": 32230,
        "district_code": 321302,
        "amphoe_code": 3213,
        "province_code": 32
    },
    {
        "district": "สำเภาลูน",
        "amphoe": "บัวเชด",
        "province": "สุรินทร์",
        "zipcode": 32230,
        "district_code": 321306,
        "amphoe_code": 3213,
        "province_code": 32
    },
    {
        "district": "อาโพน",
        "amphoe": "บัวเชด",
        "province": "สุรินทร์",
        "zipcode": 32230,
        "district_code": 321305,
        "amphoe_code": 3213,
        "province_code": 32
    },
    {
        "district": "กังแอน",
        "amphoe": "ปราสาท",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320501,
        "amphoe_code": 3205,
        "province_code": 32
    },
    {
        "district": "กันตวจระมวล",
        "amphoe": "ปราสาท",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320516,
        "amphoe_code": 3205,
        "province_code": 32
    },
    {
        "district": "ตานี",
        "amphoe": "ปราสาท",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320514,
        "amphoe_code": 3205,
        "province_code": 32
    },
    {
        "district": "ตาเบา",
        "amphoe": "ปราสาท",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320506,
        "amphoe_code": 3205,
        "province_code": 32
    },
    {
        "district": "ทมอ",
        "amphoe": "ปราสาท",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320502,
        "amphoe_code": 3205,
        "province_code": 32
    },
    {
        "district": "ทุ่งมน",
        "amphoe": "ปราสาท",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320505,
        "amphoe_code": 3205,
        "province_code": 32
    },
    {
        "district": "บ้านพลวง",
        "amphoe": "ปราสาท",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320515,
        "amphoe_code": 3205,
        "province_code": 32
    },
    {
        "district": "บ้านไทร",
        "amphoe": "ปราสาท",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320510,
        "amphoe_code": 3205,
        "province_code": 32
    },
    {
        "district": "ประทัดบุ",
        "amphoe": "ปราสาท",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320518,
        "amphoe_code": 3205,
        "province_code": 32
    },
    {
        "district": "ปราสาททนง",
        "amphoe": "ปราสาท",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320513,
        "amphoe_code": 3205,
        "province_code": 32
    },
    {
        "district": "ปรือ",
        "amphoe": "ปราสาท",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320504,
        "amphoe_code": 3205,
        "province_code": 32
    },
    {
        "district": "สมุด",
        "amphoe": "ปราสาท",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320517,
        "amphoe_code": 3205,
        "province_code": 32
    },
    {
        "district": "หนองใหญ่",
        "amphoe": "ปราสาท",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320507,
        "amphoe_code": 3205,
        "province_code": 32
    },
    {
        "district": "เชื้อเพลิง",
        "amphoe": "ปราสาท",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320512,
        "amphoe_code": 3205,
        "province_code": 32
    },
    {
        "district": "โคกยาง",
        "amphoe": "ปราสาท",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320508,
        "amphoe_code": 3205,
        "province_code": 32
    },
    {
        "district": "โคกสะอาด",
        "amphoe": "ปราสาท",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320509,
        "amphoe_code": 3205,
        "province_code": 32
    },
    {
        "district": "โชคนาสาม",
        "amphoe": "ปราสาท",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320511,
        "amphoe_code": 3205,
        "province_code": 32
    },
    {
        "district": "ไพล",
        "amphoe": "ปราสาท",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320503,
        "amphoe_code": 3205,
        "province_code": 32
    },
    {
        "district": "จีกแดก",
        "amphoe": "พนมดงรัก",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320609,
        "amphoe_code": 3214,
        "province_code": 32
    },
    {
        "district": "ตาเมียง",
        "amphoe": "พนมดงรัก",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320608,
        "amphoe_code": 3214,
        "province_code": 32
    },
    {
        "district": "บักได",
        "amphoe": "พนมดงรัก",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320598,
        "amphoe_code": 3214,
        "province_code": 32
    },
    {
        "district": "โคกกลาง",
        "amphoe": "พนมดงรัก",
        "province": "สุรินทร์",
        "zipcode": 32140,
        "district_code": 320597,
        "amphoe_code": 3214,
        "province_code": 32
    },
    {
        "district": "กุดขาคีม",
        "amphoe": "รัตนบุรี",
        "province": "สุรินทร์",
        "zipcode": 32130,
        "district_code": 320714,
        "amphoe_code": 3207,
        "province_code": 32
    },
    {
        "district": "ดอนแรด",
        "amphoe": "รัตนบุรี",
        "province": "สุรินทร์",
        "zipcode": 32130,
        "district_code": 320704,
        "amphoe_code": 3207,
        "province_code": 32
    },
    {
        "district": "ทับใหญ่",
        "amphoe": "รัตนบุรี",
        "province": "สุรินทร์",
        "zipcode": 32130,
        "district_code": 320716,
        "amphoe_code": 3207,
        "province_code": 32
    },
    {
        "district": "ธาตุ",
        "amphoe": "รัตนบุรี",
        "province": "สุรินทร์",
        "zipcode": 32130,
        "district_code": 320702,
        "amphoe_code": 3207,
        "province_code": 32
    },
    {
        "district": "น้ำเขียว",
        "amphoe": "รัตนบุรี",
        "province": "สุรินทร์",
        "zipcode": 32130,
        "district_code": 320713,
        "amphoe_code": 3207,
        "province_code": 32
    },
    {
        "district": "ยางสว่าง",
        "amphoe": "รัตนบุรี",
        "province": "สุรินทร์",
        "zipcode": 32130,
        "district_code": 320715,
        "amphoe_code": 3207,
        "province_code": 32
    },
    {
        "district": "รัตนบุรี",
        "amphoe": "รัตนบุรี",
        "province": "สุรินทร์",
        "zipcode": 32130,
        "district_code": 320701,
        "amphoe_code": 3207,
        "province_code": 32
    },
    {
        "district": "หนองบัวทอง",
        "amphoe": "รัตนบุรี",
        "province": "สุรินทร์",
        "zipcode": 32130,
        "district_code": 320705,
        "amphoe_code": 3207,
        "province_code": 32
    },
    {
        "district": "หนองบัวบาน",
        "amphoe": "รัตนบุรี",
        "province": "สุรินทร์",
        "zipcode": 32130,
        "district_code": 320706,
        "amphoe_code": 3207,
        "province_code": 32
    },
    {
        "district": "เบิด",
        "amphoe": "รัตนบุรี",
        "province": "สุรินทร์",
        "zipcode": 32130,
        "district_code": 320711,
        "amphoe_code": 3207,
        "province_code": 32
    },
    {
        "district": "แก",
        "amphoe": "รัตนบุรี",
        "province": "สุรินทร์",
        "zipcode": 32130,
        "district_code": 320703,
        "amphoe_code": 3207,
        "province_code": 32
    },
    {
        "district": "ไผ่",
        "amphoe": "รัตนบุรี",
        "province": "สุรินทร์",
        "zipcode": 32130,
        "district_code": 320709,
        "amphoe_code": 3207,
        "province_code": 32
    },
    {
        "district": "ตระเปียงเตีย",
        "amphoe": "ลำดวน",
        "province": "สุรินทร์",
        "zipcode": 32220,
        "district_code": 321105,
        "amphoe_code": 3211,
        "province_code": 32
    },
    {
        "district": "ตรำดม",
        "amphoe": "ลำดวน",
        "province": "สุรินทร์",
        "zipcode": 32220,
        "district_code": 321104,
        "amphoe_code": 3211,
        "province_code": 32
    },
    {
        "district": "ลำดวน",
        "amphoe": "ลำดวน",
        "province": "สุรินทร์",
        "zipcode": 32220,
        "district_code": 321101,
        "amphoe_code": 3211,
        "province_code": 32
    },
    {
        "district": "อู่โลก",
        "amphoe": "ลำดวน",
        "province": "สุรินทร์",
        "zipcode": 32220,
        "district_code": 321103,
        "amphoe_code": 3211,
        "province_code": 32
    },
    {
        "district": "โชคเหนือ",
        "amphoe": "ลำดวน",
        "province": "สุรินทร์",
        "zipcode": 32220,
        "district_code": 321102,
        "amphoe_code": 3211,
        "province_code": 32
    },
    {
        "district": "ณรงค์",
        "amphoe": "ศรีณรงค์",
        "province": "สุรินทร์",
        "zipcode": 32150,
        "district_code": 321003,
        "amphoe_code": 3215,
        "province_code": 32
    },
    {
        "district": "ตรวจ",
        "amphoe": "ศรีณรงค์",
        "province": "สุรินทร์",
        "zipcode": 32150,
        "district_code": 321005,
        "amphoe_code": 3215,
        "province_code": 32
    },
    {
        "district": "ศรีสุข",
        "amphoe": "ศรีณรงค์",
        "province": "สุรินทร์",
        "zipcode": 32150,
        "district_code": 321014,
        "amphoe_code": 3215,
        "province_code": 32
    },
    {
        "district": "หนองแวง",
        "amphoe": "ศรีณรงค์",
        "province": "สุรินทร์",
        "zipcode": 32150,
        "district_code": 321016,
        "amphoe_code": 3215,
        "province_code": 32
    },
    {
        "district": "แจนแวน",
        "amphoe": "ศรีณรงค์",
        "province": "สุรินทร์",
        "zipcode": 32150,
        "district_code": 321004,
        "amphoe_code": 3215,
        "province_code": 32
    },
    {
        "district": "กุดหวาย",
        "amphoe": "ศีขรภูมิ",
        "province": "สุรินทร์",
        "zipcode": 32110,
        "district_code": 320911,
        "amphoe_code": 3209,
        "province_code": 32
    },
    {
        "district": "ขวาวใหญ่",
        "amphoe": "ศีขรภูมิ",
        "province": "สุรินทร์",
        "zipcode": 32110,
        "district_code": 320912,
        "amphoe_code": 3209,
        "province_code": 32
    },
    {
        "district": "คาละแมะ",
        "amphoe": "ศีขรภูมิ",
        "province": "สุรินทร์",
        "zipcode": 32110,
        "district_code": 320907,
        "amphoe_code": 3209,
        "province_code": 32
    },
    {
        "district": "จารพัต",
        "amphoe": "ศีขรภูมิ",
        "province": "สุรินทร์",
        "zipcode": 32110,
        "district_code": 320903,
        "amphoe_code": 3209,
        "province_code": 32
    },
    {
        "district": "ช่างปี่",
        "amphoe": "ศีขรภูมิ",
        "province": "สุรินทร์",
        "zipcode": 32110,
        "district_code": 320910,
        "amphoe_code": 3209,
        "province_code": 32
    },
    {
        "district": "ตรมไพร",
        "amphoe": "ศีขรภูมิ",
        "province": "สุรินทร์",
        "zipcode": 32110,
        "district_code": 320914,
        "amphoe_code": 3209,
        "province_code": 32
    },
    {
        "district": "ตรึม",
        "amphoe": "ศีขรภูมิ",
        "province": "สุรินทร์",
        "zipcode": 32110,
        "district_code": 320902,
        "amphoe_code": 3209,
        "province_code": 32
    },
    {
        "district": "นารุ่ง",
        "amphoe": "ศีขรภูมิ",
        "province": "สุรินทร์",
        "zipcode": 32110,
        "district_code": 320913,
        "amphoe_code": 3209,
        "province_code": 32
    },
    {
        "district": "ผักไหม",
        "amphoe": "ศีขรภูมิ",
        "province": "สุรินทร์",
        "zipcode": 32110,
        "district_code": 320915,
        "amphoe_code": 3209,
        "province_code": 32
    },
    {
        "district": "ยาง",
        "amphoe": "ศีขรภูมิ",
        "province": "สุรินทร์",
        "zipcode": 32110,
        "district_code": 320904,
        "amphoe_code": 3209,
        "province_code": 32
    },
    {
        "district": "ระแงง",
        "amphoe": "ศีขรภูมิ",
        "province": "สุรินทร์",
        "zipcode": 32110,
        "district_code": 320901,
        "amphoe_code": 3209,
        "province_code": 32
    },
    {
        "district": "หนองขวาว",
        "amphoe": "ศีขรภูมิ",
        "province": "สุรินทร์",
        "zipcode": 32110,
        "district_code": 320909,
        "amphoe_code": 3209,
        "province_code": 32
    },
    {
        "district": "หนองบัว",
        "amphoe": "ศีขรภูมิ",
        "province": "สุรินทร์",
        "zipcode": 32110,
        "district_code": 320307,
        "amphoe_code": 3209,
        "province_code": 32
    },
    {
        "district": "หนองเหล็ก",
        "amphoe": "ศีขรภูมิ",
        "province": "สุรินทร์",
        "zipcode": 32110,
        "district_code": 320908,
        "amphoe_code": 3209,
        "province_code": 32
    },
    {
        "district": "แตล",
        "amphoe": "ศีขรภูมิ",
        "province": "สุรินทร์",
        "zipcode": 32110,
        "district_code": 320905,
        "amphoe_code": 3209,
        "province_code": 32
    },
    {
        "district": "นานวน",
        "amphoe": "สนม",
        "province": "สุรินทร์",
        "zipcode": 32160,
        "district_code": 320797,
        "amphoe_code": 3208,
        "province_code": 32
    },
    {
        "district": "สนม",
        "amphoe": "สนม",
        "province": "สุรินทร์",
        "zipcode": 32160,
        "district_code": 320795,
        "amphoe_code": 3208,
        "province_code": 32
    },
    {
        "district": "หนองระฆัง",
        "amphoe": "สนม",
        "province": "สุรินทร์",
        "zipcode": 32160,
        "district_code": 320796,
        "amphoe_code": 3208,
        "province_code": 32
    },
    {
        "district": "หนองอียอ",
        "amphoe": "สนม",
        "province": "สุรินทร์",
        "zipcode": 32160,
        "district_code": 320807,
        "amphoe_code": 3208,
        "province_code": 32
    },
    {
        "district": "หัวงัว",
        "amphoe": "สนม",
        "province": "สุรินทร์",
        "zipcode": 32160,
        "district_code": 320806,
        "amphoe_code": 3208,
        "province_code": 32
    },
    {
        "district": "แคน",
        "amphoe": "สนม",
        "province": "สุรินทร์",
        "zipcode": 32160,
        "district_code": 320799,
        "amphoe_code": 3208,
        "province_code": 32
    },
    {
        "district": "โพนโก",
        "amphoe": "สนม",
        "province": "สุรินทร์",
        "zipcode": 32160,
        "district_code": 320798,
        "amphoe_code": 3208,
        "province_code": 32
    },
    {
        "district": "กระเทียม",
        "amphoe": "สังขะ",
        "province": "สุรินทร์",
        "zipcode": 32150,
        "district_code": 321009,
        "amphoe_code": 3210,
        "province_code": 32
    },
    {
        "district": "ขอนแตก",
        "amphoe": "สังขะ",
        "province": "สุรินทร์",
        "zipcode": 32150,
        "district_code": 321002,
        "amphoe_code": 3210,
        "province_code": 32
    },
    {
        "district": "ดม",
        "amphoe": "สังขะ",
        "province": "สุรินทร์",
        "zipcode": 32150,
        "district_code": 321006,
        "amphoe_code": 3210,
        "province_code": 32
    },
    {
        "district": "ตาคง",
        "amphoe": "สังขะ",
        "province": "สุรินทร์",
        "zipcode": 32150,
        "district_code": 321013,
        "amphoe_code": 3210,
        "province_code": 32
    },
    {
        "district": "ตาตุม",
        "amphoe": "สังขะ",
        "province": "สุรินทร์",
        "zipcode": 32150,
        "district_code": 321011,
        "amphoe_code": 3210,
        "province_code": 32
    },
    {
        "district": "ทับทัน",
        "amphoe": "สังขะ",
        "province": "สุรินทร์",
        "zipcode": 32150,
        "district_code": 321012,
        "amphoe_code": 3210,
        "province_code": 32
    },
    {
        "district": "บ้านจารย์",
        "amphoe": "สังขะ",
        "province": "สุรินทร์",
        "zipcode": 32150,
        "district_code": 321008,
        "amphoe_code": 3210,
        "province_code": 32
    },
    {
        "district": "บ้านชบ",
        "amphoe": "สังขะ",
        "province": "สุรินทร์",
        "zipcode": 32150,
        "district_code": 321015,
        "amphoe_code": 3210,
        "province_code": 32
    },
    {
        "district": "พระแก้ว",
        "amphoe": "สังขะ",
        "province": "สุรินทร์",
        "zipcode": 32150,
        "district_code": 321007,
        "amphoe_code": 3210,
        "province_code": 32
    },
    {
        "district": "สะกาด",
        "amphoe": "สังขะ",
        "province": "สุรินทร์",
        "zipcode": 32150,
        "district_code": 321010,
        "amphoe_code": 3210,
        "province_code": 32
    },
    {
        "district": "สังขะ",
        "amphoe": "สังขะ",
        "province": "สุรินทร์",
        "zipcode": 32150,
        "district_code": 321001,
        "amphoe_code": 3210,
        "province_code": 32
    },
    {
        "district": "เทพรักษา",
        "amphoe": "สังขะ",
        "province": "สุรินทร์",
        "zipcode": 32150,
        "district_code": 321017,
        "amphoe_code": 3210,
        "province_code": 32
    },
    {
        "district": "กระออม",
        "amphoe": "สำโรงทาบ",
        "province": "สุรินทร์",
        "zipcode": 32170,
        "district_code": 321203,
        "amphoe_code": 3212,
        "province_code": 32
    },
    {
        "district": "ประดู่",
        "amphoe": "สำโรงทาบ",
        "province": "สุรินทร์",
        "zipcode": 32170,
        "district_code": 321210,
        "amphoe_code": 3212,
        "province_code": 32
    },
    {
        "district": "ศรีสุข",
        "amphoe": "สำโรงทาบ",
        "province": "สุรินทร์",
        "zipcode": 32170,
        "district_code": 321014,
        "amphoe_code": 3212,
        "province_code": 32
    },
    {
        "district": "สะโน",
        "amphoe": "สำโรงทาบ",
        "province": "สุรินทร์",
        "zipcode": 32170,
        "district_code": 321209,
        "amphoe_code": 3212,
        "province_code": 32
    },
    {
        "district": "สำโรงทาบ",
        "amphoe": "สำโรงทาบ",
        "province": "สุรินทร์",
        "zipcode": 32170,
        "district_code": 321201,
        "amphoe_code": 3212,
        "province_code": 32
    },
    {
        "district": "หนองฮะ",
        "amphoe": "สำโรงทาบ",
        "province": "สุรินทร์",
        "zipcode": 32170,
        "district_code": 321204,
        "amphoe_code": 3212,
        "province_code": 32
    },
    {
        "district": "หนองไผ่ล้อม",
        "amphoe": "สำโรงทาบ",
        "province": "สุรินทร์",
        "zipcode": 32170,
        "district_code": 321202,
        "amphoe_code": 3212,
        "province_code": 32
    },
    {
        "district": "หมื่นศรี",
        "amphoe": "สำโรงทาบ",
        "province": "สุรินทร์",
        "zipcode": 32170,
        "district_code": 321207,
        "amphoe_code": 3212,
        "province_code": 32
    },
    {
        "district": "เกาะแก้ว",
        "amphoe": "สำโรงทาบ",
        "province": "สุรินทร์",
        "zipcode": 32170,
        "district_code": 321206,
        "amphoe_code": 3212,
        "province_code": 32
    },
    {
        "district": "เสม็จ",
        "amphoe": "สำโรงทาบ",
        "province": "สุรินทร์",
        "zipcode": 32170,
        "district_code": 321208,
        "amphoe_code": 3212,
        "province_code": 32
    },
    {
        "district": "ตากูก",
        "amphoe": "เขวาสินรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320108,
        "amphoe_code": 3216,
        "province_code": 32
    },
    {
        "district": "บึง",
        "amphoe": "เขวาสินรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320115,
        "amphoe_code": 3216,
        "province_code": 32
    },
    {
        "district": "บ้านแร่",
        "amphoe": "เขวาสินรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320123,
        "amphoe_code": 3216,
        "province_code": 32
    },
    {
        "district": "ปราสาททอง",
        "amphoe": "เขวาสินรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320124,
        "amphoe_code": 3216,
        "province_code": 32
    },
    {
        "district": "เขวาสินรินทร์",
        "amphoe": "เขวาสินรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320117,
        "amphoe_code": 3216,
        "province_code": 32
    },
    {
        "district": "กาเกาะ",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320126,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "คอโค",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320112,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "ตระแสง",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320122,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "ตั้งใจ",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320102,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "ตาอ็อง",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320107,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "ท่าสว่าง",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320105,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "นอกเมือง",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320111,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "นาดี",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320104,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "นาบัว",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320118,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "บุฤาษี",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320121,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "ราม",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320120,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "สลักได",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320106,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "สวาย",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320113,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "สำโรง",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320109,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "เฉนียง",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320114,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "เทนมีย์",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320116,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "เพี้ยราม",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320103,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "เมืองที",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320119,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "แกใหญ่",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320110,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "แสลงพันธ์",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320125,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "ในเมือง",
        "amphoe": "เมืองสุรินทร์",
        "province": "สุรินทร์",
        "zipcode": 32000,
        "district_code": 320101,
        "amphoe_code": 3201,
        "province_code": 32
    },
    {
        "district": "คำผง",
        "amphoe": "โนนนารายณ์",
        "province": "สุรินทร์",
        "zipcode": 32130,
        "district_code": 320717,
        "amphoe_code": 3217,
        "province_code": 32
    },
    {
        "district": "ระเวียง",
        "amphoe": "โนนนารายณ์",
        "province": "สุรินทร์",
        "zipcode": 32130,
        "district_code": 320712,
        "amphoe_code": 3217,
        "province_code": 32
    },
    {
        "district": "หนองหลวง",
        "amphoe": "โนนนารายณ์",
        "province": "สุรินทร์",
        "zipcode": 32130,
        "district_code": 320707,
        "amphoe_code": 3217,
        "province_code": 32
    },
    {
        "district": "หนองเทพ",
        "amphoe": "โนนนารายณ์",
        "province": "สุรินทร์",
        "zipcode": 32130,
        "district_code": 320708,
        "amphoe_code": 3217,
        "province_code": 32
    },
    {
        "district": "โนน",
        "amphoe": "โนนนารายณ์",
        "province": "สุรินทร์",
        "zipcode": 32130,
        "district_code": 320710,
        "amphoe_code": 3217,
        "province_code": 32
    },
    {
        "district": "กกแรต",
        "amphoe": "กงไกรลาศ",
        "province": "สุโขทัย",
        "zipcode": 64170,
        "district_code": 640408,
        "amphoe_code": 6404,
        "province_code": 64
    },
    {
        "district": "กง",
        "amphoe": "กงไกรลาศ",
        "province": "สุโขทัย",
        "zipcode": 64170,
        "district_code": 640401,
        "amphoe_code": 6404,
        "province_code": 64
    },
    {
        "district": "ดงเดือย",
        "amphoe": "กงไกรลาศ",
        "province": "สุโขทัย",
        "zipcode": 64170,
        "district_code": 640406,
        "amphoe_code": 6404,
        "province_code": 64
    },
    {
        "district": "ท่าฉนวน",
        "amphoe": "กงไกรลาศ",
        "province": "สุโขทัย",
        "zipcode": 64170,
        "district_code": 640409,
        "amphoe_code": 6404,
        "province_code": 64
    },
    {
        "district": "บ้านกร่าง",
        "amphoe": "กงไกรลาศ",
        "province": "สุโขทัย",
        "zipcode": 64170,
        "district_code": 640402,
        "amphoe_code": 6404,
        "province_code": 64
    },
    {
        "district": "บ้านใหม่สุขเกษม",
        "amphoe": "กงไกรลาศ",
        "province": "สุโขทัย",
        "zipcode": 64170,
        "district_code": 640411,
        "amphoe_code": 6404,
        "province_code": 64
    },
    {
        "district": "ป่าแฝก",
        "amphoe": "กงไกรลาศ",
        "province": "สุโขทัย",
        "zipcode": 64170,
        "district_code": 640407,
        "amphoe_code": 6404,
        "province_code": 64
    },
    {
        "district": "หนองตูม",
        "amphoe": "กงไกรลาศ",
        "province": "สุโขทัย",
        "zipcode": 64170,
        "district_code": 640410,
        "amphoe_code": 6404,
        "province_code": 64
    },
    {
        "district": "ไกรกลาง",
        "amphoe": "กงไกรลาศ",
        "province": "สุโขทัย",
        "zipcode": 64170,
        "district_code": 640404,
        "amphoe_code": 6404,
        "province_code": 64
    },
    {
        "district": "ไกรนอก",
        "amphoe": "กงไกรลาศ",
        "province": "สุโขทัย",
        "zipcode": 64170,
        "district_code": 640403,
        "amphoe_code": 6404,
        "province_code": 64
    },
    {
        "district": "ไกรใน",
        "amphoe": "กงไกรลาศ",
        "province": "สุโขทัย",
        "zipcode": 64170,
        "district_code": 640405,
        "amphoe_code": 6404,
        "province_code": 64
    },
    {
        "district": "ทุ่งยางเมือง",
        "amphoe": "คีรีมาศ",
        "province": "สุโขทัย",
        "zipcode": 64160,
        "district_code": 640310,
        "amphoe_code": 6403,
        "province_code": 64
    },
    {
        "district": "ทุ่งหลวง",
        "amphoe": "คีรีมาศ",
        "province": "สุโขทัย",
        "zipcode": 64160,
        "district_code": 640302,
        "amphoe_code": 6403,
        "province_code": 64
    },
    {
        "district": "นาเชิงคีรี",
        "amphoe": "คีรีมาศ",
        "province": "สุโขทัย",
        "zipcode": 64160,
        "district_code": 640307,
        "amphoe_code": 6403,
        "province_code": 64
    },
    {
        "district": "บ้านน้ำพุ",
        "amphoe": "คีรีมาศ",
        "province": "สุโขทัย",
        "zipcode": 64160,
        "district_code": 640309,
        "amphoe_code": 6403,
        "province_code": 64
    },
    {
        "district": "บ้านป้อม",
        "amphoe": "คีรีมาศ",
        "province": "สุโขทัย",
        "zipcode": 64160,
        "district_code": 640303,
        "amphoe_code": 6403,
        "province_code": 64
    },
    {
        "district": "ศรีคีรีมาศ",
        "amphoe": "คีรีมาศ",
        "province": "สุโขทัย",
        "zipcode": 64160,
        "district_code": 640305,
        "amphoe_code": 6403,
        "province_code": 64
    },
    {
        "district": "สามพวง",
        "amphoe": "คีรีมาศ",
        "province": "สุโขทัย",
        "zipcode": 64160,
        "district_code": 640304,
        "amphoe_code": 6403,
        "province_code": 64
    },
    {
        "district": "หนองกระดิ่ง",
        "amphoe": "คีรีมาศ",
        "province": "สุโขทัย",
        "zipcode": 64160,
        "district_code": 640308,
        "amphoe_code": 6403,
        "province_code": 64
    },
    {
        "district": "หนองจิก",
        "amphoe": "คีรีมาศ",
        "province": "สุโขทัย",
        "zipcode": 64160,
        "district_code": 640306,
        "amphoe_code": 6403,
        "province_code": 64
    },
    {
        "district": "โตนด",
        "amphoe": "คีรีมาศ",
        "province": "สุโขทัย",
        "zipcode": 64160,
        "district_code": 640301,
        "amphoe_code": 6403,
        "province_code": 64
    },
    {
        "district": "กลางดง",
        "amphoe": "ทุ่งเสลี่ยม",
        "province": "สุโขทัย",
        "zipcode": 64150,
        "district_code": 640904,
        "amphoe_code": 6409,
        "province_code": 64
    },
    {
        "district": "ทุ่งเสลี่ยม",
        "amphoe": "ทุ่งเสลี่ยม",
        "province": "สุโขทัย",
        "zipcode": 64150,
        "district_code": 640903,
        "amphoe_code": 6409,
        "province_code": 64
    },
    {
        "district": "บ้านใหม่ไชยมงคล",
        "amphoe": "ทุ่งเสลี่ยม",
        "province": "สุโขทัย",
        "zipcode": 64230,
        "district_code": 640901,
        "amphoe_code": 6409,
        "province_code": 64
    },
    {
        "district": "เขาแก้วศรีสมบูรณ์",
        "amphoe": "ทุ่งเสลี่ยม",
        "province": "สุโขทัย",
        "zipcode": 64230,
        "district_code": 640905,
        "amphoe_code": 6409,
        "province_code": 64
    },
    {
        "district": "ไทยชนะศึก",
        "amphoe": "ทุ่งเสลี่ยม",
        "province": "สุโขทัย",
        "zipcode": 64150,
        "district_code": 640902,
        "amphoe_code": 6409,
        "province_code": 64
    },
    {
        "district": "ตลิ่งชัน",
        "amphoe": "บ้านด่านลานหอย",
        "province": "สุโขทัย",
        "zipcode": 64140,
        "district_code": 640205,
        "amphoe_code": 6402,
        "province_code": 64
    },
    {
        "district": "บ้านด่าน",
        "amphoe": "บ้านด่านลานหอย",
        "province": "สุโขทัย",
        "zipcode": 64140,
        "district_code": 640202,
        "amphoe_code": 6402,
        "province_code": 64
    },
    {
        "district": "ลานหอย",
        "amphoe": "บ้านด่านลานหอย",
        "province": "สุโขทัย",
        "zipcode": 64140,
        "district_code": 640201,
        "amphoe_code": 6402,
        "province_code": 64
    },
    {
        "district": "วังตะคร้อ",
        "amphoe": "บ้านด่านลานหอย",
        "province": "สุโขทัย",
        "zipcode": 64140,
        "district_code": 640203,
        "amphoe_code": 6402,
        "province_code": 64
    },
    {
        "district": "วังน้ำขาว",
        "amphoe": "บ้านด่านลานหอย",
        "province": "สุโขทัย",
        "zipcode": 64140,
        "district_code": 640204,
        "amphoe_code": 6402,
        "province_code": 64
    },
    {
        "district": "วังลึก",
        "amphoe": "บ้านด่านลานหอย",
        "province": "สุโขทัย",
        "zipcode": 64140,
        "district_code": 640207,
        "amphoe_code": 6402,
        "province_code": 64
    },
    {
        "district": "หนองหญ้าปล้อง",
        "amphoe": "บ้านด่านลานหอย",
        "province": "สุโขทัย",
        "zipcode": 64140,
        "district_code": 640206,
        "amphoe_code": 6402,
        "province_code": 64
    },
    {
        "district": "คลองมะพลับ",
        "amphoe": "ศรีนคร",
        "province": "สุโขทัย",
        "zipcode": 64180,
        "district_code": 640796,
        "amphoe_code": 6408,
        "province_code": 64
    },
    {
        "district": "นครเดิฐ",
        "amphoe": "ศรีนคร",
        "province": "สุโขทัย",
        "zipcode": 64180,
        "district_code": 640798,
        "amphoe_code": 6408,
        "province_code": 64
    },
    {
        "district": "น้ำขุม",
        "amphoe": "ศรีนคร",
        "province": "สุโขทัย",
        "zipcode": 64180,
        "district_code": 640797,
        "amphoe_code": 6408,
        "province_code": 64
    },
    {
        "district": "ศรีนคร",
        "amphoe": "ศรีนคร",
        "province": "สุโขทัย",
        "zipcode": 64180,
        "district_code": 640799,
        "amphoe_code": 6408,
        "province_code": 64
    },
    {
        "district": "หนองบัว",
        "amphoe": "ศรีนคร",
        "province": "สุโขทัย",
        "zipcode": 64180,
        "district_code": 640805,
        "amphoe_code": 6408,
        "province_code": 64
    },
    {
        "district": "ดงคู่",
        "amphoe": "ศรีสัชนาลัย",
        "province": "สุโขทัย",
        "zipcode": 64130,
        "district_code": 640509,
        "amphoe_code": 6405,
        "province_code": 64
    },
    {
        "district": "ท่าชัย",
        "amphoe": "ศรีสัชนาลัย",
        "province": "สุโขทัย",
        "zipcode": 64190,
        "district_code": 640507,
        "amphoe_code": 6405,
        "province_code": 64
    },
    {
        "district": "บ้านตึก",
        "amphoe": "ศรีสัชนาลัย",
        "province": "สุโขทัย",
        "zipcode": 64130,
        "district_code": 640505,
        "amphoe_code": 6405,
        "province_code": 64
    },
    {
        "district": "บ้านแก่ง",
        "amphoe": "ศรีสัชนาลัย",
        "province": "สุโขทัย",
        "zipcode": 64130,
        "district_code": 640510,
        "amphoe_code": 6405,
        "province_code": 64
    },
    {
        "district": "ป่างิ้ว",
        "amphoe": "ศรีสัชนาลัย",
        "province": "สุโขทัย",
        "zipcode": 64130,
        "district_code": 640502,
        "amphoe_code": 6405,
        "province_code": 64
    },
    {
        "district": "ศรีสัชนาลัย",
        "amphoe": "ศรีสัชนาลัย",
        "province": "สุโขทัย",
        "zipcode": 64190,
        "district_code": 640508,
        "amphoe_code": 6405,
        "province_code": 64
    },
    {
        "district": "สารจิตร",
        "amphoe": "ศรีสัชนาลัย",
        "province": "สุโขทัย",
        "zipcode": 64130,
        "district_code": 640511,
        "amphoe_code": 6405,
        "province_code": 64
    },
    {
        "district": "หนองอ้อ",
        "amphoe": "ศรีสัชนาลัย",
        "province": "สุโขทัย",
        "zipcode": 64130,
        "district_code": 640506,
        "amphoe_code": 6405,
        "province_code": 64
    },
    {
        "district": "หาดเสี้ยว",
        "amphoe": "ศรีสัชนาลัย",
        "province": "สุโขทัย",
        "zipcode": 64130,
        "district_code": 640501,
        "amphoe_code": 6405,
        "province_code": 64
    },
    {
        "district": "แม่สำ",
        "amphoe": "ศรีสัชนาลัย",
        "province": "สุโขทัย",
        "zipcode": 64130,
        "district_code": 640503,
        "amphoe_code": 6405,
        "province_code": 64
    },
    {
        "district": "แม่สิน",
        "amphoe": "ศรีสัชนาลัย",
        "province": "สุโขทัย",
        "zipcode": 64130,
        "district_code": 640504,
        "amphoe_code": 6405,
        "province_code": 64
    },
    {
        "district": "คลองตาล",
        "amphoe": "ศรีสำโรง",
        "province": "สุโขทัย",
        "zipcode": 64120,
        "district_code": 640601,
        "amphoe_code": 6406,
        "province_code": 64
    },
    {
        "district": "ทับผึ้ง",
        "amphoe": "ศรีสำโรง",
        "province": "สุโขทัย",
        "zipcode": 64120,
        "district_code": 640610,
        "amphoe_code": 6406,
        "province_code": 64
    },
    {
        "district": "นาขุนไกร",
        "amphoe": "ศรีสำโรง",
        "province": "สุโขทัย",
        "zipcode": 64120,
        "district_code": 640606,
        "amphoe_code": 6406,
        "province_code": 64
    },
    {
        "district": "บ้านซ่าน",
        "amphoe": "ศรีสำโรง",
        "province": "สุโขทัย",
        "zipcode": 64120,
        "district_code": 640611,
        "amphoe_code": 6406,
        "province_code": 64
    },
    {
        "district": "บ้านนา",
        "amphoe": "ศรีสำโรง",
        "province": "สุโขทัย",
        "zipcode": 64120,
        "district_code": 640604,
        "amphoe_code": 6406,
        "province_code": 64
    },
    {
        "district": "บ้านไร่",
        "amphoe": "ศรีสำโรง",
        "province": "สุโขทัย",
        "zipcode": 64120,
        "district_code": 640609,
        "amphoe_code": 6406,
        "province_code": 64
    },
    {
        "district": "ราวต้นจันทร์",
        "amphoe": "ศรีสำโรง",
        "province": "สุโขทัย",
        "zipcode": 64120,
        "district_code": 640613,
        "amphoe_code": 6406,
        "province_code": 64
    },
    {
        "district": "วังทอง",
        "amphoe": "ศรีสำโรง",
        "province": "สุโขทัย",
        "zipcode": 64120,
        "district_code": 640605,
        "amphoe_code": 6406,
        "province_code": 64
    },
    {
        "district": "วังลึก",
        "amphoe": "ศรีสำโรง",
        "province": "สุโขทัย",
        "zipcode": 64120,
        "district_code": 640207,
        "amphoe_code": 6406,
        "province_code": 64
    },
    {
        "district": "วังใหญ่",
        "amphoe": "ศรีสำโรง",
        "province": "สุโขทัย",
        "zipcode": 64120,
        "district_code": 640612,
        "amphoe_code": 6406,
        "province_code": 64
    },
    {
        "district": "วัดเกาะ",
        "amphoe": "ศรีสำโรง",
        "province": "สุโขทัย",
        "zipcode": 64120,
        "district_code": 640608,
        "amphoe_code": 6406,
        "province_code": 64
    },
    {
        "district": "สามเรือน",
        "amphoe": "ศรีสำโรง",
        "province": "สุโขทัย",
        "zipcode": 64120,
        "district_code": 640603,
        "amphoe_code": 6406,
        "province_code": 64
    },
    {
        "district": "เกาะตาเลี้ยง",
        "amphoe": "ศรีสำโรง",
        "province": "สุโขทัย",
        "zipcode": 64120,
        "district_code": 640607,
        "amphoe_code": 6406,
        "province_code": 64
    },
    {
        "district": "คลองกระจง",
        "amphoe": "สวรรคโลก",
        "province": "สุโขทัย",
        "zipcode": 64110,
        "district_code": 640703,
        "amphoe_code": 6407,
        "province_code": 64
    },
    {
        "district": "คลองยาง",
        "amphoe": "สวรรคโลก",
        "province": "สุโขทัย",
        "zipcode": 64110,
        "district_code": 640708,
        "amphoe_code": 6407,
        "province_code": 64
    },
    {
        "district": "ท่าทอง",
        "amphoe": "สวรรคโลก",
        "province": "สุโขทัย",
        "zipcode": 64110,
        "district_code": 640710,
        "amphoe_code": 6407,
        "province_code": 64
    },
    {
        "district": "นาทุ่ง",
        "amphoe": "สวรรคโลก",
        "province": "สุโขทัย",
        "zipcode": 64110,
        "district_code": 640707,
        "amphoe_code": 6407,
        "province_code": 64
    },
    {
        "district": "ปากน้ำ",
        "amphoe": "สวรรคโลก",
        "province": "สุโขทัย",
        "zipcode": 64110,
        "district_code": 640711,
        "amphoe_code": 6407,
        "province_code": 64
    },
    {
        "district": "ป่ากุมเกาะ",
        "amphoe": "สวรรคโลก",
        "province": "สุโขทัย",
        "zipcode": 64110,
        "district_code": 640712,
        "amphoe_code": 6407,
        "province_code": 64
    },
    {
        "district": "ย่านยาว",
        "amphoe": "สวรรคโลก",
        "province": "สุโขทัย",
        "zipcode": 64110,
        "district_code": 640706,
        "amphoe_code": 6407,
        "province_code": 64
    },
    {
        "district": "วังพิณพาทย์",
        "amphoe": "สวรรคโลก",
        "province": "สุโขทัย",
        "zipcode": 64110,
        "district_code": 640704,
        "amphoe_code": 6407,
        "province_code": 64
    },
    {
        "district": "วังไม้ขอน",
        "amphoe": "สวรรคโลก",
        "province": "สุโขทัย",
        "zipcode": 64110,
        "district_code": 640705,
        "amphoe_code": 6407,
        "province_code": 64
    },
    {
        "district": "หนองกลับ",
        "amphoe": "สวรรคโลก",
        "province": "สุโขทัย",
        "zipcode": 64110,
        "district_code": 640714,
        "amphoe_code": 6407,
        "province_code": 64
    },
    {
        "district": "เมืองบางขลัง",
        "amphoe": "สวรรคโลก",
        "province": "สุโขทัย",
        "zipcode": 64110,
        "district_code": 640713,
        "amphoe_code": 6407,
        "province_code": 64
    },
    {
        "district": "เมืองบางยม",
        "amphoe": "สวรรคโลก",
        "province": "สุโขทัย",
        "zipcode": 64110,
        "district_code": 640709,
        "amphoe_code": 6407,
        "province_code": 64
    },
    {
        "district": "เมืองสวรรคโลก",
        "amphoe": "สวรรคโลก",
        "province": "สุโขทัย",
        "zipcode": 64110,
        "district_code": 640701,
        "amphoe_code": 6407,
        "province_code": 64
    },
    {
        "district": "ในเมือง",
        "amphoe": "สวรรคโลก",
        "province": "สุโขทัย",
        "zipcode": 64110,
        "district_code": 640702,
        "amphoe_code": 6407,
        "province_code": 64
    },
    {
        "district": "ตาลเตี้ย",
        "amphoe": "เมืองสุโขทัย",
        "province": "สุโขทัย",
        "zipcode": 64220,
        "district_code": 640108,
        "amphoe_code": 6401,
        "province_code": 64
    },
    {
        "district": "ธานี",
        "amphoe": "เมืองสุโขทัย",
        "province": "สุโขทัย",
        "zipcode": 64000,
        "district_code": 640101,
        "amphoe_code": 6401,
        "province_code": 64
    },
    {
        "district": "บ้านกล้วย",
        "amphoe": "เมืองสุโขทัย",
        "province": "สุโขทัย",
        "zipcode": 64000,
        "district_code": 640106,
        "amphoe_code": 6401,
        "province_code": 64
    },
    {
        "district": "บ้านสวน",
        "amphoe": "เมืองสุโขทัย",
        "province": "สุโขทัย",
        "zipcode": 64220,
        "district_code": 640102,
        "amphoe_code": 6401,
        "province_code": 64
    },
    {
        "district": "บ้านหลุม",
        "amphoe": "เมืองสุโขทัย",
        "province": "สุโขทัย",
        "zipcode": 64000,
        "district_code": 640107,
        "amphoe_code": 6401,
        "province_code": 64
    },
    {
        "district": "ปากพระ",
        "amphoe": "เมืองสุโขทัย",
        "province": "สุโขทัย",
        "zipcode": 64000,
        "district_code": 640109,
        "amphoe_code": 6401,
        "province_code": 64
    },
    {
        "district": "ปากแคว",
        "amphoe": "เมืองสุโขทัย",
        "province": "สุโขทัย",
        "zipcode": 64000,
        "district_code": 640104,
        "amphoe_code": 6401,
        "province_code": 64
    },
    {
        "district": "ยางซ้าย",
        "amphoe": "เมืองสุโขทัย",
        "province": "สุโขทัย",
        "zipcode": 64000,
        "district_code": 640105,
        "amphoe_code": 6401,
        "province_code": 64
    },
    {
        "district": "วังทองแดง",
        "amphoe": "เมืองสุโขทัย",
        "province": "สุโขทัย",
        "zipcode": 64210,
        "district_code": 640110,
        "amphoe_code": 6401,
        "province_code": 64
    },
    {
        "district": "เมืองเก่า",
        "amphoe": "เมืองสุโขทัย",
        "province": "สุโขทัย",
        "zipcode": 64210,
        "district_code": 640103,
        "amphoe_code": 6401,
        "province_code": 64
    },
    {
        "district": "กองนาง",
        "amphoe": "ท่าบ่อ",
        "province": "หนองคาย",
        "zipcode": 43110,
        "district_code": 430203,
        "amphoe_code": 4302,
        "province_code": 43
    },
    {
        "district": "ท่าบ่อ",
        "amphoe": "ท่าบ่อ",
        "province": "หนองคาย",
        "zipcode": 43110,
        "district_code": 430201,
        "amphoe_code": 4302,
        "province_code": 43
    },
    {
        "district": "นาข่า",
        "amphoe": "ท่าบ่อ",
        "province": "หนองคาย",
        "zipcode": 43110,
        "district_code": 430208,
        "amphoe_code": 4302,
        "province_code": 43
    },
    {
        "district": "น้ำโมง",
        "amphoe": "ท่าบ่อ",
        "province": "หนองคาย",
        "zipcode": 43110,
        "district_code": 430202,
        "amphoe_code": 4302,
        "province_code": 43
    },
    {
        "district": "บ้านถ่อน",
        "amphoe": "ท่าบ่อ",
        "province": "หนองคาย",
        "zipcode": 43110,
        "district_code": 430206,
        "amphoe_code": 4302,
        "province_code": 43
    },
    {
        "district": "บ้านว่าน",
        "amphoe": "ท่าบ่อ",
        "province": "หนองคาย",
        "zipcode": 43110,
        "district_code": 430207,
        "amphoe_code": 4302,
        "province_code": 43
    },
    {
        "district": "บ้านเดื่อ",
        "amphoe": "ท่าบ่อ",
        "province": "หนองคาย",
        "zipcode": 43110,
        "district_code": 430109,
        "amphoe_code": 4302,
        "province_code": 43
    },
    {
        "district": "หนองนาง",
        "amphoe": "ท่าบ่อ",
        "province": "หนองคาย",
        "zipcode": 43110,
        "district_code": 430210,
        "amphoe_code": 4302,
        "province_code": 43
    },
    {
        "district": "โคกคอน",
        "amphoe": "ท่าบ่อ",
        "province": "หนองคาย",
        "zipcode": 43110,
        "district_code": 430204,
        "amphoe_code": 4302,
        "province_code": 43
    },
    {
        "district": "โพนสา",
        "amphoe": "ท่าบ่อ",
        "province": "หนองคาย",
        "zipcode": 43110,
        "district_code": 430209,
        "amphoe_code": 4302,
        "province_code": 43
    },
    {
        "district": "นาทับไฮ",
        "amphoe": "รัตนวาปี",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430514,
        "amphoe_code": 4316,
        "province_code": 43
    },
    {
        "district": "บ้านต้อน",
        "amphoe": "รัตนวาปี",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430520,
        "amphoe_code": 4316,
        "province_code": 43
    },
    {
        "district": "พระบาทนาสิงห์",
        "amphoe": "รัตนวาปี",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430516,
        "amphoe_code": 4316,
        "province_code": 43
    },
    {
        "district": "รัตนวาปี",
        "amphoe": "รัตนวาปี",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430505,
        "amphoe_code": 4316,
        "province_code": 43
    },
    {
        "district": "โพนแพง",
        "amphoe": "รัตนวาปี",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430511,
        "amphoe_code": 4316,
        "province_code": 43
    },
    {
        "district": "บ้านหม้อ",
        "amphoe": "ศรีเชียงใหม่",
        "province": "หนองคาย",
        "zipcode": 43130,
        "district_code": 430703,
        "amphoe_code": 4307,
        "province_code": 43
    },
    {
        "district": "พระพุทธบาท",
        "amphoe": "ศรีเชียงใหม่",
        "province": "หนองคาย",
        "zipcode": 43130,
        "district_code": 430704,
        "amphoe_code": 4307,
        "province_code": 43
    },
    {
        "district": "พานพร้าว",
        "amphoe": "ศรีเชียงใหม่",
        "province": "หนองคาย",
        "zipcode": 43130,
        "district_code": 430701,
        "amphoe_code": 4307,
        "province_code": 43
    },
    {
        "district": "หนองปลาปาก",
        "amphoe": "ศรีเชียงใหม่",
        "province": "หนองคาย",
        "zipcode": 43130,
        "district_code": 430705,
        "amphoe_code": 4307,
        "province_code": 43
    },
    {
        "district": "คอกช้าง",
        "amphoe": "สระใคร",
        "province": "หนองคาย",
        "zipcode": 43100,
        "district_code": 430115,
        "amphoe_code": 4314,
        "province_code": 43
    },
    {
        "district": "บ้านฝาง",
        "amphoe": "สระใคร",
        "province": "หนองคาย",
        "zipcode": 43100,
        "district_code": 430114,
        "amphoe_code": 4314,
        "province_code": 43
    },
    {
        "district": "สระใคร",
        "amphoe": "สระใคร",
        "province": "หนองคาย",
        "zipcode": 43100,
        "district_code": 430112,
        "amphoe_code": 4314,
        "province_code": 43
    },
    {
        "district": "นางิ้ว",
        "amphoe": "สังคม",
        "province": "หนองคาย",
        "zipcode": 43160,
        "district_code": 430804,
        "amphoe_code": 4308,
        "province_code": 43
    },
    {
        "district": "บ้านม่วง",
        "amphoe": "สังคม",
        "province": "หนองคาย",
        "zipcode": 43160,
        "district_code": 430803,
        "amphoe_code": 4308,
        "province_code": 43
    },
    {
        "district": "ผาตั้ง",
        "amphoe": "สังคม",
        "province": "หนองคาย",
        "zipcode": 43160,
        "district_code": 430802,
        "amphoe_code": 4308,
        "province_code": 43
    },
    {
        "district": "สังคม",
        "amphoe": "สังคม",
        "province": "หนองคาย",
        "zipcode": 43160,
        "district_code": 430805,
        "amphoe_code": 4308,
        "province_code": 43
    },
    {
        "district": "แก้งไก่",
        "amphoe": "สังคม",
        "province": "หนองคาย",
        "zipcode": 43160,
        "district_code": 430801,
        "amphoe_code": 4308,
        "province_code": 43
    },
    {
        "district": "นาดี",
        "amphoe": "เฝ้าไร่",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430518,
        "amphoe_code": 4315,
        "province_code": 43
    },
    {
        "district": "วังหลวง",
        "amphoe": "เฝ้าไร่",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430515,
        "amphoe_code": 4315,
        "province_code": 43
    },
    {
        "district": "หนองหลวง",
        "amphoe": "เฝ้าไร่",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430510,
        "amphoe_code": 4315,
        "province_code": 43
    },
    {
        "district": "อุดมพร",
        "amphoe": "เฝ้าไร่",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430517,
        "amphoe_code": 4315,
        "province_code": 43
    },
    {
        "district": "เฝ้าไร่",
        "amphoe": "เฝ้าไร่",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430512,
        "amphoe_code": 4315,
        "province_code": 43
    },
    {
        "district": "กวนวัน",
        "amphoe": "เมืองหนองคาย",
        "province": "หนองคาย",
        "zipcode": 43000,
        "district_code": 430104,
        "amphoe_code": 4301,
        "province_code": 43
    },
    {
        "district": "ค่ายบกหวาน",
        "amphoe": "เมืองหนองคาย",
        "province": "หนองคาย",
        "zipcode": 43100,
        "district_code": 430110,
        "amphoe_code": 4301,
        "province_code": 43
    },
    {
        "district": "บ้านเดื่อ",
        "amphoe": "เมืองหนองคาย",
        "province": "หนองคาย",
        "zipcode": 43000,
        "district_code": 430109,
        "amphoe_code": 4301,
        "province_code": 43
    },
    {
        "district": "ปะโค",
        "amphoe": "เมืองหนองคาย",
        "province": "หนองคาย",
        "zipcode": 43000,
        "district_code": 430117,
        "amphoe_code": 4301,
        "province_code": 43
    },
    {
        "district": "พระธาตุบังพวน",
        "amphoe": "เมืองหนองคาย",
        "province": "หนองคาย",
        "zipcode": 43100,
        "district_code": 430113,
        "amphoe_code": 4301,
        "province_code": 43
    },
    {
        "district": "มีชัย",
        "amphoe": "เมืองหนองคาย",
        "province": "หนองคาย",
        "zipcode": 43000,
        "district_code": 430102,
        "amphoe_code": 4301,
        "province_code": 43
    },
    {
        "district": "วัดธาตุ",
        "amphoe": "เมืองหนองคาย",
        "province": "หนองคาย",
        "zipcode": 43000,
        "district_code": 430106,
        "amphoe_code": 4301,
        "province_code": 43
    },
    {
        "district": "สองห้อง",
        "amphoe": "เมืองหนองคาย",
        "province": "หนองคาย",
        "zipcode": 43100,
        "district_code": 430111,
        "amphoe_code": 4301,
        "province_code": 43
    },
    {
        "district": "สีกาย",
        "amphoe": "เมืองหนองคาย",
        "province": "หนองคาย",
        "zipcode": 43000,
        "district_code": 430119,
        "amphoe_code": 4301,
        "province_code": 43
    },
    {
        "district": "หนองกอมเกาะ",
        "amphoe": "เมืองหนองคาย",
        "province": "หนองคาย",
        "zipcode": 43000,
        "district_code": 430116,
        "amphoe_code": 4301,
        "province_code": 43
    },
    {
        "district": "หาดคำ",
        "amphoe": "เมืองหนองคาย",
        "province": "หนองคาย",
        "zipcode": 43000,
        "district_code": 430107,
        "amphoe_code": 4301,
        "province_code": 43
    },
    {
        "district": "หินโงม",
        "amphoe": "เมืองหนองคาย",
        "province": "หนองคาย",
        "zipcode": 43000,
        "district_code": 430108,
        "amphoe_code": 4301,
        "province_code": 43
    },
    {
        "district": "เมืองหมี",
        "amphoe": "เมืองหนองคาย",
        "province": "หนองคาย",
        "zipcode": 43000,
        "district_code": 430118,
        "amphoe_code": 4301,
        "province_code": 43
    },
    {
        "district": "เวียงคุก",
        "amphoe": "เมืองหนองคาย",
        "province": "หนองคาย",
        "zipcode": 43000,
        "district_code": 430105,
        "amphoe_code": 4301,
        "province_code": 43
    },
    {
        "district": "โพธิ์ชัย",
        "amphoe": "เมืองหนองคาย",
        "province": "หนองคาย",
        "zipcode": 43000,
        "district_code": 430103,
        "amphoe_code": 4301,
        "province_code": 43
    },
    {
        "district": "ในเมือง",
        "amphoe": "เมืองหนองคาย",
        "province": "หนองคาย",
        "zipcode": 43000,
        "district_code": 430101,
        "amphoe_code": 4301,
        "province_code": 43
    },
    {
        "district": "ด่านศรีสุข",
        "amphoe": "โพธิ์ตาก",
        "province": "หนองคาย",
        "zipcode": 43130,
        "district_code": 430707,
        "amphoe_code": 4317,
        "province_code": 43
    },
    {
        "district": "โพธิ์ตาก",
        "amphoe": "โพธิ์ตาก",
        "province": "หนองคาย",
        "zipcode": 43130,
        "district_code": 430702,
        "amphoe_code": 4317,
        "province_code": 43
    },
    {
        "district": "โพนทอง",
        "amphoe": "โพธิ์ตาก",
        "province": "หนองคาย",
        "zipcode": 43130,
        "district_code": 430706,
        "amphoe_code": 4317,
        "province_code": 43
    },
    {
        "district": "กุดบง",
        "amphoe": "โพนพิสัย",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430503,
        "amphoe_code": 4305,
        "province_code": 43
    },
    {
        "district": "จุมพล",
        "amphoe": "โพนพิสัย",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430501,
        "amphoe_code": 4305,
        "province_code": 43
    },
    {
        "district": "ชุมช้าง",
        "amphoe": "โพนพิสัย",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430504,
        "amphoe_code": 4305,
        "province_code": 43
    },
    {
        "district": "ทุ่งหลวง",
        "amphoe": "โพนพิสัย",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430506,
        "amphoe_code": 4305,
        "province_code": 43
    },
    {
        "district": "นาหนัง",
        "amphoe": "โพนพิสัย",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430508,
        "amphoe_code": 4305,
        "province_code": 43
    },
    {
        "district": "บ้านผือ",
        "amphoe": "โพนพิสัย",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430521,
        "amphoe_code": 4305,
        "province_code": 43
    },
    {
        "district": "บ้านโพธิ์",
        "amphoe": "โพนพิสัย",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430513,
        "amphoe_code": 4305,
        "province_code": 43
    },
    {
        "district": "วัดหลวง",
        "amphoe": "โพนพิสัย",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430502,
        "amphoe_code": 4305,
        "province_code": 43
    },
    {
        "district": "สร้างนางขาว",
        "amphoe": "โพนพิสัย",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430522,
        "amphoe_code": 4305,
        "province_code": 43
    },
    {
        "district": "เซิม",
        "amphoe": "โพนพิสัย",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430509,
        "amphoe_code": 4305,
        "province_code": 43
    },
    {
        "district": "เหล่าต่างคำ",
        "amphoe": "โพนพิสัย",
        "province": "หนองคาย",
        "zipcode": 43120,
        "district_code": 430507,
        "amphoe_code": 4305,
        "province_code": 43
    },
    {
        "district": "กุดดินจี่",
        "amphoe": "นากลาง",
        "province": "หนองบัวลำภู",
        "zipcode": 39350,
        "district_code": 390205,
        "amphoe_code": 3902,
        "province_code": 39
    },
    {
        "district": "กุดแห่",
        "amphoe": "นากลาง",
        "province": "หนองบัวลำภู",
        "zipcode": 39170,
        "district_code": 390213,
        "amphoe_code": 3902,
        "province_code": 39
    },
    {
        "district": "ดงสวรรค์",
        "amphoe": "นากลาง",
        "province": "หนองบัวลำภู",
        "zipcode": 39350,
        "district_code": 390211,
        "amphoe_code": 3902,
        "province_code": 39
    },
    {
        "district": "ด่านช้าง",
        "amphoe": "นากลาง",
        "province": "หนองบัวลำภู",
        "zipcode": 39170,
        "district_code": 390202,
        "amphoe_code": 3902,
        "province_code": 39
    },
    {
        "district": "นากลาง",
        "amphoe": "นากลาง",
        "province": "หนองบัวลำภู",
        "zipcode": 39170,
        "district_code": 390201,
        "amphoe_code": 3902,
        "province_code": 39
    },
    {
        "district": "ฝั่งแดง",
        "amphoe": "นากลาง",
        "province": "หนองบัวลำภู",
        "zipcode": 39170,
        "district_code": 390206,
        "amphoe_code": 3902,
        "province_code": 39
    },
    {
        "district": "อุทัยสวรรค์",
        "amphoe": "นากลาง",
        "province": "หนองบัวลำภู",
        "zipcode": 39170,
        "district_code": 390210,
        "amphoe_code": 3902,
        "province_code": 39
    },
    {
        "district": "เก่ากลอย",
        "amphoe": "นากลาง",
        "province": "หนองบัวลำภู",
        "zipcode": 39350,
        "district_code": 390207,
        "amphoe_code": 3902,
        "province_code": 39
    },
    {
        "district": "โนนเมือง",
        "amphoe": "นากลาง",
        "province": "หนองบัวลำภู",
        "zipcode": 39170,
        "district_code": 390209,
        "amphoe_code": 3902,
        "province_code": 39
    },
    {
        "district": "นาเหล่า",
        "amphoe": "นาวัง",
        "province": "หนองบัวลำภู",
        "zipcode": 39170,
        "district_code": 390203,
        "amphoe_code": 3906,
        "province_code": 39
    },
    {
        "district": "นาแก",
        "amphoe": "นาวัง",
        "province": "หนองบัวลำภู",
        "zipcode": 39170,
        "district_code": 390204,
        "amphoe_code": 3906,
        "province_code": 39
    },
    {
        "district": "วังทอง",
        "amphoe": "นาวัง",
        "province": "หนองบัวลำภู",
        "zipcode": 39170,
        "district_code": 390208,
        "amphoe_code": 3906,
        "province_code": 39
    },
    {
        "district": "วังปลาป้อม",
        "amphoe": "นาวัง",
        "province": "หนองบัวลำภู",
        "zipcode": 39170,
        "district_code": 390212,
        "amphoe_code": 3906,
        "province_code": 39
    },
    {
        "district": "เทพคีรี",
        "amphoe": "นาวัง",
        "province": "หนองบัวลำภู",
        "zipcode": 39170,
        "district_code": 390214,
        "amphoe_code": 3906,
        "province_code": 39
    },
    {
        "district": "กุดสะเทียน",
        "amphoe": "ศรีบุญเรือง",
        "province": "หนองบัวลำภู",
        "zipcode": 39180,
        "district_code": 390404,
        "amphoe_code": 3904,
        "province_code": 39
    },
    {
        "district": "ทรายทอง",
        "amphoe": "ศรีบุญเรือง",
        "province": "หนองบัวลำภู",
        "zipcode": 39180,
        "district_code": 390411,
        "amphoe_code": 3904,
        "province_code": 39
    },
    {
        "district": "นากอก",
        "amphoe": "ศรีบุญเรือง",
        "province": "หนองบัวลำภู",
        "zipcode": 39180,
        "district_code": 390405,
        "amphoe_code": 3904,
        "province_code": 39
    },
    {
        "district": "ยางหล่อ",
        "amphoe": "ศรีบุญเรือง",
        "province": "หนองบัวลำภู",
        "zipcode": 39180,
        "district_code": 390407,
        "amphoe_code": 3904,
        "province_code": 39
    },
    {
        "district": "ศรีบุญเรือง",
        "amphoe": "ศรีบุญเรือง",
        "province": "หนองบัวลำภู",
        "zipcode": 39180,
        "district_code": 390402,
        "amphoe_code": 3904,
        "province_code": 39
    },
    {
        "district": "หนองกุงแก้ว",
        "amphoe": "ศรีบุญเรือง",
        "province": "หนองบัวลำภู",
        "zipcode": 39180,
        "district_code": 390409,
        "amphoe_code": 3904,
        "province_code": 39
    },
    {
        "district": "หนองบัวใต้",
        "amphoe": "ศรีบุญเรือง",
        "province": "หนองบัวลำภู",
        "zipcode": 39180,
        "district_code": 390403,
        "amphoe_code": 3904,
        "province_code": 39
    },
    {
        "district": "หนองแก",
        "amphoe": "ศรีบุญเรือง",
        "province": "หนองบัวลำภู",
        "zipcode": 39180,
        "district_code": 390410,
        "amphoe_code": 3904,
        "province_code": 39
    },
    {
        "district": "หันนางาม",
        "amphoe": "ศรีบุญเรือง",
        "province": "หนองบัวลำภู",
        "zipcode": 39180,
        "district_code": 390412,
        "amphoe_code": 3904,
        "province_code": 39
    },
    {
        "district": "เมืองใหม่",
        "amphoe": "ศรีบุญเรือง",
        "province": "หนองบัวลำภู",
        "zipcode": 39180,
        "district_code": 390401,
        "amphoe_code": 3904,
        "province_code": 39
    },
    {
        "district": "โนนม่วง",
        "amphoe": "ศรีบุญเรือง",
        "province": "หนองบัวลำภู",
        "zipcode": 39180,
        "district_code": 390408,
        "amphoe_code": 3904,
        "province_code": 39
    },
    {
        "district": "โนนสะอาด",
        "amphoe": "ศรีบุญเรือง",
        "province": "หนองบัวลำภู",
        "zipcode": 39180,
        "district_code": 390406,
        "amphoe_code": 3904,
        "province_code": 39
    },
    {
        "district": "กุดผึ้ง",
        "amphoe": "สุวรรณคูหา",
        "province": "หนองบัวลำภู",
        "zipcode": 39270,
        "district_code": 390508,
        "amphoe_code": 3905,
        "province_code": 39
    },
    {
        "district": "ดงมะไฟ",
        "amphoe": "สุวรรณคูหา",
        "province": "หนองบัวลำภู",
        "zipcode": 39270,
        "district_code": 390505,
        "amphoe_code": 3905,
        "province_code": 39
    },
    {
        "district": "นาดี",
        "amphoe": "สุวรรณคูหา",
        "province": "หนองบัวลำภู",
        "zipcode": 39270,
        "district_code": 390296,
        "amphoe_code": 3905,
        "province_code": 39
    },
    {
        "district": "นาด่าน",
        "amphoe": "สุวรรณคูหา",
        "province": "หนองบัวลำภู",
        "zipcode": 39270,
        "district_code": 390504,
        "amphoe_code": 3905,
        "province_code": 39
    },
    {
        "district": "นาสี",
        "amphoe": "สุวรรณคูหา",
        "province": "หนองบัวลำภู",
        "zipcode": 39270,
        "district_code": 390297,
        "amphoe_code": 3905,
        "province_code": 39
    },
    {
        "district": "บุญทัน",
        "amphoe": "สุวรรณคูหา",
        "province": "หนองบัวลำภู",
        "zipcode": 39270,
        "district_code": 390507,
        "amphoe_code": 3905,
        "province_code": 39
    },
    {
        "district": "บ้านโคก",
        "amphoe": "สุวรรณคูหา",
        "province": "หนองบัวลำภู",
        "zipcode": 39270,
        "district_code": 390298,
        "amphoe_code": 3905,
        "province_code": 39
    },
    {
        "district": "สุวรรณคูหา",
        "amphoe": "สุวรรณคูหา",
        "province": "หนองบัวลำภู",
        "zipcode": 39270,
        "district_code": 390506,
        "amphoe_code": 3905,
        "province_code": 39
    },
    {
        "district": "กุดจิก",
        "amphoe": "เมืองหนองบัวลำภู",
        "province": "หนองบัวลำภู",
        "zipcode": 39000,
        "district_code": 390111,
        "amphoe_code": 3901,
        "province_code": 39
    },
    {
        "district": "นาคำไฮ",
        "amphoe": "เมืองหนองบัวลำภู",
        "province": "หนองบัวลำภู",
        "zipcode": 39000,
        "district_code": 390113,
        "amphoe_code": 3901,
        "province_code": 39
    },
    {
        "district": "นามะเฟือง",
        "amphoe": "เมืองหนองบัวลำภู",
        "province": "หนองบัวลำภู",
        "zipcode": 39000,
        "district_code": 390107,
        "amphoe_code": 3901,
        "province_code": 39
    },
    {
        "district": "บ้านขาม",
        "amphoe": "เมืองหนองบัวลำภู",
        "province": "หนองบัวลำภู",
        "zipcode": 39000,
        "district_code": 390106,
        "amphoe_code": 3901,
        "province_code": 39
    },
    {
        "district": "บ้านพร้าว",
        "amphoe": "เมืองหนองบัวลำภู",
        "province": "หนองบัวลำภู",
        "zipcode": 39000,
        "district_code": 390108,
        "amphoe_code": 3901,
        "province_code": 39
    },
    {
        "district": "ป่าไม้งาม",
        "amphoe": "เมืองหนองบัวลำภู",
        "province": "หนองบัวลำภู",
        "zipcode": 39000,
        "district_code": 390114,
        "amphoe_code": 3901,
        "province_code": 39
    },
    {
        "district": "ลำภู",
        "amphoe": "เมืองหนองบัวลำภู",
        "province": "หนองบัวลำภู",
        "zipcode": 39000,
        "district_code": 390110,
        "amphoe_code": 3901,
        "province_code": 39
    },
    {
        "district": "หนองบัว",
        "amphoe": "เมืองหนองบัวลำภู",
        "province": "หนองบัวลำภู",
        "zipcode": 39000,
        "district_code": 390101,
        "amphoe_code": 3901,
        "province_code": 39
    },
    {
        "district": "หนองภัยศูนย์",
        "amphoe": "เมืองหนองบัวลำภู",
        "province": "หนองบัวลำภู",
        "zipcode": 39000,
        "district_code": 390102,
        "amphoe_code": 3901,
        "province_code": 39
    },
    {
        "district": "หนองสวรรค์",
        "amphoe": "เมืองหนองบัวลำภู",
        "province": "หนองบัวลำภู",
        "zipcode": 39000,
        "district_code": 390104,
        "amphoe_code": 3901,
        "province_code": 39
    },
    {
        "district": "หนองหว้า",
        "amphoe": "เมืองหนองบัวลำภู",
        "province": "หนองบัวลำภู",
        "zipcode": 39000,
        "district_code": 390115,
        "amphoe_code": 3901,
        "province_code": 39
    },
    {
        "district": "หัวนา",
        "amphoe": "เมืองหนองบัวลำภู",
        "province": "หนองบัวลำภู",
        "zipcode": 39000,
        "district_code": 390105,
        "amphoe_code": 3901,
        "province_code": 39
    },
    {
        "district": "โนนขมิ้น",
        "amphoe": "เมืองหนองบัวลำภู",
        "province": "หนองบัวลำภู",
        "zipcode": 39000,
        "district_code": 390109,
        "amphoe_code": 3901,
        "province_code": 39
    },
    {
        "district": "โนนทัน",
        "amphoe": "เมืองหนองบัวลำภู",
        "province": "หนองบัวลำภู",
        "zipcode": 39000,
        "district_code": 390112,
        "amphoe_code": 3901,
        "province_code": 39
    },
    {
        "district": "โพธิ์ชัย",
        "amphoe": "เมืองหนองบัวลำภู",
        "province": "หนองบัวลำภู",
        "zipcode": 39000,
        "district_code": 390103,
        "amphoe_code": 3901,
        "province_code": 39
    },
    {
        "district": "กุดดู่",
        "amphoe": "โนนสัง",
        "province": "หนองบัวลำภู",
        "zipcode": 39140,
        "district_code": 390304,
        "amphoe_code": 3903,
        "province_code": 39
    },
    {
        "district": "นิคมพัฒนา",
        "amphoe": "โนนสัง",
        "province": "หนองบัวลำภู",
        "zipcode": 39140,
        "district_code": 390309,
        "amphoe_code": 3903,
        "province_code": 39
    },
    {
        "district": "บ้านค้อ",
        "amphoe": "โนนสัง",
        "province": "หนองบัวลำภู",
        "zipcode": 39140,
        "district_code": 390305,
        "amphoe_code": 3903,
        "province_code": 39
    },
    {
        "district": "บ้านถิ่น",
        "amphoe": "โนนสัง",
        "province": "หนองบัวลำภู",
        "zipcode": 39140,
        "district_code": 390302,
        "amphoe_code": 3903,
        "province_code": 39
    },
    {
        "district": "ปางกู่",
        "amphoe": "โนนสัง",
        "province": "หนองบัวลำภู",
        "zipcode": 39140,
        "district_code": 390310,
        "amphoe_code": 3903,
        "province_code": 39
    },
    {
        "district": "หนองเรือ",
        "amphoe": "โนนสัง",
        "province": "หนองบัวลำภู",
        "zipcode": 39140,
        "district_code": 390303,
        "amphoe_code": 3903,
        "province_code": 39
    },
    {
        "district": "โคกม่วง",
        "amphoe": "โนนสัง",
        "province": "หนองบัวลำภู",
        "zipcode": 39140,
        "district_code": 390308,
        "amphoe_code": 3903,
        "province_code": 39
    },
    {
        "district": "โคกใหญ่",
        "amphoe": "โนนสัง",
        "province": "หนองบัวลำภู",
        "zipcode": 39140,
        "district_code": 390307,
        "amphoe_code": 3903,
        "province_code": 39
    },
    {
        "district": "โนนสัง",
        "amphoe": "โนนสัง",
        "province": "หนองบัวลำภู",
        "zipcode": 39140,
        "district_code": 390301,
        "amphoe_code": 3903,
        "province_code": 39
    },
    {
        "district": "โนนเมือง",
        "amphoe": "โนนสัง",
        "province": "หนองบัวลำภู",
        "zipcode": 39140,
        "district_code": 390209,
        "amphoe_code": 3903,
        "province_code": 39
    },
    {
        "district": "คำเขื่อนแก้ว",
        "amphoe": "ชานุมาน",
        "province": "อำนาจเจริญ",
        "zipcode": 37210,
        "district_code": 370203,
        "amphoe_code": 3702,
        "province_code": 37
    },
    {
        "district": "ชานุมาน",
        "amphoe": "ชานุมาน",
        "province": "อำนาจเจริญ",
        "zipcode": 37210,
        "district_code": 370201,
        "amphoe_code": 3702,
        "province_code": 37
    },
    {
        "district": "ป่าก่อ",
        "amphoe": "ชานุมาน",
        "province": "อำนาจเจริญ",
        "zipcode": 37210,
        "district_code": 370205,
        "amphoe_code": 3702,
        "province_code": 37
    },
    {
        "district": "โคกก่ง",
        "amphoe": "ชานุมาน",
        "province": "อำนาจเจริญ",
        "zipcode": 37210,
        "district_code": 370204,
        "amphoe_code": 3702,
        "province_code": 37
    },
    {
        "district": "โคกสาร",
        "amphoe": "ชานุมาน",
        "province": "อำนาจเจริญ",
        "zipcode": 37210,
        "district_code": 370202,
        "amphoe_code": 3702,
        "province_code": 37
    },
    {
        "district": "คำโพน",
        "amphoe": "ปทุมราชวงศา",
        "province": "อำนาจเจริญ",
        "zipcode": 37110,
        "district_code": 370302,
        "amphoe_code": 3703,
        "province_code": 37
    },
    {
        "district": "นาป่าแซง",
        "amphoe": "ปทุมราชวงศา",
        "province": "อำนาจเจริญ",
        "zipcode": 37110,
        "district_code": 370307,
        "amphoe_code": 3703,
        "province_code": 37
    },
    {
        "district": "นาหว้า",
        "amphoe": "ปทุมราชวงศา",
        "province": "อำนาจเจริญ",
        "zipcode": 37110,
        "district_code": 370303,
        "amphoe_code": 3703,
        "province_code": 37
    },
    {
        "district": "ลือ",
        "amphoe": "ปทุมราชวงศา",
        "province": "อำนาจเจริญ",
        "zipcode": 37110,
        "district_code": 370304,
        "amphoe_code": 3703,
        "province_code": 37
    },
    {
        "district": "หนองข่า",
        "amphoe": "ปทุมราชวงศา",
        "province": "อำนาจเจริญ",
        "zipcode": 37110,
        "district_code": 370299,
        "amphoe_code": 3703,
        "province_code": 37
    },
    {
        "district": "ห้วย",
        "amphoe": "ปทุมราชวงศา",
        "province": "อำนาจเจริญ",
        "zipcode": 37110,
        "district_code": 370305,
        "amphoe_code": 3703,
        "province_code": 37
    },
    {
        "district": "โนนงาม",
        "amphoe": "ปทุมราชวงศา",
        "province": "อำนาจเจริญ",
        "zipcode": 37110,
        "district_code": 370306,
        "amphoe_code": 3703,
        "province_code": 37
    },
    {
        "district": "จานลาน",
        "amphoe": "พนา",
        "province": "อำนาจเจริญ",
        "zipcode": 37180,
        "district_code": 370402,
        "amphoe_code": 3704,
        "province_code": 37
    },
    {
        "district": "พนา",
        "amphoe": "พนา",
        "province": "อำนาจเจริญ",
        "zipcode": 37180,
        "district_code": 370401,
        "amphoe_code": 3704,
        "province_code": 37
    },
    {
        "district": "พระเหลา",
        "amphoe": "พนา",
        "province": "อำนาจเจริญ",
        "zipcode": 37180,
        "district_code": 370404,
        "amphoe_code": 3704,
        "province_code": 37
    },
    {
        "district": "ไม้กลอน",
        "amphoe": "พนา",
        "province": "อำนาจเจริญ",
        "zipcode": 37180,
        "district_code": 370403,
        "amphoe_code": 3704,
        "province_code": 37
    },
    {
        "district": "ดงบัง",
        "amphoe": "ลืออำนาจ",
        "province": "อำนาจเจริญ",
        "zipcode": 37120,
        "district_code": 370199,
        "amphoe_code": 3707,
        "province_code": 37
    },
    {
        "district": "ดงมะยาง",
        "amphoe": "ลืออำนาจ",
        "province": "อำนาจเจริญ",
        "zipcode": 37120,
        "district_code": 370191,
        "amphoe_code": 3707,
        "province_code": 37
    },
    {
        "district": "อำนาจ",
        "amphoe": "ลืออำนาจ",
        "province": "อำนาจเจริญ",
        "zipcode": 37120,
        "district_code": 370198,
        "amphoe_code": 3707,
        "province_code": 37
    },
    {
        "district": "เปือย",
        "amphoe": "ลืออำนาจ",
        "province": "อำนาจเจริญ",
        "zipcode": 37120,
        "district_code": 370192,
        "amphoe_code": 3707,
        "province_code": 37
    },
    {
        "district": "แมด",
        "amphoe": "ลืออำนาจ",
        "province": "อำนาจเจริญ",
        "zipcode": 37120,
        "district_code": 370706,
        "amphoe_code": 3707,
        "province_code": 37
    },
    {
        "district": "โคกกลาง",
        "amphoe": "ลืออำนาจ",
        "province": "อำนาจเจริญ",
        "zipcode": 37120,
        "district_code": 370707,
        "amphoe_code": 3707,
        "province_code": 37
    },
    {
        "district": "ไร่ขี",
        "amphoe": "ลืออำนาจ",
        "province": "อำนาจเจริญ",
        "zipcode": 37120,
        "district_code": 370195,
        "amphoe_code": 3707,
        "province_code": 37
    },
    {
        "district": "คำพระ",
        "amphoe": "หัวตะพาน",
        "province": "อำนาจเจริญ",
        "zipcode": 37240,
        "district_code": 370602,
        "amphoe_code": 3706,
        "province_code": 37
    },
    {
        "district": "จิกดู่",
        "amphoe": "หัวตะพาน",
        "province": "อำนาจเจริญ",
        "zipcode": 37240,
        "district_code": 370607,
        "amphoe_code": 3706,
        "province_code": 37
    },
    {
        "district": "รัตนวารี",
        "amphoe": "หัวตะพาน",
        "province": "อำนาจเจริญ",
        "zipcode": 37240,
        "district_code": 370608,
        "amphoe_code": 3706,
        "province_code": 37
    },
    {
        "district": "สร้างถ่อน้อย",
        "amphoe": "หัวตะพาน",
        "province": "อำนาจเจริญ",
        "zipcode": 37240,
        "district_code": 370606,
        "amphoe_code": 3706,
        "province_code": 37
    },
    {
        "district": "หนองแก้ว",
        "amphoe": "หัวตะพาน",
        "province": "อำนาจเจริญ",
        "zipcode": 37240,
        "district_code": 370604,
        "amphoe_code": 3706,
        "province_code": 37
    },
    {
        "district": "หัวตะพาน",
        "amphoe": "หัวตะพาน",
        "province": "อำนาจเจริญ",
        "zipcode": 37240,
        "district_code": 370601,
        "amphoe_code": 3706,
        "province_code": 37
    },
    {
        "district": "เค็งใหญ่",
        "amphoe": "หัวตะพาน",
        "province": "อำนาจเจริญ",
        "zipcode": 37240,
        "district_code": 370603,
        "amphoe_code": 3706,
        "province_code": 37
    },
    {
        "district": "โพนเมืองน้อย",
        "amphoe": "หัวตะพาน",
        "province": "อำนาจเจริญ",
        "zipcode": 37240,
        "district_code": 370605,
        "amphoe_code": 3706,
        "province_code": 37
    },
    {
        "district": "กุดปลาดุก",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370116,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "คึมใหญ่",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370107,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "ดอนเมย",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370117,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "นาจิก",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370103,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "นาผือ",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370108,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "นายม",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370118,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "นาวัง",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370110,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "นาหมอม้า",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370111,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "นาแต้",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370119,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "น้ำปลีก",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370109,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "บุ่ง",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370101,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "ปลาค้าว",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370104,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "สร้างนกทา",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370106,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "หนองมะแซว",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370115,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "ห้วยไร่",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370114,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "เหล่าพรวน",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370105,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "โนนหนามแท่ง",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370113,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "โนนโพธิ์",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370112,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "ไก่คำ",
        "amphoe": "เมืองอำนาจเจริญ",
        "province": "อำนาจเจริญ",
        "zipcode": 37000,
        "district_code": 370102,
        "amphoe_code": 3701,
        "province_code": 37
    },
    {
        "district": "นาเวียง",
        "amphoe": "เสนางคนิคม",
        "province": "อำนาจเจริญ",
        "zipcode": 37290,
        "district_code": 370194,
        "amphoe_code": 3705,
        "province_code": 37
    },
    {
        "district": "หนองสามสี",
        "amphoe": "เสนางคนิคม",
        "province": "อำนาจเจริญ",
        "zipcode": 37290,
        "district_code": 370506,
        "amphoe_code": 3705,
        "province_code": 37
    },
    {
        "district": "หนองไฮ",
        "amphoe": "เสนางคนิคม",
        "province": "อำนาจเจริญ",
        "zipcode": 37290,
        "district_code": 370193,
        "amphoe_code": 3705,
        "province_code": 37
    },
    {
        "district": "เสนางคนิคม",
        "amphoe": "เสนางคนิคม",
        "province": "อำนาจเจริญ",
        "zipcode": 37290,
        "district_code": 370197,
        "amphoe_code": 3705,
        "province_code": 37
    },
    {
        "district": "โพนทอง",
        "amphoe": "เสนางคนิคม",
        "province": "อำนาจเจริญ",
        "zipcode": 37290,
        "district_code": 370190,
        "amphoe_code": 3705,
        "province_code": 37
    },
    {
        "district": "ไร่สีสุก",
        "amphoe": "เสนางคนิคม",
        "province": "อำนาจเจริญ",
        "zipcode": 37290,
        "district_code": 370196,
        "amphoe_code": 3705,
        "province_code": 37
    },
    {
        "district": "กุดจับ",
        "amphoe": "กุดจับ",
        "province": "อุดรธานี",
        "zipcode": 41250,
        "district_code": 410198,
        "amphoe_code": 4102,
        "province_code": 41
    },
    {
        "district": "ขอนยูง",
        "amphoe": "กุดจับ",
        "province": "อุดรธานี",
        "zipcode": 41250,
        "district_code": 410190,
        "amphoe_code": 4102,
        "province_code": 41
    },
    {
        "district": "ตาลเลียน",
        "amphoe": "กุดจับ",
        "province": "อุดรธานี",
        "zipcode": 41250,
        "district_code": 410207,
        "amphoe_code": 4102,
        "province_code": 41
    },
    {
        "district": "ปะโค",
        "amphoe": "กุดจับ",
        "province": "อุดรธานี",
        "zipcode": 41250,
        "district_code": 410191,
        "amphoe_code": 4102,
        "province_code": 41
    },
    {
        "district": "สร้างก่อ",
        "amphoe": "กุดจับ",
        "province": "อุดรธานี",
        "zipcode": 41250,
        "district_code": 410205,
        "amphoe_code": 4102,
        "province_code": 41
    },
    {
        "district": "เชียงเพ็ง",
        "amphoe": "กุดจับ",
        "province": "อุดรธานี",
        "zipcode": 41250,
        "district_code": 410197,
        "amphoe_code": 4102,
        "province_code": 41
    },
    {
        "district": "เมืองเพีย",
        "amphoe": "กุดจับ",
        "province": "อุดรธานี",
        "zipcode": 41250,
        "district_code": 410206,
        "amphoe_code": 4102,
        "province_code": 41
    },
    {
        "district": "กุมภวาปี",
        "amphoe": "กุมภวาปี",
        "province": "อุดรธานี",
        "zipcode": 41110,
        "district_code": 410415,
        "amphoe_code": 4104,
        "province_code": 41
    },
    {
        "district": "ตูมใต้",
        "amphoe": "กุมภวาปี",
        "province": "อุดรธานี",
        "zipcode": 41110,
        "district_code": 410401,
        "amphoe_code": 4104,
        "province_code": 41
    },
    {
        "district": "ท่าลี่",
        "amphoe": "กุมภวาปี",
        "province": "อุดรธานี",
        "zipcode": 41110,
        "district_code": 410414,
        "amphoe_code": 4104,
        "province_code": 41
    },
    {
        "district": "ปะโค",
        "amphoe": "กุมภวาปี",
        "province": "อุดรธานี",
        "zipcode": 41370,
        "district_code": 410191,
        "amphoe_code": 4104,
        "province_code": 41
    },
    {
        "district": "ผาสุก",
        "amphoe": "กุมภวาปี",
        "province": "อุดรธานี",
        "zipcode": 41370,
        "district_code": 410413,
        "amphoe_code": 4104,
        "province_code": 41
    },
    {
        "district": "พันดอน",
        "amphoe": "กุมภวาปี",
        "province": "อุดรธานี",
        "zipcode": 41370,
        "district_code": 410402,
        "amphoe_code": 4104,
        "province_code": 41
    },
    {
        "district": "สีออ",
        "amphoe": "กุมภวาปี",
        "province": "อุดรธานี",
        "zipcode": 41110,
        "district_code": 410410,
        "amphoe_code": 4104,
        "province_code": 41
    },
    {
        "district": "หนองหว้า",
        "amphoe": "กุมภวาปี",
        "province": "อุดรธานี",
        "zipcode": 41110,
        "district_code": 410194,
        "amphoe_code": 4104,
        "province_code": 41
    },
    {
        "district": "ห้วยเกิ้ง",
        "amphoe": "กุมภวาปี",
        "province": "อุดรธานี",
        "zipcode": 41110,
        "district_code": 410407,
        "amphoe_code": 4104,
        "province_code": 41
    },
    {
        "district": "เชียงแหว",
        "amphoe": "กุมภวาปี",
        "province": "อุดรธานี",
        "zipcode": 41110,
        "district_code": 410406,
        "amphoe_code": 4104,
        "province_code": 41
    },
    {
        "district": "เวียงคำ",
        "amphoe": "กุมภวาปี",
        "province": "อุดรธานี",
        "zipcode": 41110,
        "district_code": 410403,
        "amphoe_code": 4104,
        "province_code": 41
    },
    {
        "district": "เสอเพลอ",
        "amphoe": "กุมภวาปี",
        "province": "อุดรธานี",
        "zipcode": 41370,
        "district_code": 410409,
        "amphoe_code": 4104,
        "province_code": 41
    },
    {
        "district": "แชแล",
        "amphoe": "กุมภวาปี",
        "province": "อุดรธานี",
        "zipcode": 41110,
        "district_code": 410404,
        "amphoe_code": 4104,
        "province_code": 41
    },
    {
        "district": "คอนสาย",
        "amphoe": "กู่แก้ว",
        "province": "อุดรธานี",
        "zipcode": 41130,
        "district_code": 410603,
        "amphoe_code": 4124,
        "province_code": 41
    },
    {
        "district": "ค้อใหญ่",
        "amphoe": "กู่แก้ว",
        "province": "อุดรธานี",
        "zipcode": 41130,
        "district_code": 410616,
        "amphoe_code": 4124,
        "province_code": 41
    },
    {
        "district": "บ้านจีต",
        "amphoe": "กู่แก้ว",
        "province": "อุดรธานี",
        "zipcode": 41130,
        "district_code": 410604,
        "amphoe_code": 4124,
        "province_code": 41
    },
    {
        "district": "โนนทองอินทร์",
        "amphoe": "กู่แก้ว",
        "province": "อุดรธานี",
        "zipcode": 41130,
        "district_code": 410619,
        "amphoe_code": 4124,
        "province_code": 41
    },
    {
        "district": "ทุ่งฝน",
        "amphoe": "ทุ่งฝน",
        "province": "อุดรธานี",
        "zipcode": 41310,
        "district_code": 410697,
        "amphoe_code": 4107,
        "province_code": 41
    },
    {
        "district": "ทุ่งใหญ่",
        "amphoe": "ทุ่งฝน",
        "province": "อุดรธานี",
        "zipcode": 41310,
        "district_code": 410696,
        "amphoe_code": 4107,
        "province_code": 41
    },
    {
        "district": "นาชุมแสง",
        "amphoe": "ทุ่งฝน",
        "province": "อุดรธานี",
        "zipcode": 41310,
        "district_code": 410703,
        "amphoe_code": 4107,
        "province_code": 41
    },
    {
        "district": "นาทม",
        "amphoe": "ทุ่งฝน",
        "province": "อุดรธานี",
        "zipcode": 41310,
        "district_code": 410704,
        "amphoe_code": 4107,
        "province_code": 41
    },
    {
        "district": "นายูง",
        "amphoe": "นายูง",
        "province": "อุดรธานี",
        "zipcode": 41380,
        "district_code": 410906,
        "amphoe_code": 4122,
        "province_code": 41
    },
    {
        "district": "นาแค",
        "amphoe": "นายูง",
        "province": "อุดรธานี",
        "zipcode": 41380,
        "district_code": 411804,
        "amphoe_code": 4122,
        "province_code": 41
    },
    {
        "district": "บ้านก้อง",
        "amphoe": "นายูง",
        "province": "อุดรธานี",
        "zipcode": 41380,
        "district_code": 411809,
        "amphoe_code": 4122,
        "province_code": 41
    },
    {
        "district": "โนนทอง",
        "amphoe": "นายูง",
        "province": "อุดรธานี",
        "zipcode": 41380,
        "district_code": 411705,
        "amphoe_code": 4122,
        "province_code": 41
    },
    {
        "district": "นางัว",
        "amphoe": "น้ำโสม",
        "province": "อุดรธานี",
        "zipcode": 41210,
        "district_code": 411801,
        "amphoe_code": 4118,
        "province_code": 41
    },
    {
        "district": "น้ำโสม",
        "amphoe": "น้ำโสม",
        "province": "อุดรธานี",
        "zipcode": 41210,
        "district_code": 411802,
        "amphoe_code": 4118,
        "province_code": 41
    },
    {
        "district": "บ้านหยวก",
        "amphoe": "น้ำโสม",
        "province": "อุดรธานี",
        "zipcode": 41210,
        "district_code": 411806,
        "amphoe_code": 4118,
        "province_code": 41
    },
    {
        "district": "ศรีสำราญ",
        "amphoe": "น้ำโสม",
        "province": "อุดรธานี",
        "zipcode": 41210,
        "district_code": 411810,
        "amphoe_code": 4118,
        "province_code": 41
    },
    {
        "district": "สามัคคี",
        "amphoe": "น้ำโสม",
        "province": "อุดรธานี",
        "zipcode": 41210,
        "district_code": 411812,
        "amphoe_code": 4118,
        "province_code": 41
    },
    {
        "district": "หนองแวง",
        "amphoe": "น้ำโสม",
        "province": "อุดรธานี",
        "zipcode": 41210,
        "district_code": 411713,
        "amphoe_code": 4118,
        "province_code": 41
    },
    {
        "district": "โสมเยี่ยม",
        "amphoe": "น้ำโสม",
        "province": "อุดรธานี",
        "zipcode": 41210,
        "district_code": 411807,
        "amphoe_code": 4118,
        "province_code": 41
    },
    {
        "district": "ดงเย็น",
        "amphoe": "บ้านดุง",
        "province": "อุดรธานี",
        "zipcode": 41190,
        "district_code": 411103,
        "amphoe_code": 4111,
        "province_code": 41
    },
    {
        "district": "ถ่อนนาลับ",
        "amphoe": "บ้านดุง",
        "province": "อุดรธานี",
        "zipcode": 41190,
        "district_code": 411109,
        "amphoe_code": 4111,
        "province_code": 41
    },
    {
        "district": "นาคำ",
        "amphoe": "บ้านดุง",
        "province": "อุดรธานี",
        "zipcode": 41190,
        "district_code": 411113,
        "amphoe_code": 4111,
        "province_code": 41
    },
    {
        "district": "นาไหม",
        "amphoe": "บ้านดุง",
        "province": "อุดรธานี",
        "zipcode": 41190,
        "district_code": 411108,
        "amphoe_code": 4111,
        "province_code": 41
    },
    {
        "district": "บ้านจันทร์",
        "amphoe": "บ้านดุง",
        "province": "อุดรธานี",
        "zipcode": 41190,
        "district_code": false,
        "amphoe_code": 4111,
        "province_code": 41
    },
    {
        "district": "บ้านชัย",
        "amphoe": "บ้านดุง",
        "province": "อุดรธานี",
        "zipcode": 41190,
        "district_code": 411107,
        "amphoe_code": 4111,
        "province_code": 41
    },
    {
        "district": "บ้านดุง",
        "amphoe": "บ้านดุง",
        "province": "อุดรธานี",
        "zipcode": 41190,
        "district_code": 411102,
        "amphoe_code": 4111,
        "province_code": 41
    },
    {
        "district": "บ้านตาด",
        "amphoe": "บ้านดุง",
        "province": "อุดรธานี",
        "zipcode": 41190,
        "district_code": 410105,
        "amphoe_code": 4111,
        "province_code": 41
    },
    {
        "district": "บ้านม่วง",
        "amphoe": "บ้านดุง",
        "province": "อุดรธานี",
        "zipcode": 41190,
        "district_code": 411111,
        "amphoe_code": 4111,
        "province_code": 41
    },
    {
        "district": "วังทอง",
        "amphoe": "บ้านดุง",
        "province": "อุดรธานี",
        "zipcode": 41190,
        "district_code": 411110,
        "amphoe_code": 4111,
        "province_code": 41
    },
    {
        "district": "ศรีสุทโธ",
        "amphoe": "บ้านดุง",
        "province": "อุดรธานี",
        "zipcode": 41190,
        "district_code": 411101,
        "amphoe_code": 4111,
        "province_code": 41
    },
    {
        "district": "อ้อมกอ",
        "amphoe": "บ้านดุง",
        "province": "อุดรธานี",
        "zipcode": 41190,
        "district_code": 411105,
        "amphoe_code": 4111,
        "province_code": 41
    },
    {
        "district": "โพนสูง",
        "amphoe": "บ้านดุง",
        "province": "อุดรธานี",
        "zipcode": 41190,
        "district_code": 410698,
        "amphoe_code": 4111,
        "province_code": 41
    },
    {
        "district": "กลางใหญ่",
        "amphoe": "บ้านผือ",
        "province": "อุดรธานี",
        "zipcode": 41160,
        "district_code": 411708,
        "amphoe_code": 4117,
        "province_code": 41
    },
    {
        "district": "ข้าวสาร",
        "amphoe": "บ้านผือ",
        "province": "อุดรธานี",
        "zipcode": 41160,
        "district_code": 411706,
        "amphoe_code": 4117,
        "province_code": 41
    },
    {
        "district": "คำด้วง",
        "amphoe": "บ้านผือ",
        "province": "อุดรธานี",
        "zipcode": 41160,
        "district_code": 411710,
        "amphoe_code": 4117,
        "province_code": 41
    },
    {
        "district": "คำบง",
        "amphoe": "บ้านผือ",
        "province": "อุดรธานี",
        "zipcode": 41160,
        "district_code": 411704,
        "amphoe_code": 4117,
        "province_code": 41
    },
    {
        "district": "จำปาโมง",
        "amphoe": "บ้านผือ",
        "province": "อุดรธานี",
        "zipcode": 41160,
        "district_code": 411707,
        "amphoe_code": 4117,
        "province_code": 41
    },
    {
        "district": "บ้านค้อ",
        "amphoe": "บ้านผือ",
        "province": "อุดรธานี",
        "zipcode": 41160,
        "district_code": 411605,
        "amphoe_code": 4117,
        "province_code": 41
    },
    {
        "district": "บ้านผือ",
        "amphoe": "บ้านผือ",
        "province": "อุดรธานี",
        "zipcode": 41160,
        "district_code": 411701,
        "amphoe_code": 4117,
        "province_code": 41
    },
    {
        "district": "หนองหัวคู",
        "amphoe": "บ้านผือ",
        "province": "อุดรธานี",
        "zipcode": 41160,
        "district_code": 411711,
        "amphoe_code": 4117,
        "province_code": 41
    },
    {
        "district": "หนองแวง",
        "amphoe": "บ้านผือ",
        "province": "อุดรธานี",
        "zipcode": 41160,
        "district_code": 411713,
        "amphoe_code": 4117,
        "province_code": 41
    },
    {
        "district": "หายโศก",
        "amphoe": "บ้านผือ",
        "province": "อุดรธานี",
        "zipcode": 41160,
        "district_code": 411702,
        "amphoe_code": 4117,
        "province_code": 41
    },
    {
        "district": "เขือน้ำ",
        "amphoe": "บ้านผือ",
        "province": "อุดรธานี",
        "zipcode": 41160,
        "district_code": 411703,
        "amphoe_code": 4117,
        "province_code": 41
    },
    {
        "district": "เมืองพาน",
        "amphoe": "บ้านผือ",
        "province": "อุดรธานี",
        "zipcode": 41160,
        "district_code": 411709,
        "amphoe_code": 4117,
        "province_code": 41
    },
    {
        "district": "โนนทอง",
        "amphoe": "บ้านผือ",
        "province": "อุดรธานี",
        "zipcode": 41160,
        "district_code": 411705,
        "amphoe_code": 4117,
        "province_code": 41
    },
    {
        "district": "นาม่วง",
        "amphoe": "ประจักษ์ศิลปาคม",
        "province": "อุดรธานี",
        "zipcode": 41110,
        "district_code": 410412,
        "amphoe_code": 4125,
        "province_code": 41
    },
    {
        "district": "ห้วยสามพาด",
        "amphoe": "ประจักษ์ศิลปาคม",
        "province": "อุดรธานี",
        "zipcode": 41110,
        "district_code": 410408,
        "amphoe_code": 4125,
        "province_code": 41
    },
    {
        "district": "อุ่มจาน",
        "amphoe": "ประจักษ์ศิลปาคม",
        "province": "อุดรธานี",
        "zipcode": 41110,
        "district_code": 410405,
        "amphoe_code": 4125,
        "province_code": 41
    },
    {
        "district": "ดอนกลอย",
        "amphoe": "พิบูลย์รักษ์",
        "province": "อุดรธานี",
        "zipcode": 41130,
        "district_code": 410613,
        "amphoe_code": 4123,
        "province_code": 41
    },
    {
        "district": "นาทราย",
        "amphoe": "พิบูลย์รักษ์",
        "province": "อุดรธานี",
        "zipcode": 41130,
        "district_code": 410615,
        "amphoe_code": 4123,
        "province_code": 41
    },
    {
        "district": "บ้านแดง",
        "amphoe": "พิบูลย์รักษ์",
        "province": "อุดรธานี",
        "zipcode": 41130,
        "district_code": 410608,
        "amphoe_code": 4123,
        "province_code": 41
    },
    {
        "district": "คำโคกสูง",
        "amphoe": "วังสามหมอ",
        "province": "อุดรธานี",
        "zipcode": 41280,
        "district_code": 411005,
        "amphoe_code": 4110,
        "province_code": 41
    },
    {
        "district": "บะยาว",
        "amphoe": "วังสามหมอ",
        "province": "อุดรธานี",
        "zipcode": 41280,
        "district_code": 411003,
        "amphoe_code": 4110,
        "province_code": 41
    },
    {
        "district": "ผาสุก",
        "amphoe": "วังสามหมอ",
        "province": "อุดรธานี",
        "zipcode": 41280,
        "district_code": 410413,
        "amphoe_code": 4110,
        "province_code": 41
    },
    {
        "district": "วังสามหมอ",
        "amphoe": "วังสามหมอ",
        "province": "อุดรธานี",
        "zipcode": 41280,
        "district_code": 411006,
        "amphoe_code": 4110,
        "province_code": 41
    },
    {
        "district": "หนองกุงทับม้า",
        "amphoe": "วังสามหมอ",
        "province": "อุดรธานี",
        "zipcode": 41280,
        "district_code": 411001,
        "amphoe_code": 4110,
        "province_code": 41
    },
    {
        "district": "หนองหญ้าไซ",
        "amphoe": "วังสามหมอ",
        "province": "อุดรธานี",
        "zipcode": 41280,
        "district_code": 411002,
        "amphoe_code": 4110,
        "province_code": 41
    },
    {
        "district": "จำปี",
        "amphoe": "ศรีธาตุ",
        "province": "อุดรธานี",
        "zipcode": 41230,
        "district_code": 410902,
        "amphoe_code": 4109,
        "province_code": 41
    },
    {
        "district": "ตาดทอง",
        "amphoe": "ศรีธาตุ",
        "province": "อุดรธานี",
        "zipcode": 41230,
        "district_code": 410907,
        "amphoe_code": 4109,
        "province_code": 41
    },
    {
        "district": "นายูง",
        "amphoe": "ศรีธาตุ",
        "province": "อุดรธานี",
        "zipcode": 41230,
        "district_code": 410906,
        "amphoe_code": 4109,
        "province_code": 41
    },
    {
        "district": "บ้านโปร่ง",
        "amphoe": "ศรีธาตุ",
        "province": "อุดรธานี",
        "zipcode": 41230,
        "district_code": 410903,
        "amphoe_code": 4109,
        "province_code": 41
    },
    {
        "district": "ศรีธาตุ",
        "amphoe": "ศรีธาตุ",
        "province": "อุดรธานี",
        "zipcode": 41230,
        "district_code": 410901,
        "amphoe_code": 4109,
        "province_code": 41
    },
    {
        "district": "หนองนกเขียน",
        "amphoe": "ศรีธาตุ",
        "province": "อุดรธานี",
        "zipcode": 41230,
        "district_code": 410905,
        "amphoe_code": 4109,
        "province_code": 41
    },
    {
        "district": "หัวนาคำ",
        "amphoe": "ศรีธาตุ",
        "province": "อุดรธานี",
        "zipcode": 41230,
        "district_code": 410904,
        "amphoe_code": 4109,
        "province_code": 41
    },
    {
        "district": "นาสะอาด",
        "amphoe": "สร้างคอม",
        "province": "อุดรธานี",
        "zipcode": 41260,
        "district_code": 412005,
        "amphoe_code": 4120,
        "province_code": 41
    },
    {
        "district": "บ้านยวด",
        "amphoe": "สร้างคอม",
        "province": "อุดรธานี",
        "zipcode": 41260,
        "district_code": 411998,
        "amphoe_code": 4120,
        "province_code": 41
    },
    {
        "district": "บ้านหินโงม",
        "amphoe": "สร้างคอม",
        "province": "อุดรธานี",
        "zipcode": 41260,
        "district_code": 412006,
        "amphoe_code": 4120,
        "province_code": 41
    },
    {
        "district": "บ้านโคก",
        "amphoe": "สร้างคอม",
        "province": "อุดรธานี",
        "zipcode": 41260,
        "district_code": 411498,
        "amphoe_code": 4120,
        "province_code": 41
    },
    {
        "district": "สร้างคอม",
        "amphoe": "สร้างคอม",
        "province": "อุดรธานี",
        "zipcode": 41260,
        "district_code": 411999,
        "amphoe_code": 4120,
        "province_code": 41
    },
    {
        "district": "เชียงดา",
        "amphoe": "สร้างคอม",
        "province": "อุดรธานี",
        "zipcode": 41260,
        "district_code": 411997,
        "amphoe_code": 4120,
        "province_code": 41
    },
    {
        "district": "กุดหมากไฟ",
        "amphoe": "หนองวัวซอ",
        "province": "อุดรธานี",
        "zipcode": 41220,
        "district_code": 410304,
        "amphoe_code": 4103,
        "province_code": 41
    },
    {
        "district": "น้ำพ่น",
        "amphoe": "หนองวัวซอ",
        "province": "อุดรธานี",
        "zipcode": 41360,
        "district_code": 410305,
        "amphoe_code": 4103,
        "province_code": 41
    },
    {
        "district": "หนองบัวบาน",
        "amphoe": "หนองวัวซอ",
        "province": "อุดรธานี",
        "zipcode": 41360,
        "district_code": 410306,
        "amphoe_code": 4103,
        "province_code": 41
    },
    {
        "district": "หนองวัวซอ",
        "amphoe": "หนองวัวซอ",
        "province": "อุดรธานี",
        "zipcode": 41360,
        "district_code": 410308,
        "amphoe_code": 4103,
        "province_code": 41
    },
    {
        "district": "หนองอ้อ",
        "amphoe": "หนองวัวซอ",
        "province": "อุดรธานี",
        "zipcode": 41220,
        "district_code": 410302,
        "amphoe_code": 4103,
        "province_code": 41
    },
    {
        "district": "หมากหญ้า",
        "amphoe": "หนองวัวซอ",
        "province": "อุดรธานี",
        "zipcode": 41360,
        "district_code": 410301,
        "amphoe_code": 4103,
        "province_code": 41
    },
    {
        "district": "อูบมุง",
        "amphoe": "หนองวัวซอ",
        "province": "อุดรธานี",
        "zipcode": 41220,
        "district_code": 410303,
        "amphoe_code": 4103,
        "province_code": 41
    },
    {
        "district": "โนนหวาย",
        "amphoe": "หนองวัวซอ",
        "province": "อุดรธานี",
        "zipcode": 41220,
        "district_code": 410307,
        "amphoe_code": 4103,
        "province_code": 41
    },
    {
        "district": "ดอนหายโศก",
        "amphoe": "หนองหาน",
        "province": "อุดรธานี",
        "zipcode": 41130,
        "district_code": 410617,
        "amphoe_code": 4106,
        "province_code": 41
    },
    {
        "district": "บ้านยา",
        "amphoe": "หนองหาน",
        "province": "อุดรธานี",
        "zipcode": 41320,
        "district_code": 410610,
        "amphoe_code": 4106,
        "province_code": 41
    },
    {
        "district": "บ้านเชียง",
        "amphoe": "หนองหาน",
        "province": "อุดรธานี",
        "zipcode": 41320,
        "district_code": 410609,
        "amphoe_code": 4106,
        "province_code": 41
    },
    {
        "district": "ผักตบ",
        "amphoe": "หนองหาน",
        "province": "อุดรธานี",
        "zipcode": 41130,
        "district_code": 410612,
        "amphoe_code": 4106,
        "province_code": 41
    },
    {
        "district": "พังงู",
        "amphoe": "หนองหาน",
        "province": "อุดรธานี",
        "zipcode": 41130,
        "district_code": 410605,
        "amphoe_code": 4106,
        "province_code": 41
    },
    {
        "district": "สร้อยพร้าว",
        "amphoe": "หนองหาน",
        "province": "อุดรธานี",
        "zipcode": 41130,
        "district_code": 410607,
        "amphoe_code": 4106,
        "province_code": 41
    },
    {
        "district": "สะแบง",
        "amphoe": "หนองหาน",
        "province": "อุดรธานี",
        "zipcode": 41130,
        "district_code": 410606,
        "amphoe_code": 4106,
        "province_code": 41
    },
    {
        "district": "หนองสระปลา",
        "amphoe": "หนองหาน",
        "province": "อุดรธานี",
        "zipcode": 41320,
        "district_code": 410618,
        "amphoe_code": 4106,
        "province_code": 41
    },
    {
        "district": "หนองหาน",
        "amphoe": "หนองหาน",
        "province": "อุดรธานี",
        "zipcode": 41130,
        "district_code": 410601,
        "amphoe_code": 4106,
        "province_code": 41
    },
    {
        "district": "หนองเม็ก",
        "amphoe": "หนองหาน",
        "province": "อุดรธานี",
        "zipcode": 41130,
        "district_code": 410602,
        "amphoe_code": 4106,
        "province_code": 41
    },
    {
        "district": "หนองไผ่",
        "amphoe": "หนองหาน",
        "province": "อุดรธานี",
        "zipcode": 41130,
        "district_code": 410121,
        "amphoe_code": 4106,
        "province_code": 41
    },
    {
        "district": "โพนงาม",
        "amphoe": "หนองหาน",
        "province": "อุดรธานี",
        "zipcode": 41130,
        "district_code": 410611,
        "amphoe_code": 4106,
        "province_code": 41
    },
    {
        "district": "ทับกุง",
        "amphoe": "หนองแสง",
        "province": "อุดรธานี",
        "zipcode": 41340,
        "district_code": 412104,
        "amphoe_code": 4121,
        "province_code": 41
    },
    {
        "district": "นาดี",
        "amphoe": "หนองแสง",
        "province": "อุดรธานี",
        "zipcode": 41340,
        "district_code": 410111,
        "amphoe_code": 4121,
        "province_code": 41
    },
    {
        "district": "หนองแสง",
        "amphoe": "หนองแสง",
        "province": "อุดรธานี",
        "zipcode": 41340,
        "district_code": 410498,
        "amphoe_code": 4121,
        "province_code": 41
    },
    {
        "district": "แสงสว่าง",
        "amphoe": "หนองแสง",
        "province": "อุดรธานี",
        "zipcode": 41340,
        "district_code": 410499,
        "amphoe_code": 4121,
        "province_code": 41
    },
    {
        "district": "จอมศรี",
        "amphoe": "เพ็ญ",
        "province": "อุดรธานี",
        "zipcode": 41150,
        "district_code": 411908,
        "amphoe_code": 4119,
        "province_code": 41
    },
    {
        "district": "นาบัว",
        "amphoe": "เพ็ญ",
        "province": "อุดรธานี",
        "zipcode": 41150,
        "district_code": 411906,
        "amphoe_code": 4119,
        "province_code": 41
    },
    {
        "district": "นาพู่",
        "amphoe": "เพ็ญ",
        "province": "อุดรธานี",
        "zipcode": 41150,
        "district_code": 411903,
        "amphoe_code": 4119,
        "province_code": 41
    },
    {
        "district": "บ้านธาตุ",
        "amphoe": "เพ็ญ",
        "province": "อุดรธานี",
        "zipcode": 41150,
        "district_code": 411902,
        "amphoe_code": 4119,
        "province_code": 41
    },
    {
        "district": "บ้านเหล่า",
        "amphoe": "เพ็ญ",
        "province": "อุดรธานี",
        "zipcode": 41150,
        "district_code": 411907,
        "amphoe_code": 4119,
        "province_code": 41
    },
    {
        "district": "สร้างแป้น",
        "amphoe": "เพ็ญ",
        "province": "อุดรธานี",
        "zipcode": 41150,
        "district_code": 411911,
        "amphoe_code": 4119,
        "province_code": 41
    },
    {
        "district": "สุมเส้า",
        "amphoe": "เพ็ญ",
        "province": "อุดรธานี",
        "zipcode": 41150,
        "district_code": 411905,
        "amphoe_code": 4119,
        "province_code": 41
    },
    {
        "district": "เชียงหวาง",
        "amphoe": "เพ็ญ",
        "province": "อุดรธานี",
        "zipcode": 41150,
        "district_code": 411904,
        "amphoe_code": 4119,
        "province_code": 41
    },
    {
        "district": "เตาไห",
        "amphoe": "เพ็ญ",
        "province": "อุดรธานี",
        "zipcode": 41150,
        "district_code": 411909,
        "amphoe_code": 4119,
        "province_code": 41
    },
    {
        "district": "เพ็ญ",
        "amphoe": "เพ็ญ",
        "province": "อุดรธานี",
        "zipcode": 41150,
        "district_code": 411901,
        "amphoe_code": 4119,
        "province_code": 41
    },
    {
        "district": "โคกกลาง",
        "amphoe": "เพ็ญ",
        "province": "อุดรธานี",
        "zipcode": 41150,
        "district_code": 410506,
        "amphoe_code": 4119,
        "province_code": 41
    },
    {
        "district": "กุดสระ",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410110,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "นากว้าง",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410120,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "นาข่า",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410116,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "นาดี",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410111,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "นิคมสงเคราะห์",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410102,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "บ้านขาว",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410103,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "บ้านจั่น",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410117,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "บ้านตาด",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410105,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "บ้านเลื่อม",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410112,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "สามพร้าว",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410114,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "หนองขอนกว้าง",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410118,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "หนองนาคำ",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410109,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "หนองบัว",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410104,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "หนองไผ่",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41330,
        "district_code": 410121,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "หนองไฮ",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410115,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "หมากแข้ง",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410101,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "หมูม่น",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410107,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "เชียงพิณ",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410113,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "เชียงยืน",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410108,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "โคกสะอาด",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41000,
        "district_code": 410119,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "โนนสูง",
        "amphoe": "เมืองอุดรธานี",
        "province": "อุดรธานี",
        "zipcode": 41330,
        "district_code": 410106,
        "amphoe_code": 4101,
        "province_code": 41
    },
    {
        "district": "ทมนางาม",
        "amphoe": "โนนสะอาด",
        "province": "อุดรธานี",
        "zipcode": 41240,
        "district_code": 410504,
        "amphoe_code": 4105,
        "province_code": 41
    },
    {
        "district": "บุ่งแก้ว",
        "amphoe": "โนนสะอาด",
        "province": "อุดรธานี",
        "zipcode": 41240,
        "district_code": 410497,
        "amphoe_code": 4105,
        "province_code": 41
    },
    {
        "district": "หนองกุงศรี",
        "amphoe": "โนนสะอาด",
        "province": "อุดรธานี",
        "zipcode": 41240,
        "district_code": 410505,
        "amphoe_code": 4105,
        "province_code": 41
    },
    {
        "district": "โคกกลาง",
        "amphoe": "โนนสะอาด",
        "province": "อุดรธานี",
        "zipcode": 41240,
        "district_code": 410506,
        "amphoe_code": 4105,
        "province_code": 41
    },
    {
        "district": "โนนสะอาด",
        "amphoe": "โนนสะอาด",
        "province": "อุดรธานี",
        "zipcode": 41240,
        "district_code": 410495,
        "amphoe_code": 4105,
        "province_code": 41
    },
    {
        "district": "โพธิ์ศรีสำราญ",
        "amphoe": "โนนสะอาด",
        "province": "อุดรธานี",
        "zipcode": 41240,
        "district_code": 410496,
        "amphoe_code": 4105,
        "province_code": 41
    },
    {
        "district": "คำเลาะ",
        "amphoe": "ไชยวาน",
        "province": "อุดรธานี",
        "zipcode": 41290,
        "district_code": 410803,
        "amphoe_code": 4108,
        "province_code": 41
    },
    {
        "district": "หนองหลัก",
        "amphoe": "ไชยวาน",
        "province": "อุดรธานี",
        "zipcode": 41290,
        "district_code": 410694,
        "amphoe_code": 4108,
        "province_code": 41
    },
    {
        "district": "โพนสูง",
        "amphoe": "ไชยวาน",
        "province": "อุดรธานี",
        "zipcode": 41290,
        "district_code": 410698,
        "amphoe_code": 4108,
        "province_code": 41
    },
    {
        "district": "ไชยวาน",
        "amphoe": "ไชยวาน",
        "province": "อุดรธานี",
        "zipcode": 41290,
        "district_code": 410699,
        "amphoe_code": 4108,
        "province_code": 41
    },
    {
        "district": "ข่อยสูง",
        "amphoe": "ตรอน",
        "province": "อุตรดิตถ์",
        "zipcode": 53140,
        "district_code": 530205,
        "amphoe_code": 5302,
        "province_code": 53
    },
    {
        "district": "น้ำอ่าง",
        "amphoe": "ตรอน",
        "province": "อุตรดิตถ์",
        "zipcode": 53140,
        "district_code": 530204,
        "amphoe_code": 5302,
        "province_code": 53
    },
    {
        "district": "บ้านแก่ง",
        "amphoe": "ตรอน",
        "province": "อุตรดิตถ์",
        "zipcode": 53140,
        "district_code": 530202,
        "amphoe_code": 5302,
        "province_code": 53
    },
    {
        "district": "วังแดง",
        "amphoe": "ตรอน",
        "province": "อุตรดิตถ์",
        "zipcode": 53140,
        "district_code": 530201,
        "amphoe_code": 5302,
        "province_code": 53
    },
    {
        "district": "หาดสองแคว",
        "amphoe": "ตรอน",
        "province": "อุตรดิตถ์",
        "zipcode": 53140,
        "district_code": 530203,
        "amphoe_code": 5302,
        "province_code": 53
    },
    {
        "district": "น้ำพี้",
        "amphoe": "ทองแสนขัน",
        "province": "อุตรดิตถ์",
        "zipcode": 53230,
        "district_code": 530296,
        "amphoe_code": 5309,
        "province_code": 53
    },
    {
        "district": "บ่อทอง",
        "amphoe": "ทองแสนขัน",
        "province": "อุตรดิตถ์",
        "zipcode": 53230,
        "district_code": 530297,
        "amphoe_code": 5309,
        "province_code": 53
    },
    {
        "district": "ป่าคาย",
        "amphoe": "ทองแสนขัน",
        "province": "อุตรดิตถ์",
        "zipcode": 53230,
        "district_code": 530299,
        "amphoe_code": 5309,
        "province_code": 53
    },
    {
        "district": "ผักขวง",
        "amphoe": "ทองแสนขัน",
        "province": "อุตรดิตถ์",
        "zipcode": 53230,
        "district_code": 530298,
        "amphoe_code": 5309,
        "province_code": 53
    },
    {
        "district": "จริม",
        "amphoe": "ท่าปลา",
        "province": "อุตรดิตถ์",
        "zipcode": 53150,
        "district_code": 530304,
        "amphoe_code": 5303,
        "province_code": 53
    },
    {
        "district": "ท่าปลา",
        "amphoe": "ท่าปลา",
        "province": "อุตรดิตถ์",
        "zipcode": 53150,
        "district_code": 530301,
        "amphoe_code": 5303,
        "province_code": 53
    },
    {
        "district": "ท่าแฝก",
        "amphoe": "น้ำปาด",
        "province": "อุตรดิตถ์",
        "zipcode": 53110,
        "district_code": 530306,
        "amphoe_code": 5303,
        "province_code": 53
    },
    {
        "district": "นางพญา",
        "amphoe": "ท่าปลา",
        "province": "อุตรดิตถ์",
        "zipcode": 53150,
        "district_code": 530307,
        "amphoe_code": 5303,
        "province_code": 53
    },
    {
        "district": "น้ำหมัน",
        "amphoe": "ท่าปลา",
        "province": "อุตรดิตถ์",
        "zipcode": 53150,
        "district_code": 530305,
        "amphoe_code": 5303,
        "province_code": 53
    },
    {
        "district": "ผาเลือด",
        "amphoe": "ท่าปลา",
        "province": "อุตรดิตถ์",
        "zipcode": 53190,
        "district_code": 530303,
        "amphoe_code": 5303,
        "province_code": 53
    },
    {
        "district": "ร่วมจิต",
        "amphoe": "ท่าปลา",
        "province": "อุตรดิตถ์",
        "zipcode": 53190,
        "district_code": 530308,
        "amphoe_code": 5303,
        "province_code": 53
    },
    {
        "district": "หาดล้า",
        "amphoe": "ท่าปลา",
        "province": "อุตรดิตถ์",
        "zipcode": 53190,
        "district_code": 530302,
        "amphoe_code": 5303,
        "province_code": 53
    },
    {
        "district": "น้ำไคร้",
        "amphoe": "น้ำปาด",
        "province": "อุตรดิตถ์",
        "zipcode": 53110,
        "district_code": 530404,
        "amphoe_code": 5304,
        "province_code": 53
    },
    {
        "district": "น้ำไผ่",
        "amphoe": "น้ำปาด",
        "province": "อุตรดิตถ์",
        "zipcode": 53110,
        "district_code": 530405,
        "amphoe_code": 5304,
        "province_code": 53
    },
    {
        "district": "บ้านฝาย",
        "amphoe": "น้ำปาด",
        "province": "อุตรดิตถ์",
        "zipcode": 53110,
        "district_code": 530402,
        "amphoe_code": 5304,
        "province_code": 53
    },
    {
        "district": "ห้วยมุ่น",
        "amphoe": "น้ำปาด",
        "province": "อุตรดิตถ์",
        "zipcode": 53110,
        "district_code": 530406,
        "amphoe_code": 5304,
        "province_code": 53
    },
    {
        "district": "เด่นเหล็ก",
        "amphoe": "น้ำปาด",
        "province": "อุตรดิตถ์",
        "zipcode": 53110,
        "district_code": 530403,
        "amphoe_code": 5304,
        "province_code": 53
    },
    {
        "district": "แสนตอ",
        "amphoe": "น้ำปาด",
        "province": "อุตรดิตถ์",
        "zipcode": 53110,
        "district_code": 530114,
        "amphoe_code": 5304,
        "province_code": 53
    },
    {
        "district": "นาขุม",
        "amphoe": "บ้านโคก",
        "province": "อุตรดิตถ์",
        "zipcode": 53180,
        "district_code": 530603,
        "amphoe_code": 5306,
        "province_code": 53
    },
    {
        "district": "บ่อเบี้ย",
        "amphoe": "บ้านโคก",
        "province": "อุตรดิตถ์",
        "zipcode": 53180,
        "district_code": 530604,
        "amphoe_code": 5306,
        "province_code": 53
    },
    {
        "district": "บ้านโคก",
        "amphoe": "บ้านโคก",
        "province": "อุตรดิตถ์",
        "zipcode": 53180,
        "district_code": 530602,
        "amphoe_code": 5306,
        "province_code": 53
    },
    {
        "district": "ม่วงเจ็ดต้น",
        "amphoe": "บ้านโคก",
        "province": "อุตรดิตถ์",
        "zipcode": 53180,
        "district_code": 530601,
        "amphoe_code": 5306,
        "province_code": 53
    },
    {
        "district": "คอรุม",
        "amphoe": "พิชัย",
        "province": "อุตรดิตถ์",
        "zipcode": 53120,
        "district_code": 530705,
        "amphoe_code": 5307,
        "province_code": 53
    },
    {
        "district": "ท่ามะเฟือง",
        "amphoe": "พิชัย",
        "province": "อุตรดิตถ์",
        "zipcode": 53120,
        "district_code": 530707,
        "amphoe_code": 5307,
        "province_code": 53
    },
    {
        "district": "ท่าสัก",
        "amphoe": "พิชัย",
        "province": "อุตรดิตถ์",
        "zipcode": 53220,
        "district_code": 530704,
        "amphoe_code": 5307,
        "province_code": 53
    },
    {
        "district": "นายาง",
        "amphoe": "พิชัย",
        "province": "อุตรดิตถ์",
        "zipcode": 53120,
        "district_code": 530711,
        "amphoe_code": 5307,
        "province_code": 53
    },
    {
        "district": "นาอิน",
        "amphoe": "พิชัย",
        "province": "อุตรดิตถ์",
        "zipcode": 53120,
        "district_code": 530710,
        "amphoe_code": 5307,
        "province_code": 53
    },
    {
        "district": "บ้านดารา",
        "amphoe": "พิชัย",
        "province": "อุตรดิตถ์",
        "zipcode": 53220,
        "district_code": 530702,
        "amphoe_code": 5307,
        "province_code": 53
    },
    {
        "district": "บ้านหม้อ",
        "amphoe": "พิชัย",
        "province": "อุตรดิตถ์",
        "zipcode": 53120,
        "district_code": 530706,
        "amphoe_code": 5307,
        "province_code": 53
    },
    {
        "district": "บ้านโคน",
        "amphoe": "พิชัย",
        "province": "อุตรดิตถ์",
        "zipcode": 53120,
        "district_code": 530708,
        "amphoe_code": 5307,
        "province_code": 53
    },
    {
        "district": "พญาแมน",
        "amphoe": "พิชัย",
        "province": "อุตรดิตถ์",
        "zipcode": 53120,
        "district_code": 530709,
        "amphoe_code": 5307,
        "province_code": 53
    },
    {
        "district": "ในเมือง",
        "amphoe": "พิชัย",
        "province": "อุตรดิตถ์",
        "zipcode": 53120,
        "district_code": 530701,
        "amphoe_code": 5307,
        "province_code": 53
    },
    {
        "district": "ไร่อ้อย",
        "amphoe": "พิชัย",
        "province": "อุตรดิตถ์",
        "zipcode": 53120,
        "district_code": 530703,
        "amphoe_code": 5307,
        "province_code": 53
    },
    {
        "district": "บ้านเสี้ยว",
        "amphoe": "ฟากท่า",
        "province": "อุตรดิตถ์",
        "zipcode": 53160,
        "district_code": 530503,
        "amphoe_code": 5305,
        "province_code": 53
    },
    {
        "district": "ฟากท่า",
        "amphoe": "ฟากท่า",
        "province": "อุตรดิตถ์",
        "zipcode": 53160,
        "district_code": 530501,
        "amphoe_code": 5305,
        "province_code": 53
    },
    {
        "district": "สองคอน",
        "amphoe": "ฟากท่า",
        "province": "อุตรดิตถ์",
        "zipcode": 53160,
        "district_code": 530502,
        "amphoe_code": 5305,
        "province_code": 53
    },
    {
        "district": "สองห้อง",
        "amphoe": "ฟากท่า",
        "province": "อุตรดิตถ์",
        "zipcode": 53160,
        "district_code": 530504,
        "amphoe_code": 5305,
        "province_code": 53
    },
    {
        "district": "ชัยจุมพล",
        "amphoe": "ลับแล",
        "province": "อุตรดิตถ์",
        "zipcode": 53130,
        "district_code": 530805,
        "amphoe_code": 5308,
        "province_code": 53
    },
    {
        "district": "ด่านแม่คำมัน",
        "amphoe": "ลับแล",
        "province": "อุตรดิตถ์",
        "zipcode": 53210,
        "district_code": 530808,
        "amphoe_code": 5308,
        "province_code": 53
    },
    {
        "district": "ทุ่งยั้ง",
        "amphoe": "ลับแล",
        "province": "อุตรดิตถ์",
        "zipcode": 53210,
        "district_code": 530807,
        "amphoe_code": 5308,
        "province_code": 53
    },
    {
        "district": "นานกกก",
        "amphoe": "ลับแล",
        "province": "อุตรดิตถ์",
        "zipcode": 53130,
        "district_code": 530803,
        "amphoe_code": 5308,
        "province_code": 53
    },
    {
        "district": "ฝายหลวง",
        "amphoe": "ลับแล",
        "province": "อุตรดิตถ์",
        "zipcode": 53130,
        "district_code": 530804,
        "amphoe_code": 5308,
        "province_code": 53
    },
    {
        "district": "ศรีพนมมาศ",
        "amphoe": "ลับแล",
        "province": "อุตรดิตถ์",
        "zipcode": 53130,
        "district_code": 530801,
        "amphoe_code": 5308,
        "province_code": 53
    },
    {
        "district": "แม่พูล",
        "amphoe": "ลับแล",
        "province": "อุตรดิตถ์",
        "zipcode": 53130,
        "district_code": 530802,
        "amphoe_code": 5308,
        "province_code": 53
    },
    {
        "district": "ไผ่ล้อม",
        "amphoe": "ลับแล",
        "province": "อุตรดิตถ์",
        "zipcode": 53210,
        "district_code": 530806,
        "amphoe_code": 5308,
        "province_code": 53
    },
    {
        "district": "ขุนฝาง",
        "amphoe": "เมืองอุตรดิตถ์",
        "province": "อุตรดิตถ์",
        "zipcode": 53000,
        "district_code": 530116,
        "amphoe_code": 5301,
        "province_code": 53
    },
    {
        "district": "คุ้งตะเภา",
        "amphoe": "เมืองอุตรดิตถ์",
        "province": "อุตรดิตถ์",
        "zipcode": 53000,
        "district_code": 530105,
        "amphoe_code": 5301,
        "province_code": 53
    },
    {
        "district": "งิ้วงาม",
        "amphoe": "เมืองอุตรดิตถ์",
        "province": "อุตรดิตถ์",
        "zipcode": 53000,
        "district_code": 530109,
        "amphoe_code": 5301,
        "province_code": 53
    },
    {
        "district": "ถ้ำฉลอง",
        "amphoe": "เมืองอุตรดิตถ์",
        "province": "อุตรดิตถ์",
        "zipcode": 53000,
        "district_code": 530117,
        "amphoe_code": 5301,
        "province_code": 53
    },
    {
        "district": "ท่าอิฐ",
        "amphoe": "เมืองอุตรดิตถ์",
        "province": "อุตรดิตถ์",
        "zipcode": 53000,
        "district_code": 530101,
        "amphoe_code": 5301,
        "province_code": 53
    },
    {
        "district": "ท่าเสา",
        "amphoe": "เมืองอุตรดิตถ์",
        "province": "อุตรดิตถ์",
        "zipcode": 53000,
        "district_code": 530102,
        "amphoe_code": 5301,
        "province_code": 53
    },
    {
        "district": "น้ำริด",
        "amphoe": "เมืองอุตรดิตถ์",
        "province": "อุตรดิตถ์",
        "zipcode": 53000,
        "district_code": 530108,
        "amphoe_code": 5301,
        "province_code": 53
    },
    {
        "district": "บ้านด่าน",
        "amphoe": "เมืองอุตรดิตถ์",
        "province": "อุตรดิตถ์",
        "zipcode": 53000,
        "district_code": 530111,
        "amphoe_code": 5301,
        "province_code": 53
    },
    {
        "district": "บ้านด่านนาขาม",
        "amphoe": "เมืองอุตรดิตถ์",
        "province": "อุตรดิตถ์",
        "zipcode": 53000,
        "district_code": 530110,
        "amphoe_code": 5301,
        "province_code": 53
    },
    {
        "district": "บ้านเกาะ",
        "amphoe": "เมืองอุตรดิตถ์",
        "province": "อุตรดิตถ์",
        "zipcode": 53000,
        "district_code": 530103,
        "amphoe_code": 5301,
        "province_code": 53
    },
    {
        "district": "ป่าเซ่า",
        "amphoe": "เมืองอุตรดิตถ์",
        "province": "อุตรดิตถ์",
        "zipcode": 53000,
        "district_code": 530104,
        "amphoe_code": 5301,
        "province_code": 53
    },
    {
        "district": "ผาจุก",
        "amphoe": "เมืองอุตรดิตถ์",
        "province": "อุตรดิตถ์",
        "zipcode": 53000,
        "district_code": 530112,
        "amphoe_code": 5301,
        "province_code": 53
    },
    {
        "district": "วังกะพี้",
        "amphoe": "เมืองอุตรดิตถ์",
        "province": "อุตรดิตถ์",
        "zipcode": 53170,
        "district_code": 530106,
        "amphoe_code": 5301,
        "province_code": 53
    },
    {
        "district": "วังดิน",
        "amphoe": "เมืองอุตรดิตถ์",
        "province": "อุตรดิตถ์",
        "zipcode": 53000,
        "district_code": 530113,
        "amphoe_code": 5301,
        "province_code": 53
    },
    {
        "district": "หาดกรวด",
        "amphoe": "เมืองอุตรดิตถ์",
        "province": "อุตรดิตถ์",
        "zipcode": 53000,
        "district_code": 530107,
        "amphoe_code": 5301,
        "province_code": 53
    },
    {
        "district": "หาดงิ้ว",
        "amphoe": "เมืองอุตรดิตถ์",
        "province": "อุตรดิตถ์",
        "zipcode": 53000,
        "district_code": 530115,
        "amphoe_code": 5301,
        "province_code": 53
    },
    {
        "district": "แสนตอ",
        "amphoe": "เมืองอุตรดิตถ์",
        "province": "อุตรดิตถ์",
        "zipcode": 53000,
        "district_code": 530114,
        "amphoe_code": 5301,
        "province_code": 53
    },
    {
        "district": "ตลุกดู่",
        "amphoe": "ทัพทัน",
        "province": "อุทัยธานี",
        "zipcode": 61120,
        "district_code": 610210,
        "amphoe_code": 6102,
        "province_code": 61
    },
    {
        "district": "ทัพทัน",
        "amphoe": "ทัพทัน",
        "province": "อุทัยธานี",
        "zipcode": 61120,
        "district_code": 610201,
        "amphoe_code": 6102,
        "province_code": 61
    },
    {
        "district": "ทุ่งนาไทย",
        "amphoe": "ทัพทัน",
        "province": "อุทัยธานี",
        "zipcode": 61120,
        "district_code": 610202,
        "amphoe_code": 6102,
        "province_code": 61
    },
    {
        "district": "หนองกระทุ่ม",
        "amphoe": "ทัพทัน",
        "province": "อุทัยธานี",
        "zipcode": 61120,
        "district_code": 610208,
        "amphoe_code": 6102,
        "province_code": 61
    },
    {
        "district": "หนองกลางดง",
        "amphoe": "ทัพทัน",
        "province": "อุทัยธานี",
        "zipcode": 61120,
        "district_code": 610207,
        "amphoe_code": 6102,
        "province_code": 61
    },
    {
        "district": "หนองยายดา",
        "amphoe": "ทัพทัน",
        "province": "อุทัยธานี",
        "zipcode": 61120,
        "district_code": 610206,
        "amphoe_code": 6102,
        "province_code": 61
    },
    {
        "district": "หนองสระ",
        "amphoe": "ทัพทัน",
        "province": "อุทัยธานี",
        "zipcode": 61120,
        "district_code": 610209,
        "amphoe_code": 6102,
        "province_code": 61
    },
    {
        "district": "หนองหญ้าปล้อง",
        "amphoe": "ทัพทัน",
        "province": "อุทัยธานี",
        "zipcode": 61120,
        "district_code": 610204,
        "amphoe_code": 6102,
        "province_code": 61
    },
    {
        "district": "เขาขี้ฝอย",
        "amphoe": "ทัพทัน",
        "province": "อุทัยธานี",
        "zipcode": 61120,
        "district_code": 610203,
        "amphoe_code": 6102,
        "province_code": 61
    },
    {
        "district": "โคกหม้อ",
        "amphoe": "ทัพทัน",
        "province": "อุทัยธานี",
        "zipcode": 61120,
        "district_code": 610205,
        "amphoe_code": 6102,
        "province_code": 61
    },
    {
        "district": "คอกควาย",
        "amphoe": "บ้านไร่",
        "province": "อุทัยธานี",
        "zipcode": 61140,
        "district_code": 610604,
        "amphoe_code": 6106,
        "province_code": 61
    },
    {
        "district": "ทัพหลวง",
        "amphoe": "บ้านไร่",
        "province": "อุทัยธานี",
        "zipcode": 61140,
        "district_code": 610602,
        "amphoe_code": 6106,
        "province_code": 61
    },
    {
        "district": "บ้านบึง",
        "amphoe": "บ้านไร่",
        "province": "อุทัยธานี",
        "zipcode": 61140,
        "district_code": 610611,
        "amphoe_code": 6106,
        "province_code": 61
    },
    {
        "district": "บ้านใหม่คลองเคียน",
        "amphoe": "บ้านไร่",
        "province": "อุทัยธานี",
        "zipcode": 61180,
        "district_code": 610612,
        "amphoe_code": 6106,
        "province_code": 61
    },
    {
        "district": "บ้านไร่",
        "amphoe": "บ้านไร่",
        "province": "อุทัยธานี",
        "zipcode": 61140,
        "district_code": 610601,
        "amphoe_code": 6106,
        "province_code": 61
    },
    {
        "district": "วังหิน",
        "amphoe": "บ้านไร่",
        "province": "อุทัยธานี",
        "zipcode": 61180,
        "district_code": 610605,
        "amphoe_code": 6106,
        "province_code": 61
    },
    {
        "district": "หนองจอก",
        "amphoe": "บ้านไร่",
        "province": "อุทัยธานี",
        "zipcode": 61180,
        "district_code": 610609,
        "amphoe_code": 6106,
        "province_code": 61
    },
    {
        "district": "หนองบ่มกล้วย",
        "amphoe": "บ้านไร่",
        "province": "อุทัยธานี",
        "zipcode": 61180,
        "district_code": 610613,
        "amphoe_code": 6106,
        "province_code": 61
    },
    {
        "district": "หูช้าง",
        "amphoe": "บ้านไร่",
        "province": "อุทัยธานี",
        "zipcode": 61180,
        "district_code": 610610,
        "amphoe_code": 6106,
        "province_code": 61
    },
    {
        "district": "ห้วยแห้ง",
        "amphoe": "บ้านไร่",
        "province": "อุทัยธานี",
        "zipcode": 61140,
        "district_code": 610603,
        "amphoe_code": 6106,
        "province_code": 61
    },
    {
        "district": "เจ้าวัด",
        "amphoe": "บ้านไร่",
        "province": "อุทัยธานี",
        "zipcode": 61150,
        "district_code": 610614,
        "amphoe_code": 6106,
        "province_code": 61
    },
    {
        "district": "เมืองการุ้ง",
        "amphoe": "บ้านไร่",
        "province": "อุทัยธานี",
        "zipcode": 61180,
        "district_code": 610606,
        "amphoe_code": 6106,
        "province_code": 61
    },
    {
        "district": "แก่นมะกรูด",
        "amphoe": "บ้านไร่",
        "province": "อุทัยธานี",
        "zipcode": 61140,
        "district_code": 610607,
        "amphoe_code": 6106,
        "province_code": 61
    },
    {
        "district": "ทุ่งนางาม",
        "amphoe": "ลานสัก",
        "province": "อุทัยธานี",
        "zipcode": 61160,
        "district_code": 610706,
        "amphoe_code": 6107,
        "province_code": 61
    },
    {
        "district": "น้ำรอบ",
        "amphoe": "ลานสัก",
        "province": "อุทัยธานี",
        "zipcode": 61160,
        "district_code": 610705,
        "amphoe_code": 6107,
        "province_code": 61
    },
    {
        "district": "ประดู่ยืน",
        "amphoe": "ลานสัก",
        "province": "อุทัยธานี",
        "zipcode": 61160,
        "district_code": 610698,
        "amphoe_code": 6107,
        "province_code": 61
    },
    {
        "district": "ป่าอ้อ",
        "amphoe": "ลานสัก",
        "province": "อุทัยธานี",
        "zipcode": 61160,
        "district_code": 610697,
        "amphoe_code": 6107,
        "province_code": 61
    },
    {
        "district": "ระบำ",
        "amphoe": "ลานสัก",
        "province": "อุทัยธานี",
        "zipcode": 61160,
        "district_code": 610704,
        "amphoe_code": 6107,
        "province_code": 61
    },
    {
        "district": "ลานสัก",
        "amphoe": "ลานสัก",
        "province": "อุทัยธานี",
        "zipcode": 61160,
        "district_code": 610699,
        "amphoe_code": 6107,
        "province_code": 61
    },
    {
        "district": "บ่อยาง",
        "amphoe": "สว่างอารมณ์",
        "province": "อุทัยธานี",
        "zipcode": 61150,
        "district_code": 610305,
        "amphoe_code": 6103,
        "province_code": 61
    },
    {
        "district": "พลวงสองนาง",
        "amphoe": "สว่างอารมณ์",
        "province": "อุทัยธานี",
        "zipcode": 61150,
        "district_code": 610303,
        "amphoe_code": 6103,
        "province_code": 61
    },
    {
        "district": "สว่างอารมณ์",
        "amphoe": "สว่างอารมณ์",
        "province": "อุทัยธานี",
        "zipcode": 61150,
        "district_code": 610301,
        "amphoe_code": 6103,
        "province_code": 61
    },
    {
        "district": "หนองหลวง",
        "amphoe": "สว่างอารมณ์",
        "province": "อุทัยธานี",
        "zipcode": 61150,
        "district_code": 610302,
        "amphoe_code": 6103,
        "province_code": 61
    },
    {
        "district": "ไผ่เขียว",
        "amphoe": "สว่างอารมณ์",
        "province": "อุทัยธานี",
        "zipcode": 61150,
        "district_code": 610304,
        "amphoe_code": 6103,
        "province_code": 61
    },
    {
        "district": "ดงขวาง",
        "amphoe": "หนองขาหย่าง",
        "province": "อุทัยธานี",
        "zipcode": 61130,
        "district_code": 610509,
        "amphoe_code": 6105,
        "province_code": 61
    },
    {
        "district": "ดอนกลอย",
        "amphoe": "หนองขาหย่าง",
        "province": "อุทัยธานี",
        "zipcode": 61130,
        "district_code": 610503,
        "amphoe_code": 6105,
        "province_code": 61
    },
    {
        "district": "ทุ่งพึ่ง",
        "amphoe": "หนองขาหย่าง",
        "province": "อุทัยธานี",
        "zipcode": 61130,
        "district_code": 610505,
        "amphoe_code": 6105,
        "province_code": 61
    },
    {
        "district": "ท่าโพ",
        "amphoe": "หนองขาหย่าง",
        "province": "อุทัยธานี",
        "zipcode": 61130,
        "district_code": 610506,
        "amphoe_code": 6105,
        "province_code": 61
    },
    {
        "district": "หนองขาหย่าง",
        "amphoe": "หนองขาหย่าง",
        "province": "อุทัยธานี",
        "zipcode": 61130,
        "district_code": 610501,
        "amphoe_code": 6105,
        "province_code": 61
    },
    {
        "district": "หนองไผ่",
        "amphoe": "หนองขาหย่าง",
        "province": "อุทัยธานี",
        "zipcode": 61130,
        "district_code": 610502,
        "amphoe_code": 6105,
        "province_code": 61
    },
    {
        "district": "หมกแถว",
        "amphoe": "หนองขาหย่าง",
        "province": "อุทัยธานี",
        "zipcode": 61130,
        "district_code": 610507,
        "amphoe_code": 6105,
        "province_code": 61
    },
    {
        "district": "หลุมเข้า",
        "amphoe": "หนองขาหย่าง",
        "province": "อุทัยธานี",
        "zipcode": 61130,
        "district_code": 610508,
        "amphoe_code": 6105,
        "province_code": 61
    },
    {
        "district": "ห้วยรอบ",
        "amphoe": "หนองขาหย่าง",
        "province": "อุทัยธานี",
        "zipcode": 61130,
        "district_code": 610504,
        "amphoe_code": 6105,
        "province_code": 61
    },
    {
        "district": "ทุ่งพง",
        "amphoe": "หนองฉาง",
        "province": "อุทัยธานี",
        "zipcode": 61110,
        "district_code": 610408,
        "amphoe_code": 6104,
        "province_code": 61
    },
    {
        "district": "ทุ่งโพ",
        "amphoe": "หนองฉาง",
        "province": "อุทัยธานี",
        "zipcode": 61110,
        "district_code": 610407,
        "amphoe_code": 6104,
        "province_code": 61
    },
    {
        "district": "บ้านเก่า",
        "amphoe": "หนองฉาง",
        "province": "อุทัยธานี",
        "zipcode": 61110,
        "district_code": 610405,
        "amphoe_code": 6104,
        "province_code": 61
    },
    {
        "district": "หนองฉาง",
        "amphoe": "หนองฉาง",
        "province": "อุทัยธานี",
        "zipcode": 61110,
        "district_code": 610401,
        "amphoe_code": 6104,
        "province_code": 61
    },
    {
        "district": "หนองนางนวล",
        "amphoe": "หนองฉาง",
        "province": "อุทัยธานี",
        "zipcode": 61110,
        "district_code": 610403,
        "amphoe_code": 6104,
        "province_code": 61
    },
    {
        "district": "หนองยาง",
        "amphoe": "หนองฉาง",
        "province": "อุทัยธานี",
        "zipcode": 61110,
        "district_code": 610402,
        "amphoe_code": 6104,
        "province_code": 61
    },
    {
        "district": "หนองสรวง",
        "amphoe": "หนองฉาง",
        "province": "อุทัยธานี",
        "zipcode": 61110,
        "district_code": 610404,
        "amphoe_code": 6104,
        "province_code": 61
    },
    {
        "district": "อุทัยเก่า",
        "amphoe": "หนองฉาง",
        "province": "อุทัยธานี",
        "zipcode": 61110,
        "district_code": 610406,
        "amphoe_code": 6104,
        "province_code": 61
    },
    {
        "district": "เขากวางทอง",
        "amphoe": "หนองฉาง",
        "province": "อุทัยธานี",
        "zipcode": 61110,
        "district_code": 610410,
        "amphoe_code": 6104,
        "province_code": 61
    },
    {
        "district": "เขาบางแกรก",
        "amphoe": "หนองฉาง",
        "province": "อุทัยธานี",
        "zipcode": 61170,
        "district_code": 610409,
        "amphoe_code": 6104,
        "province_code": 61
    },
    {
        "district": "ทองหลาง",
        "amphoe": "ห้วยคต",
        "province": "อุทัยธานี",
        "zipcode": 61170,
        "district_code": 610802,
        "amphoe_code": 6108,
        "province_code": 61
    },
    {
        "district": "สุขฤทัย",
        "amphoe": "ห้วยคต",
        "province": "อุทัยธานี",
        "zipcode": 61170,
        "district_code": 610696,
        "amphoe_code": 6108,
        "province_code": 61
    },
    {
        "district": "ห้วยคต",
        "amphoe": "ห้วยคต",
        "province": "อุทัยธานี",
        "zipcode": 61170,
        "district_code": 610695,
        "amphoe_code": 6108,
        "province_code": 61
    },
    {
        "district": "ดอนขวาง",
        "amphoe": "เมืองอุทัยธานี",
        "province": "อุทัยธานี",
        "zipcode": 61000,
        "district_code": 610104,
        "amphoe_code": 6101,
        "province_code": 61
    },
    {
        "district": "ทุ่งใหญ่",
        "amphoe": "เมืองอุทัยธานี",
        "province": "อุทัยธานี",
        "zipcode": 61000,
        "district_code": 610113,
        "amphoe_code": 6101,
        "province_code": 61
    },
    {
        "district": "ท่าซุง",
        "amphoe": "เมืองอุทัยธานี",
        "province": "อุทัยธานี",
        "zipcode": 61000,
        "district_code": 610107,
        "amphoe_code": 6101,
        "province_code": 61
    },
    {
        "district": "น้ำซึม",
        "amphoe": "เมืองอุทัยธานี",
        "province": "อุทัยธานี",
        "zipcode": 61000,
        "district_code": 610102,
        "amphoe_code": 6101,
        "province_code": 61
    },
    {
        "district": "สะแกกรัง",
        "amphoe": "เมืองอุทัยธานี",
        "province": "อุทัยธานี",
        "zipcode": 61000,
        "district_code": 610103,
        "amphoe_code": 6101,
        "province_code": 61
    },
    {
        "district": "หนองพังค่า",
        "amphoe": "เมืองอุทัยธานี",
        "province": "อุทัยธานี",
        "zipcode": 61000,
        "district_code": 610112,
        "amphoe_code": 6101,
        "province_code": 61
    },
    {
        "district": "หนองเต่า",
        "amphoe": "เมืองอุทัยธานี",
        "province": "อุทัยธานี",
        "zipcode": 61000,
        "district_code": 610110,
        "amphoe_code": 6101,
        "province_code": 61
    },
    {
        "district": "หนองแก",
        "amphoe": "เมืองอุทัยธานี",
        "province": "อุทัยธานี",
        "zipcode": 61000,
        "district_code": 610108,
        "amphoe_code": 6101,
        "province_code": 61
    },
    {
        "district": "หนองไผ่แบน",
        "amphoe": "เมืองอุทัยธานี",
        "province": "อุทัยธานี",
        "zipcode": 61000,
        "district_code": 610111,
        "amphoe_code": 6101,
        "province_code": 61
    },
    {
        "district": "หาดทนง",
        "amphoe": "เมืองอุทัยธานี",
        "province": "อุทัยธานี",
        "zipcode": 61000,
        "district_code": 610105,
        "amphoe_code": 6101,
        "province_code": 61
    },
    {
        "district": "อุทัยใหม่",
        "amphoe": "เมืองอุทัยธานี",
        "province": "อุทัยธานี",
        "zipcode": 61000,
        "district_code": 610101,
        "amphoe_code": 6101,
        "province_code": 61
    },
    {
        "district": "เกาะเทโพ",
        "amphoe": "เมืองอุทัยธานี",
        "province": "อุทัยธานี",
        "zipcode": 61000,
        "district_code": 610106,
        "amphoe_code": 6101,
        "province_code": 61
    },
    {
        "district": "เนินแจง",
        "amphoe": "เมืองอุทัยธานี",
        "province": "อุทัยธานี",
        "zipcode": 61000,
        "district_code": 610114,
        "amphoe_code": 6101,
        "province_code": 61
    },
    {
        "district": "โนนเหล็ก",
        "amphoe": "เมืองอุทัยธานี",
        "province": "อุทัยธานี",
        "zipcode": 61000,
        "district_code": 610109,
        "amphoe_code": 6101,
        "province_code": 61
    },
    {
        "district": "กาบิน",
        "amphoe": "กุดข้าวปุ้น",
        "province": "อุบลราชธานี",
        "zipcode": 34270,
        "district_code": 341204,
        "amphoe_code": 3412,
        "province_code": 34
    },
    {
        "district": "ข้าวปุ้น",
        "amphoe": "กุดข้าวปุ้น",
        "province": "อุบลราชธานี",
        "zipcode": 34270,
        "district_code": 341201,
        "amphoe_code": 3412,
        "province_code": 34
    },
    {
        "district": "หนองทันน้ำ",
        "amphoe": "กุดข้าวปุ้น",
        "province": "อุบลราชธานี",
        "zipcode": 34270,
        "district_code": 341205,
        "amphoe_code": 3412,
        "province_code": 34
    },
    {
        "district": "แก่งเค็ง",
        "amphoe": "กุดข้าวปุ้น",
        "province": "อุบลราชธานี",
        "zipcode": 34270,
        "district_code": 341203,
        "amphoe_code": 3412,
        "province_code": 34
    },
    {
        "district": "โนนสวาง",
        "amphoe": "กุดข้าวปุ้น",
        "province": "อุบลราชธานี",
        "zipcode": 34270,
        "district_code": 341202,
        "amphoe_code": 3412,
        "province_code": 34
    },
    {
        "district": "คำไฮใหญ่",
        "amphoe": "ดอนมดแดง",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340110,
        "amphoe_code": 3424,
        "province_code": 34
    },
    {
        "district": "ดอนมดแดง",
        "amphoe": "ดอนมดแดง",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340106,
        "amphoe_code": 3424,
        "province_code": 34
    },
    {
        "district": "ท่าเมือง",
        "amphoe": "ดอนมดแดง",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340103,
        "amphoe_code": 3424,
        "province_code": 34
    },
    {
        "district": "เหล่าแดง",
        "amphoe": "ดอนมดแดง",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340114,
        "amphoe_code": 3424,
        "province_code": 34
    },
    {
        "district": "กระเดียน",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341102,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "กุดยาลวน",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341121,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "กุศกร",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341104,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "ขามเปี้ย",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341105,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "ขุหลุ",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341101,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "คอนสาย",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341106,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "คำเจริญ",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341123,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "ตระการ",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341111,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "ตากแดด",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341112,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "ถ้ำแข้",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341118,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "ท่าหลวง",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341119,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "นาพิน",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341108,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "นาสะไม",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341109,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "บ้านแดง",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341122,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "สะพือ",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341116,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "หนองเต่า",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341117,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "ห้วยฝ้ายพัฒนา",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341120,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "เกษม",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341103,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "เซเป็ด",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341115,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "เป้า",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341114,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "โคกจาน",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341107,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "โนนกุง",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341110,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "ไหล่ทุ่ง",
        "amphoe": "ตระการพืชผล",
        "province": "อุบลราชธานี",
        "zipcode": 34130,
        "district_code": 341113,
        "amphoe_code": 3411,
        "province_code": 34
    },
    {
        "district": "คำหว้า",
        "amphoe": "ตาลสุม",
        "province": "อุบลราชธานี",
        "zipcode": 34330,
        "district_code": 342006,
        "amphoe_code": 3420,
        "province_code": 34
    },
    {
        "district": "จิกเทิง",
        "amphoe": "ตาลสุม",
        "province": "อุบลราชธานี",
        "zipcode": 34330,
        "district_code": 342003,
        "amphoe_code": 3420,
        "province_code": 34
    },
    {
        "district": "ตาลสุม",
        "amphoe": "ตาลสุม",
        "province": "อุบลราชธานี",
        "zipcode": 34330,
        "district_code": 342001,
        "amphoe_code": 3420,
        "province_code": 34
    },
    {
        "district": "นาคาย",
        "amphoe": "ตาลสุม",
        "province": "อุบลราชธานี",
        "zipcode": 34330,
        "district_code": 342005,
        "amphoe_code": 3420,
        "province_code": 34
    },
    {
        "district": "สำโรง",
        "amphoe": "ตาลสุม",
        "province": "อุบลราชธานี",
        "zipcode": 34330,
        "district_code": 341513,
        "amphoe_code": 3420,
        "province_code": 34
    },
    {
        "district": "หนองกุง",
        "amphoe": "ตาลสุม",
        "province": "อุบลราชธานี",
        "zipcode": 34330,
        "district_code": 342004,
        "amphoe_code": 3420,
        "province_code": 34
    },
    {
        "district": "กุดเรือ",
        "amphoe": "ทุ่งศรีอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340718,
        "amphoe_code": 3426,
        "province_code": 34
    },
    {
        "district": "นาห่อม",
        "amphoe": "ทุ่งศรีอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 342606,
        "amphoe_code": 3426,
        "province_code": 34
    },
    {
        "district": "นาเกษม",
        "amphoe": "ทุ่งศรีอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340714,
        "amphoe_code": 3426,
        "province_code": 34
    },
    {
        "district": "หนองอ้ม",
        "amphoe": "ทุ่งศรีอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340707,
        "amphoe_code": 3426,
        "province_code": 34
    },
    {
        "district": "โคกชำแระ",
        "amphoe": "ทุ่งศรีอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340722,
        "amphoe_code": 3426,
        "province_code": 34
    },
    {
        "district": "นาจะหลวย",
        "amphoe": "นาจะหลวย",
        "province": "อุบลราชธานี",
        "zipcode": 34280,
        "district_code": 340801,
        "amphoe_code": 3408,
        "province_code": 34
    },
    {
        "district": "บ้านตูม",
        "amphoe": "นาจะหลวย",
        "province": "อุบลราชธานี",
        "zipcode": 34280,
        "district_code": 340804,
        "amphoe_code": 3408,
        "province_code": 34
    },
    {
        "district": "พรสวรรค์",
        "amphoe": "นาจะหลวย",
        "province": "อุบลราชธานี",
        "zipcode": 34280,
        "district_code": 340803,
        "amphoe_code": 3408,
        "province_code": 34
    },
    {
        "district": "โนนสมบูรณ์",
        "amphoe": "นาจะหลวย",
        "province": "อุบลราชธานี",
        "zipcode": 34280,
        "district_code": 340723,
        "amphoe_code": 3408,
        "province_code": 34
    },
    {
        "district": "โนนสวรรค์",
        "amphoe": "นาจะหลวย",
        "province": "อุบลราชธานี",
        "zipcode": 34280,
        "district_code": 340806,
        "amphoe_code": 3408,
        "province_code": 34
    },
    {
        "district": "โสกแสง",
        "amphoe": "นาจะหลวย",
        "province": "อุบลราชธานี",
        "zipcode": 34280,
        "district_code": 340805,
        "amphoe_code": 3408,
        "province_code": 34
    },
    {
        "district": "กองโพน",
        "amphoe": "นาตาล",
        "province": "อุบลราชธานี",
        "zipcode": 34170,
        "district_code": 340502,
        "amphoe_code": 3430,
        "province_code": 34
    },
    {
        "district": "นาตาล",
        "amphoe": "นาตาล",
        "province": "อุบลราชธานี",
        "zipcode": 34170,
        "district_code": 340506,
        "amphoe_code": 3430,
        "province_code": 34
    },
    {
        "district": "พะลาน",
        "amphoe": "นาตาล",
        "province": "อุบลราชธานี",
        "zipcode": 34170,
        "district_code": 340509,
        "amphoe_code": 3430,
        "province_code": 34
    },
    {
        "district": "พังเคน",
        "amphoe": "นาตาล",
        "province": "อุบลราชธานี",
        "zipcode": 34170,
        "district_code": 340505,
        "amphoe_code": 3430,
        "province_code": 34
    },
    {
        "district": "นาดี",
        "amphoe": "นาเยีย",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340719,
        "amphoe_code": 3429,
        "province_code": 34
    },
    {
        "district": "นาเยีย",
        "amphoe": "นาเยีย",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340703,
        "amphoe_code": 3429,
        "province_code": 34
    },
    {
        "district": "นาเรือง",
        "amphoe": "นาเยีย",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340705,
        "amphoe_code": 3429,
        "province_code": 34
    },
    {
        "district": "ขี้เหล็ก",
        "amphoe": "น้ำขุ่น",
        "province": "อุบลราชธานี",
        "zipcode": 34260,
        "district_code": 340119,
        "amphoe_code": 3433,
        "province_code": 34
    },
    {
        "district": "ตาเกา",
        "amphoe": "น้ำขุ่น",
        "province": "อุบลราชธานี",
        "zipcode": 34260,
        "district_code": 340902,
        "amphoe_code": 3433,
        "province_code": 34
    },
    {
        "district": "โคกสะอาด",
        "amphoe": "น้ำขุ่น",
        "province": "อุบลราชธานี",
        "zipcode": 34260,
        "district_code": 340910,
        "amphoe_code": 3433,
        "province_code": 34
    },
    {
        "district": "ไพบูลย์",
        "amphoe": "น้ำขุ่น",
        "province": "อุบลราชธานี",
        "zipcode": 34260,
        "district_code": 340908,
        "amphoe_code": 3433,
        "province_code": 34
    },
    {
        "district": "บุเปือย",
        "amphoe": "น้ำยืน",
        "province": "อุบลราชธานี",
        "zipcode": 34260,
        "district_code": 340906,
        "amphoe_code": 3409,
        "province_code": 34
    },
    {
        "district": "ยาง",
        "amphoe": "น้ำยืน",
        "province": "อุบลราชธานี",
        "zipcode": 34260,
        "district_code": 340903,
        "amphoe_code": 3409,
        "province_code": 34
    },
    {
        "district": "ยางใหญ่",
        "amphoe": "น้ำยืน",
        "province": "อุบลราชธานี",
        "zipcode": 34260,
        "district_code": 340909,
        "amphoe_code": 3409,
        "province_code": 34
    },
    {
        "district": "สีวิเชียร",
        "amphoe": "น้ำยืน",
        "province": "อุบลราชธานี",
        "zipcode": 34260,
        "district_code": 340907,
        "amphoe_code": 3409,
        "province_code": 34
    },
    {
        "district": "เก่าขาม",
        "amphoe": "น้ำยืน",
        "province": "อุบลราชธานี",
        "zipcode": 34260,
        "district_code": 340911,
        "amphoe_code": 3409,
        "province_code": 34
    },
    {
        "district": "โซง",
        "amphoe": "น้ำยืน",
        "province": "อุบลราชธานี",
        "zipcode": 34260,
        "district_code": 340901,
        "amphoe_code": 3409,
        "province_code": 34
    },
    {
        "district": "โดมประดิษฐ์",
        "amphoe": "น้ำยืน",
        "province": "อุบลราชธานี",
        "zipcode": 34260,
        "district_code": 340904,
        "amphoe_code": 3409,
        "province_code": 34
    },
    {
        "district": "คอแลน",
        "amphoe": "บุณฑริก",
        "province": "อุบลราชธานี",
        "zipcode": 34230,
        "district_code": 341003,
        "amphoe_code": 3410,
        "province_code": 34
    },
    {
        "district": "นาโพธิ์",
        "amphoe": "บุณฑริก",
        "province": "อุบลราชธานี",
        "zipcode": 34230,
        "district_code": 341004,
        "amphoe_code": 3410,
        "province_code": 34
    },
    {
        "district": "บัวงาม",
        "amphoe": "บุณฑริก",
        "province": "อุบลราชธานี",
        "zipcode": 34230,
        "district_code": 340715,
        "amphoe_code": 3410,
        "province_code": 34
    },
    {
        "district": "บ้านแมด",
        "amphoe": "บุณฑริก",
        "province": "อุบลราชธานี",
        "zipcode": 34230,
        "district_code": 341008,
        "amphoe_code": 3410,
        "province_code": 34
    },
    {
        "district": "หนองสะโน",
        "amphoe": "บุณฑริก",
        "province": "อุบลราชธานี",
        "zipcode": 34230,
        "district_code": 341005,
        "amphoe_code": 3410,
        "province_code": 34
    },
    {
        "district": "ห้วยข่า",
        "amphoe": "บุณฑริก",
        "province": "อุบลราชธานี",
        "zipcode": 34230,
        "district_code": 341002,
        "amphoe_code": 3410,
        "province_code": 34
    },
    {
        "district": "โนนค้อ",
        "amphoe": "บุณฑริก",
        "province": "อุบลราชธานี",
        "zipcode": 34230,
        "district_code": 341006,
        "amphoe_code": 3410,
        "province_code": 34
    },
    {
        "district": "โพนงาม",
        "amphoe": "บุณฑริก",
        "province": "อุบลราชธานี",
        "zipcode": 34230,
        "district_code": 340720,
        "amphoe_code": 3410,
        "province_code": 34
    },
    {
        "district": "กุดชมภู",
        "amphoe": "พิบูลมังสาหาร",
        "province": "อุบลราชธานี",
        "zipcode": 34110,
        "district_code": 341902,
        "amphoe_code": 3419,
        "province_code": 34
    },
    {
        "district": "ดอนจิก",
        "amphoe": "พิบูลมังสาหาร",
        "province": "อุบลราชธานี",
        "zipcode": 34110,
        "district_code": 341904,
        "amphoe_code": 3419,
        "province_code": 34
    },
    {
        "district": "ทรายมูล",
        "amphoe": "พิบูลมังสาหาร",
        "province": "อุบลราชธานี",
        "zipcode": 34110,
        "district_code": 341905,
        "amphoe_code": 3419,
        "province_code": 34
    },
    {
        "district": "นาโพธิ์",
        "amphoe": "พิบูลมังสาหาร",
        "province": "อุบลราชธานี",
        "zipcode": 34110,
        "district_code": 341004,
        "amphoe_code": 3419,
        "province_code": 34
    },
    {
        "district": "บ้านแขม",
        "amphoe": "พิบูลมังสาหาร",
        "province": "อุบลราชธานี",
        "zipcode": 34110,
        "district_code": 341919,
        "amphoe_code": 3419,
        "province_code": 34
    },
    {
        "district": "พิบูล",
        "amphoe": "พิบูลมังสาหาร",
        "province": "อุบลราชธานี",
        "zipcode": 34110,
        "district_code": 341901,
        "amphoe_code": 3419,
        "province_code": 34
    },
    {
        "district": "ระเว",
        "amphoe": "พิบูลมังสาหาร",
        "province": "อุบลราชธานี",
        "zipcode": 34110,
        "district_code": 341911,
        "amphoe_code": 3419,
        "province_code": 34
    },
    {
        "district": "หนองบัวฮี",
        "amphoe": "พิบูลมังสาหาร",
        "province": "อุบลราชธานี",
        "zipcode": 34110,
        "district_code": 341913,
        "amphoe_code": 3419,
        "province_code": 34
    },
    {
        "district": "อ่างศิลา",
        "amphoe": "พิบูลมังสาหาร",
        "province": "อุบลราชธานี",
        "zipcode": 34110,
        "district_code": 341914,
        "amphoe_code": 3419,
        "province_code": 34
    },
    {
        "district": "โนนกลาง",
        "amphoe": "พิบูลมังสาหาร",
        "province": "อุบลราชธานี",
        "zipcode": 34110,
        "district_code": 341907,
        "amphoe_code": 3419,
        "province_code": 34
    },
    {
        "district": "โนนกาหลง",
        "amphoe": "พิบูลมังสาหาร",
        "province": "อุบลราชธานี",
        "zipcode": 34110,
        "district_code": 341918,
        "amphoe_code": 3419,
        "province_code": 34
    },
    {
        "district": "โพธิ์ศรี",
        "amphoe": "พิบูลมังสาหาร",
        "province": "อุบลราชธานี",
        "zipcode": 34110,
        "district_code": 341910,
        "amphoe_code": 3419,
        "province_code": 34
    },
    {
        "district": "โพธิ์ไทร",
        "amphoe": "พิบูลมังสาหาร",
        "province": "อุบลราชธานี",
        "zipcode": 34110,
        "district_code": 341909,
        "amphoe_code": 3419,
        "province_code": 34
    },
    {
        "district": "ไร่ใต้",
        "amphoe": "พิบูลมังสาหาร",
        "province": "อุบลราชธานี",
        "zipcode": 34110,
        "district_code": 341912,
        "amphoe_code": 3419,
        "province_code": 34
    },
    {
        "district": "ดุมใหญ่",
        "amphoe": "ม่วงสามสิบ",
        "province": "อุบลราชธานี",
        "zipcode": 34140,
        "district_code": 341403,
        "amphoe_code": 3414,
        "province_code": 34
    },
    {
        "district": "นาเลิง",
        "amphoe": "ม่วงสามสิบ",
        "province": "อุบลราชธานี",
        "zipcode": 34140,
        "district_code": 341413,
        "amphoe_code": 3414,
        "province_code": 34
    },
    {
        "district": "ม่วงสามสิบ",
        "amphoe": "ม่วงสามสิบ",
        "province": "อุบลราชธานี",
        "zipcode": 34140,
        "district_code": 341401,
        "amphoe_code": 3414,
        "province_code": 34
    },
    {
        "district": "ยางสักกระโพหลุ่ม",
        "amphoe": "ม่วงสามสิบ",
        "province": "อุบลราชธานี",
        "zipcode": 34140,
        "district_code": 341407,
        "amphoe_code": 3414,
        "province_code": 34
    },
    {
        "district": "ยางโยภาพ",
        "amphoe": "ม่วงสามสิบ",
        "province": "อุบลราชธานี",
        "zipcode": 34140,
        "district_code": 341411,
        "amphoe_code": 3414,
        "province_code": 34
    },
    {
        "district": "หนองช้างใหญ่",
        "amphoe": "ม่วงสามสิบ",
        "province": "อุบลราชธานี",
        "zipcode": 34140,
        "district_code": 341404,
        "amphoe_code": 3414,
        "province_code": 34
    },
    {
        "district": "หนองฮาง",
        "amphoe": "ม่วงสามสิบ",
        "province": "อุบลราชธานี",
        "zipcode": 34140,
        "district_code": 341410,
        "amphoe_code": 3414,
        "province_code": 34
    },
    {
        "district": "หนองเมือง",
        "amphoe": "ม่วงสามสิบ",
        "province": "อุบลราชธานี",
        "zipcode": 34140,
        "district_code": 341405,
        "amphoe_code": 3414,
        "province_code": 34
    },
    {
        "district": "หนองเหล่า",
        "amphoe": "ม่วงสามสิบ",
        "province": "อุบลราชธานี",
        "zipcode": 34140,
        "district_code": 340418,
        "amphoe_code": 3414,
        "province_code": 34
    },
    {
        "district": "หนองไข่นก",
        "amphoe": "ม่วงสามสิบ",
        "province": "อุบลราชธานี",
        "zipcode": 34140,
        "district_code": 341408,
        "amphoe_code": 3414,
        "province_code": 34
    },
    {
        "district": "เตย",
        "amphoe": "ม่วงสามสิบ",
        "province": "อุบลราชธานี",
        "zipcode": 34140,
        "district_code": 341406,
        "amphoe_code": 3414,
        "province_code": 34
    },
    {
        "district": "เหล่าบก",
        "amphoe": "ม่วงสามสิบ",
        "province": "อุบลราชธานี",
        "zipcode": 34140,
        "district_code": 341402,
        "amphoe_code": 3414,
        "province_code": 34
    },
    {
        "district": "โพนแพง",
        "amphoe": "ม่วงสามสิบ",
        "province": "อุบลราชธานี",
        "zipcode": 34140,
        "district_code": 341414,
        "amphoe_code": 3414,
        "province_code": 34
    },
    {
        "district": "ไผ่ใหญ่",
        "amphoe": "ม่วงสามสิบ",
        "province": "อุบลราชธานี",
        "zipcode": 34140,
        "district_code": 341412,
        "amphoe_code": 3414,
        "province_code": 34
    },
    {
        "district": "คำขวาง",
        "amphoe": "วารินชำราบ",
        "province": "อุบลราชธานี",
        "zipcode": 34190,
        "district_code": 341515,
        "amphoe_code": 3415,
        "province_code": 34
    },
    {
        "district": "คำน้ำแซบ",
        "amphoe": "วารินชำราบ",
        "province": "อุบลราชธานี",
        "zipcode": 34190,
        "district_code": 341510,
        "amphoe_code": 3415,
        "province_code": 34
    },
    {
        "district": "คูเมือง",
        "amphoe": "วารินชำราบ",
        "province": "อุบลราชธานี",
        "zipcode": 34190,
        "district_code": 341507,
        "amphoe_code": 3415,
        "province_code": 34
    },
    {
        "district": "ท่าลาด",
        "amphoe": "วารินชำราบ",
        "province": "อุบลราชธานี",
        "zipcode": 34310,
        "district_code": 341504,
        "amphoe_code": 3415,
        "province_code": 34
    },
    {
        "district": "ธาตุ",
        "amphoe": "วารินชำราบ",
        "province": "อุบลราชธานี",
        "zipcode": 34190,
        "district_code": 341502,
        "amphoe_code": 3415,
        "province_code": 34
    },
    {
        "district": "บุ่งหวาย",
        "amphoe": "วารินชำราบ",
        "province": "อุบลราชธานี",
        "zipcode": 34310,
        "district_code": 341511,
        "amphoe_code": 3415,
        "province_code": 34
    },
    {
        "district": "บุ่งไหม",
        "amphoe": "วารินชำราบ",
        "province": "อุบลราชธานี",
        "zipcode": 34190,
        "district_code": 341526,
        "amphoe_code": 3415,
        "province_code": 34
    },
    {
        "district": "วารินชำราบ",
        "amphoe": "วารินชำราบ",
        "province": "อุบลราชธานี",
        "zipcode": 34190,
        "district_code": 341501,
        "amphoe_code": 3415,
        "province_code": 34
    },
    {
        "district": "สระสมิง",
        "amphoe": "วารินชำราบ",
        "province": "อุบลราชธานี",
        "zipcode": 34190,
        "district_code": 341508,
        "amphoe_code": 3415,
        "province_code": 34
    },
    {
        "district": "หนองกินเพล",
        "amphoe": "วารินชำราบ",
        "province": "อุบลราชธานี",
        "zipcode": 34190,
        "district_code": 341520,
        "amphoe_code": 3415,
        "province_code": 34
    },
    {
        "district": "ห้วยขะยูง",
        "amphoe": "วารินชำราบ",
        "province": "อุบลราชธานี",
        "zipcode": 34310,
        "district_code": 341524,
        "amphoe_code": 3415,
        "province_code": 34
    },
    {
        "district": "เมืองศรีไค",
        "amphoe": "วารินชำราบ",
        "province": "อุบลราชธานี",
        "zipcode": 34190,
        "district_code": 341522,
        "amphoe_code": 3415,
        "province_code": 34
    },
    {
        "district": "แสนสุข",
        "amphoe": "วารินชำราบ",
        "province": "อุบลราชธานี",
        "zipcode": 34190,
        "district_code": 341518,
        "amphoe_code": 3415,
        "province_code": 34
    },
    {
        "district": "โนนผึ้ง",
        "amphoe": "วารินชำราบ",
        "province": "อุบลราชธานี",
        "zipcode": 34190,
        "district_code": 341521,
        "amphoe_code": 3415,
        "province_code": 34
    },
    {
        "district": "โนนโหนน",
        "amphoe": "วารินชำราบ",
        "province": "อุบลราชธานี",
        "zipcode": 34190,
        "district_code": 341505,
        "amphoe_code": 3415,
        "province_code": 34
    },
    {
        "district": "โพธิ์ใหญ่",
        "amphoe": "วารินชำราบ",
        "province": "อุบลราชธานี",
        "zipcode": 34190,
        "district_code": 341516,
        "amphoe_code": 3415,
        "province_code": 34
    },
    {
        "district": "คำไหล",
        "amphoe": "ศรีเมืองใหม่",
        "province": "อุบลราชธานี",
        "zipcode": 34250,
        "district_code": 340208,
        "amphoe_code": 3402,
        "province_code": 34
    },
    {
        "district": "ดอนใหญ่",
        "amphoe": "ศรีเมืองใหม่",
        "province": "อุบลราชธานี",
        "zipcode": 34250,
        "district_code": 340211,
        "amphoe_code": 3402,
        "province_code": 34
    },
    {
        "district": "ตะบ่าย",
        "amphoe": "ศรีเมืองใหม่",
        "province": "อุบลราชธานี",
        "zipcode": 34250,
        "district_code": 340207,
        "amphoe_code": 3402,
        "province_code": 34
    },
    {
        "district": "นาคำ",
        "amphoe": "ศรีเมืองใหม่",
        "province": "อุบลราชธานี",
        "zipcode": 34250,
        "district_code": 340201,
        "amphoe_code": 3402,
        "province_code": 34
    },
    {
        "district": "นาเลิน",
        "amphoe": "ศรีเมืองใหม่",
        "province": "อุบลราชธานี",
        "zipcode": 34250,
        "district_code": 340210,
        "amphoe_code": 3402,
        "province_code": 34
    },
    {
        "district": "ลาดควาย",
        "amphoe": "ศรีเมืองใหม่",
        "province": "อุบลราชธานี",
        "zipcode": 34250,
        "district_code": 340205,
        "amphoe_code": 3402,
        "province_code": 34
    },
    {
        "district": "วาริน",
        "amphoe": "ศรีเมืองใหม่",
        "province": "อุบลราชธานี",
        "zipcode": 34250,
        "district_code": 340204,
        "amphoe_code": 3402,
        "province_code": 34
    },
    {
        "district": "สงยาง",
        "amphoe": "ศรีเมืองใหม่",
        "province": "อุบลราชธานี",
        "zipcode": 34250,
        "district_code": 340206,
        "amphoe_code": 3402,
        "province_code": 34
    },
    {
        "district": "หนามแท่ง",
        "amphoe": "ศรีเมืองใหม่",
        "province": "อุบลราชธานี",
        "zipcode": 34250,
        "district_code": 340209,
        "amphoe_code": 3402,
        "province_code": 34
    },
    {
        "district": "เอือดใหญ่",
        "amphoe": "ศรีเมืองใหม่",
        "province": "อุบลราชธานี",
        "zipcode": 34250,
        "district_code": 340203,
        "amphoe_code": 3402,
        "province_code": 34
    },
    {
        "district": "แก้งกอก",
        "amphoe": "ศรีเมืองใหม่",
        "province": "อุบลราชธานี",
        "zipcode": 34250,
        "district_code": 340202,
        "amphoe_code": 3402,
        "province_code": 34
    },
    {
        "district": "ท่าช้าง",
        "amphoe": "สว่างวีระวงศ์",
        "province": "อุบลราชธานี",
        "zipcode": 34190,
        "district_code": 341503,
        "amphoe_code": 3432,
        "province_code": 34
    },
    {
        "district": "บุ่งมะแลง",
        "amphoe": "สว่างวีระวงศ์",
        "province": "อุบลราชธานี",
        "zipcode": 34190,
        "district_code": 341523,
        "amphoe_code": 3432,
        "province_code": 34
    },
    {
        "district": "สว่าง",
        "amphoe": "สว่างวีระวงศ์",
        "province": "อุบลราชธานี",
        "zipcode": 34190,
        "district_code": 341514,
        "amphoe_code": 3432,
        "province_code": 34
    },
    {
        "district": "แก่งโดม",
        "amphoe": "สว่างวีระวงศ์",
        "province": "อุบลราชธานี",
        "zipcode": 34190,
        "district_code": 341525,
        "amphoe_code": 3432,
        "province_code": 34
    },
    {
        "district": "ขามป้อม",
        "amphoe": "สำโรง",
        "province": "อุบลราชธานี",
        "zipcode": 34360,
        "district_code": 340503,
        "amphoe_code": 3422,
        "province_code": 34
    },
    {
        "district": "ค้อน้อย",
        "amphoe": "สำโรง",
        "province": "อุบลราชธานี",
        "zipcode": 34360,
        "district_code": 341509,
        "amphoe_code": 3422,
        "province_code": 34
    },
    {
        "district": "บอน",
        "amphoe": "สำโรง",
        "province": "อุบลราชธานี",
        "zipcode": 34360,
        "district_code": 342208,
        "amphoe_code": 3422,
        "province_code": 34
    },
    {
        "district": "สำโรง",
        "amphoe": "สำโรง",
        "province": "อุบลราชธานี",
        "zipcode": 34360,
        "district_code": 341513,
        "amphoe_code": 3422,
        "province_code": 34
    },
    {
        "district": "หนองไฮ",
        "amphoe": "สำโรง",
        "province": "อุบลราชธานี",
        "zipcode": 34360,
        "district_code": 341512,
        "amphoe_code": 3422,
        "province_code": 34
    },
    {
        "district": "โคกก่อง",
        "amphoe": "สำโรง",
        "province": "อุบลราชธานี",
        "zipcode": 34360,
        "district_code": 341517,
        "amphoe_code": 3422,
        "province_code": 34
    },
    {
        "district": "โคกสว่าง",
        "amphoe": "สำโรง",
        "province": "อุบลราชธานี",
        "zipcode": 34360,
        "district_code": 341519,
        "amphoe_code": 3422,
        "province_code": 34
    },
    {
        "district": "โนนกลาง",
        "amphoe": "สำโรง",
        "province": "อุบลราชธานี",
        "zipcode": 34360,
        "district_code": 341907,
        "amphoe_code": 3422,
        "province_code": 34
    },
    {
        "district": "โนนกาเล็น",
        "amphoe": "สำโรง",
        "province": "อุบลราชธานี",
        "zipcode": 34360,
        "district_code": 341506,
        "amphoe_code": 3422,
        "province_code": 34
    },
    {
        "district": "คันไร่",
        "amphoe": "สิรินธร",
        "province": "อุบลราชธานี",
        "zipcode": 34350,
        "district_code": 341903,
        "amphoe_code": 3425,
        "province_code": 34
    },
    {
        "district": "คำเขื่อนแก้ว",
        "amphoe": "สิรินธร",
        "province": "อุบลราชธานี",
        "zipcode": 34350,
        "district_code": 340306,
        "amphoe_code": 3425,
        "province_code": 34
    },
    {
        "district": "ช่องเม็ก",
        "amphoe": "สิรินธร",
        "province": "อุบลราชธานี",
        "zipcode": 34350,
        "district_code": 341916,
        "amphoe_code": 3425,
        "province_code": 34
    },
    {
        "district": "นิคมสร้างตนเองลำโดมน้อย",
        "amphoe": "สิรินธร",
        "province": "อุบลราชธานี",
        "zipcode": 34350,
        "district_code": 342504,
        "amphoe_code": 3425,
        "province_code": 34
    },
    {
        "district": "ฝางคำ",
        "amphoe": "สิรินธร",
        "province": "อุบลราชธานี",
        "zipcode": 34350,
        "district_code": 341908,
        "amphoe_code": 3425,
        "province_code": 34
    },
    {
        "district": "โนนก่อ",
        "amphoe": "สิรินธร",
        "province": "อุบลราชธานี",
        "zipcode": 34350,
        "district_code": 341917,
        "amphoe_code": 3425,
        "province_code": 34
    },
    {
        "district": "ขามป้อม",
        "amphoe": "เขมราฐ",
        "province": "อุบลราชธานี",
        "zipcode": 34170,
        "district_code": 340503,
        "amphoe_code": 3405,
        "province_code": 34
    },
    {
        "district": "นาแวง",
        "amphoe": "เขมราฐ",
        "province": "อุบลราชธานี",
        "zipcode": 34170,
        "district_code": 340508,
        "amphoe_code": 3405,
        "province_code": 34
    },
    {
        "district": "หนองนกทา",
        "amphoe": "เขมราฐ",
        "province": "อุบลราชธานี",
        "zipcode": 34170,
        "district_code": 340511,
        "amphoe_code": 3405,
        "province_code": 34
    },
    {
        "district": "หนองผือ",
        "amphoe": "เขมราฐ",
        "province": "อุบลราชธานี",
        "zipcode": 34170,
        "district_code": 340507,
        "amphoe_code": 3405,
        "province_code": 34
    },
    {
        "district": "หนองสิม",
        "amphoe": "เขมราฐ",
        "province": "อุบลราชธานี",
        "zipcode": 34170,
        "district_code": 340512,
        "amphoe_code": 3405,
        "province_code": 34
    },
    {
        "district": "หัวนา",
        "amphoe": "เขมราฐ",
        "province": "อุบลราชธานี",
        "zipcode": 34170,
        "district_code": 340513,
        "amphoe_code": 3405,
        "province_code": 34
    },
    {
        "district": "เขมราฐ",
        "amphoe": "เขมราฐ",
        "province": "อุบลราชธานี",
        "zipcode": 34170,
        "district_code": 340501,
        "amphoe_code": 3405,
        "province_code": 34
    },
    {
        "district": "เจียด",
        "amphoe": "เขมราฐ",
        "province": "อุบลราชธานี",
        "zipcode": 34170,
        "district_code": 340504,
        "amphoe_code": 3405,
        "province_code": 34
    },
    {
        "district": "แก้งเหนือ",
        "amphoe": "เขมราฐ",
        "province": "อุบลราชธานี",
        "zipcode": 34170,
        "district_code": 340510,
        "amphoe_code": 3405,
        "province_code": 34
    },
    {
        "district": "กลางใหญ่",
        "amphoe": "เขื่องใน",
        "province": "อุบลราชธานี",
        "zipcode": 34320,
        "district_code": 340413,
        "amphoe_code": 3404,
        "province_code": 34
    },
    {
        "district": "ก่อเอ้",
        "amphoe": "เขื่องใน",
        "province": "อุบลราชธานี",
        "zipcode": 34150,
        "district_code": 340404,
        "amphoe_code": 3404,
        "province_code": 34
    },
    {
        "district": "ค้อทอง",
        "amphoe": "เขื่องใน",
        "province": "อุบลราชธานี",
        "zipcode": 34150,
        "district_code": 340403,
        "amphoe_code": 3404,
        "province_code": 34
    },
    {
        "district": "ชีทวน",
        "amphoe": "เขื่องใน",
        "province": "อุบลราชธานี",
        "zipcode": 34150,
        "district_code": 340406,
        "amphoe_code": 3404,
        "province_code": 34
    },
    {
        "district": "ท่าไห",
        "amphoe": "เขื่องใน",
        "province": "อุบลราชธานี",
        "zipcode": 34150,
        "district_code": 340407,
        "amphoe_code": 3404,
        "province_code": 34
    },
    {
        "district": "ธาตุน้อย",
        "amphoe": "เขื่องใน",
        "province": "อุบลราชธานี",
        "zipcode": 34150,
        "district_code": 340410,
        "amphoe_code": 3404,
        "province_code": 34
    },
    {
        "district": "นาคำใหญ่",
        "amphoe": "เขื่องใน",
        "province": "อุบลราชธานี",
        "zipcode": 34150,
        "district_code": 340408,
        "amphoe_code": 3404,
        "province_code": 34
    },
    {
        "district": "บ้านกอก",
        "amphoe": "เขื่องใน",
        "province": "อุบลราชธานี",
        "zipcode": 34320,
        "district_code": 340412,
        "amphoe_code": 3404,
        "province_code": 34
    },
    {
        "district": "บ้านไทย",
        "amphoe": "เขื่องใน",
        "province": "อุบลราชธานี",
        "zipcode": 34320,
        "district_code": 340411,
        "amphoe_code": 3404,
        "province_code": 34
    },
    {
        "district": "ยางขี้นก",
        "amphoe": "เขื่องใน",
        "province": "อุบลราชธานี",
        "zipcode": 34150,
        "district_code": 340415,
        "amphoe_code": 3404,
        "province_code": 34
    },
    {
        "district": "ศรีสุข",
        "amphoe": "เขื่องใน",
        "province": "อุบลราชธานี",
        "zipcode": 34150,
        "district_code": 340416,
        "amphoe_code": 3404,
        "province_code": 34
    },
    {
        "district": "สร้างถ่อ",
        "amphoe": "เขื่องใน",
        "province": "อุบลราชธานี",
        "zipcode": 34150,
        "district_code": 340402,
        "amphoe_code": 3404,
        "province_code": 34
    },
    {
        "district": "สหธาตุ",
        "amphoe": "เขื่องใน",
        "province": "อุบลราชธานี",
        "zipcode": 34150,
        "district_code": 340417,
        "amphoe_code": 3404,
        "province_code": 34
    },
    {
        "district": "หนองเหล่า",
        "amphoe": "เขื่องใน",
        "province": "อุบลราชธานี",
        "zipcode": 34150,
        "district_code": 340418,
        "amphoe_code": 3404,
        "province_code": 34
    },
    {
        "district": "หัวดอน",
        "amphoe": "เขื่องใน",
        "province": "อุบลราชธานี",
        "zipcode": 34150,
        "district_code": 340405,
        "amphoe_code": 3404,
        "province_code": 34
    },
    {
        "district": "เขื่องใน",
        "amphoe": "เขื่องใน",
        "province": "อุบลราชธานี",
        "zipcode": 34150,
        "district_code": 340401,
        "amphoe_code": 3404,
        "province_code": 34
    },
    {
        "district": "แดงหม้อ",
        "amphoe": "เขื่องใน",
        "province": "อุบลราชธานี",
        "zipcode": 34150,
        "district_code": 340409,
        "amphoe_code": 3404,
        "province_code": 34
    },
    {
        "district": "โนนรัง",
        "amphoe": "เขื่องใน",
        "province": "อุบลราชธานี",
        "zipcode": 34320,
        "district_code": 340414,
        "amphoe_code": 3404,
        "province_code": 34
    },
    {
        "district": "กลาง",
        "amphoe": "เดชอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340711,
        "amphoe_code": 3407,
        "province_code": 34
    },
    {
        "district": "กุดประทาย",
        "amphoe": "เดชอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340709,
        "amphoe_code": 3407,
        "province_code": 34
    },
    {
        "district": "คำครั่ง",
        "amphoe": "เดชอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340716,
        "amphoe_code": 3407,
        "province_code": 34
    },
    {
        "district": "ตบหู",
        "amphoe": "เดชอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340710,
        "amphoe_code": 3407,
        "province_code": 34
    },
    {
        "district": "ทุ่งเทิง",
        "amphoe": "เดชอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340706,
        "amphoe_code": 3407,
        "province_code": 34
    },
    {
        "district": "ท่าโพธิ์ศรี",
        "amphoe": "เดชอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340713,
        "amphoe_code": 3407,
        "province_code": 34
    },
    {
        "district": "นากระแซง",
        "amphoe": "เดชอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340717,
        "amphoe_code": 3407,
        "province_code": 34
    },
    {
        "district": "นาส่วง",
        "amphoe": "เดชอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340702,
        "amphoe_code": 3407,
        "province_code": 34
    },
    {
        "district": "นาเจริญ",
        "amphoe": "เดชอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340704,
        "amphoe_code": 3407,
        "province_code": 34
    },
    {
        "district": "บัวงาม",
        "amphoe": "เดชอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340715,
        "amphoe_code": 3407,
        "province_code": 34
    },
    {
        "district": "ป่าโมง",
        "amphoe": "เดชอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340721,
        "amphoe_code": 3407,
        "province_code": 34
    },
    {
        "district": "สมสะอาด",
        "amphoe": "เดชอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340708,
        "amphoe_code": 3407,
        "province_code": 34
    },
    {
        "district": "เมืองเดช",
        "amphoe": "เดชอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340701,
        "amphoe_code": 3407,
        "province_code": 34
    },
    {
        "district": "แก้ง",
        "amphoe": "เดชอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340712,
        "amphoe_code": 3407,
        "province_code": 34
    },
    {
        "district": "โนนสมบูรณ์",
        "amphoe": "เดชอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340723,
        "amphoe_code": 3407,
        "province_code": 34
    },
    {
        "district": "โพนงาม",
        "amphoe": "เดชอุดม",
        "province": "อุบลราชธานี",
        "zipcode": 34160,
        "district_code": 340720,
        "amphoe_code": 3407,
        "province_code": 34
    },
    {
        "district": "กระโสบ",
        "amphoe": "เมืองอุบลราชธานี",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340113,
        "amphoe_code": 3401,
        "province_code": 34
    },
    {
        "district": "กุดลาด",
        "amphoe": "เมืองอุบลราชธานี",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340116,
        "amphoe_code": 3401,
        "province_code": 34
    },
    {
        "district": "ขามใหญ่",
        "amphoe": "เมืองอุบลราชธานี",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340108,
        "amphoe_code": 3401,
        "province_code": 34
    },
    {
        "district": "ขี้เหล็ก",
        "amphoe": "เมืองอุบลราชธานี",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340119,
        "amphoe_code": 3401,
        "province_code": 34
    },
    {
        "district": "ปทุม",
        "amphoe": "เมืองอุบลราชธานี",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340107,
        "amphoe_code": 3401,
        "province_code": 34
    },
    {
        "district": "ปะอาว",
        "amphoe": "เมืองอุบลราชธานี",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340120,
        "amphoe_code": 3401,
        "province_code": 34
    },
    {
        "district": "หนองขอน",
        "amphoe": "เมืองอุบลราชธานี",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340105,
        "amphoe_code": 3401,
        "province_code": 34
    },
    {
        "district": "หนองบ่อ",
        "amphoe": "เมืองอุบลราชธานี",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340111,
        "amphoe_code": 3401,
        "province_code": 34
    },
    {
        "district": "หัวเรือ",
        "amphoe": "เมืองอุบลราชธานี",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340104,
        "amphoe_code": 3401,
        "province_code": 34
    },
    {
        "district": "แจระแม",
        "amphoe": "เมืองอุบลราชธานี",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340109,
        "amphoe_code": 3401,
        "province_code": 34
    },
    {
        "district": "ในเมือง",
        "amphoe": "เมืองอุบลราชธานี",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340101,
        "amphoe_code": 3401,
        "province_code": 34
    },
    {
        "district": "ไร่น้อย",
        "amphoe": "เมืองอุบลราชธานี",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340112,
        "amphoe_code": 3401,
        "province_code": 34
    },
    {
        "district": "หนองบก",
        "amphoe": "เหล่าเสือโก้ก",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340117,
        "amphoe_code": 3431,
        "province_code": 34
    },
    {
        "district": "เหล่าเสือโก้ก",
        "amphoe": "เหล่าเสือโก้ก",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340115,
        "amphoe_code": 3431,
        "province_code": 34
    },
    {
        "district": "แพงใหญ่",
        "amphoe": "เหล่าเสือโก้ก",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340118,
        "amphoe_code": 3431,
        "province_code": 34
    },
    {
        "district": "โพนเมือง",
        "amphoe": "เหล่าเสือโก้ก",
        "province": "อุบลราชธานี",
        "zipcode": 34000,
        "district_code": 340102,
        "amphoe_code": 3431,
        "province_code": 34
    },
    {
        "district": "นาโพธิ์กลาง",
        "amphoe": "โขงเจียม",
        "province": "อุบลราชธานี",
        "zipcode": 34220,
        "district_code": 340303,
        "amphoe_code": 3403,
        "province_code": 34
    },
    {
        "district": "หนองแสงใหญ่",
        "amphoe": "โขงเจียม",
        "province": "อุบลราชธานี",
        "zipcode": 34220,
        "district_code": 340304,
        "amphoe_code": 3403,
        "province_code": 34
    },
    {
        "district": "ห้วยยาง",
        "amphoe": "โขงเจียม",
        "province": "อุบลราชธานี",
        "zipcode": 34220,
        "district_code": 340302,
        "amphoe_code": 3403,
        "province_code": 34
    },
    {
        "district": "ห้วยไผ่",
        "amphoe": "โขงเจียม",
        "province": "อุบลราชธานี",
        "zipcode": 34220,
        "district_code": 340305,
        "amphoe_code": 3403,
        "province_code": 34
    },
    {
        "district": "โขงเจียม",
        "amphoe": "โขงเจียม",
        "province": "อุบลราชธานี",
        "zipcode": 34220,
        "district_code": 340301,
        "amphoe_code": 3403,
        "province_code": 34
    },
    {
        "district": "ม่วงใหญ่",
        "amphoe": "โพธิ์ไทร",
        "province": "อุบลราชธานี",
        "zipcode": 34340,
        "district_code": 342102,
        "amphoe_code": 3421,
        "province_code": 34
    },
    {
        "district": "สองคอน",
        "amphoe": "โพธิ์ไทร",
        "province": "อุบลราชธานี",
        "zipcode": 34340,
        "district_code": 342104,
        "amphoe_code": 3421,
        "province_code": 34
    },
    {
        "district": "สารภี",
        "amphoe": "โพธิ์ไทร",
        "province": "อุบลราชธานี",
        "zipcode": 34340,
        "district_code": 342105,
        "amphoe_code": 3421,
        "province_code": 34
    },
    {
        "district": "สำโรง",
        "amphoe": "โพธิ์ไทร",
        "province": "อุบลราชธานี",
        "zipcode": 34340,
        "district_code": 341513,
        "amphoe_code": 3421,
        "province_code": 34
    },
    {
        "district": "เหล่างาม",
        "amphoe": "โพธิ์ไทร",
        "province": "อุบลราชธานี",
        "zipcode": 34340,
        "district_code": 342106,
        "amphoe_code": 3421,
        "province_code": 34
    },
    {
        "district": "โพธิ์ไทร",
        "amphoe": "โพธิ์ไทร",
        "province": "อุบลราชธานี",
        "zipcode": 34340,
        "district_code": 341909,
        "amphoe_code": 3421,
        "province_code": 34
    },
    {
        "district": "นรสิงห์",
        "amphoe": "ป่าโมก",
        "province": "อ่างทอง",
        "zipcode": 14130,
        "district_code": 150306,
        "amphoe_code": 1503,
        "province_code": 15
    },
    {
        "district": "บางปลากด",
        "amphoe": "ป่าโมก",
        "province": "อ่างทอง",
        "zipcode": 14130,
        "district_code": 150301,
        "amphoe_code": 1503,
        "province_code": 15
    },
    {
        "district": "บางเสด็จ",
        "amphoe": "ป่าโมก",
        "province": "อ่างทอง",
        "zipcode": 14130,
        "district_code": 150305,
        "amphoe_code": 1503,
        "province_code": 15
    },
    {
        "district": "ป่าโมก",
        "amphoe": "ป่าโมก",
        "province": "อ่างทอง",
        "zipcode": 14130,
        "district_code": 150302,
        "amphoe_code": 1503,
        "province_code": 15
    },
    {
        "district": "สายทอง",
        "amphoe": "ป่าโมก",
        "province": "อ่างทอง",
        "zipcode": 14130,
        "district_code": 150303,
        "amphoe_code": 1503,
        "province_code": 15
    },
    {
        "district": "เอกราช",
        "amphoe": "ป่าโมก",
        "province": "อ่างทอง",
        "zipcode": 14130,
        "district_code": 150307,
        "amphoe_code": 1503,
        "province_code": 15
    },
    {
        "district": "โผงเผง",
        "amphoe": "ป่าโมก",
        "province": "อ่างทอง",
        "zipcode": 14130,
        "district_code": 150308,
        "amphoe_code": 1503,
        "province_code": 15
    },
    {
        "district": "โรงช้าง",
        "amphoe": "ป่าโมก",
        "province": "อ่างทอง",
        "zipcode": 14130,
        "district_code": 150304,
        "amphoe_code": 1503,
        "province_code": 15
    },
    {
        "district": "คลองขนาก",
        "amphoe": "วิเศษชัยชาญ",
        "province": "อ่างทอง",
        "zipcode": 14110,
        "district_code": 150609,
        "amphoe_code": 1506,
        "province_code": 15
    },
    {
        "district": "ตลาดใหม่",
        "amphoe": "วิเศษชัยชาญ",
        "province": "อ่างทอง",
        "zipcode": 14110,
        "district_code": 150615,
        "amphoe_code": 1506,
        "province_code": 15
    },
    {
        "district": "ท่าช้าง",
        "amphoe": "วิเศษชัยชาญ",
        "province": "อ่างทอง",
        "zipcode": 14110,
        "district_code": 150605,
        "amphoe_code": 1506,
        "province_code": 15
    },
    {
        "district": "บางจัก",
        "amphoe": "วิเศษชัยชาญ",
        "province": "อ่างทอง",
        "zipcode": 14110,
        "district_code": 150607,
        "amphoe_code": 1506,
        "province_code": 15
    },
    {
        "district": "ม่วงเตี้ย",
        "amphoe": "วิเศษชัยชาญ",
        "province": "อ่างทอง",
        "zipcode": 14110,
        "district_code": 150612,
        "amphoe_code": 1506,
        "province_code": 15
    },
    {
        "district": "ยี่ล้น",
        "amphoe": "วิเศษชัยชาญ",
        "province": "อ่างทอง",
        "zipcode": 14110,
        "district_code": 150606,
        "amphoe_code": 1506,
        "province_code": 15
    },
    {
        "district": "ศาลเจ้าโรงทอง",
        "amphoe": "วิเศษชัยชาญ",
        "province": "อ่างทอง",
        "zipcode": 14110,
        "district_code": 150602,
        "amphoe_code": 1506,
        "province_code": 15
    },
    {
        "district": "สาวร้องไห้",
        "amphoe": "วิเศษชัยชาญ",
        "province": "อ่างทอง",
        "zipcode": 14110,
        "district_code": 150604,
        "amphoe_code": 1506,
        "province_code": 15
    },
    {
        "district": "สี่ร้อย",
        "amphoe": "วิเศษชัยชาญ",
        "province": "อ่างทอง",
        "zipcode": 14110,
        "district_code": 150611,
        "amphoe_code": 1506,
        "province_code": 15
    },
    {
        "district": "หลักแก้ว",
        "amphoe": "วิเศษชัยชาญ",
        "province": "อ่างทอง",
        "zipcode": 14110,
        "district_code": 150614,
        "amphoe_code": 1506,
        "province_code": 15
    },
    {
        "district": "หัวตะพาน",
        "amphoe": "วิเศษชัยชาญ",
        "province": "อ่างทอง",
        "zipcode": 14110,
        "district_code": 150613,
        "amphoe_code": 1506,
        "province_code": 15
    },
    {
        "district": "ห้วยคันแหลน",
        "amphoe": "วิเศษชัยชาญ",
        "province": "อ่างทอง",
        "zipcode": 14110,
        "district_code": 150608,
        "amphoe_code": 1506,
        "province_code": 15
    },
    {
        "district": "ไผ่จำศีล",
        "amphoe": "วิเศษชัยชาญ",
        "province": "อ่างทอง",
        "zipcode": 14110,
        "district_code": false,
        "amphoe_code": 1506,
        "province_code": 15
    },
    {
        "district": "ไผ่ดำพัฒนา",
        "amphoe": "วิเศษชัยชาญ",
        "province": "อ่างทอง",
        "zipcode": 14110,
        "district_code": 150603,
        "amphoe_code": 1506,
        "province_code": 15
    },
    {
        "district": "ไผ่วง",
        "amphoe": "วิเศษชัยชาญ",
        "province": "อ่างทอง",
        "zipcode": 14110,
        "district_code": 150610,
        "amphoe_code": 1506,
        "province_code": 15
    },
    {
        "district": "มงคลธรรมนิมิต",
        "amphoe": "สามโก้",
        "province": "อ่างทอง",
        "zipcode": 14160,
        "district_code": 150705,
        "amphoe_code": 1507,
        "province_code": 15
    },
    {
        "district": "ราษฎรพัฒนา",
        "amphoe": "สามโก้",
        "province": "อ่างทอง",
        "zipcode": 14160,
        "district_code": 150702,
        "amphoe_code": 1507,
        "province_code": 15
    },
    {
        "district": "สามโก้",
        "amphoe": "สามโก้",
        "province": "อ่างทอง",
        "zipcode": 14160,
        "district_code": 150701,
        "amphoe_code": 1507,
        "province_code": 15
    },
    {
        "district": "อบทม",
        "amphoe": "สามโก้",
        "province": "อ่างทอง",
        "zipcode": 14160,
        "district_code": 150703,
        "amphoe_code": 1507,
        "province_code": 15
    },
    {
        "district": "โพธิ์ม่วงพันธ์",
        "amphoe": "สามโก้",
        "province": "อ่างทอง",
        "zipcode": 14160,
        "district_code": 150704,
        "amphoe_code": 1507,
        "province_code": 15
    },
    {
        "district": "คลองวัว",
        "amphoe": "เมืองอ่างทอง",
        "province": "อ่างทอง",
        "zipcode": 14000,
        "district_code": 150113,
        "amphoe_code": 1501,
        "province_code": 15
    },
    {
        "district": "จำปาหล่อ",
        "amphoe": "เมืองอ่างทอง",
        "province": "อ่างทอง",
        "zipcode": 14000,
        "district_code": 150110,
        "amphoe_code": 1501,
        "province_code": 15
    },
    {
        "district": "ตลาดกรวด",
        "amphoe": "เมืองอ่างทอง",
        "province": "อ่างทอง",
        "zipcode": 14000,
        "district_code": 150106,
        "amphoe_code": 1501,
        "province_code": 15
    },
    {
        "district": "ตลาดหลวง",
        "amphoe": "เมืองอ่างทอง",
        "province": "อ่างทอง",
        "zipcode": 14000,
        "district_code": 150101,
        "amphoe_code": 1501,
        "province_code": 15
    },
    {
        "district": "บางแก้ว",
        "amphoe": "เมืองอ่างทอง",
        "province": "อ่างทอง",
        "zipcode": 14000,
        "district_code": 150102,
        "amphoe_code": 1501,
        "province_code": 15
    },
    {
        "district": "บ้านรี",
        "amphoe": "เมืองอ่างทอง",
        "province": "อ่างทอง",
        "zipcode": 14000,
        "district_code": 150112,
        "amphoe_code": 1501,
        "province_code": 15
    },
    {
        "district": "บ้านอิฐ",
        "amphoe": "เมืองอ่างทอง",
        "province": "อ่างทอง",
        "zipcode": 14000,
        "district_code": 150108,
        "amphoe_code": 1501,
        "province_code": 15
    },
    {
        "district": "บ้านแห",
        "amphoe": "เมืองอ่างทอง",
        "province": "อ่างทอง",
        "zipcode": 14000,
        "district_code": 150105,
        "amphoe_code": 1501,
        "province_code": 15
    },
    {
        "district": "ป่างิ้ว",
        "amphoe": "เมืองอ่างทอง",
        "province": "อ่างทอง",
        "zipcode": 14000,
        "district_code": 150104,
        "amphoe_code": 1501,
        "province_code": 15
    },
    {
        "district": "มหาดไทย",
        "amphoe": "เมืองอ่างทอง",
        "province": "อ่างทอง",
        "zipcode": 14000,
        "district_code": 150107,
        "amphoe_code": 1501,
        "province_code": 15
    },
    {
        "district": "ย่านซื่อ",
        "amphoe": "เมืองอ่างทอง",
        "province": "อ่างทอง",
        "zipcode": 14000,
        "district_code": 150114,
        "amphoe_code": 1501,
        "province_code": 15
    },
    {
        "district": "ศาลาแดง",
        "amphoe": "เมืองอ่างทอง",
        "province": "อ่างทอง",
        "zipcode": 14000,
        "district_code": 150103,
        "amphoe_code": 1501,
        "province_code": 15
    },
    {
        "district": "หัวไผ่",
        "amphoe": "เมืองอ่างทอง",
        "province": "อ่างทอง",
        "zipcode": 14000,
        "district_code": 150109,
        "amphoe_code": 1501,
        "province_code": 15
    },
    {
        "district": "โพสะ",
        "amphoe": "เมืองอ่างทอง",
        "province": "อ่างทอง",
        "zipcode": 14000,
        "district_code": 150111,
        "amphoe_code": 1501,
        "province_code": 15
    },
    {
        "district": "จำลอง",
        "amphoe": "แสวงหา",
        "province": "อ่างทอง",
        "zipcode": 14150,
        "district_code": 150507,
        "amphoe_code": 1505,
        "province_code": 15
    },
    {
        "district": "บ้านพราน",
        "amphoe": "แสวงหา",
        "province": "อ่างทอง",
        "zipcode": 14150,
        "district_code": 150503,
        "amphoe_code": 1505,
        "province_code": 15
    },
    {
        "district": "วังน้ำเย็น",
        "amphoe": "แสวงหา",
        "province": "อ่างทอง",
        "zipcode": 14150,
        "district_code": 150504,
        "amphoe_code": 1505,
        "province_code": 15
    },
    {
        "district": "ศรีพราน",
        "amphoe": "แสวงหา",
        "province": "อ่างทอง",
        "zipcode": 14150,
        "district_code": 150502,
        "amphoe_code": 1505,
        "province_code": 15
    },
    {
        "district": "สีบัวทอง",
        "amphoe": "แสวงหา",
        "province": "อ่างทอง",
        "zipcode": 14150,
        "district_code": 150505,
        "amphoe_code": 1505,
        "province_code": 15
    },
    {
        "district": "ห้วยไผ่",
        "amphoe": "แสวงหา",
        "province": "อ่างทอง",
        "zipcode": 14150,
        "district_code": 150506,
        "amphoe_code": 1505,
        "province_code": 15
    },
    {
        "district": "แสวงหา",
        "amphoe": "แสวงหา",
        "province": "อ่างทอง",
        "zipcode": 14150,
        "district_code": 150501,
        "amphoe_code": 1505,
        "province_code": 15
    },
    {
        "district": "คำหยาด",
        "amphoe": "โพธิ์ทอง",
        "province": "อ่างทอง",
        "zipcode": 14120,
        "district_code": 150415,
        "amphoe_code": 1504,
        "province_code": 15
    },
    {
        "district": "ทางพระ",
        "amphoe": "โพธิ์ทอง",
        "province": "อ่างทอง",
        "zipcode": 14120,
        "district_code": 150412,
        "amphoe_code": 1504,
        "province_code": 15
    },
    {
        "district": "บางพลับ",
        "amphoe": "โพธิ์ทอง",
        "province": "อ่างทอง",
        "zipcode": 14120,
        "district_code": 150403,
        "amphoe_code": 1504,
        "province_code": 15
    },
    {
        "district": "บางระกำ",
        "amphoe": "โพธิ์ทอง",
        "province": "อ่างทอง",
        "zipcode": 14120,
        "district_code": 150406,
        "amphoe_code": 1504,
        "province_code": 15
    },
    {
        "district": "บางเจ้าฉ่า",
        "amphoe": "โพธิ์ทอง",
        "province": "อ่างทอง",
        "zipcode": 14120,
        "district_code": 150414,
        "amphoe_code": 1504,
        "province_code": 15
    },
    {
        "district": "บ่อแร่",
        "amphoe": "โพธิ์ทอง",
        "province": "อ่างทอง",
        "zipcode": 14120,
        "district_code": 150411,
        "amphoe_code": 1504,
        "province_code": 15
    },
    {
        "district": "ยางช้าย",
        "amphoe": "โพธิ์ทอง",
        "province": "อ่างทอง",
        "zipcode": 14120,
        "district_code": 150410,
        "amphoe_code": 1504,
        "province_code": 15
    },
    {
        "district": "รำมะสัก",
        "amphoe": "โพธิ์ทอง",
        "province": "อ่างทอง",
        "zipcode": 14120,
        "district_code": 150405,
        "amphoe_code": 1504,
        "province_code": 15
    },
    {
        "district": "สามง่าม",
        "amphoe": "โพธิ์ทอง",
        "province": "อ่างทอง",
        "zipcode": 14120,
        "district_code": 150413,
        "amphoe_code": 1504,
        "province_code": 15
    },
    {
        "district": "หนองแม่ไก่",
        "amphoe": "โพธิ์ทอง",
        "province": "อ่างทอง",
        "zipcode": 14120,
        "district_code": 150404,
        "amphoe_code": 1504,
        "province_code": 15
    },
    {
        "district": "องครักษ์",
        "amphoe": "โพธิ์ทอง",
        "province": "อ่างทอง",
        "zipcode": 14120,
        "district_code": 150408,
        "amphoe_code": 1504,
        "province_code": 15
    },
    {
        "district": "อินทประมูล",
        "amphoe": "โพธิ์ทอง",
        "province": "อ่างทอง",
        "zipcode": 14120,
        "district_code": 150402,
        "amphoe_code": 1504,
        "province_code": 15
    },
    {
        "district": "อ่างแก้ว",
        "amphoe": "โพธิ์ทอง",
        "province": "อ่างทอง",
        "zipcode": 14120,
        "district_code": 150401,
        "amphoe_code": 1504,
        "province_code": 15
    },
    {
        "district": "โคกพุทรา",
        "amphoe": "โพธิ์ทอง",
        "province": "อ่างทอง",
        "zipcode": 14120,
        "district_code": 150409,
        "amphoe_code": 1504,
        "province_code": 15
    },
    {
        "district": "โพธิ์รังนก",
        "amphoe": "โพธิ์ทอง",
        "province": "อ่างทอง",
        "zipcode": 14120,
        "district_code": 150407,
        "amphoe_code": 1504,
        "province_code": 15
    },
    {
        "district": "จรเข้ร้อง",
        "amphoe": "ไชโย",
        "province": "อ่างทอง",
        "zipcode": 14140,
        "district_code": 150201,
        "amphoe_code": 1502,
        "province_code": 15
    },
    {
        "district": "ชะไว",
        "amphoe": "ไชโย",
        "province": "อ่างทอง",
        "zipcode": 14140,
        "district_code": 150208,
        "amphoe_code": 1502,
        "province_code": 15
    },
    {
        "district": "ชัยฤทธิ์",
        "amphoe": "ไชโย",
        "province": "อ่างทอง",
        "zipcode": 14140,
        "district_code": 150203,
        "amphoe_code": 1502,
        "province_code": 15
    },
    {
        "district": "ตรีณรงค์",
        "amphoe": "ไชโย",
        "province": "อ่างทอง",
        "zipcode": 14140,
        "district_code": 150209,
        "amphoe_code": 1502,
        "province_code": 15
    },
    {
        "district": "ราชสถิตย์",
        "amphoe": "ไชโย",
        "province": "อ่างทอง",
        "zipcode": 14140,
        "district_code": 150205,
        "amphoe_code": 1502,
        "province_code": 15
    },
    {
        "district": "หลักฟ้า",
        "amphoe": "ไชโย",
        "province": "อ่างทอง",
        "zipcode": 14140,
        "district_code": 150207,
        "amphoe_code": 1502,
        "province_code": 15
    },
    {
        "district": "เทวราช",
        "amphoe": "ไชโย",
        "province": "อ่างทอง",
        "zipcode": 14140,
        "district_code": 150204,
        "amphoe_code": 1502,
        "province_code": 15
    },
    {
        "district": "ไชยภูมิ",
        "amphoe": "ไชโย",
        "province": "อ่างทอง",
        "zipcode": 14140,
        "district_code": 150202,
        "amphoe_code": 1502,
        "province_code": 15
    },
    {
        "district": "ไชโย",
        "amphoe": "ไชโย",
        "province": "อ่างทอง",
        "zipcode": 14140,
        "district_code": 150206,
        "amphoe_code": 1502,
        "province_code": 15
    },
    {
        "district": "ต้า",
        "amphoe": "ขุนตาล",
        "province": "เชียงราย",
        "zipcode": 57340,
        "district_code": 570496,
        "amphoe_code": 5714,
        "province_code": 57
    },
    {
        "district": "ป่าตาล",
        "amphoe": "ขุนตาล",
        "province": "เชียงราย",
        "zipcode": 57340,
        "district_code": 570499,
        "amphoe_code": 5714,
        "province_code": 57
    },
    {
        "district": "ยางฮอม",
        "amphoe": "ขุนตาล",
        "province": "เชียงราย",
        "zipcode": 57340,
        "district_code": 570497,
        "amphoe_code": 5714,
        "province_code": 57
    },
    {
        "district": "ปงน้อย",
        "amphoe": "ดอยหลวง",
        "province": "เชียงราย",
        "zipcode": 57110,
        "district_code": 570707,
        "amphoe_code": 5718,
        "province_code": 57
    },
    {
        "district": "หนองป่าก่อ",
        "amphoe": "ดอยหลวง",
        "province": "เชียงราย",
        "zipcode": 57110,
        "district_code": 570709,
        "amphoe_code": 5718,
        "province_code": 57
    },
    {
        "district": "โชคชัย",
        "amphoe": "ดอยหลวง",
        "province": "เชียงราย",
        "zipcode": 57110,
        "district_code": 570717,
        "amphoe_code": 5718,
        "province_code": 57
    },
    {
        "district": "ป่าแงะ",
        "amphoe": "ป่าแดด",
        "province": "เชียงราย",
        "zipcode": 57190,
        "district_code": 570597,
        "amphoe_code": 5706,
        "province_code": 57
    },
    {
        "district": "ป่าแดด",
        "amphoe": "ป่าแดด",
        "province": "เชียงราย",
        "zipcode": 57190,
        "district_code": 570599,
        "amphoe_code": 5706,
        "province_code": 57
    },
    {
        "district": "ศรีโพธิ์เงิน",
        "amphoe": "ป่าแดด",
        "province": "เชียงราย",
        "zipcode": 57190,
        "district_code": 570606,
        "amphoe_code": 5706,
        "province_code": 57
    },
    {
        "district": "สันมะค่า",
        "amphoe": "ป่าแดด",
        "province": "เชียงราย",
        "zipcode": 57190,
        "district_code": 570598,
        "amphoe_code": 5706,
        "province_code": 57
    },
    {
        "district": "โรงช้าง",
        "amphoe": "ป่าแดด",
        "province": "เชียงราย",
        "zipcode": 57190,
        "district_code": 570605,
        "amphoe_code": 5706,
        "province_code": 57
    },
    {
        "district": "ตาดควัน",
        "amphoe": "พญาเม็งราย",
        "province": "เชียงราย",
        "zipcode": 57290,
        "district_code": 571205,
        "amphoe_code": 5712,
        "province_code": 57
    },
    {
        "district": "เม็งราย",
        "amphoe": "พญาเม็งราย",
        "province": "เชียงราย",
        "zipcode": 57290,
        "district_code": 571204,
        "amphoe_code": 5712,
        "province_code": 57
    },
    {
        "district": "แม่ต๋ำ",
        "amphoe": "พญาเม็งราย",
        "province": "เชียงราย",
        "zipcode": 57290,
        "district_code": 571202,
        "amphoe_code": 5712,
        "province_code": 57
    },
    {
        "district": "แม่เปา",
        "amphoe": "พญาเม็งราย",
        "province": "เชียงราย",
        "zipcode": 57290,
        "district_code": 570498,
        "amphoe_code": 5712,
        "province_code": 57
    },
    {
        "district": "ไม้ยา",
        "amphoe": "พญาเม็งราย",
        "province": "เชียงราย",
        "zipcode": 57290,
        "district_code": 571203,
        "amphoe_code": 5712,
        "province_code": 57
    },
    {
        "district": "ดอยงาม",
        "amphoe": "พาน",
        "province": "เชียงราย",
        "zipcode": 57120,
        "district_code": 570505,
        "amphoe_code": 5705,
        "province_code": 57
    },
    {
        "district": "ทรายขาว",
        "amphoe": "พาน",
        "province": "เชียงราย",
        "zipcode": 57120,
        "district_code": 570510,
        "amphoe_code": 5705,
        "province_code": 57
    },
    {
        "district": "ทานตะวัน",
        "amphoe": "พาน",
        "province": "เชียงราย",
        "zipcode": 57280,
        "district_code": 570514,
        "amphoe_code": 5705,
        "province_code": 57
    },
    {
        "district": "ธารทอง",
        "amphoe": "พาน",
        "province": "เชียงราย",
        "zipcode": 57250,
        "district_code": 570503,
        "amphoe_code": 5705,
        "province_code": 57
    },
    {
        "district": "ป่าหุ่ง",
        "amphoe": "พาน",
        "province": "เชียงราย",
        "zipcode": 57120,
        "district_code": 570508,
        "amphoe_code": 5705,
        "province_code": 57
    },
    {
        "district": "ม่วงคำ",
        "amphoe": "พาน",
        "province": "เชียงราย",
        "zipcode": 57120,
        "district_code": 570509,
        "amphoe_code": 5705,
        "province_code": 57
    },
    {
        "district": "สันกลาง",
        "amphoe": "พาน",
        "province": "เชียงราย",
        "zipcode": 57120,
        "district_code": 570511,
        "amphoe_code": 5705,
        "province_code": 57
    },
    {
        "district": "สันติสุข",
        "amphoe": "พาน",
        "province": "เชียงราย",
        "zipcode": 57120,
        "district_code": 570504,
        "amphoe_code": 5705,
        "province_code": 57
    },
    {
        "district": "สันมะเค็ด",
        "amphoe": "พาน",
        "province": "เชียงราย",
        "zipcode": 57120,
        "district_code": 570501,
        "amphoe_code": 5705,
        "province_code": 57
    },
    {
        "district": "หัวง้ม",
        "amphoe": "พาน",
        "province": "เชียงราย",
        "zipcode": 57120,
        "district_code": 570506,
        "amphoe_code": 5705,
        "province_code": 57
    },
    {
        "district": "เจริญเมือง",
        "amphoe": "พาน",
        "province": "เชียงราย",
        "zipcode": 57120,
        "district_code": 570507,
        "amphoe_code": 5705,
        "province_code": 57
    },
    {
        "district": "เมืองพาน",
        "amphoe": "พาน",
        "province": "เชียงราย",
        "zipcode": 57120,
        "district_code": 570513,
        "amphoe_code": 5705,
        "province_code": 57
    },
    {
        "district": "เวียงห้าว",
        "amphoe": "พาน",
        "province": "เชียงราย",
        "zipcode": 57120,
        "district_code": 570515,
        "amphoe_code": 5705,
        "province_code": 57
    },
    {
        "district": "แม่อ้อ",
        "amphoe": "พาน",
        "province": "เชียงราย",
        "zipcode": 57120,
        "district_code": 570502,
        "amphoe_code": 5705,
        "province_code": 57
    },
    {
        "district": "แม่เย็น",
        "amphoe": "พาน",
        "province": "เชียงราย",
        "zipcode": 57280,
        "district_code": 570512,
        "amphoe_code": 5705,
        "province_code": 57
    },
    {
        "district": "ครึ่ง",
        "amphoe": "เชียงของ",
        "province": "เชียงราย",
        "zipcode": 57140,
        "district_code": 570303,
        "amphoe_code": 5703,
        "province_code": 57
    },
    {
        "district": "บุญเรือง",
        "amphoe": "เชียงของ",
        "province": "เชียงราย",
        "zipcode": 57140,
        "district_code": 570304,
        "amphoe_code": 5703,
        "province_code": 57
    },
    {
        "district": "ริมโขง",
        "amphoe": "เชียงของ",
        "province": "เชียงราย",
        "zipcode": 57140,
        "district_code": 570310,
        "amphoe_code": 5703,
        "province_code": 57
    },
    {
        "district": "ศรีดอนชัย",
        "amphoe": "เชียงของ",
        "province": "เชียงราย",
        "zipcode": 57140,
        "district_code": 570308,
        "amphoe_code": 5703,
        "province_code": 57
    },
    {
        "district": "สถาน",
        "amphoe": "เชียงของ",
        "province": "เชียงราย",
        "zipcode": 57140,
        "district_code": 570302,
        "amphoe_code": 5703,
        "province_code": 57
    },
    {
        "district": "ห้วยซ้อ",
        "amphoe": "เชียงของ",
        "province": "เชียงราย",
        "zipcode": 57140,
        "district_code": 570305,
        "amphoe_code": 5703,
        "province_code": 57
    },
    {
        "district": "เวียง",
        "amphoe": "เชียงของ",
        "province": "เชียงราย",
        "zipcode": 57140,
        "district_code": 570101,
        "amphoe_code": 5703,
        "province_code": 57
    },
    {
        "district": "บ้านแซว",
        "amphoe": "เชียงแสน",
        "province": "เชียงราย",
        "zipcode": 57150,
        "district_code": 570803,
        "amphoe_code": 5708,
        "province_code": 57
    },
    {
        "district": "ป่าสัก",
        "amphoe": "เชียงแสน",
        "province": "เชียงราย",
        "zipcode": 57150,
        "district_code": 570802,
        "amphoe_code": 5708,
        "province_code": 57
    },
    {
        "district": "ศรีดอนมูล",
        "amphoe": "เชียงแสน",
        "province": "เชียงราย",
        "zipcode": 57150,
        "district_code": 570804,
        "amphoe_code": 5708,
        "province_code": 57
    },
    {
        "district": "เวียง",
        "amphoe": "เชียงแสน",
        "province": "เชียงราย",
        "zipcode": 57150,
        "district_code": 570101,
        "amphoe_code": 5708,
        "province_code": 57
    },
    {
        "district": "แม่เงิน",
        "amphoe": "เชียงแสน",
        "province": "เชียงราย",
        "zipcode": 57150,
        "district_code": 570805,
        "amphoe_code": 5708,
        "province_code": 57
    },
    {
        "district": "โยนก",
        "amphoe": "เชียงแสน",
        "province": "เชียงราย",
        "zipcode": 57150,
        "district_code": 570806,
        "amphoe_code": 5708,
        "province_code": 57
    },
    {
        "district": "งิ้ว",
        "amphoe": "เทิง",
        "province": "เชียงราย",
        "zipcode": 57160,
        "district_code": 570402,
        "amphoe_code": 5704,
        "province_code": 57
    },
    {
        "district": "ตับเต่า",
        "amphoe": "เทิง",
        "province": "เชียงราย",
        "zipcode": 57160,
        "district_code": 570409,
        "amphoe_code": 5704,
        "province_code": 57
    },
    {
        "district": "ปล้อง",
        "amphoe": "เทิง",
        "province": "เชียงราย",
        "zipcode": 57230,
        "district_code": 570403,
        "amphoe_code": 5704,
        "province_code": 57
    },
    {
        "district": "ศรีดอนไชย",
        "amphoe": "เทิง",
        "province": "เชียงราย",
        "zipcode": 57230,
        "district_code": 570412,
        "amphoe_code": 5704,
        "province_code": 57
    },
    {
        "district": "สันทรายงาม",
        "amphoe": "เทิง",
        "province": "เชียงราย",
        "zipcode": 57160,
        "district_code": 570411,
        "amphoe_code": 5704,
        "province_code": 57
    },
    {
        "district": "หงาว",
        "amphoe": "เทิง",
        "province": "เชียงราย",
        "zipcode": 57160,
        "district_code": 570410,
        "amphoe_code": 5704,
        "province_code": 57
    },
    {
        "district": "หนองแรด",
        "amphoe": "เทิง",
        "province": "เชียงราย",
        "zipcode": 57230,
        "district_code": 570413,
        "amphoe_code": 5704,
        "province_code": 57
    },
    {
        "district": "เชียงเคี่ยน",
        "amphoe": "เทิง",
        "province": "เชียงราย",
        "zipcode": 57230,
        "district_code": 570405,
        "amphoe_code": 5704,
        "province_code": 57
    },
    {
        "district": "เวียง",
        "amphoe": "เทิง",
        "province": "เชียงราย",
        "zipcode": 57160,
        "district_code": 570101,
        "amphoe_code": 5704,
        "province_code": 57
    },
    {
        "district": "แม่ลอย",
        "amphoe": "เทิง",
        "province": "เชียงราย",
        "zipcode": 57230,
        "district_code": 570404,
        "amphoe_code": 5704,
        "province_code": 57
    },
    {
        "district": "ดอยลาน",
        "amphoe": "เมืองเชียงราย",
        "province": "เชียงราย",
        "zipcode": 57000,
        "district_code": 570115,
        "amphoe_code": 5701,
        "province_code": 57
    },
    {
        "district": "ดอยฮาง",
        "amphoe": "เมืองเชียงราย",
        "province": "เชียงราย",
        "zipcode": 57000,
        "district_code": 570120,
        "amphoe_code": 5701,
        "province_code": 57
    },
    {
        "district": "ท่าสาย",
        "amphoe": "เมืองเชียงราย",
        "province": "เชียงราย",
        "zipcode": 57000,
        "district_code": 570118,
        "amphoe_code": 5701,
        "province_code": 57
    },
    {
        "district": "ท่าสุด",
        "amphoe": "เมืองเชียงราย",
        "province": "เชียงราย",
        "zipcode": 57100,
        "district_code": 570121,
        "amphoe_code": 5701,
        "province_code": 57
    },
    {
        "district": "นางแล",
        "amphoe": "เมืองเชียงราย",
        "province": "เชียงราย",
        "zipcode": 57100,
        "district_code": 570104,
        "amphoe_code": 5701,
        "province_code": 57
    },
    {
        "district": "บ้านดู่",
        "amphoe": "เมืองเชียงราย",
        "province": "เชียงราย",
        "zipcode": 57100,
        "district_code": 570103,
        "amphoe_code": 5701,
        "province_code": 57
    },
    {
        "district": "ป่าอ้อดอนชัย",
        "amphoe": "เมืองเชียงราย",
        "province": "เชียงราย",
        "zipcode": 57000,
        "district_code": 570116,
        "amphoe_code": 5701,
        "province_code": 57
    },
    {
        "district": "รอบเวียง",
        "amphoe": "เมืองเชียงราย",
        "province": "เชียงราย",
        "zipcode": 57000,
        "district_code": 570102,
        "amphoe_code": 5701,
        "province_code": 57
    },
    {
        "district": "ริมกก",
        "amphoe": "เมืองเชียงราย",
        "province": "เชียงราย",
        "zipcode": 57100,
        "district_code": 570114,
        "amphoe_code": 5701,
        "province_code": 57
    },
    {
        "district": "สันทราย",
        "amphoe": "เมืองเชียงราย",
        "province": "เชียงราย",
        "zipcode": 57000,
        "district_code": 570107,
        "amphoe_code": 5701,
        "province_code": 57
    },
    {
        "district": "ห้วยชมภู",
        "amphoe": "เมืองเชียงราย",
        "province": "เชียงราย",
        "zipcode": 57000,
        "district_code": 570112,
        "amphoe_code": 5701,
        "province_code": 57
    },
    {
        "district": "ห้วยสัก",
        "amphoe": "เมืองเชียงราย",
        "province": "เชียงราย",
        "zipcode": 57000,
        "district_code": 570113,
        "amphoe_code": 5701,
        "province_code": 57
    },
    {
        "district": "เวียง",
        "amphoe": "เมืองเชียงราย",
        "province": "เชียงราย",
        "zipcode": 57000,
        "district_code": 570101,
        "amphoe_code": 5701,
        "province_code": 57
    },
    {
        "district": "แม่กรณ์",
        "amphoe": "เมืองเชียงราย",
        "province": "เชียงราย",
        "zipcode": 57000,
        "district_code": 570111,
        "amphoe_code": 5701,
        "province_code": 57
    },
    {
        "district": "แม่ข้าวต้ม",
        "amphoe": "เมืองเชียงราย",
        "province": "เชียงราย",
        "zipcode": 57100,
        "district_code": 570105,
        "amphoe_code": 5701,
        "province_code": 57
    },
    {
        "district": "แม่ยาว",
        "amphoe": "เมืองเชียงราย",
        "province": "เชียงราย",
        "zipcode": 57100,
        "district_code": 570106,
        "amphoe_code": 5701,
        "province_code": 57
    },
    {
        "district": "ดอนศิลา",
        "amphoe": "เวียงชัย",
        "province": "เชียงราย",
        "zipcode": 57210,
        "district_code": 570206,
        "amphoe_code": 5702,
        "province_code": 57
    },
    {
        "district": "ผางาม",
        "amphoe": "เวียงชัย",
        "province": "เชียงราย",
        "zipcode": 57210,
        "district_code": 570197,
        "amphoe_code": 5702,
        "province_code": 57
    },
    {
        "district": "เมืองชุม",
        "amphoe": "เวียงชัย",
        "province": "เชียงราย",
        "zipcode": 57210,
        "district_code": 570208,
        "amphoe_code": 5702,
        "province_code": 57
    },
    {
        "district": "เวียงชัย",
        "amphoe": "เวียงชัย",
        "province": "เชียงราย",
        "zipcode": 57210,
        "district_code": 570198,
        "amphoe_code": 5702,
        "province_code": 57
    },
    {
        "district": "เวียงเหนือ",
        "amphoe": "เวียงชัย",
        "province": "เชียงราย",
        "zipcode": 57210,
        "district_code": 570196,
        "amphoe_code": 5702,
        "province_code": 57
    },
    {
        "district": "บ้านโป่ง",
        "amphoe": "เวียงป่าเป้า",
        "province": "เชียงราย",
        "zipcode": 57170,
        "district_code": 571103,
        "amphoe_code": 5711,
        "province_code": 57
    },
    {
        "district": "ป่างิ้ว",
        "amphoe": "เวียงป่าเป้า",
        "province": "เชียงราย",
        "zipcode": 57170,
        "district_code": 571104,
        "amphoe_code": 5711,
        "province_code": 57
    },
    {
        "district": "สันสลี",
        "amphoe": "เวียงป่าเป้า",
        "province": "เชียงราย",
        "zipcode": 57170,
        "district_code": 571101,
        "amphoe_code": 5711,
        "province_code": 57
    },
    {
        "district": "เวียง",
        "amphoe": "เวียงป่าเป้า",
        "province": "เชียงราย",
        "zipcode": 57170,
        "district_code": 570101,
        "amphoe_code": 5711,
        "province_code": 57
    },
    {
        "district": "เวียงกาหลง",
        "amphoe": "เวียงป่าเป้า",
        "province": "เชียงราย",
        "zipcode": 57260,
        "district_code": 571105,
        "amphoe_code": 5711,
        "province_code": 57
    },
    {
        "district": "แม่เจดีย์",
        "amphoe": "เวียงป่าเป้า",
        "province": "เชียงราย",
        "zipcode": 57260,
        "district_code": 571106,
        "amphoe_code": 5711,
        "province_code": 57
    },
    {
        "district": "แม่เจดีย์ใหม่",
        "amphoe": "เวียงป่าเป้า",
        "province": "เชียงราย",
        "zipcode": 57260,
        "district_code": 571107,
        "amphoe_code": 5711,
        "province_code": 57
    },
    {
        "district": "ดงมหาวัน",
        "amphoe": "เวียงเชียงรุ้ง",
        "province": "เชียงราย",
        "zipcode": 57210,
        "district_code": 570207,
        "amphoe_code": 5717,
        "province_code": 57
    },
    {
        "district": "ทุ่งก่อ",
        "amphoe": "เวียงเชียงรุ้ง",
        "province": "เชียงราย",
        "zipcode": 57210,
        "district_code": 570192,
        "amphoe_code": 5717,
        "province_code": 57
    },
    {
        "district": "ป่าซาง",
        "amphoe": "เวียงเชียงรุ้ง",
        "province": "เชียงราย",
        "zipcode": 57210,
        "district_code": 570205,
        "amphoe_code": 5717,
        "province_code": 57
    },
    {
        "district": "ท่าข้าม",
        "amphoe": "เวียงแก่น",
        "province": "เชียงราย",
        "zipcode": 57310,
        "district_code": 571304,
        "amphoe_code": 5713,
        "province_code": 57
    },
    {
        "district": "ปอ",
        "amphoe": "เวียงแก่น",
        "province": "เชียงราย",
        "zipcode": 57310,
        "district_code": 570307,
        "amphoe_code": 5713,
        "province_code": 57
    },
    {
        "district": "ม่วงยาย",
        "amphoe": "เวียงแก่น",
        "province": "เชียงราย",
        "zipcode": 57310,
        "district_code": 570306,
        "amphoe_code": 5713,
        "province_code": 57
    },
    {
        "district": "หล่ายงาว",
        "amphoe": "เวียงแก่น",
        "province": "เชียงราย",
        "zipcode": 57310,
        "district_code": 570309,
        "amphoe_code": 5713,
        "province_code": 57
    },
    {
        "district": "จอมสวรรค์",
        "amphoe": "แม่จัน",
        "province": "เชียงราย",
        "zipcode": 57110,
        "district_code": 570713,
        "amphoe_code": 5707,
        "province_code": 57
    },
    {
        "district": "จันจว้า",
        "amphoe": "แม่จัน",
        "province": "เชียงราย",
        "zipcode": 57270,
        "district_code": 570702,
        "amphoe_code": 5707,
        "province_code": 57
    },
    {
        "district": "จันจว้าใต้",
        "amphoe": "แม่จัน",
        "province": "เชียงราย",
        "zipcode": 57270,
        "district_code": 570712,
        "amphoe_code": 5707,
        "province_code": 57
    },
    {
        "district": "ท่าข้าวเปลือก",
        "amphoe": "แม่จัน",
        "province": "เชียงราย",
        "zipcode": 57110,
        "district_code": 570706,
        "amphoe_code": 5707,
        "province_code": 57
    },
    {
        "district": "ป่าซาง",
        "amphoe": "แม่จัน",
        "province": "เชียงราย",
        "zipcode": 57110,
        "district_code": 570205,
        "amphoe_code": 5707,
        "province_code": 57
    },
    {
        "district": "ป่าตึง",
        "amphoe": "แม่จัน",
        "province": "เชียงราย",
        "zipcode": 57110,
        "district_code": 570708,
        "amphoe_code": 5707,
        "province_code": 57
    },
    {
        "district": "ศรีค้ำ",
        "amphoe": "แม่จัน",
        "province": "เชียงราย",
        "zipcode": 57110,
        "district_code": 570711,
        "amphoe_code": 5707,
        "province_code": 57
    },
    {
        "district": "สันทราย",
        "amphoe": "แม่จัน",
        "province": "เชียงราย",
        "zipcode": 57110,
        "district_code": 570107,
        "amphoe_code": 5707,
        "province_code": 57
    },
    {
        "district": "แม่คำ",
        "amphoe": "แม่จัน",
        "province": "เชียงราย",
        "zipcode": 57240,
        "district_code": 570703,
        "amphoe_code": 5707,
        "province_code": 57
    },
    {
        "district": "แม่จัน",
        "amphoe": "แม่จัน",
        "province": "เชียงราย",
        "zipcode": 57110,
        "district_code": 570701,
        "amphoe_code": 5707,
        "province_code": 57
    },
    {
        "district": "แม่ไร่",
        "amphoe": "แม่จัน",
        "province": "เชียงราย",
        "zipcode": 57240,
        "district_code": 570710,
        "amphoe_code": 5707,
        "province_code": 57
    },
    {
        "district": "เทอดไทย",
        "amphoe": "แม่ฟ้าหลวง",
        "province": "เชียงราย",
        "zipcode": 57240,
        "district_code": 571501,
        "amphoe_code": 5715,
        "province_code": 57
    },
    {
        "district": "แม่ฟ้าหลวง",
        "amphoe": "แม่ฟ้าหลวง",
        "province": "เชียงราย",
        "zipcode": 57240,
        "district_code": 571504,
        "amphoe_code": 5715,
        "province_code": 57
    },
    {
        "district": "แม่สลองนอก",
        "amphoe": "แม่ฟ้าหลวง",
        "province": "เชียงราย",
        "zipcode": 57110,
        "district_code": 570716,
        "amphoe_code": 5715,
        "province_code": 57
    },
    {
        "district": "แม่สลองใน",
        "amphoe": "แม่ฟ้าหลวง",
        "province": "เชียงราย",
        "zipcode": 57110,
        "district_code": 571502,
        "amphoe_code": 5715,
        "province_code": 57
    },
    {
        "district": "จอมหมอกแก้ว",
        "amphoe": "แม่ลาว",
        "province": "เชียงราย",
        "zipcode": 57250,
        "district_code": 570117,
        "amphoe_code": 5716,
        "province_code": 57
    },
    {
        "district": "ดงมะดะ",
        "amphoe": "แม่ลาว",
        "province": "เชียงราย",
        "zipcode": 57250,
        "district_code": 570109,
        "amphoe_code": 5716,
        "province_code": 57
    },
    {
        "district": "บัวสลี",
        "amphoe": "แม่ลาว",
        "province": "เชียงราย",
        "zipcode": 57250,
        "district_code": 570108,
        "amphoe_code": 5716,
        "province_code": 57
    },
    {
        "district": "ป่าก่อดำ",
        "amphoe": "แม่ลาว",
        "province": "เชียงราย",
        "zipcode": 57250,
        "district_code": 570110,
        "amphoe_code": 5716,
        "province_code": 57
    },
    {
        "district": "โป่งแพร่",
        "amphoe": "แม่ลาว",
        "province": "เชียงราย",
        "zipcode": 57000,
        "district_code": 570119,
        "amphoe_code": 5716,
        "province_code": 57
    },
    {
        "district": "ท่าก๊อ",
        "amphoe": "แม่สรวย",
        "province": "เชียงราย",
        "zipcode": 57180,
        "district_code": 571005,
        "amphoe_code": 5710,
        "province_code": 57
    },
    {
        "district": "ป่าแดด",
        "amphoe": "แม่สรวย",
        "province": "เชียงราย",
        "zipcode": 57180,
        "district_code": 570599,
        "amphoe_code": 5710,
        "province_code": 57
    },
    {
        "district": "วาวี",
        "amphoe": "แม่สรวย",
        "province": "เชียงราย",
        "zipcode": 57180,
        "district_code": 571006,
        "amphoe_code": 5710,
        "province_code": 57
    },
    {
        "district": "ศรีถ้อย",
        "amphoe": "แม่สรวย",
        "province": "เชียงราย",
        "zipcode": 57180,
        "district_code": 571004,
        "amphoe_code": 5710,
        "province_code": 57
    },
    {
        "district": "เจดีย์หลวง",
        "amphoe": "แม่สรวย",
        "province": "เชียงราย",
        "zipcode": 57180,
        "district_code": 571007,
        "amphoe_code": 5710,
        "province_code": 57
    },
    {
        "district": "แม่พริก",
        "amphoe": "แม่สรวย",
        "province": "เชียงราย",
        "zipcode": 57180,
        "district_code": 571003,
        "amphoe_code": 5710,
        "province_code": 57
    },
    {
        "district": "แม่สรวย",
        "amphoe": "แม่สรวย",
        "province": "เชียงราย",
        "zipcode": 57180,
        "district_code": 571001,
        "amphoe_code": 5710,
        "province_code": 57
    },
    {
        "district": "บ้านด้าย",
        "amphoe": "แม่สาย",
        "province": "เชียงราย",
        "zipcode": 57220,
        "district_code": 570908,
        "amphoe_code": 5709,
        "province_code": 57
    },
    {
        "district": "ศรีเมืองชุม",
        "amphoe": "แม่สาย",
        "province": "เชียงราย",
        "zipcode": 57130,
        "district_code": 570905,
        "amphoe_code": 5709,
        "province_code": 57
    },
    {
        "district": "ห้วยไคร้",
        "amphoe": "แม่สาย",
        "province": "เชียงราย",
        "zipcode": 57220,
        "district_code": 570902,
        "amphoe_code": 5709,
        "province_code": 57
    },
    {
        "district": "เกาะช้าง",
        "amphoe": "แม่สาย",
        "province": "เชียงราย",
        "zipcode": 57130,
        "district_code": 570903,
        "amphoe_code": 5709,
        "province_code": 57
    },
    {
        "district": "เวียงพางคำ",
        "amphoe": "แม่สาย",
        "province": "เชียงราย",
        "zipcode": 57130,
        "district_code": 570906,
        "amphoe_code": 5709,
        "province_code": 57
    },
    {
        "district": "แม่สาย",
        "amphoe": "แม่สาย",
        "province": "เชียงราย",
        "zipcode": 57130,
        "district_code": 570901,
        "amphoe_code": 5709,
        "province_code": 57
    },
    {
        "district": "โป่งงาม",
        "amphoe": "แม่สาย",
        "province": "เชียงราย",
        "zipcode": 57130,
        "district_code": 570909,
        "amphoe_code": 5709,
        "province_code": 57
    },
    {
        "district": "โป่งผา",
        "amphoe": "แม่สาย",
        "province": "เชียงราย",
        "zipcode": 57130,
        "district_code": 570904,
        "amphoe_code": 5709,
        "province_code": 57
    },
    {
        "district": "บ้านจันทร์",
        "amphoe": "กัลยาณิวัฒนา",
        "province": "เชียงใหม่",
        "zipcode": 58130,
        "district_code": 500306,
        "amphoe_code": false,
        "province_code": 50
    },
    {
        "district": "แจ่มหลวง",
        "amphoe": "กัลยาณิวัฒนา",
        "province": "เชียงใหม่",
        "zipcode": 58130,
        "district_code": 500310,
        "amphoe_code": false,
        "province_code": 50
    },
    {
        "district": "แม่แดด",
        "amphoe": "กัลยาณิวัฒนา",
        "province": "เชียงใหม่",
        "zipcode": 58130,
        "district_code": 500309,
        "amphoe_code": false,
        "province_code": 50
    },
    {
        "district": "ข่วงเปา",
        "amphoe": "จอมทอง",
        "province": "เชียงใหม่",
        "zipcode": 50160,
        "district_code": 500204,
        "amphoe_code": 5002,
        "province_code": 50
    },
    {
        "district": "ดอยแก้ว",
        "amphoe": "จอมทอง",
        "province": "เชียงใหม่",
        "zipcode": 50160,
        "district_code": 500207,
        "amphoe_code": 5002,
        "province_code": 50
    },
    {
        "district": "บ้านหลวง",
        "amphoe": "จอมทอง",
        "province": "เชียงใหม่",
        "zipcode": 50160,
        "district_code": 500203,
        "amphoe_code": 5002,
        "province_code": 50
    },
    {
        "district": "บ้านแปะ",
        "amphoe": "จอมทอง",
        "province": "เชียงใหม่",
        "zipcode": 50240,
        "district_code": 500206,
        "amphoe_code": 5002,
        "province_code": 50
    },
    {
        "district": "สบเตี๊ยะ",
        "amphoe": "จอมทอง",
        "province": "เชียงใหม่",
        "zipcode": 50160,
        "district_code": 500205,
        "amphoe_code": 5002,
        "province_code": 50
    },
    {
        "district": "แม่สอย",
        "amphoe": "จอมทอง",
        "province": "เชียงใหม่",
        "zipcode": 50240,
        "district_code": 500209,
        "amphoe_code": 5002,
        "province_code": 50
    },
    {
        "district": "ตลาดขวัญ",
        "amphoe": "ดอยสะเก็ด",
        "province": "เชียงใหม่",
        "zipcode": 50220,
        "district_code": 500507,
        "amphoe_code": 5005,
        "province_code": 50
    },
    {
        "district": "ตลาดใหญ่",
        "amphoe": "ดอยสะเก็ด",
        "province": "เชียงใหม่",
        "zipcode": 50220,
        "district_code": 500510,
        "amphoe_code": 5005,
        "province_code": 50
    },
    {
        "district": "ป่าป้อง",
        "amphoe": "ดอยสะเก็ด",
        "province": "เชียงใหม่",
        "zipcode": 50220,
        "district_code": 500504,
        "amphoe_code": 5005,
        "province_code": 50
    },
    {
        "district": "ป่าลาน",
        "amphoe": "ดอยสะเก็ด",
        "province": "เชียงใหม่",
        "zipcode": 50220,
        "district_code": 500506,
        "amphoe_code": 5005,
        "province_code": 50
    },
    {
        "district": "ป่าเมี่ยง",
        "amphoe": "ดอยสะเก็ด",
        "province": "เชียงใหม่",
        "zipcode": 50220,
        "district_code": 500513,
        "amphoe_code": 5005,
        "province_code": 50
    },
    {
        "district": "ลวงเหนือ",
        "amphoe": "ดอยสะเก็ด",
        "province": "เชียงใหม่",
        "zipcode": 50220,
        "district_code": 500503,
        "amphoe_code": 5005,
        "province_code": 50
    },
    {
        "district": "สง่าบ้าน",
        "amphoe": "ดอยสะเก็ด",
        "province": "เชียงใหม่",
        "zipcode": 50220,
        "district_code": 500505,
        "amphoe_code": 5005,
        "province_code": 50
    },
    {
        "district": "สันปูเลย",
        "amphoe": "ดอยสะเก็ด",
        "province": "เชียงใหม่",
        "zipcode": 50220,
        "district_code": 500502,
        "amphoe_code": 5005,
        "province_code": 50
    },
    {
        "district": "สำราญราษฎร์",
        "amphoe": "ดอยสะเก็ด",
        "province": "เชียงใหม่",
        "zipcode": 50220,
        "district_code": 500508,
        "amphoe_code": 5005,
        "province_code": 50
    },
    {
        "district": "เชิงดอย",
        "amphoe": "ดอยสะเก็ด",
        "province": "เชียงใหม่",
        "zipcode": 50220,
        "district_code": 500501,
        "amphoe_code": 5005,
        "province_code": 50
    },
    {
        "district": "เทพเสด็จ",
        "amphoe": "ดอยสะเก็ด",
        "province": "เชียงใหม่",
        "zipcode": 50220,
        "district_code": 500514,
        "amphoe_code": 5005,
        "province_code": 50
    },
    {
        "district": "แม่คือ",
        "amphoe": "ดอยสะเก็ด",
        "province": "เชียงใหม่",
        "zipcode": 50220,
        "district_code": 500509,
        "amphoe_code": 5005,
        "province_code": 50
    },
    {
        "district": "แม่ฮ้อยเงิน",
        "amphoe": "ดอยสะเก็ด",
        "province": "เชียงใหม่",
        "zipcode": 50220,
        "district_code": 500511,
        "amphoe_code": 5005,
        "province_code": 50
    },
    {
        "district": "แม่โป่ง",
        "amphoe": "ดอยสะเก็ด",
        "province": "เชียงใหม่",
        "zipcode": 50220,
        "district_code": 500512,
        "amphoe_code": 5005,
        "province_code": 50
    },
    {
        "district": "ดอยหล่อ",
        "amphoe": "ดอยหล่อ",
        "province": "เชียงใหม่",
        "zipcode": 50160,
        "district_code": 500208,
        "amphoe_code": 5024,
        "province_code": 50
    },
    {
        "district": "ยางคราม",
        "amphoe": "ดอยหล่อ",
        "province": "เชียงใหม่",
        "zipcode": 50160,
        "district_code": 500201,
        "amphoe_code": 5024,
        "province_code": 50
    },
    {
        "district": "สองแคว",
        "amphoe": "ดอยหล่อ",
        "province": "เชียงใหม่",
        "zipcode": 50160,
        "district_code": 500202,
        "amphoe_code": 5024,
        "province_code": 50
    },
    {
        "district": "สันติสุข",
        "amphoe": "ดอยหล่อ",
        "province": "เชียงใหม่",
        "zipcode": 50160,
        "district_code": 500210,
        "amphoe_code": 5024,
        "province_code": 50
    },
    {
        "district": "ดอยเต่า",
        "amphoe": "ดอยเต่า",
        "province": "เชียงใหม่",
        "zipcode": 50260,
        "district_code": 501701,
        "amphoe_code": 5017,
        "province_code": 50
    },
    {
        "district": "ท่าเดื่อ",
        "amphoe": "ดอยเต่า",
        "province": "เชียงใหม่",
        "zipcode": 50260,
        "district_code": 501702,
        "amphoe_code": 5017,
        "province_code": 50
    },
    {
        "district": "บงตัน",
        "amphoe": "ดอยเต่า",
        "province": "เชียงใหม่",
        "zipcode": 50260,
        "district_code": 501705,
        "amphoe_code": 5017,
        "province_code": 50
    },
    {
        "district": "บ้านแอ่น",
        "amphoe": "ดอยเต่า",
        "province": "เชียงใหม่",
        "zipcode": 50260,
        "district_code": 501704,
        "amphoe_code": 5017,
        "province_code": 50
    },
    {
        "district": "มืดกา",
        "amphoe": "ดอยเต่า",
        "province": "เชียงใหม่",
        "zipcode": 50260,
        "district_code": 501703,
        "amphoe_code": 5017,
        "province_code": 50
    },
    {
        "district": "โปงทุ่ง",
        "amphoe": "ดอยเต่า",
        "province": "เชียงใหม่",
        "zipcode": 50260,
        "district_code": 501706,
        "amphoe_code": 5017,
        "province_code": 50
    },
    {
        "district": "ม่อนปิ่น",
        "amphoe": "ฝาง",
        "province": "เชียงใหม่",
        "zipcode": 50110,
        "district_code": 500903,
        "amphoe_code": 5009,
        "province_code": 50
    },
    {
        "district": "สันทราย",
        "amphoe": "ฝาง",
        "province": "เชียงใหม่",
        "zipcode": 50110,
        "district_code": 500906,
        "amphoe_code": 5009,
        "province_code": 50
    },
    {
        "district": "เวียง",
        "amphoe": "ฝาง",
        "province": "เชียงใหม่",
        "zipcode": 50110,
        "district_code": 500901,
        "amphoe_code": 5009,
        "province_code": 50
    },
    {
        "district": "แม่ข่า",
        "amphoe": "ฝาง",
        "province": "เชียงใหม่",
        "zipcode": 50320,
        "district_code": 500911,
        "amphoe_code": 5009,
        "province_code": 50
    },
    {
        "district": "แม่คะ",
        "amphoe": "ฝาง",
        "province": "เชียงใหม่",
        "zipcode": 50110,
        "district_code": 500910,
        "amphoe_code": 5009,
        "province_code": 50
    },
    {
        "district": "แม่งอน",
        "amphoe": "ฝาง",
        "province": "เชียงใหม่",
        "zipcode": 50320,
        "district_code": 500904,
        "amphoe_code": 5009,
        "province_code": 50
    },
    {
        "district": "แม่สูน",
        "amphoe": "ฝาง",
        "province": "เชียงใหม่",
        "zipcode": 50110,
        "district_code": 500905,
        "amphoe_code": 5009,
        "province_code": 50
    },
    {
        "district": "โป่งน้ำร้อน",
        "amphoe": "ฝาง",
        "province": "เชียงใหม่",
        "zipcode": 50110,
        "district_code": 500912,
        "amphoe_code": 5009,
        "province_code": 50
    },
    {
        "district": "ทุ่งหลวง",
        "amphoe": "พร้าว",
        "province": "เชียงใหม่",
        "zipcode": 50190,
        "district_code": 501102,
        "amphoe_code": 5011,
        "province_code": 50
    },
    {
        "district": "น้ำแพร่",
        "amphoe": "พร้าว",
        "province": "เชียงใหม่",
        "zipcode": 50190,
        "district_code": 501107,
        "amphoe_code": 5011,
        "province_code": 50
    },
    {
        "district": "บ้านโป่ง",
        "amphoe": "พร้าว",
        "province": "เชียงใหม่",
        "zipcode": 50190,
        "district_code": 501106,
        "amphoe_code": 5011,
        "province_code": 50
    },
    {
        "district": "ป่าตุ้ม",
        "amphoe": "พร้าว",
        "province": "เชียงใหม่",
        "zipcode": 50190,
        "district_code": 501103,
        "amphoe_code": 5011,
        "province_code": 50
    },
    {
        "district": "ป่าไหน่",
        "amphoe": "พร้าว",
        "province": "เชียงใหม่",
        "zipcode": 50190,
        "district_code": 501104,
        "amphoe_code": 5011,
        "province_code": 50
    },
    {
        "district": "สันทราย",
        "amphoe": "พร้าว",
        "province": "เชียงใหม่",
        "zipcode": 50190,
        "district_code": 500906,
        "amphoe_code": 5011,
        "province_code": 50
    },
    {
        "district": "เขื่อนผาก",
        "amphoe": "พร้าว",
        "province": "เชียงใหม่",
        "zipcode": 50190,
        "district_code": 501108,
        "amphoe_code": 5011,
        "province_code": 50
    },
    {
        "district": "เวียง",
        "amphoe": "พร้าว",
        "province": "เชียงใหม่",
        "zipcode": 50190,
        "district_code": 500901,
        "amphoe_code": 5011,
        "province_code": 50
    },
    {
        "district": "แม่ปั๋ง",
        "amphoe": "พร้าว",
        "province": "เชียงใหม่",
        "zipcode": 50190,
        "district_code": 501110,
        "amphoe_code": 5011,
        "province_code": 50
    },
    {
        "district": "แม่แวน",
        "amphoe": "พร้าว",
        "province": "เชียงใหม่",
        "zipcode": 50190,
        "district_code": 501109,
        "amphoe_code": 5011,
        "province_code": 50
    },
    {
        "district": "โหล่งขอด",
        "amphoe": "พร้าว",
        "province": "เชียงใหม่",
        "zipcode": 50190,
        "district_code": 501111,
        "amphoe_code": 5011,
        "province_code": 50
    },
    {
        "district": "บ่อแก้ว",
        "amphoe": "สะเมิง",
        "province": "เชียงใหม่",
        "zipcode": 50250,
        "district_code": 500804,
        "amphoe_code": 5008,
        "province_code": 50
    },
    {
        "district": "ยั้งเมิน",
        "amphoe": "สะเมิง",
        "province": "เชียงใหม่",
        "zipcode": 50250,
        "district_code": 500805,
        "amphoe_code": 5008,
        "province_code": 50
    },
    {
        "district": "สะเมิงเหนือ",
        "amphoe": "สะเมิง",
        "province": "เชียงใหม่",
        "zipcode": 50250,
        "district_code": 500802,
        "amphoe_code": 5008,
        "province_code": 50
    },
    {
        "district": "สะเมิงใต้",
        "amphoe": "สะเมิง",
        "province": "เชียงใหม่",
        "zipcode": 50250,
        "district_code": 500801,
        "amphoe_code": 5008,
        "province_code": 50
    },
    {
        "district": "แม่สาบ",
        "amphoe": "สะเมิง",
        "province": "เชียงใหม่",
        "zipcode": 50250,
        "district_code": 500803,
        "amphoe_code": 5008,
        "province_code": 50
    },
    {
        "district": "ต้นเปา",
        "amphoe": "สันกำแพง",
        "province": "เชียงใหม่",
        "zipcode": 50130,
        "district_code": 501312,
        "amphoe_code": 5013,
        "province_code": 50
    },
    {
        "district": "ทรายมูล",
        "amphoe": "สันกำแพง",
        "province": "เชียงใหม่",
        "zipcode": 50130,
        "district_code": 501302,
        "amphoe_code": 5013,
        "province_code": 50
    },
    {
        "district": "บวกค้าง",
        "amphoe": "สันกำแพง",
        "province": "เชียงใหม่",
        "zipcode": 50130,
        "district_code": 501304,
        "amphoe_code": 5013,
        "province_code": 50
    },
    {
        "district": "ร้องวัวแดง",
        "amphoe": "สันกำแพง",
        "province": "เชียงใหม่",
        "zipcode": 50130,
        "district_code": 501303,
        "amphoe_code": 5013,
        "province_code": 50
    },
    {
        "district": "สันกลาง",
        "amphoe": "สันกำแพง",
        "province": "เชียงใหม่",
        "zipcode": 50130,
        "district_code": 501202,
        "amphoe_code": 5013,
        "province_code": 50
    },
    {
        "district": "สันกำแพง",
        "amphoe": "สันกำแพง",
        "province": "เชียงใหม่",
        "zipcode": 50130,
        "district_code": 501301,
        "amphoe_code": 5013,
        "province_code": 50
    },
    {
        "district": "ห้วยทราย",
        "amphoe": "สันกำแพง",
        "province": "เชียงใหม่",
        "zipcode": 50130,
        "district_code": 500706,
        "amphoe_code": 5013,
        "province_code": 50
    },
    {
        "district": "ออนใต้",
        "amphoe": "สันกำแพง",
        "province": "เชียงใหม่",
        "zipcode": 50130,
        "district_code": 501306,
        "amphoe_code": 5013,
        "province_code": 50
    },
    {
        "district": "แช่ช้าง",
        "amphoe": "สันกำแพง",
        "province": "เชียงใหม่",
        "zipcode": 50130,
        "district_code": 501305,
        "amphoe_code": 5013,
        "province_code": 50
    },
    {
        "district": "แม่ปูคา",
        "amphoe": "สันกำแพง",
        "province": "เชียงใหม่",
        "zipcode": 50130,
        "district_code": 501310,
        "amphoe_code": 5013,
        "province_code": 50
    },
    {
        "district": "ป่าไผ่",
        "amphoe": "สันทราย",
        "province": "เชียงใหม่",
        "zipcode": 50210,
        "district_code": 501412,
        "amphoe_code": 5014,
        "province_code": 50
    },
    {
        "district": "สันทรายน้อย",
        "amphoe": "สันทราย",
        "province": "เชียงใหม่",
        "zipcode": 50210,
        "district_code": 501402,
        "amphoe_code": 5014,
        "province_code": 50
    },
    {
        "district": "สันทรายหลวง",
        "amphoe": "สันทราย",
        "province": "เชียงใหม่",
        "zipcode": 50210,
        "district_code": 501401,
        "amphoe_code": 5014,
        "province_code": 50
    },
    {
        "district": "สันนาเม็ง",
        "amphoe": "สันทราย",
        "province": "เชียงใหม่",
        "zipcode": 50210,
        "district_code": 501404,
        "amphoe_code": 5014,
        "province_code": 50
    },
    {
        "district": "สันป่าเปา",
        "amphoe": "สันทราย",
        "province": "เชียงใหม่",
        "zipcode": 50210,
        "district_code": 501405,
        "amphoe_code": 5014,
        "province_code": 50
    },
    {
        "district": "สันพระเนตร",
        "amphoe": "สันทราย",
        "province": "เชียงใหม่",
        "zipcode": 50210,
        "district_code": 501403,
        "amphoe_code": 5014,
        "province_code": 50
    },
    {
        "district": "หนองจ๊อม",
        "amphoe": "สันทราย",
        "province": "เชียงใหม่",
        "zipcode": 50210,
        "district_code": 501407,
        "amphoe_code": 5014,
        "province_code": 50
    },
    {
        "district": "หนองหาร",
        "amphoe": "สันทราย",
        "province": "เชียงใหม่",
        "zipcode": 50290,
        "district_code": 501408,
        "amphoe_code": 5014,
        "province_code": 50
    },
    {
        "district": "หนองแหย่ง",
        "amphoe": "สันทราย",
        "province": "เชียงใหม่",
        "zipcode": 50210,
        "district_code": 501406,
        "amphoe_code": 5014,
        "province_code": 50
    },
    {
        "district": "เมืองเล็น",
        "amphoe": "สันทราย",
        "province": "เชียงใหม่",
        "zipcode": 50210,
        "district_code": 501411,
        "amphoe_code": 5014,
        "province_code": 50
    },
    {
        "district": "แม่แฝก",
        "amphoe": "สันทราย",
        "province": "เชียงใหม่",
        "zipcode": 50290,
        "district_code": 501409,
        "amphoe_code": 5014,
        "province_code": 50
    },
    {
        "district": "แม่แฝกใหม่",
        "amphoe": "สันทราย",
        "province": "เชียงใหม่",
        "zipcode": 50290,
        "district_code": 501410,
        "amphoe_code": 5014,
        "province_code": 50
    },
    {
        "district": "ทุ่งต้อม",
        "amphoe": "สันป่าตอง",
        "province": "เชียงใหม่",
        "zipcode": 50120,
        "district_code": 501210,
        "amphoe_code": 5012,
        "province_code": 50
    },
    {
        "district": "ทุ่งสะโตก",
        "amphoe": "สันป่าตอง",
        "province": "เชียงใหม่",
        "zipcode": 50120,
        "district_code": 501208,
        "amphoe_code": 5012,
        "province_code": 50
    },
    {
        "district": "ท่าวังพร้าว",
        "amphoe": "สันป่าตอง",
        "province": "เชียงใหม่",
        "zipcode": 50120,
        "district_code": 501203,
        "amphoe_code": 5012,
        "province_code": 50
    },
    {
        "district": "น้ำบ่อหลวง",
        "amphoe": "สันป่าตอง",
        "province": "เชียงใหม่",
        "zipcode": 50120,
        "district_code": 501214,
        "amphoe_code": 5012,
        "province_code": 50
    },
    {
        "district": "บ้านกลาง",
        "amphoe": "สันป่าตอง",
        "province": "เชียงใหม่",
        "zipcode": 50120,
        "district_code": 501207,
        "amphoe_code": 5012,
        "province_code": 50
    },
    {
        "district": "บ้านแม",
        "amphoe": "สันป่าตอง",
        "province": "เชียงใหม่",
        "zipcode": 50120,
        "district_code": 501206,
        "amphoe_code": 5012,
        "province_code": 50
    },
    {
        "district": "มะขามหลวง",
        "amphoe": "สันป่าตอง",
        "province": "เชียงใหม่",
        "zipcode": 50120,
        "district_code": 501204,
        "amphoe_code": 5012,
        "province_code": 50
    },
    {
        "district": "มะขุนหวาน",
        "amphoe": "สันป่าตอง",
        "province": "เชียงใหม่",
        "zipcode": 50120,
        "district_code": 501215,
        "amphoe_code": 5012,
        "province_code": 50
    },
    {
        "district": "ยุหว่า",
        "amphoe": "สันป่าตอง",
        "province": "เชียงใหม่",
        "zipcode": 50120,
        "district_code": 501201,
        "amphoe_code": 5012,
        "province_code": 50
    },
    {
        "district": "สันกลาง",
        "amphoe": "สันป่าตอง",
        "province": "เชียงใหม่",
        "zipcode": 50120,
        "district_code": 501202,
        "amphoe_code": 5012,
        "province_code": 50
    },
    {
        "district": "แม่ก๊า",
        "amphoe": "สันป่าตอง",
        "province": "เชียงใหม่",
        "zipcode": 50120,
        "district_code": 501205,
        "amphoe_code": 5012,
        "province_code": 50
    },
    {
        "district": "ขัวมุง",
        "amphoe": "สารภี",
        "province": "เชียงใหม่",
        "zipcode": 50140,
        "district_code": 501905,
        "amphoe_code": 5019,
        "province_code": 50
    },
    {
        "district": "ชมภู",
        "amphoe": "สารภี",
        "province": "เชียงใหม่",
        "zipcode": 50140,
        "district_code": 501903,
        "amphoe_code": 5019,
        "province_code": 50
    },
    {
        "district": "ดอนแก้ว",
        "amphoe": "สารภี",
        "province": "เชียงใหม่",
        "zipcode": 50140,
        "district_code": 500710,
        "amphoe_code": 5019,
        "province_code": 50
    },
    {
        "district": "ท่ากว้าง",
        "amphoe": "สารภี",
        "province": "เชียงใหม่",
        "zipcode": 50140,
        "district_code": 501908,
        "amphoe_code": 5019,
        "province_code": 50
    },
    {
        "district": "ท่าวังตาล",
        "amphoe": "สารภี",
        "province": "เชียงใหม่",
        "zipcode": 50140,
        "district_code": 501910,
        "amphoe_code": 5019,
        "province_code": 50
    },
    {
        "district": "ป่าบง",
        "amphoe": "สารภี",
        "province": "เชียงใหม่",
        "zipcode": 50140,
        "district_code": 501912,
        "amphoe_code": 5019,
        "province_code": 50
    },
    {
        "district": "ยางเนิ้ง",
        "amphoe": "สารภี",
        "province": "เชียงใหม่",
        "zipcode": 50140,
        "district_code": 501901,
        "amphoe_code": 5019,
        "province_code": 50
    },
    {
        "district": "สันทราย",
        "amphoe": "สารภี",
        "province": "เชียงใหม่",
        "zipcode": 50140,
        "district_code": 500906,
        "amphoe_code": 5019,
        "province_code": 50
    },
    {
        "district": "สารภี",
        "amphoe": "สารภี",
        "province": "เชียงใหม่",
        "zipcode": 50140,
        "district_code": 501902,
        "amphoe_code": 5019,
        "province_code": 50
    },
    {
        "district": "หนองผึ้ง",
        "amphoe": "สารภี",
        "province": "เชียงใหม่",
        "zipcode": 50140,
        "district_code": 501907,
        "amphoe_code": 5019,
        "province_code": 50
    },
    {
        "district": "หนองแฝก",
        "amphoe": "สารภี",
        "province": "เชียงใหม่",
        "zipcode": 50140,
        "district_code": 501906,
        "amphoe_code": 5019,
        "province_code": 50
    },
    {
        "district": "ไชยสถาน",
        "amphoe": "สารภี",
        "province": "เชียงใหม่",
        "zipcode": 50140,
        "district_code": 501904,
        "amphoe_code": 5019,
        "province_code": 50
    },
    {
        "district": "ขุนคง",
        "amphoe": "หางดง",
        "province": "เชียงใหม่",
        "zipcode": 50230,
        "district_code": 501505,
        "amphoe_code": 5015,
        "province_code": 50
    },
    {
        "district": "น้ำแพร่",
        "amphoe": "หางดง",
        "province": "เชียงใหม่",
        "zipcode": 50230,
        "district_code": 501107,
        "amphoe_code": 5015,
        "province_code": 50
    },
    {
        "district": "บ้านปง",
        "amphoe": "หางดง",
        "province": "เชียงใหม่",
        "zipcode": 50230,
        "district_code": 501510,
        "amphoe_code": 5015,
        "province_code": 50
    },
    {
        "district": "บ้านแหวน",
        "amphoe": "หางดง",
        "province": "เชียงใหม่",
        "zipcode": 50230,
        "district_code": 501507,
        "amphoe_code": 5015,
        "province_code": 50
    },
    {
        "district": "สบแม่ข่า",
        "amphoe": "หางดง",
        "province": "เชียงใหม่",
        "zipcode": 50230,
        "district_code": 501506,
        "amphoe_code": 5015,
        "province_code": 50
    },
    {
        "district": "สันผักหวาน",
        "amphoe": "หางดง",
        "province": "เชียงใหม่",
        "zipcode": 50230,
        "district_code": 501508,
        "amphoe_code": 5015,
        "province_code": 50
    },
    {
        "district": "หนองควาย",
        "amphoe": "หางดง",
        "province": "เชียงใหม่",
        "zipcode": 50230,
        "district_code": 501509,
        "amphoe_code": 5015,
        "province_code": 50
    },
    {
        "district": "หนองตอง",
        "amphoe": "หางดง",
        "province": "เชียงใหม่",
        "zipcode": 50340,
        "district_code": 501504,
        "amphoe_code": 5015,
        "province_code": 50
    },
    {
        "district": "หนองแก๋ว",
        "amphoe": "หางดง",
        "province": "เชียงใหม่",
        "zipcode": 50230,
        "district_code": 501502,
        "amphoe_code": 5015,
        "province_code": 50
    },
    {
        "district": "หางดง",
        "amphoe": "หางดง",
        "province": "เชียงใหม่",
        "zipcode": 50230,
        "district_code": 501501,
        "amphoe_code": 5015,
        "province_code": 50
    },
    {
        "district": "หารแก้ว",
        "amphoe": "หางดง",
        "province": "เชียงใหม่",
        "zipcode": 50230,
        "district_code": 501503,
        "amphoe_code": 5015,
        "province_code": 50
    },
    {
        "district": "นาเกียน",
        "amphoe": "อมก๋อย",
        "province": "เชียงใหม่",
        "zipcode": 50310,
        "district_code": 501806,
        "amphoe_code": 5018,
        "province_code": 50
    },
    {
        "district": "ม่อนจอง",
        "amphoe": "อมก๋อย",
        "province": "เชียงใหม่",
        "zipcode": 50310,
        "district_code": 501804,
        "amphoe_code": 5018,
        "province_code": 50
    },
    {
        "district": "ยางเปียง",
        "amphoe": "อมก๋อย",
        "province": "เชียงใหม่",
        "zipcode": 50310,
        "district_code": 501802,
        "amphoe_code": 5018,
        "province_code": 50
    },
    {
        "district": "สบโขง",
        "amphoe": "อมก๋อย",
        "province": "เชียงใหม่",
        "zipcode": 50310,
        "district_code": 501805,
        "amphoe_code": 5018,
        "province_code": 50
    },
    {
        "district": "อมก๋อย",
        "amphoe": "อมก๋อย",
        "province": "เชียงใหม่",
        "zipcode": 50310,
        "district_code": 501801,
        "amphoe_code": 5018,
        "province_code": 50
    },
    {
        "district": "แม่ตื่น",
        "amphoe": "อมก๋อย",
        "province": "เชียงใหม่",
        "zipcode": 50310,
        "district_code": 501803,
        "amphoe_code": 5018,
        "province_code": 50
    },
    {
        "district": "นาคอเรือ",
        "amphoe": "ฮอด",
        "province": "เชียงใหม่",
        "zipcode": 50240,
        "district_code": 501606,
        "amphoe_code": 5016,
        "province_code": 50
    },
    {
        "district": "บ่อสลี",
        "amphoe": "ฮอด",
        "province": "เชียงใหม่",
        "zipcode": 50240,
        "district_code": 501605,
        "amphoe_code": 5016,
        "province_code": 50
    },
    {
        "district": "บ่อหลวง",
        "amphoe": "ฮอด",
        "province": "เชียงใหม่",
        "zipcode": 50240,
        "district_code": 501604,
        "amphoe_code": 5016,
        "province_code": 50
    },
    {
        "district": "บ้านตาล",
        "amphoe": "ฮอด",
        "province": "เชียงใหม่",
        "zipcode": 50240,
        "district_code": 501603,
        "amphoe_code": 5016,
        "province_code": 50
    },
    {
        "district": "หางดง",
        "amphoe": "ฮอด",
        "province": "เชียงใหม่",
        "zipcode": 50240,
        "district_code": 501501,
        "amphoe_code": 5016,
        "province_code": 50
    },
    {
        "district": "ฮอด",
        "amphoe": "ฮอด",
        "province": "เชียงใหม่",
        "zipcode": 50240,
        "district_code": 501602,
        "amphoe_code": 5016,
        "province_code": 50
    },
    {
        "district": "ทุ่งข้าวพวง",
        "amphoe": "เชียงดาว",
        "province": "เชียงใหม่",
        "zipcode": 50170,
        "district_code": 500407,
        "amphoe_code": 5004,
        "province_code": 50
    },
    {
        "district": "ปิงโค้ง",
        "amphoe": "เชียงดาว",
        "province": "เชียงใหม่",
        "zipcode": 50170,
        "district_code": 500406,
        "amphoe_code": 5004,
        "province_code": 50
    },
    {
        "district": "เชียงดาว",
        "amphoe": "เชียงดาว",
        "province": "เชียงใหม่",
        "zipcode": 50170,
        "district_code": 500401,
        "amphoe_code": 5004,
        "province_code": 50
    },
    {
        "district": "เมืองคอง",
        "amphoe": "เชียงดาว",
        "province": "เชียงใหม่",
        "zipcode": 50170,
        "district_code": 500405,
        "amphoe_code": 5004,
        "province_code": 50
    },
    {
        "district": "เมืองงาย",
        "amphoe": "เชียงดาว",
        "province": "เชียงใหม่",
        "zipcode": 50170,
        "district_code": 500403,
        "amphoe_code": 5004,
        "province_code": 50
    },
    {
        "district": "เมืองนะ",
        "amphoe": "เชียงดาว",
        "province": "เชียงใหม่",
        "zipcode": 50170,
        "district_code": 500402,
        "amphoe_code": 5004,
        "province_code": 50
    },
    {
        "district": "แม่นะ",
        "amphoe": "เชียงดาว",
        "province": "เชียงใหม่",
        "zipcode": 50170,
        "district_code": 500404,
        "amphoe_code": 5004,
        "province_code": 50
    },
    {
        "district": "ช้างคลาน",
        "amphoe": "เมืองเชียงใหม่",
        "province": "เชียงใหม่",
        "zipcode": 50100,
        "district_code": 500105,
        "amphoe_code": 5001,
        "province_code": 50
    },
    {
        "district": "ช้างม่อย",
        "amphoe": "เมืองเชียงใหม่",
        "province": "เชียงใหม่",
        "zipcode": 50300,
        "district_code": 500104,
        "amphoe_code": 5001,
        "province_code": 50
    },
    {
        "district": "ช้างเผือก",
        "amphoe": "เมืองเชียงใหม่",
        "province": "เชียงใหม่",
        "zipcode": 50300,
        "district_code": 500107,
        "amphoe_code": 5001,
        "province_code": 50
    },
    {
        "district": "ท่าศาลา",
        "amphoe": "เมืองเชียงใหม่",
        "province": "เชียงใหม่",
        "zipcode": 50000,
        "district_code": 500112,
        "amphoe_code": 5001,
        "province_code": 50
    },
    {
        "district": "ป่าตัน",
        "amphoe": "เมืองเชียงใหม่",
        "province": "เชียงใหม่",
        "zipcode": 50300,
        "district_code": 500115,
        "amphoe_code": 5001,
        "province_code": 50
    },
    {
        "district": "ป่าแดด",
        "amphoe": "เมืองเชียงใหม่",
        "province": "เชียงใหม่",
        "zipcode": 50100,
        "district_code": 500110,
        "amphoe_code": 5001,
        "province_code": 50
    },
    {
        "district": "พระสิงห์",
        "amphoe": "เมืองเชียงใหม่",
        "province": "เชียงใหม่",
        "zipcode": 50200,
        "district_code": 500102,
        "amphoe_code": 5001,
        "province_code": 50
    },
    {
        "district": "ฟ้าฮ่าม",
        "amphoe": "เมืองเชียงใหม่",
        "province": "เชียงใหม่",
        "zipcode": 50000,
        "district_code": 500114,
        "amphoe_code": 5001,
        "province_code": 50
    },
    {
        "district": "วัดเกต",
        "amphoe": "เมืองเชียงใหม่",
        "province": "เชียงใหม่",
        "zipcode": 50000,
        "district_code": 500106,
        "amphoe_code": 5001,
        "province_code": 50
    },
    {
        "district": "ศรีภูมิ",
        "amphoe": "เมืองเชียงใหม่",
        "province": "เชียงใหม่",
        "zipcode": 50200,
        "district_code": 500101,
        "amphoe_code": 5001,
        "province_code": 50
    },
    {
        "district": "สันผีเสื้อ",
        "amphoe": "เมืองเชียงใหม่",
        "province": "เชียงใหม่",
        "zipcode": 50300,
        "district_code": 500116,
        "amphoe_code": 5001,
        "province_code": 50
    },
    {
        "district": "สุเทพ",
        "amphoe": "เมืองเชียงใหม่",
        "province": "เชียงใหม่",
        "zipcode": 50200,
        "district_code": 500108,
        "amphoe_code": 5001,
        "province_code": 50
    },
    {
        "district": "หนองป่าครั่ง",
        "amphoe": "เมืองเชียงใหม่",
        "province": "เชียงใหม่",
        "zipcode": 50000,
        "district_code": 500113,
        "amphoe_code": 5001,
        "province_code": 50
    },
    {
        "district": "หนองหอย",
        "amphoe": "เมืองเชียงใหม่",
        "province": "เชียงใหม่",
        "zipcode": 50000,
        "district_code": 500111,
        "amphoe_code": 5001,
        "province_code": 50
    },
    {
        "district": "หายยา",
        "amphoe": "เมืองเชียงใหม่",
        "province": "เชียงใหม่",
        "zipcode": 50100,
        "district_code": 500103,
        "amphoe_code": 5001,
        "province_code": 50
    },
    {
        "district": "แม่เหียะ",
        "amphoe": "เมืองเชียงใหม่",
        "province": "เชียงใหม่",
        "zipcode": 50100,
        "district_code": 500109,
        "amphoe_code": 5001,
        "province_code": 50
    },
    {
        "district": "เปียงหลวง",
        "amphoe": "เวียงแหง",
        "province": "เชียงใหม่",
        "zipcode": 50350,
        "district_code": 502002,
        "amphoe_code": 5020,
        "province_code": 50
    },
    {
        "district": "เมืองแหง",
        "amphoe": "เวียงแหง",
        "province": "เชียงใหม่",
        "zipcode": 50350,
        "district_code": 502001,
        "amphoe_code": 5020,
        "province_code": 50
    },
    {
        "district": "แสนไห",
        "amphoe": "เวียงแหง",
        "province": "เชียงใหม่",
        "zipcode": 50350,
        "district_code": 502003,
        "amphoe_code": 5020,
        "province_code": 50
    },
    {
        "district": "ขี้เหล็ก",
        "amphoe": "แม่ริม",
        "province": "เชียงใหม่",
        "zipcode": 50180,
        "district_code": 500603,
        "amphoe_code": 5007,
        "province_code": 50
    },
    {
        "district": "ดอนแก้ว",
        "amphoe": "แม่ริม",
        "province": "เชียงใหม่",
        "zipcode": 50180,
        "district_code": 500710,
        "amphoe_code": 5007,
        "province_code": 50
    },
    {
        "district": "ริมเหนือ",
        "amphoe": "แม่ริม",
        "province": "เชียงใหม่",
        "zipcode": 50180,
        "district_code": 500702,
        "amphoe_code": 5007,
        "province_code": 50
    },
    {
        "district": "ริมใต้",
        "amphoe": "แม่ริม",
        "province": "เชียงใหม่",
        "zipcode": 50180,
        "district_code": 500701,
        "amphoe_code": 5007,
        "province_code": 50
    },
    {
        "district": "สะลวง",
        "amphoe": "แม่ริม",
        "province": "เชียงใหม่",
        "zipcode": 50330,
        "district_code": 500705,
        "amphoe_code": 5007,
        "province_code": 50
    },
    {
        "district": "สันโป่ง",
        "amphoe": "แม่ริม",
        "province": "เชียงใหม่",
        "zipcode": 50180,
        "district_code": 500703,
        "amphoe_code": 5007,
        "province_code": 50
    },
    {
        "district": "ห้วยทราย",
        "amphoe": "แม่ริม",
        "province": "เชียงใหม่",
        "zipcode": 50180,
        "district_code": 500706,
        "amphoe_code": 5007,
        "province_code": 50
    },
    {
        "district": "เหมืองแก้ว",
        "amphoe": "แม่ริม",
        "province": "เชียงใหม่",
        "zipcode": 50180,
        "district_code": 500711,
        "amphoe_code": 5007,
        "province_code": 50
    },
    {
        "district": "แม่สา",
        "amphoe": "แม่ริม",
        "province": "เชียงใหม่",
        "zipcode": 50180,
        "district_code": 500709,
        "amphoe_code": 5007,
        "province_code": 50
    },
    {
        "district": "แม่แรม",
        "amphoe": "แม่ริม",
        "province": "เชียงใหม่",
        "zipcode": 50180,
        "district_code": 500707,
        "amphoe_code": 5007,
        "province_code": 50
    },
    {
        "district": "โป่งแยง",
        "amphoe": "แม่ริม",
        "province": "เชียงใหม่",
        "zipcode": 50180,
        "district_code": 500708,
        "amphoe_code": 5007,
        "province_code": 50
    },
    {
        "district": "ดอนเปา",
        "amphoe": "แม่วาง",
        "province": "เชียงใหม่",
        "zipcode": 50360,
        "district_code": 502205,
        "amphoe_code": 5022,
        "province_code": 50
    },
    {
        "district": "ทุ่งปี้",
        "amphoe": "แม่วาง",
        "province": "เชียงใหม่",
        "zipcode": 50360,
        "district_code": 501209,
        "amphoe_code": 5022,
        "province_code": 50
    },
    {
        "district": "ทุ่งรวงทอง",
        "amphoe": "แม่วาง",
        "province": "เชียงใหม่",
        "zipcode": 50360,
        "district_code": 501213,
        "amphoe_code": 5022,
        "province_code": 50
    },
    {
        "district": "บ้านกาด",
        "amphoe": "แม่วาง",
        "province": "เชียงใหม่",
        "zipcode": 50360,
        "district_code": 501211,
        "amphoe_code": 5022,
        "province_code": 50
    },
    {
        "district": "แม่วิน",
        "amphoe": "แม่วาง",
        "province": "เชียงใหม่",
        "zipcode": 50360,
        "district_code": 501212,
        "amphoe_code": 5022,
        "province_code": 50
    },
    {
        "district": "ทาเหนือ",
        "amphoe": "แม่ออน",
        "province": "เชียงใหม่",
        "zipcode": 50130,
        "district_code": 501315,
        "amphoe_code": 5023,
        "province_code": 50
    },
    {
        "district": "บ้านสหกรณ์",
        "amphoe": "แม่ออน",
        "province": "เชียงใหม่",
        "zipcode": 50130,
        "district_code": 501308,
        "amphoe_code": 5023,
        "province_code": 50
    },
    {
        "district": "ห้วยแก้ว",
        "amphoe": "แม่ออน",
        "province": "เชียงใหม่",
        "zipcode": 50130,
        "district_code": 501309,
        "amphoe_code": 5023,
        "province_code": 50
    },
    {
        "district": "ออนกลาง",
        "amphoe": "แม่ออน",
        "province": "เชียงใหม่",
        "zipcode": 50130,
        "district_code": 501316,
        "amphoe_code": 5023,
        "province_code": 50
    },
    {
        "district": "ออนเหนือ",
        "amphoe": "แม่ออน",
        "province": "เชียงใหม่",
        "zipcode": 50130,
        "district_code": 501307,
        "amphoe_code": 5023,
        "province_code": 50
    },
    {
        "district": "แม่ทา",
        "amphoe": "แม่ออน",
        "province": "เชียงใหม่",
        "zipcode": 50130,
        "district_code": 501314,
        "amphoe_code": 5023,
        "province_code": 50
    },
    {
        "district": "ท่าตอน",
        "amphoe": "แม่อาย",
        "province": "เชียงใหม่",
        "zipcode": 50280,
        "district_code": 501005,
        "amphoe_code": 5010,
        "province_code": 50
    },
    {
        "district": "บ้านหลวง",
        "amphoe": "แม่อาย",
        "province": "เชียงใหม่",
        "zipcode": 50280,
        "district_code": 500203,
        "amphoe_code": 5010,
        "province_code": 50
    },
    {
        "district": "มะลิกา",
        "amphoe": "แม่อาย",
        "province": "เชียงใหม่",
        "zipcode": 50280,
        "district_code": 501007,
        "amphoe_code": 5010,
        "province_code": 50
    },
    {
        "district": "สันต้นหมื้อ",
        "amphoe": "แม่อาย",
        "province": "เชียงใหม่",
        "zipcode": 50280,
        "district_code": 501003,
        "amphoe_code": 5010,
        "province_code": 50
    },
    {
        "district": "แม่นาวาง",
        "amphoe": "แม่อาย",
        "province": "เชียงใหม่",
        "zipcode": 50280,
        "district_code": 500995,
        "amphoe_code": 5010,
        "province_code": 50
    },
    {
        "district": "แม่สาว",
        "amphoe": "แม่อาย",
        "province": "เชียงใหม่",
        "zipcode": 50280,
        "district_code": 500996,
        "amphoe_code": 5010,
        "province_code": 50
    },
    {
        "district": "แม่อาย",
        "amphoe": "แม่อาย",
        "province": "เชียงใหม่",
        "zipcode": 50280,
        "district_code": 500997,
        "amphoe_code": 5010,
        "province_code": 50
    },
    {
        "district": "กองแขก",
        "amphoe": "แม่แจ่ม",
        "province": "เชียงใหม่",
        "zipcode": 50270,
        "district_code": 500308,
        "amphoe_code": 5003,
        "province_code": 50
    },
    {
        "district": "ช่างเคิ่ง",
        "amphoe": "แม่แจ่ม",
        "province": "เชียงใหม่",
        "zipcode": 50270,
        "district_code": 500301,
        "amphoe_code": 5003,
        "province_code": 50
    },
    {
        "district": "ท่าผา",
        "amphoe": "แม่แจ่ม",
        "province": "เชียงใหม่",
        "zipcode": 50270,
        "district_code": 500302,
        "amphoe_code": 5003,
        "province_code": 50
    },
    {
        "district": "บ้านทับ",
        "amphoe": "แม่แจ่ม",
        "province": "เชียงใหม่",
        "zipcode": 50270,
        "district_code": 500303,
        "amphoe_code": 5003,
        "province_code": 50
    },
    {
        "district": "ปางหินฝน",
        "amphoe": "แม่แจ่ม",
        "province": "เชียงใหม่",
        "zipcode": 50270,
        "district_code": 500307,
        "amphoe_code": 5003,
        "province_code": 50
    },
    {
        "district": "แม่นาจร",
        "amphoe": "แม่แจ่ม",
        "province": "เชียงใหม่",
        "zipcode": 50270,
        "district_code": 500305,
        "amphoe_code": 5003,
        "province_code": 50
    },
    {
        "district": "แม่ศึก",
        "amphoe": "แม่แจ่ม",
        "province": "เชียงใหม่",
        "zipcode": 50270,
        "district_code": 500304,
        "amphoe_code": 5003,
        "province_code": 50
    },
    {
        "district": "กื้ดช้าง",
        "amphoe": "แม่แตง",
        "province": "เชียงใหม่",
        "zipcode": 50150,
        "district_code": 500612,
        "amphoe_code": 5006,
        "province_code": 50
    },
    {
        "district": "ขี้เหล็ก",
        "amphoe": "แม่แตง",
        "province": "เชียงใหม่",
        "zipcode": 50150,
        "district_code": 500603,
        "amphoe_code": 5006,
        "province_code": 50
    },
    {
        "district": "ช่อแล",
        "amphoe": "แม่แตง",
        "province": "เชียงใหม่",
        "zipcode": 50150,
        "district_code": 500604,
        "amphoe_code": 5006,
        "province_code": 50
    },
    {
        "district": "บ้านช้าง",
        "amphoe": "แม่แตง",
        "province": "เชียงใหม่",
        "zipcode": 50150,
        "district_code": 500611,
        "amphoe_code": 5006,
        "province_code": 50
    },
    {
        "district": "บ้านเป้า",
        "amphoe": "แม่แตง",
        "province": "เชียงใหม่",
        "zipcode": 50150,
        "district_code": 500607,
        "amphoe_code": 5006,
        "province_code": 50
    },
    {
        "district": "ป่าแป๋",
        "amphoe": "แม่แตง",
        "province": "เชียงใหม่",
        "zipcode": 50150,
        "district_code": 500609,
        "amphoe_code": 5006,
        "province_code": 50
    },
    {
        "district": "สบเปิง",
        "amphoe": "แม่แตง",
        "province": "เชียงใหม่",
        "zipcode": 50150,
        "district_code": 500606,
        "amphoe_code": 5006,
        "province_code": 50
    },
    {
        "district": "สันป่ายาง",
        "amphoe": "แม่แตง",
        "province": "เชียงใหม่",
        "zipcode": 50330,
        "district_code": 500608,
        "amphoe_code": 5006,
        "province_code": 50
    },
    {
        "district": "สันมหาพน",
        "amphoe": "แม่แตง",
        "province": "เชียงใหม่",
        "zipcode": 50150,
        "district_code": 500601,
        "amphoe_code": 5006,
        "province_code": 50
    },
    {
        "district": "อินทขิล",
        "amphoe": "แม่แตง",
        "province": "เชียงใหม่",
        "zipcode": 50150,
        "district_code": 500613,
        "amphoe_code": 5006,
        "province_code": 50
    },
    {
        "district": "เมืองก๋าย",
        "amphoe": "แม่แตง",
        "province": "เชียงใหม่",
        "zipcode": 50150,
        "district_code": 500610,
        "amphoe_code": 5006,
        "province_code": 50
    },
    {
        "district": "แม่หอพระ",
        "amphoe": "แม่แตง",
        "province": "เชียงใหม่",
        "zipcode": 50150,
        "district_code": 500605,
        "amphoe_code": 5006,
        "province_code": 50
    },
    {
        "district": "แม่แตง",
        "amphoe": "แม่แตง",
        "province": "เชียงใหม่",
        "zipcode": 50150,
        "district_code": 500602,
        "amphoe_code": 5006,
        "province_code": 50
    },
    {
        "district": "ปงตำ",
        "amphoe": "ไชยปราการ",
        "province": "เชียงใหม่",
        "zipcode": 50320,
        "district_code": 500902,
        "amphoe_code": 5021,
        "province_code": 50
    },
    {
        "district": "ศรีดงเย็น",
        "amphoe": "ไชยปราการ",
        "province": "เชียงใหม่",
        "zipcode": 50320,
        "district_code": 500907,
        "amphoe_code": 5021,
        "province_code": 50
    },
    {
        "district": "หนองบัว",
        "amphoe": "ไชยปราการ",
        "province": "เชียงใหม่",
        "zipcode": 50320,
        "district_code": 500909,
        "amphoe_code": 5021,
        "province_code": 50
    },
    {
        "district": "แม่ทะลบ",
        "amphoe": "ไชยปราการ",
        "province": "เชียงใหม่",
        "zipcode": 50320,
        "district_code": 500908,
        "amphoe_code": 5021,
        "province_code": 50
    },
    {
        "district": "ชะอำ",
        "amphoe": "ชะอำ",
        "province": "เพชรบุรี",
        "zipcode": 76120,
        "district_code": 760401,
        "amphoe_code": 7604,
        "province_code": 76
    },
    {
        "district": "ดอนขุนห้วย",
        "amphoe": "ชะอำ",
        "province": "เพชรบุรี",
        "zipcode": 76120,
        "district_code": 760409,
        "amphoe_code": 7604,
        "province_code": 76
    },
    {
        "district": "นายาง",
        "amphoe": "ชะอำ",
        "province": "เพชรบุรี",
        "zipcode": 76120,
        "district_code": 760403,
        "amphoe_code": 7604,
        "province_code": 76
    },
    {
        "district": "บางเก่า",
        "amphoe": "ชะอำ",
        "province": "เพชรบุรี",
        "zipcode": 76120,
        "district_code": 760402,
        "amphoe_code": 7604,
        "province_code": 76
    },
    {
        "district": "สามพระยา",
        "amphoe": "ชะอำ",
        "province": "เพชรบุรี",
        "zipcode": 76120,
        "district_code": 760408,
        "amphoe_code": 7604,
        "province_code": 76
    },
    {
        "district": "หนองศาลา",
        "amphoe": "ชะอำ",
        "province": "เพชรบุรี",
        "zipcode": 76120,
        "district_code": 760405,
        "amphoe_code": 7604,
        "province_code": 76
    },
    {
        "district": "ห้วยทรายเหนือ",
        "amphoe": "ชะอำ",
        "province": "เพชรบุรี",
        "zipcode": 76120,
        "district_code": 760406,
        "amphoe_code": 7604,
        "province_code": 76
    },
    {
        "district": "เขาใหญ่",
        "amphoe": "ชะอำ",
        "province": "เพชรบุรี",
        "zipcode": 76120,
        "district_code": 760404,
        "amphoe_code": 7604,
        "province_code": 76
    },
    {
        "district": "ไร่ใหม่พัฒนา",
        "amphoe": "ชะอำ",
        "province": "เพชรบุรี",
        "zipcode": 76120,
        "district_code": 760407,
        "amphoe_code": 7604,
        "province_code": 76
    },
    {
        "district": "กลัดหลวง",
        "amphoe": "ท่ายาง",
        "province": "เพชรบุรี",
        "zipcode": 76130,
        "district_code": 760511,
        "amphoe_code": 7605,
        "province_code": 76
    },
    {
        "district": "ท่าคอย",
        "amphoe": "ท่ายาง",
        "province": "เพชรบุรี",
        "zipcode": 76130,
        "district_code": 760502,
        "amphoe_code": 7605,
        "province_code": 76
    },
    {
        "district": "ท่ายาง",
        "amphoe": "ท่ายาง",
        "province": "เพชรบุรี",
        "zipcode": 76130,
        "district_code": 760501,
        "amphoe_code": 7605,
        "province_code": 76
    },
    {
        "district": "ท่าแลง",
        "amphoe": "ท่ายาง",
        "province": "เพชรบุรี",
        "zipcode": 76130,
        "district_code": 760514,
        "amphoe_code": 7605,
        "province_code": 76
    },
    {
        "district": "ท่าไม้รวก",
        "amphoe": "ท่ายาง",
        "province": "เพชรบุรี",
        "zipcode": 76130,
        "district_code": 760506,
        "amphoe_code": 7605,
        "province_code": 76
    },
    {
        "district": "บ้านในดง",
        "amphoe": "ท่ายาง",
        "province": "เพชรบุรี",
        "zipcode": 76130,
        "district_code": 760515,
        "amphoe_code": 7605,
        "province_code": 76
    },
    {
        "district": "ปึกเตียน",
        "amphoe": "ท่ายาง",
        "province": "เพชรบุรี",
        "zipcode": 76130,
        "district_code": 760512,
        "amphoe_code": 7605,
        "province_code": 76
    },
    {
        "district": "มาบปลาเค้า",
        "amphoe": "ท่ายาง",
        "province": "เพชรบุรี",
        "zipcode": 76130,
        "district_code": 760505,
        "amphoe_code": 7605,
        "province_code": 76
    },
    {
        "district": "ยางหย่อง",
        "amphoe": "ท่ายาง",
        "province": "เพชรบุรี",
        "zipcode": 76130,
        "district_code": 760503,
        "amphoe_code": 7605,
        "province_code": 76
    },
    {
        "district": "วังไคร้",
        "amphoe": "ท่ายาง",
        "province": "เพชรบุรี",
        "zipcode": 76130,
        "district_code": 760507,
        "amphoe_code": 7605,
        "province_code": 76
    },
    {
        "district": "หนองจอก",
        "amphoe": "ท่ายาง",
        "province": "เพชรบุรี",
        "zipcode": 76130,
        "district_code": 760504,
        "amphoe_code": 7605,
        "province_code": 76
    },
    {
        "district": "เขากระปุก",
        "amphoe": "ท่ายาง",
        "province": "เพชรบุรี",
        "zipcode": 76130,
        "district_code": 760513,
        "amphoe_code": 7605,
        "province_code": 76
    },
    {
        "district": "ตำหรุ",
        "amphoe": "บ้านลาด",
        "province": "เพชรบุรี",
        "zipcode": 76150,
        "district_code": 760604,
        "amphoe_code": 7606,
        "province_code": 76
    },
    {
        "district": "ถ้ำรงค์",
        "amphoe": "บ้านลาด",
        "province": "เพชรบุรี",
        "zipcode": 76150,
        "district_code": 760617,
        "amphoe_code": 7606,
        "province_code": 76
    },
    {
        "district": "ท่าช้าง",
        "amphoe": "บ้านลาด",
        "province": "เพชรบุรี",
        "zipcode": 76150,
        "district_code": 760616,
        "amphoe_code": 7606,
        "province_code": 76
    },
    {
        "district": "ท่าเสน",
        "amphoe": "บ้านลาด",
        "province": "เพชรบุรี",
        "zipcode": 76150,
        "district_code": 760607,
        "amphoe_code": 7606,
        "province_code": 76
    },
    {
        "district": "บ้านทาน",
        "amphoe": "บ้านลาด",
        "province": "เพชรบุรี",
        "zipcode": 76150,
        "district_code": 760603,
        "amphoe_code": 7606,
        "province_code": 76
    },
    {
        "district": "บ้านลาด",
        "amphoe": "บ้านลาด",
        "province": "เพชรบุรี",
        "zipcode": 76150,
        "district_code": 760601,
        "amphoe_code": 7606,
        "province_code": 76
    },
    {
        "district": "บ้านหาด",
        "amphoe": "บ้านลาด",
        "province": "เพชรบุรี",
        "zipcode": 76150,
        "district_code": 760602,
        "amphoe_code": 7606,
        "province_code": 76
    },
    {
        "district": "ลาดโพธิ์",
        "amphoe": "บ้านลาด",
        "province": "เพชรบุรี",
        "zipcode": 76150,
        "district_code": 760610,
        "amphoe_code": 7606,
        "province_code": 76
    },
    {
        "district": "สมอพลือ",
        "amphoe": "บ้านลาด",
        "province": "เพชรบุรี",
        "zipcode": 76150,
        "district_code": 760605,
        "amphoe_code": 7606,
        "province_code": 76
    },
    {
        "district": "สะพานไกร",
        "amphoe": "บ้านลาด",
        "province": "เพชรบุรี",
        "zipcode": 76150,
        "district_code": 760611,
        "amphoe_code": 7606,
        "province_code": 76
    },
    {
        "district": "หนองกระเจ็ด",
        "amphoe": "บ้านลาด",
        "province": "เพชรบุรี",
        "zipcode": 76150,
        "district_code": 760608,
        "amphoe_code": 7606,
        "province_code": 76
    },
    {
        "district": "หนองกะปุ",
        "amphoe": "บ้านลาด",
        "province": "เพชรบุรี",
        "zipcode": 76150,
        "district_code": 760609,
        "amphoe_code": 7606,
        "province_code": 76
    },
    {
        "district": "ห้วยข้อง",
        "amphoe": "บ้านลาด",
        "province": "เพชรบุรี",
        "zipcode": 76150,
        "district_code": 760615,
        "amphoe_code": 7606,
        "province_code": 76
    },
    {
        "district": "ห้วยลึก",
        "amphoe": "บ้านลาด",
        "province": "เพชรบุรี",
        "zipcode": 76150,
        "district_code": 760618,
        "amphoe_code": 7606,
        "province_code": 76
    },
    {
        "district": "โรงเข้",
        "amphoe": "บ้านลาด",
        "province": "เพชรบุรี",
        "zipcode": 76150,
        "district_code": 760613,
        "amphoe_code": 7606,
        "province_code": 76
    },
    {
        "district": "ไร่มะขาม",
        "amphoe": "บ้านลาด",
        "province": "เพชรบุรี",
        "zipcode": 76150,
        "district_code": 760606,
        "amphoe_code": 7606,
        "province_code": 76
    },
    {
        "district": "ไร่สะท้อน",
        "amphoe": "บ้านลาด",
        "province": "เพชรบุรี",
        "zipcode": 76150,
        "district_code": 760614,
        "amphoe_code": 7606,
        "province_code": 76
    },
    {
        "district": "ไร่โคก",
        "amphoe": "บ้านลาด",
        "province": "เพชรบุรี",
        "zipcode": 76150,
        "district_code": 760612,
        "amphoe_code": 7606,
        "province_code": 76
    },
    {
        "district": "ท่าแร้ง",
        "amphoe": "บ้านแหลม",
        "province": "เพชรบุรี",
        "zipcode": 76110,
        "district_code": 760709,
        "amphoe_code": 7607,
        "province_code": 76
    },
    {
        "district": "ท่าแร้งออก",
        "amphoe": "บ้านแหลม",
        "province": "เพชรบุรี",
        "zipcode": 76110,
        "district_code": 760710,
        "amphoe_code": 7607,
        "province_code": 76
    },
    {
        "district": "บางขุนไทร",
        "amphoe": "บ้านแหลม",
        "province": "เพชรบุรี",
        "zipcode": 76110,
        "district_code": 760702,
        "amphoe_code": 7607,
        "province_code": 76
    },
    {
        "district": "บางครก",
        "amphoe": "บ้านแหลม",
        "province": "เพชรบุรี",
        "zipcode": 76110,
        "district_code": 760708,
        "amphoe_code": 7607,
        "province_code": 76
    },
    {
        "district": "บางตะบูน",
        "amphoe": "บ้านแหลม",
        "province": "เพชรบุรี",
        "zipcode": 76110,
        "district_code": 760706,
        "amphoe_code": 7607,
        "province_code": 76
    },
    {
        "district": "บางตะบูนออก",
        "amphoe": "บ้านแหลม",
        "province": "เพชรบุรี",
        "zipcode": 76110,
        "district_code": 760707,
        "amphoe_code": 7607,
        "province_code": 76
    },
    {
        "district": "บางแก้ว",
        "amphoe": "บ้านแหลม",
        "province": "เพชรบุรี",
        "zipcode": 76110,
        "district_code": 760704,
        "amphoe_code": 7607,
        "province_code": 76
    },
    {
        "district": "บ้านแหลม",
        "amphoe": "บ้านแหลม",
        "province": "เพชรบุรี",
        "zipcode": 76110,
        "district_code": 760701,
        "amphoe_code": 7607,
        "province_code": 76
    },
    {
        "district": "ปากทะเล",
        "amphoe": "บ้านแหลม",
        "province": "เพชรบุรี",
        "zipcode": 76110,
        "district_code": 760703,
        "amphoe_code": 7607,
        "province_code": 76
    },
    {
        "district": "แหลมผักเบี้ย",
        "amphoe": "บ้านแหลม",
        "province": "เพชรบุรี",
        "zipcode": 76100,
        "district_code": 760705,
        "amphoe_code": 7607,
        "province_code": 76
    },
    {
        "district": "ท่าตะคร้อ",
        "amphoe": "หนองหญ้าปล้อง",
        "province": "เพชรบุรี",
        "zipcode": 76160,
        "district_code": 760304,
        "amphoe_code": 7603,
        "province_code": 76
    },
    {
        "district": "ยางน้ำกลัดเหนือ",
        "amphoe": "หนองหญ้าปล้อง",
        "province": "เพชรบุรี",
        "zipcode": 76160,
        "district_code": 760302,
        "amphoe_code": 7603,
        "province_code": 76
    },
    {
        "district": "ยางน้ำกลัดใต้",
        "amphoe": "หนองหญ้าปล้อง",
        "province": "เพชรบุรี",
        "zipcode": 76160,
        "district_code": 760303,
        "amphoe_code": 7603,
        "province_code": 76
    },
    {
        "district": "หนองหญ้าปล้อง",
        "amphoe": "หนองหญ้าปล้อง",
        "province": "เพชรบุรี",
        "zipcode": 76160,
        "district_code": 760299,
        "amphoe_code": 7603,
        "province_code": 76
    },
    {
        "district": "ทับคาง",
        "amphoe": "เขาย้อย",
        "province": "เพชรบุรี",
        "zipcode": 76140,
        "district_code": 760204,
        "amphoe_code": 7602,
        "province_code": 76
    },
    {
        "district": "บางเค็ม",
        "amphoe": "เขาย้อย",
        "province": "เพชรบุรี",
        "zipcode": 76140,
        "district_code": 760203,
        "amphoe_code": 7602,
        "province_code": 76
    },
    {
        "district": "สระพัง",
        "amphoe": "เขาย้อย",
        "province": "เพชรบุรี",
        "zipcode": 76140,
        "district_code": 760202,
        "amphoe_code": 7602,
        "province_code": 76
    },
    {
        "district": "หนองชุมพล",
        "amphoe": "เขาย้อย",
        "province": "เพชรบุรี",
        "zipcode": 76140,
        "district_code": 760207,
        "amphoe_code": 7602,
        "province_code": 76
    },
    {
        "district": "หนองชุมพลเหนือ",
        "amphoe": "เขาย้อย",
        "province": "เพชรบุรี",
        "zipcode": 76140,
        "district_code": 760210,
        "amphoe_code": 7602,
        "province_code": 76
    },
    {
        "district": "หนองปรง",
        "amphoe": "เขาย้อย",
        "province": "เพชรบุรี",
        "zipcode": 76140,
        "district_code": 760206,
        "amphoe_code": 7602,
        "province_code": 76
    },
    {
        "district": "หนองปลาไหล",
        "amphoe": "เขาย้อย",
        "province": "เพชรบุรี",
        "zipcode": 76140,
        "district_code": 760205,
        "amphoe_code": 7602,
        "province_code": 76
    },
    {
        "district": "ห้วยท่าช้าง",
        "amphoe": "เขาย้อย",
        "province": "เพชรบุรี",
        "zipcode": 76140,
        "district_code": 760209,
        "amphoe_code": 7602,
        "province_code": 76
    },
    {
        "district": "ห้วยโรง",
        "amphoe": "เขาย้อย",
        "province": "เพชรบุรี",
        "zipcode": 76140,
        "district_code": 760208,
        "amphoe_code": 7602,
        "province_code": 76
    },
    {
        "district": "เขาย้อย",
        "amphoe": "เขาย้อย",
        "province": "เพชรบุรี",
        "zipcode": 76140,
        "district_code": 760201,
        "amphoe_code": 7602,
        "province_code": 76
    },
    {
        "district": "คลองกระแชง",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760102,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "ช่องสะแก",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760113,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "ดอนยาง",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760122,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "ต้นมะพร้าว",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760119,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "ต้นมะม่วง",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760112,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "ท่าราบ",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760101,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "ธงชัย",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760105,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "นาพันสาม",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760104,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "นาวุ้ง",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760114,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "บางจาก",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760110,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "บางจาน",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760103,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "บ้านกุ่ม",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760106,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "บ้านหม้อ",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760111,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "วังตะโก",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760120,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "สำมะโรง",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760115,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "หนองขนาน",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760123,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "หนองพลับ",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760124,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "หนองโสน",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760107,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "หัวสะพาน",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760118,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "หาดเจ้าสำราญ",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76100,
        "district_code": 760117,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "เวียงคอย",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760109,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "โพพระ",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760116,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "โพไร่หวาน",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760121,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "ไร่ส้ม",
        "amphoe": "เมืองเพชรบุรี",
        "province": "เพชรบุรี",
        "zipcode": 76000,
        "district_code": 760108,
        "amphoe_code": 7601,
        "province_code": 76
    },
    {
        "district": "ป่าเด็ง",
        "amphoe": "แก่งกระจาน",
        "province": "เพชรบุรี",
        "zipcode": 76170,
        "district_code": 760804,
        "amphoe_code": 7608,
        "province_code": 76
    },
    {
        "district": "พุสวรรค์",
        "amphoe": "แก่งกระจาน",
        "province": "เพชรบุรี",
        "zipcode": 76170,
        "district_code": 760805,
        "amphoe_code": 7608,
        "province_code": 76
    },
    {
        "district": "วังจันทร์",
        "amphoe": "แก่งกระจาน",
        "province": "เพชรบุรี",
        "zipcode": 76170,
        "district_code": 760508,
        "amphoe_code": 7608,
        "province_code": 76
    },
    {
        "district": "สองพี่น้อง",
        "amphoe": "แก่งกระจาน",
        "province": "เพชรบุรี",
        "zipcode": 76170,
        "district_code": 760509,
        "amphoe_code": 7608,
        "province_code": 76
    },
    {
        "district": "ห้วยแม่เพรียง",
        "amphoe": "แก่งกระจาน",
        "province": "เพชรบุรี",
        "zipcode": 76170,
        "district_code": 760806,
        "amphoe_code": 7608,
        "province_code": 76
    },
    {
        "district": "แก่งกระจาน",
        "amphoe": "แก่งกระจาน",
        "province": "เพชรบุรี",
        "zipcode": 76170,
        "district_code": 760510,
        "amphoe_code": 7608,
        "province_code": 76
    },
    {
        "district": "ชนแดน",
        "amphoe": "ชนแดน",
        "province": "เพชรบูรณ์",
        "zipcode": 67150,
        "district_code": 670201,
        "amphoe_code": 6702,
        "province_code": 67
    },
    {
        "district": "ซับพุทรา",
        "amphoe": "ชนแดน",
        "province": "เพชรบูรณ์",
        "zipcode": 67150,
        "district_code": 670208,
        "amphoe_code": 6702,
        "province_code": 67
    },
    {
        "district": "ดงขุย",
        "amphoe": "ชนแดน",
        "province": "เพชรบูรณ์",
        "zipcode": 67190,
        "district_code": 670202,
        "amphoe_code": 6702,
        "province_code": 67
    },
    {
        "district": "ตะกุดไร",
        "amphoe": "ชนแดน",
        "province": "เพชรบูรณ์",
        "zipcode": 67190,
        "district_code": 670209,
        "amphoe_code": 6702,
        "province_code": 67
    },
    {
        "district": "ท่าข้าม",
        "amphoe": "ชนแดน",
        "province": "เพชรบูรณ์",
        "zipcode": 67150,
        "district_code": 670203,
        "amphoe_code": 6702,
        "province_code": 67
    },
    {
        "district": "บ้านกล้วย",
        "amphoe": "ชนแดน",
        "province": "เพชรบูรณ์",
        "zipcode": 67190,
        "district_code": 670206,
        "amphoe_code": 6702,
        "province_code": 67
    },
    {
        "district": "พุทธบาท",
        "amphoe": "ชนแดน",
        "province": "เพชรบูรณ์",
        "zipcode": 67150,
        "district_code": 670204,
        "amphoe_code": 6702,
        "province_code": 67
    },
    {
        "district": "ลาดแค",
        "amphoe": "ชนแดน",
        "province": "เพชรบูรณ์",
        "zipcode": 67150,
        "district_code": 670205,
        "amphoe_code": 6702,
        "province_code": 67
    },
    {
        "district": "ศาลาลาย",
        "amphoe": "ชนแดน",
        "province": "เพชรบูรณ์",
        "zipcode": 67150,
        "district_code": 670210,
        "amphoe_code": 6702,
        "province_code": 67
    },
    {
        "district": "น้ำหนาว",
        "amphoe": "น้ำหนาว",
        "province": "เพชรบูรณ์",
        "zipcode": 67260,
        "district_code": 670499,
        "amphoe_code": 6709,
        "province_code": 67
    },
    {
        "district": "วังกวาง",
        "amphoe": "น้ำหนาว",
        "province": "เพชรบูรณ์",
        "zipcode": 67260,
        "district_code": 670903,
        "amphoe_code": 6709,
        "province_code": 67
    },
    {
        "district": "หลักด่าน",
        "amphoe": "น้ำหนาว",
        "province": "เพชรบูรณ์",
        "zipcode": 67260,
        "district_code": 670902,
        "amphoe_code": 6709,
        "province_code": 67
    },
    {
        "district": "โคกมน",
        "amphoe": "น้ำหนาว",
        "province": "เพชรบูรณ์",
        "zipcode": 67260,
        "district_code": 670904,
        "amphoe_code": 6709,
        "province_code": 67
    },
    {
        "district": "กันจุ",
        "amphoe": "บึงสามพัน",
        "province": "เพชรบูรณ์",
        "zipcode": 67160,
        "district_code": 670804,
        "amphoe_code": 6708,
        "province_code": 67
    },
    {
        "district": "ซับสมอทอด",
        "amphoe": "บึงสามพัน",
        "province": "เพชรบูรณ์",
        "zipcode": 67160,
        "district_code": 670801,
        "amphoe_code": 6708,
        "province_code": 67
    },
    {
        "district": "ซับไม้แดง",
        "amphoe": "บึงสามพัน",
        "province": "เพชรบูรณ์",
        "zipcode": 67160,
        "district_code": 670802,
        "amphoe_code": 6708,
        "province_code": 67
    },
    {
        "district": "บึงสามพัน",
        "amphoe": "บึงสามพัน",
        "province": "เพชรบูรณ์",
        "zipcode": 67160,
        "district_code": 670809,
        "amphoe_code": 6708,
        "province_code": 67
    },
    {
        "district": "พญาวัง",
        "amphoe": "บึงสามพัน",
        "province": "เพชรบูรณ์",
        "zipcode": 67160,
        "district_code": 670806,
        "amphoe_code": 6708,
        "province_code": 67
    },
    {
        "district": "วังพิกุล",
        "amphoe": "บึงสามพัน",
        "province": "เพชรบูรณ์",
        "zipcode": 67230,
        "district_code": 670805,
        "amphoe_code": 6708,
        "province_code": 67
    },
    {
        "district": "ศรีมงคล",
        "amphoe": "บึงสามพัน",
        "province": "เพชรบูรณ์",
        "zipcode": 67160,
        "district_code": 670807,
        "amphoe_code": 6708,
        "province_code": 67
    },
    {
        "district": "สระแก้ว",
        "amphoe": "บึงสามพัน",
        "province": "เพชรบูรณ์",
        "zipcode": 67160,
        "district_code": 670808,
        "amphoe_code": 6708,
        "province_code": 67
    },
    {
        "district": "หนองแจง",
        "amphoe": "บึงสามพัน",
        "province": "เพชรบูรณ์",
        "zipcode": 67160,
        "district_code": 670803,
        "amphoe_code": 6708,
        "province_code": 67
    },
    {
        "district": "ซับเปิบ",
        "amphoe": "วังโป่ง",
        "province": "เพชรบูรณ์",
        "zipcode": 67240,
        "district_code": 671003,
        "amphoe_code": 6710,
        "province_code": 67
    },
    {
        "district": "ท้ายดง",
        "amphoe": "วังโป่ง",
        "province": "เพชรบูรณ์",
        "zipcode": 67240,
        "district_code": 670298,
        "amphoe_code": 6710,
        "province_code": 67
    },
    {
        "district": "วังศาล",
        "amphoe": "วังโป่ง",
        "province": "เพชรบูรณ์",
        "zipcode": 67240,
        "district_code": 671005,
        "amphoe_code": 6710,
        "province_code": 67
    },
    {
        "district": "วังหิน",
        "amphoe": "วังโป่ง",
        "province": "เพชรบูรณ์",
        "zipcode": 67240,
        "district_code": 671004,
        "amphoe_code": 6710,
        "province_code": 67
    },
    {
        "district": "วังโป่ง",
        "amphoe": "วังโป่ง",
        "province": "เพชรบูรณ์",
        "zipcode": 67240,
        "district_code": 670299,
        "amphoe_code": 6710,
        "province_code": 67
    },
    {
        "district": "ซับน้อย",
        "amphoe": "วิเชียรบุรี",
        "province": "เพชรบูรณ์",
        "zipcode": 67180,
        "district_code": 670514,
        "amphoe_code": 6705,
        "province_code": 67
    },
    {
        "district": "ซับสมบูรณ์",
        "amphoe": "วิเชียรบุรี",
        "province": "เพชรบูรณ์",
        "zipcode": 67180,
        "district_code": 670510,
        "amphoe_code": 6705,
        "province_code": 67
    },
    {
        "district": "ท่าโรง",
        "amphoe": "วิเชียรบุรี",
        "province": "เพชรบูรณ์",
        "zipcode": 67130,
        "district_code": 670501,
        "amphoe_code": 6705,
        "province_code": 67
    },
    {
        "district": "น้ำร้อน",
        "amphoe": "วิเชียรบุรี",
        "province": "เพชรบูรณ์",
        "zipcode": 67130,
        "district_code": 670114,
        "amphoe_code": 6705,
        "province_code": 67
    },
    {
        "district": "บึงกระจับ",
        "amphoe": "วิเชียรบุรี",
        "province": "เพชรบูรณ์",
        "zipcode": 67130,
        "district_code": 670511,
        "amphoe_code": 6705,
        "province_code": 67
    },
    {
        "district": "บ่อรัง",
        "amphoe": "วิเชียรบุรี",
        "province": "เพชรบูรณ์",
        "zipcode": 67130,
        "district_code": 670506,
        "amphoe_code": 6705,
        "province_code": 67
    },
    {
        "district": "พุขาม",
        "amphoe": "วิเชียรบุรี",
        "province": "เพชรบูรณ์",
        "zipcode": 67180,
        "district_code": 670508,
        "amphoe_code": 6705,
        "province_code": 67
    },
    {
        "district": "พุเตย",
        "amphoe": "วิเชียรบุรี",
        "province": "เพชรบูรณ์",
        "zipcode": 67180,
        "district_code": 670507,
        "amphoe_code": 6705,
        "province_code": 67
    },
    {
        "district": "ภูน้ำหยด",
        "amphoe": "วิเชียรบุรี",
        "province": "เพชรบูรณ์",
        "zipcode": 67180,
        "district_code": 670509,
        "amphoe_code": 6705,
        "province_code": 67
    },
    {
        "district": "ยางสาว",
        "amphoe": "วิเชียรบุรี",
        "province": "เพชรบูรณ์",
        "zipcode": 67130,
        "district_code": 670513,
        "amphoe_code": 6705,
        "province_code": 67
    },
    {
        "district": "วังใหญ่",
        "amphoe": "วิเชียรบุรี",
        "province": "เพชรบูรณ์",
        "zipcode": 67180,
        "district_code": 670512,
        "amphoe_code": 6705,
        "province_code": 67
    },
    {
        "district": "สระประดู่",
        "amphoe": "วิเชียรบุรี",
        "province": "เพชรบูรณ์",
        "zipcode": 67130,
        "district_code": 670502,
        "amphoe_code": 6705,
        "province_code": 67
    },
    {
        "district": "สามแยก",
        "amphoe": "วิเชียรบุรี",
        "province": "เพชรบูรณ์",
        "zipcode": 67130,
        "district_code": 670503,
        "amphoe_code": 6705,
        "province_code": 67
    },
    {
        "district": "โคกปรง",
        "amphoe": "วิเชียรบุรี",
        "province": "เพชรบูรณ์",
        "zipcode": 67130,
        "district_code": 670504,
        "amphoe_code": 6705,
        "province_code": 67
    },
    {
        "district": "คลองกระจัง",
        "amphoe": "ศรีเทพ",
        "province": "เพชรบูรณ์",
        "zipcode": 67170,
        "district_code": 670597,
        "amphoe_code": 6706,
        "province_code": 67
    },
    {
        "district": "นาสนุ่น",
        "amphoe": "ศรีเทพ",
        "province": "เพชรบูรณ์",
        "zipcode": 67170,
        "district_code": 670595,
        "amphoe_code": 6706,
        "province_code": 67
    },
    {
        "district": "ประดู่งาม",
        "amphoe": "ศรีเทพ",
        "province": "เพชรบูรณ์",
        "zipcode": 67170,
        "district_code": 670607,
        "amphoe_code": 6706,
        "province_code": 67
    },
    {
        "district": "ศรีเทพ",
        "amphoe": "ศรีเทพ",
        "province": "เพชรบูรณ์",
        "zipcode": 67170,
        "district_code": 670599,
        "amphoe_code": 6706,
        "province_code": 67
    },
    {
        "district": "สระกรวด",
        "amphoe": "ศรีเทพ",
        "province": "เพชรบูรณ์",
        "zipcode": 67170,
        "district_code": 670598,
        "amphoe_code": 6706,
        "province_code": 67
    },
    {
        "district": "หนองย่างทอย",
        "amphoe": "ศรีเทพ",
        "province": "เพชรบูรณ์",
        "zipcode": 67170,
        "district_code": 670606,
        "amphoe_code": 6706,
        "province_code": 67
    },
    {
        "district": "โคกสะอาด",
        "amphoe": "ศรีเทพ",
        "province": "เพชรบูรณ์",
        "zipcode": 67170,
        "district_code": 670605,
        "amphoe_code": 6706,
        "province_code": 67
    },
    {
        "district": "กองทูล",
        "amphoe": "หนองไผ่",
        "province": "เพชรบูรณ์",
        "zipcode": 67140,
        "district_code": 670701,
        "amphoe_code": 6707,
        "province_code": 67
    },
    {
        "district": "ท่าด้วง",
        "amphoe": "หนองไผ่",
        "province": "เพชรบูรณ์",
        "zipcode": 67140,
        "district_code": 670713,
        "amphoe_code": 6707,
        "province_code": 67
    },
    {
        "district": "ท่าแดง",
        "amphoe": "หนองไผ่",
        "province": "เพชรบูรณ์",
        "zipcode": 67140,
        "district_code": 670704,
        "amphoe_code": 6707,
        "province_code": 67
    },
    {
        "district": "นาเฉลียง",
        "amphoe": "หนองไผ่",
        "province": "เพชรบูรณ์",
        "zipcode": 67220,
        "district_code": 670702,
        "amphoe_code": 6707,
        "province_code": 67
    },
    {
        "district": "บัววัฒนา",
        "amphoe": "หนองไผ่",
        "province": "เพชรบูรณ์",
        "zipcode": 67140,
        "district_code": 670709,
        "amphoe_code": 6707,
        "province_code": 67
    },
    {
        "district": "บ่อไทย",
        "amphoe": "หนองไผ่",
        "province": "เพชรบูรณ์",
        "zipcode": 67140,
        "district_code": 670706,
        "amphoe_code": 6707,
        "province_code": 67
    },
    {
        "district": "บ้านโภชน์",
        "amphoe": "หนองไผ่",
        "province": "เพชรบูรณ์",
        "zipcode": 67140,
        "district_code": 670703,
        "amphoe_code": 6707,
        "province_code": 67
    },
    {
        "district": "ยางงาม",
        "amphoe": "หนองไผ่",
        "province": "เพชรบูรณ์",
        "zipcode": 67220,
        "district_code": 670712,
        "amphoe_code": 6707,
        "province_code": 67
    },
    {
        "district": "วังท่าดี",
        "amphoe": "หนองไผ่",
        "province": "เพชรบูรณ์",
        "zipcode": 67140,
        "district_code": 670708,
        "amphoe_code": 6707,
        "province_code": 67
    },
    {
        "district": "วังโบสถ์",
        "amphoe": "หนองไผ่",
        "province": "เพชรบูรณ์",
        "zipcode": 67140,
        "district_code": 670711,
        "amphoe_code": 6707,
        "province_code": 67
    },
    {
        "district": "หนองไผ่",
        "amphoe": "หนองไผ่",
        "province": "เพชรบูรณ์",
        "zipcode": 67140,
        "district_code": 670710,
        "amphoe_code": 6707,
        "province_code": 67
    },
    {
        "district": "ห้วยโป่ง",
        "amphoe": "หนองไผ่",
        "province": "เพชรบูรณ์",
        "zipcode": 67220,
        "district_code": 670707,
        "amphoe_code": 6707,
        "province_code": 67
    },
    {
        "district": "เพชรละคร",
        "amphoe": "หนองไผ่",
        "province": "เพชรบูรณ์",
        "zipcode": 67140,
        "district_code": 670705,
        "amphoe_code": 6707,
        "province_code": 67
    },
    {
        "district": "ช้างตะลูด",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670320,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "ตาลเดี่ยว",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670303,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "ท่าอิบุญ",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670308,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "น้ำก้อ",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670312,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "น้ำชุน",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670314,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "น้ำเฮี้ย",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670306,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "บุ่งคล้า",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670317,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "บุ่งน้ำเต้า",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670318,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "บ้านกลาง",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670319,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "บ้านติ้ว",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670310,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "บ้านหวาย",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670323,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "บ้านโสก",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670309,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "บ้านไร่",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670321,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "ปากช่อง",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670313,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "ปากดุก",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670322,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "ฝายนาแซง",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670304,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "ลานบ่า",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670316,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "วัดป่า",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670302,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "สักหลง",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670307,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "หนองสว่าง",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670305,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "หนองไขว่",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670315,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "หล่มสัก",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670301,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "ห้วยไร่",
        "amphoe": "หล่มสัก",
        "province": "เพชรบูรณ์",
        "zipcode": 67110,
        "district_code": 670311,
        "amphoe_code": 6703,
        "province_code": 67
    },
    {
        "district": "ตาดกลอย",
        "amphoe": "หล่มเก่า",
        "province": "เพชรบูรณ์",
        "zipcode": 67120,
        "district_code": 670409,
        "amphoe_code": 6704,
        "province_code": 67
    },
    {
        "district": "นาซำ",
        "amphoe": "หล่มเก่า",
        "province": "เพชรบูรณ์",
        "zipcode": 67120,
        "district_code": 670402,
        "amphoe_code": 6704,
        "province_code": 67
    },
    {
        "district": "นาเกาะ",
        "amphoe": "หล่มเก่า",
        "province": "เพชรบูรณ์",
        "zipcode": 67120,
        "district_code": 670408,
        "amphoe_code": 6704,
        "province_code": 67
    },
    {
        "district": "นาแซง",
        "amphoe": "หล่มเก่า",
        "province": "เพชรบูรณ์",
        "zipcode": 67120,
        "district_code": 670406,
        "amphoe_code": 6704,
        "province_code": 67
    },
    {
        "district": "บ้านเนิน",
        "amphoe": "หล่มเก่า",
        "province": "เพชรบูรณ์",
        "zipcode": 67120,
        "district_code": 670404,
        "amphoe_code": 6704,
        "province_code": 67
    },
    {
        "district": "วังบาล",
        "amphoe": "หล่มเก่า",
        "province": "เพชรบูรณ์",
        "zipcode": 67120,
        "district_code": 670407,
        "amphoe_code": 6704,
        "province_code": 67
    },
    {
        "district": "ศิลา",
        "amphoe": "หล่มเก่า",
        "province": "เพชรบูรณ์",
        "zipcode": 67120,
        "district_code": 670405,
        "amphoe_code": 6704,
        "province_code": 67
    },
    {
        "district": "หล่มเก่า",
        "amphoe": "หล่มเก่า",
        "province": "เพชรบูรณ์",
        "zipcode": 67120,
        "district_code": 670401,
        "amphoe_code": 6704,
        "province_code": 67
    },
    {
        "district": "หินฮาว",
        "amphoe": "หล่มเก่า",
        "province": "เพชรบูรณ์",
        "zipcode": 67120,
        "district_code": 670403,
        "amphoe_code": 6704,
        "province_code": 67
    },
    {
        "district": "ทุ่งสมอ",
        "amphoe": "เขาค้อ",
        "province": "เพชรบูรณ์",
        "zipcode": 67270,
        "district_code": 671101,
        "amphoe_code": 6711,
        "province_code": 67
    },
    {
        "district": "ริมสีม่วง",
        "amphoe": "เขาค้อ",
        "province": "เพชรบูรณ์",
        "zipcode": 67270,
        "district_code": 671104,
        "amphoe_code": 6711,
        "province_code": 67
    },
    {
        "district": "สะเดาะพง",
        "amphoe": "เขาค้อ",
        "province": "เพชรบูรณ์",
        "zipcode": 67270,
        "district_code": 671105,
        "amphoe_code": 6711,
        "province_code": 67
    },
    {
        "district": "หนองแม่นา",
        "amphoe": "เขาค้อ",
        "province": "เพชรบูรณ์",
        "zipcode": 67270,
        "district_code": 671106,
        "amphoe_code": 6711,
        "province_code": 67
    },
    {
        "district": "เขาค้อ",
        "amphoe": "เขาค้อ",
        "province": "เพชรบูรณ์",
        "zipcode": 67270,
        "district_code": 671103,
        "amphoe_code": 6711,
        "province_code": 67
    },
    {
        "district": "เข็กน้อย",
        "amphoe": "เขาค้อ",
        "province": "เพชรบูรณ์",
        "zipcode": 67280,
        "district_code": 671107,
        "amphoe_code": 6711,
        "province_code": 67
    },
    {
        "district": "แคมป์สน",
        "amphoe": "เขาค้อ",
        "province": "เพชรบูรณ์",
        "zipcode": 67280,
        "district_code": 670399,
        "amphoe_code": 6711,
        "province_code": 67
    },
    {
        "district": "ชอนไพร",
        "amphoe": "เมืองเพชรบูรณ์",
        "province": "เพชรบูรณ์",
        "zipcode": 67000,
        "district_code": 670110,
        "amphoe_code": 6701,
        "province_code": 67
    },
    {
        "district": "ดงมูลเหล็ก",
        "amphoe": "เมืองเพชรบูรณ์",
        "province": "เพชรบูรณ์",
        "zipcode": 67000,
        "district_code": 670108,
        "amphoe_code": 6701,
        "province_code": 67
    },
    {
        "district": "ตะเบาะ",
        "amphoe": "เมืองเพชรบูรณ์",
        "province": "เพชรบูรณ์",
        "zipcode": 67000,
        "district_code": 670102,
        "amphoe_code": 6701,
        "province_code": 67
    },
    {
        "district": "ท่าพล",
        "amphoe": "เมืองเพชรบูรณ์",
        "province": "เพชรบูรณ์",
        "zipcode": 67250,
        "district_code": 670107,
        "amphoe_code": 6701,
        "province_code": 67
    },
    {
        "district": "นางั่ว",
        "amphoe": "เมืองเพชรบูรณ์",
        "province": "เพชรบูรณ์",
        "zipcode": 67000,
        "district_code": 670106,
        "amphoe_code": 6701,
        "province_code": 67
    },
    {
        "district": "นาป่า",
        "amphoe": "เมืองเพชรบูรณ์",
        "province": "เพชรบูรณ์",
        "zipcode": 67000,
        "district_code": 670111,
        "amphoe_code": 6701,
        "province_code": 67
    },
    {
        "district": "นายม",
        "amphoe": "เมืองเพชรบูรณ์",
        "province": "เพชรบูรณ์",
        "zipcode": 67210,
        "district_code": 670112,
        "amphoe_code": 6701,
        "province_code": 67
    },
    {
        "district": "น้ำร้อน",
        "amphoe": "เมืองเพชรบูรณ์",
        "province": "เพชรบูรณ์",
        "zipcode": 67000,
        "district_code": 670114,
        "amphoe_code": 6701,
        "province_code": 67
    },
    {
        "district": "บ้านโคก",
        "amphoe": "เมืองเพชรบูรณ์",
        "province": "เพชรบูรณ์",
        "zipcode": 67000,
        "district_code": 670109,
        "amphoe_code": 6701,
        "province_code": 67
    },
    {
        "district": "บ้านโตก",
        "amphoe": "เมืองเพชรบูรณ์",
        "province": "เพชรบูรณ์",
        "zipcode": 67000,
        "district_code": 670103,
        "amphoe_code": 6701,
        "province_code": 67
    },
    {
        "district": "ป่าเลา",
        "amphoe": "เมืองเพชรบูรณ์",
        "province": "เพชรบูรณ์",
        "zipcode": 67000,
        "district_code": 670105,
        "amphoe_code": 6701,
        "province_code": 67
    },
    {
        "district": "ระวิง",
        "amphoe": "เมืองเพชรบูรณ์",
        "province": "เพชรบูรณ์",
        "zipcode": 67210,
        "district_code": 670117,
        "amphoe_code": 6701,
        "province_code": 67
    },
    {
        "district": "วังชมภู",
        "amphoe": "เมืองเพชรบูรณ์",
        "province": "เพชรบูรณ์",
        "zipcode": 67210,
        "district_code": 670113,
        "amphoe_code": 6701,
        "province_code": 67
    },
    {
        "district": "สะเดียง",
        "amphoe": "เมืองเพชรบูรณ์",
        "province": "เพชรบูรณ์",
        "zipcode": 67000,
        "district_code": 670104,
        "amphoe_code": 6701,
        "province_code": 67
    },
    {
        "district": "ห้วยสะแก",
        "amphoe": "เมืองเพชรบูรณ์",
        "province": "เพชรบูรณ์",
        "zipcode": 67210,
        "district_code": 670115,
        "amphoe_code": 6701,
        "province_code": 67
    },
    {
        "district": "ห้วยใหญ่",
        "amphoe": "เมืองเพชรบูรณ์",
        "province": "เพชรบูรณ์",
        "zipcode": 67000,
        "district_code": 670116,
        "amphoe_code": 6701,
        "province_code": 67
    },
    {
        "district": "ในเมือง",
        "amphoe": "เมืองเพชรบูรณ์",
        "province": "เพชรบูรณ์",
        "zipcode": 67000,
        "district_code": 670101,
        "amphoe_code": 6701,
        "province_code": 67
    },
    {
        "district": "กกสะทอน",
        "amphoe": "ด่านซ้าย",
        "province": "เลย",
        "zipcode": 42120,
        "district_code": 420507,
        "amphoe_code": 4205,
        "province_code": 42
    },
    {
        "district": "ด่านซ้าย",
        "amphoe": "ด่านซ้าย",
        "province": "เลย",
        "zipcode": 42120,
        "district_code": 420501,
        "amphoe_code": 4205,
        "province_code": 42
    },
    {
        "district": "นาดี",
        "amphoe": "ด่านซ้าย",
        "province": "เลย",
        "zipcode": 42120,
        "district_code": 420503,
        "amphoe_code": 4205,
        "province_code": 42
    },
    {
        "district": "นาหอ",
        "amphoe": "ด่านซ้าย",
        "province": "เลย",
        "zipcode": 42120,
        "district_code": 420510,
        "amphoe_code": 4205,
        "province_code": 42
    },
    {
        "district": "ปากหมัน",
        "amphoe": "ด่านซ้าย",
        "province": "เลย",
        "zipcode": 42120,
        "district_code": 420502,
        "amphoe_code": 4205,
        "province_code": 42
    },
    {
        "district": "วังยาว",
        "amphoe": "ด่านซ้าย",
        "province": "เลย",
        "zipcode": 42120,
        "district_code": 420509,
        "amphoe_code": 4205,
        "province_code": 42
    },
    {
        "district": "อิปุ่ม",
        "amphoe": "ด่านซ้าย",
        "province": "เลย",
        "zipcode": 42120,
        "district_code": 420506,
        "amphoe_code": 4205,
        "province_code": 42
    },
    {
        "district": "โคกงาม",
        "amphoe": "ด่านซ้าย",
        "province": "เลย",
        "zipcode": 42120,
        "district_code": 420504,
        "amphoe_code": 4205,
        "province_code": 42
    },
    {
        "district": "โป่ง",
        "amphoe": "ด่านซ้าย",
        "province": "เลย",
        "zipcode": 42120,
        "district_code": 420508,
        "amphoe_code": 4205,
        "province_code": 42
    },
    {
        "district": "โพนสูง",
        "amphoe": "ด่านซ้าย",
        "province": "เลย",
        "zipcode": 42120,
        "district_code": 420505,
        "amphoe_code": 4205,
        "province_code": 42
    },
    {
        "district": "ท่าลี่",
        "amphoe": "ท่าลี่",
        "province": "เลย",
        "zipcode": 42140,
        "district_code": 420801,
        "amphoe_code": 4208,
        "province_code": 42
    },
    {
        "district": "น้ำทูน",
        "amphoe": "ท่าลี่",
        "province": "เลย",
        "zipcode": 42140,
        "district_code": 420806,
        "amphoe_code": 4208,
        "province_code": 42
    },
    {
        "district": "น้ำแคม",
        "amphoe": "ท่าลี่",
        "province": "เลย",
        "zipcode": 42140,
        "district_code": 420804,
        "amphoe_code": 4208,
        "province_code": 42
    },
    {
        "district": "หนองผือ",
        "amphoe": "ท่าลี่",
        "province": "เลย",
        "zipcode": 42140,
        "district_code": 420802,
        "amphoe_code": 4208,
        "province_code": 42
    },
    {
        "district": "อาฮี",
        "amphoe": "ท่าลี่",
        "province": "เลย",
        "zipcode": 42140,
        "district_code": 420803,
        "amphoe_code": 4208,
        "province_code": 42
    },
    {
        "district": "โคกใหญ่",
        "amphoe": "ท่าลี่",
        "province": "เลย",
        "zipcode": 42140,
        "district_code": 420805,
        "amphoe_code": 4208,
        "province_code": 42
    },
    {
        "district": "ท่าสวรรค์",
        "amphoe": "นาด้วง",
        "province": "เลย",
        "zipcode": 42210,
        "district_code": 420204,
        "amphoe_code": 4202,
        "province_code": 42
    },
    {
        "district": "ท่าสะอาด",
        "amphoe": "นาด้วง",
        "province": "เลย",
        "zipcode": 42210,
        "district_code": 420203,
        "amphoe_code": 4202,
        "province_code": 42
    },
    {
        "district": "นาดอกคำ",
        "amphoe": "นาด้วง",
        "province": "เลย",
        "zipcode": 42210,
        "district_code": 420202,
        "amphoe_code": 4202,
        "province_code": 42
    },
    {
        "district": "นาด้วง",
        "amphoe": "นาด้วง",
        "province": "เลย",
        "zipcode": 42210,
        "district_code": 420201,
        "amphoe_code": 4202,
        "province_code": 42
    },
    {
        "district": "นาพึง",
        "amphoe": "นาแห้ว",
        "province": "เลย",
        "zipcode": 42170,
        "district_code": 420603,
        "amphoe_code": 4206,
        "province_code": 42
    },
    {
        "district": "นามาลา",
        "amphoe": "นาแห้ว",
        "province": "เลย",
        "zipcode": 42170,
        "district_code": 420604,
        "amphoe_code": 4206,
        "province_code": 42
    },
    {
        "district": "นาแห้ว",
        "amphoe": "นาแห้ว",
        "province": "เลย",
        "zipcode": 42170,
        "district_code": 420599,
        "amphoe_code": 4206,
        "province_code": 42
    },
    {
        "district": "เหล่ากอหก",
        "amphoe": "นาแห้ว",
        "province": "เลย",
        "zipcode": 42170,
        "district_code": 420605,
        "amphoe_code": 4206,
        "province_code": 42
    },
    {
        "district": "แสงภา",
        "amphoe": "นาแห้ว",
        "province": "เลย",
        "zipcode": 42170,
        "district_code": 420594,
        "amphoe_code": 4206,
        "province_code": 42
    },
    {
        "district": "ชมเจริญ",
        "amphoe": "ปากชม",
        "province": "เลย",
        "zipcode": 42150,
        "district_code": 420406,
        "amphoe_code": 4204,
        "province_code": 42
    },
    {
        "district": "ปากชม",
        "amphoe": "ปากชม",
        "province": "เลย",
        "zipcode": 42150,
        "district_code": 420401,
        "amphoe_code": 4204,
        "province_code": 42
    },
    {
        "district": "หาดคัมภีร์",
        "amphoe": "ปากชม",
        "province": "เลย",
        "zipcode": 42150,
        "district_code": 420403,
        "amphoe_code": 4204,
        "province_code": 42
    },
    {
        "district": "ห้วยบ่อซืน",
        "amphoe": "ปากชม",
        "province": "เลย",
        "zipcode": 42150,
        "district_code": 420404,
        "amphoe_code": 4204,
        "province_code": 42
    },
    {
        "district": "ห้วยพิชัย",
        "amphoe": "ปากชม",
        "province": "เลย",
        "zipcode": 42150,
        "district_code": 420405,
        "amphoe_code": 4204,
        "province_code": 42
    },
    {
        "district": "เชียงกลม",
        "amphoe": "ปากชม",
        "province": "เลย",
        "zipcode": 42150,
        "district_code": 420402,
        "amphoe_code": 4204,
        "province_code": 42
    },
    {
        "district": "ท่าช้างคล้อง",
        "amphoe": "ผาขาว",
        "province": "เลย",
        "zipcode": 42240,
        "district_code": 421003,
        "amphoe_code": 4212,
        "province_code": 42
    },
    {
        "district": "บ้านเพิ่ม",
        "amphoe": "ผาขาว",
        "province": "เลย",
        "zipcode": 42240,
        "district_code": 421205,
        "amphoe_code": 4212,
        "province_code": 42
    },
    {
        "district": "ผาขาว",
        "amphoe": "ผาขาว",
        "province": "เลย",
        "zipcode": 42240,
        "district_code": 421004,
        "amphoe_code": 4212,
        "province_code": 42
    },
    {
        "district": "โนนปอแดง",
        "amphoe": "ผาขาว",
        "province": "เลย",
        "zipcode": 42240,
        "district_code": 421009,
        "amphoe_code": 4212,
        "province_code": 42
    },
    {
        "district": "โนนป่าซาง",
        "amphoe": "ผาขาว",
        "province": "เลย",
        "zipcode": 42240,
        "district_code": 421006,
        "amphoe_code": 4212,
        "province_code": 42
    },
    {
        "district": "ผานกเค้า",
        "amphoe": "ภูกระดึง",
        "province": "เลย",
        "zipcode": 42180,
        "district_code": 421005,
        "amphoe_code": 4210,
        "province_code": 42
    },
    {
        "district": "ภูกระดึง",
        "amphoe": "ภูกระดึง",
        "province": "เลย",
        "zipcode": 42180,
        "district_code": 421007,
        "amphoe_code": 4210,
        "province_code": 42
    },
    {
        "district": "ศรีฐาน",
        "amphoe": "ภูกระดึง",
        "province": "เลย",
        "zipcode": 42180,
        "district_code": 421001,
        "amphoe_code": 4210,
        "province_code": 42
    },
    {
        "district": "ห้วยส้ม",
        "amphoe": "ภูกระดึง",
        "province": "เลย",
        "zipcode": 42180,
        "district_code": 421010,
        "amphoe_code": 4210,
        "province_code": 42
    },
    {
        "district": "ภูหอ",
        "amphoe": "ภูหลวง",
        "province": "เลย",
        "zipcode": 42230,
        "district_code": 420999,
        "amphoe_code": 4211,
        "province_code": 42
    },
    {
        "district": "หนองคัน",
        "amphoe": "ภูหลวง",
        "province": "เลย",
        "zipcode": 42230,
        "district_code": 420998,
        "amphoe_code": 4211,
        "province_code": 42
    },
    {
        "district": "ห้วยสีเสียด",
        "amphoe": "ภูหลวง",
        "province": "เลย",
        "zipcode": 42230,
        "district_code": 421104,
        "amphoe_code": 4211,
        "province_code": 42
    },
    {
        "district": "เลยวังไสย์",
        "amphoe": "ภูหลวง",
        "province": "เลย",
        "zipcode": 42230,
        "district_code": 421105,
        "amphoe_code": 4211,
        "province_code": 42
    },
    {
        "district": "แก่งศรีภูมิ",
        "amphoe": "ภูหลวง",
        "province": "เลย",
        "zipcode": 42230,
        "district_code": 421106,
        "amphoe_code": 4211,
        "province_code": 42
    },
    {
        "district": "ท่าศาลา",
        "amphoe": "ภูเรือ",
        "province": "เลย",
        "zipcode": 42160,
        "district_code": 420597,
        "amphoe_code": 4207,
        "province_code": 42
    },
    {
        "district": "ปลาบ่า",
        "amphoe": "ภูเรือ",
        "province": "เลย",
        "zipcode": 42160,
        "district_code": 420595,
        "amphoe_code": 4207,
        "province_code": 42
    },
    {
        "district": "ร่องจิก",
        "amphoe": "ภูเรือ",
        "province": "เลย",
        "zipcode": 42160,
        "district_code": 420593,
        "amphoe_code": 4207,
        "province_code": 42
    },
    {
        "district": "ลาดค่าง",
        "amphoe": "ภูเรือ",
        "province": "เลย",
        "zipcode": 42160,
        "district_code": 420705,
        "amphoe_code": 4207,
        "province_code": 42
    },
    {
        "district": "สานตม",
        "amphoe": "ภูเรือ",
        "province": "เลย",
        "zipcode": 42160,
        "district_code": 420706,
        "amphoe_code": 4207,
        "province_code": 42
    },
    {
        "district": "หนองบัว",
        "amphoe": "ภูเรือ",
        "province": "เลย",
        "zipcode": 42160,
        "district_code": 420598,
        "amphoe_code": 4207,
        "province_code": 42
    },
    {
        "district": "ทรายขาว",
        "amphoe": "วังสะพุง",
        "province": "เลย",
        "zipcode": 42130,
        "district_code": 420902,
        "amphoe_code": 4209,
        "province_code": 42
    },
    {
        "district": "ปากปวน",
        "amphoe": "วังสะพุง",
        "province": "เลย",
        "zipcode": 42130,
        "district_code": 420905,
        "amphoe_code": 4209,
        "province_code": 42
    },
    {
        "district": "ผาน้อย",
        "amphoe": "วังสะพุง",
        "province": "เลย",
        "zipcode": 42130,
        "district_code": 420906,
        "amphoe_code": 4209,
        "province_code": 42
    },
    {
        "district": "ผาบิ้ง",
        "amphoe": "วังสะพุง",
        "province": "เลย",
        "zipcode": 42130,
        "district_code": 420910,
        "amphoe_code": 4209,
        "province_code": 42
    },
    {
        "district": "วังสะพุง",
        "amphoe": "วังสะพุง",
        "province": "เลย",
        "zipcode": 42130,
        "district_code": 420901,
        "amphoe_code": 4209,
        "province_code": 42
    },
    {
        "district": "ศรีสงคราม",
        "amphoe": "วังสะพุง",
        "province": "เลย",
        "zipcode": 42130,
        "district_code": 420913,
        "amphoe_code": 4209,
        "province_code": 42
    },
    {
        "district": "หนองงิ้ว",
        "amphoe": "วังสะพุง",
        "province": "เลย",
        "zipcode": 42130,
        "district_code": 420904,
        "amphoe_code": 4209,
        "province_code": 42
    },
    {
        "district": "หนองหญ้าปล้อง",
        "amphoe": "วังสะพุง",
        "province": "เลย",
        "zipcode": 42130,
        "district_code": 420903,
        "amphoe_code": 4209,
        "province_code": 42
    },
    {
        "district": "เขาหลวง",
        "amphoe": "วังสะพุง",
        "province": "เลย",
        "zipcode": 42130,
        "district_code": 420911,
        "amphoe_code": 4209,
        "province_code": 42
    },
    {
        "district": "โคกขมิ้น",
        "amphoe": "วังสะพุง",
        "province": "เลย",
        "zipcode": 42130,
        "district_code": 420912,
        "amphoe_code": 4209,
        "province_code": 42
    },
    {
        "district": "ตาดข่า",
        "amphoe": "หนองหิน",
        "province": "เลย",
        "zipcode": 42190,
        "district_code": 421011,
        "amphoe_code": 4214,
        "province_code": 42
    },
    {
        "district": "ปวนพุ",
        "amphoe": "หนองหิน",
        "province": "เลย",
        "zipcode": 42190,
        "district_code": 421002,
        "amphoe_code": 4214,
        "province_code": 42
    },
    {
        "district": "หนองหิน",
        "amphoe": "หนองหิน",
        "province": "เลย",
        "zipcode": 42190,
        "district_code": 421008,
        "amphoe_code": 4214,
        "province_code": 42
    },
    {
        "district": "จอมศรี",
        "amphoe": "เชียงคาน",
        "province": "เลย",
        "zipcode": 42110,
        "district_code": 420307,
        "amphoe_code": 4203,
        "province_code": 42
    },
    {
        "district": "ธาตุ",
        "amphoe": "เชียงคาน",
        "province": "เลย",
        "zipcode": 42110,
        "district_code": 420302,
        "amphoe_code": 4203,
        "province_code": 42
    },
    {
        "district": "นาซ่าว",
        "amphoe": "เชียงคาน",
        "province": "เลย",
        "zipcode": 42110,
        "district_code": 420303,
        "amphoe_code": 4203,
        "province_code": 42
    },
    {
        "district": "บุฮม",
        "amphoe": "เชียงคาน",
        "province": "เลย",
        "zipcode": 42110,
        "district_code": 420306,
        "amphoe_code": 4203,
        "province_code": 42
    },
    {
        "district": "ปากตม",
        "amphoe": "เชียงคาน",
        "province": "เลย",
        "zipcode": 42110,
        "district_code": 420305,
        "amphoe_code": 4203,
        "province_code": 42
    },
    {
        "district": "หาดทรายขาว",
        "amphoe": "เชียงคาน",
        "province": "เลย",
        "zipcode": 42110,
        "district_code": 420308,
        "amphoe_code": 4203,
        "province_code": 42
    },
    {
        "district": "เขาแก้ว",
        "amphoe": "เชียงคาน",
        "province": "เลย",
        "zipcode": 42110,
        "district_code": 420304,
        "amphoe_code": 4203,
        "province_code": 42
    },
    {
        "district": "เชียงคาน",
        "amphoe": "เชียงคาน",
        "province": "เลย",
        "zipcode": 42110,
        "district_code": 420301,
        "amphoe_code": 4203,
        "province_code": 42
    },
    {
        "district": "กกดู่",
        "amphoe": "เมืองเลย",
        "province": "เลย",
        "zipcode": 42000,
        "district_code": 420104,
        "amphoe_code": 4201,
        "province_code": 42
    },
    {
        "district": "กกทอง",
        "amphoe": "เมืองเลย",
        "province": "เลย",
        "zipcode": 42000,
        "district_code": 420114,
        "amphoe_code": 4201,
        "province_code": 42
    },
    {
        "district": "กุดป่อง",
        "amphoe": "เมืองเลย",
        "province": "เลย",
        "zipcode": 42000,
        "district_code": 420101,
        "amphoe_code": 4201,
        "province_code": 42
    },
    {
        "district": "ชัยพฤกษ์",
        "amphoe": "เมืองเลย",
        "province": "เลย",
        "zipcode": 42000,
        "district_code": 420111,
        "amphoe_code": 4201,
        "province_code": 42
    },
    {
        "district": "นาดินดำ",
        "amphoe": "เมืองเลย",
        "province": "เลย",
        "zipcode": 42000,
        "district_code": 420109,
        "amphoe_code": 4201,
        "province_code": 42
    },
    {
        "district": "นาอาน",
        "amphoe": "เมืองเลย",
        "province": "เลย",
        "zipcode": 42000,
        "district_code": 420107,
        "amphoe_code": 4201,
        "province_code": 42
    },
    {
        "district": "นาอ้อ",
        "amphoe": "เมืองเลย",
        "province": "เลย",
        "zipcode": 42100,
        "district_code": 420103,
        "amphoe_code": 4201,
        "province_code": 42
    },
    {
        "district": "นาแขม",
        "amphoe": "เมืองเลย",
        "province": "เลย",
        "zipcode": 42000,
        "district_code": 420112,
        "amphoe_code": 4201,
        "province_code": 42
    },
    {
        "district": "นาโป่ง",
        "amphoe": "เมืองเลย",
        "province": "เลย",
        "zipcode": 42000,
        "district_code": 420108,
        "amphoe_code": 4201,
        "province_code": 42
    },
    {
        "district": "น้ำสวย",
        "amphoe": "เมืองเลย",
        "province": "เลย",
        "zipcode": 42000,
        "district_code": 420110,
        "amphoe_code": 4201,
        "province_code": 42
    },
    {
        "district": "น้ำหมาน",
        "amphoe": "เมืองเลย",
        "province": "เลย",
        "zipcode": 42000,
        "district_code": 420105,
        "amphoe_code": 4201,
        "province_code": 42
    },
    {
        "district": "ศรีสองรัก",
        "amphoe": "เมืองเลย",
        "province": "เลย",
        "zipcode": 42100,
        "district_code": 420113,
        "amphoe_code": 4201,
        "province_code": 42
    },
    {
        "district": "เมือง",
        "amphoe": "เมืองเลย",
        "province": "เลย",
        "zipcode": 42000,
        "district_code": 420102,
        "amphoe_code": 4201,
        "province_code": 42
    },
    {
        "district": "เสี้ยว",
        "amphoe": "เมืองเลย",
        "province": "เลย",
        "zipcode": 42000,
        "district_code": 420106,
        "amphoe_code": 4201,
        "province_code": 42
    },
    {
        "district": "ทรัพย์ไพวัลย์",
        "amphoe": "เอราวัณ",
        "province": "เลย",
        "zipcode": 42220,
        "district_code": 420914,
        "amphoe_code": 4213,
        "province_code": 42
    },
    {
        "district": "ผาสามยอด",
        "amphoe": "เอราวัณ",
        "province": "เลย",
        "zipcode": 42220,
        "district_code": 420909,
        "amphoe_code": 4213,
        "province_code": 42
    },
    {
        "district": "ผาอินทร์แปลง",
        "amphoe": "เอราวัณ",
        "province": "เลย",
        "zipcode": 42220,
        "district_code": 420908,
        "amphoe_code": 4213,
        "province_code": 42
    },
    {
        "district": "เอราวัณ",
        "amphoe": "เอราวัณ",
        "province": "เลย",
        "zipcode": 42220,
        "district_code": 420907,
        "amphoe_code": 4213,
        "province_code": 42
    },
    {
        "district": "ทุ่งศรี",
        "amphoe": "ร้องกวาง",
        "province": "แพร่",
        "zipcode": 54140,
        "district_code": 540207,
        "amphoe_code": 5402,
        "province_code": 54
    },
    {
        "district": "น้ำเลา",
        "amphoe": "ร้องกวาง",
        "province": "แพร่",
        "zipcode": 54140,
        "district_code": 540205,
        "amphoe_code": 5402,
        "province_code": 54
    },
    {
        "district": "บ้านเวียง",
        "amphoe": "ร้องกวาง",
        "province": "แพร่",
        "zipcode": 54140,
        "district_code": 540206,
        "amphoe_code": 5402,
        "province_code": 54
    },
    {
        "district": "ร้องกวาง",
        "amphoe": "ร้องกวาง",
        "province": "แพร่",
        "zipcode": 54140,
        "district_code": 540201,
        "amphoe_code": 5402,
        "province_code": 54
    },
    {
        "district": "ร้องเข็ม",
        "amphoe": "ร้องกวาง",
        "province": "แพร่",
        "zipcode": 54140,
        "district_code": 540204,
        "amphoe_code": 5402,
        "province_code": 54
    },
    {
        "district": "ห้วยโรง",
        "amphoe": "ร้องกวาง",
        "province": "แพร่",
        "zipcode": 54140,
        "district_code": 540213,
        "amphoe_code": 5402,
        "province_code": 54
    },
    {
        "district": "แม่ทราย",
        "amphoe": "ร้องกวาง",
        "province": "แพร่",
        "zipcode": 54140,
        "district_code": 540214,
        "amphoe_code": 5402,
        "province_code": 54
    },
    {
        "district": "แม่ยางตาล",
        "amphoe": "ร้องกวาง",
        "province": "แพร่",
        "zipcode": 54140,
        "district_code": 540208,
        "amphoe_code": 5402,
        "province_code": 54
    },
    {
        "district": "แม่ยางร้อง",
        "amphoe": "ร้องกวาง",
        "province": "แพร่",
        "zipcode": 54140,
        "district_code": 540215,
        "amphoe_code": 5402,
        "province_code": 54
    },
    {
        "district": "แม่ยางฮ่อ",
        "amphoe": "ร้องกวาง",
        "province": "แพร่",
        "zipcode": 54140,
        "district_code": 540209,
        "amphoe_code": 5402,
        "province_code": 54
    },
    {
        "district": "ไผ่โทน",
        "amphoe": "ร้องกวาง",
        "province": "แพร่",
        "zipcode": 54140,
        "district_code": 540210,
        "amphoe_code": 5402,
        "province_code": 54
    },
    {
        "district": "ต้าผามอก",
        "amphoe": "ลอง",
        "province": "แพร่",
        "zipcode": 54150,
        "district_code": 540303,
        "amphoe_code": 5403,
        "province_code": 54
    },
    {
        "district": "ทุ่งแล้ง",
        "amphoe": "ลอง",
        "province": "แพร่",
        "zipcode": 54150,
        "district_code": 540307,
        "amphoe_code": 5403,
        "province_code": 54
    },
    {
        "district": "บ่อเหล็กลอง",
        "amphoe": "ลอง",
        "province": "แพร่",
        "zipcode": 54150,
        "district_code": 540308,
        "amphoe_code": 5403,
        "province_code": 54
    },
    {
        "district": "บ้านปิน",
        "amphoe": "ลอง",
        "province": "แพร่",
        "zipcode": 54150,
        "district_code": 540302,
        "amphoe_code": 5403,
        "province_code": 54
    },
    {
        "district": "ปากกาง",
        "amphoe": "ลอง",
        "province": "แพร่",
        "zipcode": 54150,
        "district_code": 540305,
        "amphoe_code": 5403,
        "province_code": 54
    },
    {
        "district": "หัวทุ่ง",
        "amphoe": "ลอง",
        "province": "แพร่",
        "zipcode": 54150,
        "district_code": 540306,
        "amphoe_code": 5403,
        "province_code": 54
    },
    {
        "district": "ห้วยอ้อ",
        "amphoe": "ลอง",
        "province": "แพร่",
        "zipcode": 54150,
        "district_code": 540301,
        "amphoe_code": 5403,
        "province_code": 54
    },
    {
        "district": "เวียงต้า",
        "amphoe": "ลอง",
        "province": "แพร่",
        "zipcode": 54150,
        "district_code": 540304,
        "amphoe_code": 5403,
        "province_code": 54
    },
    {
        "district": "แม่ปาน",
        "amphoe": "ลอง",
        "province": "แพร่",
        "zipcode": 54150,
        "district_code": 540309,
        "amphoe_code": 5403,
        "province_code": 54
    },
    {
        "district": "นาพูน",
        "amphoe": "วังชิ้น",
        "province": "แพร่",
        "zipcode": 54160,
        "district_code": 540704,
        "amphoe_code": 5407,
        "province_code": 54
    },
    {
        "district": "ป่าสัก",
        "amphoe": "วังชิ้น",
        "province": "แพร่",
        "zipcode": 54160,
        "district_code": 540706,
        "amphoe_code": 5407,
        "province_code": 54
    },
    {
        "district": "วังชิ้น",
        "amphoe": "วังชิ้น",
        "province": "แพร่",
        "zipcode": 54160,
        "district_code": 540701,
        "amphoe_code": 5407,
        "province_code": 54
    },
    {
        "district": "สรอย",
        "amphoe": "วังชิ้น",
        "province": "แพร่",
        "zipcode": 54160,
        "district_code": 540702,
        "amphoe_code": 5407,
        "province_code": 54
    },
    {
        "district": "แม่ป้าก",
        "amphoe": "วังชิ้น",
        "province": "แพร่",
        "zipcode": 54160,
        "district_code": 540703,
        "amphoe_code": 5407,
        "province_code": 54
    },
    {
        "district": "แม่พุง",
        "amphoe": "วังชิ้น",
        "province": "แพร่",
        "zipcode": 54160,
        "district_code": 540705,
        "amphoe_code": 5407,
        "province_code": 54
    },
    {
        "district": "แม่เกิ๋ง",
        "amphoe": "วังชิ้น",
        "province": "แพร่",
        "zipcode": 54160,
        "district_code": 540707,
        "amphoe_code": 5407,
        "province_code": 54
    },
    {
        "district": "ทุ่งน้าว",
        "amphoe": "สอง",
        "province": "แพร่",
        "zipcode": 54120,
        "district_code": 540608,
        "amphoe_code": 5406,
        "province_code": 54
    },
    {
        "district": "บ้านกลาง",
        "amphoe": "สอง",
        "province": "แพร่",
        "zipcode": 54120,
        "district_code": 540602,
        "amphoe_code": 5406,
        "province_code": 54
    },
    {
        "district": "บ้านหนุน",
        "amphoe": "สอง",
        "province": "แพร่",
        "zipcode": 54120,
        "district_code": 540601,
        "amphoe_code": 5406,
        "province_code": 54
    },
    {
        "district": "สะเอียบ",
        "amphoe": "สอง",
        "province": "แพร่",
        "zipcode": 54120,
        "district_code": 540606,
        "amphoe_code": 5406,
        "province_code": 54
    },
    {
        "district": "หัวเมือง",
        "amphoe": "สอง",
        "province": "แพร่",
        "zipcode": 54120,
        "district_code": 540605,
        "amphoe_code": 5406,
        "province_code": 54
    },
    {
        "district": "ห้วยหม้าย",
        "amphoe": "สอง",
        "province": "แพร่",
        "zipcode": 54120,
        "district_code": 540603,
        "amphoe_code": 5406,
        "province_code": 54
    },
    {
        "district": "เตาปูน",
        "amphoe": "สอง",
        "province": "แพร่",
        "zipcode": 54120,
        "district_code": 540604,
        "amphoe_code": 5406,
        "province_code": 54
    },
    {
        "district": "แดนชุมพล",
        "amphoe": "สอง",
        "province": "แพร่",
        "zipcode": 54120,
        "district_code": 540607,
        "amphoe_code": 5406,
        "province_code": 54
    },
    {
        "district": "ดอนมูล",
        "amphoe": "สูงเม่น",
        "province": "แพร่",
        "zipcode": 54130,
        "district_code": 540404,
        "amphoe_code": 5404,
        "province_code": 54
    },
    {
        "district": "น้ำชำ",
        "amphoe": "สูงเม่น",
        "province": "แพร่",
        "zipcode": 54130,
        "district_code": 540103,
        "amphoe_code": 5404,
        "province_code": 54
    },
    {
        "district": "บ้านกวาง",
        "amphoe": "สูงเม่น",
        "province": "แพร่",
        "zipcode": 54130,
        "district_code": 540406,
        "amphoe_code": 5404,
        "province_code": 54
    },
    {
        "district": "บ้านกาศ",
        "amphoe": "สูงเม่น",
        "province": "แพร่",
        "zipcode": 54130,
        "district_code": 540408,
        "amphoe_code": 5404,
        "province_code": 54
    },
    {
        "district": "บ้านปง",
        "amphoe": "สูงเม่น",
        "province": "แพร่",
        "zipcode": 54130,
        "district_code": 540407,
        "amphoe_code": 5404,
        "province_code": 54
    },
    {
        "district": "บ้านเหล่า",
        "amphoe": "สูงเม่น",
        "province": "แพร่",
        "zipcode": 54130,
        "district_code": 540405,
        "amphoe_code": 5404,
        "province_code": 54
    },
    {
        "district": "พระหลวง",
        "amphoe": "สูงเม่น",
        "province": "แพร่",
        "zipcode": 54130,
        "district_code": 540412,
        "amphoe_code": 5404,
        "province_code": 54
    },
    {
        "district": "ร่องกาศ",
        "amphoe": "สูงเม่น",
        "province": "แพร่",
        "zipcode": 54130,
        "district_code": 540409,
        "amphoe_code": 5404,
        "province_code": 54
    },
    {
        "district": "สบสาย",
        "amphoe": "สูงเม่น",
        "province": "แพร่",
        "zipcode": 54130,
        "district_code": 540410,
        "amphoe_code": 5404,
        "province_code": 54
    },
    {
        "district": "สูงเม่น",
        "amphoe": "สูงเม่น",
        "province": "แพร่",
        "zipcode": 54130,
        "district_code": 540401,
        "amphoe_code": 5404,
        "province_code": 54
    },
    {
        "district": "หัวฝาย",
        "amphoe": "สูงเม่น",
        "province": "แพร่",
        "zipcode": 54130,
        "district_code": 540403,
        "amphoe_code": 5404,
        "province_code": 54
    },
    {
        "district": "เวียงทอง",
        "amphoe": "สูงเม่น",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540411,
        "amphoe_code": 5404,
        "province_code": 54
    },
    {
        "district": "ตำหนักธรรม",
        "amphoe": "หนองม่วงไข่",
        "province": "แพร่",
        "zipcode": 54170,
        "district_code": 540805,
        "amphoe_code": 5408,
        "province_code": 54
    },
    {
        "district": "ทุ่งแค้ว",
        "amphoe": "หนองม่วงไข่",
        "province": "แพร่",
        "zipcode": 54170,
        "district_code": 540806,
        "amphoe_code": 5408,
        "province_code": 54
    },
    {
        "district": "น้ำรัด",
        "amphoe": "หนองม่วงไข่",
        "province": "แพร่",
        "zipcode": 54170,
        "district_code": 540211,
        "amphoe_code": 5408,
        "province_code": 54
    },
    {
        "district": "วังหลวง",
        "amphoe": "หนองม่วงไข่",
        "province": "แพร่",
        "zipcode": 54170,
        "district_code": 540212,
        "amphoe_code": 5408,
        "province_code": 54
    },
    {
        "district": "หนองม่วงไข่",
        "amphoe": "หนองม่วงไข่",
        "province": "แพร่",
        "zipcode": 54170,
        "district_code": 540202,
        "amphoe_code": 5408,
        "province_code": 54
    },
    {
        "district": "แม่คำมี",
        "amphoe": "หนองม่วงไข่",
        "province": "แพร่",
        "zipcode": 54170,
        "district_code": 540114,
        "amphoe_code": 5408,
        "province_code": 54
    },
    {
        "district": "ปงป่าหวาย",
        "amphoe": "เด่นชัย",
        "province": "แพร่",
        "zipcode": 54110,
        "district_code": 540505,
        "amphoe_code": 5405,
        "province_code": 54
    },
    {
        "district": "ห้วยไร่",
        "amphoe": "เด่นชัย",
        "province": "แพร่",
        "zipcode": 54110,
        "district_code": 540504,
        "amphoe_code": 5405,
        "province_code": 54
    },
    {
        "district": "เด่นชัย",
        "amphoe": "เด่นชัย",
        "province": "แพร่",
        "zipcode": 54110,
        "district_code": 540501,
        "amphoe_code": 5405,
        "province_code": 54
    },
    {
        "district": "แม่จั๊วะ",
        "amphoe": "เด่นชัย",
        "province": "แพร่",
        "zipcode": 54110,
        "district_code": 540502,
        "amphoe_code": 5405,
        "province_code": 54
    },
    {
        "district": "ไทรย้อย",
        "amphoe": "เด่นชัย",
        "province": "แพร่",
        "zipcode": 54110,
        "district_code": 540503,
        "amphoe_code": 5405,
        "province_code": 54
    },
    {
        "district": "กาญจนา",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540120,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "ช่อแฮ",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540118,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "ทุ่งกวาว",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540115,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "ทุ่งโฮ้ง",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540105,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "ท่าข้าม",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540116,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "นาจักร",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540102,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "น้ำชำ",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540103,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "บ้านถิ่น",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540111,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "ป่าแดง",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540104,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "ป่าแมต",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540110,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "ร่องฟอง",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540119,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "วังธง",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540107,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "วังหงษ์",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": false,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "สวนเขื่อน",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540112,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "ห้วยม้า",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540109,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "เหมืองหม้อ",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540106,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "แม่คำมี",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540114,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "แม่ยม",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540117,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "แม่หล่าย",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540108,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "ในเวียง",
        "amphoe": "เมืองแพร่",
        "province": "แพร่",
        "zipcode": 54000,
        "district_code": 540101,
        "amphoe_code": 5401,
        "province_code": 54
    },
    {
        "district": "ขุนยวม",
        "amphoe": "ขุนยวม",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58140,
        "district_code": 580201,
        "amphoe_code": 5802,
        "province_code": 58
    },
    {
        "district": "เมืองปอน",
        "amphoe": "ขุนยวม",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58140,
        "district_code": 580203,
        "amphoe_code": 5802,
        "province_code": 58
    },
    {
        "district": "แม่กิ๊",
        "amphoe": "ขุนยวม",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58140,
        "district_code": 580205,
        "amphoe_code": 5802,
        "province_code": 58
    },
    {
        "district": "แม่ยวมน้อย",
        "amphoe": "ขุนยวม",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58140,
        "district_code": 580204,
        "amphoe_code": 5802,
        "province_code": 58
    },
    {
        "district": "แม่อูคอ",
        "amphoe": "ขุนยวม",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58140,
        "district_code": 580206,
        "amphoe_code": 5802,
        "province_code": 58
    },
    {
        "district": "แม่เงา",
        "amphoe": "ขุนยวม",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58140,
        "district_code": 580202,
        "amphoe_code": 5802,
        "province_code": 58
    },
    {
        "district": "ถ้ำลอด",
        "amphoe": "ปางมะผ้า",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58150,
        "district_code": 580703,
        "amphoe_code": 5807,
        "province_code": 58
    },
    {
        "district": "นาปู่ป้อม",
        "amphoe": "ปางมะผ้า",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58150,
        "district_code": 580704,
        "amphoe_code": 5807,
        "province_code": 58
    },
    {
        "district": "ปางมะผ้า",
        "amphoe": "ปางมะผ้า",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58150,
        "district_code": 580107,
        "amphoe_code": 5807,
        "province_code": 58
    },
    {
        "district": "สบป่อง",
        "amphoe": "ปางมะผ้า",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58150,
        "district_code": 580108,
        "amphoe_code": 5807,
        "province_code": 58
    },
    {
        "district": "ทุ่งยาว",
        "amphoe": "ปาย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58130,
        "district_code": 580305,
        "amphoe_code": 5803,
        "province_code": 58
    },
    {
        "district": "เมืองแปง",
        "amphoe": "ปาย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58130,
        "district_code": 580306,
        "amphoe_code": 5803,
        "province_code": 58
    },
    {
        "district": "เวียงเหนือ",
        "amphoe": "ปาย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58130,
        "district_code": 580302,
        "amphoe_code": 5803,
        "province_code": 58
    },
    {
        "district": "เวียงใต้",
        "amphoe": "ปาย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58130,
        "district_code": 580301,
        "amphoe_code": 5803,
        "province_code": 58
    },
    {
        "district": "แม่นาเติง",
        "amphoe": "ปาย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58130,
        "district_code": 580303,
        "amphoe_code": 5803,
        "province_code": 58
    },
    {
        "district": "แม่ฮี้",
        "amphoe": "ปาย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58130,
        "district_code": 580304,
        "amphoe_code": 5803,
        "province_code": 58
    },
    {
        "district": "โป่งสา",
        "amphoe": "ปาย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58130,
        "district_code": 580307,
        "amphoe_code": 5803,
        "province_code": 58
    },
    {
        "district": "กองก๋อย",
        "amphoe": "สบเมย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58110,
        "district_code": 580603,
        "amphoe_code": 5806,
        "province_code": 58
    },
    {
        "district": "ป่าโปง",
        "amphoe": "สบเมย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58110,
        "district_code": 580605,
        "amphoe_code": 5806,
        "province_code": 58
    },
    {
        "district": "สบเมย",
        "amphoe": "สบเมย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58110,
        "district_code": 580499,
        "amphoe_code": 5806,
        "province_code": 58
    },
    {
        "district": "แม่คะตวน",
        "amphoe": "สบเมย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58110,
        "district_code": 580498,
        "amphoe_code": 5806,
        "province_code": 58
    },
    {
        "district": "แม่สวด",
        "amphoe": "สบเมย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58110,
        "district_code": 580604,
        "amphoe_code": 5806,
        "province_code": 58
    },
    {
        "district": "แม่สามแลบ",
        "amphoe": "สบเมย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58110,
        "district_code": 580606,
        "amphoe_code": 5806,
        "province_code": 58
    },
    {
        "district": "จองคำ",
        "amphoe": "เมืองแม่ฮ่องสอน",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58000,
        "district_code": 580101,
        "amphoe_code": 5801,
        "province_code": 58
    },
    {
        "district": "ปางหมู",
        "amphoe": "เมืองแม่ฮ่องสอน",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58000,
        "district_code": 580104,
        "amphoe_code": 5801,
        "province_code": 58
    },
    {
        "district": "ผาบ่อง",
        "amphoe": "เมืองแม่ฮ่องสอน",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58000,
        "district_code": 580103,
        "amphoe_code": 5801,
        "province_code": 58
    },
    {
        "district": "หมอกจำแป่",
        "amphoe": "เมืองแม่ฮ่องสอน",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58000,
        "district_code": 580105,
        "amphoe_code": 5801,
        "province_code": 58
    },
    {
        "district": "ห้วยปูลิง",
        "amphoe": "เมืองแม่ฮ่องสอน",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58000,
        "district_code": 580109,
        "amphoe_code": 5801,
        "province_code": 58
    },
    {
        "district": "ห้วยผา",
        "amphoe": "เมืองแม่ฮ่องสอน",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58000,
        "district_code": 580106,
        "amphoe_code": 5801,
        "province_code": 58
    },
    {
        "district": "ห้วยโป่ง",
        "amphoe": "เมืองแม่ฮ่องสอน",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58000,
        "district_code": 580102,
        "amphoe_code": 5801,
        "province_code": 58
    },
    {
        "district": "ขุนแม่ลาน้อย",
        "amphoe": "แม่ลาน้อย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58120,
        "district_code": 580508,
        "amphoe_code": 5805,
        "province_code": 58
    },
    {
        "district": "ท่าผาปุ้ม",
        "amphoe": "แม่ลาน้อย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58120,
        "district_code": 580503,
        "amphoe_code": 5805,
        "province_code": 58
    },
    {
        "district": "สันติคีรี",
        "amphoe": "แม่ลาน้อย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58120,
        "district_code": 580507,
        "amphoe_code": 5805,
        "province_code": 58
    },
    {
        "district": "ห้วยห้อม",
        "amphoe": "แม่ลาน้อย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58120,
        "district_code": 580505,
        "amphoe_code": 5805,
        "province_code": 58
    },
    {
        "district": "แม่นาจาง",
        "amphoe": "แม่ลาน้อย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58120,
        "district_code": 580506,
        "amphoe_code": 5805,
        "province_code": 58
    },
    {
        "district": "แม่ลาน้อย",
        "amphoe": "แม่ลาน้อย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58120,
        "district_code": 580501,
        "amphoe_code": 5805,
        "province_code": 58
    },
    {
        "district": "แม่ลาหลวง",
        "amphoe": "แม่ลาน้อย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58120,
        "district_code": 580502,
        "amphoe_code": 5805,
        "province_code": 58
    },
    {
        "district": "แม่โถ",
        "amphoe": "แม่ลาน้อย",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58120,
        "district_code": 580504,
        "amphoe_code": 5805,
        "province_code": 58
    },
    {
        "district": "บ้านกาศ",
        "amphoe": "แม่สะเรียง",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58110,
        "district_code": 580401,
        "amphoe_code": 5804,
        "province_code": 58
    },
    {
        "district": "ป่าแป๋",
        "amphoe": "แม่สะเรียง",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58110,
        "district_code": 580408,
        "amphoe_code": 5804,
        "province_code": 58
    },
    {
        "district": "เสาหิน",
        "amphoe": "แม่สะเรียง",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58110,
        "district_code": 580406,
        "amphoe_code": 5804,
        "province_code": 58
    },
    {
        "district": "แม่คง",
        "amphoe": "แม่สะเรียง",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58110,
        "district_code": 580403,
        "amphoe_code": 5804,
        "province_code": 58
    },
    {
        "district": "แม่ยวม",
        "amphoe": "แม่สะเรียง",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58110,
        "district_code": 580405,
        "amphoe_code": 5804,
        "province_code": 58
    },
    {
        "district": "แม่สะเรียง",
        "amphoe": "แม่สะเรียง",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58110,
        "district_code": 580402,
        "amphoe_code": 5804,
        "province_code": 58
    },
    {
        "district": "แม่เหาะ",
        "amphoe": "แม่สะเรียง",
        "province": "แม่ฮ่องสอน",
        "zipcode": 58110,
        "district_code": 580404,
        "amphoe_code": 5804,
        "province_code": 58
    }
];

export const getProvinces = () => addresses.map(item => item.province);

export const getCities = () =>  addresses.map(item => item.district);

export const getSubDistricts = () =>  addresses.map(item => item.amphoe);

export default {};